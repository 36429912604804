'use strict';

/**
 * @name supplierTagsBySupplierDetailCtrl
 *
 * @description Controller for supplier tags grouped by selected supplier
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function supplierTagsBySupplierDetailCtrl($scope, companiesTaggedToSupplier, $rootScope, supplierName, HighlightId,
                                          $stateParams, supplierGlobalFunc) {

  $scope.showColumn = showColumn;
  $scope.back = back;
  $scope.isCurrentUserSupplierManager = isCurrentUserSupplierManager;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function back() {
    if (!!$stateParams.supplierCode) {
      HighlightId.setId($stateParams.supplierCode);
    }
    window.history.back();
  }

  /**
   * Check if current user has system role: Supplier Manager
   *
   * @returns {boolean}
   */
  function isCurrentUserSupplierManager() {
    return (supplierGlobalFunc.checkIfUserHasSupplierManagerRole($rootScope.currentUser));
  }

  function initialize() {
    $scope.editClicked = false;
    $scope.supplierName = supplierName;
    $scope.companiesTaggedToSupplier = companiesTaggedToSupplier.data;
    $scope.meta = companiesTaggedToSupplier.meta;
    $scope.supplierTagService = companiesTaggedToSupplier;
    $scope.storageKey = 'companies-tagged-to-supplier-selected-columns';
    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

supplierTagsBySupplierDetailCtrl.$inject = [
  '$scope', 'companiesTaggedToSupplier', '$rootScope', 'supplierName', 'HighlightId', '$stateParams',
  'supplierGlobalFunc'
];

angular.module('metabuyer')
  .controller('supplierTagsBySupplierDetailCtrl', supplierTagsBySupplierDetailCtrl);
