'use strict';

angular.module('metabuyer.services.starringServices', ['ngResource'])
  .factory('prStarring',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.purchaseRequisitions.starring,
        {
          id: '@id'
        },
        {
          'post': { method: 'POST' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('starringPO',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.starring.listPO,
        {
          id: '@id',
          action: '@action'
        },
        {
          'update': {method: 'PUT'},
          'getStar': {
            method: 'GET',
            params: {
              id: '@id'
            },
            url: pathConstants.apiUrls.users.listPOStar
          }
        }
      );
    }
  );
