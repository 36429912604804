'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider, pathConstants) {
    //TODO: rename the states from to 'users' to something more readable (Ahmed Saleh)
    $stateProvider
      .state('main.user.users', {
        url: '/users',
        controller: 'UsersCtrl',
        templateUrl: 'app/usersManagement/usersManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;
            if (!!profile.roles && profile.roles.length > 0) {
              for (var index in profile.roles) {
                if ((profile.roles[index].name.toLowerCase() === 'admin' && profile.roles[index].company_code === null) ||
                  profile.roles[index].name.toLowerCase() === 'tenant_super_admin'){
                  check = true;
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.users.search', {
        url: '/manage/search?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/manage/manage.html',
        controller: 'UsersManageCtrl',
        resolve: {
          action: function () {
            return "search";
          },
          usersList: function ($q, searchModule, $stateParams, globalFunc, exportSearch) {
            var deferred = $q.defer();

            var params = {};
            var status = globalFunc.availableStatuses('users', $stateParams.status);

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User - search" + (!!$stateParams.query ? " - " + $stateParams.query : "");
          }
        }
      })
      .state('main.user.users.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/manage/manage.html',
        controller: 'UsersManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if($stateParams.query){
              return 'search';
            }
            else{
              return 'show';
            }
          },
          usersList: function ($q, searchModule, $stateParams, globalFunc, exportSearch) {

            var status = globalFunc.availableStatuses('users', $stateParams.status);
            var deferred = $q.defer();
            var params = {};

            /**
             * First determine if search or listing
             */
            if(!!$stateParams.query){
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                var temp = 'criteria[' + $stateParams.filter + ']';
                params = {
                  module: 'users',
                  criteria_operator: !!$stateParams.status ? 'and' : null,
                  'criteria[1][status]': $stateParams.status !== 'all' ? status : null,
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };

                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
                }

              }
              /**
               * If searching without filter
               */
              else {
                params = {
                  module: 'users',
                  'criteria[0][display_name]': $stateParams.query,
                  'criteria[0][email]': $stateParams.query,
                  'criteria[0][emp_no]': $stateParams.query,
                  'criteria[0][creator.display_name]': $stateParams.query,
                  'criteria[0][updater.display_name]': $stateParams.query,
                  'criteria[1][status]': $stateParams.status !== 'all' ? status : null,
                  'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
                  criteria_operator: !!$stateParams.query ? 'and' : null,
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            /**
             * For listing
             */
            else{
              params = {
                module: 'users',
                'criteria[status]': $stateParams.status !== 'all' ? status : null,
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User - listing" + (!!$stateParams.status ? " - " + $stateParams.status : "");
          }
        }
      })
      .state('main.user.users.escalation', {
        url: '/escalation?{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/escalation/escalationTemplate.html',
        controller: 'usersEscalationCtrl',
        resolve: {
          usersList: function ($q, searchModule, $stateParams, exportSearch) {

            var deferred = $q.defer();
            var params = {
              module: 'user-superior',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'user.display_name',
              order: !!$stateParams.order ? $stateParams.order : 1
            };

            /**
             * First determine if search or listing
             */
            if(!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {
                params['criteria[' + $stateParams.filter + ']'] = $stateParams.query;
              }
              /**
               * Searching in all filters
               */
              else{
                params['criteria[user.display_name]'] = $stateParams.query;
                params['criteria[user.email]'] = $stateParams.query;
                params['criteria[superior.display_name]'] = $stateParams.query;
                params['criteria[superior.email]'] = $stateParams.query;
                params['criteria[company.descr]'] = $stateParams.query;
                params['criteria_operator'] = 'or';
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Escalation Listing";
          }
        }
      })
      .state('main.user.users.delegation', {
        url: '/delegation?{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/delegations/delegations.html',
        controller: 'usersDelegationCtrl',
        resolve: {
          delegationList: function ($q, searchModule, $stateParams) {

            var deferred = $q.defer();
            var params = {
              module: 'delegations',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * First determine if search or listing
             */
            if(!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                if ($stateParams.type === 'date') {
                  var temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * Searching in all filters
               */
              else{
                params['criteria[0][user.display_name]'] = $stateParams.query;
                params['criteria[0][delegatee.display_name]'] = $stateParams.query;
                params['criteria[0][company_code]'] = $stateParams.query;
                params['criteria[0][company.descr]'] = $stateParams.query;
                params['criteria[0][reason]'] = $stateParams.query;
                params['criteria_operator'] = 'or';
              }
            }

            searchModule.get(params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Delegations Listing';
          }
        }
      })
      .state('main.user.users.onlineUsers', {
        url: '/online_users',
        templateUrl: 'app/usersManagement/onlineUsers/onlineUsersTemplate.html',
        controller: 'onlineUsersCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkUserSessionAccess(profile, 'C', $rootScope.changeWorkSpace)
              && !UserPermissions.checkUserSessionAccess(profile, 'U', $rootScope.changeWorkSpace)
              && !UserPermissions.checkUserSessionAccess(profile, 'D', $rootScope.changeWorkSpace)) {
              $state.go('notAuthorized');
            }
          },
          activeUsersList: function ($q, activeUsers) {
            var deferred = $q.defer();
            activeUsers.get(
              {
                session_id: 'active'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'all online users';
          }
        }
      })
      .state('main.user.users.add', {
        url: '/add-user',
        templateUrl: 'app/usersManagement/add/add.html',
        controller: 'UsersAddUserCtrl',
        resolve: {
          companiesForAdminUse: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order: 1,
                order_by: 'descr'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User-Mgmt - add - user";
          }
        }
      })
      .state('main.user.users.edit', {
        url: '/edit-user?{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/edit/edit.html',
        controller: 'UsersEditCtrl',
        resolve: {
          editedUser: function ($q, $stateParams, singleUser) {
            var deferred = $q.defer();
            singleUser.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companiesForAdminUse: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order: 1,
                order_by: 'descr'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User-Mgmt - edit" + (!!$stateParams.status ? " - " + $stateParams.status : "");
          }
        }
      })
      .state('main.user.users.import', {
        url: '/import-user',
        templateUrl: 'app/usersManagement/import/import.html',
        controller: 'UsersImportUserCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "User-Mgmt - import - user";
          }
        }
      })
      .state('main.user.users.setting', {
        url: '/setting',
        templateUrl: 'app/usersManagement/settings/settings.html',
        controller: 'UsersSettingCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "User-Mgmt - setting";
          }
        }
      })
      .state('main.user.users.detail', {
        url: '/{id:.+}',
        templateUrl: 'app/usersManagement/detail/detail.html',
        controller: 'UsersDetailCtrl',
        resolve: {
          profile: function ($q, $stateParams, singleUser) {
            var deferred = $q.defer();
            singleUser.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User-Mgmt - details" + (!!$stateParams.status ? " - " + $stateParams.status : "");
          }
        }
      });
  }]);
