'use strict';
/**
 * @name permissionsPanelCtrl
 * @desc Controller for Permissions Modal to select the domain as well as the operation of a certain permission
 */
function permissionsPanelCtrl($scope, permission, $uibModalInstance, permissionsClassList, availableConditions) {
  $scope.permission = permission;
  $scope.permissionsList = permissionsClassList.data;
  $scope.initialize = initialize;
  $scope.domain = availableConditions;
  for (var index in $scope.permissionsList) {
    $scope.permissionsList[index].domain = $scope.domain[0];
  }
  /**
   * fill the checkpoints if its already selected for Each operation
   */
  function initialize() {
    /**
     * coping over the managed conditions
     */
    if(!$scope.managedRole.permissionsConditions) {
      $scope.managedRole.permissionsConditions = [];
      for (var i in $scope.managedRole.permissions_conditions) {
        if (typeof $scope.managedRole.permissions_conditions[i] === 'object') {
          $scope.managedRole.permissionsConditions.push($scope.managedRole.permissions_conditions[i]);
        }
      }
      //FIXME: this is to remove all the object and reformat it, as the backend returns an irregular object (Ahmed Saleh, 20/2/2016)
      $scope.managedRole.permissions_conditions = $scope.managedRole.permissionsConditions;
    }
    /**
     * coping over the managed permission list for Update them later
     */
    if(!$scope.managedRole.permissions) {
      $scope.managedRole.permissions = [];
      for (var i in $scope.managedRole.permissions_list) {
        $scope.managedRole.permissions.push($scope.managedRole.permissions_list[i]._id);
      }
    }
    if ($scope.managedRole.permissionsConditions.length > 0) {
      for (index in $scope.managedRole.permissionsConditions) {
        /**
         * assigning the True and False value to assign the checkboxes
         */
        for (var i in $scope.permissionsList) {
          if (Object.keys($scope.managedRole.permissionsConditions[index])[0] === $scope.permissionsList[i]._id) {
            $scope.permissionsList[i].checked = true;
            /**
             * assigning the selected domain
             */
            for (var key in $scope.managedRole.permissionsConditions[index]) {
              for (var v in $scope.domain) {
                if ($scope.managedRole.permissionsConditions[index][key] === $scope.domain[v].value) {
                  $scope.permissionsList[i].domain = $scope.domain[v];
                }
              }
            }
          }
        }
      }
    }

  }

  $scope.saveSelection = function () {
    /**
     * matching the permissions so the ID of the permission is added to the new Role
     * the id will be added then the array
     */
    var tempCondition = {};
    var tempId = {};
    if (!$scope.managedRole.permissions) {
      $scope.managedRole.permissions = [];
    }
    if (!$scope.managedRole.permissionsConditions) {
      $scope.managedRole.permissionsConditions = [];
    }
    for (var index in $scope.permissionsList) {
      /**
       * if its checked it will be added
       */
      if (!!$scope.permissionsList[index].checked) {
        tempCondition = {};
        tempId = {};
        $scope.managedRole.permissions.push($scope.permissionsList[index]._id);
        tempId = $scope.permissionsList[index]._id;
        tempCondition[tempId] = $scope.permissionsList[index].domain.value;
        $scope.managedRole.permissionsConditions.push(tempCondition);
      }
      else {
        /**
         * if its Not checked, a validation is run in case if it was removed
         */
        var temp = $scope.managedRole.permissions.indexOf($scope.permissionsList[index]._id);
        if (temp > -1) {
          $scope.managedRole.permissions.splice(temp, 1);
        }

        for (var i in  $scope.managedRole.permissionsConditions) {
          if (Object.keys($scope.managedRole.permissionsConditions[i]).toString() === $scope.permissionsList[index]._id.toString()) {
            $scope.managedRole.permissionsConditions.splice(i, 1);
          }
        }

        for (var i in  $scope.managedRole.permissions_conditions) {
            if (Object.keys($scope.managedRole.permissions_conditions[i]).toString() === $scope.permissionsList[index]._id.toString()) {
              $scope.managedRole.permissions_conditions.splice(i, 1);
            }
        }

      }
    }
    $uibModalInstance.close();
  };

  $scope.close = function () {
    $uibModalInstance.close();
  };
  initialize();

}
permissionsPanelCtrl.$inject = ['$scope', 'permission', '$uibModalInstance', 'permissionsClassList', 'availableConditions'];

angular
  .module('metabuyer')
  .controller('permissionsPanelCtrl', permissionsPanelCtrl);
