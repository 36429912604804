'use strict';

function reLogInCtrl ($scope, $uibModalInstance, $uibModalStack, $auth, $state, currentStateData, email, ManageLocalStorage, $rootScope, message) {
  $scope.currentStateData = currentStateData;
  $scope.email = email;
  $scope.message = message;
  $scope.attempt = true;
  $scope.login = function () {
    $scope.error = null;
    $auth.login({email: $scope.email, password: $scope.password})
      .then(function () {
        $rootScope.reLoginDialogOpen = false;
        $uibModalInstance.close(false);
        $state.go(currentStateData.name, currentStateData.params);
      })
      .catch(function (response) {
        if (!!response && !!response.data && !!response.data.message) {
          $scope.error = response.data.message;
          $scope.errorCount = response.data.login_attempts_remaining;
        } else {
          $scope.error = 'An unexpected error has occurred. Please try again later.';
        }
      });
  };

  $scope.logout = function () {
    ManageLocalStorage.clearLocalStorage();
    $auth.logout().then(function () {
      $rootScope.reLoginDialogOpen = false;
      $uibModalStack.dismissAll();
    });
    $scope.password = '';
    $state.go('login');
  };

}

reLogInCtrl.$inject = ['$scope', '$uibModalInstance', '$uibModalStack', '$auth', '$state', 'currentStateData', 'email', 'ManageLocalStorage', '$rootScope', 'message'];

angular.module('metabuyer')
  .controller('reLogInCtrl', reLogInCtrl);
