'use strict';
/**
 * @name genericImport Directive
 * @desc Directive for generic import CSV across multiple modules
 *
 * @isolatedScope moduleName
 * To accept the module name to be displayed with button
 *
 * @isolatedScope params
 * To determine which module to generate CSV for
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */
angular
  .module('metabuyer')
  .directive('genericImport', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/genericImport/genericImport-template.html',
      scope: {
        moduleName: '@',
        params: '='
      },
      controller: function ($scope, $http, $uibModal, $filter, $state) {

        $scope.importCSVModal = importCSVModal;

        /**
         * @function importCSV
         * This function is to import CSV with parameters
         *
         */
        function importCSVModal() {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/genericImport/genericImport-modal.html',
            backdrop: 'static',
            keyboard: false,
            scope: $scope,
            controller: function ($scope, globalFunc, pathConstants, toastr, $uibModalInstance) {
              $scope.close = close;
              $scope.importCSV = importCSV;
              $scope.disableImportButton = false;
              var maxErrorMessage = 5;

              $scope.downloadErrorMessage = [
                'Approval Template', 'Position', 'Position Assignment'
              ];

              function close() {
                $uibModalInstance.close();
              }

              /**
               * Function to handle the many import error messages and formats
               * @param errorData
               */
              function importErrorMessages(errorData) {
                // for display purpose
                var errorText = '';
                // for text that automatically downloaded
                var errorFile = '';
                if (!!errorData) {
                  if (typeof errorData !== 'string') {
                    _.forEach(errorData, function (errorMessage, key) {
                      if (typeof errorMessage !== 'string') {
                        if (_.isArray(errorMessage)) {
                          _.forEach(errorMessage, function (errorArray) {
                            errorText += 'Error in row ' + key + ': ' + errorArray + '<br>';
                          })
                        }
                        else {
                          errorText += 'Error in row ' + key + ': ' + errorMessage + '<br>';
                        }
                      }
                      // Ignore the error if it is 'Successfully imported'
                      else if (errorMessage !== 'Successfully imported') {
                        if ($scope.downloadErrorMessage.indexOf($scope.moduleName) > -1) {
                          if (key < maxErrorMessage) {
                            errorText += errorMessage + '<br>';
                          }
                          else if (key === maxErrorMessage) {
                            errorText += '+' + (errorData.length - maxErrorMessage) + ' more error(s)' + '<br>';
                          }
                          errorFile += errorMessage + '\r\n';
                        } else {
                          errorText += errorMessage + '<br>';
                        }
                      }
                    });

                  } else {
                    errorText = errorData;
                  }
                }

                if ($scope.downloadErrorMessage.indexOf($scope.moduleName) > -1 && errorData.length > 0) {
                  downloadErrorFile(errorFile);
                }

                swal({
                  title: 'Import Failed',
                  text: errorText,
                  type: 'warning',
                  html: true,
                  confirmButtonColor: '#1ab394',
                  confirmButtonText: 'Close',
                  closeOnConfirm: true
                });
              }

              function downloadErrorFile(data) {
                var blob = new Blob([data]);
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Error Message-' + $filter('date')(new Date(), 'dd-MMM-yyyy') +'.txt';
                link.click();
              }

              function importCSV(csvFile) {
                $scope.disableImportButton = true;
                var fd = new FormData();

                /**
                 * Check if file is selected in the form
                 */
                if (!!csvFile) {
                  fd.append('file', csvFile);
                } else {
                  $scope.disableImportButton = false;
                  toastr.error('Please select a file first');
                  return false;
                }

                if (!!$scope.params) {
                  var paramIteration;
                  for (paramIteration in $scope.params) {
                    if ($scope.params.hasOwnProperty(paramIteration)) {
                      fd.append(paramIteration, $scope.params[paramIteration]);
                    }
                  }
                }

                $http.post(
                  $filter('format')(pathConstants.apiUrls.imports.now), fd, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined}
                  }
                )
                  .then(function onSuccess(resource) {
                    if (!!resource.data.content) {
                      if (!!resource.data.content.message)
                        toastr.success(resource.data.content.message);
                      else
                        toastr.success($scope.moduleName + ' successfully uploaded');

                      $state.reload();
                    }
                    $uibModalInstance.close();
                  })
                  .catch(function onError(error) {
                    if (!!error.data && !!error.data.content && !!error.data.content.error) {
                      if (_.isString(error.data.content.error) && !_.isString(error.data.content.message)) {
                        if (error.data.content.error !== 'The import was partially successful') {
                          toastr.error(error.data.content.error);
                        }
                        else {
                          toastr.error('Failed to upload CSV file');
                        }
                        importErrorMessages(error.data.content.message);
                      } else if (!_.isString(error.data.content.error)) {
                        importErrorMessages(error.data.content.error);
                      } else {
                        toastr.error('Failed to upload CSV file');
                      }
                    }
                    else {
                      toastr.error('Failed to upload CSV file');
                    }
                    $scope.disableImportButton = false;
                  });
              }
            }
          });
        }
      }
    }
  });
