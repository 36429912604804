'use strict';
/**
 * @name passwordResetModal Directive
 * @desc Directive for modal to change passwords
 * data is for profile data
 * oldPasswordField is to determine modal to have old password field
 */
angular
  .module('metabuyer')
  .directive('passwordResetModal',function($uibModal) {
    return {
      restrict: 'A',
      scope: {
        data: '=',
        oldPasswordField: '='
      },
      link: function ($scope, element) {

        var panel = function () {
          $uibModal.open({
            templateUrl: 'components/passwordResetModal/passwordResetModalTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'passwordResetModalCtrl',
            scope: $scope,
            resolve: {
              profile: function(){
                return $scope.data;
              }
            }
          });
        };

        //loading the panel
        $scope.showPasswordModal = function () {
          panel();
        };

        element.bind("click", function(){
          $scope.showPasswordModal();
        });

      }
    }
  });
