'use strict';

function supplierVersionsController(
  $scope, supplierVersions, $uibModalInstance, $uibModal, supplierStatus, versionType
) {
  $scope.versions = supplierVersions;
  $scope.checkedVersion = [];
  $scope.currentStatus = supplierStatus;
  $scope.versionType = versionType;

  $scope.closeModal = closeModal;
  $scope.selectVersion = selectVersion;
  $scope.compareVersions = compareVersions;

  function selectVersion(supplierId, checked) {
    if (!!checked)
      $scope.checkedVersion.push(supplierId);
    else {
      var valIndex = $scope.checkedVersion.indexOf(supplierId);
      if (valIndex > -1)
        $scope.checkedVersion.splice(valIndex, 1);
    }
  }

  function compareVersions() {
    closeModal();
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/comparison.html',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      resolve: {
        versions: function() {
          return $scope.versions;
        },
        selectedVersion: function() {
          return $scope.checkedVersion;
        },
        currentStatus: function() {
          return $scope.currentStatus;
        },
        versionType: function() {
          return $scope.versionType;
        }
      },
      controller: 'compareSupplierVersionCtrl'
    });
  }

  function closeModal() {
    $uibModalInstance.close();
  }
}

supplierVersionsController.$inject = [
  '$scope', 'supplierVersions', '$uibModalInstance', '$uibModal', 'supplierStatus', 'versionType'
];

angular.module('metabuyer')
  .controller('supplierVersionsController', supplierVersionsController);
