'use strict';

/**
 * @name itemListing Directive
 * @desc item listing that is used in the budget
 *
 * @isolatedScope data
 * To synchronize items data
 *
 * @isolatedScope budget
 * To accept budget id that using this directive
 *
 * @isolatedScope assignment
 * To accept budget assignment id that using this directive, optional
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('itemListing', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budget/itemListing/itemListing.html',
      scope: {
        budget: '=',
        assignment: '='
      },
      controller: function($scope, globalFunc, toastr, budgets) {
        $scope.budgets = budgets;
        $scope.itemData = [];
        $scope.selectedTab = 'used';
        $scope.itemMeta = {};

        $scope.selectTab = selectTab;
        $scope.getItemListing = getItemListing;

        // called when switching type of items
        function selectTab(tab) {
          $scope.selectedTab = tab;
          getItemListing();
        }

        // retrieve items data by type
        function getItemListing() {
          $scope.embeddedParams = {
            id: $scope.budget,
            assignment_id: $scope.assignment,
            item_type: $scope.selectedTab,
            action: 'get-items',
            offset: 10,
            page: null,
            cursor: null
          };

          budgets.get(
            $scope.embeddedParams,
            function (resource) {
              if (!!resource.content && !!resource.content.data) {
                $scope.itemData = resource.content.data;
                $scope.itemMeta = resource.content.meta;
              }
            },
            function (error) {
              if (!!error.data.content && !!error.data.content.message) {
                toastr.error(error.data.content.message.join('</br>'));
              } else {
                globalFunc.objectErrorMessage(error);
              }
            }
          );
        }

        function initialize() {
          getItemListing();
        }

        initialize();
      }
    };
  });
