'use strict';

/**
 * Budget management states
 * @description
 * Contains the states in budget and handles the resolve for data
 *
 * @states main.user.budget.manage
 * State for budget listing
 *
 * @states main.user.budget.details
 * State for budget details by id
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @author Khor Jin Ee <jinee585@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.budget', {
        url: '/budgetManagement',
        controller: 'budgetManagementCtrl',
        templateUrl: 'app/budgetManagement/budgetManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, budgetFunctions, globalFunc) {
            // Initialize budget setting for company to false
            budgetFunctions.setHasBudgetSettingOn(false);
            // Set true if tenant super admin
            if(!!profile && !!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN')) {
              budgetFunctions.setHasBudgetSettingOn(true);
            }
            _.forEach(profile.companies, function(company) {
              if (!!company.budget_setting.enable) {
                budgetFunctions.setHasBudgetSettingOn(true);
                return false;
              }
            });

            if (!UserPermissions.checkPermissionsAccess(profile, 'L', 'Budget') ||
              !UserPermissions.checkPermissionsAccess(profile, 'O', 'Budget')) {
              $state.go('notAuthorized');
            } else if (!budgetFunctions.getHasBudgetSettingOn()) {
              $state.go('unauthorizedBudgetAccess');
            }
          }
        }
      })
      .state('main.user.budget.manage', {
        templateUrl: 'app/budgetManagement/manage/manage.html',
        url: '/manage?&{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&' +
        '{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'budgetListCtrl',
        resolve: {
          dataList: function ($q, $stateParams, searchModule, budgetFunctions) {
            var deferred = $q.defer();
            var status = budgetFunctions.getBudgetStatus($stateParams.status);

            var params = {
              module: 'budgets',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'updated_at',
              order: !!$stateParams.order ? $stateParams.order: 0
            };

            /**
             * searching
             */
            if(!!$stateParams.query) {
              /**
               * searching with a specific filter
               */
              if (!!$stateParams.filter) {
                params['criteria_operator'] = 'and';
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else if ($stateParams.type === 'double') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * searching all filters
               */
              else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][descr]'] = $stateParams.query;
                params['criteria[1][company|descr]'] = $stateParams.query;
                params['criteria[1][cost_center|descr]'] = $stateParams.query;
                params['criteria[1][creator.display_name]'] = $stateParams.query;
                params['criteria[1][updater.display_name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
                params['criteria_operator'] = 'and';
              }
            }

            if (status === undefined) {
              params['criteria[0][status][]'] = [0, 1, 2, 3, 4, 5, 6, 7, 8]; // Every status except revision (9)
            }
            else {
              params['criteria[0][status]'] = status;
            }

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
                deferred.resolve({data: []});
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Budget - Listing' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
      .state('main.user.budget.details', {
        templateUrl: 'app/budgetManagement/details/details.html',
        url: '/details/{id:[a-zA-Z0-9]+}',
        controller: 'budgetDetailsCtrl',
        resolve: {
          budgetData: function ($q, budgets, $stateParams, globalFunc, toastr, $location) {
            var deferred = $q.defer();
            budgets.get(
              {id: $stateParams.id},
              function (resource) {
                if (!!resource.content && !!resource.content.data) {
                  // if the budget with revision status, redirect to parent budget
                  if (!!resource.content.data.status && resource.content.data.status === 'revision' &&
                    !!resource.content.data.parent_id
                  ) {
                    $location.path('/user/budgetManagement/details/' + resource.content.data.parent_id).search('').replace();
                    $state.reload();
                  }
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Budget - Details';
          }
        }
      })
  }]);
