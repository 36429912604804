'use strict';
/**
 * @name requisitionsManageCtrl
 *
 * @param $scope
 * @param dataList
 * @param status
 * @param purchaseRequisitionsServices
 * @param $rootScope
 * @param globalFunc
 * @param $state
 * @param $stateParams
 * @param HighlightId
 * @param $window
 * @param $filter
 * @param toastr
 * @param prFunction
 */
function requisitionsManageCtrl(
  $scope, dataList, status, purchaseRequisitionsServices, $rootScope, globalFunc, $state, $stateParams, HighlightId,
  $window, $filter, toastr, prFunction, Approvals, $q
) {

  /**
   * return true if show details is clicked
   * @param id
   * @returns boolean
   */
  function showDetails(id) {
    return (id === $scope.selectedDetail) ? true : false;
  }

  /**
   * set value of selectedDetail
   * @param id
   * @param option (set to show/hide details)
   */
  function setShowDetailsId(id, option) {
    if (option === 'show') {
      _.forEach($scope.dataList, function (item) {
        if (item['_id'] === id) {
          $scope.PR = item;
        }
      });
      $scope.selectedDetail = id;
      setPRItem();
      loadApprovalFlow();
      checkIfPRIsEditable();
    }
    if (option === 'hide')
      $scope.selectedDetail = null;
  }

  /**
   * function returns checker for the states to hide or show the PR details
   * @returns {*|boolean}
   */
  function isShoppingMode() {
    $scope.PR = prFunction.getPRData();
    return $state.includes('main.user.purchaseRequisitions.details.catalog');
  }

  function getEditingPRState() {
    return prFunction.getEditingState();
  }

  /**
   * this function will check the state of the PR
   * as well as the Type of the logged in user
   */
  function checkIfPRIsEditable() {
    prFunction.setEditableState(($scope.PR.status === 'draft') ? 'editable' : 'notEditable');
    if ($rootScope.currentUser._id !== $scope.PR.creator._id)
      prFunction.setEditableState('notEditable');
  }

  function loadApprovalFlow() {
    if ($scope.PR.status !== 'draft') {
      Approvals.get({
        id: $scope.PR.approval_id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          prFunction.setApprovalFlow(resource.content.data);
        }
      });
    }
  }

  function setPRItem() {
    var deferred = $q.defer();
    purchaseRequisitionsServices.getSinglePR(
      {
        id: $scope.PR._id
      }, function (resource) {
        if (!!resource.content) {
          deferred.resolve(resource.content);
          prFunction.initializePRData(resource.content.data);
        } else {
          deferred.resolve([]);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }


  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * function to navigate between the sub tabs
   * @param tab
   */
  function tabSelection(tab) {
    switch (tab) {
      case 'all': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitions.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: null,
          extraFilterValue: null,
          cursor: null
        });
        break;
      }
      case 'yours': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitions.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[creator|_id][][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        });
        break;
      }
      case 'shared': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitions.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[collaborators_ids][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        });
        break;
      }
      case 'waiting': {
        $scope.selectedListTab = tab;
        $state.go('main.user.purchaseRequisitions.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        });
        break
      }
    }
  }

  /**
   * return if the user is part of the waiting on, for a specific PR
   * @param requisition
   * @returns {*}
   */
  function checkWaitingOnUser(requisition) {
    if(requisition.status === 'pending')
      return globalFunc.findInArray(requisition.waiting_on, '_id', $rootScope.currentUser._id);
    else
      return false;
  }


  /**
   * returns store the selected PRs
   * @returns {Array}
   */
  function getSelectedRequisitions() {
    var selectedPRs = [];
    _.forEach($scope.dataList, function (pr) {
      if (!!pr.selected) {
        selectedPRs.push(pr);
      }
    });
    return selectedPRs;
  }

  function starringPR(pr, isStarred) {
    prFunction.requisitionStarring(pr, !isStarred).$promise
      .then(function () {
        if (isStarred)
          pr.starred_users = _.pull(pr.starred_users, $rootScope.currentUser._id);
        else {
          pr.starred_users = [];
          pr.starred_users.push($rootScope.currentUser._id);
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function deletePR(prID) {
    swal({
        title: 'Confirm delete this Draft Purchase Requisition?',
        text: 'This Draft Purchase Requisition will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          purchaseRequisitionsServices.deleteSinglePR(
            {
              id: prID
            },
            function () {
              toastr.success('Draft Purchase Requisition deleted');
              $state.reload();
            }, function () {
              toastr.error('This Draft PR cannot be deleted.');
            }
          );
        }
      });
  }

  function checkStarred(pr) {
    if(!!pr.starred_users && pr.starred_users.length > 0) {
      return (pr.starred_users.indexOf($rootScope.currentUser._id) > -1);
    }
  }


  /**
   *  auto select/deselect the items for approval action
   * @param status
   */
  function multiSelectPendingItems (status) {
    _.forEach($scope.dataList, function (requisition) {
      if (checkWaitingOnUser(requisition)) {
        requisition.selected = status;
      }
    });
  }

  $scope.deletePR                     = deletePR;
  $scope.showColumn                   = showColumn;
  $scope.tabSelection                 = tabSelection;
  $scope.starringPR                   = starringPR;
  $scope.checkStarred                 = checkStarred;
  $scope.checkWaitingOnUser           = checkWaitingOnUser;
  $scope.getSelectedRequisitions      = getSelectedRequisitions;
  $scope.multiSelectPendingItems      = multiSelectPendingItems;
  $scope.showDetails                  = showDetails;
  $scope.setShowDetailsId             = setShowDetailsId;
  $scope.isShoppingMode               = isShoppingMode;
  $scope.getEditingPRState            = getEditingPRState;


  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.status = status;
  $scope.loadingPRMainParams = {
    module: 'PR',
    offset: 20,
    'criteria[status]': $scope.status,
    cursor: null
  };
  /**
   * to be used in the HTML validation
   */
  $scope.currentUser                  = $rootScope.currentUser;
  $scope.storageKey                   = 'purchase-requisitions-management-selected-columns';
  $scope.selectedDetail               = '';
  $scope.PR                           = [];

  function initialize(){
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    $scope.selectedListTab = $stateParams.tab;
    if(!$scope.selectedListTab){
      $scope.selectedListTab = 'all';
    }
    /**
     * Id highlight
     */
    $scope.highlightId = HighlightId.getId();
    if(!!$scope.highlightId) {
      //scroll the window to top
      $window.scroll(0, 0);
      //clear current id
      HighlightId.setId(null);
    }
  }

  initialize();

}

requisitionsManageCtrl.$inject = [
  '$scope', 'dataList', 'status', 'purchaseRequisitionsServices', '$rootScope', 'globalFunc', '$state', '$stateParams',
  'HighlightId', '$window', '$filter', 'toastr', 'prFunction', 'Approvals', '$q'
];

angular
  .module('metabuyer')
  .controller('requisitionsManageCtrl', requisitionsManageCtrl);
