'use strict';

angular.module('metabuyer.services.attachments', ['ngResource'])
  .factory('Attachments', function ($resource, pathConstants) {
    var Attachments = $resource(
      pathConstants.apiUrls.attachments.listPRAttachment,
      {
        pr_id: '@pr_id'
      },
      {
        'get': {
          method: 'GET'
        }, //get attachment list
        'submit': {
          method: 'POST'
        },
        'getListPRAttachment': {
          method: 'GET',
          params: {
            pr_id: '@pr_id'
          },
          url: pathConstants.apiUrls.attachments.listPRAttachment
        },
        'getListPOAttachment': {
          method: 'GET',
          params: {
            po_id: '@po_id'
          },
          url: pathConstants.apiUrls.po.listAttachment
        },
        'getListSupplierAttachment': {
          method: 'GET',
          params: {
            po_id: '@po_id'
          },
          url: pathConstants.apiUrls.suppliers.listAttachment
        }
      }
    );
    return Attachments;
  })
  .factory('attachmentsByReference',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.reference,
        {
          referenceId: '@referenceId'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'}
        }
      );
    })
  .factory('singleAttachment',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.single,
        {
          attachmentId: '@attachmentId'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'delete': {method: 'DELETE'}
        }
      );
    })
  // TODO: Refactor PO side for attachments (Justin 7/9/17)
  .factory('AttachmentSingle', function ($resource, pathConstants) {
    var AttachmentSingle = $resource(
      pathConstants.apiUrls.attachments.single,
      {
        pr_id: '@pr_id',
        attachment_id: '@attachment_id'
      },
      {
        'get': {
          method: 'GET'
        }, //get single attachment detail
        'update': {
          method: 'PUT'
        },
        'getSinglePOAttachment': {
          method: 'GET',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.po.singleAttachment
        },
        'updateSinglePOAttachment': {
          method: 'PUT',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.po.singleAttachment
        },
        'deleteSinglePOAttachment': {
          method: 'DELETE',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.po.singleAttachment
        },
        'getSingleSupplierAttachment': {
          method: 'GET',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.suppliers.singleAttachment
        },
        'updateSingleSupplierAttachment': {
          method: 'PUT',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.suppliers.singleAttachment
        },
        'deleteSingleSupplierAttachment': {
          method: 'DELETE',
          params: {
            po_id: '@po_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.suppliers.singleAttachment
        }
      }
    );
    return AttachmentSingle;
  })
  .factory('PRNewAttachments', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.attachments.listPRNewAttachment,
      {
        pr_id: '@pr_id'
      },
      {
        'getListPRAttachment': {
          method: 'GET'
        }, //get attachment list
        'post': {
          method: 'POST'
        },
        'get': {
          method: 'GET',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.attachments.singlePRNewAttachment
        },
        'delete': {
          method: 'DELETE',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.attachments.singlePRNewAttachment
        },
        'put': {
          method: 'PUT',
          params: {
            pr_id: '@pr_id',
            attachment_id: '@attachment_id'
          },
          url: pathConstants.apiUrls.attachments.singlePRNewAttachment
        }
      }
    );
  });
