'use strict';

function ItemsManageCtrl($scope, $state, $q, $stateParams, toastr, items, deleteItems, action,
                         globalFunc, $rootScope, HighlightId, $window, $filter) {

  $scope.showColumn              = showColumn;
  $scope.getImportStatusName     = getImportStatusName;
  $scope.checkWaitingOnUser      = checkWaitingOnUser;
  $scope.getSelectedItems        = getSelectedItems;
  $scope.viewCatalog             = viewCatalog;
  $scope.multiSelectPendingItems = multiSelectPendingItems;

  $scope.storageKey = 'catalog-management-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }


  /**
   *  auto select/deselect the items for approval action
   * @param status
   */
  function multiSelectPendingItems (status) {
    _.forEach($scope.items, function (item) {
      if (checkWaitingOnUser(item)) {
        item.selected = status;
      }
    });
  }


  /**
   * returns the status name
   * @param status
   * @returns {*}
   */
  function getImportStatusName(status) {
    /**
     Compose = 0
     Pending = 1
     Approved = 2
     Deleted = 3
     Expired = 4
     Rejected = 5
     Active = 6
     Deactivated = 7
     Error = 8
     */
    if (status === 0) {
      return 'Compose'
    }
    if (status === 1) {
      return 'Pending'
    }
    if (status === 2) {
      return 'Approved'
    }
    if (status === 3) {
      return 'Deleted'
    }
    if (status === 4) {
      return 'Expired'
    }
    if (status === 5) {
      return 'Rejected'
    }
    if (status === 6) {
      return 'Active'
    }
    if (status === 7) {
      return 'Inactive'
    }
    if (status === 8) {
      return 'Error'
    }
    return 'N/A';
  }

  /**
   * return if the user is part of the waiting on, for a specific Supplier
   * @param item
   * @returns {*}
   */
  function checkWaitingOnUser(item) {
    return globalFunc.findInArray(item.waiting_on, '_id', $rootScope.currentUser._id);
  }

  /**
   * returns store the selected Suppliers
   * @returns {Array}
   */
  function getSelectedItems() {
    var selectedItems = [];
    _.forEach($scope.items, function (item) {
      if (!!item.selected) {
        selectedItems.push(item);
      }
    });
    return selectedItems;
  }


  function viewCatalog(catalog){
    /**
     * If import status is 8/ error, redirect to select file for import and pass catalog object
     */
    if(catalog.import_status === 8){
      $state.go('main.user.items.import.select_file',
        {
          importID : catalog._id,
          catalogGroupID : catalog.catalog_group[0]._id,
          supplierID : catalog.supplier[0]._id,
          catalogCodeID : catalog.catalog_code[0]._id
        });
    }
    else{
      $state.go('main.user.items.detail', ({id: catalog._id}));
    }
  }

  // Put everything here to run it on first load
  function initialize() {

    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;
    $scope.title = $stateParams.title;
    $scope.items = items.data;
    $scope.meta = items.meta;
    $scope.itemManageService = items;

    $scope.actionType = action;
    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    //TODO: remove this once the states Search and Manage are combined (Ahmed Saleh, 4/10/2016)
    $rootScope.$broadcast('activateTab', $scope.$parent.tabData[0]);

    if (!!HighlightId.getId() && HighlightId.getId() != undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }
  }

  // Run initialize function
  initialize();

}

ItemsManageCtrl.$inject = ['$scope', '$state', '$q', '$stateParams', 'toastr', 'items',
                           'deleteItems', 'action', 'globalFunc', '$rootScope', 'HighlightId',
                           '$window', '$filter'];

angular.module('metabuyer')
  .controller('ItemsManageCtrl', ItemsManageCtrl);
