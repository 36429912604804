'use strict';

angular.module('metabuyer')
  .controller('LoginCtrl', function ($scope, $auth, $location, $rootScope, ManageLocalStorage, $state) {

    $scope.isLoginClicked = false;

    $scope.login = function() {
      $scope.isLoginClicked = true;
      $scope.error = null;
      $scope.errorCount = null;

      $auth.login({ email: $scope.email, password: $scope.password })
        .then(function() {

          /**
           * verify the cached data
           */
          var cachedEmail = window.localStorage.getItem('email');
          if (!cachedEmail || cachedEmail !== $scope.email) {
            ManageLocalStorage.clearLocalStorage('All');
          }else {
            ManageLocalStorage.clearLocalStorage();
          }

          window.localStorage.setItem('email', $scope.email);
          if (!!$rootScope.redirectUrl) {
            var tempUrl  = angular.copy($rootScope.redirectUrl);
            $rootScope.redirectUrl = null;
            $location.url(tempUrl);
          }
        })
        .catch(function(response) {
          $scope.isLoginClicked = false;
          if (response && response.data && response.data.message) {
            $scope.error = response.data.message;
            $scope.errorCount = response.data.login_attempts_remaining;
          } else if (response.data === null) {
            $scope.error = 'An unexpected error has occurred. Please try again later.';
          }
        });
    };

  });
