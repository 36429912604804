'use strict';
/**
 * @name MainCtrl
 * @desc The main controller in which the the main panels are loaded NOT including the user information
 */
function MainCtrl($scope, $state, $auth, userSettings, pathConstants, $rootScope, budgetFunctions, globalFunc, $http) {

  $rootScope.imageBasePath = pathConstants.apiUrls.image.pr;

  /**
   * Setting the date picker input's timezone to be in UTC.
   * To be passed to ng-model options
   * Example: ng-model-options = "datepickerTimezone"
   * @type {{timezone: string}}
   */
  $rootScope.datePickerTimezone = {timezone: 'UTC'};

  $scope.logout = logout;
  $scope.navigateSideBar = navigateSideBar;
  $scope.activateSidePanel = activateSidePanel;
  $scope.toggleSearchBar = toggleSearchBar;
  $scope.devInfo = '';
  $scope.isDevEnvironment = '';

  function getTemplateUrl(isMobileBrowser) {
    switch (!!isMobileBrowser) {
      case true:
          $scope.navbarTemplateUrl = 'components/navbar/navbar-mobile.html';
          $scope.sidebarTemplateUrl = 'components/sidebar/sidebar-mobile.html';
          $scope.mobileClass = 'remove-padding-main';
        break;
      case false :
        $scope.navbarTemplateUrl = 'components/navbar/navbar.html';
        $scope.sidebarTemplateUrl = 'components/sidebar/sidebar.html';
        break;
    }
  }

  function logout() {

    swal({
        title: 'Log out',
        text: 'Are you sure you want to log out?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (!!isConfirm) {
          userSettings.loggedOut();
          $auth.logout();
          $state.go('login');
        }
      }
    );
  }

  /**
   * The sidebar button
   * @param link
   */
  function navigateSideBar(link) {
    $state.go(link.key, {
      status: link.status,
      tab: link.tab,
      order_by: link.order_by,
      order: link.order,
      extraFilterKey: link.extraFilterKey,
      extraFilterValue: link.extraFilterValue
    });
  }

  /**
   * Activates the sidebar styling
   * @param option
   * @return {boolean}
   */
  function activateSidePanel (option) {
    return ($state.includes('main.user.purchaseRequisitions') && option.key.indexOf('main.user.purchaseRequisitions') > -1) ||
      ($state.includes('main.user.orders') && option.key.indexOf('main.user.orders') > -1) ||
      ($state.includes('main.user.mainMenu.waiting-on-you') && option.key.indexOf('main.user.mainMenu.waiting-on-you') > -1
      && !!$rootScope.isMobileBrowser) ||
      ($state.includes('main.user.mainMenu.notifications') && option.key.indexOf('main.user.mainMenu.notifications') > -1
      && !!$rootScope.isMobileBrowser) ||
        /**
         * work around to avoid duplicating the name check
         * as .includes interprets .suppliersList similar to .suppliers
         */
      ($state.current.name.indexOf('main.user.suppliers.') > -1 && option.key.indexOf('main.user.suppliers.') > -1) ||
      ($state.includes('main.user.suppliersList') && option.key.indexOf('main.user.suppliersList') > -1) ||
      ($state.includes('main.user.users') && option.key.indexOf('main.user.users') > -1) ||
      ($state.includes('main.user.items') && option.key.indexOf('main.user.items') > -1) ||
      ($state.includes('main.user.companiesMgmt') && option.key.indexOf('main.user.companiesMgmt') > -1) ||
      ($state.includes('main.user.auditTrail') && option.key.indexOf('main.user.auditTrail') > -1) ||
      ($state.includes('main.user.security') && option.key.indexOf('main.user.security') > -1) ||
      ($state.includes('main.user.reports') && option.key.indexOf('main.user.reports') > -1) ||
      ($state.includes('main.user.exports') && option.key.indexOf('main.user.exports') > -1) ||
      ($state.includes('main.user.settings') && option.key.indexOf('main.user.settings') > -1) ||
      ($state.includes('main.user.approvalFlowManagement') && option.key.indexOf('main.user.approvalFlowManagement') > -1) ||
      ($state.includes('main.user.positionManagement') && option.key.indexOf('main.user.positionManagement') > -1) ||
      ($state.includes('main.user.masterData') && option.key.indexOf('main.user.masterData') > -1) ||
      ($state.includes('main.user.catalogView.catalogItemList') && option.key.indexOf('main.user.catalogView.catalogItemList') > -1);
  }

  function toggleSearchBar() {
    $scope.displaySearchBar = !$scope.displaySearchBar;
  }

  $scope.$on('pageTitleChanged', function (event, data) {
    $scope.pageTitle = data;
    if(!!$rootScope.isMobileBrowser) {
      if ((!!$state.current.name && $state.current.name.indexOf('purchaseRequisitions') > -1) ||
        (!!$state.current.name && $state.current.name.indexOf('orders') > -1)) {
        $scope.enableMobileSearching = true;
      }
      else {
        $scope.enableMobileSearching = false;
      }
    }
  });

  $scope.$on('actionsChanged', function (event, data) {
    $scope.actions = data;
  });

  function initialize () {
    // Display dev string
    if (!!pathConstants.RollbarConfiguration)  {
      var environment = pathConstants.RollbarConfiguration.environment;
      $scope.isDevEnvironment = environment === 'local' || environment === 'development';

      if (!!$scope.isDevEnvironment && $scope.devInfo === '') {
        $http.get(pathConstants.apiUrls.devInfo)
          .then(function (response) {
            if(typeof response.data === 'string') {
              $scope.devInfo = response.data;
            }
          });
      }
    }

    // Initialize budget setting for company to false
    budgetFunctions.setHasBudgetSettingOn(false);
    // Set true if tenant super admin
    if(!!$rootScope.currentUser && !!globalFunc.findInArray($rootScope.currentUser.roles, 'name', 'TENANT_SUPER_ADMIN')) {
      budgetFunctions.setHasBudgetSettingOn(true);
    }
    _.forEach($rootScope.currentUser.companies, function(company) {
      if (!!company.budget_setting.enable) {
        budgetFunctions.setHasBudgetSettingOn(true);
        return false;
      }
    });

    getTemplateUrl($rootScope.isMobileBrowser);
    //Hide sidebar by default if is mobile browser
    if(!!$rootScope.isMobileBrowser) {
      $(document).ready(function(){
        $('body').removeClass('mini-navbar mobile-navbar');
        $('body').addClass('mobile');
      });

      if ((!!$state.current.name && $state.current.name.indexOf('purchaseRequisitions') > -1) ||
        (!!$state.current.name && $state.current.name.indexOf('orders') > -1)) {
        $scope.enableMobileSearching = true;
      }
      else {
        $scope.enableMobileSearching = false;
      }

      $scope.waitingOnYouLink = {
        key : 'main.user.mainMenu.waiting-on-you'
      };
      $scope.notificationsLink = {
        key : 'main.user.mainMenu.notifications'
      };
      $scope.userProfileLink = {
        key : 'main.user.mainMenu.userProfile'
      };
    }
  }

  initialize();
}

MainCtrl.$inject = [
  '$scope', '$state', '$auth', 'userSettings', 'pathConstants', '$rootScope', 'budgetFunctions', 'globalFunc', '$http'
];

angular.module('metabuyer')
  .controller('MainCtrl', MainCtrl);
