'use strict';
/**
 * @name MasterDataManageUOMCtrl
 * @desc Controller for master data UOM controller
 */
function MasterDataManageUOMCtrl($scope, $stateParams, uoms, uomList, $location, toastr, singleUom,
                                 $state, $uibModal, globalFunc,pathConstants) {
  $scope.dataList = uomList.data;
  $scope.meta = uomList.meta;
  $scope.uomService = uoms;

  $scope.search = search;
  $scope.newUom = newUom;
  $scope.uomDetails = uomDetails;
  $scope.importUOM = importUOM;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.importLink = pathConstants.apiUrls.uom.import;
  $scope.params = {};
  $scope.showColumn = showColumn;
  $scope.columns = [

        {id: 'code', label: 'Code'},
        {id: 'descr', label: 'Description'},
        {id: 'fraction', label: 'Fraction Enabled'},
        {id: 'updated_at', label: 'Updated At'},
        {id: 'updater.display_name', label: 'Updated By'},
        {id: 'created_at', label: 'Created At'},
        {id: 'creator.display_name', label: 'Created By'},
        {id: 'is_active', label: 'Status'}
      ];

  $scope.storageKey = 'master-data-uom-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }


  $scope.uomFraction = [
    {
      status: 'Allow',
      value: 1
    },
    {
      status: 'Disallow',
      value: 0
    }
  ];

  function initialize(){
    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }
  }

  function search(query) {
    $location.search({query: query});
  }

  function importUOM() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'UOM';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function validate(uom){
    if (!uom){
      return false;
    }
    if (!uom.code){
      return false;
    }
    if (!uom.descr){
      return false;
    }
    if (!uom.is_fraction){
      return false;
    }
    return true;
  }

  function newUom() {

    $scope.submitted = true;
    if (validate($scope.uom)) {
      singleUom.post(
        {},
        {
          descr: $scope.uom.descr,
          code: $scope.uom.code,
          is_fraction: $scope.uom.is_fraction.value,
          is_active: 1
        },
        function (resource) {
          toastr.success('UOM was added successfully');
          if (!!resource.content && !!resource.content.data) {
            var addedUOM = resource.content.data;
            $scope.dataList.unshift({
              _id: addedUOM._id,
              code: addedUOM.code,
              descr: addedUOM.descr,
              is_fraction: addedUOM.is_fraction,
              created_at: addedUOM.created_at,
              updated_at: addedUOM.updated_at,
              updater: [$scope.user],
              creator: [$scope.user],
              is_active: 1
            });
          }
          $scope.uom.code = '';
          $scope.uom.descr = '';
          $scope.uom.is_fraction = null;
        },
        function (err) {
          globalFunc.objectErrorMessage(err);
        }
      )
    }
    else{
      toastr.error('Please fill all the required fields');
    }
  }

  function uomDetails(index) {
    var uomId = $scope.dataList[index]._id;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/uom/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'uomDetailsCtrl',
      size: 'lg',
      resolve: {
        uomDetails: function($q, singleUom) {
          var deferred = $q.defer();
          singleUom.get(
            {
              id: uomId
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.is_fraction = newValue.is_fraction;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updated_by;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });
  }

  initialize();
}

MasterDataManageUOMCtrl.$inject = [
  '$scope', '$stateParams', 'uoms', 'uomList', '$location', 'toastr', 'singleUom', '$state',
  '$uibModal', 'globalFunc', 'pathConstants'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageUOMCtrl', MasterDataManageUOMCtrl);
