'use strict';
/**
 * @name addCompanyCtrl
 * @desc Controller for add companies
 */
function addCompanyCtrl(
  $scope, $q, $resource, $state, pathConstants, Companies, toastr, currenciesList, globalFunc, BU, industryList, addressList,
  $http, searchModule, $rootScope, companyGlobalFunc, expenseTypesList
) {

  $scope.BU = BU.data;
  $scope.industries = industryList.data;
  $scope.submitted = false;
  $scope.company = {};
  $scope.corresponding_address_id = '';
  $scope.corresponding_address = {};
  $scope.selectedCurrencies = [];
  $scope.response_error = {};
  $scope.parentCompanies = [];
  $scope.uploader = {};
  $scope.companyCurrencies = currenciesList;
  $scope.selectedCompanyCurrencies = [];
  $scope.selectedExpenseTypes = [];
  $scope.selectedPreferredCurrency = {};
  $scope.expenseTypes = expenseTypesList;
  $scope.extraSettingCurrencies = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select currency'
  };
  $scope.extraSettingExpenseTypes = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select expense type'
  };
  $scope.extraSettingPreferredCurrencies = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    enableSearch: true,
    selectionLimit: 1
  };
  $scope.options = {
    parent_co: [],
    add_fields: []
  };
  $scope._ = {
    isEmpty: _.isEmpty
  };
  $scope.duplicateAddress = {
    value: false
  };
  $scope.selected_options = {
    parent_co: {},
    add_fields: []
  };
  $scope.defaultAddFieldButtonText = {
    buttonDefaultText: "Add Field"
  };
  $scope.POInitialStatuses = companyGlobalFunc.getPOInitialStatuses();

  $scope.onSelectedBU = onSelectedBU;
  $scope.onSelectedIndustry = onSelectedIndustry;
  $scope.saveCorrespondingAddress = saveCorrespondingAddress;
  $scope.addNewCompany = addNewCompany;
  $scope.duplicationCheck = duplicationCheck;
  $scope.remove = remove;
  $scope.changeItem = changeItem;
  $scope.loadOptions = loadOptions;
  $scope.loadOptionsSimple = loadOptionsSimple;
  $scope.cancelAddCompany = cancelAddCompany;
  $scope.validateInputData = validateInputData;
  $scope.saveCompany = saveCompany;
  $scope.uploadFile = uploadFile;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.onSelectedState   = onSelectedState;
  $scope.countries = [];
  $scope.MYStates = [];
  //setting for add-field multi-select dropdown list
  $scope.settingAddField = {
    smartButtonMaxItems: 1,
    scrollable: true,
    externalIdProp: "",
    displayProp: "name",
    smartButtonTextConverter: function(itemText, originalItem) { return "Add Field"; }
  };

  function remove(item){
    _.remove($scope.selected_options.add_fields, function(selected_item){
      return item === selected_item;
    });
  }

  function changeItem(sub, item){
    item.custom_name = sub;
  }

  function onSelectedState(state){
    $scope.corresponding_address.state = state.descr;
  }

  function onSelectedCountry(country){
    $scope.corresponding_address.state = undefined;
    $scope.corresponding_address.country = country.descr;
    if(country.descr === "Malaysia"){
         $scope.selectedMalaysia = true;
      }
    else
    {
     $scope.selectedMalaysia = false;
    }
  }

  //load option
  function loadOptions(url, params, options, paramValues, scopeOptionName) {
    if ($scope.options[scopeOptionName].length > 0) {
      return $scope.options[scopeOptionName];
    }

    var deferred = $q.defer();
    $resource(
      url,
      params,
      options
    ).get(
      paramValues,
      function (resource) {
        $scope.options[scopeOptionName] = resource.content.data;
        deferred.resolve(resource.data);

      }
    );

    return deferred.promise;
  }

  function loadOptionsSimple(url, scopeOptionName) {
    return $scope.loadOptions(url, {}, {}, {}, scopeOptionName);
  }

  function getCompanies(){
    searchModule.get(
      {
        module: 'companies',
        offset: 0,
        order: 1,
        order_by: 'descr'
      },
      function(resource) {
        if(!!resource.content) {
          $scope.parentCompanies = resource.content.data;
        }else {
          $scope.parentCompanies = [];
        }

        $scope.parentCompanies.unshift({id: null, descr: 'None'});
        $scope.company.parent_company_id = $scope.parentCompanies[0];
      }
    )
  }

  function onSelectedBU(bu){
    $scope.company.bu_code = bu.code;
  }

  function onSelectedIndustry(industry) {
    $scope.company.industry_code = industry.code;
  }

  function validateInputData(){

    if (!$scope.company.name || !$scope.company.name.length) {
      return false;
    }

    if (!$scope.company.code || !$scope.company.code.length) {
      return false;
    }

    if (!$scope.company.reg_no || !$scope.company.reg_no.length) {
      return false;
    }

    if (!$scope.selectedCurrencies) {
      return false;
    }

    if (!$scope.selectedPreferredCurrency) {
      return false;
    }

    if (!$scope.selectedExpenseTypes || !$scope.selectedExpenseTypes.length) {
      return false;
    }

    if (!$scope.company.email || !$scope.company.email.length) {
      return false;
    }

    return true;
  }

  function cancelAddCompany(){
    $state.go('main.user.companiesMgmt.manageCompanies');
  }

  function saveCorrespondingAddress(){
    $scope.corresponding_address.phone = $scope.company.phone;
    addressList.post(
      $scope.corresponding_address,
      function(resource) {
        if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id){
          $scope.corresponding_address_id = resource.content.data._id;
          saveCompany();
        }
        else {
          toastr.error("Corresponding address failed to add");
        }
      },
        function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
  }

  //function to upload company logo after adding company
  function uploadFile(companyID) {
    var fd = new FormData();
    fd.append("file", $scope.companyFile);
    $http.post(pathConstants.apiUrls.companies.list + '/' + companyID + '/image', fd, {
      headers: {'Content-Type': undefined },
      transformRequest: angular.identity
    }).then(function onSuccess(){
      toastr.success("Company logo uploaded successfully");
      $state.go('main.user.companiesMgmt.manageCompanies');
    }).catch(function onError (){
      toastr.error("Company logo failed to add");
    });
  }

  //upload company detail
  function saveCompany(){
    var companyCurrency = [];
    var preferredCurrency = '';
    var expenseTypes = [];

    $scope.company_detail_form.company_name.$dirty = true;

    if(!!$scope.selectedCurrencies){
      companyCurrency = globalFunc.getArrayOfKeyValues($scope.selectedCurrencies, 'code');
      preferredCurrency = $scope.selectedPreferredCurrency.code;
    }

    if (!!$scope.selectedExpenseTypes) {
      expenseTypes = globalFunc.getArrayOfKeyValues($scope.selectedExpenseTypes, '_id');
    }

    if(!$scope.company.allowedToHoldPR) {
      $scope.company.allowedToHoldPR = false;
    }

    var submitData = {
      descr: $scope.company.name,
      bu_code: $scope.company.bu_code,
      industry_code: $scope.company.industry_code,
      code: $scope.company.code,
      currencies: companyCurrency,
      currency_code: preferredCurrency,
      fax: $scope.company.fax,
      email: $scope.company.email,
      phone: $scope.company.phone,
      reg_no: $scope.company.reg_no,
      corresponding_address_id: $scope.corresponding_address_id,
      gst_code: $scope.company.gst_code,
      PO_initial_status: $scope.company.PO_initial_status.value,
      expense_types: expenseTypes,
      allowedToHoldPR: $scope.company.allowedToHoldPR
    };
    if(angular.isDefined($scope.company.parent_company_id))
      submitData['parent_company_id'] = $scope.company.parent_company_id._id;

    Companies.post(
      {},
      submitData,
      function(resource){
      toastr.success("Company has been added");
      $state.go('main.user.companiesMgmt.manageCompanies');
        //Upload image after company added
        if(!!resource._id && angular.isDefined($scope.companyFile)) {
          $scope.uploadFile(resource._id);
        }
      },
      function(error){
        if(!!error.data && !!error.data.message){
          toastr.error(error.data.message);
        }
      }
    );
  }


  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'rocCheckTrue':
          $scope.rocCheck = true;
          break;
        case 'gstCheckTrue':
          $scope.gstCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'gstCheckFalse':
          $scope.gstCheck = false;
          break;
      }
    });
  }

  function addNewCompany(){
    $scope.submitted = true;
    if($scope.validateInputData() == false){
      toastr.error('Please fill all required fields before proceeding');
      return;
    }

    saveCorrespondingAddress();
  }

  /**
   * provide the preferred currency data
   * from selectedCurrencies
   */
  $scope.selectCurrencies = {
    onItemSelect: function(selectedCurrencies){
      $scope.selectedCompanyCurrencies.push(selectedCurrencies);
    },
    onItemDeselect: function(deselectedCurrencies){
      $scope.selectedCompanyCurrencies = globalFunc.removeValueFromArray(
        $scope.selectedCompanyCurrencies, '_id', deselectedCurrencies._id
      );

      if(!!$scope.selectedPreferredCurrency && !!$scope.selectedPreferredCurrency._id){
        if($scope.selectedPreferredCurrency._id === deselectedCurrencies._id){
          $scope.selectedPreferredCurrency = {};
        }
      }
    },
    onDeselectAll: function(){
      $scope.selectedCompanyCurrencies = [];
      $scope.selectedPreferredCurrency = {};
    }
  };

  function initialize(){

    var tabData = {
      tab: $scope.$parent.tabData[3],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();
    $scope.company.PO_initial_status = globalFunc.findInArray($scope.POInitialStatuses, 'value', 0);
    getCompanies();
  }

  initialize();

}

addCompanyCtrl.$inject = [
  '$scope', '$q', '$resource', '$state', 'pathConstants', 'Companies', 'toastr', 'currenciesList', 'globalFunc',
  'BU', 'industryList', 'addressList', '$http', 'searchModule', '$rootScope', 'companyGlobalFunc', 'expenseTypesList'
];

angular
  .module('metabuyer')
  .controller('addCompanyCtrl', addCompanyCtrl);


