'use strict';

function passwordResetModalCtrl($scope, profile, $uibModalInstance, toastr, singleUser, globalFunc) {

  $scope.createNewPassword = createNewPassword;
  $scope.closeModal = closeModal;
  $scope.error = false;
  $scope.missMatchError = false;
  $scope.wrongFormatError = false;
  $scope.profile = profile;

  function createNewPassword() {
    $scope.submitted = true;
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.missMatchError = false;
    $scope.error = false;
    //checks if they pass all the validation
    if(!!$scope.oldPasswordField){
      if(!$scope.password_old.length){
        $scope.error = true;
      }
    }
    if (checkPasswordFormat()) {
      if (!checkMatchPassword()) {
        //message that the new password didn't match
        $scope.missMatchError = true;
        $scope.error = true;
      }
    }
    else {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.error = true;
    }
    if (!$scope.error) {
      if(!!$scope.oldPasswordField){
        singleUser.put({
            id: 'me'
          },
          {
            old_password: $scope.password_old,
            password: $scope.password_new,
            password_confirm: $scope.password_confirm
          },
          function () {
            $uibModalInstance.close();
            toastr.success("User's password successfully changed");
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          });
      }
      else{
        singleUser.put({
            id: $scope.profile._id
          }, {
            password: $scope.password_new,
            password_confirm: $scope.password_confirm,
            send_email : $scope.send_email
          },
          function () {
            $uibModalInstance.close();
            toastr.success("User's password successfully changed");
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          });
      }
    }
    else{
      toastr.error("Please fill in the required fields");
    }
  }

  function checkPasswordFormat() {
    var letter = /[a-z]/;
    var capitalLetter = /[A-Z]/;
    var number = /[0-9]/;
    return (letter.test($scope.password_new) && number.test($scope.password_new) &&
      $scope.password_new.length > 7 && capitalLetter.test($scope.password_new));
  }

  function checkMatchPassword() {
    return $scope.password_new === $scope.password_confirm;
  }

  function closeModal() {
    $uibModalInstance.close();
  }

}

passwordResetModalCtrl.$inject = ['$scope', 'profile', '$uibModalInstance', 'toastr', 'singleUser', 'globalFunc'];

angular.module('metabuyer')
  .controller('passwordResetModalCtrl', passwordResetModalCtrl);
