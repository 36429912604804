'use strict';
/**
 * @name securityCtrl
 * @desc Controller for Security controller
 */
function securityCtrl(
  $scope, companies, rolesList, permissions, $uibModal, toastr, classesList, $state, globalFunc, pathConstants,
  permissionsParameters, classes) {

  $scope.$emit('pageTitleChanged', 'Security');
  $scope.companies = companies.data;
  $scope.companies =  globalFunc.sortObjectsArray($scope.companies, 'descr');
  $scope.permissionsClasses = classesList.data;
  $scope.selectedCompany = true;
  $scope.selectedPermission = false;
  $scope.disableCompaniesDropDown = false;
  $scope.editMode = false;
  $scope.permissionsList = [];
  $scope.classPermissionsList = classes;
  $scope.backUpClassPermissionsList = [];
  $scope.openPermissionPanel = openPermissionPanel;
  $scope.selectCompany = selectCompany;
  $scope.managedRole = {};
  $scope.managedRole.permissions = reformatPermissions($scope.managedRole.permissions_list);
  $scope.managedRole.permissionsConditions = reformatPermissionsConditions($scope.managedRole.permissions_conditions);
  $scope.importLink = pathConstants.apiUrls.imports.now;
  $scope.createNewRole = createNewRole;
  $scope.submitRole = submitRole;
  $scope.editCheck = editCheck;
  $scope.editDropDown = editDropDown;
  $scope.cancelAddingARole = cancelAddingARole;

  var companyConditions =  globalFunc.securityConditions();

  function openPermissionPanel(title, permClass) {
    var availableConditions = [];
    var temp = {
      name: 'Self',
      value: '(@__OWN__)'
    };
    availableConditions.push(temp);

    /**
     * adding Company option of its supplied from the backend
     */
    for(var i in companyConditions){
      if(permClass === companyConditions[i].class){
        if(!!companyConditions[i].value){
          temp = {
            name: 'Company',
            value: companyConditions[i].value
          };
        availableConditions.push(temp);
        break;
        }
      }
    }
    $uibModal.open({
      templateUrl: 'app/security/permissionsPanel/permissionsPanelTemplate.html',
      backdrop: 'static',
      keyboard: false,
      controller: permissionsPanelCtrl,
      scope: $scope,
      resolve: {
        permission: function () {
          return title;
        },
        permissionsClassList: function (permissionsParameters, $q) {
          var deferred = $q.defer();
          permissionsParameters.get(
            {
              class: permClass
            },
            function (resource) {
              deferred.resolve(resource.content);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        availableConditions: function(){
          return availableConditions;
        }
      }
    });
  }

  /**
   * cleaning the messy object and make it an array, so that permissions can be appended/removed
   * @returns {Array}
   */
  function reformatPermissions(permissions){
    var tempArray = [];
    for(var i in permissions){
      if(typeof permissions[i] === 'object'){
        tempArray.push(permissions[i]._id);
      }
    }
    return tempArray;
  }

  /**
   * cleaning the messy object and make it an array, so that permissions conditions can be appended/removed
   * @returns {Array}
   */

  function reformatPermissionsConditions(conditions){
    var tempArray = [];
    _.forEach(conditions, function(condition, key){
      if(!!condition){
        var tempCondition = {};
        if((condition.indexOf('@__OWN') > -1) || (condition.indexOf('@__COMPANY') > -1)) {
          if (condition.indexOf('@__OWN') > -1) {
            tempCondition[key] = {
              name: "Self",
              value: condition
            };

          } else {
            tempCondition[key] = {
              name: "Company",
              value: condition
            };
          }
        }
        else{
          tempCondition[key] = {
            name: "Self",
            value: condition
          };
        }
        tempArray.push(tempCondition);
      }
    });

    return tempArray;
  }

  function createNewRole() {
    if(!!$scope.activeCompany) {
      refreshPermissionsList();
      $scope.managedRole = {};
      $scope.managedRole.permissions = [];
      $scope.managedRole.permissionsConditions = [];
      $state.go('main.user.security.add', {code: $scope.activeCompany.code});
    }
  }

  /**
   * clear the selection for any Role
   */
  function refreshPermissionsList() {
    for (var i in $scope.classPermissionsList) {
      for (var k in $scope.classPermissionsList[i].val) {
        $scope.classPermissionsList[i].val[k].checked = null;
        /**
         * fix to auto fill the not Allowed dropdown
         */
        $scope.classPermissionsList[i].val[k].domain = {
          name: 'not allowed',
          value: 'null'
        };
      }
    }
  }

  function searchRoleNameDuplication(roleName, id){
    for(var i in $scope.roles){
      if($scope.roles[i].name.toLowerCase() === roleName.toLowerCase()){
        if($scope.roles[i]._id !== id) {
          if (!!$scope.roles[i].company_code) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * add/remove a permission with the dropdown
   * @param perm
   */
  function editDropDown(perm){
    if(!!perm.domain){
      if(perm.domain.value === null){
        removePermissionFromPermissionsList(perm._id);
        removePermissionConditionFromPermissionsConditions(perm._id);
      }else if(perm.domain.value === '(@__OWN__)'){
        removePermissionFromPermissionsList(perm._id);
        removePermissionConditionFromPermissionsConditions(perm._id);
        $scope.managedRole.permissions.push(perm._id);

        var tempCondition = {};
        tempCondition[perm._id] = perm.domain;
        $scope.managedRole.permissionsConditions.push(tempCondition);
      }else{
        removePermissionFromPermissionsList(perm._id);
        removePermissionConditionFromPermissionsConditions(perm._id);
        $scope.managedRole.permissions.push(perm._id);

        var tempCondition = {};
        tempCondition[perm._id] = perm.domain;
        $scope.managedRole.permissionsConditions.push(tempCondition);
      }
    }
  }

  function editCheck(perm) {
    if (perm.checked === true) {
      removePermissionFromPermissionsList(perm._id);
      removePermissionConditionFromPermissionsConditions(perm._id);
      $scope.managedRole.permissions.push(perm._id);
      var tempCondition = {};
      tempCondition[perm._id] = {
        name: 'Self',
        value: '(1==1)'
      };
      $scope.managedRole.permissionsConditions.push(tempCondition);
    }else{
      removePermissionFromPermissionsList(perm._id);
      removePermissionConditionFromPermissionsConditions(perm._id);
    }
  }

  /**
   * cleaning the permissions array
   * @param id
   */
  function removePermissionFromPermissionsList(id){
    for (var i in $scope.managedRole.permissions) {
      if($scope.managedRole.permissions[i] === id){
        $scope.managedRole.permissions.splice(i,1);
      }
    }
  }

  /**
   * cleaning the permissions conditions array
   * @param id
   */
  function removePermissionConditionFromPermissionsConditions(id){
    for (var i in $scope.managedRole.permissionsConditions) {
      if(Object.keys($scope.managedRole.permissionsConditions[i])[0] === id){
        $scope.managedRole.permissionsConditions.splice(i,1);
      }
    }
  }

  /**
   * validate the selection
   */
    //TODO: check if the name is not spaces (Ahmed Saleh)
  function validate(message) {
    if (!$scope.activeCompany || !$scope.activeCompany.code) {
      toastr.error('Please select a company for this role');
      return false;
    }
    if (!$scope.managedRole.name) {
      toastr.error('Please enter a name for this role');
      return false;
    }
    if (!$scope.managedRole.descr) {
      toastr.error('Please enter a description for this role');
      return false;
    }
    if(message !== 'updated') {
      if (searchRoleNameDuplication($scope.managedRole.name, $scope.managedRole._id)) {
        toastr.error('Please enter another name for this role, this Role name is already taken');
        return false;
      }
    }
    /**
     * if Role is edited, just check against if all permissions selection were removed
     */
    if (message === 'updated') {
      if ((!$scope.managedRole.permissions_list || $scope.managedRole.permissions_list.length < 1) && (!$scope.managedRole.permissions || $scope.managedRole.permissions.length < 1)) {
        toastr.error('Please assign at least 1 permission');
        return false;
      }
    }
    /**
     * if Role is new, just check if there were permissions selected
     */
    else {
      if (!$scope.managedRole.permissions || $scope.managedRole.permissions.length < 1) {
        toastr.error('Please assign at least 1 permission');
        return false;
      }
    }
    return true;
  }

  function reformatConditionsToSubmit(conditions){
    var tempConditions = {};
    _.forEach(conditions, function(condition){
      var objKey = Object.keys(condition);
      objKey = objKey[0];
      tempConditions[objKey] = condition[objKey].value;
    });

    return tempConditions;
  }

  function submitRole(message) {
    if (validate(message)) {

      var reformatPermissionsConditions = reformatConditionsToSubmit($scope.managedRole.permissionsConditions);

      rolesList.post(
          {
            name: $scope.managedRole.name,
            descr: $scope.managedRole.descr,
            permissions_list: $scope.managedRole.permissions,
            permissions_conditions: reformatPermissionsConditions,
            company_code: $scope.activeCompany.code,
            company_id: $scope.activeCompany._id,
            type: 1
          },
          function (resource) {
            if(!!resource) {
              $scope.managedRole._id = resource._id;
              toastr.success('New role has been ' + message);
              $scope.backUpManagedRole = angular.copy($scope.managedRole);
              $state.go('main.user.security.rolesListing', {code: $scope.activeCompany.code});
            }
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          }
        )
    }
  }

  function cancelAddingARole(){
    $state.go('main.user.security.rolesListing', {code: $scope.activeCompany.code});
  }

  function selectCompany(company) {
    $scope.managedRole = {};
    $scope.activeCompany = company;
    $state.go('main.user.security.rolesListing', {code: company.code});
  }

  $scope.$on('setCompany', function(args, code) {
    /**
     * finding which company is active/selected
     */
    if (!!code) {
      $scope.company = globalFunc.findInArray($scope.companies, 'code', code);
      $scope.activeCompany = globalFunc.findInArray($scope.companies, 'code', code);
    }
  });

  function prepareImportParams() {
    $scope.params = {
      class: 'Metabuyer\\Models\\RolesPermissionsImporter'
    };
  }

  function initialize () {
    prepareImportParams();
  }

  initialize();

}
securityCtrl.$inject = [
  '$scope', 'companies', 'rolesList', 'permissions', '$uibModal', 'toastr', 'classesList', '$state', 'globalFunc',
  'pathConstants', 'permissionsParameters', 'classes'
];

angular
  .module('metabuyer')
  .controller('securityCtrl', securityCtrl);
