'use strict';

angular.module('metabuyer.services.attachmentCategory', ['ngResource'])
  .factory('attachmentCategory',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.category,
        {
          id: '@id',
          host_class: '@host_class',
          object_id: '@object_id'
        },
        {
          'get': { method: 'GET' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('attachmentDescription',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.description,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' },
          'post': { method: 'POST' },
          'put': { method: 'put' },
          'delete': { method: 'delete' }
        }
      );
    }
  )
  .factory('attachmentDetail',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.attachments.detail,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' },
          'post': { method: 'POST' }
        }
      );
    }
  );
