'use strict';

/**
 * @name budget dashboard directive
 * @desc Directive to display budget dashboard
 *
 * @isolatedScope budget
 * Budget
 *
 * @isolatedScope showDashboard
 * To synchronize variable with the calling page, then to hide or to show this directive
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetDashboard', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budget/dashboard/budgetDashboard.html',
      scope: {
        prBudget: '=',
        prStatus: '=',
        prId: '=',
        showDashboard: '=',
        sourceCurrency: '<',
        sourceCompanyId: '<'
      },
      controller: function ($scope, budgets, ExchangeRate, budgetFunctions, globalFunc) {

        $scope.option = {
          animate: {enabled: false},
          readOnly: true,
          size: 150,
          unit: '%',
          skin: {
            type: 'tron',
            width: 5,
            color: '#1ab394',
            spaceWidth: 3
          },
          barColor: '#1ab394',
          trackWidth: 20,
          barWidth: 20,
          step: 0.01,
          textColor: ''
        };

        $scope.isSourceCurrencyDifferent = isSourceCurrencyDifferent;

        /**
         * Get budget information
         */
        function getBudget() {
          if (!!$scope.prBudget && !!$scope.prBudget._id) {
            budgets.get(
              {
                id: $scope.prBudget._id,
                type: 'budget_dashboard',
                prId: !!$scope.prId ? $scope.prId : '',
                assignmentId: !!$scope.prBudget.assignment_id ? $scope.prBudget.assignment_id: ''
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  $scope.budget = resource.content.data;
                  $scope.showDashboard = _.size($scope.budget) > 0;
                  $scope.totalBudget = resource.content.data.amount - resource.content.data.block_amount;
                  $scope.valueTotalBudget = 100;
                  $scope.currentApportion = null;

                  // If selected budget has apportion, replace the values
                  if (!!resource.content.data.current_apportion) {
                    $scope.currentApportion = resource.content.data.current_apportion;
                    $scope.totalAvailableBudget = resource.content.data.current_apportion.available_balance;
                    $scope.amount = resource.content.data.current_apportion.amount;
                    $scope.totalUsedBudget = resource.content.data.current_apportion.used_amount;
                    $scope.totalCommittedBudget = resource.content.data.current_apportion.committed_amount;
                  } else {
                    $scope.totalAvailableBudget = resource.content.data.available_balance;
                    $scope.amount = (resource.content.data.amount - resource.content.data.block_amount);
                    $scope.totalUsedBudget = resource.content.data.used_amount;
                    $scope.totalCommittedBudget = resource.content.data.committed_amount;
                  }

                  $scope.valueTotalAvailableBudget =
                    budgetFunctions.multiply(
                      budgetFunctions.divide(
                        $scope.totalAvailableBudget,
                        ($scope.amount)
                      ), 100
                    );
                  $scope.valueTotalUsedBudget =
                    budgetFunctions.multiply(
                      budgetFunctions.divide(
                        $scope.totalUsedBudget,
                        $scope.amount
                      ), 100);
                  $scope.valueTotalCommittedBudget =
                    budgetFunctions.multiply(
                      budgetFunctions.divide(
                        $scope.totalCommittedBudget,
                        $scope.amount
                      ), 100
                    );

                  getExchangeRate();
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
          else {
            $scope.showDashboard = false;
          }
        }

        /**
         * Get the exchange rate of source to budget
         */
        function getExchangeRate() {
          if (!!$scope.budget && !!$scope.sourceCompanyId && !!$scope.sourceCurrency && !!$scope.sourceCurrency._id &&
            !!$scope.budget.currency && !!$scope.budget.currency._id) {

            ExchangeRate.get({
              id: $scope.sourceCompanyId,
              source_id: $scope.sourceCurrency._id,
              target_id: $scope.budget.currency._id
            }, function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data) {
                $scope.exchangeRate = resource.content.data;
              }
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            })
          }
        }

        /**
         * Check if source currency is different from the budget currency
         */
        function isSourceCurrencyDifferent() {
          if (!!$scope.budget && !!$scope.sourceCurrency && !!$scope.sourceCurrency._id && !!$scope.budget.currency &&
            !!$scope.budget.currency._id) {
            return $scope.budget.currency._id !== $scope.sourceCurrency._id;
          }
          return false;
        }

        function initialize() {
          getBudget();

          $scope.$watch('sourceCurrency', function () {
            getExchangeRate();
          });

          $scope.$on('PRDataUpdated', function () {
            getBudget();
          });
        }

        initialize();
      }
    };
  });
