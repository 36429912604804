/**
 * @name fixHeader
 *
 * @description Directive to make the header on fix position
 *
 * @param height
 * height limit to set as fix header
 * @param width
 * width (boolean), true if want to use the existing width, false to set to auto
 * @param padding
 * fill in with value. to add additional padding
 * @param top
 * set top value
 *
 * @author Deniel
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('fixHeader', function ($window) {
    return {
      restrict: 'A',
      scope:{
        height: '=',
        width: '=',
        padding: '=',
        top: '='
      },
      link: function ($scope, element, attrs) {
        var initBackground,
            initPadding,
            initTop,
            initWidth;

        var fixHeaderElement = document.getElementById('fixHeaderDirective');

        function setFixHeader() {
          var result = document.getElementsByClassName('item-action-scrollfix');
          var wrappedResult = angular.element(result);

          angular.element(fixHeaderElement).css('transition', 'all linear .5s');
          angular.element(fixHeaderElement).css('position', 'fixed');
          angular.element(fixHeaderElement).css('background', 'white');
          angular.element(fixHeaderElement).css('top', '0');
          angular.element(fixHeaderElement).css('z-index', '100');
          angular.element(fixHeaderElement).css('padding', '10px');
          angular.element(fixHeaderElement).css('border-bottom', '#dcdcdc 2px solid');
          if(!!document.getElementById('fixHeaderViewWidth'))
            angular.element(fixHeaderElement).css('width', angular.element(document.getElementById('fixHeaderViewWidth')).prop('offsetWidth') + 'px');
          if (!!wrappedResult && !!wrappedResult.length)
            angular.element(fixHeaderElement).css('top', '62px');

          if (!!$scope.padding)
            angular.element(fixHeaderElement).css('padding', $scope.padding);
          if (!document.getElementById('fixHeaderViewWidth'))
            angular.element(fixHeaderElement).css('width', initWidth);
          if (!!$scope.top)
            angular.element(fixHeaderElement).css('top', $scope.top);
        }

        function removeFixHeader() {
          angular.element(fixHeaderElement).css('position', '');
          angular.element(fixHeaderElement).css('border-bottom', '');
          angular.element(fixHeaderElement).css('z-index', '');
          angular.element(fixHeaderElement).css('background', initBackground);
          angular.element(fixHeaderElement).css('width', '');

          if (!!$scope.padding)
            element.css('padding', initPadding);
          if (!!$scope.top)
            element.css('top', initTop);
        }

        function initialize() {
          initBackground = angular.element(fixHeaderElement).css('backgroundColor');
          initWidth = angular.element(fixHeaderElement).css('width');
          initPadding = angular.element(fixHeaderElement).css('padding');
          initTop = angular.element(fixHeaderElement).css('top');
        }

        initialize();

        /**
         * Event on window scroll
         */
        var timer;
        angular.element($window).bind('scroll', function() {
          if(timer) {
            $window.clearTimeout(timer);
          }

          /**
           * Set a delay of 100ms before calling the set/remove header
           * @type {number}
           */
          timer = window.setTimeout(function() {

            /**
             * Set fix header when scroll is more than 25% of window height
             */
            if (this.pageYOffset >= ($window.innerHeight/4)){
              setFixHeader();
            }
            /**
             * Remove fix header when scroll is less than 10% of window height
             */
            else if(this.pageYOffset < ($window.innerHeight/10)){
              removeFixHeader();
            }

            // to avoid error $digest already in progress
            _.defer(function() {
              $scope.$apply();
            });
          }, 100);

        });
      }
    }
  });
