'use strict';

function suppliersManageCtrl(
  $scope, suppliers, $rootScope, action, suppliersList, $stateParams, HighlightId, $window,
  supplierGlobalFunc, $filter, globalFunc)
{
  $scope.showColumn                   = showColumn;
  $scope.getStatusName                = getStatusName;
  $scope.getOriginName                = getOriginName;
  $scope.checkWaitingOnUser           = checkWaitingOnUser;
  $scope.getSelectedSuppliers         = getSelectedSuppliers;
  $scope.multiSelectPendingItems      = multiSelectPendingItems;
  $scope.isCurrentUserSupplierManager = isCurrentUserSupplierManager;

  $scope.storageKey = 'suppliers-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the status name
   * @param supplierStatus
   * @param approvalStatus
   * @param draftingStatus
   *
   * @returns {*}
   */
  function getStatusName(supplierStatus, approvalStatus, draftingStatus){
    var status = supplierGlobalFunc.getSupplierStatus(supplierStatus, approvalStatus, draftingStatus, 'val');
    if (!!status)
      return status;

    return 'N/A';
  }

  /**
   * returns the name of te Original state the supplier was in
   * @param val
   * @returns {*}
   */
  function getOriginName(val){
    if(val === 1){
      return 'Active';
    }
    if(val === 2){
      return 'Blacklisted';
    }
    if(val === 3){
      return 'Inactive';
    }
    return 'N/A';
  }

  /**
   * return if the user is part of the waiting on, for a specific Supplier
   * @param supplier
   * @returns {*}
   */
  function checkWaitingOnUser(supplier) {
    return globalFunc.findInArray(supplier.waiting_on, '_id', $rootScope.currentUser._id);
  }

  /**
   * returns store the selected Suppliers
   * @returns {Array}
   */
  function getSelectedSuppliers() {
    var selectedSuppliers = [];
    _.forEach($scope.suppliers, function (supplier) {
      if (!!supplier.selected) {
        selectedSuppliers.push(supplier);
      }
    });
    return selectedSuppliers;
  }

  /**
   *  auto select/deselect the items for approval action
   * @param status
   */
  function multiSelectPendingItems (status) {
    _.forEach($scope.suppliers, function (supplier) {
      if (checkWaitingOnUser(supplier)) {
        supplier.selected = status;
      }
    });
  }

  /**
   * Check if current user has system role: Supplier Manager
   *
   * @returns {boolean}
   */
  function isCurrentUserSupplierManager () {
    return (supplierGlobalFunc.checkIfUserHasSupplierManagerRole($rootScope.currentUser));
  }

  function initialize(){

    $scope.suppliers = suppliers.data;
    $scope.actionType = action;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }
    $scope.meta = suppliers.meta;
    $scope.supplierManageService = suppliersList;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    //set status for parent
    if(!!HighlightId.getId() && HighlightId.getId() != undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

  }

  initialize();
}

suppliersManageCtrl.$inject = ['$scope','suppliers', '$rootScope', 'action', 'suppliersList', '$stateParams',
  'HighlightId', '$window', 'supplierGlobalFunc', '$filter', 'globalFunc'];

angular.module('metabuyer')
  .controller('suppliersManageCtrl', suppliersManageCtrl);
