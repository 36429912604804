'use strict';
/**
 * @name userCtrl
 * @desc The controller to load the user once logged in to the system
 */
function userCtrl($scope, reLoginService, $auth, $state, $rootScope, Account, toastr,
                  anTinycon, ManageLocalStorage, authenticated,
                  pathConstants, UserPermissions, profile, $interval, unreadNotificationList,
                  waitingOnUserList, budgetFunctions) {

  $rootScope.changeWorkSpace = changeWorkSpace;

  $rootScope.navOptions = {
    pr: {
      'key': 'main.user.purchaseRequisitions.manage',
      'title': 'Purchase Requisitions',
      'icon': 'fa-pr',
      'description': 'Purchase Requisitions',
      'status': !!$rootScope.isMobileMode ? 'pending' : 'draft',
      'extraFilterKey': !!$rootScope.isMobileMode ? 'criteria[waiting_on][]' : null,
      'extraFilterValue': !!$rootScope.isMobileMode ? profile._id : null,
      'tab': !!$rootScope.isMobileMode ? 'waiting' : 'all',
      'id': 'prSidebarIcon'
    },
    po: {
      'key': 'main.user.orders.manage',
      'title': 'Purchase Orders',
      'icon': 'fa-po',
      'description': 'Purchase Orders',
      'status': 'SUBMITTED',
      'id': 'poSidebarIcon'
    },
    suppliersListing: {
      'key': 'main.user.suppliersList.manage',
      'title': 'Suppliers List',
      'icon': 'fa-truck',
      'description': 'Suppliers List',
      'status': 'active',
      'id': 'suppliersListingSidebarIcon'
    },
    budget: {
      'key': 'main.user.budget.manage',
      'title': 'Budget',
      'icon': 'fa-budget',
      'description': 'Budget',
      'id': 'budgetSidebarIcon',
      'status': 'draft'
    },
    itemsManagement: {
      'key': 'main.user.items.manageCatalog',
      'title': 'Catalog',
      'icon': 'fa-th-list',
      'description': 'Catalog',
      'status': 'approved',
      'id': 'itemsSidebarIcon'
    },
    catalogItemView: {
      'key': 'main.user.catalogView.catalogItemList',
      'title': 'Catalog',
      'icon': 'fa-th-list',
      'description': 'Catalog',
      'id': 'viewCatalogItemsSidebarIcon'
    },
    exploreApps: {
      'key': 'main.user.explore-apps',
      'title': 'Explore Apps',
      'icon': 'fa-th',
      'description': 'Explore Apps'
    },
    more: {
      'key': 'main.user.more',
      'title': 'More',
      'icon': 'fa-ellipsis-v',
      'description': 'More',
      'id': 'moreSidebarIcon'
    },
    Companies: {
      'key': 'main.user.companiesMgmt.manageCompanies',
      'title': 'Companies',
      'icon': 'fa-user',
      'description': 'Companies',
      'id': 'companiesSidebarIcon'
    },
    Users: {
      'key': 'main.user.users.manage',
      'title': 'Users',
      'icon': 'fa-user',
      'description': 'Users',
      'status': 'active',
      'id': 'usersSidebarIcon'
    },
    supplier: {
      'key': 'main.user.suppliers.manage',
      'title': 'Suppliers',
      'icon': 'fa-user',
      'description': 'Suppliers',
      'status': 'active',
      'id': 'suppliersSidebarIcon'
    },
    masterData: {
      'key': '',
      'title': 'Master Data',
      'icon': 'fa-th-list',
      'description': 'Master Data',
      'status': '',
      'id': 'masterDataSidebarIcon'
    },
    approvalFlow: {
      'key': 'main.user.approvalFlow.manage',
      'title': 'Approval Flow',
      'icon': 'fa-th-list',
      'description': 'Approval Flow',
      'id': 'approvalFlowSidebarIcon'
    },
    security: {
      'key': 'main.user.security.manage',
      'title': 'Security',
      'icon': 'fa-users',
      'description': 'Security',
      'id': 'securitySidebarIcon'
    },
    settings: {
      'key': 'main.user.settings.mainPanel',
      'title': 'Settings',
      'icon': 'fa-cogs',
      'description': 'Settings',
      'id': 'settingsSidebarIcon'
    },
    reports: {
      'key': 'main.user.reports.generate',
      'title': 'Reports',
      'icon': 'fa-cogs',
      'description': 'Reports',
      'id': 'reportsSidebarIcon'
    },
    exports: {
      'key': 'main.user.exports.log',
      'title': 'Exports',
      'icon': 'fa-cogs',
      'description': 'Exports',
      'id': 'exportsSidebarIcon'
    },
    position: {
      'key': 'main.user.positionManagement.assign',
      'title': 'Position Management',
      'icon': 'fa-th-list',
      'description': 'Position Management',
      'id': 'positionSidebarIcon'
    },
    emails: {
      'key': 'main.user.emailLogs.emailLogsList',
      'title': 'Email Logs',
      'icon': 'fa-th-list',
      'description': 'Email Logs',
      'id': 'emailsSidebarIcon'
    }
  };

  /**
   * This function called to refresh the user Notifications list, the Interval can be changed if its too fast or too slow
   */
  var time = 180000;
  $scope.intervalPromise = $interval(loadWaitingsAndNotifications, time);

  function loadWaitingsAndNotifications() {
    if ($auth.isAuthenticated()) {
      //To get updated number of unread notifications per interval
      unreadNotificationList.get(
        {},
        function (resource) {
          if (!!resource.content) {
            if (!!resource.content.data && !!resource.content.data.length > 0) {
              $rootScope.notifications = resource.content;
              $rootScope.$broadcast('newNotifications');
            }
          }
        },
        function (error) {
          if (error.status === 404) {
          }
        }
      );

      //To get updated number of waiting on you per interval
      waitingOnUserList.get(
        {},
        function (resource) {
          if (!!resource.content) {
            $rootScope.waitings = resource.content;
            $rootScope.$broadcast('newWaitingList');
            $scope.nofifyWaitingOnFavico($rootScope.waitings);
          }
        },
        function (error) {
          if (error.status === 404) {
          }
        }
      );
    } else if (!!$rootScope.currentUser && !!$rootScope.currentUser.email) {
      var currentStateData = {
        name: $state.current.name,
        params: $state.params,
        url: $state.href($state.current.name, $state.params)
      };
      reLoginService(currentStateData, $rootScope.currentUser.email, 'The session has expired');
    }
  }
  // adding base path for user image
  $rootScope.userImageBasePath = pathConstants.apiUrls.image.user;

  // notify amount of items waiting on user on favico
  $scope.nofifyWaitingOnFavico = function (waitings) {
    if (!!waitings) {
      if (!!waitings.meta) {
        if (!!waitings.meta.cursor) {
          $scope.nofifyWaiting = waitings.meta.cursor.count;
          if ($scope.nofifyWaiting > 0) {
            anTinycon.bind($scope, 'nofifyWaiting');
          }
        }
      }
    }
    else {
      anTinycon.setBubble(0);
    }
  };
  $scope.nofifyWaitingOnFavico($rootScope.waitings);

  var resetActionButtonStates = function () {
    angular.forEach($scope.actions, function (action) {
      if (action.disabled) {
        action.disabled = false;
      }
    })
  };

  $rootScope.showDialog = false;

  //$scope.$on('actionsChanged', function (event, data) {
  //  $scope.actions = data;
  //});

  $rootScope.$on('$stateChangeSuccess', function () {
    resetActionButtonStates();
  });

  $rootScope.$on('$stateChangeError', function () {
    resetActionButtonStates();
  });


  //TODO: remove this hardcoding after getting support from backend (Ahmed Saleh, 10/3/2016)
  function checkUserRole(role){
    var userProfile = profile.roles;
    for (var index in userProfile) {
      if(userProfile.hasOwnProperty(index)){
        if (userProfile[index].name === role) {
          return true;
        }
      }
    }
    return false;
  }

  $scope.getProfile = function (companyWorkSpace) {
    var getNavLinkByRole = function () {
      var navLinks = {
        nonAdministrative: [],
        administrative: []
      };
      if (!!UserPermissions.checkPRAccess(profile, 'L', companyWorkSpace)) {
        navLinks.nonAdministrative.push($rootScope.navOptions.pr);
      }
      if (!!UserPermissions.checkPOAccess(profile, 'L', companyWorkSpace)) {
        navLinks.nonAdministrative.push($rootScope.navOptions.po);
      }
      if (!!UserPermissions.checkSupplierAccess(profile, 'L', companyWorkSpace) &&
        !!UserPermissions.checkSupplierAccess(profile, 'O', companyWorkSpace)) {
        navLinks.nonAdministrative.push($rootScope.navOptions.suppliersListing);
      }
      if ((!!UserPermissions.ImportItemSummary(profile, 'L', companyWorkSpace) &&
        !!UserPermissions.ImportItemSummary(profile, 'O', companyWorkSpace)) &&
        (!!UserPermissions.ImportItemDetail(profile, 'L', companyWorkSpace) &&
        !!UserPermissions.ImportItemDetail(profile, 'O', companyWorkSpace))) {
        navLinks.nonAdministrative.push($rootScope.navOptions.catalogItemView);
      }

      if (((!!UserPermissions.checkPermissionsAccess(profile, 'C', 'Budget') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'L', 'Budget') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'O', 'Budget') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'U', 'Budget') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'D', 'Budget') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'A', 'Budget')) &&
        !!budgetFunctions.getHasBudgetSettingOn()) ||
        !!checkUserRole('TENANT_SUPER_ADMIN')) {
        navLinks.nonAdministrative.push($rootScope.navOptions.budget);
      }

      /**
       * list all the companies that a user can create a PR from
       * @type {Array}
       */
      $rootScope.listOfCompaniesForLoggedInUser = [];
      for(var i = 0; i < profile.companies.length; i++){
        if(!!UserPermissions.checkPRAccess(profile,'C',profile.companies[i].code)){
          $rootScope.listOfCompaniesForLoggedInUser.push(profile.companies[i]);
        }
      }

      //loading the admin panel
      //TODO: Clean up the permissions to access the panels later on this time its 'OR' for Create, Delete, Update (Ahmed Saleh)
      if (!!UserPermissions.ImportItemDetail(profile, 'C', companyWorkSpace) && !!UserPermissions.ImportItemDetail(profile, 'U', companyWorkSpace)
        && !!UserPermissions.ImportItemDetail(profile, 'D', companyWorkSpace) && !!UserPermissions.ImportItemSummary(profile, 'C', companyWorkSpace)
        && !!UserPermissions.ImportItemSummary(profile, 'D', companyWorkSpace) && !!UserPermissions.ImportItemSummary(profile, 'U', companyWorkSpace)) {
        navLinks.administrative.push($rootScope.navOptions.itemsManagement);
      }

      if ((!!UserPermissions.checkSupplierAccess(profile, 'C', companyWorkSpace) ||
        !!UserPermissions.checkSupplierAccess(profile, 'U', companyWorkSpace) ||
        !!UserPermissions.checkSupplierAccess(profile, 'D', companyWorkSpace)) || !!checkUserRole('TENANT_SUPER_ADMIN')) {
        navLinks.administrative.push($rootScope.navOptions.supplier);
      }
      /**
       * check makes sure that only add the first option of the master data to add the state with it so it transit to the correct tab
       */
      if (!!UserPermissions.checkCurrenciesAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkCurrenciesAccess(profile, 'U', companyWorkSpace)
        || !!UserPermissions.checkCurrenciesAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.currency';
          $rootScope.navOptions.masterData.status = 'currency';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkExpenseTypeAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkExpenseTypeAccess(profile, 'U', companyWorkSpace)
      || !!UserPermissions.checkExpenseTypeAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.expenseTypes';
          $rootScope.navOptions.masterData.status = 'expense_types';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkPaymentTermsAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkPaymentTermsAccess(profile, 'U', companyWorkSpace)
      || !!UserPermissions.checkPaymentTermsAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.paymentTerms';
          $rootScope.navOptions.masterData.status = 'payment_term';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkPaymentMethodAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkPaymentMethodAccess(profile, 'U', companyWorkSpace)
      || !!UserPermissions.checkPaymentMethodAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.paymentMethods';
          $rootScope.navOptions.masterData.status = 'payment_method';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkTaxesManagementAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkTaxesManagementAccess(profile, 'U', companyWorkSpace)
      || !!UserPermissions.checkTaxesManagementAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.taxes';
          $rootScope.navOptions.masterData.status = 'taxes';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if (!!UserPermissions.checkUOMAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkUOMAccess(profile, 'U', companyWorkSpace)
        || !!UserPermissions.checkUOMAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.uom';
          $rootScope.navOptions.masterData.status = 'uom';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(
        !!UserPermissions.checkCommodityCodeAccess(profile, 'C', companyWorkSpace) || !!UserPermissions.checkCommodityCodeAccess(profile, 'U', companyWorkSpace)
        || !!UserPermissions.checkCommodityCodeAccess(profile, 'D', companyWorkSpace)) {
          $rootScope.navOptions.masterData.key = 'main.user.masterData.commodityCode';
          $rootScope.navOptions.masterData.status = 'commodity_code';
          navLinks.administrative.push($rootScope.navOptions.masterData);
      }

      if(!!UserPermissions.checkApprovalTemplateAccess(profile, 'C') &&
        !!UserPermissions.checkApprovalTemplateAccess(profile, 'L') &&
        !!UserPermissions.checkApprovalTemplateAccess(profile, 'O') &&
        !!UserPermissions.checkApprovalTemplateAccess(profile, 'U') &&
        !!UserPermissions.checkApprovalTemplateAccess(profile, 'D') && !checkUserRole('User')) {
        navLinks.administrative.push($rootScope.navOptions.approvalFlow);
        navLinks.administrative.push($rootScope.navOptions.position);
      }

      //TODO: once userPermissions are cleared up for exports, update here (Justin 4/5/2016)
      if(!!UserPermissions.checkExportLogAccess(profile, 'C') && !!UserPermissions.checkExportLogAccess(profile, 'L') &&
        !!UserPermissions.checkExportLogAccess(profile, 'O') && !!UserPermissions.checkExportLogAccess(profile, 'U') &&
        !!UserPermissions.checkExportLogAccess(profile, 'D') && !checkUserRole('User')) {
        navLinks.administrative.push($scope.navOptions.exports);
      }

      if(!!UserPermissions.checkReportLogAccess(profile, 'C') && !!UserPermissions.checkReportLogAccess(profile, 'L') &&
        !!UserPermissions.checkReportLogAccess(profile, 'O') && !!UserPermissions.checkReportLogAccess(profile, 'U') &&
        !!UserPermissions.checkReportLogAccess(profile, 'D') && !checkUserRole('User')) {
        navLinks.administrative.push($scope.navOptions.reports);
      }

      if(!!profile.roles && profile.roles.length > 0) {
        for (var index in profile.roles) {
          if ((profile.roles[index].name.toLowerCase() === 'admin' && profile.roles[index].company_code === null) ||
            profile.roles[index].name.toLowerCase() === 'tenant_super_admin') {
            //FIXME: this is temporally until we get the security roles for the settings (Ahmed Saleh 17/2/2016)
            navLinks.administrative.push($scope.navOptions.settings);
            /**
             * Companies, Users and Security do not have permissions, so only available for the ADMIN user
             */
            navLinks.administrative.push($rootScope.navOptions.Users);
            navLinks.administrative.push($rootScope.navOptions.Companies);
            navLinks.administrative.push($scope.navOptions.security);
            navLinks.administrative.push($rootScope.navOptions.emails);

            break;
          }
        }
      }

      return navLinks;
    };
    if (!!authenticated && !$rootScope.isMobileMode) {
      $rootScope.navlinks = {};
      $rootScope.navlinks = getNavLinkByRole();
      $scope.user = profile;
    }

    if(!!authenticated && $rootScope.isMobileMode){
      $rootScope.navlinks = {};
      $rootScope.navlinks.nonAdministrative = [];
      if (!!UserPermissions.checkPRAccess(profile, 'L', companyWorkSpace)) {
        $rootScope.navlinks.nonAdministrative.push($rootScope.navOptions.pr);
      }
      if (!!UserPermissions.checkPOAccess(profile, 'L', companyWorkSpace)) {
        $rootScope.navlinks.nonAdministrative.push($rootScope.navOptions.po);
      }
      $scope.user = profile;
    }
  };


  $scope.isSidebarCollapsed = function () {
    return angular.element('body').hasClass('mini-navbar');
  };

  $scope.isProfileDropdownOpen = function () {
    return angular.element('.profile-element').hasClass('open');
  };

  $scope.getProfile($scope.companyWorkSpace);


  if ($state.current.name === 'main') {
    $state.go('main.user.mainMenu.waiting-on-you');
  }

  var unwatchSidebar = $rootScope.$watch(
    function () {
      // also check against 'pace-done' class in body
      // to ensure the animation only starts after all other stuffs are loaded
      // otherwise it would be too quick and the animation would not be smooth
      var body = $('body');
      return $('.navbar-minimalize') && body.hasClass('pace-done') && body.hasClass('mini-navbar');
    },
    function (newValue, oldValue) {
      if (newValue === true) {
        unwatchSidebar();
      }
    });

  $scope.$on('flow::fileAdded', function (event, $flow, flowFile) {
    event.preventDefault();//prevent file from uploading
  });
  function changeWorkSpace(newCompany) {
    window.localStorage.setItem('workSpace', JSON.stringify(newCompany));
    $rootScope.companyWorkSpace = newCompany;
    //TODO: for now the user will be in the same page, only reload, this could be enhanced later (Ahmed Saleh)
    if ($state.current.name === 'main.user.mainMenu.waiting-on-you') {
      //TODO: need to figure out what do if the workspace changed in the main page ! (Ahmed Saleh)
      //$state.reload();
    }
    else {
      $state.go('main.user.mainMenu.waiting-on-you');
    }
  }
  $rootScope.bodyClasses = '';
  $('body').addClass('mini-navbar');
  loadWaitingsAndNotifications();
}

userCtrl.$inject = [
  '$scope', 'reLoginService', '$auth', '$state', '$rootScope', 'Account', 'toastr',
  'anTinycon', 'ManageLocalStorage', 'authenticated', 'pathConstants',
  'UserPermissions', 'profile', '$interval', 'unreadNotificationList', 'waitingOnUserList', 'budgetFunctions'
];

angular.module('metabuyer')
  .controller('userCtrl', userCtrl);
