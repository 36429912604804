'use strict';
/**
 * @name accountCodeDetailsCtrl
 * @desc Controller for account codes details
 */
function accountCodeDetailsCtrl(
  $scope, $uibModalInstance, accountCode, singleAccountCode, toastr, $state, companyId, accountCodeStatuses, accountEntries,
  accountReceivable, accountType, globalFunc, costCenters
) {
  $scope.editClicked = false;
  $scope.detailedAccountCode = angular.copy(accountCode);

  $scope.accountCodeStatuses = accountCodeStatuses;
  $scope.accountEntries = accountEntries;
  $scope.accountReceivable = accountReceivable;
  $scope.accountType = accountType;
  $scope.costCenters = costCenters;

  $scope.companyId = companyId;
  $scope.cancel = cancel;
  $scope.editAccountCode = editAccountCode;
  $scope.updateAccountCode = updateAccountCode;

  function cancel() {
    $uibModalInstance.close();
  }

  function editAccountCode(){
    $scope.editClicked = true;
  }

  function validate(accountCode){
    return(!!accountCode && !!accountCode.code && !!accountCode.descr);
  }

  function updateAccountCode() {
    if(validate($scope.detailedAccountCode)){
      swal(
        {
          title: "Confirm Updating the Account Code",
          text: "This will update the details for this Account Code",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1ab394  ",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {
            // set status in integer to pass it as parameter
            var tempStatus;
            switch($scope.detailedAccountCode.accountCodeStatus.isActive.toLowerCase()){
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
            }

            var costCenterIds = prepareCostCenterIds($scope.detailedAccountCode.cost_center);
            singleAccountCode.put(
              {
                id: $scope.companyId,
                accountCode_id: $scope.detailedAccountCode._id
              },
              {
                name: $scope.detailedAccountCode.name,
                descr: $scope.detailedAccountCode.descr,
                account_entries : $scope.detailedAccountCode.account_entries.value,
                account_receivable : $scope.detailedAccountCode.account_receivable.value,
                account_type : $scope.detailedAccountCode.account_type.value,
                cost_center_ids: costCenterIds,
                is_active: tempStatus
              },
              function () {
                toastr.success('The Account Code has been updated');
                $uibModalInstance.close($scope.detailedAccountCode);
              },
              function () {
                toastr.error('Failed to update the Account Code');
              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  function prepareCostCenterIds(selectedCostCenters) {
    var ids = [];
    _.forEach(selectedCostCenters, function(cc) {
      ids.push(cc._id);
    });
    return ids;
  }

  function setSelectedCostCenter() {
    var tempSelectedCostCenter = [];
    _.forEach($scope.detailedAccountCode.cost_center, function (selectedCostCenter) {
      var tempCostCenter = globalFunc.findInArray($scope.costCenters, '_id', selectedCostCenter._id);
      if (!!tempCostCenter)
        tempSelectedCostCenter.push(tempCostCenter);
    });

    $scope.detailedAccountCode.cost_center = tempSelectedCostCenter;
  }

  function initialize(){

    if(angular.isDefined($scope.detailedAccountCode.is_active)) {
      $scope.detailedAccountCode.accountCodeStatus = globalFunc.findInArray(
        $scope.accountCodeStatuses, 'value', $scope.detailedAccountCode.is_active
      );
    }

    $scope.detailedAccountCode.account_entries = globalFunc.findInArray(
      $scope.accountEntries, 'value', $scope.detailedAccountCode.account_entries
    );
    $scope.detailedAccountCode.account_type = globalFunc.findInArray(
      $scope.accountType, 'value', $scope.detailedAccountCode.account_type
    );
    $scope.detailedAccountCode.account_receivable = globalFunc.findInArray(
      $scope.accountReceivable, 'value', $scope.detailedAccountCode.account_receivable
    );

    // set selected cost center
    setSelectedCostCenter();
  }

  initialize();

}

accountCodeDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'accountCode', 'singleAccountCode', 'toastr', '$state', 'companyId', 'accountCodeStatuses',
  'accountEntries', 'accountReceivable', 'accountType', 'globalFunc', 'costCenters'
];

angular
  .module('metabuyer')
  .controller('accountCodeDetailsCtrl', accountCodeDetailsCtrl);
