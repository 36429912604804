'use strict';

angular.module('metabuyer')
  .controller('CancelPOCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId, $state, globalFunc) {

    $scope.cancel_reason = "Others";
    $scope.commentText = "";
    $scope.cancel = cancel;
    $scope.cancelPO = cancelPO;

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //cancel action
    function cancelPO(){

      $uibModalInstance.close();

      Orders.setPOStatus({id: $scope.po._id}, {status: 9, reason: $scope.cancel_reason, comment: $scope.commentText})
        .$promise.then(
        function(resource) {
          toastr.success('This PO is cancelled');

          if (!!resource.content && !!resource.content.data) {
            HighlightId.setId(resource.content.data._id);
          }

          $state.go('main.user.orders.manage', {status: 'Cancelled'});

        }, function(error){
          globalFunc.objectErrorMessage(error);
        });
    }
  });
