'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.suppliers', {
        url: '/suppliers',
        controller: 'suppliersCtrl',
        templateUrl: 'app/suppliersManagement/suppliersManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkSupplierAccess(profile, 'U')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.suppliers.manage', {
        templateUrl: 'app/suppliersManagement/manage/manage.html',
        url: '/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'suppliersManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            }
            else {
              return 'show';
            }
          },
          suppliers: function ($q, $state, $stateParams, Orders, authenticated, searchModule, globalFunc,
                               supplierGlobalFunc, exportSearch) {
            var deferred = $q.defer();
            var status = supplierGlobalFunc.getSupplierListStatus($stateParams.status);
            var statusWithoutArchiveOf = ['draft', 'pending', 'withdraw'];

            var params = {
              module: 'suppliers',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'descr',
              order: !!$stateParams.order ? $stateParams.order : 1
            };

            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                params['criteria_operator'] = 'and';

                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * If searching without filter
               */
              else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[0][descr]'] = $stateParams.query;
                params['criteria[0][mb_supplier_code]'] = $stateParams.query;
                params['criteria[0][reg_no]'] = $stateParams.query;
                params['criteria[0][email]'] = $stateParams.query;
                params['criteria[0][contact_person.first_name]'] = $stateParams.query;
                params['criteria[0][contact_person.email]'] = $stateParams.query;
                params['criteria[0][primary_business_nature]'] = $stateParams.query;
                params['criteria[0][primary_commodity.descr]'] = $stateParams.query;
                params['criteria[0][secondary_commodity.descr]'] = $stateParams.query;
                params['criteria[0][currency_code]'] = $stateParams.query;
                params['criteria[0][creator.display_name]'] = $stateParams.query;
                params['criteria[0][updater.display_name]'] = $stateParams.query;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
              if (!!status && !!status.length) {
                if (status[0] !== '*')
                  params['criteria[1][status]'] = status[0];

                if (status[1] !== '*')
                  params['criteria[1][approval_status]'] = status[1];

                if (status[2] !== '*')
                  params['criteria[1][draft_status]'] = status[2];
              }
              else if ($stateParams.status === 'all') {
                params['criteria[1][status]'] = null;
                params['criteria[1][approval_status]'] = null;
                params['criteria[1][draft_status]'] = null;
              }
              // to exclude archived supplier
              if (statusWithoutArchiveOf.indexOf($stateParams.status) === -1) {
                params['criteria[1][archive_of]'] = '';
              }
            }
            /**
             * For listing
             */
            else {

              if (!!status && !!status.length) {
                if (status[0] !== '*') {
                  params['criteria[0][status]'] = status[0];
                }

                if (status[1] !== '*') {
                  params['criteria[0][approval_status]'] = status[1];
                }

                if (status[2] !== '*') {
                  params['criteria[0][draft_status]'] = status[2];
                }
              }
              else if ($stateParams.status === 'all') {
                params['criteria[0][status]'] = null;
                params['criteria[0][approval_status]'] = null;
                params['criteria[0][draft_status]'] = null;
              }

              // to exclude archived supplier
              if (statusWithoutArchiveOf.indexOf($stateParams.status) === -1) {
                params['criteria[0][archive_of]'] = '';
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
              }
            );

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - listing' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })

      /**
       * State to list supplier groups a.k.a. business units
       */
      .state('main.user.suppliers.supplierGroups', {
        templateUrl: 'app/suppliersManagement/supplierGroups/manage/manage.html',
        url: '/supplierGroups?{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierGroupsCtrl',
        resolve: {
          dataList: function ($q, searchModule, $stateParams) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Supplier Groups';
          }
        }
      })

      /**
       * State to list suppliers by a specific supplier group / global supplier
       */
      .state('main.user.suppliers.supplierGroupDetail', {
        templateUrl: 'app/suppliersManagement/supplierGroups/detail/detail.html',
        url: '/supplierGroups/detail?{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&' +
        '{bu_code:[a-zA-Z0-9]+}&{bu_descr:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}',
        controller: 'supplierGroupDetailCtrl',
        resolve: {
          suppliersByGroup: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'suppliers',
              'criteria[1][approval_status]': 1,
              'criteria[1][archive_of]': '',
              'criteria[1][draft_status]': 3,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                params['criteria_operator'] = 'and';

                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[2][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[2][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * If searching without filter
               */
              else {
                params['criteria_operator'] = !!$stateParams.query ? 'and' : null;
                params['criteria[2][descr]'] = $stateParams.query;
                params['criteria[2][mb_supplier_code]'] = $stateParams.query;
                params['criteria[2][reg_no]'] = $stateParams.query;
                params['criteria[2][email]'] = $stateParams.query;
                params['criteria[2][contact_person.first_name]'] = $stateParams.query;
                params['criteria[2][contact_person.email]'] = $stateParams.query;
                params['criteria[2][primary_business_nature]'] = $stateParams.query;
                params['criteria[2][primary_commodity.descr]'] = $stateParams.query;
                params['criteria[2][secondary_commodity.descr]'] = $stateParams.query;
                params['criteria[2][currency_code]'] = $stateParams.query;
                params['criteria[2][creator.display_name]'] = $stateParams.query;
                params['criteria[2][updater.display_name]'] = $stateParams.query;
                params['criteria[2][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            var bu_param = 'criteria[0][bu_status|' + $stateParams.bu_code + '|status]';

            /**
             * Check if selected business unit or global
             * Switch case to handle the bu status params
             */
            if ($stateParams.bu_code.toLowerCase() !== 'global') {
              /**
               * Params for business unit status
               */
              switch ($stateParams.status) {
                case 'all':
                  params['criteria[0][bu_status|' + $stateParams.bu_code + '|status][0]'] = 1;
                  params['criteria[0][bu_status|' + $stateParams.bu_code + '|status][1]'] = 2;
                  params['criteria[0][bu_status|' + $stateParams.bu_code + '|status][2]'] = 6;
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'active':
                  params[bu_param] = 1;
                  break;
                case 'black_listed':
                  params[bu_param] = 2;
                  break;
                case 'inactive':
                  params[bu_param] = 6;
                  break;
              }
            }
            else {
              /**
               * Params for global suppliers
               */
              params['criteria[0][is_global_supplier]'] = 'true';
              switch ($stateParams.status) {
                case 'all':
                  break;
                case 'active':
                  params['criteria[0][status]'] = 1;
                  params['criteria[0][$operator]'] = 'and';
                  break;
                case 'black_listed':
                  params['criteria[0][status]'] = 2;
                  params['criteria[0][$operator]'] = 'and';
                  break;
                case 'inactive':
                  params['criteria[0][status]'] = 6;
                  params['criteria[0][$operator]'] = 'and';
                  break;
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Supplier Business Unit - Supplier Listing';
          }
        }
      })
      .state('main.user.suppliers.import', {
        templateUrl: 'app/suppliersManagement/import/suppliersImportTemplate.html',
        url: '/import',
        controller: 'suppliersItemsCtrl',
        resolve: {
          $title: function ($stateParams) {
            return 'Supplier - Import Supplier';
          }
        }
      })
      .state('main.user.suppliers.newSupplier', {
        templateUrl: 'app/suppliersManagement/newSupplier/newSupplier.html',
        url: '/newSupplier',
        controller: 'newSupplierCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkSupplierAccess(profile, 'C')) {
              $state.go('notAuthorized');
            }
          },
          taxes: function (searchModule, $q) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('taxes'))) {
              searchModule.get(
                {
                  module: 'tax',
                  offset: 0
                },
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
                  }
                  else {
                    deferred.resolve([]);
                  }
                },
                function () {
                  deferred.resolve([]);
                }
              );
            } else {
              deferred.resolve(JSON.parse(window.localStorage.getItem('taxes')));
            }
            return deferred.promise;
          },
          paymentMethods: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentMethods
            if (!JSON.parse(window.localStorage.getItem('paymentMethods'))) {
              searchModule.get(
                {
                  module: 'payment-method',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    deferred.resolve(resource.content);
                    window.localStorage.setItem('paymentMethods', JSON.stringify(resource.content));
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentMethods'));
            }
          },
          paymentTerms: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentTerms
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies')) && !!JSON.parse(window.localStorage.getItem('currencies')).length) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          commodities: function ($q, listChild) {
            var deferred = $q.defer();

            listChild.get(
              {
                code: 'All'
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - New Supplier' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
      .state('main.user.suppliers.detail', {
        templateUrl: 'app/suppliersManagement/detail/detail.html',
        url: '/detail?{id:[a-zA-Z0-9]+}',
        controller: 'supplierDetailCtrl',
        resolve: {
          supplier: function ($q, $state, $stateParams, $filter, authenticated, singleSupplier, $rootScope, toastr) {
            var deferred = $q.defer();
            singleSupplier.get(
              {id: $stateParams.id},
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content.data);
                } else {
                  deferred.resolve({});
                }
              },
              function (error) {
                toastr.error('Supplier not found');
                $state.go($rootScope.previousState.name, $rootScope.previousState.params);
              }
            );
            return deferred.promise;
          },
          taxes: function (searchModule, $q) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('taxes'))) {
              searchModule.get(
                {
                  module: 'tax',
                  offset: 0
                },
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
                  }
                  else {
                    deferred.resolve([]);
                  }
                },
                function () {
                  deferred.resolve([]);
                }
              );
            }
            else {
              deferred.resolve(JSON.parse(window.localStorage.getItem('taxes')));
            }
            return deferred.promise;
          },
          paymentMethods: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentMethods
            if (!JSON.parse(window.localStorage.getItem('paymentMethods'))) {
              searchModule.get(
                {
                  module: 'payment-method',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content) {
                    deferred.resolve(resource.content);
                    window.localStorage.setItem('paymentMethods', JSON.stringify(resource.content));
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
              return deferred.promise;
            }
            else {
              return JSON.parse(window.localStorage.getItem('paymentMethods'));
            }
          },
          paymentTerms: function (searchModule, $q) {
            var deferred = $q.defer();
            //load the paymentTerms
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          },
          currencies: function ($stateParams, $q, searchModule) {
            var deferred = $q.defer();

            if (!!JSON.parse(window.localStorage.getItem('currencies'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('currencies')));
            } else {
              searchModule.get(
                {
                  module: 'currencies',
                  offset: 0
                },
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    window.localStorage.setItem('currencies', JSON.stringify(resource.content.data));
                    deferred.resolve(resource.content.data);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }

            return deferred.promise;
          },
          commodities: function ($q, listChild) {
            var deferred = $q.defer();

            listChild.get(
              {
                code: 'All'
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                }
                else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Supplier - Details' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
      .state('main.user.suppliers.supplierTagging', {
        templateUrl: 'app/suppliersList/supplierTagging/supplierTagging-template.html',
        url: '/SupplierTagging?{companyCode:[a-zA-Z0-9]+}',
        controller: 'supplierTaggingCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'C', 'SupplierTag') ||
              !UserPermissions.checkPermissionsAccess(profile, 'U', 'SupplierTag')) {
              $state.go('notAuthorized');
            }
          },
          companiesList: function ($q, searchModule, authenticated) {
            var deferred = $q.defer();

            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }

            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          // Resolve the suppliers list for ui-select multiple
          suppliersList: function ($q, suppliersActiveList) {
            var deferred = $q.defer();

            /**
             * Setting list of active suppliers to local storage to reduce loading times
             */
            if (!!JSON.parse(window.localStorage.getItem('activeSuppliersForTagging'))) {
              deferred.resolve(JSON.parse(window.localStorage.getItem('activeSuppliersForTagging')));
            }
            else {
              suppliersActiveList.get(
                {},
                function (resource) {
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    var tempList = [];
                    _.forEach(resource.content.data, function (supplier) {
                      tempList.push({
                        _id: supplier._id,
                        descr: supplier.descr,
                        mb_supplier_code: supplier.mb_supplier_code
                      });
                    });
                    window.localStorage.setItem('activeSuppliersForTagging', JSON.stringify(tempList));
                    deferred.resolve(tempList);
                  } else {
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                });
            }
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Tag Suppliers';
          }
        }
      })
      // TODO: rethink of a better state name to describe these lists (Justin 25/5/17)
      .state('main.user.suppliers.tagsBySupplier', {
        templateUrl: 'app/suppliersList/supplierTagsBySupplier/manage/manage.html',
        url: '/tagsBySupplier?{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsBySupplierCtrl',
        resolve: {
          detailState: function () {
            return 'main.user.suppliers.supplierTagsBySupplierDetail';
          },
          suppliersList: function ($q, searchModule, authenticated, $stateParams) {
            var deferred = $q.defer();

            var params = {
              module: 'suppliers',
              'criteria[0][archive_of]': '',
              'criteria[0][draft_status]': 3,
              'criteria[0][status]': 1,
              'criteria[0][$operator]': 'and',
              'criteria[0][approval_status]': 1,
              criteria_operator: 'and',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }

            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              else {
                params['criteria[1][descr]'] = $stateParams.query;
                params['criteria[1][mb_supplier_code]'] = $stateParams.query;
                params['criteria[1][reg_no]'] = $stateParams.query;
                params['criteria[1][email]'] = $stateParams.query;
                params['criteria[1][contact_person.first_name]'] = $stateParams.query;
                params['criteria[1][contact_person.email]'] = $stateParams.query;
                params['criteria[1][primary_business_nature]'] = $stateParams.query;
                params['criteria[1][primary_commodity.descr]'] = $stateParams.query;
                params['criteria[1][secondary_commodity.descr]'] = $stateParams.query;
                params['criteria[1][currency_code]'] = $stateParams.query;
                params['criteria[1][creator.display_name]'] = $stateParams.query;
                params['criteria[1][updater.display_name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Supplier List';
          }
        }
      })
      .state('main.user.suppliers.supplierTagsBySupplierDetail', {
        templateUrl: 'app/suppliersList/supplierTagsBySupplier/detail/detail.html',
        url: '/supplierTagsBySupplierDetail?{supplierCode:[a-zA-Z0-9]+}&{supplierName:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsBySupplierDetailCtrl',
        resolve: {
          supplierName: function ($stateParams) {
            return $stateParams.supplierName;
          },
          companiesTaggedToSupplier: function ($q, searchModule, getTagsBySupplierCode, exportSearch, $stateParams) {
            var deferred = $q.defer();

            /**
             * Setting the params to prepare for exporting
             */
            var exportParams = {
              module: 'supplier-tags',
              'criteria[supplier_code]': $stateParams.supplierCode
            };

            exportSearch.setParams(exportParams);

            var params = {
              module: 'companies',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              criteria_operator: 'and',
              'criteria[0][supplier_tags.supplier_code]': $stateParams.supplierCode
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = 'criteria[1][' + $stateParams.filter + ']';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params[temp] = $stateParams.query;
                }
              }
              else {
                params['criteria[1][descr]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[1][reg_no]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[1][currency_code]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Companies Tagged to Supplier';
          }
        }
      })
      .state('main.user.suppliers.tagsByCompany', {
        templateUrl: 'app/suppliersList/supplierTagsByCompany/manage/manage.html',
        url: '/tagsByCompany?{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsByCompanyCtrl',
        resolve: {
          detailState: function () {
            return 'main.user.suppliers.supplierTagsByCompanyDetail';
          },
          companiesList: function ($q, searchModule, profile, $stateParams, authenticated, globalFunc) {
            var deferred = $q.defer();
            var userCompanyCodes = [];

            _.forEach(profile.companies, function (company) {
              userCompanyCodes.push(company.code);
            });

            var params = {
              module: 'companies',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params[temp] = $stateParams.query;
                }
              }
              else {
                params['criteria[0][descr]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][reg_no]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][currency_code]'] = !!$stateParams.query ? $stateParams.query : null;
                params['criteria[0][$operator]'] = !!$stateParams.query ? 'or' : null;
              }

              if (!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN')) {
                params['criteria[1][code][]'] = userCompanyCodes;
                params['criteria_operator'] = 'and';
              }
            }
            else {
              if (!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN')) {
                params['criteria[0][code][]'] = userCompanyCodes;
                params['criteria_operator'] = 'and';
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Company List';
          }
        }
      })
      .state('main.user.suppliers.supplierTagsByCompanyDetail', {
        templateUrl: 'app/suppliersList/supplierTagsByCompany/detail/detail.html',
        url: '/supplierTagsByCompanyDetail?{companyId:[a-zA-Z0-9]+}&{companyCode:[a-zA-Z0-9]+}&' +
        '{companyName:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]*}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&' +
        '{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'supplierTagsByCompanyDetailCtrl',
        resolve: {
          canEditSupplierTag: function (profile, UserPermissions) {
            return (!!UserPermissions.checkPermissionsAccess(profile, 'C', 'SupplierTag') &&
            !!UserPermissions.checkPermissionsAccess(profile, 'U', 'SupplierTag'));
          },
          detailState: function () {
            return 'main.user.suppliers.supplierTagging';
          },
          companyName: function ($stateParams) {
            return $stateParams.companyName;
          },
          suppliersTaggedToCompany: function ($q, searchModule, getTagsByCompanyCode, exportSearch, $stateParams) {
            var deferred = $q.defer();

            /**
             * Setting the params to prepare for exporting
             */
            var exportParams = {
              module: 'supplier-tags',
              'criteria[company_code]': $stateParams.companyCode
            };

            exportSearch.setParams(exportParams);

            var params = {
              module: 'suppliers',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              'criteria[0][archive_of]': '',
              'criteria[0][draft_status]': 3,
              'criteria[0][status]': 1,
              'criteria[0][$operator]': 'and',
              'criteria[0][approval_status]': 1,
              criteria_operator: 'and',
              'criteria[0][supplier_tags.company_code]': $stateParams.companyCode
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              else {
                params['criteria[1][descr]'] = $stateParams.query;
                params['criteria[1][reg_no]'] = $stateParams.query;
                params['criteria[1][email]'] = $stateParams.query;
                params['criteria[1][contact_person.first_name]'] = $stateParams.query;
                params['criteria[1][contact_person.email]'] = $stateParams.query;
                params['criteria[1][primary_business_nature]'] = $stateParams.query;
                params['criteria[1][primary_commodity.descr]'] = $stateParams.query;
                params['criteria[1][secondary_commodity.descr]'] = $stateParams.query;
                params['criteria[1][currency_code]'] = $stateParams.query;
                params['criteria[1][creator.display_name]'] = $stateParams.query;
                params['criteria[1][updater.display_name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = !!$stateParams.query ? 'or' : null;
              }
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Supplier - Suppliers Tagged to Company';
          }
        }
      });
  }]);
