'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider, pathConstants) {

    $stateProvider
      .state('main.user.orders', {
        url: '/orders',
        controller: 'OrdersCtrl',
        templateUrl: 'app/orders/orders.html',
        resolve : {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPOAccess(profile, 'O') || !UserPermissions.checkPOAccess(profile, 'L')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.orders.manage', {
        templateUrl: 'app/orders/manage/manage.html',
        url: '/manage?{query:[a-zA-Z0-9]+}&{status:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'OrdersManageCtrl',
        resolve: {
          orders: function ($rootScope, $q, $state, $stateParams, Orders, searchModule, profile, globalFunc, exportSearch) {
            var deferred = $q.defer();
            var params = {};

            var status = globalFunc.availableStatuses('po', $stateParams.status);

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
            /**
             * If searching , check if searching with filter
             */
            if (!!$stateParams.filter) {
              params = {
                module: 'PO',
                offset: 20,
                criteria_operator: 'and',
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              }
              else if ($stateParams.type === 'double') {
                temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              }
              else {
                params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
              }
            }
            /**
             * Else for listing and sorting without filter
             * Pass criteria[status] in an array with or condition based on status
             * Submitted tab will list submitted, delivery_date_unconfirmed and awaiting_confirmation status
             * Awaiting delivery tab will list awaiting_delivery, partially_delivered and in_port status
             * Closed tab will list closed and delivered status
             * Declined tab will list declined status
             * Cancelled tab will list cancelled status
             * Starred tab will list PO if stared_by current user
             */
            else {
              params = {
                module: 'PO',
                offset: 20,
                'criteria[1][po_number]': $stateParams.query,
                'criteria[1][pr_number]': $stateParams.query,
                'criteria[1][title]': $stateParams.query,
                'criteria[1][supplier.descr]': $stateParams.query,
                'criteria[1][billing|company|descr]': $stateParams.query,
                'criteria[1][do_number]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'and' : null,
                'criteria[1][$operator]': 'or',
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }
          }
            /**
             * Else for listing
             */
          else{
            params = {
              module: 'PO',
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            }
          }

            if (!!status) {
              /**
               * Pass param into status based on tab status
               */
              switch(status){
                case 'SUBMITTED':
                  params['criteria[0][status][0]'] = 'SUBMITTED';
                  params['criteria[0][status][1]'] = 'DELIVERY_DATE_UNCONFIRMED';
                  params['criteria[0][status][2]'] = 'AWAITING_CONFIRMATION';
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'AWAITING_DELIVERY':
                  params['criteria[0][status][0]'] = 'AWAITING_DELIVERY';
                  params['criteria[0][status][1]'] = 'PARTIALLY_DELIVERED';
                  params['criteria[0][status][2]'] = 'IN_PORT';
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'CLOSED':
                  params['criteria[0][status][0]'] = 'CLOSED';
                  params['criteria[0][status][1]'] = 'FULLY_DELIVERED';
                  params['criteria[0][$operator]'] = 'or';
                  break;
                case 'DECLINED':
                  params['criteria[0][status][0]'] = 'DECLINED';
                  break;
                case 'CANCELLED':
                  params['criteria[0][status][0]'] = 'CANCELLED';
                  break;
                case 'STARRED':
                  params['criteria[0][stared_by][]'] = profile._id;
                  break;
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "PO - listing" + (!!$stateParams.status ? " - " +
              $stateParams.status.toLowerCase() : "");
          }
        }
      })
      .state('main.user.orders.detail', {
        templateUrl: 'app/orders/detail/detail.html',
        url: '/{id:[a-zA-Z0-9]+}?{title:[a-zA-Z0-9]*}&{action:[a-zA-Z0-9]*}',
        controller: 'OrdersDetailCtrl',
        resolve: {
          order: function ($rootScope, $q, $state, toastr, $stateParams, $filter, Orders,
                           authenticated) {
            var deferred = $q.defer();

            if (!authenticated) {
              deferred.resolve({});
              return deferred.promise;
            }

            var method = Orders.get;
            method(
              {id: $stateParams.id},
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data){
                  deferred.resolve(resource.content.data);
                } else {
                  deferred.resolve({});
                }
              },
              function (error) {
                toastr.error("PO not found");
                if(!!$rootScope.previousState && !!$rootScope.previousState.name){
                  $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                } else {
                  $state.go('main.user.orders.manage', {status: $stateParams.status || "SUBMITTED"});
                }
              }
            );
            return deferred.promise;
          },
          POSubscriptionCheck: function($q, order, subscriptionCheck){
            var deferred = $q.defer();
            subscriptionCheck.get(
              {
                object_id: order._id,
                class: 'Metabuyer\\Models\\PO'
              },
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function($stateParams){
            return "PO - detail" + (!!$stateParams.id? " - " + $stateParams.id : "");
          }
        }
      });
  }]);
