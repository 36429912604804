'use strict';

function createPurchaseRequisitionButtonCtrl($scope, purchaseRequisitionsServices, $rootScope, $state,
                                             prFunction, globalFunc) {

  function createPR(company) {
    $scope.title = 'Loading ...';
    $scope.disabled = true;

    /**
     * add the new field required by the backend
     */
    company.company_id = company._id;

    purchaseRequisitionsServices.createPR({
        company: {company_id : company._id}
      },
      function (resource) {
        prFunction.setPRData({});
        $state.go('main.user.purchaseRequisitions.details', {id: resource.content.data._id, PR: resource.content});
        $scope.title = 'Create PR';
        $scope.disabled = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.title = 'Create PR';
        $scope.disabled = false;
      }
    );

  }

  /**
   * This function return a boolean to show or hiding the create PR button
   * @returns {boolean}
   */
  function showCreateButton(){
    if($state.current.name.indexOf('purchaseRequisitions') > -1)
      return true;
    else if ($state.current.name.indexOf('suppliersList') > -1)
      return true;
    else if ($state.current.name.indexOf('orders') > -1)
      return true;
    else
      return false;
  }

  $scope.disabled = false;
  $scope.title = 'Create PR';
  $scope.listOfCompanies = $rootScope.listOfCompaniesForLoggedInUser;
  $scope.createPR = createPR;
  $scope.showCreateButton = showCreateButton;
}


createPurchaseRequisitionButtonCtrl.$inject= ['$scope', 'purchaseRequisitionsServices', '$rootScope', '$state',
                                              'prFunction', 'globalFunc'];

angular
  .module('metabuyer')
  .directive('createPurchaseRequisitionButton',function() {
    return{
      restrict: 'E',
      templateUrl: 'components/purchaseRequisitionComponents/createPurchaseRequisitionButton/createPurchaseRequisitionButtonTemplate.html',
      scope: {},
      controller: 'createPurchaseRequisitionButtonCtrl'
    };
  })
  .controller('createPurchaseRequisitionButtonCtrl', createPurchaseRequisitionButtonCtrl);
