'use strict';
/**
 * @name costCenterDetailCtrl
 * @desc Controller for cost centers detail
 */
function costCenterDetailCtrl($scope, $state, toastr, costCenterDetails, CostCenters,
                              $uibModalInstance, singleAddress, addressList, globalFunc) {

  $scope.costCenterDetails = costCenterDetails;
  $scope.delivery_address = $scope.costCenterDetails.corresponding_address;
  $scope.editClicked = false;

  $scope.backToDetailCompanies = backToDetailCompanies;
  $scope.cancel = cancel;
  $scope.editCostCenter = editCostCenter;
  $scope.saveDeliveryAddress = saveDeliveryAddress;
  $scope.saveDeliveryAddressChanges = saveDeliveryAddressChanges;
  $scope.updateChanges = updateChanges;
  $scope.deleteCostCenter = deleteCostCenter;
  $scope.validateInput = validateInput;
  $scope.addressesList = addressesList;
  $scope.editCostCenterUsers = editCostCenterUsers;

  $scope.duplicateAddress = {
    value: true
  };

  function cancel() {
    $uibModalInstance.close();
  }

  function backToDetailCompanies() {
    $state.go($scope.previousState.name, $scope.previousState.params);
  }

  function editCostCenter(){
    $scope.editClicked = true;
  }

  function saveDeliveryAddress(){
    //To set default to follow corresponding
    if(!$scope.delivery_address.line1 && !$scope.delivery_address.postal_code
      && !$scope.delivery_address.city && !$scope.delivery_address.state && !$scope.delivery_address.country){
      $scope.duplicateAddress.value = true;
    }

    var submit_data = {
      line1: $scope.delivery_address.line1,
      line2: $scope.delivery_address.line2,
      line3: $scope.delivery_address.line3,
      postal_code: $scope.delivery_address.postal_code,
      city: $scope.delivery_address.city,
      state: $scope.delivery_address.state,
      country: $scope.delivery_address.country
    };
    if(!!$scope.duplicateAddress.value){
      $scope.costCenterDetails.delivery_address_id = $scope.companyDetails.corresponding_address._id;
    }

    addressList.post(
      submit_data,
      function(resource) {
        if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id){
          $scope.costCenterDetails.delivery_address_id = resource.content.data._id;
          $scope.updateCostCenter();
        }
        else {
          toastr.error("Delivery address failed to add");
        }
      },
      function(error){
        // Check the error is Object or string
        // If String then load the message
        // else Loop the message object
        if (typeof error.data.message === 'string') {
          toastr.error(error.data.message);
        }else {
          // loop the error object
          angular.forEach(error.data.message, function (errorDatas) {
            // Get the object key
            angular.forEach(errorDatas, function () {
              // show the array value of the Error from the json
              toastr.error(errorDatas[0]);
            });

          });
        }
      }
    );
  }

  function saveDeliveryAddressChanges(){
    //To set default to follow corresponding
    if(!$scope.delivery_address.line1 && !$scope.delivery_address.postal_code
      && !$scope.delivery_address.city && !$scope.delivery_address.state && !$scope.delivery_address.country){
      $scope.duplicateAddress.value = true;
    }

      var update_data = {
        line1: $scope.delivery_address.line1,
        line2: $scope.delivery_address.line2,
        line3: $scope.delivery_address.line3,
        postal_code: $scope.delivery_address.postal_code,
        city: $scope.delivery_address.city,
        state: $scope.delivery_address.state,
        country: $scope.delivery_address.country
      };

      singleAddress.put(
        {
          id: $scope.delivery_address._id
        },
        update_data,
        function (resource) {
          if (!!resource._id) {
            $scope.costCenterDetails.delivery_address_id = resource._id;
          }
          $scope.updateCostCenter();
        },
        function (error) {
          // Check the error is Object or string
          // If String then load the message
          // else Loop the message object
          if (typeof error.data.message === 'string') {
            toastr.error(error.data.message);
          } else {
            // loop the error object
            angular.forEach(error.data.message, function (errorDatas) {
              // Get the object key
              angular.forEach(errorDatas, function () {
                // show the array value of the Error from the json
                toastr.error(errorDatas[0]);
              });

            });
          }
          $scope.updateCostCenter();
        }
      );
  }

  function validateInput(){
    if (!!$scope.costCenterDetails.descr && !!$scope.costCenterDetails.descr.length
      && !!$scope.costCenterDetails.code && !!$scope.costCenterDetails.code.length){
      return true;
    }

    if (!$scope.duplicateAddress.value){
      if(!!$scope.delivery_address.line1 && !!$scope.delivery_address.line1.length
      && !!$scope.delivery_address.postal_code && !!$scope.delivery_address.postal_code.length
      && !!$scope.delivery_address.city && !!$scope.delivery_address.city.length
      && !!$scope.delivery_address.state && !!$scope.delivery_address.state.length
      && !!$scope.delivery_address.country && !!$scope.delivery_address.country.length){
        return true;
      }
    }
    return false;
  }

  function updateChanges(){
    $scope.submitted = true;
    if(validateInput() && !$scope.duplicateAddress.value) {
      if(!$scope.delivery_address._id){
        saveDeliveryAddress();
      }
      else if(!!$scope.delivery_address._id === !!$scope.companyDetails.corresponding_address._id){
        saveDeliveryAddress();
      }
      else {
        saveDeliveryAddressChanges();
      }
    }
    else if(!!$scope.duplicateAddress.value){
      $scope.costCenterDetails.delivery_address_id = $scope.companyDetails.corresponding_address._id;
      $scope.updateCostCenter();
    }
    else{
      return false;
    }
  }

  $scope.updateCostCenter = function(){
    var update_data = {
      _id: $scope.costCenterDetails._id,
      descr: $scope.costCenterDetails.descr,
      corresponding_address_id: $scope.costCenterDetails.corresponding_address_id,
      billing_addresses_ids: globalFunc.getArrayOfKeyValues($scope.costCenterDetails.billing_addresses, '_id'),
      delivery_addresses_ids: globalFunc.getArrayOfKeyValues($scope.costCenterDetails.delivery_addresses, '_id')
    };
    CostCenters.put(
      {
        id: $scope.costCenterDetails._id
      }, update_data ,
      function(){
        toastr.success('The Cost Center was updated successfully');
        $uibModalInstance.close($scope.costCenterDetails);
      },
      function(){
        toastr.error('Failed to update the Cost Center');
      }
    );
  };

  function deleteCostCenter(){
    var id = $scope.costCenterDetails._id;
    swal({
        title: "Confirm delete Cost Center?",
        text: "This Cost Center will be deleted. Please confirm",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function() {
        CostCenters.delete(
          {
            id: id
          },
          function() {
            toastr.success('Deleted cost center');
            for (var i in $scope.companyDetails.costCenters) {
              if ($scope.companyDetails.costCenters[i]._id === id) {
                $scope.companyDetails.costCenters.splice(i, 1);
              }
            }
            $uibModalInstance.close();
          },
          function() {
            toastr.error('Failed to delete cost center');
          }
        )
      });
  }

  function addressesList(id) {
    cancel();
    $state.go('main.user.companiesMgmt.costCenterDetail', {companyId: $scope.companyDetails._id, id: id });
  }

  /**
   * Navigate to cost center editing page
   *
   * @param {string} costCenterId Cost center id to be edited
   */
  function editCostCenterUsers(costCenterId) {
    cancel();
    $state.go('main.user.companiesMgmt.detailCompanies.updateCostCenter', {costCenterId: costCenterId});
  }
}

costCenterDetailCtrl.$inject = ['$scope', '$state', 'toastr', 'costCenterDetails', 'CostCenters',
                                '$uibModalInstance', 'singleAddress', 'addressList', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('costCenterDetailCtrl', costCenterDetailCtrl);
