'use strict';

function supplierDetailCtrl(
  $scope, $q, toastr, supplier, taxes, singleSupplier, paymentMethods, paymentTerms, $state, $location, currencies,
  HighlightId, addressList, singleAddress, Approvals, profile, globalFunc, approvalSupplier, searchAllCommodity,
  listChild, supplierGlobalFunc, draftSupplier, supplierArchives, $uibModal, pathConstants, $http, $rootScope,
  attachmentDetail, $filter
) {

  var supplierAttachment = [];

  $scope.supplier = supplier;
  $scope.supplier.original_status = supplier.status;
  $scope.supplier.status = supplierGlobalFunc.getSupplierStatus(
    supplier.status, supplier.approval_status, supplier.draft_status, 'descr'
  );
  $scope.approvals = [];
  $scope.approvalFlowData = {};
  $scope.waiting_on_user = [];
  $scope.commodityLevel = [3, 4];
  $scope.address = {};
  $scope.taxes = taxes;
  $scope.paymentMethods = paymentMethods.data;
  $scope.paymentTerms = paymentTerms.data;
  $scope.selectedContactPersons = [];
  $scope.selectedBranch = [];
  $scope.currencies = currencies;
  $scope.user = profile;
  $scope.editClicked = false;
  $scope.originalDescr = $scope.supplier.descr;
  $scope.originalROC = $scope.supplier.reg_no;
  $scope.originalGSTCode = $scope.supplier.gst_code;
  $scope.closeTreeSearchPrimary = false;
  $scope.closeTreeSearchSecondary = false;
  $scope.supplierVersions = [];
  $scope.supplierDraftVersions = [];
  $scope.statusUpdatePayload = {};
  $scope.pendingEditClicked = false;

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];

  // The standard list for Business Nature and Business Entity
  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING', value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY', value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR', value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER', value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR', value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER', value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING', value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER', value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER', value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR', value: 11},
    {descr: 'Trading', shortDescr: 'TRADING', value: 12},
    {descr: 'Other', shortDescr: 'OTHERS', value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', shortDescr: 'BERHAD', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'SENDIRIAN_BERHAD', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: '3'},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: '4'}
  ];

  $scope.poReceive = [
    {descr: 'System', value: 0},
    {descr: 'System and Email', value: 1},
    {descr: 'System, Email and Fax', value: 2},
    {descr: 'System, Email, Fax and Post', value: 3}
  ];

  $scope.selection = {
    poMethod: {}
  };

  $scope.freightTerms = [];

  $scope.getPaidUpCategory = getPaidUpCategory;
  $scope.backToListingSupplier = backToListingSupplier;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.onAddedContact = onAddedContact;
  $scope.onUpdatedCountry = onUpdatedCountry;
  $scope.onUpdatedState = onUpdatedState;
  $scope.revertUpdate = revertUpdate;
  $scope.setActiveTab = setActiveTab;
  $scope.removeContact = removeContact;
  $scope.updateAddress = updateAddress;
  $scope.loadCommodityList = loadCommodityList;
  $scope.selectedBumiputra = selectedBumiputra;
  $scope.selectPO = selectPO;
  $scope.updateChanges = updateChanges;
  $scope.validateEmail = validateEmail;
  $scope.validateSelectedTax = validateSelectedTax;
  $scope.validatePrimaryBusinessNature = validatePrimaryBusinessNature;
  $scope.validateSecondaryBusinessNature = validateSecondaryBusinessNature;
  $scope.checkSupplierChanges = checkSupplierChanges;
  $scope.checkWaitingOn = checkWaitingOn;
  $scope.isEditableStatusChange = isEditableStatusChange;
  $scope.loadApprovalFlow = loadApprovalFlow;
  $scope.checkApprove = checkApprove;
  $scope.checkReject = checkReject;
  $scope.duplicationCheck = duplicationCheck;
  $scope.validateNumbers = validateNumbers;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.updateStatus = updateStatus;
  $scope.editActiveSupplier = editActiveSupplier;
  $scope.showVersionList = showVersionList;
  $scope.editSupplier = editSupplier;
  $scope.reopenDraftSupplier = reopenDraftSupplier;
  $scope.withdrawSupplier = withdrawSupplier;
  $scope.back = back;
  $scope.updateSupplierStatus = updateSupplierStatus;
  $scope.downloadAttachment = downloadAttachment;
  $scope.checkHyphenFirstCharacter = checkHyphenFirstCharacter;
  $scope.requestEditableDraft = requestEditableDraft;
  $scope.submitEditableDraft = submitEditableDraft;
  $scope.cancelEditableDraft = cancelEditableDraft;
  $scope.showDraftVersionList = showDraftVersionList;

  function submitEditableDraft() {
    if (!validateOnUpdate()) {
      toastr.error('Please complete all required fields before proceeding.');
      return;
    }

    if (!paymentMethodValidation()) {
      toastr.error('Invalid payment method.');
      return;
    }

    if (!paymentTermValidation()) {
      toastr.error('Invalid payment term.');
      return;
    }

    var status = 'draft';
    var updateAndSubmit = true;

    //Update supplier to draft
    draftSupplier.put(
      {
        id: $scope.supplier._id,
        action: status
      },
      {
        status_reason: ''
      },
      function () {
        updateChanges(updateAndSubmit);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function requestEditableDraft() {
    $scope.pendingEditClicked = true;
    $scope.editClicked = true;
  }

  function cancelEditableDraft() {
    revertUpdate();
  }

  function checkHyphenFirstCharacter(string, field) {
    if (!!string) {
      $scope.descrFirstCharHyphen = supplierGlobalFunc.checkHyphenFirstCharacter(string);
    }

    return $scope.descrFirstCharHyphen;
  }

  // Add requestor
  $scope.requestor = {
    '_id': $scope.supplier.created_by._id,
    'display_name': $scope.supplier.created_by.display_name,
    'img_url': ''
  };

  $scope.methodSelected = false;
  $scope.faxChecker = false;
  $scope.emailChecker = false;

  function selectPO(poMethod) {
    $scope.methodSelected = true;
    $scope.faxChecker = false;
    $scope.emailChecker = false;

    if (poMethod === 2 || poMethod === 3)
      $scope.faxChecker = true;

    if (poMethod === 1 || poMethod === 2 || poMethod === 3)
      $scope.emailChecker = true;

  }

  //check user waiting on
  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function (approver) {
      return (approver._id === profile._id);
    });

    if (find_user !== undefined) {
      return true;
    }
    return false;
  }

  /**
   * Check if status change allows editing
   *
   * @returns {boolean}
   */
  function isEditableStatusChange() {
    // Return true if newly created draft or edit draft from active supplier
    return ((!$scope.supplier.submitted_from ||
        (!!$scope.supplier.submitted_from && $scope.supplier.submitted_from === 'ACTIVE')) &&
      (!!$scope.supplier.original_status && $scope.supplier.original_status === 'ACTIVE'));
  }

  function getUpdateAddressData() {
    var update_data = {};

    if (!!$scope.address) {
      if (!!$scope.address._id) {
        update_data._id = $scope.address._id;
      }

      update_data.line1 = $scope.address.line1;
      update_data.line2 = $scope.address.line2;
      update_data.line3 = $scope.address.line3;
      update_data.city = $scope.address.city;
      update_data.state = $scope.address.state;
      update_data.postal_code = $scope.address.postal_code;
      update_data.country = $scope.address.country;
      update_data.phone = $scope.update_phone;
      update_data.fax = $scope.update_fax;
    }

    return update_data;
  }

  function updateAddress(updateAndSubmit) {
    if (!!$scope.address && !$scope.address._id) {
      addressList.post(getUpdateAddressData(),
        function(resource){
          if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id){
            $scope.address._id = resource.content.data._id;
            $scope.updateSupplier(updateAndSubmit);
          }
        }, function (error) {
          toastr.error('Failed to update address');
          globalFunc.objectErrorMessage(error);
        }
      );
      return;
    }

    singleAddress.put(
      {
        id: $scope.address._id
      },
      getUpdateAddressData(),
      function () {
        $scope.updateSupplier(updateAndSubmit);
      }, function (error) {
        toastr.error('Failed to update address');
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * Function for loading the commodity listing based on parameter item for code
   */
  function loadCommodityList(item, callback) {
    listChild.get(
      {
        code: item
      },
      function (resource) {
        $scope.families = resource.content.data;
        if (typeof callback === 'function') {
          callback($scope.families);
        }
      },
      function (error) {
        if (error.status === 404) {
          toastr.error('Could not load families for this segment');
        }
      }
    );
  }

  function searchPrimaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 2) {
      searchAllCommodity.get({
        keywords: keyword
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $scope.primaryCommodityCodes = resource.content.data;
      }, function () {
        $scope.primaryCommodityCodes = [];
      });

      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword) {
    if (!!keyword && keyword.length > 2) {
      searchAllCommodity.get({
        keywords: keyword
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $scope.secondaryCommodityCodes = resource.content.data;
      }, function () {
        $scope.secondaryCommodityCodes = [];
      });

      return $scope.secondaryCommodityCodes;
    }
  }

  function onAddedContact(contact) {
    var spliceIndex = $scope.selectedContactPersons[0];
    if (!!spliceIndex) {
      $scope.selectedContactPersons.push(contact);
    }
    /**
     * If the first index is null, undefined or empty
     */
    else {
      $scope.selectedContactPersons.splice(spliceIndex, 1, contact);
    }
  }

  /**
   * Get child's data for primary commodity
   * @param cc
   */
  function openChildCommodityCodePrimary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.primaryCommodityCodes = resource.content.data;
        }
        else {
          toastr.error('There are no child on this commodity code');
        }
      },
      function () {
        toastr.error('Failed to get commodity codes data');
      })
  }

  /**
   * Get child's data for secondary commodity
   * @param cc
   */
  function openChildCommodityCodeSecondary(cc) {
    listChild.get({
        code: cc
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0) {
          $scope.secondaryCommodityCodes = resource.content.data;
        }
        else {
          toastr.error('There are no child on this commodity code');
        }
      },
      function () {
        toastr.error('Failed to get commodity codes data');
      })
  }

  /**
   * Function for setting bumiputra status
   */
  function selectedBumiputra(value) {
    $scope.supplier.bumiputra_status = value;
    $scope.noBumiStatus = false;
  }

  /**
   * This function is responsible for preparing the payload for PUT request on supplier
   */
  function getUpdateData() {
    var update_data = {
      _id: $scope.supplier._id
    };

    if (angular.isDefined($scope.update_email) &&
      ($scope.update_email !== $scope.supplier.email)) {
      update_data.email = $scope.update_email;
    }

    if (angular.isDefined($scope.update_fax) &&
      ($scope.update_fax !== $scope.supplier.fax)) {
      update_data.fax = $scope.update_fax;
    }

    if (angular.isDefined($scope.update_phone) &&
      ($scope.update_phone !== $scope.supplier.phone)) {
      update_data.phone = $scope.update_phone;
    }

    if (!!$scope.updateDescr) {
      update_data.descr = $scope.updateDescr;
    }

    if (angular.isDefined($scope.updateBranch)) {
      update_data.branches = $scope.updateBranch;
    }

    if (angular.isDefined($scope.update_code) &&
      ($scope.update_code !== $scope.supplier.mb_supplier_code)) {
      update_data.code = $scope.update_code;
    }

    if (!!$scope.updateRegNo) {
      update_data.reg_no = $scope.updateRegNo;
    }


    if (!!$scope.supplier.selectedTax && !!$scope.supplier.selectedTax._id) {
      if (!!$scope.supplier.tax && !!$scope.supplier.tax._id) {
        update_data.tax_id = $scope.supplier.selectedTax._id;
        if ($scope.supplier.selectedTax.code === 'NR0 - No Tax') {
          $scope.update_gst_code = null;
        }
      } else {
        update_data.tax_id = $scope.supplier.selectedTax._id;
      }
    }

    if (angular.isDefined($scope.update_gst_code) && $scope.update_gst_code !== null) {
      update_data.gst_code = $scope.update_gst_code;
    }

    if (angular.isDefined($scope.update_paid_up_capital_amount)) {
      if ($scope.update_paid_up_capital_amount === null) {
        update_data.paid_up_value = null;
      } else {
        update_data.paid_up_value = Number($scope.update_paid_up_capital_amount);
      }
    }

    if (angular.isDefined($scope.supplier.primaryCommodity) &&
      angular.isDefined($scope.supplier.primaryCommodity.code)) {
      if ($scope.supplier.primaryCommodity.code !== $scope.supplier.primary_commodity_code) {
        update_data.primary_commodity_code = $scope.supplier.primaryCommodity.code;
      }
    }

    if (!!$scope.supplier.secondaryCommodity && !!$scope.supplier.secondaryCommodity.code) {
      if ($scope.supplier.secondaryCommodity.code !== $scope.supplier.secondary_commodity_code) {
        update_data.secondary_commodity_code = $scope.supplier.secondaryCommodity.code;
      }
    }

    if (angular.isDefined($scope.supplier.selectedPrimaryBusinessNature) &&
      angular.isDefined($scope.supplier.selectedPrimaryBusinessNature.value)) {
      update_data.primary_business_nature = $scope.supplier.selectedPrimaryBusinessNature.shortDescr;
    }

    if (angular.isDefined($scope.supplier.selectedSecondaryBusinessNature) &&
      angular.isDefined($scope.supplier.selectedSecondaryBusinessNature.value)) {
      update_data.secondary_business_nature = $scope.supplier.selectedSecondaryBusinessNature.shortDescr;
    }

    if (angular.isDefined($scope.supplier.selectedBusinessEntity) &&
      angular.isDefined($scope.supplier.selectedBusinessEntity.value)) {
      update_data.business_entity = $scope.supplier.selectedBusinessEntity.value;
    }

    if (!!$scope.supplier.selectedPaymentTerms && !!$scope.supplier.selectedPaymentTerms.code) {
      if (!!$scope.supplier.pay_terms && !!$scope.supplier.pay_terms.code) {
        if ($scope.supplier.selectedPaymentTerms.code !== $scope.supplier.pay_terms.code) {
          update_data.pay_terms_code = $scope.supplier.selectedPaymentTerms.code;
        }
      } else {
        update_data.pay_terms_code = $scope.supplier.selectedPaymentTerms.code;
      }
    }

    if (!!$scope.supplier.selectedPaymentMethod && !!$scope.supplier.selectedPaymentMethod.code) {
      if (!!$scope.supplier.pay_method && !!$scope.supplier.pay_method.code) {
        if ($scope.supplier.selectedPaymentMethod.code !== $scope.supplier.pay_method.code) {
          update_data.pay_method_code = $scope.supplier.selectedPaymentMethod.code;
        }
      } else {
        update_data.pay_method_code = $scope.supplier.selectedPaymentMethod.code;
      }
    }

    if (!!$scope.supplier.selectedCurrency && ($scope.supplier.selectedCurrency.code !== $scope.supplier.currency_code)) {
      update_data.currency_code = $scope.supplier.selectedCurrency.code;
    }

    if ((!!$scope.address && !$scope.supplier.corresponding_address) ||
      ($scope.supplier.corresponding_address._id && ($scope.supplier.corresponding_address._id !== $scope.address._id))) {
      update_data.corresponding_address_id = $scope.address._id;
    }

    if (checkContactPersonChanges()) {
      var temp_contact_id = [];
      _.forEach($scope.selectedContactPersons, function (item) {
        if (!!item && !!item._id)
          temp_contact_id.push(item._id);
      });

      update_data.additional_contacts_ids = temp_contact_id;

      update_data.contact_person_id = temp_contact_id[0];
      update_data.additional_contacts_ids.splice(0, 1);
    }

    if (angular.isDefined($scope.supplier.bumiputra_status))
      update_data.bumi_status = $scope.supplier.bumiputra_status;

    if (!!$scope.supplier.bu_status && !!$scope.supplier.bu_status.length && !$scope.supplier.is_global_supplier)
      update_data.bu_status = supplierGlobalFunc.prepareSupplierGroup($scope.supplier.bu_status);
    else
      update_data.bu_status = {};

    update_data.receive_via_system = $scope.supplier.receive_via_system;
    update_data.receive_via_email = $scope.supplier.receive_via_email;
    update_data.receive_via_fax = $scope.supplier.receive_via_fax;
    update_data.receive_via_post = $scope.supplier.receive_via_post;
    update_data.is_global_supplier = $scope.supplier.is_global_supplier.toString();

    return update_data;
  }

  $scope.updateSupplier = function (updateAndSubmit) {
    var payload = getUpdateData();
    if (!!$scope.supplier.attachments_descriptions && !!$scope.supplier.attachments_descriptions.length) {
      prepareAttachmentsDetails(payload, updateAndSubmit, 0);
    } else {
      updateDraftSupplier(payload, updateAndSubmit);
    }
  };

  function updateDraftSupplier (params,  updateAndSubmit, index) {
    supplierAttachment = [];
    draftSupplier.put(
      {
        id: $scope.supplier._id
      }, params,
      function (resource) {
        toastr.success('The supplier was updated successfully.');
        $scope.descrTitle = $scope.updateDescr;
        $scope.editClicked = false;
        if (!!$scope.submitted)
          $scope.submitted = false;

        if (!!index)
          $scope.supplier.attachments_descriptions[index]._id = resource.content.data.attachments_descriptions[index]._id;

        if (!!updateAndSubmit)
          updateStatus('submit', updateAndSubmit);
      },
      function (error) {
        toastr.error('Failed to update the supplier!');
        globalFunc.objectErrorMessage(error);
        revertUpdate();
      }
    );
  }

  /**
   * prepare attachment details for the payload
   * @param payload
   * @param updateAndSubmit
   * @param index (attachment index)
   */
  function prepareAttachmentsDetails(payload, updateAndSubmit, index) {
    var attachment = $scope.supplier.attachments_descriptions[index];
    if (!!attachment) {
      if (attachment.attachment_detail.file) {
        uploadAttachment(attachment.attachment_detail.file)
          .then(
            function (resource) {
              if (!!resource && !!resource.data && !!resource.data.content && !!resource.data.content.data)
                supplierAttachmentsPayload(
                  attachment._id,
                  attachment.attachment_category._id,
                  resource.data.content.data._id,
                  attachment.description_array
                );

              prepareAttachmentsDetails(payload, updateAndSubmit, ++index);
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
              supplierAttachment = [];
            }
          );
      } else {
        supplierAttachmentsPayload(
          attachment._id,
          attachment.attachment_category._id,
          attachment.attachment_detail._id,
          attachment.description_array
        );

        prepareAttachmentsDetails(payload, updateAndSubmit, ++index);
      }
    } else {
      payload.attachments_descriptions = supplierAttachment;
      updateDraftSupplier(payload, updateAndSubmit, index);
    }
  }

  function supplierAttachmentsPayload(descriptionId, attachmentCategoryId, attachmentId, descriptionArray) {
    var params = {
      _id: descriptionId,
      attachment_category_id: attachmentCategoryId,
      attachment_id: attachmentId
    };
    params = globalFunc.reformatArrayToObject(
      descriptionArray, 'name', 'value', params
    );
    supplierAttachment.push(params);
  }

  function uploadAttachment(attachmentFile) {
    var fd = new FormData();
    fd.append("file", attachmentFile._file);
    return $http.post(
      pathConstants.apiUrls.attachments.reference, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }
    );
  }

  function validateEmail(email) {
    var regex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  function validateSelectedTax(taxModel) {
    if (taxModel.code === 'NR0 - No Tax') {
      $scope.update_gst_code = undefined;
    }
  }

  function validatePrimaryBusinessNature(primary) {
    $scope.primaryBN = primary.value;
    if (angular.isDefined($scope.secondaryBN) ||
      (!!$scope.supplier.selectedSecondaryBusinessNature && !!$scope.supplier.selectedSecondaryBusinessNature.value)) {
      if ((($scope.secondaryBN || $scope.supplier.selectedSecondaryBusinessNature.value) === primary.value) &&
        primary.value !== 0) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  function validateSecondaryBusinessNature(secondary) {
    $scope.secondaryBN = secondary.value;
    if (angular.isDefined($scope.primaryBN) ||
      angular.isDefined($scope.supplier.selectedPrimaryBusinessNature.value)) {
      if ((($scope.primaryBN || $scope.supplier.selectedPrimaryBusinessNature.value) === secondary.value) &&
        secondary.value !== 0) {
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      } else {
        $scope.validatedBN = false;
      }
    }
  }

  /**
   * Validation for update data
   */
  function validateOnUpdate() {
    if (!$scope.updateDescr || !!$scope.descrCheck)
      return false;

    if (!$scope.updateRegNo || !!$scope.rocCheck)
      return false;

    if (!!$scope.gstCheck)
      return false;

    return true;
  }

  /**
   * Validation for submit
   * @returns {boolean}
   */
  function validate() {

    if (angular.isUndefined($scope.supplier.bumiputra_status)) {
      $scope.noBumiStatus = true;
      return false;
    }

    if ($scope.validatedBN === true) {
      return false;
    }

    if ($scope.validatedCommodity === true) {
      return false;
    }

    if (checkHyphenFirstCharacter($scope.updateDescr, 'descr')) {
      return false;
    }

    if (!$scope.updateDescr || !$scope.updateDescr.length || $scope.descrCheck) {
      return false;
    }

    if (!$scope.updateRegNo || !$scope.updateRegNo.length || $scope.rocCheck) {
      return false;
    }
    /**
     * check if tax selected is not NR, then validate gst code exist and gst duplicate
     */
    if (!$scope.update_gst_code && (!!$scope.supplier.selectedTax && !!$scope.supplier.selectedTax &&
        $scope.supplier.selectedTax.code !== 'NR0 - No Tax') || $scope.gstCheck) {
      return false;
    }

    /**
     * check if tax selected
     */
    if (!$scope.supplier.selectedTax) {
      return false;
    }

    if (!$scope.supplier.primaryCommodity) {
      return false;
    }

    if (!$scope.supplier.selectedPrimaryBusinessNature) {
      return false;
    }

    if (!$scope.supplier.selectedBusinessEntity) {
      return false;
    }

    if (!$scope.supplier.receive_via_email && !$scope.supplier.receive_via_fax && !$scope.supplier.receive_via_post) {
      return false;
    }

    if (!$scope.supplier.selectedPaymentTerms) {
      return false;
    }

    if (!$scope.supplier.selectedPaymentMethod) {
      return false;
    }

    if (!$scope.supplier.selectedCurrency) {
      return false;
    }

    if (!$scope.isValidPhoneNo) {
      return false;
    }

    if (!$scope.isValidFaxNo) {
      return false;
    }

    if (!$scope.update_phone || !$scope.update_phone.length) {
      return false;
    }

    if ((!$scope.supplier.bu_status || (!!$scope.supplier.bu_status && !$scope.supplier.bu_status.length)) && !$scope.supplier.is_global_supplier) {
      return false;
    }

    if (!!$scope.supplier.receive_via_email) {
      if (!$scope.update_email || !$scope.update_email.length) {
        return false;
      }
    }

    if (!!$scope.supplier.receive_via_fax) {
      if (!$scope.update_fax || !$scope.update_fax.length) {
        return false;
      }
    }

    if (!!$scope.update_email && ($scope.update_email !== $scope.supplier.email) && !validateEmail($scope.update_email)) {
      return false;
    }

    if (!$scope.address || !$scope.address.line1 || !$scope.address.city || !$scope.address.state || !$scope.address.country) {
      return false;
    }

    if (!$scope.selectedContactPersons || !$scope.selectedContactPersons.length) {
      return false;
    }

    return true;
  }

  function checkAddressChanges() {
    var address_attrs = ['line1', 'line2', 'line3', 'city', 'state', 'postal_code', 'country'];

    for (var index = 0; index < address_attrs.length; index++) {
      var attr = address_attrs[index];
      if (!!$scope.address && !!$scope.address[attr]) {
        if (!$scope.supplier.corresponding_address) return true;
        if (!!$scope.supplier.corresponding_address &&
          ($scope.address[attr] !== $scope.supplier.corresponding_address[attr])) {
          return true;
        }
      }
    }

    /**
     * If supplier phone or fax is changed, also return true
     */
    if (($scope.update_phone !== $scope.supplier.phone) || ($scope.update_fax !== $scope.supplier.fax)) {
      return true;
    }

    return false;
  }

  function checkContactPersonChanges() {

    if (!!$scope.supplier.additional_contacts && !!$scope.supplier.additional_contacts.data) {
      if ($scope.selectedContactPersons.length !== $scope.supplier.additional_contacts.data.length) {
        return true;
      }

      var temp = _.find($scope.supplier.additional_contacts.data, function (item) {
        var find_item = _.find($scope.selectedContactPersons, function (temp_item) {
          return item._id === temp_item._id;
        });

        if (!!find_item) {
          return false;
        }

        return true;
      });

      if (!!temp) {
        return true;
      }
    }

    if (!!$scope.supplier.additional_contacts && !!$scope.supplier.additional_contacts.data) {
      if ($scope.selectedContactPersons.length !== $scope.supplier.additional_contacts.data.length) {
        return true;
      }

      var temp = _.find($scope.supplier.selectedContactPersons, function (item) {
        var find_item = _.find($scope.additional_contacts.data, function (temp_item) {
          return item._id === temp_item._id;
        });

        if (!!find_item) {
          return false;
        }

        return true;
      });

      if (!!temp) {
        return true;
      }
    }

    return false;
  }

  function checkSupplierChanges() {

    if (checkAddressChanges()) {
      return true;
    }

    if ($scope.updateDescr !== $scope.supplier.descr) {
      return true;
    }

    if ($scope.update_code !== $scope.supplier.mb_supplier_code) {
      return true;
    }

    if ($scope.updateRegNo !== $scope.supplier.reg_no) {
      return true;
    }

    if ($scope.update_gst_code !== $scope.supplier.gst_code) {
      return true;
    }

    if (!!$scope.supplier.selectedTax && !!$scope.supplier.selectedTax._id) {
      if (!!$scope.supplier.tax && !!$scope.supplier.tax._id) {
        if ($scope.supplier.selectedTax._id !== $scope.supplier.tax._id) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (!!$scope.supplier.selectedPaymentTerms && !!$scope.supplier.selectedPaymentTerms.code) {
      if (!!$scope.supplier.pay_terms && !!$scope.supplier.pay_terms.code) {
        if ($scope.supplier.selectedPaymentTerms.code !== $scope.supplier.pay_terms.code) {
          return true;
        }
      } else {
        return true;
      }
    }

    if (!!$scope.supplier.selectedPaymentMethod && !!$scope.supplier.selectedPaymentMethod.code) {
      if (!!$scope.supplier.pay_method && !!$scope.supplier.pay_method.code) {
        if ($scope.supplier.selectedPaymentMethod.code !== $scope.supplier.pay_method.code) {
          return true;
        }
      } else {
        return true;
      }
    }

    if ($scope.update_email !== $scope.supplier.email) {
      return true;
    }

    if ($scope.update_phone !== $scope.supplier.phone) {
      return true;
    }

    if (!!$scope.supplier.selectedCurrency && $scope.supplier.selectedCurrency.code !==
      $scope.supplier.currency_code) {
      return true;
    }

    if ($scope.update_fax !== $scope.supplier.fax) {
      return true;
    }

    if (checkContactPersonChanges()) {
      return true;
    }

    return false;
  }

  function paymentTermValidation() {
    var validPaymentTerm = false;
    if (!!$scope.supplier.selectedPaymentTerms && !!$scope.supplier.selectedPaymentTerms.code) {
      if(globalFunc.findInArray(paymentTerms.data,'code',$scope.supplier.selectedPaymentTerms.code)) {
        validPaymentTerm = true;
      }
    }
    return validPaymentTerm;
  }

  function paymentMethodValidation() {
    var validPaymentMethod = false;
    if (!!$scope.supplier.selectedPaymentMethod && !!$scope.supplier.selectedPaymentMethod.code) {
      if(globalFunc.findInArray(paymentMethods.data,'code',$scope.supplier.selectedPaymentMethod.code)) {
        validPaymentMethod = true;
      }
    }
    return validPaymentMethod;
  }

  function updateChanges(updateAndSubmit) {
    if (!validateOnUpdate()) {
      toastr.error('Please complete all required fields before proceeding');
      return;
    }

    if (!paymentMethodValidation()) {
      toastr.error('Invalid payment method.');
      return;
    }

    if (!paymentTermValidation()) {
      toastr.error('Invalid payment term');
      return;
    }

    if (checkAddressChanges()) {
      $scope.updateAddress(updateAndSubmit);
    } else {
      $scope.updateSupplier(updateAndSubmit);
    }
  }

  //set status for parent
  function setActiveTab() {
    $scope.tabData.active = _.find($scope.tabData, function (tab) {
      if (!!tab.menu && tab.menu.length) {
        return _.find(tab.menu, function (item) {
          return item.params.status == supplier.status.toLowerCase();
        });
      }
      else {
        return tab.params.status == supplier.status.toLowerCase();
      }
    });
    if (!!$scope.tabData.active) {
      $scope.tabData.active.active = true;
    }
  }

  function backToListingSupplier() {
    var supplierStatus = supplierGlobalFunc.getSupplierStatus(
      supplier.status, supplier.approval_status, supplier.draft_status, 'descr'
    );
    var data = $scope.$parent.tabData;

    var tabData = _.find(data[0].menu, function (arr) {
      return (arr.params.status === supplierStatus.toLowerCase());
    });

    $rootScope.$broadcast('loadSpecificTab', {tab: tabData});
  }

  function back() {
    if (!!$scope.supplier && !!$scope.supplier._id) {
      HighlightId.setId($scope.supplier._id);
    }
    window.history.back();
  }

  function editActiveSupplier(currentStatus) {
    var title = 'Editing supplier';
    if (currentStatus === 'inactive' || currentStatus === 'black_listed' || currentStatus === 'rejected')
      title = 'move supplier to draft';

    swal(
      {
        title: 'Confirm ' + title + ' ?',
        text: 'A new draft will be created to be edited and approved',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true
      },
      function () {
        draftSupplier.post(
          {
            parent_id: $scope.supplier._id,
            status: 1,
            // need to add paid up value and bumi status on creating draft from existing supplier
            // because there is a problem with versions and archive id the value is 0
            paid_up_value: $scope.update_paid_up_capital_amount,
            bumi_status: $scope.supplier.bumiputra_status,
            business_entity: $scope.supplier.selectedBusinessEntity.value,
            bu_status: supplierGlobalFunc.prepareSupplierGroup($scope.supplier.bu_status),
            is_global_supplier: $scope.supplier.is_global_supplier.toString()
          },
          function (resource) {
            if (!!resource && !!resource.content && !!resource.content.data) {
              swal.close();
              $location.search({id: resource.content.data._id});
            }
          },
          function (err) {
            draftExistError(err);
          }
        );
      }
    );
  }

  /**
   * Display Error for existing draft
   * @param err
   */
  function draftExistError(err) {
    if (!!err && !!err.data && !!err.data.content && !!err.data.content.error && !!err.data.content.message && !!err.data.content.message.draft_id) {
      swal(
        {
          title: err.data.content.error,
          type: 'warning',
          html: true,
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'View Supplier',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        }, function (confirm) {
          if (!!confirm) {
            $state.go('main.user.suppliers.detail', {id: err.data.content.message.draft_id});
          }
        }
      );
    }
    else
      globalFunc.objectErrorMessage(err);
  }

  /**
   * Update the supplier status for action modal
   *
   * @param {string} status
   */
  function updateSupplierStatus (status) {
    var payload = {
      parent_id: $scope.supplier._id,
      status: status,
      status_reason: $scope.statusUpdatePayload.status_reason,
      paid_up_value: $scope.update_paid_up_capital_amount,
      bumi_status: $scope.supplier.bumiputra_status,
      business_entity: $scope.supplier.selectedBusinessEntity.value,
      bu_status: supplierGlobalFunc.prepareSupplierGroup($scope.supplier.bu_status),
      is_global_supplier: $scope.supplier.is_global_supplier.toString()
    };

    if (!!$scope.statusUpdatePayload.attachment) {
      payload.status_attachment_id = $scope.statusUpdatePayload.attachment._id;
    }

    return draftSupplier.post(payload,
      function (resource) {
        // update status
        draftSupplier.put({
          id: resource.content.data._id,
          action: 'submit'
        }, function () {
          $state.go('main.user.suppliers.manage', {status: 'pending'});
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        })
      },
      function (err) {
        draftExistError(err);
      }
    );
  }

  function onUpdatedContact(contact) {
    var index = _.findIndex($scope.selectedContactPersons, function (item) {
      return item._id == contact._id;
    });

    $scope.selectedContactPersons.splice(index, 1, contact);
  }

  /**
   * Function to revert any changes done to the html form by initializing the form to previous values
   */
  function revertUpdate() {
    initialize();

    if ($scope.editClicked === true) {
      $scope.editClicked = false;
      $scope.submitted = false;
      $scope.validatedBN = false;
      $scope.validatedCommodity = false;
      $scope.descrFirstCharHyphen = false;

      toastr.success('Changes reverted!');
      $state.reload();
    } else {
      $state.go('main.user.suppliers.manage');
    }
  }

  function removeContact(contact) {
    var index = _.findIndex($scope.selectedContactPersons, function (item) {
      return item._id == contact._id;
    });

    $scope.selectedContactPersons.splice(index, 1);
  }

  function onUpdatedCountry(country) {
    $scope.address.state = undefined;
    if (!!country.descr) {
      $scope.address.country = country.descr;
      $scope.selectedMalaysia = (country.descr === 'Malaysia');
    }
  }

  function onUpdatedState(state) {
    if (!!state) {
      $scope.address.state = state.descr;
    }
  }

  function loadApprovalFlow() {

    if (!$scope.supplier || !$scope.supplier.approval_id) {
      $scope.approvals = [];
    }

    Approvals.get(
      {
        id: $scope.supplier.approval_id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          if ($scope.supplier.status.toLowerCase() === 'pending') {
            $scope.approvalFlowData = resource.content.data;
            globalFunc.getWaitingOnUser(resource.content.data, $scope.waiting_on_user);
          }
        } else {
          $scope.approvals = [];
        }
      },
      function () {
        $scope.approvals = [];
      });
  }

  function checkReject() {
    var statusReason = $scope.statusUpdatePayload.status_reason;

    return approvalSupplier.post(
      {
        approval_id: $scope.supplier.approval_id,
        status: 'reject'
      },
      {
        comment: statusReason,
        attachment_id: (!!$scope.statusUpdatePayload.attachment) ? $scope.statusUpdatePayload.attachment._id : null
      },
      function () {
        toastr.success('Supplier rejected.');
        HighlightId.setId($scope.supplier._id);
        $state.go('main.user.suppliers.manage', {status: 'rejected'});
      }, function () {
        toastr.error('Supplier cannot be rejected.');
      }
    );
  }

  /**
   * Function to approve supplier to move to next status
   */
  function checkApprove() {
    var statusReason = $scope.statusUpdatePayload.status_reason;

    return approvalSupplier.post(
      {
        approval_id: $scope.supplier.approval_id,
        status: 'approve'
      },
      {
        comment: statusReason,
        attachment_id: (!!$scope.statusUpdatePayload.attachment) ? $scope.statusUpdatePayload.attachment._id : null
      },
      function () {
        toastr.success('Supplier approved.');
        HighlightId.setId($scope.supplier._id);
        $state.go('main.user.suppliers.manage', {status: 'pending'});
      }, function () {
        toastr.error('Supplier cannot be approved.');
      }
    );
  }

  function getPaidUpCategory() {
    $scope.paidUpCategoryDescr = supplierGlobalFunc.getPaidUpCategory($scope.update_paid_up_capital_amount);
  }

  function updateStatus(action, updateAndSubmit) {
    var supplierFormValidation = validate();
    $scope.submitted = true;

    if (supplierFormValidation === false) {
      toastr.error('Please fill the required fields before proceeding');
      return;
    }

    if (!!updateAndSubmit) {
      draftSupplier.put(
        {
          id: $scope.supplier._id,
          action: action
        },
        {
          status_reason: ''
        },
        function () {
          var actionMessage = 'submitted';
          var redirection = 'pending';

          toastr.success('The supplier was ' + actionMessage + '.');
          HighlightId.setId($scope.supplier._id);
          $state.go('main.user.suppliers.manage', {status: redirection});
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      swal(
        {
          title: 'Confirm submit supplier?',
          text: 'This supplier will be submitted by you. Please state a reason.',
          showCancelButton: true,
          type: 'input',
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function (input) {
          if (input !== false) {
            draftSupplier.put(
              {
                id: $scope.supplier._id,
                action: action
              },
              {
                status_reason: input || ''
              },
              function () {
                var actionMessage = 'submitted';
                var redirection = 'pending';

                toastr.success('The supplier was ' + actionMessage + '.');
                HighlightId.setId($scope.supplier._id);
                $state.go('main.user.suppliers.manage', {status: redirection});
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
        }
      );
    }
  }

  function withdrawSupplier() {
    approvalSupplier.post(
      {
        approval_id: $scope.supplier.approval_id,
        status: 'withdraw'
      },
      {},
      function () {
        toastr.success('The supplier was withdrawn');
        HighlightId.setId($scope.supplier._id);
        $state.go('main.user.suppliers.manage', {status: 'withdrawn'});
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * Get all archived data of the active supplier
   */
  function getArchivesData() {
    // if status pending and it has parent_id, get the parent id
    if ($scope.supplier.status.toLowerCase() === 'pending' && !!$scope.supplier.parent_id) {
      singleSupplier.get({
        id: $scope.supplier.parent_id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $scope.supplierVersions.push(resource.content.data);

        $scope.supplierVersions.unshift($scope.supplier);
      });
    } else if ($scope.supplier.status.toLowerCase() === 'active') {
      supplierArchives.get({
        id: $scope.supplier._id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.supplierVersions = resource.content.data;
          $scope.supplierVersions = reSortingArray($scope.supplierVersions, 'object_version');
          // add the current version to the supplier versions list
          $scope.supplierVersions.unshift($scope.supplier);
        }
      }, function () {
        $scope.supplierVersions = [];
      });
    }
  }

  /**
   * Get all archived draft version of the pending supplier
   */
  function getDraftArchivesData() {
    supplierArchives.get({
      id: $scope.supplier._id,
      type: 'draft'
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        $scope.supplierDraftVersions = resource.content.data;
        // add the current version to the supplier versions list
        $scope.supplierDraftVersions.unshift($scope.supplier);
      }
    }, function () {
      $scope.supplierDraftVersions = [];
    });
  }

  /**
   * Sorting array based on the object key
   * @param array
   * @param key
   * @returns {*}
   */
  function reSortingArray(array, key) {
    return array.sort(function (a, b) {
      return a[key] - b[key];
    });
  }

  /**
   * Display supplier versions list in modal
   */
  function showVersionList() {
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/supplier-versions.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        supplierVersions: function () {
          return $scope.supplierVersions;
        },
        supplierStatus: function () {
          return $scope.supplier.status;
        },
        versionType: function () {
          return '';
        }
      },
      controller: 'supplierVersionsController'
    });
  }

  /**
   * Display supplier draft versions list in modal
   */
  function showDraftVersionList() {
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/supplier-versions.html',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        supplierVersions: function () {
          return $scope.supplierDraftVersions;
        },
        supplierStatus: function () {
          return $scope.supplier.status;
        },
        versionType: function () {
          return 'draft';
        }
      },
      controller: 'supplierVersionsController'
    });
  }

  /**
   * Move to draft to be able re edit
   * rejected or withdrawn supplier
   * @param currentStatus
   */
  function reopenDraftSupplier(currentStatus) {
    currentStatus = currentStatus.toLowerCase();
    swal({
        title: 'Confirm move ' + currentStatus + ' supplier to draft?',
        text: 'This supplier will be moved from ' + currentStatus + ' status to draft',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (input) {
        if (!!input) {
          draftSupplier.put(
            {
              id: $scope.supplier._id,
              action: 'reopen'
            },
            function () {
              toastr.success('The supplier was moved to draft successfully');
              HighlightId.setId($scope.supplier._id);
              $state.go('main.user.suppliers.manage', {status: 'draft'});
            },
            function (err) {
              draftExistError(err);
            }
          );
        }
      });

  }

  function initialize() {
    $scope.paymentMethods = supplierGlobalFunc.filterInactiveCode(paymentMethods.data);
    $scope.paymentTerms = supplierGlobalFunc.filterInactiveCode(paymentTerms.data);
    $state.params.status = !!$scope.supplier.status ? $scope.supplier.status.toLowerCase() : 'all';

    var statusNeedsRevision = ['active', 'pending'];
    if (statusNeedsRevision.indexOf($scope.supplier.status.toLowerCase()) > -1)
      getArchivesData();

    if ($scope.supplier.status.toLowerCase() === 'pending') {
      getDraftArchivesData();
    }

    //active tab
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();

    // loadAttachments();

    /**
     * To initialize primary business nature by looping and comparing short descr with returned data
     */
    if (angular.isDefined($scope.supplier.primary_business_nature)) {
      for (var i = 0; i < $scope.businessNatures.length; i++) {
        if ($scope.businessNatures[i].shortDescr === $scope.supplier.primary_business_nature) {
          $scope.supplier.selectedPrimaryBusinessNature = $scope.businessNatures[i];
        }
      }
    }
    else {
      $scope.supplier.selectedPrimaryBusinessNature = $scope.businessNatures[0];
    }

    /**
     * To initialize secondary business nature by looping and comparing short descr with returned data
     */
    if (angular.isDefined($scope.supplier.secondary_business_nature)) {
      for (var j = 0; j < $scope.businessNatures.length; j++) {
        if ($scope.businessNatures[j].shortDescr === $scope.supplier.secondary_business_nature) {
          $scope.supplier.selectedSecondaryBusinessNature = $scope.businessNatures[j];
        }
      }
    }
    else {
      $scope.supplier.selectedSecondaryBusinessNature = $scope.businessNatures[0];
    }

    /**
     * To initialize business entities by looping and comparing short descr with returned data
     */
    if (angular.isDefined($scope.supplier.business_entity)) {
      for (var k = 0; k < $scope.businessEntities.length; k++) {
        if ($scope.businessEntities[k].value === $scope.supplier.business_entity) {
          $scope.supplier.selectedBusinessEntity = $scope.businessEntities[k];
        }
      }
    } else {
      $scope.supplier.selectedBusinessEntity = $scope.businessEntities[0];
    }

    /**
     * To initialize primary commodity code and populate drop downs accordingly
     */
    if (!!$scope.supplier.primary_commodity && !!$scope.supplier.primary_commodity.code) {
      $scope.primaryCommodityKeyword = $scope.supplier.primary_commodity.code + ' ' + $scope.supplier.primary_commodity.descr;
      $scope.supplier.primaryCommodity = $scope.supplier.primary_commodity;
    }

    /**
     * To initialize secondary commodity code and populate drop downs accordingly
     */
    if (!!$scope.supplier.secondary_commodity && $scope.supplier.secondary_commodity.code) {
      $scope.secondaryCommodityKeyword = $scope.supplier.secondary_commodity.code + ' ' + $scope.supplier.secondary_commodity.descr;
      $scope.supplier.secondaryCommodity = $scope.supplier.secondary_commodity;
    }

    $scope.update_email = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.update_email = $scope.supplier.email;
    }

    $scope.update_phone = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.update_phone = $scope.supplier.phone;
    }

    $scope.update_fax = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.update_fax = $scope.supplier.fax;
    }
    $scope.descrTitle = null;
    $scope.updateDescr = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.updateDescr = $scope.supplier.descr;
      $scope.descrTitle = $scope.updateDescr;
    }

    $scope.updateBranch = [];
    if (angular.isDefined($scope.supplier)) {
      $scope.updateBranch = $scope.supplier.branches;
    }

    $scope.update_code = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.update_code = $scope.supplier.mb_supplier_code;
    }

    $scope.updateRegNo = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.updateRegNo = $scope.supplier.reg_no;
    }

    $scope.update_gst_code = null;
    if (angular.isDefined($scope.supplier)) {
      $scope.update_gst_code = $scope.supplier.gst_code;
    }

    $scope.supplier.selectedPaymentTerms = null;
    if (!!$scope.supplier && !!$scope.supplier.pay_terms && !!$scope.supplier.pay_terms._id) {
      $scope.supplier.selectedPaymentTerms = $scope.supplier.pay_terms;
    }

    $scope.supplier.selectedPaymentMethod = null;
    if (!!$scope.supplier && !!$scope.supplier.pay_method && !!$scope.supplier.pay_method._id) {
      $scope.supplier.selectedPaymentMethod = $scope.supplier.pay_method;
    }

    $scope.supplier.selectedTax = null;
    if (!!$scope.supplier && !!$scope.supplier.tax) {
      $scope.supplier.selectedTax = $scope.supplier.tax;
    }

    $scope.selectedContactPersons = null;
    if (!!$scope.supplier && !!$scope.supplier.additional_contacts && !!$scope.supplier.additional_contacts.data) {
      $scope.selectedContactPersons = _.cloneDeep($scope.supplier.additional_contacts.data);
      $scope.selectedContactPersons.unshift($scope.supplier.contact_person);
    }

    $scope.supplier.selectedCurrency = null;
    if (!!$scope.supplier && !!$scope.supplier.currency_code) {
      var temp = _.find($scope.currencies, function (item) {
        return $scope.supplier.currency_code == item.code;
      });

      if (!!temp) {
        $scope.supplier.selectedCurrency = temp;
      }
    }

    $scope.address = {};
    if (!!$scope.supplier && !!$scope.supplier.corresponding_address && !_.isArray($scope.supplier.corresponding_address)) {
      $scope.address = _.cloneDeep($scope.supplier.corresponding_address);
    }

    /**
     * To initialize country by looping and comparing descr with returned data
     */
    if (angular.isDefined($scope.address.country)) {
      if (!!globalFunc.findInArray($scope.countries, 'descr', $scope.address.country)
        && globalFunc.findInArray($scope.countries, 'descr', $scope.address.country).descr ===
        $scope.address.country) {
        $scope.selectedMalaysia = ($scope.address.country === 'Malaysia');
      }
    }

    /* //backup supplier
     $scope.backup_supplier = _.cloneDeep($scope.supplier);*/

    if ($scope.supplier.status.toLowerCase() === 'pending') {
      $scope.loadApprovalFlow();
    }

    if (angular.isDefined($scope.supplier) && angular.isDefined($scope.supplier.paid_up_value)) {
      $scope.update_paid_up_capital_amount = !!$scope.supplier.paid_up_value ?
        Number($scope.supplier.paid_up_value) : null;
      getPaidUpCategory();
    }

    if (angular.isDefined($scope.supplier) && angular.isDefined($scope.supplier.bumi_status)) {
      $scope.supplier.bumiputra_status = $scope.supplier.bumi_status;
    }
    if (angular.isDefined($scope.supplier) && angular.isDefined($scope.supplier.receiving_mode)) {
      switch ($scope.supplier.receiving_mode) {
        case 'SYSTEM':
          $scope.selection.poMethod = 0;
          break;
        case 'EMAIL':
          $scope.selection.poMethod = 1;
          break;
        case 'FAX':
          $scope.selection.poMethod = 2;
          break;
        case 'POST':
          $scope.selection.poMethod = 3;
          break;
      }
    }

    $scope.supplier.comments = globalFunc.translateSupplierHistory($scope.supplier.comments);
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
  }

  initialize();

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model, field, value, checker) {

    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'rocCheckTrue':
          if ($scope.updateRegNo !== $scope.originalROC) {
            $scope.rocCheck = true;
          }
          else {
            $scope.rocCheck = false;
          }
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'gstCheckTrue':
          if ($scope.update_gst_code !== $scope.originalGSTCode) {
            $scope.gstCheck = true;
          }
          else {
            $scope.gstCheck = false;
          }
          break;
        case 'gstCheckFalse':
          $scope.gstCheck = false;
          break;
        case 'supplierDescrCheckTrue':
          if ($scope.updateDescr !== $scope.originalDescr) {
            $scope.descrCheck = true;
          }
          else {
            $scope.descrCheck = false;
          }
          break;
        case 'supplierDescrCheckFalse':
          $scope.descrCheck = false;
          break;
      }
    });
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  function removeSecondaryCommodity() {
    if (!!$scope.supplier) {
      $scope.supplier.secondaryCommodity = undefined;
      $scope.secondaryCommodityKeyword = null;
    }
  }

  function editSupplier() {
    $scope.editClicked = true;
  }

  /**
   * Download attachment
   *
   * @param {object} attachment
   */
  function downloadAttachment(attachment) {
    if (!!attachment) {
      attachmentDetail.get({
        id: attachment._id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          getAttachmentFile(resource.content.data.temporary_url);
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Get the attachment file
   *
   * @param {string} url
   */
  function getAttachmentFile(url) {
    var newUrl = $filter('format')(pathConstants.apiUrls.attachments.download, {
      hash: url
    });
    $http({
      url: newUrl
    }).then(function () {
      window.open(newUrl, '_self');
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }
}

supplierDetailCtrl.$inject = [
  '$scope', '$q', 'toastr', 'supplier', 'taxes', 'singleSupplier', 'paymentMethods', 'paymentTerms', '$state',
  '$location', 'currencies', 'HighlightId', 'addressList', 'singleAddress', 'Approvals', 'profile', 'globalFunc',
  'approvalSupplier', 'searchAllCommodity', 'listChild', 'supplierGlobalFunc', 'draftSupplier', 'supplierArchives',
  '$uibModal', 'pathConstants', '$http', '$rootScope', 'attachmentDetail', '$filter'
];

angular.module('metabuyer')
  .controller('supplierDetailCtrl', supplierDetailCtrl);
