/**
 * @name commentsPanel
 *
 * @requires $rootScope
 * @requires toastr
 *
 * @description Directive for handling comments and history
 * This directive is for displaying the html as well as handling logic for comments and history
 *
 * Logic involved:
 * Display comments and history with pagination
 * Add comments
 * Tag users via @ mentio
 * Creator of comments can delete comments
 *
 * @isolatedScope commentsObject
 * Isolated scope for parent scope's comments object, e.g. pr or po object
 * @bindingType Two way binding
 *
 * @isolatedScope commentsBackupObject
 * Isolated scope for parent scope's backup object, e.g. backup_pr
 * @bindingType Two way binding
 *
 * @isolatedScope commentsModule
 * Isolated scope to determine which module is the directive being called
 * @bindingType Attribute binding
 *
 * @isolatedScope commentsService
 * Isolated scope for parent scope's comment service to be used for the methods
 * @bindingType Two way binding
 *
 * @isolatedScope deleteService
 * Isolated scope parent scope's delete service to be used for delete method
 * @bindingType Two way binding
 *
 * @isolatedScope contactOptions
 * Isolated scope for parent scope's options.contact_persons for mentioning
 * @bindingType Two way binding
 *
 * @isolatedScope disableComment
 * Isolated scope for disable comment flag
 * @bindingType Two way binding
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('commentsPanel', function () {
    return {
      scope: {
        commentsObject: '=?',
        commentsBackupObject: '=?',
        commentsModule: '@?',
        commentsService: '=?',
        deleteService: '=',
        contactOptions: '=?',
        disableComment: '=?'
      },
      template: '<div ng-include="template"></div>',
      restrict: 'E',
      controller: function ($scope, $rootScope, toastr, globalFunc) {

        if ($scope.commentsModule === 'PR') {
          $scope.pr = $scope.commentsObject;
        }
        if ($scope.commentsModule === 'PO') {
          $scope.po = $scope.commentsObject;
        }
        $scope.usersList = [];
        $scope.tag_contact_person = [];
        $scope.tag_collaborator = [];
        $scope.commentsAndHistory = [];
        $scope.userBasePath = $rootScope.userImageBasePath;
        $scope.user = $rootScope.currentUser;
        $scope.commentSize = 10;
        $scope.commentPageCount = 0;
        $scope.meta = {};
        $scope.mentions = [];

        $scope.selectTagName = selectTagName;
        $scope.postComment = postComment;
        $scope.readMoreComment = readMoreComment;
        $scope.deleteComments = deleteComments;
        $scope.getGRNNumber = getGRNNumber;
        $scope.getCommentAction = getCommentAction;
        $scope.getClosedItemIndex = getClosedItemIndex;
        $scope.disabled = $scope.disableComment;
        $scope.comment = {
          text: ''
        };

        getHeaderTitle();

        /**
         *  Receive broadcast for updated embedded pagination data to update on controller side
         */
        $scope.$on('updateEmbeddedPagination', function (event, content) {
          if(!!content && !!content.data)
          $scope.commentsAndHistory = content.data;
        });

        $scope.template = $rootScope.isMobileMode ? 'components/comments/comments-panel-mobile.html' :
          'components/comments/comments-panel.html';

        $scope.$on('refreshComments',function(){
          refreshHistory();
        });

        $scope.$on('collaboratorAdjusted', function (event, args) {
          refreshHistory();
        });

        function refreshHistory() {
          $scope.meta = {};
          $scope.commentsAndHistory.splice(0, $scope.commentsAndHistory.length);
          $scope.readMoreComment();
        }

        if (!$scope.isFirstCreate) {
          $scope.meta = {};
          $scope.commentsAndHistory.splice(0, $scope.commentsAndHistory.length);
          $scope.readMoreComment();
        }

        //tag on contact_person
        $scope.$watch('contactOptions', function (newValue) {
          $scope.tag_contact_person.splice(0, $scope.tag_contact_person.length);
          _.forEach(_.forEach(newValue, function (person) {
            person.label = person.display_name
          }), function (item) {
            $scope.tag_contact_person.push(item);

            $scope.usersList = $scope.tag_contact_person;
            $scope.usersList = _.uniq($scope.usersList, function (item) {
              return item._id;
            });
            $scope.usersList.unshift(
              {
                _id: '_all_',
                label: 'all'
              }
            );

          });
        }, true);

        function deleteComments(activity) {
          swal({
            title: 'Confirm delete comment?',
            showCancelButton: true,
            confirmButtonColor: '#1ab394',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            closeOnConfirm: true,
            closeOnCancel: true
          }, function (isConfirm) {
            if (isConfirm) {
              var deleteCommentService = $scope.deleteService;
              var params = {
                commentId: activity._id
              };

              deleteCommentService.delete(
                params ,
                function () {
                  refreshHistory();
                },
                function(error) {
                  globalFunc.objectErrorMessage(error);
                  refreshHistory();
                }
              )
            }
          });
        }

        function getGRNNumber(value) {
          if (!!value) {
            var index = value.indexOf('(');

            if (index !== -1) {
              return value.substr(index, value.length);
            }
          }

          return '';
        }

        function getCommentAction(activity) {
          if (!activity) {
            return '';
          }

          var moduleName;
          switch($scope.commentsModule) {
            case 'PR':
              moduleName = 'purchase requisition';
              break;
            case 'PO':
              moduleName = 'purchase order';
              break;
            case 'Budget':
              moduleName = 'budget';
              break;
            case 'SupplierTag':
              moduleName = 'supplier tag';
              break;
          }

          // TODO: Update the switch case after CommentHistory refactor for PR/PO(Justin 7/9/17)
          switch(activity.action) {
            case 'AWAITING_DELIVERY':
              return ' set the PO to awaiting delivery';
              break;
            case 'DELIVERY_DATE_UNCONFIRMED':
              return ' set the delivery date to unconfirmed';
              break;
            case 'CANCELLED':
              return ' cancelled PO';
              break;
            case 'RESENT':
              return activity.comment;
              break;
            case 'Received goods':
              return ' commented on received goods';
              break;
            case 'Reversed goods':
              if(!!activity.comment) {
                return ' reversed goods. Set PO to awaiting delivery';
              }
              else {
                return ' commented on reverse goods';
              }
              break;
            case 'LINE ITEM CLOSED':
              return ' closed line item (' + ($scope.getClosedItemIndex(activity._id) + 1) + ')';
              break;
            case 'ATTACHED':
              return ' attached ' + activity.comment + ' to the PO';
              break;
            case 'ATTACHMENT UPDATED':
              return ' updated attachment';
              break;
            case 'EMAIL_SEND_FAILED':
              return ' ' + activity.comment;
              break;
            case 'REMOVE_ATTACHMENT':
              return ' removed ' + activity.comment + ' from the PO';
              break;
            case 'SUBMITTED':
              return activity.comment;
              break;
            case 'created':
              return ' created this ' + moduleName;
              break;
            case 'created_comment':
              return ' commented';
              break;
            case 'fully_approved':
              return 'fully approved this ' + moduleName;
              break;
            case 'added_collaborators':
              return 'added a collaborator: ' + activity.collaborator.display_name;
              break;
            case 'removed_collaborators':
              return 'removed a collaborator: ' + activity.collaborator.display_name;
              break;
            case 'escalated':
              return ' has been escalated in this ' + moduleName;
              break;
            case 'delegated':
              return ' has been delegated in this ' + moduleName;
              break;
            case 'expired':
              return moduleName + ' has expired';
              break;
            case 'expired_delegated':
              return ' is no longer being delegated for this ' + moduleName;
              break;
            case 'rollback':
              return ' has rolled back this ' + moduleName;
              break;
            case 'resubmit':
              return ' has edited and resubmitted this ' + moduleName;
              break;
            case 'on_hold':
              return ' placed this ' + moduleName + ' on hold';
              break;
              //TODO: get the action of releasing hold on a module's approval (Justin 12/9/17)
            case 'release':
              return ' released the hold on this ' + moduleName;
              break;
            case 'added_attachments':
              return activity.comment;
              break;
            case 'removed_attachments':
              return activity.comment;
              break;
            case 'tagged_supplier':
              return ' tagged supplier: ' + activity.supplier_descr;
              break;
            case 'untagged_supplier':
              return ' untagged supplier: ' + activity.supplier_descr;
              break;
            case 'tagged_supplier_via_po':
              return ' Supplier: ' + activity.supplier_descr + ' was tagged via PO: ' + activity.reference;
              break;
            case 'added_supplier_note':
              return ' added a note to supplier';
              break;
            case 'updated_supplier_note':
              return ' updated the note to supplier';
              break;
            case 'deleted_supplier_note':
              return ' deleted the note to supplier';
              break;
            case 'deactivated_for_revision':
              return ' created a revision draft for this ' + moduleName + ' and deactivated the parent ' + moduleName;
              break;
            case 'created_revision':
              return ' fully approved a revision for this ' + moduleName;
              break;
            case 'deleted_revision':
              return ' deleted a revision draft for this ' + moduleName + ' and reactivated the parent ' + moduleName;
              break;
            case undefined:
              return ' commented';
              break;
            case 'added_adhoc_approver':
            case 'removed_adhoc_approver':
              return activity.comment;
              break;
            default:
              if (!!activity.action) {
                if ($scope.commentsModule === 'PO') {
                  return ' set the PO to ' + activity.action.toLowerCase();
                }
                else {
                  return activity.action + ' this ' + moduleName;
                }
              }
              break;
          }
        }

        function getClosedItemIndex(comment_id){
          if($scope.commentsModule === 'PO'){
            return _.findIndex($scope.commentsObject.items, function(item){
              return item.closing_comment_id == comment_id;
            });
          }
        }

        function readMoreComment(doNotRefreshData) {

          $scope.embeddedParams = {
            offset: $scope.commentSize
          };
          //TODO: remove this from here, its a generic directive (Ahmed Saleh, 2017/8/9)
          if($scope.commentsModule === 'SupplierTag') {
            $scope.embeddedParams['companyCode'] = $scope.commentsObject;
          }
          else {
            $scope.embeddedParams['referenceId'] =  $scope.commentsObject._id;
          }

          $scope.is_loading_comment = true;
          $scope.commentsService.get(
            $scope.embeddedParams,
            function (resource) {
              $scope.is_loading_comment = false;
              /**
               * using the doNotRefreshData variable, it is determined which page is the comments are on:
               * in case the comment was in the first page, no need to reload.
               * Just updating the meta by broadcasting to the embedded pagination directive
               * in case its not on the first page, will pass the whole data to the embedded pagination directive
               */
              if (!!resource && !!resource.content && !!resource.content.data) {
                if (doNotRefreshData) {
                  $scope.meta = resource.content.meta;
                  $rootScope.$broadcast('embeddedPaginationUpdate', {meta: $scope.meta, offset: $scope.commentSize});
                } else {
                  $scope.commentsAndHistory = resource.content.data;
                  $scope.meta = resource.content.meta;
                  $rootScope.$broadcast('embeddedPaginationUpdate', {data: $scope.commentsAndHistory,
                    meta: $scope.meta, offset: $scope.commentSize});
                }

                // If updated_at is less than 1 month ago from current time: show [time] ago,
                // else show date
                // Adding change the image user to call the user image endpoint
                _.forEach($scope.commentsAndHistory, function (comment) {
                  var tempDate = new Date(comment.updated_at);
                  tempDate.setMonth(tempDate.getMonth() + 1);
                })
              }
            }, function (error) {
              $scope.is_loading_comment = false;
            }
          );
        }

        function postComment(text) {
          if (!!text && text !== '') {
            $scope.disabled = true;
            // handle text before submit

            verifyMentions(text);
            var content = replaceContent(text);
            var mentionedUsersArray = [];

            $scope.commentsService.post(
              {
                referenceId: $scope.commentsObject._id
              },
              {
                comment: content,
                mentionedUserIds: $scope.mentionedIdArray
              }
            ).
              $promise.then(function (resource) {
                $scope.mentions = [];
                $scope.mentionedIdArray = [];
                mentionedUsersArray = [];
                //successful
                $scope.disabled = false;
                $scope.comment = {
                  text: ''
                };
                /**
                 * in case the user is not in page 1, reset it
                 * fix the total count for the embedded pagination
                 */
                if (!!$scope.meta && !!$scope.meta.cursor && !$scope.meta.cursor.prev) {
                  /**
                   * pushing the message in frontend only, in case of the first page,
                   * else reloading data
                   */
                  if (!!resource && !!resource.content && !!resource.content.data) {
                    $scope.commentsAndHistory.splice(0, 0, resource.content.data);
                  }
                  $rootScope.$broadcast('addToCountEnd');
                }
                refreshHistory();
              },
              function () {
                toastr.error('Failed to submit comment');
                $scope.disabled = false;
              }
            );
          }
        }

        function replaceContent(text) {
          var content = angular.copy(text);
          for (var index in $scope.mentions) {
            var item = $scope.mentions[index];
            content = content.replace(
              new RegExp('@' + item.label, 'g'),
                '@' + item.label
            );
          }
          return content;
        }

        function verifyMentions(text) {
          if (!$scope.mentionedIdArray)
            return;

          //verifying the specific mentions
          for (var i = 0;i < $scope.usersList.length; i++) {
            var label = '@' + $scope.usersList[i].label;
            if (text.indexOf(label) === -1 && label !== '@all') {
              var index = $scope.mentionedIdArray.indexOf($scope.usersList[i]._id);
              if (index > -1) {
                $scope.mentionedIdArray.splice(index, 1);
              }
            }
          }

          //verifying the all mention, pushing all the users back for true case
          label = '@all';
          if(text.indexOf(label) > -1){
            for (i = 0; i < $scope.usersList.length; i++) {
              //push all users Ids except current and _all_
              if ($scope.usersList[i]._id !== $rootScope.currentUser._id
                && $scope.usersList[i]._id !== '_all_') {
                //pushing without duplicates
                index = $scope.mentionedIdArray.indexOf($scope.usersList[i]._id);
                if (index === -1) {
                  $scope.mentionedIdArray.push($scope.usersList[i]._id);
                }
              }
            }
          }
        }

        function selectTagName(item) {
          $scope.selectedTag = item;

          var isExisting = false;
          for (var index in $scope.mentions) {
            if ($scope.mentions[index]._id === item._id) {
              isExisting = true;
            }
          }
          if (!isExisting) {
            $scope.mentions.push(item);
          }

          if(!!$scope.mentions && !!$scope.mentions.length){
            $scope.mentionedIdArray = [];

            if(globalFunc.findInArray($scope.mentions, '_id', '_all_')){
              for (var i = 0; i < $scope.usersList.length; i++) {
                //push all users Ids except current and _all_
                if ($scope.usersList[i]._id !== $rootScope.currentUser._id
                  && $scope.usersList[i]._id !== '_all_') {
                  $scope.mentionedIdArray.push($scope.usersList[i]._id);
                }
              }
            }else {
              for (i = 0; i < $scope.mentions.length; i++) {
                $scope.mentionedIdArray.push($scope.mentions[i]._id);
              }
            }
          }
          else{
            $scope.mentionedIdArray = [];
          }

          return '@' + item.label;
        }

        /**
         * Function to get the header title for the directive
         */
        function getHeaderTitle() {
          switch($scope.commentsModule) {
            case 'SupplierTag':
              $scope.headerTitle = 'History';
              break;
            default:
              $scope.headerTitle = 'Comments & History';
              break;
          }
        }
      }
    }
  });
