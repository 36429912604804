'use strict';
angular
    .module('metabuyer')
    .directive('positionsSelectionPanel',function($uibModal) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs) {
        var panelPosition = function (selectedCompany) {
          $uibModal.open({
            templateUrl: 'components/positionsSelectionPanel/positionsSelectionTemplate.html',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              user: function(){
                return scope.user
              },
              selected_company: function() {
                return scope.selected_company
              },
              companyRoles: function ($q, rolesList) {
                var deferred = $q.defer();
                rolesList.get(
                  {
                    company_code: selectedCompany.code,
                    //TODO: this offset is temporary to make sure we get the ADMIN role, remove later (Ahmed Saleh)
                    offset: 0
                  },
                  function (resource) {
                    if (!!resource.content) {
                      deferred.resolve(resource.content.data);
                    }
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              },
              semanticRoles: function ($q, rolesList) {
                var deferred = $q.defer();
                rolesList.get(
                  {
                    company_code: 'system',
                    offset: 0
                  },
                  function (resource) {
                    if (!!resource.content) {
                      deferred.resolve(resource.content.data);
                    }
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              }
            },
            controller: 'positionSelectionCtrl'
          });
        };
        scope.openPositionModal = function (selectedCompany) {
          panelPosition(selectedCompany);
        }
      }
    };
  })
  .controller('positionSelectionCtrl',
    function ($scope, user, selected_company, rolesList, singleUser, semanticRoles, companyRoles, $state, userPosition,
              toastr, $uibModalInstance) {
      // todo: use role selectin panel instead of create new one
      $scope.rolesList = semanticRoles.concat(companyRoles);
      $scope.user = user;
      $scope.selected_company = selected_company;
      $scope.addNewPosition = addNewPosition;
      $scope.removePosition = removePosition;
      $scope.closeModal = closeModal;
      $scope.$state = $state.current.name;
      function closeModal() {
        $uibModalInstance.close();
      }

      function addNewPosition(newRole) {
        swal({
            title: "Confirmation",
            text: "Confirm add new position?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function(isConfirm) {
            if (isConfirm) {
              var tempRolesIds = [];
              //move the ids of the Roles in  a new array to push to the backend right now the User list returned from
              // the backend is just text and wil be updated to an object of id
              for(var i in $scope.user.positions){
                if( newRole._id === $scope.user.positions[i]._id){
                  toastr.error('Cannot add same position again');
                  return false;
                }
                else {
                  tempRolesIds.push($scope.user.positions[i]._id);
                }
              }
              tempRolesIds.push(newRole._id);
              if($scope.$state === 'main.user.users.add'){
                toastr.success('Position added');
              } else {
                userPosition.put({
                  id: user._id,
                  positionId: newRole._id
                }, function(){
                  removePositionOnLocalStorage(newRole._id);
                  toastr.success('Position added');
                  $scope.user.positions.push(newRole);
                }, function(error){
                  var warningMessage = "";
                  if(!!error && !!error.data.content)
                    warningMessage = error.data.content.error;
                  toastr.error(warningMessage);
                  return false;
                });
              }
            }
          }
        );
      }

      /**
       * Filter the roles returned by the type === 'ORG_ROLE'
       * ORG_ROLE is for position and used in approval flow
       */
      function filterRoles(){
        var tempRoles = [];
        _.forEach($scope.rolesList, function(role){
          if(role.type === 'ORG_ROLE')
            tempRoles.push(role);
        });
        $scope.rolesList = tempRoles;
      }

      function removePosition(role, index) {

        swal({
            title: "Confirmation",
            text: "Confirm remove position?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function(isConfirm) {
            var newRole = {};
            if(!!isConfirm){

              if($scope.$state === 'main.user.users.add'){
                toastr.success('Position removed');
                user.positions.splice(index, 1);
                $scope.user.roles_id.splice(index, 1);
              } else {
                userPosition.delete({
                  id: user._id,
                  positionId: role._id
                }, function(){
                  removePositionOnLocalStorage(role._id);
                  toastr.success('Position removed');
                  user.positions.splice(index, 1);
                }, function(){
                  toastr.error("Failed to delete position");
                });
              }
            }
          }
        );
      }

      /**
       * to remove a position based position id in the localstorage
       * @param id
       */
      function removePositionOnLocalStorage(id) {
        var usersPosition = JSON.parse(window.localStorage.getItem('roleUsers'));
        if(!!usersPosition && !!usersPosition[id]){
          delete usersPosition[id];
          window.localStorage.setItem('roleUsers', JSON.stringify(usersPosition));
        }
      }

      function initialize(){
        filterRoles();
      }

      initialize();
    }
  );
