'use strict';

/**
 * @name reportsGenerationCtrl
 * @desc Controller for Reports generation
 */

function reportsGenerationCtrl ($scope, reports, toastr, businessList, singleBU, $timeout, $filter, profile, globalFunc,
  checkReportStatus, $interval, pathConstants, reportTypes, $state, searchModule, suppliersActiveList, UserPermissions) {

  //region Variable and scope declaration
  var usersCompaniesCode = [],
      usersCompanies = profile.companies,
      usersPerms = profile.view_perms,
      usersBUCode = [];
  var allBUs = {
      descr: 'All Available Business Units',
      _id: 'all'
  };
  $scope.reports = {};
  $scope.business = businessList;
  // Used for sorting business units when displaying them in a list
  $scope.businessId = {};
  $scope.selectedCompanies = [];
  $scope.selectedCostCenters = [];
  $scope.selectedSuppliers = [];
  $scope.selectedBudgetType = {};
  $scope.selectedBudgetType.list = null;
  $scope.costCenterList = [];
  $scope.companyList = [];
  $scope.company_ids = [];
  $scope.companyCodes = [];
  $scope.supplierCodes = [];
  $scope.date_type = [
    {id: 'receivedDate', name: 'Received'},
    {id: 'GRNDate', name: 'GRN'}
  ];
  $scope.spend_date_type = [
    {id: 'PODate', name: 'PO Date'},
    {id: 'PRDate', name: 'PR Date'}
  ];
  $scope.budgetType = [
    {id: 'master', descr: 'Master'},
    {id: 'assignment', descr: 'Assignment'},
    {id: 'itemBreakdown', descr: 'Item Breakdown'}
  ];

  $scope.getCompanies = getCompanies;
  $scope.generateReports = generateReports;
  $scope.hasSupplierTagPermission = hasSupplierTagPermission;
  $scope.generateOptionsBasedOnReportType = generateOptionsBasedOnReportType;
  $scope.onSelectBusinessUnit = onSelectBusinessUnit;
  $scope.openDatepicker = openDatepicker;
  $scope.onSelectDateType = onSelectDateType;
  $scope.sortedBusiness = sortedBusiness;
  $scope.getSelectedCompanies = getSelectedCompanies;
  $scope.onSelectedCompanyChanges = onSelectedCompanyChanges;
  $scope.onSelectSingle = onSelectSingle;
  $scope.onSelectAll = onSelectAll;
  $scope.onSelectNone = onSelectNone;

  $scope.reportTypes = reportTypes.getReportTypes();

  $scope.datepickerOpened = {
    startDate: false,
    endDate: false
  };

  //endregion

  // Return string of selected company
  function getSelectedCompanies(array, key) {
    return globalFunc.createStringBasedOnObjectKeyInArray(array, key);
  }

  // Function for sorting BU on list
  function sortedBusiness (business) {
    if (business.descr === 'All Available Business Units') {
      return -1;
    }
    return business.descr;
  }

  //region On select functions

  // Set selection based on field type
  function onSelectSingle(fieldType, selection) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selection;
        break;
      case 'costCenter':
        $scope.selectedCostCenters = selection;
        break;
      case 'supplier':
        $scope.selectedSuppliers = selection;
        break;
    }
  }

  // Set selection to all based on field type
  function onSelectAll(fieldType, selectionList) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selectionList;
        break;
      case 'costCenter':
        $scope.selectedCostCenters = selectionList;
        break;
      case 'supplier':
        $scope.selectedSuppliers = selectionList;
        break;
    }
  }

  // Set selection to none based on field type
  function onSelectNone(fieldType) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = [];
        break;
      case 'costCenter':
        $scope.selectedCostCenters = [];
        break;
      case 'supplier':
        $scope.selectedSuppliers = [];
        break;
    }
  }

  function generateOptionsBasedOnReportType (reportType) {
    $scope.selectedCompanies = [];
    $scope.selectedSuppliers = [];
    switch (reportType.code) {
      case 'SPEND':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = true;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        break;
      case 'RECEIVING':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = true;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        break;
      case 'REQUISITION_COUNTER':
        $scope.buSelect = false;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        break;
      case 'REQUISITION':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        break;
      case 'SUPPLIER_TAGGING':
        $scope.buSelect = true;
        $scope.supplierSelect = true;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        getSuppliers();
        break;
      case 'BUDGET':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        break;
      default :
        $scope.buSelect = false;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        break;
    }
  }

  function onSelectBusinessUnit (businessUnit) {
    $scope.selectedCompanies = [];
    $scope.businessUnitSelected = true;
    $scope.inputId = businessUnit._id;

    getCompanies($scope.inputId);
  }

  function onSelectDateType (type) {
    $scope.input_date_type = type;
  }
  //endregion

  function openDatepicker($event, which) {
    if($scope.datepickerOpened[which]){
      $event.preventDefault();
      $event.stopPropagation();
    }
    else{
      $timeout(function(){
        $scope.datepickerOpened[which] = true;
      })
    }

    if (!!$scope.endDate && !!$scope.endDate.date) {
      $scope.startDateOptions = {
        formatYear: 'yy',
        maxDate: $scope.endDate.date
      };
    }

    if (!!$scope.startingDate && !!$scope.startingDate.date) {
      $scope.endDateOptions = {
        formatYear: 'yy',
        minDate: $scope.startingDate.date
      };
    }

    if (!!$scope.reports.type && !!$scope.reports.type.code && $scope.reports.type.code !== 'BUDGET') {
      $scope.startDateOptions.maxDate = new Date();
      $scope.endDateOptions.maxDate = new Date();
    }
  }

  //region Functions for generating array for payload
  function extractCompanyId (){
    $scope.company_ids = [];
    _.forEach($scope.selectedCompanies, function(company){
      if (!!company && !!company._id)
        $scope.company_ids.push(company._id);
    });
  }

  function extractCompanyCode (){
    $scope.companyCodes = [];
    _.forEach($scope.selectedCompanies, function(company){
      if (!!company && !!company.code)
        $scope.companyCodes.push(company.code);
    });
  }

  function extractSupplierCode (){
    $scope.supplierCodes = [];
    _.forEach($scope.selectedSuppliers, function(supplier){
      if (!!supplier && !!supplier.mb_supplier_code)
        $scope.supplierCodes.push(supplier.mb_supplier_code);
    });
  }

  /**
   * Extract cost center code
   */
  function extractCostCenterCode() {
    $scope.costCenterCodes = [];
    _.forEach($scope.selectedCostCenters, function (costCenter) {
      if (!!costCenter && !!costCenter.code)
        $scope.costCenterCodes.push(costCenter.code);
    });
  }
  //endregion

  //region Functions for getting companies and supplier listing
  function getCompanies(businessUnit){
    var param = {};
    var resource;
    if (businessUnit === 'all') {
      resource = searchModule;
      param.offset = 0;
      param.module = 'companies';
      param.order = 1;
      param.order_by = 'descr';
    } else {
      resource = singleBU;
      param.id = businessUnit;
    }

    resource.get(param,
      function (success) {
        if (!!success.content.data.companies) {
          $scope.companyList = success.content.data.companies;
        } else {
          $scope.companyList = success.content.data;
        }

        if (!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN') && checkForGlobalReportRole() === false) {
          $scope.companyList = filterUsersData($scope.companyList, 'code', usersCompaniesCode, true);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function getSuppliers () {
    if (!!JSON.parse(window.localStorage.getItem('activeSuppliersForTagging'))){
      $scope.suppliersList = (JSON.parse(window.localStorage.getItem('activeSuppliersForTagging')));
    }
    else {
      suppliersActiveList.get(
        {},
        function(resource) {
          if(!!resource && !!resource.content && !!resource.content.data){
            window.localStorage.setItem('activeSuppliersForTagging', JSON.stringify(resource.content.data));
            $scope.suppliersList = resource.content.data;
          }
        },
        function(error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }
  //endregion

  //region Functions for checking permission and validation
  function hasSupplierTagPermission () {
    return (!!UserPermissions.checkPermissionsAccess(profile, 'C', 'SupplierTag') &&
      !!UserPermissions.checkPermissionsAccess(profile, 'U', 'SupplierTag'));
  }

  function checkForGlobalReportRole(){
    var role = globalFunc.findInArray(profile.roles, 'name', 'Report Manager');
    if(!role)
      return false;

    if(!!role.company_code && role.company_code.length > 0)
      return false;

    return true;
  }

  /**
   * validation for report generation form
   * @returns {boolean}
   */
  function validate(){
    extractCompanyId();
    extractCompanyCode();
    extractSupplierCode();
    extractCostCenterCode()
    if(!$scope.reports.type){
      return false;
    }

    /**
     * Only validate for spend report or receiving details report type
     */
    if ($scope.reports.type.code === 'SPEND' || $scope.reports.type.code === 'RECEIVING' ||
      $scope.reports.type.code === 'BUDGET') {
      if ($scope.company_ids.length === 0) {
        return false;
      }

      if (!$scope.startingDate || !$scope.startingDate.date || !$scope.endDate || !$scope.endDate.date) {
        return false;
      }

      if ($scope.startingDate > $scope.endDate) {
        toastr.error('Ending date must be older than starting date');
        return false;
      }
      if (($scope.reports.type.code === 'SPEND' || $scope.reports.type.code === 'RECEIVING') &&
          (!$scope.input_date_type || !$scope.input_date_type.id)) {
        return false;
      }
    }
    if (($scope.reports.type.code === 'REQUISITION' || $scope.reports.type.code === 'BUDGET') &&
      (!$scope.company_ids || $scope.company_ids.length === 0)) {
      return false;
    }
    if ($scope.reports.type.code === 'SUPPLIER_TAGGING') {
      if ($scope.company_ids.length === 0 && $scope.selectedSuppliers.length === 0) {
        return false;
      }
      else if ($scope.company_ids.length === 0 && $scope.selectedSuppliers.length > 0) {
        return true;
      }
      else if ($scope.company_ids.length > 0 && $scope.selectedSuppliers.length === 0) {
        return true;
      }
    }

    if ($scope.reports.type.code === 'BUDGET') {
      if (!$scope.selectedBudgetType.list || !$scope.selectedBudgetType.list.id) {
        return false;
      }
    }

    return true;
  }
  //endregion

  //region Functions for generating report and check
  function downloadReport(hash){
    var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
      id: hash
    });
    window.open(newUrl, '_self');
  }

  /**
   * Function to check the export status with intervals
   * @param id
   */
  function checkReport(id){

    checkReportStatus.get(
      {
        id: id
      },
      function(resource){
        if(!!resource.content && !!resource.content.data &&
          !!resource.content.message && !!resource.content.data.status &&
          !!resource.content.data.hash){

          /**
           * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
           */
          toastr.clear();

          var checkingStatus = true;

          /**
           * Status for report generation
           * 0 = PENDING
           * 1 = IN_PROGRESS
           * 2 = DONE
           * 3 = FAILED
           * 4 = CANCELLED
           */
          switch(resource.content.data.status){
            case 0:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 1:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              break;
            case 2:
              toastr.success(resource.content.message,
                {timeOut: 1500, extendedTimeOut: 0, onClose: downloadReport(resource.content.data.hash)});
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 3:
              toastr.error(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 4:
              toastr.info(resource.content.message, {timeOut: 0, extendedTimeOut: 0});
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
          }
          $scope.checkingReportStatus = checkingStatus;
        }
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  /**
   * Report Generation
   */
  function generateReports(startDate, endDate){
     $scope.checkingReportStatus = true;
     startDate = globalFunc.convertDateToTimestamp(startDate);
     endDate = globalFunc.convertDateToTimestamp(endDate);

    if(!validate()){
      toastr.error('Please fill all fields before proceeding');
      $scope.checkingReportStatus = false;
    }
    else {
      var inputDateType = '';
      if ($scope.reports.type.code === 'RECEIVING' || $scope.reports.type.code === 'SPEND') {
        inputDateType = $scope.input_date_type.id;
      }

      var submitData = {
        reportType: $scope.reports.type.code,
        dateFrom: startDate,
        dateTo: endDate,
        companyCodes: $scope.companyCodes,
        supplierCodes: $scope.supplierCodes,
        companyIDs: $scope.company_ids,
        costCenterCodes: !!$scope.costCenterCodes ? $scope.costCenterCodes : [],
        budgetType: !!$scope.selectedBudgetType.list && !!$scope.selectedBudgetType.list.id ?
          $scope.selectedBudgetType.list.id : null,
        dateType: inputDateType
      };

      reports.post({},
        submitData,
        function(resource) {
          toastr.info('Report is being generated. The download will start automatically', {timeOut: 0, extendedTimeOut: 0});
          $scope.startChecking = $interval(function () {
            checkReport(resource.content.data[0]);
          }, 5000);
          $scope.input_date_type = '';
          $scope.supplierSelect = '';
          $scope.selectedSuppliers = '';
          $scope.buSelect = '';
          $scope.reports.type = '';
          $scope.reports.businessId = '';
          $scope.selectedCompanies = '';
          $scope.spendReportBasedOn = false;
          $scope.dateTypeSelect = false;
          $scope.dateSelect = false;
          $scope.startingDate = {
            date: new Date()
          };
          $scope.endDate      = {
            date:  new Date()
          };
          $scope.selectedCostCenters = [];
          $scope.selectedBudgetType.list = null;
          $scope.companyList = [];
        },
        function(error) {
          $scope.checkingReportStatus = false;
          globalFunc.objectErrorMessage(error);
        }
      )
    }
  }
  //endregion

  //region Functions for filtering data
  /**
   * filter data based on the params
   * @param array
   * @param filteredKey
   * @param filterData
   * @param reject
   * true or false to reject data
   * @returns {*}
   */
  function filterUsersData(array, filteredKey, filterData, reject) {
    if (filterData.length === 0) return array;

    return _.reject(array, function(val){
      if (!!reject)
        return filterData.indexOf(val[filteredKey]) === -1;
      else
        return filterData.indexOf(val[filteredKey]) > -1;
    });
  }

  function dataFilter() {
    if(!!usersPerms) {
      // check the permissions for reportLog
      if (!!usersPerms.ReportLog && usersPerms.ReportLog.hasOwnProperty('C')) {
        // get the report permission to companies
        usersCompaniesCode = usersPerms.ReportLog.C;

        // get the BUs data belongs to user
        // based on the user permissions
        if (usersCompaniesCode.length > 0) {
          usersBUCode = filterUsersData(usersCompanies, 'code', usersCompaniesCode, true);
          usersBUCode = globalFunc.arrayOfObjectToString(usersBUCode, 'bu_code');
        }
      }
    }
  }
  //endregion

  /**
   * Get cost center list when any selected company changes
   */
  function onSelectedCompanyChanges() {
    $scope.costCenterList = [];

    //stop when no company is selected or is not in budget report
    if (!$scope.selectedCompanies.length || $scope.reports.type.code !== 'BUDGET') {
      return;
    }

    extractCompanyCode();

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'or',
      order_by: 'descr',
      order: 1
    };

    _.forEach($scope.companyCodes, function (companyCode, index) {
      params['criteria[company_code][' + index + ']'] = companyCode;
    });

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterList = resource.content.data;
          _.forEach($scope.costCenterList, function (costcenter) {
            costcenter.descr = costcenter.descr + ' [' + costcenter.company_name + ']';
          });
        }
      }
    )
  }

  function initialize(){
    $scope.$watch('selectedCompanies.length', function () {
      onSelectedCompanyChanges();
    });

    if (!!$scope.$parent)
      $scope.$parent.activatedTab = $scope.$parent.tabData[1];
    else
      $scope.activatedTab = $scope.tabData[1];

    $scope.startingDate = {
      date: new Date()
    };
    $scope.endDate      = {
      date:  new Date()
    };

    $scope.startDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };
    $scope.endDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };

    dataFilter();
    if(!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN') && checkForGlobalReportRole() === false)
      $scope.business = filterUsersData($scope.business, 'code', usersBUCode, true);

    $scope.business.unshift(allBUs);

    $scope.multiselectTranslation       = {
      selectAll       : 'Select all',
      selectNone      : 'Select none',
      reset           : 'Reset',
      search          : 'Type here to search...',
      nothingSelected : 'Select'
    };
  }

  initialize();
}

reportsGenerationCtrl.$inject = [
  '$scope', 'reports', 'toastr', 'businessList', 'singleBU', '$timeout','$filter', 'profile',
  'globalFunc', 'checkReportStatus', '$interval', 'pathConstants', 'reportTypes', '$state', 'searchModule',
  'suppliersActiveList', 'UserPermissions'
];

angular
  .module('metabuyer')
  .controller('reportsGenerationCtrl', reportsGenerationCtrl);
