/**
 * @name supplierGlobalFunc
 *
 * @description Service for supplier global functions
 * This service is for functions that are re-used in supplier modules, to minimize redundancy
 *
 * @function getPaidUpCategory
 * This function is to get the paid up category based on provided paid up amount
 *
 * @function checkHyphenFirstCharacter
 * This function is to check if first character of string is a hyphen "-"
 *
 * @author Justin Cheong <justin.cty90@gmail.com>
 * @author Deniel Ariesta <deniel.ariesta@gmail.com>
 * @copyright 2016 Metacloud Sdn. Bhd.
 */

'use strict';

function supplierGlobalFunc (globalFunc){
  var supplierStatuses = [
    { val: 1, descr: 'active' },
    { val: 2, descr: 'black_listed' },
    { val: 6, descr: 'inactive' }
  ];
  var supplierApprovalStatuses  = [
    { val: 0, descr: 'pending' },
    { val: 1, descr: 'approved' },
    { val: 2, descr: 'rejected' },
    { val: 3, descr: 'on_hold' }
  ];
  var supplierDraftingStatuses  = [
    { val: 0, descr: 'draft' },
    { val: 1, descr: 'submitted' },
    { val: 2, descr: 'withdrawn' },
    { val: 3, descr: 'approved' },
    { val: 4, descr: 'disproved'}
  ];

  /**
   * To get supplier status List based on supplier status, supplier approval status, and supplier drafting status
   * the supplier status sequence is
   * supplierStatuses, supplierApprovalStatuses, supplierDraftingStatuses
   * means it can be any status
   * @param key
   */
  function generateSupplierStatusList(key) {
    return {
      draft:        [ '*', '*', supplierDraftingStatuses[0][key] ],
      withdrawn:    [ '*', '*', supplierDraftingStatuses[2][key] ],
      pending:      [ '*', supplierApprovalStatuses[0][key], supplierDraftingStatuses[1][key] ],
      active:       [ supplierStatuses[0][key], supplierApprovalStatuses[1][key], '*' ],
      black_listed:  [ supplierStatuses[1][key], supplierApprovalStatuses[1][key], '*' ],
      rejected:     [ '*', supplierApprovalStatuses[2][key], supplierDraftingStatuses[4][key] ],
      inactive:     [ supplierStatuses[2][key], supplierApprovalStatuses[1][key], '*' ],
      approved:     [ '*', supplierApprovalStatuses[1][key], '*' ]
    };
  }

  return {
    getPaidUpCategory: function(paidUpValue) {
      var paidUpCategoryDesc;

      if(!!paidUpValue) {
        if ((paidUpValue >= 1) && (paidUpValue <= 50000))
          paidUpCategoryDesc = '(from 1 to 50,000)';
        else if ((paidUpValue > 50000) && (paidUpValue <= 100000))
          paidUpCategoryDesc = '(from 50,001 to 100,000)';
        else if ((paidUpValue > 100000) && (paidUpValue <= 250000))
          paidUpCategoryDesc = '(from 100,001 to 250,000)';
        else if ((paidUpValue > 250000) && (paidUpValue <= 500000))
          paidUpCategoryDesc = '(from 250,001 to 500,000)';
        else if ((paidUpValue > 500000) && (paidUpValue <= 1000000))
          paidUpCategoryDesc = '(from 500,001 to 1,000,000)';
        else if (paidUpValue > 1000000)
          paidUpCategoryDesc = '(above 1,000,001)';
      }

      return paidUpCategoryDesc;
    },
    /**
     * Get supplier status for status in supplier controller
     * @param supplierStatus
     * @param approvalStatus
     * @param draftingStatus
     * @param statusType
     * @returns {string}
     */
    getSupplierStatus: function(supplierStatus, approvalStatus, draftingStatus, statusType) {

      if (statusType === 'descr'){
        supplierStatus = supplierStatus.toLowerCase();
        approvalStatus = approvalStatus.toLowerCase();
        draftingStatus = draftingStatus.toLowerCase();
      } else if (statusType === 'val') {
        var tempSupplierStatus = globalFunc.findInArray(supplierStatuses, 'val', supplierStatus);
        var tempApprovalStatus = globalFunc.findInArray(supplierApprovalStatuses, 'val', approvalStatus);
        var tempDraftingStatus = globalFunc.findInArray(supplierDraftingStatuses, 'val', draftingStatus);

        if(!!tempSupplierStatus) supplierStatus = tempSupplierStatus.descr;
        if(!!tempApprovalStatus) approvalStatus = tempApprovalStatus.descr;
        if(!!tempDraftingStatus) draftingStatus = tempDraftingStatus.descr;
      }

      if(draftingStatus === 'draft' || draftingStatus === 'withdrawn')
        return draftingStatus;

      if(approvalStatus === 'rejected' || approvalStatus === 'pending')
        return approvalStatus;

      if(approvalStatus === 'approved'){
        if(supplierStatus === 'active' || supplierStatus === 'black_listed' || supplierStatus === 'inactive')
           return supplierStatus;
        else
          return approvalStatus;
      }

      return 'archived';
    },
    /**
     * get the status list based on string status (pending, draft, etc)
     * @param status
     * @returns {Array}
     */
    getSupplierListStatus: function(status) {
      status = status.toLowerCase();
      var supplierStatusList = generateSupplierStatusList('val');
      if (!!supplierStatusList.hasOwnProperty(status))
        return supplierStatusList[status];
      else
        return [];
    },

    /**
     * Preparing supplier group data to send
     * @param groups
     * @returns {{}}
     */
    prepareSupplierGroup: function (groups) {
      var supplierGroup = {};
      _.forEach(groups, function(group) {
        var groupCode = group.code;
        supplierGroup[groupCode] = {
          status: Number(group.supplierGroupStatus)
        };

        if (!!group.reason)
          supplierGroup[groupCode].reason = group.reason;
      });

      return supplierGroup;
    },

    /**
     * Filter inactive payment code
     * @param data
     * @returns object
     */
    filterInactiveCode: function (data) {
      for (var i=0;data.length>i;i++) {
        if (data[i].is_active === 0) {
          data.splice(i,1);
        }
      }
      return data;
    },

    checkHyphenFirstCharacter: function (string) {
      return (!!string && string.substring(0, 1) === '-');
    },
    /**
     * Check if user has system role: Supplier Manager
     *
     * @param {object} user
     * @returns {boolean}
     */
    checkIfUserHasSupplierManagerRole: function (user) {
      if (!user || !user.roles) {
        return false;
      }

      var result = false;
      _.forEach(user.roles, function (role) {
        if (role.name === 'Supplier Manager' && role.company_code === null) {
          result = true;
        }
      });
      return result;
    }
  }
}

supplierGlobalFunc.$inject = ['globalFunc'];

angular
  .module('metabuyer')
  .factory('supplierGlobalFunc',supplierGlobalFunc);
