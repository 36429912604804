'use strict';

/**
 * @name editAddressCostCenterCtrl
 * @desc Controller for edit address controller
 */
function editAddressCostCenterCtrl(
  $scope, $uibModalInstance, addressType, toastr, singleAddress, globalFunc, CostCenters, addressDetail,
  costCenterDetails, updateFunction
) {

  $scope.addressType = addressType;
  $scope.updateAddressModel = updateFunction;
  $scope.onUpdatedState = onUpdatedState;
  $scope.onUpdatedCountry = onUpdatedCountry;

  $scope.cancel = cancel;
  $scope.saveEditAddress = saveEditAddress;
  $scope.deleteAddress = deleteAddress;

  function cancel() {
    $uibModalInstance.close();
  }

  function onUpdatedCountry(country){
    $scope.address.state = undefined;
    $scope.address.country = country.descr;
    if(country.descr === "Malaysia"){
      $scope.selectedMalaysia = true;
    }
    else
    {
      $scope.selectedMalaysia = false;
    }
  }

  function onUpdatedState(state){
    $scope.address.state = state.descr;
  }

  /**
   * To save the changes address to address book endpoint
   */
  function saveEditAddress() {
    $scope.submitted = true;
    if(validate($scope.address)){
      singleAddress.put({
          id: addressDetail._id
        },$scope.address,
        function(resource){
          toastr.success("Successfully updated the address");
          $scope.updateAddressModel($scope.address, $scope.addressType);
          cancel();
        }, function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      toastr.error("Fill the required field");
    }
  }

  function validate() {
    return ($scope.address.line1 && $scope.address.postal_code && $scope.address.state &&
    $scope.address.city && $scope.address.country && $scope.address.phone);
  }

  function deleteAddress(addressType){
    // delete address from array cost center address
    var update_data = {};
    var dataToUpdate = {};

    if(addressType === 'billing'){
      update_data['billing_addresses_ids'] = [];

      if(!!costCenterDetails.billing_addresses){
        _.forEach(costCenterDetails.billing_addresses, function(billingAddress){
          if(addressDetail._id !== billingAddress._id && addressType === 'billing')
            update_data['billing_addresses_ids'].push(billingAddress._id);
        });
      }
    } else if(addressType === 'delivery'){
      update_data['delivery_addresses_ids'] = [];

      if(!!costCenterDetails.delivery_addresses){
        _.forEach(costCenterDetails.delivery_addresses, function(deliveryAddress){
          if(addressDetail._id !== deliveryAddress._id && addressType === 'delivery')
            update_data['delivery_addresses_ids'].push(deliveryAddress._id);
        });
      }
    }

    // to define action
    dataToUpdate['type'] = addressType;
    dataToUpdate['id'] = addressDetail._id;

    CostCenters.put(
      {
        id: costCenterDetails._id,
        descr: costCenterDetails.descr
      }, update_data ,
      function(){
        toastr.success('The address was deleted successfully');
        $uibModalInstance.close(dataToUpdate);
      },
      function(){
        toastr.error('Failed to delete address');
      }
    );
  }

  function initialize(){
    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();
    $scope.address = addressDetail;

    if($scope.address.country === 'Malaysia')
    {
      $scope.selectedMalaysia = true;
    }else {
      $scope.selectedMalaysia = false;
    }
  }

  initialize();

}

editAddressCostCenterCtrl.$inject = [
  '$scope', '$uibModalInstance', 'addressType', 'toastr', 'singleAddress', 'globalFunc', 'CostCenters',
  'addressDetail', 'costCenterDetails', 'updateFunction'
];

angular
  .module('metabuyer')
  .controller('editAddressCostCenterCtrl', editAddressCostCenterCtrl);
