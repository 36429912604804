'use strict';
angular
    .module('metabuyer')
    .directive('rolesSelectionPanel',function($uibModal) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs) {
        var panel = function (selectedCompany) {
          $uibModal.open({
            templateUrl: 'components/rolesSelectionPanel/rolesSelectionTemplate.html',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              user: function(){
                return scope.user
              },
              selected_company: function() {
                return scope.selected_company
              },
              companyRoles: function ($q, rolesList) {
                var deferred = $q.defer();
                rolesList.get(
                  {
                    company_code: selectedCompany.code,
                    offset: 0
                  },
                  function (resource) {
                    if (!!resource.content) {
                      deferred.resolve(resource.content.data);
                    }
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              },
              semanticRoles: function ($q, rolesList) {
                var deferred = $q.defer();
                rolesList.get(
                  {
                    company_code: 'system',
                    offset: 0
                  },
                  function (resource) {
                    if (!!resource.content) {
                      deferred.resolve(resource.content.data);
                    }
                  },
                  function (error) {
                    if (error.status === 404) {
                      deferred.resolve([]);
                    }
                  }
                );
                return deferred.promise;
              }
            },
            controller: 'roleSelectionCtrl'
          });
        };
        scope.openRolesModal = function (selectedCompany) {
          panel(selectedCompany);
        }
      }
    };
  })
  .controller('roleSelectionCtrl',
    function ($scope, user, selected_company, rolesList, singleUser, semanticRoles, companyRoles, $state, userPosition,
              toastr, $uibModalInstance) {
      $scope.rolesList = semanticRoles.concat(companyRoles);
      $scope.user = user;
      $scope.selected_company = selected_company;
      $scope.addNewRole = addNewRole;
      $scope.removeRole = removeRole;
      $scope.closeModal = closeModal;
      $scope.state = $state.current.name;
      function closeModal() {
        $uibModalInstance.close();
      }

      function addNewRole(newRole) {
        swal({
            title: "Confirmation",
            text: "Confirm add new role?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function(isConfirm) {
            if (isConfirm) {
              var tempRolesIds = [];
              //move the ids of the Roles in  a new array to push to the backend right now the User list returned from
              // the backend is just text and wil be updated to an object of id
              for(var i in $scope.user.roles){
                if( newRole._id === $scope.user.roles[i]._id){
                  toastr.error('Cannot add same role again');
                  return false;
                }
                else {
                  tempRolesIds.push($scope.user.roles[i]._id);
                }
              }
              tempRolesIds.push(newRole._id);
              if($scope.state === 'main.user.users.add'){
                toastr.success('Role added successfully');
                user.roles.push(newRole);
                $scope.user.roles_id.push(newRole._id);
              }
              else {
                singleUser.put(
                  {
                    id: user._id
                  },
                  {
                    roles: tempRolesIds
                  }, function () {
                    toastr.success('Role added successfully');
                    user.roles.push(newRole);
                  },
                  function () {
                    toastr.error('Failed to add role');
                    return false;
                  }
                );
              }
            }
          }
        );
      }

      /**
       * Filter the roles returned by the type === 'ORG_ROLE'
       * ORG_ROLE is for position and used in approval flow
       */
      function filterRoles(){
        var tempRoles = [];
        _.forEach($scope.rolesList, function(role){
          if(role.type !== 'ORG_ROLE')
            tempRoles.push(role);
        });
        $scope.rolesList = tempRoles;
      }

      function removeRole(index) {

        swal({
            title: "Confirmation",
            text: "Confirm remove role?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1ab394",
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function(isConfirm) {
            var newRole = {};
            if(!!isConfirm){
              var tempRolesIds = [];
              //move the ids of the Roles in  a new array to push to the backend right now the User list returned from
              // the backend is just text and wil be updated to an object of id
              for(var i in $scope.user.roles){
                $scope.user.roles[i]._id ? tempRolesIds.push($scope.user.roles[i]._id) : null;
              }

              newRole = $scope.user.roles[index];
              tempRolesIds.splice(index, 1);

              if($scope.state === 'main.user.users.add'){
                toastr.success('Role removed');
                user.roles.splice(index, 1);
                $scope.user.roles_id.splice(index, 1);
              } else {
                singleUser.put(
                  {
                    id: user._id
                  },
                  {
                    roles: tempRolesIds
                  }, function () {
                    toastr.success('Role removed');
                    user.roles.splice(index, 1);
                  },
                  function () {
                    toastr.error('Role remove failed');
                    return false;
                  }
                );
              }
            }
          }
        );
      }

      function initialize(){
        filterRoles();
      }

      initialize();
    }
  );
