'use strict';

/**
 * @name securityManageCtrl
 * @desc Controller for security for Viewing and Adding Roles
 */
function securityManageCtrl($scope, managedRole, permissionsList, rolesList , globalFunc, classes,
                            $state, toastr, $stateParams, $rootScope) {

  $scope.back = back;
  $scope.submitRole = submitRole;
  $scope.enableEditing = enableEditing;
  $scope.disableEditing = disableEditing;
  $scope.editCheck = editCheck;
  $scope.editDropDown = editDropDown;
  $scope.cancelEditing = cancelEditing;
  $scope.back = back;
  $scope.deleteRole = deleteRole;

  $scope.managedRole = managedRole.data;
  $scope.classPermissionsList = classes;
  $scope.backUpManagedRole = angular.copy(managedRole.data);
  $scope.managedRole.permissionsConditions = reformatPermissionsConditions($scope.managedRole.permissions_conditions);
  $scope.managedRole.permissions = reformatPermissions($scope.managedRole.permissions_list);
  $scope.rolePreview = formatRole($scope.managedRole);

  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $stateParams.id,
      'object[0][object_type]': 'Metabuyer\\Models\\Role',
      offset: 10,
      order_by: '-created_at' // Created at, descending
    }
  };

  function generateTenantSuperAdminPermissionsList(){

    /**
     * Variables for looping index
     */
    var classPermissionIndex;
    var classValueIndex;
    var classDropdownIndex;

    for (classPermissionIndex in $scope.classPermissionsList) {
      for (classValueIndex in $scope.classPermissionsList[classPermissionIndex].val) {
        if ($scope.classPermissionsList[classPermissionIndex].val[classValueIndex].subjectAction !== 'Approve') {
          if ($scope.classPermissionsList[classPermissionIndex].dropdown.length > 1) {
            for (classDropdownIndex in $scope.classPermissionsList[classPermissionIndex].dropdown) {
              if ($scope.classPermissionsList[classPermissionIndex].dropdown[classDropdownIndex].name === 'Company') {
                $scope.classPermissionsList[classPermissionIndex].val[classValueIndex].domain = $scope.classPermissionsList[classPermissionIndex].dropdown[classDropdownIndex];
              }
            }
          } else {
            $scope.classPermissionsList[classPermissionIndex].val[classValueIndex].checked = true;
          }
        }
      }
    }
  }

  /**
   * apply the checks and conditions on the permissions list for previewing and editing
   * @param role
   */
  function applyRoleOnPermissionsList(role) {
    refreshPermissionsList();
    for (var i in $scope.classPermissionsList) {
      for (var j in role) {
        if (!!role[j].className) {
          if ($scope.classPermissionsList[i].className.toLowerCase() === globalFunc.translateSecurityClasses(role[j].className).toLowerCase()) {
            for (var k in $scope.classPermissionsList[i].val) {
              for (var z in role[j].actions) {
                if (!!role[j].actions[z].permissions) {
                  if ($scope.classPermissionsList[i].val[k]._id === role[j].actions[z].permissions._id) {
                    if ($scope.classPermissionsList[i].dropdown.length > 1) {
                      for (var y in $scope.classPermissionsList[i].dropdown) {
                        if ($scope.classPermissionsList[i].dropdown[y].name === role[j].actions[z].domain) {
                          $scope.classPermissionsList[i].val[k].domain = $scope.classPermissionsList[i].dropdown[y];
                        }
                      }
                    } else {
                      $scope.classPermissionsList[i].val[k].checked = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * format the role to an object that can be displayed in the html view
   * @param role
   * @returns {Array}
   */
  function formatRole(role){
    var formattedRole = [];
    var temp = {};
    var tempDomain = {};
    var flag = false;
    for(var i in role.permissions_list) {
      temp = {};
      flag = false;
      tempDomain = findDomain(role.permissions_conditions, role.permissions_list[i]._id);
      temp.permissions = angular.copy(role.permissions_list[i]);
      temp.domain = angular.copy(tempDomain);
      temp.actionType = temp.permissions.subjectAction;
      temp.className = angular.copy(findClassName(role.permissions_list[i].subjectClass));

      for (var j in formattedRole) {
        if (formattedRole[j].className === temp.className) {
          flag = true;
          if (!formattedRole[j].actions) {
            formattedRole[j].actions = [];
          }
          formattedRole[j].actions.push(temp);
          break;
        }
      }
      if (!flag) {
        var tempClass = {
          className: temp.className,
          actions: [temp]
        };
        formattedRole.push(tempClass)
      }
    }
    return formattedRole;
  }

  function reformatConditionsToSubmit(conditions){
    var tempConditions = {};
    _.forEach(conditions, function(condition){
      var objKey = Object.keys(condition);
      objKey = objKey[0];
      tempConditions[objKey] = condition[objKey].value;
    });

    return tempConditions;
  }

  function submitRole(message, id) {
    if (validate(message)) {

      var reformatPermissionsConditions = reformatConditionsToSubmit($scope.managedRole.permissionsConditions);

        rolesList.put(
          {
            id: $scope.managedRole._id
          },
          {
            name: $scope.managedRole.name,
            descr: $scope.managedRole.descr,
            permissions_list: $scope.managedRole.permissions,
            permissions_conditions: reformatPermissionsConditions,
            company_code: $scope.activeCompany.code,
            type: 1
          },
          function (resource) {
            /**
             * update the role that was just updated in the List
             */
            toastr.success('The role has been '+ message);
            $scope.backUpManagedRole = angular.copy($scope.managedRole);
            disableEditing();
          },
          function (error) {
            //TODO: use the error function (Ahmed Saleh, 18/2/2016)
            toastr.error('Failed to update the Role');
          }
        )
    }
  }

  function deleteRole() {
    rolesList.delete({
      id: $scope.managedRole._id
    }, function (success) {
      toastr.success('Role has been deleted successfully');
      disableEditing();
      $state.go('main.user.security.rolesListing', {code: $scope.activeCompany.code})
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function enableEditing(){
    $scope.editMode = true;
  }

  function cancelEditing(){
    $state.reload();
  }

  function disableEditing(){
    $scope.editMode = false;
  }

  /**
   * clear the selection for any Role
   */
  function refreshPermissionsList() {
    for (var i in $scope.classPermissionsList) {
      for (var k in $scope.classPermissionsList[i].val) {
        $scope.classPermissionsList[i].val[k].checked = null;
        /**
         * fix to auto fill the not Allowed dropdown
         */
        $scope.classPermissionsList[i].val[k].domain = {
          name: 'not allowed',
          value: 'null'
        };
      }
    }
  }

  /**
   * Store the Role to be Edited
   * as well as preview
   */
  function roleSelected(role) {
    $state.go('main.user.security.edit', {'id': role._id});
  }

  /**
   * get the class name of a permission
   * @param words
   * @returns {*}
   */
  function findClassName(words) {
    var n = words.split("\\");
    return n[n.length - 1];

  }



  /**
   * get and clean up the domain of each permission
   * @param conditions
   * @param id
   * @returns {*}
   */
  function findDomain(conditions, id) {
    var temp = null;
    id = String(id);
    if (!!conditions[id]) {
      if(conditions[id] === '(@__OWN__)'){
        temp = 'Self';
      }else if (conditions[id].indexOf('@__COMPANY_') > -1){
        temp = 'Company';
      }else if (conditions[id].indexOf('@__COST_CENTER__') > -1){
        temp = 'Cost Center';
      }else if(conditions[id].indexOf('1==1') > -1){
        temp = 'Company';
      }
      return temp;
    }
    return temp;
  }

  function searchRoleNameDuplication(roleName, id){
    for(var i in $scope.roles){
      if($scope.roles[i].name.toLowerCase() === roleName.toLowerCase()){
        if($scope.roles[i]._id !== id) {
          if (!!$scope.roles[i].company_code) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * validate the selection
   */
  //TODO: check if the name is not spaces (Ahmed Saleh)
  function validate(message) {
    if (!$scope.activeCompany || !$scope.activeCompany.code) {
      toastr.error('Please select a company for this role');
      return false;
    }
    if (!$scope.managedRole.name) {
      toastr.error('Please enter a name for this role');
      return false;
    }
    if (!$scope.managedRole.descr) {
      toastr.error('Please enter a description for this role');
      return false;
    }
    if(message !== 'updated') {
      if (searchRoleNameDuplication($scope.managedRole.name, $scope.managedRole._id)) {
        toastr.error('Please enter another name for this role, this Role name is already taken');
        return false;
      }
    }
    /**
     * if Role is edited, just check against if all permissions selection were removed
     */
    if (message === 'updated') {
      if ((!$scope.managedRole.permissions_list || $scope.managedRole.permissions_list.length < 1) && (!$scope.managedRole.permissions || $scope.managedRole.permissions.length < 1)) {
        toastr.error('Please assign at least 1 permission');
        return false;
      }
    }
    /**
     * if Role is new, just check if there were permissions selected
     */
    else {
      if (!$scope.managedRole.permissions || $scope.managedRole.permissions.length < 1) {
        toastr.error('Please assign at least 1 permission');
        return false;
      }
    }
    return true;
  }

  function back(){
    window.history.back();
  }

  function formatThePermissions() {
    /**
     * this to reformat the permissions so they can be used
     * this function might not be needed later on if the return from the backend is in a usable format
     */
    $scope.permissionsList = [];
    var temp = permissionsList.data;
    for (var i = 0; i < temp.length; i++) {
      var temp2 = temp[i].name.slice(0, 1);
      var temp3 = temp[i].name.slice(2);
      var temp4 = {
        _id: temp[i]._id,
        operation: temp2,
        name: temp3
      };
      $scope.permissionsList.push(temp4);
    }
  }

  /**
   * cleaning the messy object and make it an array, so that permissions conditions can be appended/removed
   * @returns {Array}
   */

  function reformatPermissionsConditions(conditions){
    var tempArray = [];
    _.forEach(conditions, function(condition, key){
      if(!!condition){
        var tempCondition = {};
        if((condition.indexOf('@__OWN') > -1) ||
          (condition.indexOf('@__COMPANY') > -1) ||
          (condition.indexOf('@__COST_CENTER__') > -1))
        {
          if (condition.indexOf('@__OWN') > -1) {
            tempCondition[key] = {
              name: "Self",
              value: condition
            };
          } else if (condition.indexOf('@__COST_CENTER__') > -1) {
            tempCondition[key] = {
              name: "Cost Center",
              value: condition
            };
          } else {
            tempCondition[key] = {
              name: "Company",
              value: condition
            };
          }
        }else{
          tempCondition[key] = {
            name: "Self",
            value: condition
          };
        }
        tempArray.push(tempCondition);
      }
    });

    return tempArray;
  }

  /**
   * cleaning the messy object and make it an array, so that permissions can be appended/removed
   * @returns {Array}
   */
  function reformatPermissions(permissions){
    var tempArray = [];
    for(var i in permissions){
      if(typeof permissions[i] === 'object'){
        tempArray.push(permissions[i]._id);
      }
    }
    return tempArray;
  }

  /**
   * cleaning the permissions array
   * @param id
   */
  function removePermissionFromPermissionsList(id){
    for (var i in $scope.managedRole.permissions) {
      if($scope.managedRole.permissions[i] === id){
        $scope.managedRole.permissions.splice(i,1);
      }
    }
  }

  /**
   * cleaning the permissions conditions array
   * @param id
   */
  function removePermissionConditionFromPermissionsConditions(id){
    for (var i in $scope.managedRole.permissionsConditions) {
      if(Object.keys($scope.managedRole.permissionsConditions[i])[0] === id){
        $scope.managedRole.permissionsConditions.splice(i,1);
      }
    }
  }

  /**
   * add/remove a permission with the check boxes
   * @param perm
   */
  function editCheck(perm) {
    if (perm.checked === true) {
      removePermissionFromPermissionsList(perm._id);
      removePermissionConditionFromPermissionsConditions(perm._id);
      $scope.managedRole.permissions.push(perm._id);
      var tempCondition = {};
      tempCondition[perm._id] = {
        name: 'Self',
        value: '(1==1)'
      };
      $scope.managedRole.permissionsConditions.push(tempCondition);
    }else{
      removePermissionFromPermissionsList(perm._id);
      removePermissionConditionFromPermissionsConditions(perm._id);
    }
  }

  /**
   * add/remove a permission with the dropdown
   * @param perm
   */
  function editDropDown(perm){
    if(!!perm.domain){
      if(perm.domain.value === null){
        removePermissionFromPermissionsList(perm._id);
        removePermissionConditionFromPermissionsConditions(perm._id);
      }else if(perm.domain.value === '(@__OWN__)'){
        removePermissionFromPermissionsList(perm._id);
        removePermissionConditionFromPermissionsConditions(perm._id);
        $scope.managedRole.permissions.push(perm._id);

        var tempCondition = {};
        tempCondition[perm._id] = perm.domain;
        $scope.managedRole.permissionsConditions.push(tempCondition);
      }else{
        removePermissionFromPermissionsList(perm._id);
        removePermissionConditionFromPermissionsConditions(perm._id);
        $scope.managedRole.permissions.push(perm._id);

        var tempCondition = {};
        tempCondition[perm._id] = perm.domain;
        $scope.managedRole.permissionsConditions.push(tempCondition);
      }
    }
  }

  function initialize(){
    if(!$scope.managedRole.permissionsConditions){
      $scope.managedRole.permissionsConditions = [];
    }
    if(!$scope.managedRole.permissions){
      $scope.managedRole.permissions = [];
    }
    $scope.userPerms = [];
    $scope.adminPerms = [];

    for(var i in $scope.rolePreview){
      if($scope.rolePreview[i].className === 'PR'){
        $scope.group = 'user';
        $scope.userPerms.push($scope.rolePreview[i]);
      }
      else if($scope.rolePreview[i].className === 'PO'){
        $scope.group = 'user';
        $scope.userPerms.push($scope.rolePreview[i]);
      }
      else{
        $scope.group = 'admin';
        $scope.adminPerms.push($scope.rolePreview[i]);
      }
    }

    $scope.rolePreview.title = $scope.managedRole.name;

    /**
     * If managed role is tenant super admin, generate the preview via hardcoding,
     * else for other roles, generate the preview based on permissions_list and permissions_condition
     */
    if(!!$scope.managedRole && !!$scope.managedRole.name && $scope.managedRole.name === 'TENANT_SUPER_ADMIN') {
      generateTenantSuperAdminPermissionsList();
    }
    else{
      applyRoleOnPermissionsList($scope.rolePreview);
    }

    formatThePermissions();
    $rootScope.$broadcast('setCompany', $stateParams.code);
  }

  initialize();
}

securityManageCtrl.$inject = [
  '$scope', 'managedRole', 'permissionsList', 'rolesList', 'globalFunc', 'classes',
  '$state', 'toastr', '$stateParams', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('securityManageCtrl', securityManageCtrl);
