'use strict';
/**
 * @name passwordResetCtrl
 * @desc Controller for Users to enter their new password after it has been reset
 */
function passwordResetCtrl($scope, $rootScope, passwordUpdate, $state, toastr, ManageLocalStorage, $auth) {
  $scope.updatePassword = updatePassword;

  $scope.isChangePasswordClicked = false;
  $scope.errorFlag = false;
  $scope.missMatchError = false;
  $scope.wrongFormatError = false;

  $scope.logout = logout;

  //TODO: use the services of metabuyer (Ahmed Saleh, 31/5/2016)
  function logout() {
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem('satellizer_token');
    $auth.logout();
    $rootScope.currentUser = null;
    $state.go('login');
    toastr.clear();
  }

  function updatePassword() {
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.missMatchError = false;
    $scope.errorFlag = false;
    //checks if they pass all the validation
    if (checkPasswordFormat()) {
      if (!checkMatchPassword()) {
        //message that the new password didn't match
        $scope.missMatchError = true;
        $scope.errorFlag = true;
      }
    }
    else {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.errorFlag = true;
    }
    if (!$scope.errorFlag) {
      passwordUpdate.put({
          password: $scope.password_new,
          password_confirm: $scope.password_confirm

        },
        function (success) {
          toastr.clear();
          toastr.success("Successfully changed password");
          $state.go("main.user.mainMenu.waiting-on-you");
        },
        function (error) {
          if (!!error && !!error.data) {
            if (parseInt(error.data.http_code) === 400) {
              toastr.error((!!error.data.message) ? error.data.message :
                (!!error.data.content && !!error.data.content.error) ? error.data.content.error : 'Failed to change password');
              $scope.errorFlag = true;
            }
          }
        });
    }
    else {

    }

  }

  function checkPasswordFormat() {
    var letter = /[a-z]/;
    var capitalLetter = /[A-Z]/;
    var number = /[0-9]/;
    return (letter.test($scope.password_new) && number.test($scope.password_new) && $scope.password_new.length > 7 && capitalLetter.test($scope.password_new));
  }

  function checkMatchPassword() {
    return $scope.password_new === $scope.password_confirm;
  }

}

passwordResetCtrl.$inject = ['$scope', '$rootScope', 'passwordUpdate', '$state', 'toastr', 'ManageLocalStorage', '$auth'];

angular
  .module('metabuyer')
  .controller('passwordResetCtrl', passwordResetCtrl);
