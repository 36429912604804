'use strict';

/**
 * @name collaboratorsCtrl
 * @desc controller for Adding and removing Collaborators
 *
 * @param $scope
 * @param purchaseRequisitionsServices
 * @param $uibModalInstance
 * @param globalFunc
 * @param $rootScope
 * @param prFunction
 * @param $http
 * @param pathConstants
 * @param toastr
 */

function collaboratorsCtrl($scope, purchaseRequisitionsServices, $uibModalInstance, globalFunc, $rootScope, prFunction, $http, pathConstants, toastr) {

  $scope.loading = false;
  $scope.delay = 500;
  $scope.collaboratorsTextFiled = {};
  $scope.collaboratorsTextFiled.input = null;
  $scope.collaborators = prFunction.getPRData().collaborators;
  $scope.pr = prFunction.getPRData();
  $scope.profile = $rootScope.currentUser;
  $scope.loggedInUserIsAnApprover = globalFunc.findInArray(prFunction.getPRData().waiting_on, '_id', $rootScope.currentUser._id);
  $scope.removeCollaborator = removeCollaborator;
  $scope.onSelect = onSelect;
  $scope.searchUsers = searchUsers;
  $scope.close = close;
  $scope.canUpdateCollaborators = canUpdateCollaborators;

  /**
   * Check if the current PR has valid status to update the collaborator
   *
   * @param string currentPrStatus String of PR status
   * @returns {boolean}
   */
  function canUpdateCollaborators(currentPrStatus) {
    var validPRStatus = [
      'draft',
      'pending',
      'on_hold',
      'withdrawn',
      'rejected',
      'approved'
    ];

    return !_.isEmpty(_.intersection(validPRStatus, [currentPrStatus]));
  }

  /**
   * check if the logged in user is a creator/requestor/current approver of the PR
   * @returns {boolean|*}
   */
  function allowedToPerformAction() {
    return ((!!$scope.pr.requestor && $scope.pr.requestor._id === $scope.profile._id) ||
    (!!$scope.pr.creator && $scope.pr.creator._id === $scope.profile._id) ||
    $scope.loggedInUserIsAnApprover);
  }

  /**
   * delete collaborator
   * @param collaborator
   */
  function removeCollaborator(collaborator) {

    if (allowedToPerformAction()) {

      purchaseRequisitionsServices.removeCollaborator(
        {
          id: $scope.pr._id,
          collaborator_id: collaborator._id
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            prFunction.setPRData(resource.content.data);
            $scope.collaborators = prFunction.getPRData().collaborators;
          }
          /**
           * add the collaborator removal to the history
           * @type {{creator: *, collaborator: *}}
           */
          var history = {
            creator: $scope.profile,
            collaborator: collaborator
          };
          $rootScope.$broadcast('collaboratorAdjusted', [history, 'removed']);
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
    } else {
      toastr.error('Only PR requester/approver can edit collaborators');
    }
  }


  function onSelect(user) {
    addCollaborator(user);
  }

  function searchUsers(query) {

    $scope.loading = true;
    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
      params: {
        module: 'users',
        'criteria[1][display_name]': query,
        'criteria[1][email]': query,
        'criteria[0][status]': 1,
        'criteria[2][companies][]': $scope.pr.company.code,
        'criteria[1][$operator]': 'or',
        criteria_operator: 'and'
      }
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    });

  }

  /**
   * validate the selected new collaborators
   * @param newCollaborator
   * @returns {boolean}
   */
  function validate(newCollaborator) {
    if (newCollaborator._id === $scope.pr.creator._id) {
      toastr.warning('You cannot add the creator as a collaborator');
      return false;
    }
    if ($scope.collaborators != null) {
      for (var i = 0; i < $scope.collaborators.length; i++) {
        if ($scope.collaborators[i]._id === newCollaborator._id) {
          toastr.error('This Collaborator has already been added!');
          return false;
        }
      }
    }
    else {
      $scope.collaborators = [];
    }
    return true;
  }

  /**
   * adding a collaborator
   * @param newCollaborator
   */
  function addCollaborator(newCollaborator) {

    if (allowedToPerformAction()) {

      if (validate(newCollaborator)) {

        purchaseRequisitionsServices.addCollaborator(
          {id: $scope.pr._id},
          {collaborator_id: newCollaborator._id},
          function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prFunction.setPRData(resource.content.data);
              $scope.collaborators = prFunction.getPRData().collaborators;
            }

            /**
             * add the collaborator Addition to the history
             * @type {{creator: *, collaborator: *}}
             */
            var history = {
              creator: $scope.profile,
              collaborator: newCollaborator
            };
            $scope.collaboratorsTextFiled.input = null;
            $rootScope.$broadcast('collaboratorAdjusted', [history, 'added']);
          }, function (error) {
            globalFunc.objectErrorMessage(error);
          });
      }
      else {
        $scope.collaboratorsTextFiled.input = null;
      }
    } else {
      toastr.error('Only PR requester/approver can edit collaborators');
    }
  }


  function close() {
    $uibModalInstance.close();
  }
}

angular
  .module('metabuyer')
  .controller('collaboratorsCtrl', collaboratorsCtrl)
  .directive('collaboratorsPanel',function($uibModal) {
    return {
      restrict: 'A',
      link: function (scope) {
        var panel = function () {
          $uibModal.open({
            templateUrl: 'components/collaborator/collaboratorTemplate.html',
            controller: 'collaboratorsCtrl',
            backdrop: 'static',
            keyboard: false
          });
        };

        //loading the panel to search
        scope.inviteCollaborator = function () {
          panel();
        };

      }
    };
  });
