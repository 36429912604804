'use strict';
/**
 * @name addRolesCtrl
 * @desc Controller for adding a new role
 */

function addRolesCtrl($stateParams, $rootScope) {

  $rootScope.$broadcast('setCompany', $stateParams.code);

}

addRolesCtrl.$inject = ['$stateParams', '$rootScope'];

angular
  .module('metabuyer')
  .controller('addRolesCtrl', addRolesCtrl);
