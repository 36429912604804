'use strict';

/**
 * @name budgetFunctions
 *
 * @description Service for Budget functions
 * This service is for functions that are re-used in budget modules, to minimize redundancy
 *
 * @requires globalFunc
 * @requires $q
 *
 * @function getBudgetStatus
 * Function to convert budget status string to integer
 *
 * @function setEditableState
 * Function to set editable state
 *
 * @function setAssignmentRemovableState
 * Function to set assignment removable state
 *
 * @function getEditableState
 * Function to get editable state
 *
 * @function getAssignmentRemovableState
 * Function to get assignment removable state
 *
 * @function getItemBreakdownRemovableState
 * Function to get editable state for item breakdown
 *
 * @function setItemBreakdownRemovableState
 * Function to set editable state for item breakdown
 *
 * @function getQuarterOfYear
 * Function to return the quarter of the year with provided month
 *
 * @function convertMonthToTextualRepresentation
 * Function to convert given month to textual representation
 *
 * @function checkApportionAllocation
 * Function to check if apportion allocation values will change due to field changes
 *
 * @function getApportionAllocationData
 * Function to get apportion allocation data, based on apportion type calls respective functions
 *
 * @function removeParentApportionData
 * Function to remove parent apportion data (apportion_allocation and apportion_carry_forward)
 *
 * @authors Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

function budgetFunctions(globalFunc, $q, apportionAllocation) {

  var budgetEditableState = 'editable';
  var assignmentRemovableState = false;
  var itemBreakdownRemovableState = false;
  var budgetSetting = false;

  return {
    /**
     * Converts budget status string to integer representation
     *
     * @param {string} status String representation of budget status
     * Values:
     *         0 for pending
     *         1 for approved
     *         2 for rejected
     *         3 for on_hold
     *         4 for draft
     *         5 for withdraw
     *         6 for inactive
     *         7 for expired
     *         8 for deleted
     *         9 for revision
     */
    getBudgetStatus: function (status) {
      var budgetStatusArray = {
        'pending': 0,
        'approved': 1,
        'rejected': 2,
        'on hold': 3,
        'draft': 4,
        'withdraw': 5,
        'inactive': 6,
        'expired': 7,
        'deleted': 8,
        'revision': 9
      };

      return budgetStatusArray[status];
    },
    setEditableState: function (state) {
      budgetEditableState = state;
    },
    setAssignmentRemovableState: function (state) {
      assignmentRemovableState = state;
    },
    getEditingState: function () {
      return budgetEditableState;
    },
    getAssignmentRemovableState: function () {
      return assignmentRemovableState;
    },
    setItemBreakdownRemovableState: function(itemsBreakdown) {
      for (var i = 0; i < itemsBreakdown.length; i++) {
        if (!!itemsBreakdown[i].committed_amount || !!itemsBreakdown[i].used_amount) {
          itemBreakdownRemovableState = true;
        }
      }
    },
    getItemBreakdownRemovableState: function() {
      return itemBreakdownRemovableState;
    },
    /**
     * To check if apportion allocation values will change due to field changes and returns the value
     * for recalculations
     *
     * @param {*}     oldValue
     * @param {*}     newValue
     * @param {array} apportionAllocation
     * @returns {*} Returns the newValue if apportion allocation will be affected
     */
    checkApportionAllocation: function (oldValue, newValue, apportionAllocation) {
      var deferred = $q.defer();
      // If new value is not equal to old value and old value is not null and apportion allocation is not empty
      if (newValue !== oldValue && !!oldValue && !_.isEmpty(apportionAllocation)) {
        swal(
          {
            title: 'Confirm Apportion allocation',
            text: 'This action will affect the apportion allocation',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1ab394',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function (isConfirm) {
            if (!!isConfirm) {
              deferred.resolve(newValue);
            }
            else {
              deferred.resolve(oldValue);
            }
          }
        );
      }
      // Else if old value is null or apportion allocation is empty
      else if (!oldValue || _.isEmpty(apportionAllocation)) {
        deferred.resolve(newValue);
      }
      return deferred.promise;
    },
    /**
     * Generates the apportion allocation array based on start date, end date, apportion type and amount
     * Will not be called if existing apportion allocation exists
     *
     * @param {Date}    startDate                     Start date for apportion
     * @param {Date}    endDate                       End date for apportion
     * @param {string}  apportionType                 Apportion's type
     * @param {number}  availableAmount               Budget available amount
     * @param {boolean} isRevisionApportionAllocation Flag for revision apportion
     * @param {array}   existingApportionAllocations  Existing apportion allocation
     * @returns {Array}
     */
    getApportionAllocationData: function (startDate, endDate, apportionType, availableAmount,
                                          isRevisionApportionAllocation, existingApportionAllocations) {
      var deferred = $q.defer();

      apportionAllocation.post(
        {
          startDate: startDate,
          endDate: endDate,
          apportionType: apportionType,
          availableAmount: availableAmount,
          isRevisionApportionAllocation: isRevisionApportionAllocation,
          existingApportionAllocations: existingApportionAllocations
        },
        function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data) {
            deferred.resolve(resource.content.data);
          }
          else {
            deferred.resolve([]);
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
          deferred.resolve([]);
        }
      );

      return deferred.promise;
    },
    /**
     * Remove the parent apportion allocation and set apportion carry forward to false
     */
    removeParentApportionData: function (parentData) {
      parentData.apportion_allocation = [];
      parentData.apportion_carry_forward = false;
      return parentData;
    },
    setHasBudgetSettingOn: function (flag) {
      budgetSetting = flag;
    },
    getHasBudgetSettingOn: function () {
      return budgetSetting;
    },

    /**
     * Function to divide number
     *
     * @param {number} numerator    dividend
     * @param {number} denominators divisor
     * @returns {number}
     */
    divide: function (numerator, denominators) {
      return Number(math.format(
        math.divide(numerator, denominators),
        {precision: 64}
      ));
    },

    /**
     * Function to multiply numbers
     *
     * @param {number} firstNumber  first number to be multiplied
     * @param {number} secondNumber second number to be multiplied
     * @returns {number}
     */
    multiply: function (firstNumber, secondNumber) {
      return Number(math.format(
        math.multiply(firstNumber, secondNumber),
        {precision: 64}
      ));
    },

    /**
     * Function to subtract numbers
     *
     * @param {number} subtrahend number to be subtracted
     * @param {number} minuend    number to be subtracted from
     * @returns {number}
     */
    subtract: function (subtrahend, minuend) {
      return Number(math.format(
        math.subtract(subtrahend || 0, minuend || 0),
        {precision: 64}
      ));
    },

    /**
     * Function to add numbers
     *
     * @param {number} firstNumber  first number to be added
     * @param {number} secondNumber second number to be added
     * @returns {number}
     */
    add: function (firstNumber, secondNumber) {
      return Number(math.format(
        math.add(firstNumber || 0, secondNumber || 0),
        {precision: 64}
      ));
    }
  };
}

budgetFunctions.$inject = ['globalFunc', '$q', 'apportionAllocation'];

angular
  .module('metabuyer')
  .factory('budgetFunctions', budgetFunctions);
