'use strict';
/**
 * @name accountLockedCtrl
 * @desc Controller for displaying page for locked accounts
 */
function accountLockedCtrl($scope, $rootScope, $state, ManageLocalStorage, $auth) {

  //applying CSS for the page on the controller level
  $rootScope.bodyClasses = 'gray-bg';

  $scope.logout = logout;

  function logout() {
    ManageLocalStorage.clearLocalStorage();
    window.localStorage.removeItem("satellizer_token");
    window.localStorage.removeItem("email");
    $auth.logout();
    $rootScope.currentUser = null;
    $state.go('login');
  }
}

accountLockedCtrl.$inject = ['$scope', '$rootScope', '$state', 'ManageLocalStorage', '$auth'];

angular
  .module('metabuyer')
  .controller('accountLockedCtrl', accountLockedCtrl);
