'use strict';


function importItemsCtrl(
  $scope, toastr, pathConstants, $http, $filter, $q, changeStatusCatalog, BUs, searchSupplier, $state, Approvals,
  checkImport, itemDetailsListing, getCatalogCodeListing, $timeout, searchModule, globalFunc, singleSupplier, catalogCode
) {

  $scope.disableSubmitting = true;
  $scope.success = false;
  $scope.error = false;
  $scope.saveImage = saveImage;
  $scope.saveFile = saveFile;
  $scope.checkImportFile = checkImportFile;
  $scope.importingItems = importingItems;
  $scope.getItemListing = getItemListing;
  $scope.selectCatalogGroup = selectCatalogGroup;
  $scope.selectSupplier = selectSupplier;
  $scope.getCatalogCodeList = getCatalogCodeList;
  $scope.backToSelectSupplier = backToSelectSupplier;
  $scope.onSelectedCatalogCode = onSelectedCatalogCode;
  $scope.openDatePicker = openDatePicker;
  $scope.setEffectiveDate = setEffectiveDate;
  $scope.search = search;
  $scope.approvals = [];
  $scope.limit = 10;

  $scope.dateOptions = {
    formatYear: 'yy',
    minDate: new Date(),
    startingDay: 1
  };
  $scope.datepickerOpened = {
    startDate: false
  };

  $scope.catalogCodeSelected = false;

  // Add select All
  $scope.BUs = BUs.data;

  //TODO: remove this to a service of its own (Ahmed Saleh, 9/5/2016)
  $scope.column = {
    columns: [
      {id: 'item_name', label: 'Item Name'},
      {id: 'supplier_part_no', label: 'Supplier Part Number'},
      {id: 'tax_rate', label: 'Tax Rate'},
      {id: 'payment_terms', label: 'Payment Term'},
      {id: 'currency', label: 'Currency'},
      {id: 'unit_price', label: 'Unit Price'},
      {id: 'uom', label: 'UOM'},
      {id: 'effective_at', label: 'Effective Date'},
      {id: 'expiry_at', label: 'Expiry Date'}
    ],
    selectedColumns: [],
    buttonText: {buttonDefaultText: 'Columns'},
    setting: {
      buttonClasses: 'btn btn-white',
      smartButtonMaxItems: 3,
      showCheckAll: false,
      showUncheckAll: false
    },
    events: {
      onItemSelect: function (item) {
        $scope.column.saveSelectedColumn($scope.column.selectedColumns);
      },
      onItemDeselect: function (item) {
        $scope.column.saveSelectedColumn($scope.column.selectedColumns);
      }
    },
    showColumn: function (name) {
      for (var index = 0; index < $scope.column.selectedColumns.length; ++index) {
        var item = $scope.column.selectedColumns[index];
        if (item.id == name) {
          return true;
        }
      }
      return false
    },
    saveSelectedColumn: function (columns) {
      window.localStorage.setItem('item-listing-selected-columns',
        JSON.stringify(_.map(columns, function (item) {
          return item.id || item
        })));
    },
    loadSelectedColumn: function () {
      return JSON.parse(window.localStorage.getItem('item-listing-selected-columns')) || [];
    }
  };
  // Apply selected column
  var selectedColumn = $scope.column.loadSelectedColumn();
  if (selectedColumn.length == 0) { // Default column
    for (var index = 0; index < $scope.column.columns.length; ++index) {
      $scope.column.selectedColumns.push($scope.column.columns[index]);
    }
  }
  else {
    for (index = 0; index < selectedColumn.length; ++index) {
      var column = _.find($scope.column.columns, function (item) {
        return item.id == selectedColumn[index];
      });
      if (!!column)
        $scope.column.selectedColumns.push(column);
    }
  }

  var valid = function () {
    if (!$scope.selectedSupplier) {
      toastr.error('Please Choose a Supplier');
      return false;
    }
    if (!$scope.selectedCatalogGroup || (!!$scope.selectedCatalogGroup && !$scope.selectedCatalogGroup._id)) {
      toastr.error('Please Choose a Catalog Group');
      return false
    }
    return true;
  };

  $scope.cancelCatalog = function()
  {
    changeStatusCatalog.put(
      {
        status: 'cancel',
        id: $scope.importId
      },
      function(resource)
      {
        $state.go('main.user.items.import.select_file');
        toastr.error('Catalog successfully cancelled');
      },
      function(error)      {
        toastr.error('Cancelling the catalog failed');
      }
    );

  };

  $scope.submitCatalog = function()
  {
    if(!$scope.importId){
      toastr.error('Please reupload the catalog!');
    }
    else{
      changeStatusCatalog.put(
        {
          status: 'submit',
          id: $scope.importId
        },
        function (resource) {
          $state.go('main.user.items.manageCatalog', {'status': 'pending'});
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  };

  function search(text) {

      // Search only the active supplier
      var params = {
        module: 'suppliers',
        'criteria[descr]': text,
        'criteria[archive_of]': '',
        'criteria[draft_status]': 3,
        'criteria[status]': 1,
        criteria_operator: 'and',
        'criteria[approval_status]': 1
      };

      searchModule.get(
        params,
        function (resource) {
          $scope.searchResult = 0;
          if(resource.content.data.length > 0){
            $scope.suppliersResults = resource.content.data;
            $scope.searchResult = resource.content.data.length;
          }
        },
        function (error) {
            globalFunc.objectErrorMessage(error);
        }
      );
  }

  /**
   *  Function to call endpoint to list item details based on status
   *  @params status: 10 New, 11 Updated, 12 Deleted, 13 Total
   */

  function getItemListing(status) {
    $scope.showItemDetails = true;
    $scope.itemDetailsStatus = status;

    itemDetailsListing.get(
      {
        summary_id: $scope.data._id,
        status: status
      },
      function (resource) {
        if(!!resource && !!resource.content && !!resource.content.data) {
          $scope.itemDetailService = itemDetailsListing;
          $scope.itemDetailsList = resource.content.data;
          $scope.itemDetailsMeta = resource.content.meta;
          $scope.itemDetailsLoaded = true;
          switch (status) {
            case 10:
              $scope.itemDetailTitle = 'New Item(s)';
              break;
            case 11:
              $scope.itemDetailTitle = 'Updated Item(s)';
              break;
            case 12:
              $scope.itemDetailTitle = 'Deleted Item(s)';
              break;
            case 13:
              $scope.itemDetailTitle = 'Total Items';
              break;
          }
          $scope.embeddedParams = {
            id: $scope.data.id,
            offset: 10
          };
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function selectCatalogGroup(catalogGroupID){
    $scope.selectedCatalogGroup = globalFunc.findInArray($scope.BUs, '_id', catalogGroupID);
  }

  function backToSelectSupplier(){
    $scope.catalogCodeSelected = false;
    $scope.newCatalogCodeSelected = false;
    if(!!$state.params){
      window.history.back();
    }
    else{
      $state.go('main.user.items.import.select_supplier');
    }
  }

  function getCatalogCodeList(){
    searchModule.get(
      {
        module: 'catalog-code',
        offset: 0,
        'criteria[is_active]': 1
      },
      function(resource){
        $scope.catalogCodeList = resource.content.data;
      },
      function(error){
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function onSelectedCatalogCode(selectedCatCode){
    $scope.selectedCatalogCode = selectedCatCode;
    $scope.catalogCodeSelected = true;
    $scope.newCatalogCodeSelected = false;
  }

  function openDatePicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  function setEffectiveDate(effective_at){
    $scope.importEffectiveDate = globalFunc.convertDateToTimestamp(effective_at);
  }

  /**
   *  Receive broadcast for updated embedded pagination data to update on controller side
   */
  $scope.$on('updateEmbeddedPagination', function(events, content){
    if(!!content && !!content.data)
    $scope.itemDetailsList = content.data;
  });

  function selectSupplier(supplier) {
    $scope.selectedSupplier = supplier;
  }


  // Check Radio button has a value or not.
  $scope.isRadioSelected = function(index) {
    return index === 1;
  };

  // Import and save CSV file
  function saveFile(items, images, effective_date, catalog_code_id) {

    var fd = new FormData();
    fd.append('file', items);
    fd.append('supplier_id', $scope.selectedSupplier._id);
    fd.append('catalog_group_id', $scope.selectedCatalogGroup._id);
    fd.append('catalog_code_id', catalog_code_id);
    fd.append('import_effective_date', effective_date);
    if(!!$scope.errorImportID){
      fd.append('import_summary_id', $scope.errorImportID);
    }

    $http.post($filter('format')(pathConstants.apiUrls.items.import), fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    })
      .then(function onSuccess (dataCatalog, status) {
        $scope.success = true;
        $scope.error = false;
        $scope.status = status;

        //prepare the success Message
        var data = dataCatalog.data;
        $scope.message = {};
        if (!!data && !!data.content) {
          $scope.data = data.content.data;
          $scope.message.header = $scope.data.file_header;
          $scope.message.version = $scope.data.import_version;
          $scope.message.importInfo = $scope.data.import_info;
          $scope.importId = $scope.data._id;
          getItemListing(13);

          if($scope.data.attachment.length === 0) {
            toastr.error('Failed to upload file.');
          } else {
            $scope.importSummaryId = $scope.data.attachment[0].import_summary_id;

            toastr.success('File successfully uploaded');
            $scope.disableSubmitting = false;

            // Check image empty or not
            // If empty, redirected to next step
            if(!!images) {
              $scope.saveImage(images, $scope.importSummaryId);
            }else{
              $state.go('main.user.items.import.confirmation');
              //checkConfirmationPage();
            }

            // Run loadApprovalFlow function
            loadApprovalFlow($scope.data.approval_id);
            $scope.requestor = {
              '_id': '',
              'display_name': $scope.data.created_by,
              'img_url':''
            };
          }

        } else {
          // No data received
          toastr.error('Failed to upload file.');
        }

      }, function onError (errorMessage) {

        $scope.success = false;
        $scope.error = true;
        $scope.data = null;

        if (!!errorMessage.data) {
          $scope.errorMessageType = '';

          /**
           * If error is a MBSecurityException, then display this error instead
           */
          var errorContent = [];
          var temp;
          //securityError
            if (!!errorMessage.data.error && !!errorMessage.data.error.type &&
                !!errorMessage.data.error.type.indexOf('MBSecurityException') > -1) {
                temp = {
                    key: 'error',
                    value: errorMessage.data.error.message
                };
                errorContent.push(temp);
                $scope.message = errorContent;
            }
            //wrongTemplateMessage
            else if (!!errorMessage.data.content && !!errorMessage.data.content.error &&
                (typeof errorMessage.data.content.error.error === 'string')) {
                temp = {
                    key: 'error',
                    value: errorMessage.data.content.error.error
                };
                errorContent.push(temp);
                $scope.message = errorContent;
            }
            //wrongFileType
            else if (!!errorMessage.data.content && !!errorMessage.data.content.error &&
                (typeof errorMessage.data.content.error === 'string')) {
                temp = {
                    key: 'error',
                    value: errorMessage.data.content.error
                };
                errorContent.push(temp);
                $scope.message = errorContent;
            }
            //normalError object
            else if (!!errorMessage.data.content && !!errorMessage.data.content.error && !!errorMessage.data.content.error.error &&
                (typeof errorMessage.data.content.error.error === 'object') && (typeof errorMessage.data.content.error.error.content === 'object')) {
                errorContent = errorMessage.data.content.error.error.content;
                errorContent = globalFunc.reformatFromObjectToArray(errorContent);
                _.forEach(errorContent, function (n) {
                    n.value = n.value[0];
                });
                $scope.message = errorContent;
            }
            //normalError
            else if (!!errorMessage.data.content && !!errorMessage.data.content.error && !!errorMessage.data.content.error.error &&
                (Array.isArray(errorMessage.data.content.error.error.data))) {
                var errorData = errorMessage.data.content.error.error.data;
                var count = 1;
                _.forEach(errorData, function (n) {
                    temp = {
                        key: 'error'.count,
                        value: n
                    };
                    errorContent.push(temp);
                    count++;
                });
                $scope.message = errorContent;
            }
            //UI form error
            else if (typeof errorMessage.data.content.error === 'object') {
                errorContent = errorMessage.data.content.error;
                errorContent = globalFunc.reformatFromObjectToArray(errorContent);
                _.forEach(errorContent, function (n) {
                    n.value = n.value[0];
                });
                $scope.message = errorContent;
            }

            $scope.disableSubmitting = true;
      }
      });
  }

  // Import and save Image file
  function saveImage(images, summaryId) {
    if(!!images) {
      var fd = new FormData();
      fd.append('file', images);
      $http.post($filter('format')(pathConstants.apiUrls.items.imagesImport + summaryId), fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
        .then(function onSuccess () {
          toastr.success('Images successfully uploaded');
          $state.go('main.user.items.import.confirmation');
          //checkConfirmationPage();
        })
        .catch(function onError () {
          toastr.error('Failed to submit the Images');
        });
    }
  }

  // Action to save Import file
  function importingItems(items, images, effective_date, catalog_code_id) {
    $scope.error = null;
    $scope.status = null;

    //uploading the CSV file
    if(valid())
      saveFile(items, images, effective_date, catalog_code_id);
  }

  // Approval Flow Data
  function loadApprovalFlow(approvalId){

    var deferred = $q.defer();

    if(!approvalId){
      $scope.approvals = [];
      deferred.resolve([]);
      return deferred.promise;
    }

    Approvals.get(
      {
        id: approvalId
      },
      function(resource){
        if(!!resource && !!resource.content && !!resource.content.data &&
          !!resource.content.data.flow){
          $scope.approvals = resource.content.data.flow;
          deferred.resolve(resource.content.data.flow);
        } else {
          $scope.approvals = [];
          deferred.resolve([]);
        }
      },
      function (error){
        $scope.approvals = [];
        deferred.resolve([]);
      });

    return deferred.promise;
  }

  // Check the same Import file
  // that not approved yet
  function checkImportFile(item, images){
    var catalog_code_id;

    if(!!$scope.catalogCodeSelected)
      catalog_code_id = $scope.selectedCatalogCode._id;

    if(valid()) {
      checkImport.get(
        {
          supplier_id: $scope.selectedSupplier._id,
          BU: $scope.selectedCatalogGroup._id,
          catalog_code_id: catalog_code_id
        },
        function (resource) {
          // Check the message
          // If empty
          if (!!resource.content  && !!resource.content.message) {
            // Show the message in alert Confirmation
            // if the user choose ok then continue to import file
            // if the user choose cancel redirect to catalog list
            swal(
              {
                title: 'Import alert!',
                text: resource.content.message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1ab394',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true
              },
              function (is_confirm) {
                if (is_confirm) {
                  importingItems(item, images, $scope.importEffectiveDate, catalog_code_id);
                } else {
                  $state.go('main.user.items.manageCatalog', {'status': 'pending'});
                }
              }
            );
          }
          else{
            importingItems(item, images, $scope.importEffectiveDate, catalog_code_id);
          }
        },
        function (error) {
          //failed to load
          toastr.error('Failed to check the import file');
        }
      );
    }
  }

  function initialize(){
    if(!!$state.params.importID && !!$state.params.catalogGroupID &&
      !!$state.params.supplierID && !!$state.params.catalogCodeID){
      $scope.errorImportID = $state.params.importID;
      selectCatalogGroup($state.params.catalogGroupID);
      singleSupplier.get(
        {
          id:$state.params.supplierID
        },
        function(resource){
          if(!!resource && !!resource.content && !!resource.content.data){
            selectSupplier(resource.content.data);
          }
          else{
            toastr.error('Invalid supplier');
            window.history.back();
          }
        },
        function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
      catalogCode.get(
        {
          id: $state.params.catalogCodeID
        },
        function(resource){
          if(!!resource && !!resource.content && !!resource.content.data){
            onSelectedCatalogCode(resource.content.data);
          }
          else{
            toastr.error('Invalid catalog code');
            window.history.back();
          }
        },
        function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
    }
    else{
      $scope.errorImportID = undefined;
      $scope.selectedCatalogGroup = undefined;
      $scope.selectedSupplier = undefined;
      $scope.selectedCatalogCode = undefined;
    }
  }

  initialize();
}

importItemsCtrl.$inject = [
  '$scope', 'toastr', 'pathConstants', '$http', '$filter', '$q', 'changeStatusCatalog', 'BUs', 'searchSupplier',
  '$state', 'Approvals', 'checkImport', 'itemDetailsListing', 'getCatalogCodeListing', '$timeout', 'searchModule',
  'globalFunc', 'singleSupplier', 'catalogCode'
];

angular.module('metabuyer')
  .controller('importItemsCtrl', importItemsCtrl);
