'use strict';
/**
 * @name approvalFlowDetailController
 * @desc Controller for Approval Flow Detail
 */

function approvalFlowDetailController (
  $scope, approvalFlowDetail, globalFunc, $stateParams, $filter, approvalTemplateHistory, approvalTemplateInfo, $state,
  toastr, HighlightId
) {

  $scope.approvalTemplate = approvalFlowDetail;
  $scope.timezoneDiff = globalFunc.getTimezoneDiff();
  $scope.limit = 10;
  $scope.meta = [];
  $scope.page = 0;
  $scope.costCenterListing = null;
  $scope.uiCostCenter = {};
  $scope.uiCostCenter.list = null;
  $scope.expenseTypeListing = null;
  $scope.uiExpense = {};
  $scope.uiExpense.list = null;
  $scope.uiTemplate = {};
  $scope.uiTemplate.list = null;
  $scope.uiStatus = {};
  $scope.uiStatus.list = null;
  $scope.availableApprovals = null;
  $scope.templateListing = null;
  $scope.templateStatus = [
    {
      code: 0,
      descr: 'Active'
    },
    {
      code: 1,
      descr: 'Inactive'
    }
  ];
  $scope.modulesWithCompanyCode = ['pr', 'budget'];

  $scope.back = back;
  $scope.getPosition = getPosition;
  $scope.checkStep = checkStep;
  $scope.returnDate = returnDate;
  $scope.exportCSV = exportCSV;
  $scope.checkStepPosition = checkStepPosition;
  $scope.printApprovalFlow = printApprovalFlow;
  $scope.filterAvailableApprovals = filterAvailableApprovals;
  $scope.navigateTemplate = navigateTemplate;
  $scope.clearSearch = clearSearch;
  $scope.getTemplateHistory = getTemplateHistory;
  $scope.getStatus = getStatus;

  function back() {
    if (!!$scope.approvalTemplate && !!$scope.approvalTemplate.id) {
      HighlightId.setId($scope.approvalTemplate.id);
    }
    window.history.back();
  }

  function checkStep(step) {
    var userFound = false;
    _.forEach(step.step_positions, function (stepPosition) {
      userFound = false;
      if (stepPosition.position.position_type !== 'ad-hoc') {
        _.forEach(stepPosition.position_assignments, function (assignment) {
          userFound = !!assignment.active_users.length > 0 ? true : userFound;
        });
      } else {
        userFound = true;
      }
      if (!userFound)
        return false;
    });

    return userFound;
  }

  function checkStepPosition(stepPosition) {
    var userFound = false;
    if (stepPosition.position.position_type === 'ad-hoc') {
      return true;
    }
    _.forEach(stepPosition.position_assignments, function (assignment) {
      userFound = !!assignment.active_users.length > 0 ? true : userFound;
    });

    return userFound;
  }

  function getPosition(assignments) {
    var userAssignment = [];

    _.forEach(assignments, function (assignment) {

      if (!assignment.active_users.length) {
        userAssignment.push(false);
      } else {
        userAssignment.push(true);
      }
    });

    if (userAssignment.indexOf(true) === -1) {
      return 'There are no users assigned to the position';
    }
  }

  function prepareDetailData() {
    $scope.approvalTemplate.created_at = globalFunc.convertToLocalDate($scope.approvalTemplate.created_at);
    $scope.approvalTemplate.updated_at = globalFunc.convertToLocalDate($scope.approvalTemplate.updated_at);
    $scope.approvalTemplate.effective_date = (!!$scope.approvalTemplate.effective_date) ?
      globalFunc.convertToLocalDate($scope.approvalTemplate.effective_date) : '-';
  }

  function returnDate(date) {
    return globalFunc.convertToLocalDate(date);
  }

  function processData() {
    $scope.meta['currentPageNumber'] = $scope.approvalHistoryData['meta']['current'];
    $scope.meta['totalPageNumber'] = $scope.approvalHistoryData['meta']['pages'];
    $scope.meta['totalRec'] = $scope.approvalHistoryData['meta']['count'];
    $scope.meta['startRecNo'] = $scope.limit * $scope.page + 1;
    $scope.meta['endRecNo'] = $scope.meta['totalRec'] - ((1 + $scope.meta['currentPageNumber']) * $scope.limit) >= 0 ?
      (1 + $scope.meta['currentPageNumber']) * $scope.limit :
      (($scope.meta['currentPageNumber']) * $scope.limit) + $scope.meta['totalRec'] % $scope.limit;
  }

  function constructHistoryLogExport(data) {
    var returnData = '';

    if (data.event === 'created') {
      return returnData;
    }

    returnData += !!data.new_values.module ? 'module : ' + data.new_values.module + '\r\n' : '';
    returnData += !!data.new_values.company ? 'company : ' + data.new_values.company + '\r\n' : '';
    returnData += !!data.new_values.title ? 'title : ' + data.new_values.title + '\r\n' : '';
    returnData += !!data.new_values.code ? 'code : ' + data.new_values.code + '\r\n' : '';
    returnData += !!data.new_values.status ? 'status : ' + data.new_values.status ? 'Active' : 'Inactive' + '\r\n' : '';
    returnData += !!data.new_values.effective_date ? 'effective date : ' + data.new_values.effective_date + '\r\n' : '';
    returnData += !!data.new_values.cost_center ? 'cost center  : ' + data.new_values.cost_center + '\r\n' : '';
    returnData += !!data.new_values.expense_type ? 'expense type : ' + data.new_values.expense_type + '\r\n' : '';

    _.forEach(data.new_values.steps, function (stepValue) {
      returnData += 'step : ' + stepValue.seq;
      if (stepValue.changes === 'removed' || stepValue.changes === 'added') {
        returnData += ' ** ' + stepValue.changes + ' **';
      } else if (stepValue.changes === '') {
        returnData += ' ** no changes **';
      }
      returnData += '\r\n';
      returnData += !!stepValue.min_approver ? 'min approver : ' + stepValue.min_approver + '\r\n' : '';

      _.forEach(stepValue.positions, function (positionValue) {
        if (!!positionValue.changes && positionValue.changes !== '') {
          returnData += '   position : ' + positionValue.position_code;

          if (!!positionValue.changes && (positionValue.changes === 'removed' || positionValue.changes === 'added')) {
            returnData += '   **' + positionValue.changes + '**';
          }
          returnData += '\r\n';

          _.forEach(positionValue.rules, function (ruleValue, ruleKey) {
            if (!!ruleValue.value) {
              returnData += '      rule ' + (ruleKey + 1) + ' : ' + ruleValue.name + ruleValue.operator +
                ruleValue.value + '\r\n';
            }
          });
        }
      });
    });

    return returnData;
  }

  function processHistoryLogExport(data) {
    _.forEach(data, function (item, itemKey) {
      if (!!item.new_values) {
        data[itemKey].exportChanges = constructHistoryLogExport(item);
      }
    });
  }

  function searchAvailableTemplates() {

    var params = {
      limit: 0,
      page: 0,
      'module': $scope.approvalTemplate.module,
      'code': $scope.approvalTemplate.code
    }
    if ($scope.modulesWithCompanyCode.indexOf($scope.approvalTemplate.module.toLowerCase()) > -1) {
      params.company = $scope.approvalTemplate.organization.company.code;
    }

    approvalTemplateInfo.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.availableApprovals = prepareAvailableTemplates(resource.content.data);
          $scope.templateListing = $scope.availableApprovals;
        }
      },
      function () {
        toastr.error('Failed to load available templates.');
      }
    )
  }

  function getTemplateHistory(page) {
    $scope.page = page;
    approvalTemplateHistory.get(
      {
        'sort[1][key]': 'created_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'auditable_id',
        'filter_groups[0][filters][0][value]': $stateParams.id,
        'filter_groups[0][filters][0][operator]': 'eq',
        'filter_groups[1][filters][0][key]': 'auditable_type',
        'filter_groups[1][filters][0][value]': 'Api\\Templates\\Models\\Template',
        'filter_groups[1][filters][0][operator]': 'eq',
        limit: 10,
        page: page
      },
      function (resource) {
        if (!!resource.content) {
          $scope.approvalHistoryData = resource.content.data;
          $scope.approvalHistoryData['meta'] = resource.content.meta.cursor;
          prepareDetailData();
          processData();
        }
      },
      function () {
        toastr.error('Failed to retrieve history logs.');
      }
    )
  }

  /**
   * Replace status number to be text
   *
   * @param status
   * @returns {*}
   */
  function getStatus(status) {
    if (!!status) {
      status = status.replace('0', 'Active');
      status = status.replace('1', 'Inactive');
    }

    return status;
  }

  function prepareAvailableTemplates(data){
    var finalData = [];
    _.forEach(data, function (item) {

      if (!!item.organization.cost_center && item.organization.cost_center === 'all') {
        item.organization.cost_center = {'code':'all', 'descr':'All'};
      }

      finalData.push({
        'template_id': item.id,
        'title': item.title,
        'cost_center': !!item.organization.cost_center && !!item.organization.cost_center.code ?
          item.organization.cost_center.descr + ' (' + item.organization.cost_center.code + ')' : '',
        'expense_type': !!item.organization.expense_type && !!item.organization.expense_type.code ?
          item.organization.expense_type.descr + ' (' + item.organization.expense_type.code + ')' : '',
        'status': item.status
      });
    });

    $scope.costCenterListing = _.chain(finalData).pluck('cost_center').unique().value();
    $scope.expenseTypeListing = _.chain(finalData).pluck('expense_type').unique().value();

    return finalData;
  }

  function exportCSV() {
    processHistoryLogExport($scope.approvalHistoryData);

    $scope.arrayData = [['Username', 'Action', 'Date', 'Changes']];
    _.forEach($scope.approvalHistoryData, function (data) {
      $scope.arrayData.push([
        (!!data.user && !!data.user.display_name ? data.user.display_name : ''),
        data.event,
        $filter('date')(returnDate(data.created_at), 'dd MMM yyyy h:mm a'),
        data.exportChanges
      ])
    });
    $scope.fileName = globalFunc.getCsvFilenameWithDate('Approval Template - ' + $scope.approvalTemplate.code);
    $scope.triggerHistoryExport = true;
  }

  function printApprovalFlow() {
    window.print();
  }

  function filterAvailableApprovals() {
    if (!_.isEmpty($scope.availableApprovals)) {
      $scope.templateListing = _.filter($scope.availableApprovals, function (item) {
        return (!!$scope.uiExpense.list && item.expense_type ? item.expense_type === $scope.uiExpense.list : true) &&
          (!!$scope.uiCostCenter.list && item.cost_center ? item.cost_center === $scope.uiCostCenter.list : true) &&
          (!!$scope.uiStatus.list ? item.status === $scope.uiStatus.list.code : true);
      });
    }

    $scope.uiTemplate.list = null;
  }

  function navigateTemplate() {
    if (!!$scope.uiTemplate.list && !!$scope.uiTemplate.list.template_id) {
      $state.go('main.user.approvalFlow.detail', {
        id: $scope.uiTemplate.list.template_id
      });
    }
    else {
      toastr.error('Please select a template to continue.');
    }
  }

  function clearSearch() {
    $scope.uiExpense.list = null;
    $scope.uiCostCenter.list = null;
    $scope.uiStatus.list = null;
    filterAvailableApprovals();
  }

  /**
   * To remove duplicated users in the position assignment
   */
  function removeDuplicatedUser() {
    _.forEach($scope.approvalTemplate.steps, function (step) {
      _.forEach(step.step_positions, function (stepPosition) {
        var tempUsers = [];
        _.forEach(stepPosition.position_assignments, function (assignment) {
          var newActiveUsers = [];
          _.forEach(assignment.active_users, function (user) {
            if (!!user && !!user.assignee_id) {
              if (tempUsers.indexOf(user.assignee_id) === -1) {
                newActiveUsers.push(user);
                tempUsers.push(user.assignee_id);
              }
            }
          })
          assignment.active_users = newActiveUsers;
        })
      })
    })
  }

  function initialize() {
    getTemplateHistory(0);
    if (!!$scope.approvalTemplate && $scope.approvalTemplate.module)
    {
      searchAvailableTemplates();
      removeDuplicatedUser();
    }
  }

  initialize();
}

approvalFlowDetailController.$inject = [
  '$scope', 'approvalFlowDetail', 'globalFunc', '$stateParams', '$filter', 'approvalTemplateHistory',
  'approvalTemplateInfo', '$state', 'toastr', 'HighlightId'
];

angular
  .module('metabuyer')
  .controller('approvalFlowDetailController', approvalFlowDetailController);
