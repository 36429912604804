'use strict';

function purchaseRequisitionsHeaderCtrl(
  $scope, prFunction, purchaseRequisitionsServices, globalFunc, $rootScope, $uibModal, $state, HighlightId
) {

  $scope.back           = back;
  $scope.updatePRTitle  = updatePRTitle;
  $scope.PRData         = PRData;
  $scope.starringPR     = starringPR;
  $scope.checkStarred   = checkStarred;
  $scope.openRevision   = openRevision;

  $scope.originalPRTitle = angular.copy($scope.PR.title);

  $scope.templateUrl = $rootScope.isMobileMode ?
    'components/purchaseRequisitionComponents/prHeader/purchaseRequisitionHeaderTemplate-mobile.html':
    'components/purchaseRequisitionComponents/prHeader/purchaseRequisitionHeaderTemplate.html';

  function back() {
    if ($scope.shoppingModeStatus){
      if(!!PRData().original_pr){
        window.history.back();
      }
      else {
        $state.go('main.user.purchaseRequisitions.details', {id: PRData()._id, PR: {data: PRData()}});
      }
    }
    else {
      var prStatus = PRData().status;
      if(PRData().status === 'on_hold' || PRData().status === 'edit'){
        prStatus = 'pending';
      }
      $state.go('main.user.purchaseRequisitions.manage', {
        status: prStatus,
        tab: prStatus === 'pending' ? 'waiting' : null,
        extraFilterKey: prStatus === 'pending' ? 'criteria[waiting_on][]' : null,
        extraFilterValue: prStatus === 'pending' ? $rootScope.currentUser._id : null,
        cursor: null
      });
      if(!!PRData().original_pr){
        HighlightId.setId(PRData().original_pr._id);
      }
      else{
        window.history.back();
        HighlightId.setId($scope.PR._id);
      }
    }
  }

  // function backToPRDetails() {
  //   console.log($scope.PRData._id);
  //   $state.go('main.user.purchaseRequisitions.details', {id: $scope.PRData._id});
  // }

  function starringPR(pr, isStarred) {
    prFunction.requisitionStarring(pr, !isStarred).$promise
      .then(function () {
        if (isStarred)
          pr.starred_users = _.pull(pr.starred_users, $rootScope.currentUser._id);
        else {
          pr.starred_users = [];
          pr.starred_users.push($rootScope.currentUser._id);
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function checkStarred(pr) {
    if(!!pr.starred_users && pr.starred_users.length > 0){
      return (pr.starred_users.indexOf($rootScope.currentUser._id) > -1);
    }
  }

  function updatePRTitle(prTitle) {
    if (!!prTitle) {
      purchaseRequisitionsServices.updateTitle(
        {
          id: $scope.PR._id
        },{
          title : prTitle
        },function(){
          $scope.PR.title = prTitle;
          $scope.originalPRTitle = angular.copy($scope.PR.title);
        },function (error){
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      // If input field is empty, fill empty input by previous data
      $scope.PR.title = angular.copy($scope.originalPRTitle);
    }
  }

  function PRData(){
    return prFunction.getPRData();
  }

  /**
   * Open PR comparison with the old version in modal
   * @param currentPR
   * @param previousId
   * @param version (version number)
   */
  function openRevision(currentPR, previousId, version) {
    $uibModal.open({
      templateUrl: 'components/purchaseRequisitionComponents/revision/revision.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'prVersionCtrl',
      size: 'lg',
      windowClass: 'revision-wrapper',
      resolve: {
        currentPR: function(){
          return currentPR;
        },
        previousVersion: function($q, purchaseRequisitionsServices){
          var deferred = $q.defer();
          purchaseRequisitionsServices.getSinglePR(
            {
              id: previousId
            },
            function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data)
                deferred.resolve(resource.content.data);
              else
                deferred.resolve({});
            },
            function (error) {
              deferred.resolve({});
              globalFunc.objectErrorMessage(error);
            }
          );

          return deferred.promise;
        },
        versionNumber: function () {
          return version;
        }
      }
    });
  }

  /**
   * need to reformat the revision data
   * because the data from backend is in object and hard to read by ng-repeat
   */
  function preparePRRevisions() {
    $scope.PRData().revisions = globalFunc.reformatFromObjectToArray($scope.PRData().revisions);
  }

  function initialize() {
    preparePRRevisions();
  }

  initialize();

}

purchaseRequisitionsHeaderCtrl.$inject = [
  '$scope', 'prFunction', 'purchaseRequisitionsServices', 'globalFunc', '$rootScope', '$uibModal', '$state', 'HighlightId'
];

angular
  .module('metabuyer')
  .directive('purchaseRequisitionHeader',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        PR: '=pr',
        costCenters: '=',
        shoppingModeStatus: '='
      },
      template: '<div ng-include="templateUrl"></div>',
      controller: 'purchaseRequisitionsHeaderCtrl'
    }
  })
  .controller('purchaseRequisitionsHeaderCtrl',  purchaseRequisitionsHeaderCtrl);
