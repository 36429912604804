'use strict';

angular.module('metabuyer.services.suppliersManagement', ['ngResource'])
  .factory('suppliersList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.list,
        {},
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('suppliersActiveList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.listActive,
        {},
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('singleSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.single,
        {
          id: '@supplier_id'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('searchSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.search,
        {
          status: '@status'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('newSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.new,
        {},
        {
          'post': {method: 'POST'},
          'getContactPersons': {
            method: 'GET',
            params: {},
            url: pathConstants.apiUrls.contactPersons.contacts
          },
          'addContactPersons': {
            method: 'POST',
            params: {},
            url: pathConstants.apiUrls.contactPersons.contacts
          }
        }
      );
    })
  .factory('supplierTagViaCompany',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.tagViaCompany,
        {},
        {
          'post': {method: 'POST'}
        }
      );
    })
  .factory('getTagsBySupplierCode',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.tagBySupplierCode,
        {
          supplierCode: '@supplierCode'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('getTagsByCompanyCode',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.tagByCompanyCode,
        {
          companyCode: '@companyCode'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('changeStatus',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.status,
        {
          id: '@id',
          status: '@status'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('approvalSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvals.status,
        {
          approval_id: '@approval_id',
          status: '@status'
        },
        {
          'post': {method: 'POST'}
        }
      );
    })
  .factory('blackListSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.blackList,
        {
          supplier_id: '@supplier_id',
          comment: '@comment'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('unBlackListSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.unBlackList,
        {
          supplier_id: '@supplier_id',
          comment: '@comment'
        },
        {
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('draftSupplier',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.draft,
        {
          id: '@id',
          action: '@action'
        },
        {
          'post': {method: 'POST'},
          'put': {method: 'PUT'}
        }
      );
    })
  .factory('supplierArchives',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.archives,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('validateSupplierBranchDeletion',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.suppliers.validateBranchDeletion,
        {
          id:'@id',
          branches: '@branchIDs',
          supplierID: '@supplierId'
        },
        {
          'get': { method: 'GET' }
        }
      )
    })
.factory('supplierBranches',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.suppliers.branches,
      {
        id:'@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
