/**
 * @name prWarningBlock
 *
 * @requires $scope
 * @requires itemDetailsInformation
 *
 * @description Directive for displaying a warning block on PR if conditions are met
 *
 * @isolatedScope data
 * Two way binded scope for pr items
 *
 * @isolatedScope pr
 * Two way binded scope for pr
 *
 * @isolatedScope unavailableItems
 * Two way binded scope for unavailable items
 *
 * @function getNumberOfIncompleteItem
 * Function to get the number of incomplete items (currently unused 1/12/16)
 *
 * @function getNumberOfNonCatalogItem
 * Function to get the number of non catalog items in the PR
 *
 * @function getNumberOfMissingData
 * Function to get number of missing data from incomplete items in more detail (currently unused 1/12/16)
 *
 * @function getNumberOfItemsChanged
 * Function to get number of items with changed data
 *
 * @function getNumberOfZeroPriceItems
 * Function to get number of items with zero price
 *
 * @author Justin Cheong Tian Yee<justin.cty90@gmail.com>
 *         Udhayan Nair
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('prWarningBlock', function ($rootScope) {
    return {
      restrict: 'E',
      templateUrl: function(){
        return $rootScope.isMobileMode ? 'components/prWarningBlock/prWarningBlockTemplate-mobile.html' :
          'components/prWarningBlock/prWarningBlockTemplate.html';
      },
      scope: {
        data: '=',
        pr: '=',
        unavailableItems: '='
      },
      controller: function ($scope, itemDetailsInformation, prFunction) {

        //TODO: Have a function for when incomplete item is implemented (Justin 30/11/16)

        function getNumberOfZeroPriceItems() {
          var zeroPrice = _.filter($scope.data, function (item) {
            return item.unit_price === 0;
          });

          $scope.numberOfZeroPriceItem = zeroPrice.length;
        }

        /**
         * Function to get pr_related_data_changes
         * 1 for billing address
         * 2 for delivery address
         * 3 for creator deletion
         * 4 for creator deactivation
         * 5 for requestor deletion
         * 6 for requestor deactivation
         * 7 for expense type deactivated
         * 8 for expense type deleted
         */
        function getPRDataChanged(){
          if(!!$scope.pr.pr_related_data_changes){
            _.forEach($scope.pr.pr_related_data_changes, function(prChanges){
              switch (prChanges) {
                case 1:
                  $scope.prDeliveryAddressChanged = true;
                  break;
                case 2:
                  $scope.prBillingAddressChanged = true;
                  break;
                case 3:
                  $scope.prCreatorDeleted = true;
                  break;
                case 4:
                  $scope.prCreatorDeactivated = true;
                  break;
                case 5:
                  $scope.prRequestorDeleted = true;
                  break;
                case 6:
                  $scope.prRequestorDeactivated = true;
                  break;
                case 7 :
                  $scope.prExpenseTypeDeleted = true;
                  break;
                case 8 :
                  $scope.prExpenseTypeDeactivated = true;
                  break;
                case 13 :
                  $scope.prCurrencyDeactivated = true;
                  break;
                case 14 :
                  $scope.prCurrencyDeleted = true;
                  break;
                case 15 :
                  $scope.prCurrencyUnassignedFromCompany = true;
                  break;
                case 18 :
                  $scope.prCostCenterDeleted = true;
                  break;
                case 19 :
                  $scope.prBillAddressUnassigned = true;
                  break;
                case 20 :
                  $scope.prDeliveryAddressUnassigned = true;
                  break;
                case 21 :
                  $scope.prExpenseTypesUnassigned = true;
                  break;
                }
            });
          }
        }

        function getNumberOfNonCatalogItem() {
          var nonCatalog = _.filter($scope.data, function (item) {
            return item.item_details.is_stock === 0;
          });

          $scope.numberOfNonCatalogItem = nonCatalog.length;
        }

        function getNumberOfItemsChanged() {
          $scope.numberOfCatalogItemChanged = 0;
          $scope.arrayOfDataChangedItems = [];

          _.forEach($scope.data, function (item) {

            /**
             * Check if item_validity_status is null, break out of loop
             */
            if(item.item_validity_status === null){
              return false;
            }

            if (item.item_validity_status !== 0) {
              $scope.arrayOfDataChangedItems.push(item);
              $scope.numberOfCatalogItemChanged += 1;
              //TODO: change messages to be more specific (Ahmed Saleh, 16/1/2017)
              if (item.item_validity_status === 1 || item.item_validity_status === 2 ||
                  item.item_validity_status === 4 || item.item_validity_status === 9 || item.item_validity_status === 10 ||
                  item.item_validity_status === 11 || item.item_validity_status === 12 ||
                  item.item_validity_status === 13 || item.item_validity_status === 14 ) {
                item.catalogRemovedItems = true;
              }
              if (!!item.data_change) {
                _.forEach(item.data_change, function (dataChanged) {
                  switch(dataChanged) {
                    case 'TaxRateModified':
                      item.taxRateChanged = true;
                      break;
                    case 'PriceChanged':
                      item.catalogPriceChanged = true;
                      break;
                    case 'DescriptionChanged':
                      item.catalogDescriptionChanged = true;
                      break;
                    case 'ItemTypeChanged':
                      item.itemTypeChanged = true;
                      break;
                    case 'CurrencyChanged':
                      item.catalogCurrencyChanged = true;
                      break;
                    case 'TaxDeactivated': // Fall through
                    case 'TaxDeleted':
                      item.taxUnavailable = true;
                      break;
                    case 'PaymentTermDeactivated': // Fall through
                    case 'PaymentTermDeleted':
                      item.paymentTermUnavailable = true;
                      break;
                    case 'UomDeactivated': // Fall through
                    case 'UomDeleted':
                      item.uomUnavailable = true;
                      break;
                    case 'CurrencyDeactivated': // Fall through
                    case 'CurrencyDeleted':
                    case 'CurrencyUnassignedFromCompany':
                      item.currencyUnavailable = true;
                      break;
                    case 'ExchangeRateDeactivated': // Fall through
                    case 'ExchangeRateDeleted':
                      item.exchangeRateUnavailable = true;
                      break;
                    case 'ItemExpired':
                      item.itemExpired = true;
                      break;
                    case 'SupplierBranchDeactivated':
                      item.supplierBranchDeactivated = true;
                      break;
                    case 'SupplierBranchUpdated':
                      item.supplierBranchUpdated = true;
                      break;
                    case 'SupplierBlackListed':
                      item.supplierBlackListed = true;
                      break;
                    case 'SupplierInactive':
                      item.supplierInactive = true;
                      break;
                  }
                });
              }
            }
          });
        }

        function invalidItemReason(item) {
          var response = '';
          switch(item.item_validity_status[item.item_validity_status.length -1]) {
            case 1:
              response = 'item was deleted.';
              break;
            case 2:
              response = 'item has expired.';
              break;
            case 3:
              response = 'item was updated.';
              break;
            case 4:
              response = 'supplier of the item was blacklisted.';
              break;
            case 5:
              response = 'currency of the item was updated.';
              break;
            case 6:
              response = 'price of the item was updated.';
              break;
            case 7:
              response = 'item type was updated.';
              break;
            case 8:
              response = 'tax rate of the item was updated.';
              break;
            case 81:
              response = 'tax rate of the item was deactivated.';
              break;
            case 82:
              response = 'tax rate of the item was updated.';
              break;
            case 9:
              response = 'supplier of the item was deactivated.';
              break;
            case 10:
              response = 'supplier of the item is not found.';
              break;
            case 11:
              response = 'payment term was deactivated.';
              break;
            case 12:
              response = 'payment term was deleted.';
              break;
            case 13:
              response = 'UOM was deactivated.';
              break;
            case 14:
              response = 'UOM was deleted.';
              break;
            case 15:
              response = 'currency was deactivated.';
              break;
            case 16:
              response = 'currency was deleted.';
              break;
            case 17:
              response = 'UOM was unassigned from company.';
              break;
            case 18:
              response = 'exchange rate was deleted.';
              break;
            case 19:
              response = 'exchange rate was deactivated.';
              break;
            case 20:
              response = 'supplier branch ('+ item.branch.descr +') was deactivated.';
              break;
            case 21:
              response = 'supplier branch ('+ item.branch.descr +') was deleted.';
              break;
            case 22:
              response = 'supplier branch ('+ item.branch.descr +') details was updated.';
              break;
            case 23:
              response = 'item was deactivated.';
              break;
            case 24:
              response = 'item description was updated.';
              break;
            default:
              response = 'changes in the item.';
          }

          return response;
        }

        function getNumberOfUnavailableItems(unavailable_items) {
          $scope.numberOfUnavailableItems = unavailable_items.length;
        }

        function checkForErrors() {
          var errorsFound = false;

          if($scope.pr.status === 'draft' || $scope.pr.status === 'pending' || $scope.pr.status === 'on_hold') {
            if ($scope.numberOfCatalogItemChanged > 0)
              errorsFound = true;
            if ($scope.numberOfUnavailableItems > 0)
              errorsFound = true;
            if ($scope.numberOfZeroPriceItem > 0)
              errorsFound = true;
            if ($scope.numberOfIncompleteItem > 0)
              errorsFound = true;
            if (!!$scope.prDeliveryAddressChanged)
              errorsFound = true;
            if (!!$scope.prBillingAddressChanged)
              errorsFound = true;
            if (!!$scope.prCreatorDeleted)
              errorsFound = true;
            if (!!$scope.prCreatorDeactivated)
              errorsFound = true;
            if (!!$scope.prRequestorDeleted)
              errorsFound = true;
            if (!!$scope.prRequestorDeactivated)
              errorsFound = true;
            if (!!$scope.prExpenseTypeDeactivated)
              errorsFound = true;
            if (!!$scope.prExpenseTypeDeleted)
              errorsFound = true;
            if (!!$scope.prCurrencyDeactivated)
              errorsFound = true;
            if (!!$scope.prCurrencyDeleted)
              errorsFound = true;
            if (!!$scope.prCurrencyUnassignedFromCompany)
              errorsFound = true;
            if (!!$scope.prCostCenterDeleted)
              errorsFound = true;
            if (!!$scope.prBillAddressUnassigned)
              errorsFound = true;
            if (!!$scope.prDeliveryAddressUnassigned)
              errorsFound = true;
            if (!!$scope.prExpenseTypesUnassigned)
              errorsFound = true;
          }

          prFunction.setDisplayWarningBlock(errorsFound);
          return errorsFound;
        }

        function initialize(){

          $scope.numberOfNonCatalogItem = 0;
          $scope.numberOfIncompleteItem = 0;
          $scope.checkForErrors = checkForErrors;
          $scope.invalidItemReason = invalidItemReason;

          /**
           * Check for unavailable item lines if pr is in draft
           */
          if(angular.isDefined($scope.unavailableItems) && $scope.pr.status.toLowerCase() === 'draft'){
            getNumberOfUnavailableItems($scope.unavailableItems);
          }

          getNumberOfNonCatalogItem();
          getNumberOfItemsChanged();
          getNumberOfZeroPriceItems();
          getPRDataChanged();
        }
        initialize();

        /**
         * Watcher for pr items when item is added/removed
         */
        $scope.$watch('data', function (newData, oldData) {
          if (!!newData && !!oldData) {
              $scope.data = newData;

              /**
               * Check for unavailable item lines if pr is in draft
               */
              if(angular.isDefined($scope.unavailableItems) && $scope.pr.status.toLowerCase() === 'draft'){
                getNumberOfUnavailableItems($scope.unavailableItems);
              }

              getNumberOfNonCatalogItem();
              getNumberOfItemsChanged();
              getNumberOfZeroPriceItems();
              getPRDataChanged();
            }
        }, true);

      }
    }
  });
