'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.positionManagement', {
        url: '/position-management',
        controller: 'positionManagementMainCtrl',
        templateUrl: 'app/positionManagement/positionManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkApprovalTemplateAccess(profile, 'C') ||
              !UserPermissions.checkApprovalTemplateAccess(profile, 'L') ||
              !UserPermissions.checkApprovalTemplateAccess(profile, 'O') ||
              !UserPermissions.checkApprovalTemplateAccess(profile, 'U') ||
              !UserPermissions.checkApprovalTemplateAccess(profile, 'D')
            ) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.positionManagement.manage', {
        url: '/manage',
        controller: 'positionManagementCtrl',
        templateUrl: 'app/positionManagement/manage/positionManage.html',
        resolve: {
          positionManagementListing: function ($q, positionManagement) {
            var deferred = $q.defer();
            positionManagement.get(
              {
                limit: 30,
                page: 0,
                'sort[0][key]': 'updated_at',
                'sort[0][direction]': 'DESC'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Position Management - Manage';
          }
        }
      })
      .state('main.user.positionManagement.assign', {
        url: '/assign',
        controller: 'positionAssignCtrl',
        templateUrl: 'app/positionManagement/assign/positionAssign.html',
        resolve: {
          companies: function ($q, $stateParams, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order_by: 'descr',
                order: 1
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          positions: function ($q, positionManagement) {
            var deferred = $q.defer();
            positionManagement.get(
              {
                'sort[0][key]': 'name',
                'sort[0][direction]': 'ASC'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          positionAssignmentListing: function ($q, positionAssignment) {
            var deferred = $q.defer();
            positionAssignment.get(
              {
                limit: 30,
                page: 0,
                'sort[0][key]': 'status',
                'sort[0][direction]': 'ASC',
                'sort[1][key]': 'updated_at',
                'sort[1][direction]': 'DESC'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Position Management - Assign';
          }
        }
      })
  }]);

