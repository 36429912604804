'use strict';

function purchaseRequisitionsDetailsHeaderCtrl(
  $scope, metabuyerCache, subscriptionCheck, singleAddress, $q, companyExpenseTypes,
  purchaseRequisitionsServices, globalFunc, prFunction, PRAccountCodeList, $rootScope, budgetList
) {

  function copyTheDataToTemporaryModes() {
    $scope.editableData = angular.copy(prFunction.getPRData());
    if (!!$scope.editableData.budget && !$scope.editableData.budget._id) {
      $scope.editableData.budget = $scope.defaultBudgetOption
    }
  }

  /**
   * checking the Subscription of the PR
   * @param requisition
   */
  function checkSubscription(requisition) {
    subscriptionCheck.get(
      {
        object_id: requisition._id,
        class: 'Metabuyer\\PR\\PR'
      },
      function (resource) {
        $scope.PR.notify = resource.subscribed;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }


  /**
   * Load the Addresses and Caching them
   * @param addresses
   * @param type
   */
  function loadAddressesData (addresses, type) {
    var promises = [];
    var cleanedAddress = [];
    angular.forEach(addresses, function (id) {
      if (!!metabuyerCache.get(id.$id)) {
        cleanedAddress.push(metabuyerCache.get(id.$id));
      } else {
        var promise = singleAddress.get({
            id: id.$id
          }
        );
        promises.push(promise.$promise);
      }
    });
    $q.all(promises).then(function (resource) {
      angular.forEach(resource, function (address) {
        if (!!address && !!address.content && !!address.content.data) {
          cleanedAddress.push(address.content.data);
          metabuyerCache.put(address.content.data._id, address.content.data);
        }
      });
    }).catch().finally(function () {
      if (type === 'billing') {
        $scope.costCenterBillingAddresses = angular.copy(cleanedAddress);
      }
    });
  }

  /**
   * store the available addresses for this PR in variables
   * Also, enables the selection of multiple addresses or not
   * adding the billing address from the Cost Center
   */
  function loadAvailableAddresses(CC) {
    $scope.enableSelectingBillingAddress = false;
    $scope.enableSelectingDelieveryAddress = false;

    if(!CC)
      return;

    var costCenterBillingAddressesIds = globalFunc.removeDuplicateObjects(CC.billing_addresses_ids, '$id');


    loadAddressesData(costCenterBillingAddressesIds, 'billing');

    purchaseRequisitionsServices.getAvailableDeliveryAddresses(
      {
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.costCenterDeliveryAddresses = resource.content.data;
          prFunction.setCostCenterDeliveryAddresses(resource.content.data);
        } else {
          //error
        }

      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }


  /**
   * loading the list of company expense types
   */
  function loadExpenseTypes() {
    companyExpenseTypes.get(
      {
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypes = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  /**
   * loading the List of available contact persons
   */
  function loadContactPersons() {
    $scope.contactPersonsList = [];
    purchaseRequisitionsServices.getContactPersonsList({
        id: $scope.PR.company._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.contactPersonsList = resource.content.data;
          prFunction.setContactPerson(resource.content.data);
        }
      });
  }

  /**
   * loading the List of available contact persons
   */
  function loadRequestorsList(CC) {
    $scope.requestorsList = [];
    purchaseRequisitionsServices.getAvailableRequestorsList({
        id: CC._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.requestorsList = resource.content.data;

          /**
           * remove the creator from the List and adding it to the start of the list
           */
          $scope.requestorsList = globalFunc.removeValueFromArray($scope.requestorsList, '_id', $scope.PR.creator._id);
          $scope.requestorsList.unshift($scope.PR.creator);

        }else{
          //error
        }

      },function(error){
        //error
      });
  }


  function onSelectDeliveryAddress(address) {
    $scope.editableData.delivery_address = address;
    onUpdatingHeaderInfo();
  }

  function onSelectBillingAddress(address) {
    $scope.editableData.billing_address = address;
    onUpdatingHeaderInfo();
  }

  function onSelectBillingCompany(company) {
    $scope.editableData.alternate_billing_company = !!company ? company : null;
    onUpdatingHeaderInfo();
  }

  /**
   * validation to check account code in line item
   */
  function validateAccountCode(items) {
    var accountCodeExist = [];
    if (!!items && !!items.length) {
      _.forEach(items, function (item) {
        if (!!item.account_code)
          accountCodeExist.push(item._id);
      });
    }
    return accountCodeExist;
  }

  /**
   * on updating cost center
   * needs to validate the account code in line item,
   * warn the user if line item is having account code from existing cost center
   * @param CC
   */
  function onSelectCostCenter(CC){
    var pr = prFunction.getPRData();
    if (!!pr.cost_center && !!pr.cost_center._id !== CC._id) {
      var itemIds = validateAccountCode(pr.pr_items);
      if (!!itemIds && !!itemIds.length) {
        swal({
          title: 'Account code is assigned in a line item!',
          text: 'Updating the Purchase Requisition Cost Center will result in removing the assigned account code.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Proceed',
          closeOnConfirm: true
        }, function(isConfirm){
          if (isConfirm) {
            prFunction.setLoading('lineItemTable', true);
            removeAccountCodes(itemIds, CC);
          }
          else
            $scope.editableData.cost_center = pr.cost_center;
        });
      }
      else
        updatingCostCenter(CC);
    }
    else{
      updatingCostCenter(CC);
    }
  }

  /**
   * remove account codes in all items
   * @param ids
   * @param CC
   */
  function removeAccountCodes(ids, CC) {
    purchaseRequisitionsServices.setItem({
      id: ids[0],
      action: 'account-code'
    }, {
      account_code_id : null
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prFunction.setPRData(resource.content.data);

      ids.shift();
      if (!!ids && !!ids.length)
        removeAccountCodes(ids, CC);
      else {
        updatingCostCenter(CC);
        prFunction.setLoading('lineItemTable', false);
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
      callback(false);
    });
  }

  /**
   * Updating cost center process
   * @param CC
   */
  function updatingCostCenter(CC) {
    loadRequestorsList(CC);
    loadAccountCode($scope.PR.company.code, CC._id);

    //validate billing address
    var billing = !!globalFunc.findInArray(CC.billing_addresses_ids, '$id', prFunction.getPRData().billing_to_address._id) ? null : '';
    //validate billing address
    var delivery = !!globalFunc.findInArray(CC.delivery_addresses_ids, '$id', prFunction.getPRData().delivery_to_address._id) ? null : '';

    //Remove budget selection when cost center is modified
    $scope.editableData.budget = $scope.defaultBudgetOption;
    $scope.PR.budget = $scope.defaultBudgetOption;

    if (!prFunction.getPRData().cost_center || prFunction.getPRData().cost_center._id || prFunction.getPRData().cost_center._id !== CC._id)
      loadAvailableAddresses(CC);

    onUpdatingHeaderInfo(billing, delivery);
    loadBudgets();
  }

  function onSelectExpenseType(){
    //Remove budget selection when expense type is modified
    $scope.editableData.budget = $scope.defaultBudgetOption;
    $scope.PR.budget = $scope.defaultBudgetOption;
    onUpdatingHeaderInfo();
    loadBudgets();
  }

  function onSelectCurrency(){
    onUpdatingHeaderInfo();
  }

  function onSelectBudget(budget) {
    checkPrItemsToBudget(budget);
  }

  function checkPrItemsToBudget(budget) {
    var info = {
      'budget_id': !!$scope.editableData.budget && !!$scope.editableData.budget._id ?
        $scope.editableData.budget._id : null,
      'budget_assignment': !!$scope.editableData.budget && !!$scope.editableData.budget.assignment_id ?
        $scope.editableData.budget.assignment_id : null
    };

    purchaseRequisitionsServices.checkPRCompanyInfo(
      {
        id: $scope.PR._id
      },{
        budget : info
      },function(){
        $scope.PR.budget = budget;
        onUpdatingHeaderInfo();
      },function (error){
        if (!!error && !!error.data && !!error.data.content && !!error.data.content.error) {
          if (error.data.content.error === 'InvalidPrItems') {
            userToConfirmRemovePrItems(error.data.content.message, budget);
          }
        }
      }
    );
  }

  function userToConfirmRemovePrItems(removedItems, budget) {
    swal(
      {
        title: 'Confirm select budget?',
        text: 'Selecting this budget will remove the following Items:  '.concat(removedItems),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          $scope.PR.budget = budget;
          onUpdatingHeaderInfo();
        } else {
          $scope.editableData.budget = prFunction.getPRData().budget;
        }
      }
    );
  }

  function onSelectContactPerson(person) {
    var text;
    if(typeof person === 'object'){
      text = person.display_name;
    }else{
      text = person;
    }
    purchaseRequisitionsServices.setPPContactPerson(
      {
        id: $scope.PR._id
      },{
        contact_person : text
      },function(resource){
        $scope.PR.contact_person = text;
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function onSelectPRRequestor(person) {

    purchaseRequisitionsServices.setRequestedBy(
      {
        id: $scope.PR._id
      },{
        requested_by_id : person._id
      },function(resource){
        $scope.PR.requestor = person;
      },function (error){
       // console.log(error);
      }
    );
  }

  /**
   * Action when remarks is changed
   *
   * @param {string} remarks
   */
  function onChangeRemarks(remarks) {

    purchaseRequisitionsServices.setRemarks(
      {
        id: $scope.PR._id
      },{
        remarks : remarks
      },function(){
        $scope.PR.remarks = remarks;
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function openDatePicker($event){
    $event.preventDefault();
    $event.stopPropagation();
    $scope.editableData.neededByDatePanel = true;
  }

  /**
   * this function checks the models for the Billing Address, Delivery Address, Cost Center, Expense Type and Currency, then update them as 1 payload in the backend
   * @param billingAddress
   * @param deliveryAddress
   * @param CCId
   * @param expenseType
   * @param currencyId
   * @param alternateBillingCompanyCode
   */
  function onUpdatingHeaderInfo(billingAddress, deliveryAddress, CCId, expenseType, currencyId, alternateBillingCompanyCode){
    prFunction.setLoading('updatePRField', true);
    var info = {
      'company_id': $scope.PR.company._id,
      'billing_to_address_id' : (!!billingAddress || billingAddress === '') ? billingAddress : (!!$scope.editableData.billing_address && !!$scope.editableData.billing_address._id) ?
        $scope.editableData.billing_address._id : $scope.editableData.billing_to_address._id,
      'delivery_to_address_id' : (!!deliveryAddress || deliveryAddress === '') ? deliveryAddress : (!!$scope.editableData.delivery_address && !!$scope.editableData.delivery_address._id) ?
        $scope.editableData.delivery_address._id : $scope.editableData.delivery_to_address._id,
      'cost_center_id' : (!!CCId || CCId ==='') ? CCId : (!!$scope.editableData.cost_center && !!$scope.editableData.cost_center._id) ?
        $scope.editableData.cost_center._id : null,
      'expense_type_id' : (!!expenseType || expenseType === '') ? expenseType :
        (!!$scope.editableData.expense_type && !!$scope.editableData.expense_type._id) ? $scope.editableData.expense_type._id : null,
      'currency_id' : (!!currencyId || currencyId === '') ? currencyId : (!!$scope.editableData.currency && !!$scope.editableData.currency._id) ? $scope.editableData.currency._id : null,
      'alternate_billing_company' : (!!alternateBillingCompanyCode || alternateBillingCompanyCode === '') ? alternateBillingCompanyCode :
        (!!$scope.editableData.alternate_billing_company) ? $scope.editableData.alternate_billing_company : null,
      'budget_id': !!$scope.editableData.budget && !!$scope.editableData.budget._id ?
        $scope.editableData.budget._id : null,
      'budget_assignment': !!$scope.editableData.budget && !!$scope.editableData.budget.assignment_id ?
        $scope.editableData.budget.assignment_id : null
    };

    purchaseRequisitionsServices.updatePRCompanyInfo(
      {
        id: $scope.PR._id
      },{
        company : info
      },function(resource){
        $scope.PR.billing_address = $scope.editableData.billing_address;
        $scope.PR.delivery_address = $scope.editableData.delivery_address;
        $scope.PR.cost_center = $scope.editableData.cost_center;
        $scope.PR.expense_type = $scope.editableData.expense_type;
        $scope.PR.currency = $scope.editableData.currency;
        $scope.PR.alternate_billing_company = $scope.editableData.alternate_billing_company;
        if (!!resource && !!resource.content && !!resource.content.data) {
          prFunction.setPRData(resource.content.data);
          $scope.editableData.billing_to_address = resource.content.data.billing_to_address;
          $scope.editableData.delivery_to_address = resource.content.data.delivery_to_address;
        }
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );

  }

  $scope.$watch('editableData.needed_by_date', function (newValue, oldValue) {

    if (!newValue || (newValue === oldValue)) {
        // nothing is needed
    }
    else {
      var milliseconds = globalFunc.convertDateToTimestamp(newValue);

      purchaseRequisitionsServices.setNeededByDate(
        {
          id: $scope.PR._id
        },{
          needed_by_date : milliseconds
        },function(resource){

        },function (error){
          globalFunc.objectErrorMessage(error);
        }
      );
    }

  });

  function loadAccountCode(companyCode, ccId) {
    PRAccountCodeList.get({
      company_code: companyCode,
      cost_center_id: ccId
    }, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prFunction.setAccountCodes(resource.content.data)
    })
  }

  // refresh budget drop-down listing
  function loadBudgets() {
    if (!$scope.editableData.cost_center || !$scope.editableData.cost_center.code ||
      !$scope.editableData.expense_type || !$scope.editableData.expense_type.code) {
      return;
    }

    budgetList.get({
        type: 'budget_listing_for_pr_draft',
        company_code: $scope.PR.company.code,
        cost_center: $scope.editableData.cost_center.code,
        expense_type: $scope.editableData.expense_type.code
      }, function (resource) {
        if (!!resource.content && !!resource.content.data && !!resource.content.setting) {
          $scope.budgetListing = resource.content.data;
          $scope.budgetListing.unshift(
            $scope.defaultBudgetOption
          );
          $scope.budgetSetting = resource.content.setting;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function getCurrencyList() {
    return prFunction.getCurrenciesData();
  }

  function clearNeededByDate($event){
    $event.preventDefault();
    $event.stopPropagation();

    purchaseRequisitionsServices.setNeededByDate(
      {
        id: $scope.PR._id
      },{
        needed_by_date : ''
      },function(){
        $scope.editableData.needed_by_date = null;
        $scope.PR.needed_by_date = null;
      },function (error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * check errors field from prFunction.getErrorOnSubmit
   * @param field
   */
  function submissionValidation(field) {
    var errors = prFunction.getErrorsOnSubmit();
    return (errors.indexOf(field) > -1);
  }

  function approvalFlowData() {
    $scope.localApprovalData = prFunction.getApprovalFlow();
    return $scope.localApprovalData;
  }

  function setShowing(section){
    switch (section) {
      case 'bill_to':
        $scope.billToShow = ($scope.billToShow === true) ? false : true;
        break;
      case 'deliver_to':
        $scope.deliverToShow = ($scope.deliverToShow === true) ? false : true;
        break;
      case 'other_details':
        $scope.otherToShow = ($scope.otherToShow === true) ? false : true;
        break;
    }
  }

  function initialize(){

    /**
     * Assign variable for pr data
     */
    var pr = prFunction.getPRData();

    $scope.dateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1
    };
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;

    $scope.requestorsList = [];
    $scope.expenseTypes = [];
    $scope.budgetListing = [];
    $scope.billToShow = false;
    $scope.deliverToShow = false;
    $scope.otherToShow = false;
    $scope.defaultBudgetOption = {
      '_id': null,
      'assignment_id': null,
      'descr': 'N/A',
      'code': 'Not Applicable',
      'type': null
    };

    $scope.openDatePicker           = openDatePicker;
    $scope.clearNeededByDate        = clearNeededByDate;
    $scope.onSelectDeliveryAddress  = onSelectDeliveryAddress;
    $scope.onSelectBillingAddress   = onSelectBillingAddress;
    $scope.onSelectCostCenter       = onSelectCostCenter;
    $scope.onSelectBillingCompany   = onSelectBillingCompany;
    $scope.onSelectExpenseType      = onSelectExpenseType;
    $scope.onSelectCurrency         = onSelectCurrency;
    $scope.onSelectContactPerson    = onSelectContactPerson;
    $scope.onUpdatingHeaderInfo     = onUpdatingHeaderInfo;
    $scope.onSelectPRRequestor      = onSelectPRRequestor;
    $scope.submissionValidation     = submissionValidation;
    $scope.getCurrencyList          = getCurrencyList;
    $scope.approvalFlowData         = approvalFlowData;
    $scope.setShowing               = setShowing;
    $scope.onSelectBudget           = onSelectBudget;
    $scope.checkAvailableBudget     = checkAvailableBudget;
    $scope.onChangeRemarks          = onChangeRemarks;

    function checkAvailableBudget(budgetListing) {
      var emptyArray = false;

      _.forEach(budgetListing, function(value) {
        if (!!value._id) {
          emptyArray = true;
        }
      });

      return emptyArray;
    }

    copyTheDataToTemporaryModes();
    checkSubscription($scope.PR);
    if (!!$scope.costCenters && !!$scope.costCenters.length){
      if(!!$scope.PR.cost_center && !!$scope.PR.cost_center._id){
        loadAvailableAddresses(globalFunc.findInArray($scope.costCenters, '_id', $scope.PR.cost_center._id));
      }
    }

    loadExpenseTypes();
    loadContactPersons();
    loadBudgets();

    if(!!pr.cost_center && !!pr.cost_center._id){
      loadRequestorsList(pr.cost_center);
      loadAccountCode(pr.company.code, pr.cost_center._id);
    }

    var billingCompanyArray = globalFunc.findInArray($scope.PR.company.billing_company_codes_list, 'code', $scope.editableData.alternate_billing_company);
    if (!!billingCompanyArray) {
      $scope.editableData.alternate_billing_company = billingCompanyArray;
    }
  }

  initialize();
}


angular
  .module('metabuyer')
  .directive('purchaseRequisitionDetailsHeader',function($rootScope) {
      return {
        restrict: 'E',
        scope: {
          editingState: '=',
          PR: '=pr',
          costCenters: '='
        },
        template: '<div ng-include="myTemplateUrl"></div>',
        controller: 'purchaseRequisitionsDetailsHeaderCtrl',
        link: function ($scope) {
          $scope.getTemplateUrl = getTemplateUrl;

          function getTemplateUrl(state) {
            if ($rootScope.isMobileMode){
              $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/detailsHeader/purchaseRequisitionDetailsHeader.NonEditableTemplate-mobile.html';
            }

            if (!$rootScope.isMobileMode) {
              switch (state) {
                case 'editable':
                  $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/detailsHeader/purchaseRequisitionDetailsHeader.EditableTemplate.html';
                  break;
                case 'notEditable' :
                  $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/detailsHeader/purchaseRequisitionDetailsHeader.NonEditableTemplate.html';
                  break;
              }
            }
          }
          $scope.$watch('editingState', function (newValue) {
            getTemplateUrl(newValue);
          });
        }
      }
    })
  .controller('purchaseRequisitionsDetailsHeaderCtrl',  purchaseRequisitionsDetailsHeaderCtrl);
