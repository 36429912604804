'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.exports', {
        url: '/exports',
        controller: 'exportsCtrl',
        templateUrl: 'app/exportsListing/exportsListing.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkExportLogAccess(profile, 'C') ||
              !UserPermissions.checkExportLogAccess(profile, 'L') ||
              !UserPermissions.checkExportLogAccess(profile, 'O') ||
              !UserPermissions.checkExportLogAccess(profile, 'U') ||
              !UserPermissions.checkExportLogAccess(profile, 'D')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.exports.log', {
        templateUrl: 'app/exportsListing/logs/logs.html',
        url: '/logs',
        controller: 'exportsLogCtrl',
        resolve: {
          $title: function(){
            return 'Exports - Logs';
          }
        }
      })
  }]);

