'use strict';

/**
 * controller for preparing the data to pass it to the actionModal directive
 *
 * @param $scope
 * @param multiApprovalActions
 * @param globalFunc
 * @param $state
 * @param toastr
 */

function multiApprovalActionCtrl ($scope, multiApprovalActions, globalFunc, $state, toastr) {

  /**
   * list the Approval IDs for the selected PRs
   * @returns {{approval_ids: Array}}
   */
  function getApprovalIDs() {
    var IDs = [];
    _.forEach($scope.getSelectedItems() , function (item) {
      if (!!item.approval_id) {
        IDs.push(item.approval_id);
      }
    });
    return {approval_ids: IDs};
  }

  /**
   * return the resource function for the directive
   * @param action
   * @param text
   * @param extraPayload
   * @returns {*}
   */
  function getMultiActionFunction(action, text, extraPayload) {
    return multiApprovalActions.post(
      {
        action: action
      },
      extraPayload,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.message) {
          if (_.isObject(resource.content.message)) {
            if (!!resource.content.message.failed)
              displayFailedErrorData(action, resource.content.message.failed);
          } else {
            toastr.success(resource.content.message);
          }
        } else {
          toastr.success('The action was performed successfully');
        }
      }, function (errors) {
        errors = errors.data;
        // cannot use global error message
        // it is using different data format
        // the error message returns approval id and the error message
        if (!!errors && !!errors.content && !!errors.content.error && !!errors.content.error.message) {
          displayFailedErrorData(action, errors.content.error.message);
        }
      }
    );
  }

  /**
   * Display error for failed data
   *
   * @param action
   * @param data
   */
  function displayFailedErrorData(action, data) {
    var contents = $scope.getSelectedItems();
    _.forEach(data, function (error) {
      var object = globalFunc.findInArray(contents, 'approval_id', error.approval_id);

      if (!!object) {
        var objectName = object.reference || object.descr || object.catalog_descr;
        toastr.error('Failed to ' + action + ' ' + objectName + ': '+ error.message);
      }
    })
  }

  /**
   * return handler for the multiAction
   * @returns {*|void|promise}
   */
  function getMultiActionHandler(){
    return $state.reload();
  }

  /**
   * prepare any extra params for each action once the <span> is clicked
   */
  function prepareExtraParams(){
    $scope.extraParams = getApprovalIDs();
  }

  $scope.getMultiActionFunction       = getMultiActionFunction;
  $scope.getMultiActionHandler        = getMultiActionHandler;
  $scope.prepareExtraParams           = prepareExtraParams;
}

/**
 * @name multiApprovalAction
 * @desc directive for performing Approval action on multiple items
 * this directive uses the action modal directive
 *
 */
angular
  .module('metabuyer')
  .controller('multiApprovalActionCtrl', multiApprovalActionCtrl)
  .directive('multiApprovalAction',function() {
    return {
      restrict: 'EA',
      templateUrl: 'components/multiApprovalAction/multiApprovalActionTemplate.html',
      controller: 'multiApprovalActionCtrl',
      scope: {
        getSelectedItems: '&',
        showOnHold: '@?',
        type: '@'
      }
    }
  });
