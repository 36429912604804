'use strict';

angular
  .module('metabuyer')
  .directive('ucwords', function(uppercaseFilter, $parse, globalFunc) {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        var capitalize = function(inputValue) {
          if (!!inputValue) {
            var capitalized = globalFunc.capitalizeFirstCharacter(inputValue);
            if(capitalized !== inputValue) {
              modelCtrl.$setViewValue(capitalized);
              modelCtrl.$render();
            }
            return capitalized;
          }
        }
        var model = $parse(attrs.ngModel);
        modelCtrl.$parsers.push(capitalize);
        capitalize(model(scope));
      }
    };
  });
