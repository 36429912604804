'use strict';

angular
  .module('metabuyer')
  .controller('addApproverCtrl', addApproverCtrl);

addApproverCtrl.$inject = [
  '$scope', 'toastr', '$uibModalInstance', 'companyCode', 'overlays', 'approvalId', 'adhocApprover', '$state',
  'getUsersInCompanies', 'globalFunc', '$rootScope'
];

function addApproverCtrl (
  $scope, toastr, $uibModalInstance, companyCode, overlays, approvalId, adhocApprover, $state, getUsersInCompanies,
  globalFunc, $rootScope
) {

  $scope.cancel = cancel;
  $scope.addApprover = addApprover;
  $scope.getUserName = getUserName;

  $scope.approvalRoles = [];
  $scope.newApprover = {};
  $scope.usersSelected = [];
  $scope.users = [];
  $scope.userSetting = {
    scrollable: true,
    scrollableHeight: '300px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select users'
  };


  function cancel() {
    $uibModalInstance.close();
  }

  /**
   * Add new approver to the approval flow step
   */
  function addApprover(){
    var users = [];
    var tempSourceId = overlays.sourceId;
    var tempTargetId = overlays.targetId || '';

    tempSourceId = tempSourceId.split('_');
    tempTargetId = tempTargetId.split('_');

    _.forEach($scope.usersSelected, function(user){
      users.push(user.id);
    });

    var sourceStep = tempSourceId[1];
    var targetStep = tempTargetId[1];

    adhocApprover.post({
      id: approvalId
    }, {
      users: users,
      source_step: sourceStep,
      target_step: targetStep
    }, function(){
      toastr.success('New approver added');
      $state.reload();
      cancel();
    }, function (error){
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * To prepare users data list follows ng-dropdown format
   */
  function reformatUsersRole(users){
    if (!!users) {
      _.forEach(users, function(user) {
        user.id = user._id;
        user.label = user.display_name;

        if ($rootScope.currentUser._id !== user._id) {
          $scope.users.push(user);
        }
      });
    }
  }

  /**
   * Get user name based on given userId
   *
   * @param userId
   * @returns {*}
   */
  function getUserName(userId) {
    var user = globalFunc.findInArray($scope.users, 'id', userId);
    return user.label;
  }

  function getUsers() {
    // get all the users in company
    var params = {
      'companyCodes[0]': companyCode
    };

    getUsersInCompanies.get(
      params,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          reformatUsersRole(resource.content.data);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function initialize() {
    getUsers();
  }

  initialize();
}
