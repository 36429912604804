'use strict';

angular.module('metabuyer')
  .controller('ReverseGoodsCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId, pathConstants,
                                            $http, $filter, $state) {
    $scope.next_reverse_goods = true;
    $scope.back_reverse_goods = false;
    $scope.enable_next = false;
    $scope.submit_data = {};

    $scope.reverse_goods_data = [];

    $scope.nextReverseGoods = nextReverseGoods;
    $scope.cancel = cancel;
    $scope.backReverseGoods = backReverseGoods;
    $scope.processReverseGoods = processReverseGoods;
    $scope.initialize = initialize;
    $scope.convertToInt = convertToInt;

    //next action
    function nextReverseGoods(){
      $scope.next_reverse_goods = false;
      $scope.back_reverse_goods = true;
    }

    function convertToInt(number) {
      return Number(number);
    }

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //back action
    function backReverseGoods(){
      $scope.next_reverse_goods = true;
      $scope.back_reverse_goods = false;
    }

    $scope.$watch("reverse_goods_data", function(value){
      var fill_up_wrong = _.find($scope.reverse_goods_data, function(item){
        return Number(item.reversed_amount) != 0 &&
          (Number(item.reversed_amount) > Number(item.po_item.quantity) ||
          Number(item.reversed_amount) > Number(item.po_item.order.delivered));
      });

      if(fill_up_wrong != undefined){
        $scope.enable_next = false;
      }
      else{
        var fill_up_right = _.find($scope.reverse_goods_data, function(item){
          return !!item.reversed_amount && item.reversed_amount != 0;
        });

        if(fill_up_right != undefined){
          $scope.enable_next = true;
        } else {
          $scope.enable_next = false;
        }
      }
    }, true);

    function checkFraction(item) {
      if(!!item.po_item && !!item.po_item.uom) {
        if (!item.po_item.uom.is_fraction || item.po_item.uom.is_fraction === 0) {
          if (item.reversed_amount % 1 !== 0) {
            toastr.error('Unit of Measurement of item number ' + (Number(item.POIndex) + 1) +' does not allow amount in fraction');
            return false;
          }
        }
      }
      return true;
    }
    //proceed action
    function processReverseGoods(){

      var fd = new FormData();
      var validFraction = true;
      for(var i in $scope.reverse_goods_data) {
        var item = $scope.reverse_goods_data[i];
        if (item.reversed_amount && (item.reversed_amount <= item.po_item.quantity
          || item.reversed_amount <= item.po_item.order.delivered)) {
          validFraction = checkFraction(item);
          if (!validFraction) {
            break;
          }
          fd.append("items[" + item.POIndex + "]", item.reversed_amount);
        }
      }

      if(!!$scope.submit_data.comment){
        fd.append('comment', $scope.submit_data.comment);
      }

      if(validFraction) {
        $uibModalInstance.close();

        $http.post($filter('format')(pathConstants.apiUrls.po.reverse, {po_id: $scope.po._id}), fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
          })
          .then(function onSuccess () {
            toastr.success('PO has been reversed');

            HighlightId.setId($scope.po._id);
            $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
          })
          .catch(function onError () {
            toastr.error('Failed to reverse PO');
          });
      }
    }

    function initialize(){
      _.forEach($scope.po.items, function(item, i){
        $scope.reverse_goods_data.push({po_item: item, reversed_amount: 0, POIndex: i});
      });
    }

    $scope.initialize();
  });
