'use strict';
/**
 * @name approvalFlowDetailController
 * @desc Controller for Approval Flow Detail
 */

function approvalFlowPreviewController($scope, globalFunc, companies, searchModule, companyExpenseTypes,
                                       companyCurrencies, toastr, approvalPreviewByData) {

  $scope.uiCompany = {};
  $scope.uiCompany.list = {};
  $scope.companyListing = companies.data;
  $scope.uiCostCenter = {};
  $scope.uiCostCenter.list = {};
  $scope.expenseTypesListing = null;
  $scope.uiExpense = {};
  $scope.uiExpense.list = {};
  $scope.uiAmount = 0;
  $scope.uiItemType = {};
  $scope.uiItemType.list = [];
  $scope.itemTypeListing = [
    {
      code: 'IT',
      descr: 'Information Technology'
    },
    {
      code: 'CP',
      descr: 'Car Purchase'
    },
    {
      code: 'ME',
      descr: 'M&E'
    },
    {
      code: 'FB',
      descr: 'Food, Beverages and Tobacco'
    }
  ];
  $scope.currencyListing = [];
  $scope.uiCurrency = {};
  $scope.uiCurrency.list = {};
  $scope.uiCatalog = {};
  $scope.uiCatalog.list = {};
  $scope.catalogListing = [
    {
      code: 'catalog',
      descr: 'Catalog Only'
    },
    {
      code: 'noncatalog',
      descr: 'Catalog & Non Catalog'
    }
  ];
  $scope.uiType = {};
  $scope.uiType.list = {};
  $scope.typeListing = [];
  $scope.templateTypes = [
    {
      code: 'PR',
      descr: 'PR'
    },
    {
      code: 'Supplier',
      descr: 'Supplier'
    },
    {
      code: 'Catalog',
      descr: 'Catalog'
    },
    {
      code: 'Budget',
      descr: 'Budget'
    }
  ];
  $scope.approvals = null;

  $scope.processCostCenterList = processCostCenterList;
  $scope.preview = preview;
  $scope.resetSelection = resetSelection;

  function loadCurrencies() {
    companyCurrencies.get({
        id: $scope.uiCompany.list._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.currencyListing = resource.content.data;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function loadExpenseTypes() {
    companyExpenseTypes.get(
      {
        id: $scope.uiCompany.list._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypesListing = resource.content.data;
          if (!!$scope.uiType.list && $scope.uiType.list.code === 'Budget') {
            $scope.expenseTypesListing.unshift({'code': 'all', 'descr': 'All'});
          }
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function processCostCenterList() {
    $scope.uiCostCenter.list = {};
    $scope.uiExpense.list = [];

    //stop when no company is selected
    if (!$scope.uiCompany.list.code) {
      $scope.costCenterListing = [];
      return;
    }

    loadExpenseTypes();
    loadCurrencies();

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'and',
      order_by: 'descr',
      order: 1
    };

    if ($scope.uiCompany.list.code !== 'SYSTEM')
      params['criteria[company_code]'] = $scope.uiCompany.list.code;

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterListing = resource.content.data;
          $scope.costCenterListing.unshift({'code':'all', 'descr':'All'});
        }
      }
    )
  }

  function validationBeforePreview() {
    //non-PR types are not required to fill in the rest of the fields
    if (!!$scope.uiType.list.code && $scope.uiType.list.code !== 'PR' && $scope.uiType.list.code !== 'Budget') {
      return true;
    }

    switch ($scope.uiType.list.code) {
      case 'PR':
        if (!$scope.uiCompany.list.code || !$scope.uiCostCenter.list.code || !$scope.uiExpense.list.code ||
          $scope.uiAmount <= 0 || !$scope.uiCurrency.list.code || !$scope.uiCatalog.list.code ||
          !$scope.uiType.list.code
        ) {
          toastr.error('Please fill in all the required fields and total amount must be greater than 0.');
          return false;
        }
        break;

      case 'Budget':
        if (!$scope.uiCompany.list.code || !$scope.uiCostCenter.list.code || !$scope.uiExpense.list.code ||
          $scope.uiAmount <= 0 || !$scope.uiCurrency.list.code
        ) {
          toastr.error('Please fill in all the required fields and total amount must be greater than 0.');
          return false;
        }
        break;
    }
    return true;
  }

  function preview() {
    $scope.approvals = null;

    if (!validationBeforePreview())
      return;

    var param = {
      type: $scope.uiType.list.code,
      company: $scope.uiCompany.list.code,
      cost_center: !!$scope.uiCostCenter.list.code ? $scope.uiCostCenter.list.code : 'all',
      expense: $scope.uiExpense.list.code,
      total_amount: $scope.uiAmount,
      item_type: !!$scope.uiItemType.list.length ? _.pluck($scope.uiItemType.list, 'code').join(",") : '',
      currency: $scope.uiCurrency.list.code,
      catalog: $scope.uiCatalog.list.code
    }

    approvalPreviewByData.get(
      {
        data: param
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.approvals = resource.content.data;
          generatePrint();
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  /**
   * Function that reset searching fields
   * @param {boolean} isResetButton  Calling from either 'reset button' or on type selecting
   * @param {boolean} resetTypeField Whether to reset Type searching field
   */
  function resetSelection(isResetButton, resetTypeField) {
    if ((!!isResetButton) ||
      (!isResetButton && !!$scope.uiType && !!$scope.uiType.list && !!$scope.uiType.list.code &&
      $scope.uiType.list.code !== 'PR')) {
      $scope.uiCompany.list = [];
      $scope.uiCostCenter.list = {};
      $scope.uiExpense.list = [];
      $scope.uiAmount = 0;
      $scope.uiItemType.list = [];
      $scope.uiCurrency.list = [];
      $scope.uiCatalog.list = [];
      if (!!resetTypeField) {
        $scope.uiType.list = [];
      }
    }
  }
}

approvalFlowPreviewController.$inject = ['$scope', 'globalFunc', 'companies', 'searchModule', 'companyExpenseTypes',
  'companyCurrencies', 'toastr', 'approvalPreviewByData'];

angular
  .module('metabuyer')
  .controller('approvalFlowPreviewController', approvalFlowPreviewController);
