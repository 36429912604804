'use strict';

/**
 * @name supplierBranching
 *
 * @description
 * Directive for supplier branching used in add and edit supplier
 *
 * @param data (array)
 * to pass branch data to directive
 *
 * @param editable (boolean)
 * editable status
 *
 * @param searchable (boolean)
 * param to activate search bar
 *
 * @param selectedSupplierBranch (array)
 * Selected supplier branch. call back access parent function
 *
 * @author Khor Jin Ee <jinee585@gmail.com>
 * @copyright Sunway Metacloud &copy; 2017
 */
angular.module('metabuyer')
  .directive('branchListing', function (searchModule, globalFunc, $q, $uibModal,
                                        validateSupplierBranchDeletion, toastr) {
    return {
      restrict: 'E',
      templateUrl: 'components/branchListing/branchListing.html',
      scope: {
        data: '=',
        editable: '=',
        searchable: '=',
        selectable: '=',
        selectedSupplierBranch: '&'
      },
      link: function ($scope) {
        $scope.branchDetailsModal = branchDetailsModal;
        $scope.deleteBranches = deleteBranches;
        $scope.addBranchesModal = addBranchesModal;
        $scope.selectedSupplierBranch = selectedSupplierBranch;

        var indexDeletedRows =[];
        var branchIDs = [];

        function consolidateRemainingBranches(remainingBranches) {
          $scope.data = remainingBranches.slice();
        }

        function deleteBranches() {
          indexDeletedRows =[];
          branchIDs =[];
          var remainingBranches = [];

          _.forEach($scope.data, function(data, index) {
            if(data.checked == true) {
              indexDeletedRows.push(index);
              if(!!data._id) {
                branchIDs.push(data._id);
              }
            } else {
              remainingBranches.push($scope.data[index]);
            }
          });

          if(branchIDs.length > 0) {
            validateSupplierBranchDeletion.get({
              id: $scope.$parent.supplier._id,
              branches: branchIDs.toString(),
              supplierId : $scope.$parent.supplier._id
            }, function(resource) {
              var beingUsedBranches = [];

              _.forEach(resource, function(data, index) {
                if (data === false) {
                  beingUsedBranches.push($scope.data.filter(function(el) {
                    if (el._id === index) {
                      return {descr: el.descr};
                    }
                  }));
                }
              });

              if (beingUsedBranches.length > 0) {
                var listInString = '';
                _.forEach(beingUsedBranches, function(branch, index) {
                  if(index !== 0){
                    listInString += ' ,';
                  }
                  listInString += branch[0].descr;
                });
                swal(
                  {
                    title: 'Branch Deletion Aborted',
                    text: 'You can not delete the branches below due to their ' +
                    ' relationship with existing PRs. Please check the lists below: ' +
                    listInString,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: "#1ab394",
                    confirmButtonText: "Okay",
                    closeOnConfirm: true
                  });
              } else {
                consolidateRemainingBranches(remainingBranches);
              }

            }, function () {
              toastr.error('Branch Deletion Failed');
            });
          } else {
            consolidateRemainingBranches(remainingBranches);
          }
        }

        function selectedSupplierBranch(branch) {
          $scope.$parent.callBackFunction(branch);
        }

        function addBranchesModal() {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/suppliersManagement/branches/add/add.html',
            controller: 'addSupplierBranches',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              branches: function () {
                return $scope.data;
              }
            }
          });
          modalInstance.result.then(function (branchValue) {
            if(!!branchValue) {
              $scope.data.push(branchValue);
            }
          });
        }

        function branchDetailsModal(branch,index) {
          var modalInstance = $uibModal.open({
            templateUrl: 'app/suppliersManagement/branches/details/details.html',
            controller: 'supplierBranchDetailCtrl',
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
            resolve: {
              param: function () {
                return branch;
              },
              editable: function () {
                return $scope.editable;
              },
              // Return all branches related to this supplier
              branches: function () {
                return $scope.data;
              },
              // Return the branch index
              branchIndex: function() {
                return index;
              }
            }
          });
          modalInstance.result.then(function (branchDetails) {
            if(!!branchDetails) {
              $scope.data[index] = branchDetails;
            }
          });
        }
      }
    }
  });
