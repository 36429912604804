'use strict';

/**
 * @name budget services
 *
 * @description service for budget endpoint calls
 * This service is calling budget endpoints
 *
 * @requires $resource
 * @requires $filter
 * @requires pathConstants
 *
 * @authors Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */

angular.module('metabuyer.services.budgets', ['ngResource'])
  .factory('budgets', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.single,
      {
        id: '@id',
        action: '@action'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('budgetCommentList',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.listBudgetComment,
        {
          referenceId: '@referenceId'
        },
        {
          get: {method: 'GET'},
          post: {
            method: 'POST',
            params: {
              referenceId: '@referenceId'
            },
            url: pathConstants.apiUrls.comments.saveBudgetComment
          }
        }
      )
    })
  .factory('budgetList', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.list,
      {
        action: '@action'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('apportionAllocation', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.budget.apportionAllocation,
      {},
      {
        'post': {method: 'POST'}
      }
    );
  });
