'use strict';

function purchaseRequisitionsItemListCtrl (
  $scope, prFunction, purchaseRequisitionsServices, globalFunc, pathConstants, toastr, lang,
  $rootScope, $q, $uibModal
) {

  // PR data used in local controller
  var localPRData = {};

  $scope.imageBasePath            = pathConstants.apiUrls.image.pr;
  $scope.minDateItem              = new Date();
  $scope.currentUser              = $rootScope.currentUser || {_id: null};
  $scope.accountCodes             = [];
  $scope.itemMainCheckBox         = {
    selected : false
  };
  $scope.itemOthersDetail         = [
    {code :'needed_by_date',name : 'needed_by_date'},
    {code :'delivery_address',name : 'delivery_address'},
    {code :'note',name : 'note'},
    {code :'account_code',name : 'account_code'},
    {code :'freights',name : 'freights'},
    {code :'transportation',name : 'transportation'},
    {code :'branch',name : 'supplier_branch'}
  ];
  $scope.itemMainCheckBox = {};
  var fieldNameConversion = {
    qty: {
      name: 'Qty',
      type: 'number'
    },
    price: {
      name: 'price',
      type: 'number'
    },
    discount: {
      name: 'discount',
      type: 'numberPercentage'
    },
    tax: {
      name: 'tax_id',
      type: 'string'
    },
    'needed-by': {
      name: 'needed_by_date',
      type: 'string'
    },
    note: {
      name: 'note',
      type: 'string'
    },
    'delivery-address': {
      name: 'delivery_address_id',
      type: 'string'
    },
    'set-supplier-branch': {
      name: 'branch',
      type: 'string'
    },
    'unset-supplier-branch': {
      name: 'branch',
      type: 'string'
    },
    'account-code': {
      name: 'account_code_id',
      type: 'string'
    },
    freights: {
      amount: {
        name: 'amount',
        type: 'numberPercentage'
      },
      discount: {
        name: 'discount',
        type: 'numberPercentage'
      },
      tax_id: {
        name: 'tax_id',
        type: 'string'
      }
    },
    transportation: {
      amount: {
        name: 'amount',
        type: 'numberPercentage'
      },
      discount: {
        name: 'discount',
        type: 'numberPercentage'
      },
      tax_id: {
        name: 'tax_id',
        type: 'string'
      }
    }
  };

  $scope.getTemplateUrl                 = getTemplateUrl;
  $scope.PRData                         = PRData;
  $scope.differentCurrencyChecker       = differentCurrencyChecker;
  $scope.setLoading                     = setLoading;
  $scope.checkLoading                   = checkLoading;
  $scope.updateItem                     = updateItem;
  $scope.removeItems                    = removeItems;
  $scope.duplicateItem                  = duplicateItem;
  $scope.addingDiscount                 = addingDiscount;
  $scope.addLumpSum                     = addLumpSum;
  $scope.updateLumpSum                  = updateLumpSum;
  $scope.addingLumpSum                  = addingLumpSum;
  $scope.addOthers                      = addOthers;
  $scope.checkOthersValue               = checkOthersValue;
  $scope.isPRCreator                    = isPRCreator;
  $scope.allowEditingPR                 = allowEditingPR;
  $scope.openDatePicker                 = openDatePicker;
  $scope.updateNeededByDate             = updateNeededByDate;
  $scope.removeOtherValue               = removeOtherValue;
  $scope.getDeliveryAddresses           = getDeliveryAddresses;
  $scope.onSelectDeliveryAddress        = onSelectDeliveryAddress;
  $scope.getAccountCodes                = getAccountCodes;
  $scope.onSelectAccountCode            = onSelectAccountCode;
  $scope.itemMainCheckboxClicked        = itemMainCheckboxClicked;
  $scope.updateMultiItems               = updateMultiItems;
  $scope.addingFreightsTransport        = addingFreightsTransport;
  $scope.updateFreightTransport         = updateFreightTransport;
  $scope.removeFreightOrTransportation  = removeFreightOrTransportation;
  $scope.removeTax                      = removeTax;
  $scope.showLumpSumButton              = showLumpSumButton;
  $scope.updateItemTax                  = updateItemTax;
  $scope.taxList                        = getTaxData;
  $scope.canEditPR                      = canEditPR;
  $scope.supplierBranchModal            = supplierBranchModal;
  $scope.onSelectSupplierBranch         = onSelectSupplierBranch;
  $scope.updateDiscountInputFocus       = updateDiscountInputFocus;
  $scope.Unchecked                      = Unchecked;

  function supplierBranchModal(pr, index, branch) {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/purchaseRequisitionComponents/branchPrListing/branchPrListing.html',
      controller: 'branchPrListingCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      size: 'lg',
      resolve: {
        branchSelection: function ($q, supplierBranches) {
          var deferred = $q.defer();
          var params = {
            id : pr,
            mode : 'active'
          };
          supplierBranches.get(
            params,
            function (resource) {
              if (!!resource && !!resource.content) {
                deferred.resolve(resource.content);
              }
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        onSelectSupplierBranch : function() {
          return $scope.onSelectSupplierBranch;
        },
        itemIndex : index,
        backupBranch : branch
      }
    });
  }

  function onSelectSupplierBranch(supplierBranch, itemIndex, backupBranch) {

      if (!!backupBranch) {
        backupBranch = null;
      }

      var pr = prFunction.getPRData();

      pr.pr_items[itemIndex].branch = supplierBranch;

      var payload = {
        supplier_id: pr.pr_items[itemIndex].supplier._id,
        branch_id: supplierBranch._id
      }

      var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: "Are you sure to add supplier branch?",
          text: validateLumpSum.message,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, add it!",
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prFunction.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                updateItem(itemIndex, 'set-supplier-branch', false, payload, '');
            })
          }
          else
            $scope.PRData().pr_items[itemIndex].branch = backupBranch;
        });
      }
      else {
        updateItem(itemIndex, 'set-supplier-branch', false, payload, '');
      }
      return true;
  }

  /**
   * function to handle the main check box, in case of selected or unselected
   * @param value
   */
  function itemMainCheckboxClicked(value){
    if(value){
      selectAllItems();
    }else{
      $scope.itemMainCheckBox.selected = false;
      unSelectAllItems();
    }
  }

  /**
   * makes all the items' checkboxes selected
   */
  function selectAllItems(){
    for (var i in $scope.PRData().pr_items){
      $scope.PRData().pr_items[i].selected = true;
    }
  }

  /**
   * makes all the items' checkboxes unselected
   */
  function unSelectAllItems(){
    for (var i in $scope.PRData().pr_items){
      $scope.PRData().pr_items[i].selected = false;
    }
  }

  /**
   * makes the main checkbox uncheck if one item unselected
   */
  function Unchecked(){
    $scope.itemMainCheckBox.selected = false;
  }


  function getTemplateUrl(state) {
    if (!$rootScope.isMobileMode) {
      switch (state) {
        case 'editable':
          $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/itemList/prItemList.EditableTemplate.html';
          break;
        case 'notEditable' :
          $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/itemList/prItemList.NonEditableTemplate.html';
          break;
      }
    }

    if ($rootScope.isMobileMode) {
      $scope.myTemplateUrl = 'components/purchaseRequisitionComponents/itemList/prItemList.NonEditableTemplate-mobile.html';
    }
  }

  $scope.$watch('editingState', function (newValue) {
    getTemplateUrl(newValue);
  });

  /**
   * function to open date picker
   * @param $event
   * @param itemIndex
   */
  function openDatePicker($event, itemIndex) {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.PRData().pr_items[itemIndex].datePicker = true;
  }

  /**
   * get delivery addresses from prFunction
   * shared by pr header
   * @returns {*}
   */
  function getDeliveryAddresses() {
    return prFunction.getCostCenterDeliveryAddresses();
  }

  /**
   * on selecting delivery address in line item
   * @param address
   * @param itemIndex
   * @param backupAddress
   */
  function onSelectDeliveryAddress(address, itemIndex, backupAddress) {
    var pr = prFunction.getPRData();
    pr.pr_items[itemIndex].delivery_address = address;
    var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: "Are you sure to add delivery address?",
        text: validateLumpSum.message,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, add it!",
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prFunction.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, 'delivery-address', false, address._id, '');
          })
        }
        else
          $scope.PRData().pr_items[itemIndex].delivery_address = backupAddress;
      });
    }
    else
      updateItem(itemIndex, 'delivery-address', false, address._id, '');
  }

  /**
   * updating account code
   */
  function onSelectAccountCode(selectedAccountCode, itemIndex) {
    updateItem(itemIndex, 'account-code', false, selectedAccountCode._id, '');
  }

  /**
   * Update needed by date value
   * @param itemIndex
   * @param date
   * @param dateBackup
   */
  function updateNeededByDate(itemIndex, date, dateBackup) {
    var milliTime = globalFunc.convertDateToTimestamp(date),
        milliTimeBackup = globalFunc.convertDateToTimestamp(dateBackup);
    updateItem(itemIndex, 'needed-by', false, milliTime, milliTimeBackup);
  }

  /**
   * check if the user is PRCreator
   * @returns {boolean}
   */
  function isPRCreator() {
    return ($scope.currentUser._id === $scope.PRData().creator._id);
  }

  /**
   * Check if current approver is a PA
   * @returns {*}
   */
  function allowEditingPR() {
    if (prFunction.getPRData().status === 'pending')
      return prFunction.checkCurrentApproverIsPA(!!prFunction.getPRData() ? prFunction.getPRData().waiting_on : null, !!$scope.currentUser  ? $scope.currentUser._id : null,
        $scope.currentUser  ? $scope.currentUser.roles : null);
    else
      return false
  }

  function removeOtherValue(item, field, itemIndex) {
    var tempFieldName = field;

    if (field === 'needed-by')
      tempFieldName = 'needed_by_date';
    if (field === 'account-code')
      tempFieldName = 'account_code';
    if (field === 'branch')
      tempFieldName = 'branch';

    if (!!item.selecting_others && !!item.selecting_others.length) {
      var arrayIndex = item.selecting_others.indexOf(tempFieldName);
      if (arrayIndex > -1)
        item.selecting_others.splice(arrayIndex, 1);
    }

    if (!!item[tempFieldName]) {
      if (field === 'delivery_address')
        field = 'delivery-address';

      if (field === 'branch')
        field = 'unset-supplier-branch';

      updateItem(itemIndex, field, false, null, item[tempFieldName]);
    }
  }

  function removeTax(itemIndex, othersType){
    updateFreightTransport(itemIndex, othersType, 'tax', 'tax_id', {_id:null});
  }

  function removeFreightOrTransportation(field, itemIndex) {

    var item = $scope.PRData().pr_items[itemIndex];
    var param = {};
    var backupField = 'backup_' + field;
    var backupValue = item[field][backupField];

    param.amount = null;
    param.discount = null;
    param.tax_id = null;

    sendingUpdateItem (itemIndex, item, field, param, backupValue);
  }

  /**
   * To get PR Data from service
   * and store the data also in localPRData
   * @returns {{}}
   */
  function PRData() {
    localPRData = prFunction.getPRData();
    return localPRData;
  }

  /**
   * adding state on adding others value
   * set an array called selecting_others
   * and push the field value to the array
   * @param field
   * @param itemIndex
   */
  function addOthers(field, itemIndex){
    var item = $scope.PRData().pr_items[itemIndex];
    if (!item.selecting_others)
      item.selecting_others = [];
    if (item.selecting_others.indexOf(field) === -1)
      item.selecting_others.push(field);

    localPRData.pr_items[itemIndex] = item;
    prFunction.setPRData(localPRData);
    $scope.PRData().pr_items[itemIndex] = item;
  }

  function updateItemTax(itemIndex, field, isDecimal, newValue, backupValue) {
    var pr = prFunction.getPRData();
    pr.pr_items[itemIndex].tax = newValue;
    var validateLumpSum = prFunction.validateLumpSum(pr.pr_items[itemIndex], itemIndex);

    if (!!pr.lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: "Are you sure to change the tax?",
        text: validateLumpSum.message,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, add it!",
        closeOnConfirm: true
      }, function(isConfirm){
        if (isConfirm) {
          // remove lump sum
          prFunction.removeLumpSum(pr._id, function(resource) {
            if (!!resource)
              updateItem(itemIndex, field, isDecimal, newValue, backupValue);
          })
        }
        // change back to the prev tax
        else
          $scope.PRData().pr_items[itemIndex].tax = backupValue;
      });
    }
    else
      updateItem(itemIndex, field, isDecimal, newValue, backupValue);
  }

  /**
   * Check if the others field in editing state
   * @param item
   * @param field
   * @returns {boolean}
   */
  function checkOthersValue(item, field) {
    if (field === 'freights' || field === 'transportation')
      return (item[field].amount > 0 ||
        (!!item.selecting_others && !!item.selecting_others.length && item.selecting_others.indexOf(field) > -1));
    else if (!!item[field])
      return (!!item[field]);
    else if (!!item.selecting_others && !!item.selecting_others.length)
      return (item.selecting_others.indexOf(field) > -1);
  }

  /**
   * adding lumpsum state
   * to indicate user is adding or editing lumpsum
   * @param value
   */
  function addLumpSum(value) {
    localPRData.lump_sum_discount = value;
    localPRData.lump_sum_discount_entry = value;
    prFunction.setPRData(localPRData);
  }

  /**
   * Update lump sum value
   * @param value
   * @param backupValue
   */
  function updateLumpSum(value, backupValue) {
    var param = {};

    if (value == backupValue)
      return;

    if (value !== null) {
      var validateValue = lineItemValidation(value, 'numberPercentage', true);
      if (!!validateValue) {
        setLineItemBackupValue(false, 'lump_sum_discount', false, backupValue, false);
        return false;
      }

      var convertValToStr = value.toString();
      if (convertValToStr.indexOf('%') > -1)
        param.lump_sum_percent = Number(convertValToStr.replace('%', ''));
      else
        param.lump_sum_net = Number(value);
    }

    prFunction.setLoading('lineItemTable', true);
    prFunction.setLoading('updatePRField', true);
    purchaseRequisitionsServices.setLumpSum({
      id: localPRData._id
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prFunction.setPRData(resource.content.data);

      prFunction.setLoading('lineItemTable', false);
    }, function(error) {
      setLineItemBackupValue(false, 'lump_sum_discount', false, backupValue, false);
      prFunction.setLoading('lineItemTable', false);
      globalFunc.objectErrorMessage(error);
    });
  }

  /**
   * Check param currency code with pr currency code
   * @param currencyCode
   * @returns {boolean}
   */
  function differentCurrencyChecker(currencyCode) {
    return (localPRData.currency.code !== currencyCode);
  }

  /**
   * Set loading value in prFunction service
   * @param loadingObject
   * @param loadingValue
   */
  function setLoading(loadingObject, loadingValue) {
    prFunction.setLoading(loadingObject, loadingValue);
  }

  /**
   * get loading value from prFunction service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prFunction.getLoading(loadingObject);
  }

  function addingDiscount(itemIndex) {
    // Update data
    PRData();
    localPRData.pr_items[itemIndex].addingDiscount = true;
    prFunction.setPRData(localPRData);
  }

  /**
   * Updating the discount input field to blur
   */
  function updateDiscountInputFocus(index) {
    angular.element('#discountInput' + index).blur();
  }

  /**
   * state on adding freight or transportation
   * @param itemIndex
   * @param type
   * @param state
   */
  function addingFreightsTransport(itemIndex, type, state) {
    // Update data
    PRData();
    localPRData.pr_items[itemIndex][type][state] = true;
    prFunction.setPRData(localPRData);
  }

  function addingLumpSum() {
    // Update data
    PRData();
    localPRData.addingLumpSum = true;
    prFunction.setPRData(localPRData);
  }

  /**
   * Duplicating line item
   * @param item
   */
  function duplicateItem(item) {
    if (!!item) {
      var itemId = item._id;

      prFunction.setLoading('lineItemTable', true);
      prFunction.setLoading('updatePRField', true);
      purchaseRequisitionsServices.setItem({
        id: itemId,
        action: 'duplicate'
      }, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prFunction.setPRData(resource.content.data);

        prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * Remove item(s) by id, the function receives an array
   * @param arr
   */
  function removeItems(arr) {
    if (!!arr && _.isArray(arr) && arr.length > 0) {
      var item = arr[arr.length - 1];
      if (!!item) {
        prFunction.setLoading('lineItemTable', true);
        prFunction.setLoading('updatePRField', true);
        prFunction.removeItem(item._id).$promise.then(function (resource) {
          if (!!resource.content && !!resource.content.data)
            prFunction.setPRData(resource.content.data);
          else {
            globalFunc.objectErrorMessage('something went wrong');
            return;
          }
          arr.pop();
          removeItems(arr);
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      }
      else {
        prFunction.setLoading('lineItemTable', false);
        prFunction.setLoading('updatePRField', false);
        $scope.itemMainCheckBox.selected = false;
      }
    } else {
      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('updatePRField', false);
      $scope.itemMainCheckBox.selected = false;
    }
  }

  /**
   * Update Freight Item
   * @param itemIndex
   * @param othersType (freights or transportation)
   * @param fieldEntry - Field name for entry is using 'entry'
   * @param field
   * @param tax
   */
  function updateFreightTransport(itemIndex, othersType, fieldEntry, field, tax) {
    var item = $scope.PRData().pr_items[itemIndex];
    var param = {};
    var backupField = 'backup_' + othersType;
    var newValue = item[othersType][fieldEntry];
    var backupValue = item[backupField][fieldEntry];

    if (!!tax)
      newValue = tax._id;

    // validate input
    if (newValue == backupValue)
      return;

    if (!!lineItemValidation(newValue, fieldNameConversion[othersType][field].type, false)) {
      setLineItemBackupValue(item, field, itemIndex, backupValue, false);
      return false;
    }

    // send all the information (amount, discount, tax) in one request
    var amount_entry = item[othersType].entry.toString();
    if (amount_entry.indexOf('%') > -1)
      param.amount_percentage = Number(amount_entry.replace('%', ''));
    else
      param.amount = Number(amount_entry);

    param.discount = null;
    if (!!item[othersType].discount_entry) {
      var discount_entry = item[othersType].discount_entry.toString();
      if (discount_entry.indexOf('%') > -1)
        param.discount_percentage = Number(discount_entry.replace('%', ''));
      else
        param.discount = Number(discount_entry);
    }

    if (!!tax)
      param.tax_id = tax._id;
    else
      param.tax_id = item[othersType].tax_id;

    sendingUpdateItem (itemIndex, item, othersType, param, backupValue);
  }

  /**
   * Update Item value (QTY, discount, tax)
   * @param itemIndex
   * @param field
   * @param isDecimal
   * @param newValue
   * @param backupValue
   */
  function updateItem(itemIndex, field, isDecimal, newValue, backupValue) {
    var item = localPRData.pr_items[itemIndex];
    var fieldToSend = field;
    var param = {};
    var zeroAllowed = field === 'price';
    if (newValue == backupValue)
      return;

    if (!!lineItemValidation(newValue, fieldNameConversion[field].type, isDecimal, zeroAllowed)) {
      setLineItemBackupValue(item, field, itemIndex, backupValue, false);
      return false;
    }

    if (field in fieldNameConversion)
      fieldToSend = fieldNameConversion[field].name;

    param[fieldToSend] = newValue;

    if (field === 'tax')
      param[fieldToSend] = newValue._id;

    // set is_percentage for discount
    if (field === 'discount') {
      newValue = newValue.toString();
      param.is_percentage = (newValue.indexOf('%') > -1);
      param[fieldToSend] = Number(newValue.replace('%', ''));
    }

    if (field === 'freight' || field === 'transportation') {
      newValue = newValue.toString();
      if (!!param[fieldToSend])
        delete param[fieldToSend];
      if (newValue.indexOf('%') > -1)
        param.amount_percentage = Number(newValue.replace('%', ''));
      else
        param.amount = Number(newValue);
    }

    sendingUpdateItem (itemIndex, item, field, param, backupValue);
  }

  /**
   * Sending updateItem request
   * @param itemIndex
   * @param item
   * @param field
   * @param param
   * @param backupValue
   */
  function sendingUpdateItem(itemIndex, item, field, param, backupValue) {
    if (!!item) {
      prFunction.setLoading('lineItemTable', true);
      prFunction.setLoading('updatePRField', true);
      purchaseRequisitionsServices.setItem({
        id: item._id,
        action: field
      }, param, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          prFunction.setPRData(resource.content.data);

        // Set backup value
        prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        setLineItemBackupValue(item, field, itemIndex, backupValue, false);
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * updating item for mass edit
   * using recursive function to send the request one by one
   *
   * @param items
   * @param field
   * @param newValue
   * @param itemIndex
   *
   * @returns {string}
   */
  function updateMultiItems(items, field, newValue, itemIndex){

    var fieldToSend = field;
    var param = {};

    var index = itemIndex || 0;
    var verified = true;
    var item = items[index];

    if (lineItemValidation(newValue, fieldNameConversion[field].type, item.UOM.is_fraction)) {
      verified = false;
      return 'error';
    }

    if (field in fieldNameConversion)
      fieldToSend = fieldNameConversion[field].name;

    param[fieldToSend] = newValue;

    if (field === 'tax')
      param[fieldToSend] = newValue._id;

    if (field === 'discount') {
      if (!!newValue) {
        newValue = newValue.toString();
        param.is_percentage = (newValue.indexOf('%') > -1);
        param[fieldToSend] = Number(newValue.replace('%', ''));
        if(newValue > item.amount){
          globalFunc.objectErrorMessage('The discount cannot be more than the Total amount');
          verified = false;
          return 'error';
        }
      } else if (newValue === 0) {
        param.is_percentage = false;
        param[fieldToSend] = 0;
      }
    }

    if(verified) {
      prFunction.setLoading('lineItemTable', true);
      prFunction.setLoading('updatePRField', true);
      purchaseRequisitionsServices.setItem({
        id: item._id,
        action: field
      }, param, function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          prFunction.setPRData(resource.content.data);
          addSelectedStatus(items, resource.content.data);
        }

        // go to the next item index
        // and validate if the next index is the last index
        // if not the last, call the updateMultiItems with the new index
        index++;
        if (!!items && items.length !== index)
          updateMultiItems(items, field, newValue, index);
        else
          prFunction.setLoading('lineItemTable', false);
      }, function(error) {
        setLineItemBackupValue(item, field, itemIndex, backupValue, false);
        prFunction.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  /**
   * re-added selected value to the resource data
   * @param prevPRItem
   * @param newPR
   * @returns {*}
   */
  function addSelectedStatus(prevPRItem, newPR) {
    _.forEach(newPR.pr_items, function (item) {
      var checkPrevPR = globalFunc.findInArray(prevPRItem, '_id', item._id);
      if (!!checkPrevPR && !!checkPrevPR.selected)
        item.selected = checkPrevPR.selected;
    });
    return newPR;
  }

  /**
   * Set or Restore the item backup value
   * @param item
   * @param field
   * @param itemIndex
   * @param backupValue
   * @param setValue
   * boolean value to set or to restore
   */
  function setLineItemBackupValue(item, field, itemIndex, backupValue, setValue) {

    // replace the value with backupValue
    var newFieldName = field;
    if (!!setValue)
      newFieldName = 'backup_' + field;

    if (!!item) {
      item[newFieldName] = backupValue;

      if (newFieldName === 'price')
        item.unit_price = backupValue;

      if (newFieldName === 'discount')
        item.discount_entry = backupValue;

      if (newFieldName === 'freights' || newFieldName === 'transportation') {
        var backupField = 'backup_' + newFieldName;
        item[newFieldName] = item[backupField];
      }

      $scope.PRData().pr_items[itemIndex] = item;
    } else {
      $scope.PRData()[newFieldName] = backupValue;
    }
    prFunction.setPRData($scope.PRData());
  }

  /**
   * This validation can be used in general cases
   * todo: can be moved to general function (later)
   * Validate value based on the type
   * and calling function based on the type
   * @param value
   * @param type
   * @param isDecimal
   * @param zeroAllowed
   */
  function lineItemValidation(value, type, isDecimal, zeroAllowed) {
    var message = "";

    if (type === 'number')
      message = numberValidation(isDecimal, value, zeroAllowed);

    if (type === 'numberPercentage')
      message = percentageValidation(value);

    if (!!message)
      toastr.error(message);

    return !!message;
  }

  function percentageValidation(entry) {
    if (!!entry) {
      entry = entry.toString();
      var percentageCount = (entry.match(/%/g) || []).length;
      var dotCount = (entry.match(/\./g) || []).length;

      /**
       * Remove % character from input to pass to check decimal places
       */
      if(entry.indexOf('.') > 1){
        var entryWithoutPercentSign = angular.copy(entry);
        entryWithoutPercentSign = entryWithoutPercentSign.replace('%', '');
        var decimalPlaces = (entryWithoutPercentSign.length - entryWithoutPercentSign.indexOf('.')) - 1;
      }

      if (percentageCount > 1 ||
        (percentageCount === 1 && entry.length <= 1) ||
        (percentageCount === 1 && entry.indexOf("%") !== entry.length - 1) ||
        (dotCount > 1) ||
        (dotCount > 0 && entry.indexOf(".") == 0) ||
        decimalPlaces > 2
      ) {
        return lang.validation.percentage.invalid;
      }

      return false;
    }
  }

  /**
   * Number validation
   * - Positive number only
   * - check if valid Number
   * - check decimal value if isDecimal value is true
   * @param isDecimal
   * @param value
   * @param zeroAllowed
   */
  function numberValidation(isDecimal, value, zeroAllowed) {
    var validFraction = fractionValidation(value);

    if (isNaN(value))
      return lang.validation.number.isNumber;
    if(zeroAllowed) {
      if (!(value >= 0))
        return lang.validation.number.positive;
    }else{
      if (!(value > 0))
        return lang.validation.number.positive;
    }
    if (!isDecimal && !!validFraction)
      return lang.validation.number.noFraction;

    return false;
  }

  function getAccountCodes() {
    return prFunction.getAccountCodes();
  }

  /**
   * Decimal value validation
   * @param value
   */
  function fractionValidation(value) {
    return Number(value) % 1 !== 0;
  }

  function showLumpSumButton() {
    var validateLumpSum = prFunction.validateLumpSum(null, -1);
    return validateLumpSum.status;
  }

  function getTaxData() {
    return prFunction.getTaxData();
  }

  function canEditPR() {
    return prFunction.getPRData().status === 'edit';
  }

  $scope.$on('PRDataUpdated', function(){
    PRData();
  });

  function initialize() {
    $scope.datePickerTimezone = $rootScope.datePickerTimezone;

    if(!prFunction.getPRData() || !prFunction.getPRData()._id){}
      prFunction.setPRData($scope.pr);
  }

  initialize();

}

purchaseRequisitionsItemListCtrl.$inject = [
  '$scope', 'prFunction', 'purchaseRequisitionsServices', 'globalFunc', 'pathConstants', 'toastr', 'lang', '$rootScope',
  '$q', '$uibModal'
];

angular
  .module('metabuyer')
  .directive('prItemList',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        pr: '='
      },
      template: '<div ng-include="myTemplateUrl"></div>',
      controller: 'purchaseRequisitionsItemListCtrl'
    }
  })
  .controller('purchaseRequisitionsItemListCtrl', purchaseRequisitionsItemListCtrl);
