'use strict';
/**
 * @name purchaseRequisitionsMainCtrl
 *
 * @description
 *
 * Main controller for the requisitions State
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

function purchaseRequisitionsMainCtrl($scope, $state, globalFunc, $rootScope) {
  var tempState = _.cloneDeep($state.params);

  if (!$rootScope.isMobileMode) {
    $scope.tabData = [
      {
        heading: 'Draft',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'draft',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'draft',
          tab: 'all',
          cursor: null
        })
      },
      {
        heading: 'Pending Approval',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'pending',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'pending',
          tab: 'waiting',
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        })
      },
      {
        heading: 'Approved',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'approved',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'approved',
          tab: 'all',
          cursor: null
        })
      }
    ];

    $scope.tabData.more = [
      {
        heading: 'Deleted',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'deleted',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'deleted',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Withdrawn',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'withdrawn',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'withdrawn',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Starred',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'starred',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'starred',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Rejected',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'rejected',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'rejected',
          tab: null,
          cursor: null
        })
      }
    ];
  }

  if ($rootScope.isMobileMode) {
    $scope.tabData = [
      {
        heading: 'Pending Approval',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'pending',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'pending',
          tab: 'waiting',
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          cursor: null
        })
      },
      {
        heading: 'Approved',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'approved',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'approved',
          tab: 'all',
          cursor: null
        })
      },
      {
        heading: 'Deleted',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'deleted',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'deleted',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Starred',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'starred',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'starred',
          tab: null,
          cursor: null
        })
      },
      {
        heading: 'Rejected',
        route: 'main.user.purchaseRequisitions.manage',
        key: 'rejected',
        type: 'more',
        params: globalFunc.filterUrlParams(tempState, {
          status: 'rejected',
          tab: null,
          cursor: null
        })
      }
    ];
  }


  function initialize() {

    $scope.$emit('pageTitleChanged', 'Purchase Requisitions');
    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }
    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status;


      //set the default tab on refreshing
      var tempIndex = _.findIndex($scope.tabData, function(tab){return tab.key === $state.params.status.toLowerCase()});
      if(tempIndex < 0 && !$rootScope.isMobileMode) {
        tempIndex = _.findIndex($scope.tabData.more, function (tab) {
          return tab.key === $state.params.status.toLowerCase()
        });
        if(!!tempIndex)
          $scope.activatedTab = $scope.tabData.more[tempIndex];
          }
      else
        $scope.activatedTab = $scope.tabData[tempIndex];
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

    $scope.searchPlaceholder = 'Search Purchase Requisition';
    $scope.searchState = 'main.user.purchaseRequisitions.manage';
    $scope.parentState = 'main.user.purchaseRequisitions.manage';
    $scope.parentStateParams = $state.params;

  }

  if (!$rootScope.isMobileMode) {
    $scope.columns = [
      {
        id: 'check box',
        title: '',
        unsortable: true,
        unsearchable: true,
        conditional: true,
        condition: ['pending'],
        unSelectable: true
      },
      {id: 'star', label: 'Favorite', unsortable: true, unsearchable: true, unSelectable: true},
      {id: 'reference', label: 'PR#'},
      {id: 'title', label: 'Title'},
      {
        id: 'waiting_on',
        label: 'Waiting on',
        conditional: true,
        condition: ['pending'],
        unsortable: true,
        unsearchable: true
      },
      {id: 'pos', label: 'PO#', conditional: true, condition: ['approved'], unsortable: true, unsearchable: true},
      {
        id: 'grand_total',
        label: 'Amount',
        type: 'double',
        unsearchable: true
      },
      {id: 'closed_at', label: 'Approved Date', conditional: true, condition: ['approved'], unsearchable: true},
      {
        id: 'submitted_at',
        label: 'Submitted Date',
        conditional: true,
        condition: ['pending', 'approved'],
        type: 'date',
        unsearchable: true
      },
      {
        id: 'progress',
        label: 'Progress',
        conditional: true,
        condition: ['pending', 'approved'],
        unsortable: true,
        unsearchable: true
      },
      {
        id: 'needed_by_date',
        label: 'Needed By',
        type: 'date',
        unsearchable: true
      },
      {id: 'creator|display_name', label: 'Creator'},
      {id: 'requestor|display_name', label: 'Requestor'},
      {
        id: 'created_at',
        label: 'Created At',
        type: 'date',
        unsearchable: true
      },
      {
        id: 'updated_at',
        label: 'Updated At',
        type: 'date',
        unsearchable: true
      },
      {id: 'company|descr', label: 'Company'},
      {id: 'cost_center|descr', label: 'Cost Center'},
      {id: 'status', label: 'Status', unsortable: true, unsearchable: true},
      {
        id: 'withdrawn_at',
        label: 'Withdrawn date',
        conditional: true,
        condition: ['withdrawn'],
        type: 'date',
        unsearchable: true
      },
      {id: 'withdrawer|display_name', label: 'Withdrawn by', conditional: true, condition: ['withdrawn']},
      {
        id: 'deleted_at',
        label: 'Deleted Date',
        conditional: true,
        condition: ['deleted'],
        type: 'date',
        unsearchable: true
      },
      {
        id: 'delete_button',
        label: '',
        unsortable: true,
        conditional: true,
        condition: ['draft'],
        unsearchable: true,
        unSelectable: true
      }
    ];
  }
  else {
    $scope.columns = [
      {id: 'star', label: 'Favorite', unsortable: true, unsearchable: true, unSelectable: true},
      {id: 'reference', label: 'Company/PR#'},
      {id: 'title', label: 'Title'},
      {
        id: 'grand_total',
        label: 'Amount',
        type: 'double',
        unsearchable: true
      },
      {id: 'showMore', label: '', type: 'boolean'}
    ];
  }

  $scope.$on('$stateChangeStart', function (event, toState, toParams) {
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
      $scope.status = toParams.status;
    }
  });

  initialize();
}
purchaseRequisitionsMainCtrl.$inject = ['$scope', '$state', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('purchaseRequisitionsMainCtrl', purchaseRequisitionsMainCtrl);
