'use strict';

/**
 * @name tabDropDown
 *
 * @description
 *
 * A directive for the drop down in the modules
 * the Module can receive a broadcast to activate specific tab with or without transition
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2016
 */

function tabDropDownCtrl ($scope, $state, $location, globalFunc, $rootScope) {

  if (!$scope.tabs) {
    throw new Error('\'data\' attribute not defined, please check documentation for how to use this directive.');
  }

  if (!_.isArray($scope.tabs)) {
    throw new Error('\'data\' attribute must be an array of tab data with at least one tab defined.');
  }

  /**
   * activate the selected tab
   * @param key
   */
  function activate (key) {

    _.forEach($scope.tabs, function (tab) {
      tab.active = false;
      tab.active = tab.key === key;
    });
  }

  /**
   * remove the dynamic tabs addition
   */
  function cleanMoreTabs() {
    _.forEach($scope.tabs.more, function (tab) {
      _.forEach($scope.tabs, function (mainTab) {
        if (tab.key === mainTab.key) {
          $scope.tabs.pop();
          return false;
        }
      });
    });
  }

  /**
   * tabs selection
   * @param tab
   * @param noTransition
   */
  function changeTab(tab, noTransition) {

    if(!$rootScope.isMobileMode) {
      if (tab.type === 'more') {
        cleanMoreTabs();
        $scope.tabs.push(tab);
      }
    }

    if (_.isArray(tab.params)) {
      activate(tab.key);
      if(!noTransition)
        $state.go(tab.route, tab.params[0], tab.options);
    } else {
      activate(tab.key);
      if(!noTransition)
        $state.go(tab.route, tab.params, tab.options);
    }
  }

  /**
   * receives a broadcast to load the previous tab in case the module failed to load data
   */
  $scope.$on('loadPreviousTab', function(){
    if(!!previousTab)
      changeTab(previousTab, true);
  });

  /**
   * receives a broadcast to load the specific tab
   */
  $scope.$on('loadSpecificTab', function(args, data){
    if(!!data && data.tab && data.tab.key)
      changeTab(data.tab, data.noTransition);
  });


  /**
   * activate the tabs in case the params changes
   * Metabuyer has too many options within tabs, so instead of calling them separably,
   * we activate the tab according to the activation key passed
   */
  $scope.$watch(function(){
    return $location.search();
  }, function(value) {
    if (!value || !$scope.activationKey)
      return;

    var tab = globalFunc.findInArray($scope.tabs, 'key', value[$scope.activationKey], true);
    if(!tab && $scope.tabs.more)
      tab = globalFunc.findInArray($scope.tabs.more, 'key', value[$scope.activationKey], true);
    if(!tab)
      return;
    changeTab(tab, true);
  });

  /**
   * activate the tabs in case the state changes
   * we activate the tab according to the state name
   */
  $rootScope.$on('$stateChangeSuccess',
    function (event, state) {
    if(!!$scope.activationKey || !state.name)
      return;

      var tab = globalFunc.findInArray($scope.tabs, 'route', state.name, true);
      if(!tab)
        return;
      changeTab(tab, true);
    });


  function initialize() {
    previousTab = null;
    if(!$scope.tabs || $scope.tabs.length < 1)
      return;

    _.forEach($scope.tabs, function (tab) {
      tab.active = false;
    });

    if(!!$scope.activatedTab && $scope.activatedTab.key)
      changeTab($scope.activatedTab, true);
    else
      changeTab($scope.tabs[0], true);
  }

  initialize();

  $scope.changeTab = changeTab;
  var previousTab = null;
}


angular.module('metabuyer')
  .directive('tabDropDown',function () {
    return {
      restrict: 'E',
      scope: {
        tabs: '=data',
        activeKey: '=',
        activatedTab: '=?',
        activationKey: '=?'
      },
      controller: tabDropDownCtrl,
      templateUrl: function (element, attributes) {
        return attributes.templateUrl || 'ui-router-tabs-default-template.html';
      }
    };
  })
  .run(['$templateCache',
  function ($templateCache) {
    var DEFAULT_TEMPLATE = '<div>' +
      '<tabset class="tab-container">' +
      '  <tab class="tab" ng-repeat="tab in tabs track by $index" heading="{{tab.heading}}" active="tab.active" ng-click="changeTab(tab)" />' +
      '</tabset>' +
      '</div>';

    $templateCache.put('ui-router-tabs-default-template.html', DEFAULT_TEMPLATE);
  }]);
