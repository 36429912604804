'use strict';
/**
 * @name positionAssignDetailsController
 * @desc Controller for edit details of position assignment
 */
function positionAssignDetailsController(
  $scope, $uibModalInstance, searchModule, positionAssignment, toastr, isEditWindow, isEditable, data,
  positionAssignmentSingle, globalFunc, assignHistory, approvalTemplateHistory, $filter, getUsersInCompanies
) {

  var backupPositionAssignment = angular.copy(data);

  $scope.editClicked = isEditable;
  $scope.inEditMode = isEditWindow;
  $scope.data = data;
  $scope.costCenterListing = [];
  $scope.uiSelectCompanies = {};
  $scope.uiSelectCompanies.list = [];
  $scope.uiSelectCostCenter = {};
  $scope.uiSelectCostCenter.list = [];
  $scope.uiUsernameList = [];
  $scope.selectedUsername = null;
  $scope.selectedPosition = null;
  $scope.costCenterLimit = undefined;
  $scope.companyLimit = undefined;
  $scope.status = [
    {id: 0, name: "Active"},
    {id: 1, name: "Inactive"}
  ];
  $scope.tab = 1;
  $scope.limitDetail = 10;
  $scope.metaDetail = [];
  $scope.pageDetail = 0;
  $scope.uiEffectiveDate = !!data.effective_date ? new Date(data.effective_date) : null;
  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datepickerOpened = false;

  $scope.toggleTab = toggleTab;
  $scope.selectCompany = selectCompany;
  $scope.selectCostCenter = selectCostCenter;
  $scope.addPositionAssign = addPositionAssign;
  $scope.cancel = cancel;
  $scope.editPositionAssign = editPositionAssign;
  $scope.updatePositionAssign = updatePositionAssign;
  $scope.searchHistory = searchHistory;
  $scope.openDatepicker = openDatepicker;
  $scope.checkPositionIsAdhoc = checkPositionIsAdhoc;
  $scope.converToLocalDate = converToLocalDate;

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function selectCompany(action) {
    if ((action === 'assign' && $scope.uiSelectCompanies.list.length > 1) ||
      (!!$scope.uiSelectCompanies.list[$scope.uiSelectCompanies.list.length - 1] &&
      $scope.uiSelectCompanies.list[$scope.uiSelectCompanies.list.length - 1].code === 'SYSTEM')) {
      $scope.costCenterLimit = 0;
      $scope.uiSelectCostCenter.list = [];
      $scope.costCenterListing = [];
    } else if (action === 'unassign' && $scope.uiSelectCompanies.list.length <= 1 && $scope.costCenterLimit === 0) {
      $scope.costCenterLimit = undefined;
    }

    if (!!$scope.uiSelectCompanies.list[$scope.uiSelectCompanies.list.length - 1] &&
      $scope.uiSelectCompanies.list[$scope.uiSelectCompanies.list.length - 1].code === 'SYSTEM') {
      $scope.companyLimit = 0;
      $scope.uiSelectCompanies.list = [];
      $scope.uiSelectCompanies.list = [{
        code: 'SYSTEM',
        descr: 'System Wide'
      }];
    } else {
      $scope.companyLimit = undefined;
    }
    loadCostCenter();
    loadUsers();
  }

  function selectCostCenter() {
    loadUsers();
  }

  function loadUsers() {
    //stop when no cost center is selected
    if ($scope.uiSelectCostCenter.list.length === 0 && $scope.uiSelectCompanies.list.length === 0) {
      $scope.selectedUsername = null;
      $scope.uiUsernameList = [];
      return;
    }

    var params = {};
    _.forEach($scope.uiSelectCompanies.list, function (company, index) {
      var temp = 'companyCodes[' + index + ']';
      if (company.code !== 'SYSTEM') {
        params[temp] = company.code;
      }
    });

    getUsersInCompanies.get(
      params,
      function (resource) {
        $scope.uiUsernameList = (!!resource && !!resource.content && !!resource.content.data) ?
          resource.content.data : [];

        var exist = false;
        if (globalFunc.findInArray($scope.uiUsernameList, '_id', $scope.selectedUsername)) {
          exist = true;
        }
        if (!exist)
          $scope.selectedUsername = null;

      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.users = [];
      }
    );
  }

  function loadCostCenter() {

    //stop when no company is selected OR when no cost center is allowed
    if ($scope.uiSelectCompanies.list.length === 0 || $scope.costCenterLimit === 0) {
      $scope.uiSelectCostCenter.list = [];
      $scope.costCenterListing = [];
      return;
    }

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'and',
      order_by: 'descr',
      order: 1
    };

    $scope.companiesArray = [];
    _.forEach($scope.uiSelectCompanies.list, function (company, index) {
      $scope.companiesArray[index] = company.code;
    });
    params['criteria[company_code][]'] = $scope.companiesArray;

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterListing = resource.content.data;
        }
      }
    )
  }

  function checkPositionIsAdhoc(selectedPosition) {
    if (!selectedPosition) {
      return false;
    }

    var position = globalFunc.findInArray($scope.positionListing, 'id', selectedPosition);
    return (position.position_type === 'ad-hoc');
  }

  function validateBeforeCreate() {

    if (_.isEmpty($scope.uiSelectCompanies.list) || _.isEmpty($scope.selectedUsername)) {
      if (typeof $scope.selectedPosition === 'number') {
        var selectedPositionDetail = globalFunc.findInArray($scope.positionListing, 'id', $scope.selectedPosition);
        if (selectedPositionDetail.position_type === 'ad-hoc') {
          return true;
        }
      }

      toastr.error('Please fill in all the fields.');
      return false;
    }

    if (typeof $scope.selectedPosition !== 'number') {
      toastr.error('Please fill in all the fields.');
      return false;
    }

    return true;
  }

  function addPositionAssign() {
    var date = !_.isNull($scope.uiEffectiveDate) ? $filter('date')(new Date($scope.uiEffectiveDate), 'dd-MM-yyyy') : null;
    var status = _.isNull($scope.uiEffectiveDate) ? 'active' : 'inactive';

    if (!!validateBeforeCreate()) {

      var param = [];

      //if SYSTEM is selected
      if ($scope.uiSelectCompanies.list[0].code === 'SYSTEM') {
        param.push({
          position_id: $scope.selectedPosition,
          assignee_email: $scope.selectedUsername,
          organization_type: 'system',
          organization_code: 'system',
          effective_date: date,
          status: status
        })
      }
      //if cost center is needed
      else if (!_.isEmpty($scope.uiSelectCostCenter.list)) {
        _.forEach($scope.uiSelectCostCenter.list, function (cc) {
          param.push({
            position_id: $scope.selectedPosition,
            assignee_email: $scope.selectedUsername,
            organization_type: 'cost_center',
            organization_code: cc.code,
            effective_date: date,
            status: status
          })
        });
      }
      //if only company selected
      else {
        _.forEach($scope.uiSelectCompanies.list, function (company) {
          param.push({
            position_id: $scope.selectedPosition,
            assignee_email: $scope.selectedUsername,
            organization_type: 'company',
            organization_code: company.code,
            effective_date: date,
            status: status
          })
        });
      }

      positionAssignment.post(
        {
          'assignment': param
        },
        function () {
          $scope.search($scope.metaDetail['currentPageNumber']);
          cancel();
          toastr.success('New position has been successfully created.');
        },
        function (resource) {
          if (!!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
            toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
          } else if (!!resource.data.content && !!resource.data.content.message) {
            toastr.error(resource.data.content.message.join('\n'));
          } else {
            toastr.error('Failed to create new position.');
          }
        }
      )
    }

  }

  function editPositionAssign() {
    $scope.editClicked = true;
  }

  function updatePositionAssign() {
    var param = {
      position_assignment_id: data.position_assignment.id,
      assignee_id: data.assignee_id,
      assignee_name: data.assignee_name,
      effective_date: !_.isNull($scope.uiEffectiveDate) ? $filter('date')(new Date($scope.uiEffectiveDate), 'dd-MM-yyyy') : null,
      status: data.status
    }
    positionAssignmentSingle.put(
      {
        id: data.id,
        'assignment': param
      },
      function () {
        $scope.search($scope.metaDetail['currentPageNumber']);
        cancel();
        toastr.success('Position has been successfully updated.');
      },
      function (resource) {
        if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        } else if (!!resource.data.content && !!resource.data.content.message) {
          toastr.error(resource.data.content.message.join('\n'));
        } else {
          toastr.error('Failed to update selected position assignment.');
        }

        $scope.data = backupPositionAssignment;
        $scope.uiEffectiveDate = !!$scope.data.effective_date ? new Date($scope.data.effective_date) : null;
      }
    )
  }

  function populateLog(data) {
    _.forEach(data, function (historyData) {
      historyData.log = [];
      if (historyData.event === 'updated') {
        if (typeof(historyData.new_values.status) !== 'undefined' && typeof(historyData.old_values.status) !== 'undefined') {
          historyData.log.push('status : ' + (historyData.old_values.status === '0' || historyData.old_values.status === 0 ?
              'active' : 'inactive') + ' => ' + (historyData.new_values.status === '0' ? 'active' : 'inactive'));
        }
        if (typeof(historyData.new_values.effective_date) !== 'undefined' && typeof(historyData.old_values.effective_date) !== 'undefined') {
          historyData.log.push('effective date : ' +
            (_.isNull(historyData.old_values.effective_date) ? ' - ' : $filter('date')(new Date(historyData.old_values.effective_date), 'dd MMM yyyy')) +
            ' => ' +
            (_.isNull(historyData.new_values.effective_date) ? ' - ' : $filter('date')(new Date(historyData.new_values.effective_date), 'dd MMM yyyy')));
        }
      }
    })
    return data;
  }

  function processData() {
    $scope.assignHistoryData = populateLog($scope.assignHistoryData);
    $scope.metaDetail['currentPageNumber'] = $scope.assignHistoryData['meta']['current'];
    $scope.metaDetail['totalPageNumber'] = $scope.assignHistoryData['meta']['pages'];
    $scope.metaDetail['totalRec'] = $scope.assignHistoryData['meta']['count'];
    $scope.metaDetail['startRecNo'] = $scope.limitDetail * $scope.pageDetail + 1;
    $scope.metaDetail['endRecNo'] = $scope.metaDetail['totalRec'] - ((1 + $scope.metaDetail['currentPageNumber']) * $scope.limitDetail) >= 0 ?
      (1 + $scope.metaDetail['currentPageNumber']) * $scope.limitDetail :
      (($scope.metaDetail['currentPageNumber']) * $scope.limitDetail) + $scope.metaDetail['totalRec'] % $scope.limitDetail;
  }

  function searchHistory(pageDetail, callFrom) {
    var isExport = !!callFrom && callFrom === 'export' ? true : false
    $scope.pageDetail = !!pageDetail ? pageDetail : $scope.pageDetail;
    approvalTemplateHistory.get(
      {
        'sort[1][key]': 'created_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'auditable_id',
        'filter_groups[0][filters][0][value]': data.id,
        'filter_groups[0][filters][0][operator]': 'eq',
        'filter_groups[1][filters][0][key]': 'auditable_type',
        'filter_groups[1][filters][0][value]': 'Api\\Positions\\Models\\UserAssignment',
        'filter_groups[1][filters][0][operator]': 'eq',
        limit: isExport ? 0 : $scope.limitDetail,
        page: isExport ? 0 : pageDetail
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          if (isExport) {
            $scope.assignHistoryDataExport = resource.content.data;
            $scope.assignHistoryDataExport = populateLog($scope.assignHistoryDataExport);
            exportCSV();
          } else {
            $scope.assignHistoryData = resource.content.data;
            $scope.assignHistoryData['meta'] = resource.content.meta.cursor;
            processData();
          }
        }
      },
      function (resource) {
        if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        }
        else {
          toastr.error('Failed to load history.');
        }
      }
    )
  }

  function openDatepicker($event) {
    $scope.startDateOptions.minDate = new Date();
    $scope.startDateOptions.minDate.setDate($scope.startDateOptions.minDate.getDate() + 1);
    $event.preventDefault();
    $event.stopPropagation();
    $scope.datepickerOpened = true;
  }

  function exportCSV() {
    $scope.arrayData = [['Username', 'Action', 'Date', 'Changes']];
    _.forEach($scope.assignHistoryDataExport, function (data) {
      $scope.arrayData.push([
        (!!data.user && !!data.user.display_name ? data.user.display_name : ''),
        data.event,
        $filter('date')(globalFunc.convertToLocalDate(data.created_at), 'dd MMM yyyy h:mm a'),
        data.log
      ])
    });
    $scope.fileName = globalFunc.getCsvFilenameWithDate('Position Assignment - ' + $scope.data.position_assignment.position.code);
    $scope.triggerHistoryExport = true;
  }

  function converToLocalDate(date){
    return globalFunc.convertToLocalDate(date);
  }

  function cancel() {
    $uibModalInstance.close(backupPositionAssignment);
  }

  function initialize(){
    if (!!assignHistory && !!assignHistory.data) {
      $scope.assignHistoryData = assignHistory.data;
      $scope.assignHistoryData['meta'] = assignHistory.meta.cursor;
      processData();
    }
  }

  initialize();

}

positionAssignDetailsController.$inject = [
  '$scope', '$uibModalInstance', 'searchModule', 'positionAssignment', 'toastr', 'isEditWindow', 'isEditable', 'data',
  'positionAssignmentSingle', 'globalFunc', 'assignHistory', 'approvalTemplateHistory', '$filter', 'getUsersInCompanies'
];

angular
  .module('metabuyer')
  .controller('positionAssignDetailsController', positionAssignDetailsController);
