'use strict';
/**
 * @name addCostCenterCtrl
 * @desc Controller to add cost center for a company
 */
function addCostCenterCtrl(
  $scope, AddCostCenters, toastr, globalFunc, companyUsers, costCenterData, costCenterUsers
) {
  $scope.submitted = false;
  $scope.costcenter = {};
  $scope.delivery_address = {};
  $scope.costcenter.delivery_address_id = '';
  $scope.addNewAddress = false;
  $scope.billing = {};
  $scope.delivery = {};
  $scope.states = {
    MY: $scope.MYStates
  };
  $scope.duplicateAddress = {
    value: true
  };
  $scope.companyUsers = companyUsers;
  $scope.selectedUsers = [];
  $scope.costCenterUsers = costCenterUsers;
  $scope.costCenterData = costCenterData;
  $scope.isEditingMode = (!!$scope.costCenterData && !!$scope.costCenterData._id);
  $scope.followCompanyAddress = {
    billing: false,
    delivery: false
  };

  $scope.checkAddress = checkAddress;
  $scope.duplicationCheck = duplicationCheck;
  $scope.addCostCenter = addCostCenter;
  $scope.back = back;
  $scope.selectUser = selectUser;
  $scope.removeUser = removeUser;
  $scope.removeAllSelectedUsers = removeAllSelectedUsers;
  $scope.addAllCompanyUsers = addAllCompanyUsers;
  $scope.saveCostCenter = saveCostCenter;

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model, field, value, checker) {
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  /**
   * Validation before adding cost center
   *
   * @returns {boolean}
   */
  function validateAddCostCenter() {
    $scope.selectedUserIds = _.chain($scope.selectedUsers).pluck('_id').unique().value();

    if (!$scope.costcenter)
      return false;

    if (!$scope.costcenter.descr || !$scope.costcenter.code || !$scope.costcenter.billingAddressId ||
      !$scope.costcenter.deliveryAddressId) {
      globalFunc.objectErrorMessage('Please fill in the required fields.');
      return false;
    }

    if (!!$scope.codeCheck) {
      globalFunc.objectErrorMessage('The code is already taken.');
      return false;
    }

    return true;
  }

  /**
   * Validation for editing cost center
   *
   * @returns {boolean}
   */
  function validateEditedCostCenter() {
    $scope.selectedUserIds = _.chain($scope.selectedUsers).pluck('_id').unique().value();

    if (!$scope.costCenterData || !$scope.costCenterData.code) {
      globalFunc.objectErrorMessage('Cost center code is required.');
      return false;
    }

    return true;
  }

  /**
   * Send request to add the new cost center
   */
  function addCostCenter() {
    $scope.submitted = true;

    var tempBillingArray = [];
    tempBillingArray.push($scope.costcenter.billingAddressId);
    var tempDeliveryArray = [$scope.costcenter.deliveryAddressId];
    if (!!validateAddCostCenter()) {
      var submit_data = {
        descr: $scope.costcenter.descr,
        code: $scope.costcenter.code,
        company_name: $scope.companyDetails.descr,
        company_code: $scope.companyDetails.code,
        billing_addresses_ids: tempBillingArray,
        delivery_addresses_ids: tempDeliveryArray,
        user_ids: $scope.selectedUserIds
      };

      AddCostCenters.post(
        {},
        submit_data,
        function (resource) {
          var temp = {};
          temp._id = resource._id;
          temp.descr = $scope.costcenter.descr;
          temp.code = $scope.costcenter.code;
          $scope.companyDetails.costCenters.push(temp);
          toastr.success('Cost Center added successfully.');
          back();
        },
        function (error) {
          $scope.submitted = false;
          globalFunc.objectErrorMessage(error)
        }
      );
    }
  }

  function checkAddress(type, value) {
    if (type === 'billing') {
      if (value) {
        $scope.costcenter.billingAddressId = $scope.companyDetails.corresponding_address._id;
      } else {
        $scope.costcenter.billingAddressId = null;
      }
    } else {
      if (value) {
        $scope.costcenter.deliveryAddressId = $scope.costcenter.billingAddressId;
      } else {
        $scope.costcenter.deliveryAddressId = null;
      }
    }
  }

  /**
   * Navigate back to previous page
   */
  function back(){
    window.history.back();
  }

  /**
   * Add user into selected user list
   *
   * @param {object} selectedUser User to be added
   */
  function selectUser(selectedUser) {
    $scope.companyUsers = globalFunc.removeValueFromArray($scope.companyUsers, '_id', selectedUser._id);
    $scope.selectedUsers.push(selectedUser);
  }

  /**
   * Remove user from selected user list
   *
   * @param {object} selectedUser User to be removed
   */
  function removeUser(selectedUser) {
    $scope.selectedUsers = globalFunc.removeValueFromArray($scope.selectedUsers, '_id', selectedUser._id);
    $scope.companyUsers.push(selectedUser);
  }

  /**
   * Remove all company user(s) from selected user list
   */
  function removeAllSelectedUsers() {
    swal({
      title: 'Confirm remove all selected user(s)?',
      text: 'All selected user(s) will be deselected.',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      $scope.companyUsers = $scope.companyUsers.concat($scope.selectedUsers);
      $scope.selectedUsers = [];
      toastr.success('All selected user(s) was deselected.');
    });
  }

  /**
   * Add all company user(s) to the selected user list
   */
  function addAllCompanyUsers() {
    swal({
      title: 'Confirm add all selected user(s)?',
      text: 'All company user(s) will be selected.',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function () {
      $scope.selectedUsers = $scope.selectedUsers.concat($scope.companyUsers);
      $scope.companyUsers = [];
      toastr.success('All selected user(s) was selected.');
    });
  }

  /**
   * Send request to update the cost center
   */
  function saveCostCenter() {
    if (!!validateEditedCostCenter()) {
      var submitData = {
        code: $scope.costCenterData.code,
        user_ids: $scope.selectedUserIds
      };

      AddCostCenters.put(
        {},
        submitData,
        function () {
          toastr.success('Changes saved successfully.');
        },
        function (error) {
          globalFunc.objectErrorMessage(error)
        }
      );
    }
  }

  /**
   * Function to be run on page load
   */
  function initialize() {
    // if is editing cost center
    if (!!$scope.isEditingMode) {
      $scope.costcenter = $scope.costCenterData;
      $scope.selectedUsers = $scope.costCenterUsers;

      _.forEach($scope.selectedUsers, function (user) {
        $scope.companyUsers = globalFunc.removeValueFromArray($scope.companyUsers, '_id', user._id);
      });
    }
  }

  initialize();
}

addCostCenterCtrl.$inject = [
  '$scope', 'AddCostCenters', 'toastr', 'globalFunc', 'companyUsers', 'costCenterData', 'costCenterUsers'
];

angular
  .module('metabuyer')
  .controller('addCostCenterCtrl', addCostCenterCtrl);
