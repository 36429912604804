'use strict';

function newSupplierCtrl(
  $scope, $q, toastr, taxes, draftSupplier, paymentMethods, paymentTerms, $stateParams, $state, currencies, HighlightId,
  addressList, globalFunc, $http, $filter, pathConstants, searchModule, listChild, supplierGlobalFunc,
  attachmentDescription, ngIntlTelInput
) {

  var supplierAttachment  = [];

  $scope.checkAddress = false;
  $scope.supplier = {};
  $scope.approvals = [];
  $scope.taxes = taxes;
  $scope.paymentMethods = paymentMethods.data;
  $scope.paymentTerms = paymentTerms.data;
  $scope.selectedContactPersons = [];
  $scope.freightTerms = [];
  $scope.supplier.corresponding_address_id = '';
  $scope.currencies = currencies;
  $scope.address = {};
  $scope.closeTreeSearchPrimary = false;
  $scope.closeTreeSearchSecondary = false;
  $scope.commodityLevel = [3,4];
  $scope.supplier.additionalFile = [];
  $scope.status_reason = '';

  $scope.backToListingSupplier = backToListingSupplier;
  $scope.saveNewSupplier = saveNewSupplier;
  $scope.getPaidUpCategory = getPaidUpCategory;
  $scope.onSelectedTax = onSelectedTax;
  $scope.onSelectedBusinessEntity = onSelectedBusinessEntity;
  $scope.onSelectedPaymentTerm = onSelectedPaymentTerm;
  $scope.onSelectedCurrency = onSelectedCurrency;
  $scope.onSelectedPaymentMethod = onSelectedPaymentMethod;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.onAddedContact = onAddedContact;
  $scope.removeContact = removeContact;
  $scope.saveAddress = saveAddress;
  $scope.addNewSupplier = addNewSupplier;
  $scope.duplicationCheck = duplicationCheck;
  $scope.validateNumbers = validateNumbers;
  $scope.settingContacts = settingContacts;
  $scope.selectedBumiputra = selectedBumiputra;
  $scope.validatePrimaryBusinessNature = validatePrimaryBusinessNature;
  $scope.validateSecondaryBusinessNature = validateSecondaryBusinessNature;
  $scope.onSelectedState = onSelectedState;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.removeSecondaryCommodity = removeSecondaryCommodity;
  $scope.searchPrimaryCommodityCode = searchPrimaryCommodityCode;
  $scope.searchSecondaryCommodityCode = searchSecondaryCommodityCode;
  $scope.openChildCommodityCodePrimary = openChildCommodityCodePrimary;
  $scope.openChildCommodityCodeSecondary = openChildCommodityCodeSecondary;
  $scope.checkHyphenFirstCharacter = checkHyphenFirstCharacter;

  function checkHyphenFirstCharacter(string, field) {
    if (!!string) {
      $scope.descrFirstCharHyphen = supplierGlobalFunc.checkHyphenFirstCharacter(string);
    }

    return $scope.descrFirstCharHyphen;
  }

  function searchPrimaryCommodityCode(keyword){
    if(!!keyword && keyword.length > 1){
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.primaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.primaryCommodityCodes = [];
        });

      return $scope.primaryCommodityCodes;
    }
  }

  function searchSecondaryCommodityCode(keyword){
    if(!!keyword && keyword.length > 1){
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.secondaryCommodityCodes = resource.content.data;
        }, function () {
          $scope.secondaryCommodityCodes = [];
        });

      return $scope.secondaryCommodityCodes;
    }
  }

  /**
   * Get child's data for primary commodity
   * @param cc
   */
  function openChildCommodityCodePrimary(cc){
    listChild.get({
        code: cc
      },
      function(resource){
        if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0){
          $scope.primaryCommodityCodes = resource.content.data;
        }
        else{
          toastr.error("There are no child on this commodity code");
        }
      },
      function(){
        toastr.error("Failed to get commodity codes data");
      })
  }

  /**
   * Get child's data for secondary commodity
   * @param cc
   */
  function openChildCommodityCodeSecondary(cc){
    listChild.get({
        code: cc
      },
      function(resource){
        if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0){
          $scope.secondaryCommodityCodes = resource.content.data;
        }
        else{
          toastr.error("There are no child on this commodity code");
        }
      },
      function(){
        toastr.error("Failed to get commodity codes data");
      })
  }

  // The standard list for Business Nature and Business Entity
  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING',  value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY',  value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR',  value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER',  value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR',  value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER',  value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING',  value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER',  value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER',  value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR',  value: 11},
    {descr: 'Trading', shortDescr: 'TRADING',  value: 12},
    {descr: 'Other', shortDescr: 'OTHERS',  value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Berhad (Public Limited)', value: '1'},
    {descr: 'Sendirian Berhad (Private Limited)', value: '2'},
    {descr: 'Sole Proprietor & Enterprise', value: '3'},
    {descr: 'Partnership', value: '4'}
  ];

  $scope.poReceive = [
    {descr: 'System', value: 0},
    {descr: 'System and Email', value: 1},
    {descr: 'System, Email, and Fax', value: 2},
    {descr: 'System, Email, Fax, and Post', value: 3}
  ];

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];

  $scope.selection = {
    poMethod: {}
  };

  $scope.methodSelected = false;

  function selectedBumiputra(value){
    $scope.supplier.bumiputra_status = value;
    $scope.noBumiStatus = false;
  }

  function backToListingSupplier() {
    $state.go('main.user.suppliers.manage', {status: "pending"});
  }

  //to push a N/A field for taxes
  //var empty = {_id: null, code: 'N/A'};
  //$scope.taxes.unshift(empty);

  function getPaidUpCategory(){
    $scope.paidUpCategoryDescr = supplierGlobalFunc.getPaidUpCategory($scope.supplier.paid_up_capital_amount);
  }

  function onSelectedTax(tax){
    $scope.supplier.tax_id = tax._id;
    if(tax.code === 'NR0 - No Tax'){
      $scope.supplier.gst_code = undefined;
    }
  }

  function onSelectedBusinessEntity(business_entity){
    $scope.supplier.business_entity = business_entity.value;
  }

  function validatePrimaryBusinessNature(primary){
    $scope.primaryBN = primary.value;
    if(angular.isDefined($scope.secondaryBN)){
      if(($scope.secondaryBN === primary.value) && primary.value != 0){
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      }
      else{
        $scope.validatedBN = false;
      }
    }
  }

  function validateSecondaryBusinessNature(secondary){
    $scope.secondaryBN = secondary.value;
    if(angular.isDefined($scope.primaryBN)){
      if(($scope.primaryBN === secondary.value) && secondary.value != 0){
        toastr.error('Primary business nature cannot be the same as secondary business nature');
        $scope.validatedBN = true;
      }
      else{
        $scope.validatedBN = false;
      }
    }
  }


  function onSelectedPaymentTerm(payment_term){
    $scope.supplier.pay_terms_code = payment_term.code;
  }


  function onSelectedCurrency(currency){
    $scope.supplier.currency_code = currency.code;
  }

  function onSelectedPaymentMethod(payment_method){
    $scope.supplier.pay_method_code = payment_method.code;
  }

  function onUpdatedContact(contact){
    var index = _.findIndex($scope.selectedContactPersons, function(item){
      return item._id == contact._id;
    });

    $scope.selectedContactPersons.splice(index, 1, contact);
  }

  function onAddedContact(contact){
    $scope.selectedContactPersons.splice($scope.selectedContactPersons.length, 0, contact);
  }

  function removeContact(contact){
    var index = _.findIndex($scope.selectedContactPersons, function(item){
      return item._id == contact._id;
    });

    $scope.selectedContactPersons.splice(index, 1);
  }

  function onSelectedCountry(country){
    $scope.address.state = undefined;
    $scope.address.country = country.descr;
    $scope.selectedMalaysia = (country.descr === "Malaysia");
  }

  function onSelectedState(state){
      $scope.address.state = state.descr;
  }

  /**
   * Save Address before create draft or submit
   * @param action
   */
  function saveAddress(action){

    /**
     * Added phone and fax into address, taken from supplier phone and fax
     */
    $scope.address.phone = $scope.supplier.phone;
    $scope.address.fax = $scope.supplier.fax;

    addressList.post($scope.address,
      function(resource){

        if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id){
          $scope.supplier.corresponding_address_id = resource.content.data._id;

          //save supplier if success
          saveNewSupplier(action);
        }

      }
      , function(error){
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * @function To set first contact in additional contact as primary, then splice from the array
   */
  function settingContacts(){
    var temp_contact_id = [];
    var temp_contact_object = [];

    /**
     * Loop each selected contact person
     * Push the ids into temp_contact_id array containing ids to pass to payload
     * Push the objects into temp_contact_object array containing the contact objects for display
     */
    _.forEach($scope.selectedContactPersons, function(item){
      if(angular.isDefined(item._id)){
        temp_contact_id.push(item._id);
        temp_contact_object.push(item);
      }
      else{
        temp_contact_id.push(item);
      }
    });

    $scope.supplier.additional_contacts_ids = temp_contact_id;
    $scope.supplier.additional_contacts = temp_contact_object;

    $scope.selectedContactPersons = $scope.supplier.additional_contacts;

    /**
      * Sets the first contact as primary contact and splices the contact from additional contacts
      * to place into contact_person_id to pass to payload
      */
    $scope.supplier.contact_person_id = temp_contact_id[0];
    $scope.supplier.additional_contacts_ids.splice(0 , 1);

  }

  function saveNewSupplier(action) {
    settingContacts();

    var submitData = {};

    // Add the data only if there is a data on it
    if (!!$scope.supplier.additional_contacts_ids)
      submitData['additional_contacts_ids'] = $scope.supplier.additional_contacts_ids;

    if (!!$scope.supplier.business_entity)
      submitData['business_entity'] = $scope.supplier.business_entity;

    if (!!$scope.supplier.select_primary_business_nature && !!$scope.supplier.select_primary_business_nature.shortDescr)
      submitData['primary_business_nature'] = $scope.supplier.select_primary_business_nature.shortDescr;

    if (!!$scope.supplier.select_secondary_business_nature && !!$scope.supplier.select_secondary_business_nature.shortDescr)
      submitData['secondary_business_nature'] = $scope.supplier.select_secondary_business_nature.shortDescr;

    if (!!$scope.supplier.paid_up_capital_amount)
      submitData['paid_up_value'] = $scope.supplier.paid_up_capital_amount;

    if (angular.isDefined($scope.supplier.bumiputra_status))
      submitData['bumi_status'] = $scope.supplier.bumiputra_status;

    if (!!$scope.supplier.first_primary_commodity && !!$scope.supplier.first_primary_commodity.code)
      submitData['primary_commodity_code'] = $scope.supplier.first_primary_commodity.code;

    if (!!$scope.supplier.contact_person_id)
      submitData['contact_person_id'] = $scope.supplier.contact_person_id;

    if (!!$scope.supplier.corresponding_address_id)
      submitData['corresponding_address_id'] = $scope.supplier.corresponding_address_id;

    if (!!$scope.supplier.selectedCurrency && !!$scope.supplier.selectedCurrency.code)
      submitData['currency_code'] = $scope.supplier.selectedCurrency.code;

    if (!!$scope.supplier.descr)
      submitData['descr'] = $scope.supplier.descr;

    if (!!$scope.supplier.selectedPaymentMethod && !!$scope.supplier.selectedPaymentMethod.code)
      submitData['pay_method_code'] = $scope.supplier.selectedPaymentMethod.code;

    if (!!$scope.supplier.selectedPaymentTerms && !!$scope.supplier.selectedPaymentTerms.code)
      submitData['pay_terms_code'] = $scope.supplier.selectedPaymentTerms.code;

    if (!!$scope.supplier.email)
      submitData['email'] = $scope.supplier.email;

    if (!!$scope.supplier.fax)
      submitData['fax'] = $scope.supplier.fax;

    if (!!$scope.supplier.phone)
      submitData['phone'] = $scope.supplier.phone;

    if (!!$scope.receive_via_system)
      submitData['receive_via_system'] = $scope.receive_via_system;

    if (!!$scope.receive_via_email)
      submitData['receive_via_email'] = $scope.receive_via_email;

    if (!!$scope.receive_via_fax)
      submitData['receive_via_fax'] = $scope.receive_via_fax;

    if (!!$scope.receive_via_post)
      submitData['receive_via_post'] = $scope.receive_via_post;

    if (!!$scope.supplier.reg_no)
      submitData['reg_no'] = $scope.supplier.reg_no;

    if (!!$scope.supplier.gst_code)
      submitData['gst_code'] = $scope.supplier.gst_code;

    if (!!$scope.supplier.tax_id)
      submitData['tax_id'] = $scope.supplier.tax_id;

    if (!!$scope.supplier.branches)
      submitData['branches'] = $scope.supplier.branches;

    submitData['is_global_supplier'] = $scope.supplier.is_global_supplier.toString();
    submitData['status_reason'] = $scope.status_reason;

    if(!!($scope.supplier.first_secondary_commodity)){
      submitData.secondary_commodity_code =  $scope.supplier.first_secondary_commodity.code;
    }

    if (!!$scope.supplier.bu_status && !!$scope.supplier.bu_status.length && !$scope.supplier.is_global_supplier)
      submitData.bu_status = supplierGlobalFunc.prepareSupplierGroup($scope.supplier.bu_status);
    else
      submitData.bu_status = {};

    if(!!$scope.supplier.additionalFile && !!$scope.supplier.additionalFile.length)
      prepareAttachmentsDetails(submitData, action, 0);
    else
      createDraftSupplier(submitData, action);
  }

  function submitSupplier(supplierId) {
    draftSupplier.put({
      id: supplierId,
      action: 'submit'
    }, function() {
      toastr.success('The supplier was Submitted');
      HighlightId.setId(supplierId);
      $state.go('main.user.suppliers.manage', { status: 'pending' });
    }, function(error) {
      globalFunc.objectErrorMessage(error);
      supplierAttachment = [];
      $state.go('main.user.suppliers.detail', { id: supplierId });
    });
  }

  /**
   * prepare attachment details for the payload
   * @param submitData
   * @param action
   * @param index (attachment index)
   */
  function prepareAttachmentsDetails(submitData, action, index){
    uploadAttachment($scope.supplier.additionalFile[index].attachment_detail.file)
      .then(
      function (resource) {
        if (!!resource && !!resource.data && !!resource.data.content && !!resource.data.content.data) {
          var params = {
            _id: null,
            attachment_category_id: $scope.supplier.additionalFile[index].attachment_category._id,
            attachment_id: resource.data.content.data._id
          };
          params = globalFunc.reformatArrayToObject(
            $scope.supplier.additionalFile[index].description_array, 'name', 'value', params
          );
          supplierAttachment.push(params);
        }

        if (!!$scope.supplier.additionalFile[++index])
          prepareAttachmentsDetails(submitData, action, index);
        else {
          submitData.attachments_descriptions = supplierAttachment;
          createDraftSupplier(submitData, action);
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        supplierAttachment = [];
      }
    );
  }

  function createDraftSupplier(submitData, action) {
    draftSupplier.post(
      {},
      submitData,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.status_reason = '';
          if (action === 'draft') {
            toastr.success('The supplier was added successfully');
            if (!!resource && !!resource.content && !!resource.content.data)
              HighlightId.setId(resource.content.data._id);

            // $scope.approvals = resource.content.data.flow;
            $state.go("main.user.suppliers.manage", {status: 'draft'});
          }
          else
            submitSupplier(resource.content.data._id);
        }
      },
      function (err) {
        globalFunc.objectErrorMessage(err);
        supplierAttachment = [];
        $scope.status_reason = '';
      }
    );
  }

  function uploadAttachment(attachmentFile) {
    var fd = new FormData();
    fd.append("file", attachmentFile._file);
    return $http.post(
      pathConstants.apiUrls.attachments.reference, fd, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      }
    );
  }

  function submitSupplierValidation() {
    if (angular.isUndefined($scope.supplier.bumiputra_status)) {
      $scope.noBumiStatus = true;
      return false;
    }

    if ($scope.validatedBN === true) {
      return false;
    }

    if (checkHyphenFirstCharacter($scope.updateDescr, 'descr')) {
      return false;
    }

//    if($scope.validatedCommodity === true){
//      return false;
//    }

    if (!$scope.supplier.descr || !$scope.supplier.descr.length || $scope.descrCheck) {
      return false;
    }

    //if(!$scope.supplier.code || !$scope.supplier.code.length){
    //  return false;
    //}

    if (!$scope.supplier.reg_no || !$scope.supplier.reg_no.length || $scope.rocCheck) {
      return false;
    }

    /**
     * check if tax selected is not NR, then validate gst code exist and gst duplicate
     */
    if ((!$scope.supplier.gst_code &&
        (angular.isDefined($scope.supplier.selectedTax) && $scope.supplier.selectedTax.code != 'NR0 - No Tax')) ||
      $scope.gstCheck) {
      return false;
    }

    /**
     * check if tax selected
     */
    if (!$scope.supplier.selectedTax) {
      return false;
    }

    if (!$scope.supplier.first_primary_commodity) {
      return false;
    }

    if (!$scope.supplier.select_primary_business_nature) {
      return false;
    }

    if (!$scope.receive_via_email && !$scope.receive_via_fax && !$scope.receive_via_post) {
      return false;
    }

    if (!$scope.supplier.select_business_entity) {
      return false;
    }

    if (!$scope.supplier.selectedPaymentTerms) {
      return false;
    }

    if (!$scope.supplier.selectedCurrency) {
      return false;
    }

    if (!$scope.isValidPhoneNo) {
      return false;
    }

    if (!$scope.isValidFaxNo) {
      return false;
    }

    if (!$scope.supplier.phone || !$scope.supplier.phone.length) {
      return false;
    }

    if (!!$scope.receive_via_email) {
      if (!$scope.supplier.email || !$scope.supplier.email.length) {
        return false;
      }
    }

    if (!!$scope.receive_via_fax) {
      if (!$scope.supplier.fax || !$scope.supplier.fax.length) {
        return false;
      }
    }

    if (!$scope.address || !$scope.address.line1 || !$scope.address.city || !$scope.address.state ||
      !$scope.address.country || !$scope.address.postal_code) {
      return false;
    }

    if (!$scope.selectedContactPersons || !$scope.selectedContactPersons.length) {
      return false;
    }

    if (!$scope.supplier.is_global_supplier &&
      (!$scope.supplier.bu_status || (!!$scope.supplier.bu_status && !$scope.supplier.bu_status.length))) {
      return false;
    }

    return true;
  }

  function draftSupplierValidation() {
    if (!$scope.supplier.descr || !!$scope.descrCheck)
      return false;

    if (!$scope.supplier.reg_no || !!$scope.rocCheck)
      return false;

    return true;
  }

  function paymentTermValidation() {
    var validPaymentTerm = false;
    if (!!$scope.supplier.selectedPaymentTerms && !!$scope.supplier.selectedPaymentTerms.code) {
      if(globalFunc.findInArray(paymentTerms.data,'code',$scope.supplier.selectedPaymentTerms.code)) {
        validPaymentTerm = true;
      }
    }
    return validPaymentTerm;
  }

  function paymentMethodValidation() {
    var validPaymentMethod = false;
    if (!!$scope.supplier.selectedPaymentMethod && !!$scope.supplier.selectedPaymentMethod.code) {
      if(globalFunc.findInArray(paymentMethods.data,'code',$scope.supplier.selectedPaymentMethod.code)) {
        validPaymentMethod = true;
      }
    }
    return validPaymentMethod;
  }

  /**
   *
   * @param action
   * draft = to save new supplier as draft
   * submit = to save new supplier as draft then submit
   */
  function addNewSupplier(action){
    $scope.saveAs = action;

    if(action === 'submit' && submitSupplierValidation() === false){
      toastr.error('Please complete all required fields before proceeding');
      return;
    }

    // validation for draft
    if (action === 'draft' && !draftSupplierValidation()) {
      toastr.error('Please complete all required fields before proceeding');
      return;
    }

    if (!paymentMethodValidation()) {
      toastr.error('Invalid payment method.');
      return;
    }

    if (!paymentTermValidation()) {
      toastr.error('Invalid payment term.');
      return;
    }

    // show swal only when submit
    if (action === 'draft') {
      if(!!$scope.address && !!$scope.address.line1)
        saveAddress(action);
      else
        saveNewSupplier(action);
    } else {
      swal(
        {
          title: 'Confirm submit supplier?',
          text: 'This supplier will be submitted by you. Please state a reason.',
          showCancelButton: true,
          type: 'input',
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true,
          inputType: 'text',
          inputPlaceholder: 'Write a reason'
        },
        function (input) {
          if (input !== false) {
            $scope.status_reason = input || '';

            if(!!$scope.address && !!$scope.address.line1)
              saveAddress(action);
            else
              saveNewSupplier(action);
          }
        }
      );
    }
  }

  function removeSecondaryCommodity() {
    if (!!$scope.supplier) {
      $scope.supplier.first_secondary_commodity = null;
      $scope.secondaryCommodityKeyword = null;
    }
  }

  //Initialized values for B. nature, B. entity, Payment Terms and Payment Methods
  function initialize(){
    $scope.paymentMethods = supplierGlobalFunc.filterInactiveCode(paymentMethods.data);
    $scope.paymentTerms = supplierGlobalFunc.filterInactiveCode(paymentTerms.data);
    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();
    $scope.supplier = {
      //selectedPaymentMethod             : globalFunc.findInArray($scope.paymentMethods, 'code', 'NA'),
      selectedCurrency                  : globalFunc.findInArray($scope.currencies, 'code', 'MYR')
    };

    $scope.receive_via_system = true;

    $scope.supplier.currency_code = !!$scope.supplier.selectedCurrency ? $scope.supplier.selectedCurrency.code : '';
    $scope.supplier.bu_status = [];
    $scope.supplier.is_global_supplier = true;
    $scope.supplier.branches = [];
    $scope.firstCharHyphen = false;
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
  }

  initialize();


  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model, field, value, checker) {
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'rocCheckTrue':
          $scope.rocCheck = true;
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'gstCheckTrue':
          $scope.gstCheck = true;
          break;
        case 'gstCheckFalse':
          $scope.gstCheck = false;
          break;
        case 'supplierDescrCheckTrue':
          $scope.descrCheck = true;
          break;
        case 'supplierDescrCheckFalse':
          $scope.descrCheck = false;
          break;
      }
    });
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }
}

newSupplierCtrl.$inject = [
  '$scope', '$q', 'toastr', 'taxes', 'draftSupplier', 'paymentMethods', 'paymentTerms', '$stateParams', '$state',
  'currencies', 'HighlightId', 'addressList', 'globalFunc', '$http', '$filter', 'pathConstants',
  'searchModule', 'listChild', 'supplierGlobalFunc', 'attachmentDescription', 'ngIntlTelInput'
];

angular.module('metabuyer')
  .controller('newSupplierCtrl', newSupplierCtrl);
