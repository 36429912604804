'use strict';
angular.module('metabuyer')
  .directive('supplierNote', function()  {
    return {
      restrict: 'E',
      template: '<div ng-include="template"></div>',
      scope: {
        prid: '=',
        editable: '=',
        status: '=',
        items: '='
      },
      controller: function ($scope, toastr, userImage, supplierNote, prSupplier, prFunction, purchaseRequisitionsServices,
                            globalFunc, $rootScope) {
        $scope.suppliersList = [];
        $scope.supplierId = [];
        $scope.selectedSuppliers = [];
        $scope.notes = [];
        $scope.noteMessage = '';
        $scope.messageExists = false;
        var backupNote = '';
        var backupSuppliers = [];
        $scope.extraSetting = {
          showCheckAll: false
        };
        $scope.textSetting = {
          selectionCount: ' selected',
          buttonDefaultText: 'Select Supplier(s)',
          dynamicButtonTextSuffix: ' selected'
        };

        $scope.submitted = submitted;

        /**
         * To get the supplier list in the PR
         */
        function getListSuppliers() {
          return prFunction.getPRSuppliers();
        }

        /**
         * adding a new Note
         * @param noteMessage
         * @param suppliers
         */
        function addNewNote(noteMessage, suppliers) {
          purchaseRequisitionsServices.addSupplierNote({
            id: prFunction.getPRData()._id
          }, {
            suppliers_ids: suppliers,
            note: noteMessage
          }, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prFunction.setPRData(resource.content.data);
            }
            $rootScope.$broadcast('refreshComments');
            initialize();
          }, function (error) {
            if (error.content.error !== 'Supplier Ids should be set') {
              globalFunc.objectErrorMessage(error);
            }
          });
        }

        /**
         * updating/removing old note
         * @param noteMessage
         * @param suppliers
         */
        function updateNote(noteMessage, suppliers) {
          purchaseRequisitionsServices.updateSupplierNote({
            id: prFunction.getPRData()._id
          }, {
            note_id: 0,
            suppliers_ids: suppliers,
            note: noteMessage
          }, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prFunction.setPRData(resource.content.data);
            }
            $rootScope.$broadcast('refreshComments');
            initialize();
          }, function (error) {
            if (error.content.error !== 'Supplier Ids should be set') {
              globalFunc.objectErrorMessage(error);
            }
          });
        }

        /**
         * validating the submission
         * @param noteMessage
         */
        function submitted(noteMessage) {
          var suppliers = reformatSupplierId();
          if (validate(noteMessage)) {
            if (!backupNote) {
              addNewNote(noteMessage, suppliers);
            } else {
              updateNote(noteMessage, suppliers);
            }
          }
        }

        /**
         * checking multiple conditions for vaildation
         * @param noteMessage
         * @returns {boolean}
         */
        function validate(noteMessage) {
          /**
           * no message was typed
           */
          if (!noteMessage || noteMessage === '')
            return false;

          /**
           * no Old message exists
           */
          if (backupNote !== noteMessage) {
            return true;
          }

          /**
           * no supplier is selected yet
           */
          if (!$scope.selectedSuppliers || $scope.selectedSuppliers.length < 1) {
            return false;
          }


          /**
           * the amount of suppliers changed that the note written for
           */
          if ($scope.selectedSuppliers.length !== backupSuppliers.length) {
            return true;
          }

          /**
           * the suppliers changed
           */
          for (var i in $scope.selectedSuppliers) {
            if ($scope.selectedSuppliers[i]._id !== backupSuppliers[i]._id) {
              return true;
            }
          }
          return false;
        }

        /**
         * list of events for the dropdown lists
         * @type {{onItemSelect: Function, onItemDeselect: Function}}
         */
        $scope.noteEvents = {
          onItemSelect: function () {
            submitted($scope.noteMessage);
          },
          onItemDeselect: function (supplier) {
            /**
             * in case all previous note, had suppliers being deselected
             */
            if (!$scope.selectedSuppliers.length && !!$scope.noteMessage) {
              //add a warning
              swal({
                title: 'Delete note',
                text: 'Are you sure you want to delete the note to supplier?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                closeOnConfirm: true,
                closeOnCancel: true
              }, function (isConfirm) {
                if (isConfirm) {
                  $scope.noteMessage = '';
                  updateNote('', []);
                } else {
                  $scope.selectedSuppliers.push(globalFunc.findInArray($scope.suppliersList, 'id', supplier.id));
                }
              });
            } else {
              submitted($scope.noteMessage);
            }
          }
        };

        /**
         * getting the list of suppliers to push the Note too
         * @returns {Array}
         */
        function reformatSupplierId() {
          var suppliers = [];
          _.forEach($scope.selectedSuppliers, function (supplier) {
            /**
             * check if the supplier id is still valid and an item is for that supplier is still there
             */
            if(globalFunc.findInArray(getListSuppliers(), 'id', supplier.id)) {
              suppliers.push(supplier.id);
            }
          });

          return suppliers;
        }

        // To update selected supplier list on item changed/deleted
        function updateSelectedSupplier() {
          $scope.selectedSuppliers = _.intersection($scope.selectedSuppliers, $scope.suppliersList);
        }

        /**
         * init function to load the list of suppliers Then the notes to prepare the drop-down list
         */
        function initialize() {
          $scope.suppliersList = getListSuppliers();
          $scope.template = $rootScope.isMobileMode ? 'components/supplierNotes/supplier-note-mobile.html' :
            'components/supplierNotes/supplier-note.html';
          /**
           * setting the note as well as the suppliers selected
           */
          if (!!prFunction.getPRData().notes_to_suppliers && !!prFunction.getPRData().notes_to_suppliers[0]) {

            /**
             * preparing the data for display
             * @type {*}
             */
            $scope.noteMessage = prFunction.getPRData().notes_to_suppliers[0].note;
            $scope.selectedSuppliers = [];
            _.forEach(prFunction.getPRData().notes_to_suppliers[0].suppliers_ids, function (supplierId) {
              $scope.selectedSuppliers.push(globalFunc.findInArray($scope.suppliersList, 'id', supplierId) || []);
            });

            /**
             * storing back up data for submitting
             * @type {*}
             */
            backupSuppliers = _.cloneDeep($scope.selectedSuppliers);
            backupNote = _.cloneDeep($scope.noteMessage);
          } else {
            backupSuppliers = null;
            backupNote = null;
          }
        }

        initialize();


        $scope.$watch('items', function () {
          initialize();
          updateSelectedSupplier();
        });
      }
    }
  });
