'use strict';

//TODO: reformat the file to standard

angular.module('metabuyer')
  .controller('AddressBookCtrl', function ($scope, $uibModalInstance, toastr) {
    $scope.column = {
      columns: [
        {id: 'address_line_1', label: 'Address Line 1', visible: true},
        {id: 'address_line_2', label: 'Address Line 2', visible: true},
        {id: 'address_line_3', label: 'Address Line 3', visible: true},
        {id: 'postal_code'   , label: 'Postcode'      , visible: true},
        {id: 'city_state'    , label: 'City, State'   , visible: true},
        {id: 'phone'         , label: 'Phone'         , visible: true},
        {id: 'fax'           , label: 'Fax'           , visible: true}
      ],
      selectedColumns: [],
      buttonText: {buttonDefaultText: 'Columns'},
      setting: {
        buttonClasses: "btn btn-white",
        smartButtonMaxItems: 3,
        showCheckAll: false,
        showUncheckAll: false
      },
      events: {
        onItemSelect: function (item) {
          $scope.column.saveSelectedColumn($scope.column.selectedColumns);
        },
        onItemDeselect: function (item) {
          $scope.column.saveSelectedColumn($scope.column.selectedColumns);
        }
      },
      showColumn: function (name) {
        for (var index = 0; index < $scope.column.selectedColumns.length; ++index) {
          var item = $scope.column.selectedColumns[index];
          if (!!item && item.id == name) {
            return true;
          }
        }
        return false
      },
      saveSelectedColumn: function (columns) {
        window.localStorage.setItem("address-book-selected_columns", JSON.stringify(_.map(columns, function (item) {
          return item.id || item
        })));
      },
      loadSelectedColumn: function () {
        return JSON.parse(window.localStorage.getItem("address-book-selected_columns")) || [];
      }
    };

    // Apply selected column
    var selectedColumn = $scope.column.loadSelectedColumn();
    if (selectedColumn.length == 0) { // Default column
      for (var index = 0; index < $scope.column.columns.length; ++index) {
        $scope.column.selectedColumns.push($scope.column.columns[index]);
      }
    }
    else {
      for (var index = 0; index < selectedColumn.length; ++index) {
        var column = _.find($scope.column.columns, function (item) {
          return item.id == selectedColumn[index];
        });
        if (!!column)
          $scope.column.selectedColumns.push(column);
      }
    }

    //select address item
    $scope.selectAddressBook = function(cost_center) {
      /**
       * check for a lump sum before showing a warning
       */
      if (!!$scope.lumpSum) {
        if ($scope.accessFrom === 'lineItemDeliveryAddress' || $scope.accessFrom === 'multiSelectPanel') {
          swal(
            {
              title: 'Confirm add delivery address to this line item?',
              text: "Adding a different delivery address on line item will remove lump sum discount. Proceed?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#1ab394",
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              closeOnConfirm: true,
              closeOnCancel: true
            },
            function (isConfirm) {
              if (isConfirm) {
                /**
                 * removing the lump sum discount then adding the new delivery address
                 */
                $uibModalInstance.close();
                $scope.removeLumpSum().$promise.then(function (success) {
                  //item selected before, nothing to do
                  if ($scope.addressBook == cost_center._id) {
                    return;
                  }
                  $scope.onSelectedAddressBook({address_book: _.cloneDeep(cost_center)});
                });
              }
            }
          );
        }
        else {
          $uibModalInstance.close();
          //item selected before, nothing to do
          if ($scope.addressBook == cost_center._id) {
            return;
          }
          if( $scope.accessFrom === 'multiSelectPanel'){
            $scope.onSelectedAddressBook()(cost_center, $scope.selectedItemsIndexes);
          }else {
            $scope.onSelectedAddressBook({address_book: _.cloneDeep(cost_center)});
          }
        }
      }
      else {
        $uibModalInstance.close();
        //item selected before, nothing to do
        if ($scope.addressBook == cost_center._id) {
          return;
        }
        if( $scope.accessFrom === 'multiSelectPanel'){
          $scope.onSelectedAddressBook()(cost_center, $scope.selectedItemsIndexes);
        }else {
          $scope.onSelectedAddressBook({address_book: _.cloneDeep(cost_center)});
        }
      }
    };

    //close address book modal
    $scope.closeAddressBook = function(){
      $uibModalInstance.close();
    };

    //move selected item on top
    $scope.setSelectedItemOnTop = function(){

      if(!$scope.addressBook){
        return;
      }

      var index = _.findIndex($scope.addressList, function(item){
        return item._id == $scope.addressBook;
      });

      if(index != -1){

        $scope.addressList.splice(0, 0, $scope.addressList[index]);
        $scope.addressList.splice(index + 1, 1);
      }
    };

    $scope.search = {
      isShownAddressLine: $scope.column.showColumn
    };

    $scope.setSelectedItemOnTop();

    $scope.removeMultiAddresses = function(){
      $scope.multiDeletionFunction();
      $scope.closeAddressBook();
    }

  });

