'use strict';

angular.module('metabuyer.services.companies', ['ngResource'])
  .factory('Companies', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('companyCostCenters', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.costCenters,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('CompanyAccountCode', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.accountCode,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyUsers', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.users,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyActiveUsers', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.activeUsers,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('getUsersInCompanies', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.usersInCompanies,
      {
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyCurrencies', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.currencies,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  //companies image factory with function to upload
  .factory('companiesDetailImage', function ($resource, pathConstants) {
  return{
    uploadImagePath: function (companyID) {
      var path = pathConstants.apiUrls.companies.image;
      return path.replace(":id", companyID);
    }
  }
  })
  .factory('searchCompany', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.search,
      {
        query: '@query'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('companyReferenceCounter', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.referenceCounter,
      {
        companyId: '@companyId',
        referenceId: '@referenceId',
        action: '@action'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('companyReferenceCounterSearch', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.referenceCounterSearch,
      {
        query: '@query'
      },
      {
        'get': {method: 'GET'}
      }
    );
    })
  .factory('companyGroupsList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogGroups.list,
        {
          groupId: '@groupId',
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'},
          'delete': {method: 'DELETE'}
        }
      );
    })
  .factory('companyBudgetSetting', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.companies.budgetSetting,
      {
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('companyGroupsAction',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalogGroups.assignment,
        {
          groupId: '@groupId',
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'},
          'post': {method: 'POST'}
        }
      );
    })
  .factory('companyCostCentersAddress',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.companies.costCentersAddress,
        {
          companyId: '@companyId'
        },
        {
          'get': {method: 'GET'}
        }
      );
    });

