'use strict';
/**
 * @name accountCode
 * @desc directive for the Account Codes within a Company
 */
angular
  .module('metabuyer')
  .directive('accountCodes',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/accountCode/accountCodeTemplate.html',
      scope: {
        data: '=',
        code: '=',
        costCenters: '='
      },
      controller: function(
        $scope, accountCodesList, accountCodeSearch, singleAccountCode, toastr, $rootScope, $uibModal, pathConstants,
        globalFunc, searchModule, $location, $stateParams
      ) {
        $scope.dataList = $scope.data.data;
        $scope.meta = $scope.data.meta;
        $scope.accountCodesListService = accountCodesList;
        $scope.parent = {};
        $scope.submitted = false;
        $scope.root = {
          id: '',
          code: ''
        };
        $scope.parentCode = {
          status: '',
          code: ''
        };

        $scope.addNewAccountCode = addNewAccountCode;
        $scope.accountCodeDetail = accountCodeDetail;
        $scope.search = search;
        $scope.importAccountCodes = importAccountCodes;
        $scope.getAccountTypeName = getAccountTypeName;
        $scope.getAccountEntriesName = getAccountEntriesName;
        $scope.getAccountReceivableName = getAccountReceivableName;
        $scope.getActiveStatusName = getActiveStatusName;
        $scope.showColumn = showColumn;

        $scope.importLink = pathConstants.apiUrls.accountCode.import;
        $scope.columns = [
            {id: 'name', label: 'Name'},
            {id: 'code', label: 'Code'},
            {id: 'descr', label: 'Description'},
            {id: 'account_entries', label: 'Account Entries'},
            {id: 'account_receivable', label: 'Account Receivable'},
            {id: 'account_type', label: 'Account Type'},
            {id: 'cost_centers', label: 'Cost Centers'},
            {id: 'updated_at', label: 'Updated At'},
            {id: 'updater.display_name', label: 'Updated By'},
            {id: 'created_at', label: 'Created At'},
            {id: 'creator.display_name', label: 'Created By'},
            {id: 'is_active', label: 'Status'},
            {id: 'edit', label: 'Edit', unsortable: true}
          ];

        $scope.storageKey = 'company-management-account-codes-selected-columns';

        function showColumn(id){
          for (var i in $scope.columns) {
            if (id === $scope.columns[i].id) {
              if(!!$scope.columns[i].conditional){
                if($scope.status === $scope.columns[i].condition){
                  return $scope.columns[i].selected;
                }
                if($scope.columns[i].condition instanceof Array){
                  for(var j in $scope.columns[i].condition){
                    if($scope.status === $scope.columns[i].condition[j]){
                      return $scope.columns[i].selected;
                    }
                  }
                }
              }else{
                return $scope.columns[i].selected;
              }
            }
          }
        }

        /**
         * returns the is_active name
         * @param code
         * @returns {*}
         */
        function getActiveStatusName(code){
          /**
           DEACTIVATED = 0; 
           ACTIVE = 1; 
           DELETED = 2; 
           DISABLED = 3; 
           */
          if (code === 0 || code === 'DEACTIVATED') {
            return 'Deactivated'
          }
          if (code === 1 || code === 'ACTIVE') {
            return 'Active'
          }
          if (code === 2 || code === 'DELETED') {
            return 'Deleted'
          }
          if (code === 3 || code === 'DISABLED') {
            return 'Disabled'
          }
          return 'N/A';
        }

        function getAccountTypeName(num){
          for(var i in $scope.accountType){
            if($scope.accountType[i].value === num){
              return $scope.accountType[i].descr;
            }
          }
          return 'N/A';
        }

        function getAccountEntriesName(num){
          for(var i in $scope.accountType){
            if($scope.accountEntries[i].value === num){
              return $scope.accountEntries[i].descr;
            }
          }
          return 'N/A';
        }

        function getAccountReceivableName(num){
          for(var i in $scope.accountType){
            if($scope.accountReceivable[i].value === num){
              return $scope.accountReceivable[i].descr;
            }
          }
          return 'N/A';
        }

        function importAccountCodes() {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/accountCode/Import-Template.html',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              importLink: function () {
                return $scope.importLink;
              },
              title: function () {
                return "Account Code";
              },
              id: function() {
                return $scope.id;
              }
            },
            controller: function ($scope, $uibModalInstance, importLink, title, id) {
              $scope.cancel = function () {
                $uibModalInstance.close();
              };

              $scope.importLink = importLink;
              $scope.title = title;
              $scope.id = id;
            }
          });
        }

        $scope.accountCodeStatuses = [
          { isActive: 'Active', value: 1},
          { isActive: 'Deactivated' , value: 0 }
        ];

        $scope.accountEntries = [
          {value: 0, descr: 'UNSPECIFIED'},
          {value: 1, descr: 'CREDIT'     },
          {value: 2, descr: 'DEBIT'      }
        ];

        $scope.accountReceivable = [
          {value: 0, descr: 'UNSPECIFIED'          },
          {value: 1, descr: 'RETAINED EARNING'     },
          {value: 2, descr: 'CURRENT YEAR EARNINGS'},
          {value: 3, descr: 'ACCOUNT RECEIVABLE'   },
          {value: 4, descr: 'BANK'                 },
          {value: 5, descr: 'ACCOUNT PAYABLE'      }
        ];

        $scope.accountType = [
          {value: 0, descr: 'UNSPECIFIED'},
          {value: 1, descr: 'EQUITY'     },
          {value: 2, descr: 'ASSET'      },
          {value: 3, descr: 'LIABILITY'  },
          {value: 4, descr: 'INCOME'     },
          {value: 5, descr: 'EXPENDITURE'}
        ];


        function addNewAccountCode() {
          $scope.submitted = true;
          if (validate($scope.newAccountCode)) {
            var costCenterIds = prepareCostCenterIds($scope.newAccountCode.cost_centers);

            singleAccountCode.post(
              {
                id: $stateParams.id
              },
              {
                code: $scope.newAccountCode.code,
                name: $scope.newAccountCode.name,
                descr: $scope.newAccountCode.descr,
                account_entries : $scope.newAccountCode.account_entries.value,
                account_receivable : $scope.newAccountCode.account_receivable.value,
                account_type : $scope.newAccountCode.account_type.value,
                cost_center_ids: costCenterIds,
                is_active: 1
              },
              function (success) {
                toastr.success('A new account code has been added successfully');
                if(!!success.content) {
                  if (!!success.content.data) {
                    var addedAccountCode = success.content.data;
                    $scope.dataList.unshift({
                      _id: addedAccountCode._id,
                      code: addedAccountCode.code,
                      descr: addedAccountCode.descr,
                      name: addedAccountCode.name,
                      account_entries: $scope.newAccountCode.account_entries.value,
                      account_receivable: $scope.newAccountCode.account_receivable.value,
                      account_type: $scope.newAccountCode.account_type.value,
                      created_at: addedAccountCode.created_at,
                      updated_at: addedAccountCode.updated_at,
                      cost_center: addedAccountCode.cost_centers,
                      updater: [$rootScope.currentUser],
                      creator: [$rootScope.currentUser],
                      is_active: 1
                    });
                  }
                }
                $scope.newAccountCode.name = '';
                $scope.newAccountCode.code = '';
                $scope.newAccountCode.account_entries = '';
                $scope.newAccountCode.account_receivable = '';
                $scope.newAccountCode.account_type = '';
                $scope.newAccountCode.descr = '';
                $scope.newAccountCode.cost_centers = [];
                $scope.submitted = false;
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                $scope.submitted = false;
              }
            );
          }
        }

        function prepareImportParams () {
            $scope.params = {
                'class': 'Metabuyer\\Models\\AccountCode'
            };
            _.forEach ($scope.dataList, function(account, index){
                var temp = 'additional_params[]['+ index+ ']';
                $scope.params[temp] = account.code;
            });
        }

        prepareImportParams();


        function prepareCostCenterIds(selectedCostCenters) {
          var ids = [];
          _.forEach(selectedCostCenters, function(cc) {
            ids.push(cc._id);
          });
          return ids;
        }

        function search(query) {
          $location.search({query: query});
        }

        function validate(accountCode) {
          var validationError = 0;
          if (!!accountCode) {
            if (!accountCode.code ||
              !accountCode.name ||
              !accountCode.descr ||
              !accountCode.account_entries ||
              !accountCode.account_type ||
              !accountCode.account_receivable
            )
              validationError++;

            if (validationError > 0) {
              globalFunc.objectErrorMessage('Please complete all required fields before proceeding');
              return false;
            }
          }

          return true;
        }

        function accountCodeDetail(accountCode) {
          var modalInstance = $uibModal.open({
            templateUrl: "components/accountCode/details/details.html",
            backdrop: 'static',
            keyboard: false,
            controller: 'accountCodeDetailsCtrl',
            resolve: {
              accountCode: function() {
                return accountCode;
              },
              companyId: function(){
                return $stateParams.id;
              },
              accountCodeStatuses: function(){
                return $scope.accountCodeStatuses;
              },
              accountEntries: function(){
                return $scope.accountEntries;
              },
              accountReceivable: function(){
                return $scope.accountReceivable;
              },
              accountType: function(){
                return $scope.accountType;
              },
              costCenters: function() {
                return $scope.costCenters;
              }
            }
          });
          modalInstance.result.then(function (newValue) {
            if(!!newValue){
              _.forEach($scope.dataList, function(dataList){
                if(dataList._id === newValue._id){
                  dataList.name = newValue.name;
                  dataList.code = newValue.code;
                  dataList.descr = newValue.descr;
                  dataList.account_entries = newValue.account_entries.value;
                  dataList.account_receivable = newValue.account_receivable.value;
                  dataList.account_type = newValue.account_type.value;
                  dataList.cost_center = newValue.cost_center;
                  dataList.is_active = newValue.accountCodeStatus.value;
                  dataList.updated_at = newValue.updated_at;
                  dataList.updater[0].display_name = newValue.updater[0].display_name;
                  dataList.parent_code = newValue.parent_code;
                }
              });
            }
          });
        }
      }
    }
  });
