'use strict';

function ItemsCtrl($scope, $state) {

  $scope.tabData = [
    {
      heading: 'Manage Catalog Uploads',
      route: 'main.user.items.manageCatalog',
      key: 'uploads',
      menu: [
        {
          heading: 'All',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'all',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Approved',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'approved',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Compose',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'compose',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Expired',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'expired',
            query: null
          }
        },
        {
          heading: 'Error',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'error',
            cursor: null,
            query: null
          }
        },
        {
          heading: 'Inactive',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'inactive',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Pending Approval',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'pending',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Rejected',
          route: 'main.user.items.manageCatalog',
          key: 'uploads',
          params: {
            status: 'rejected',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        }
      ]
    },
    {
      heading: 'Manage Catalog',
      route: 'main.user.items.catalogGroup',
      key: 'catalog',
      menu: [
        {
          heading: 'Catalog Group',
          route: 'main.user.items.catalogGroup',
          key: 'catalog',
          params: {
            status: '',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Catalog Code',
          route: 'main.user.items.catalogCode',
          key: 'catalog',
          params: {
            status: '',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Catalog Items',
          route: 'main.user.items.catalogItems',
          key: 'catalog',
          params: {
            status: '',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        }
      ]
    },
    {
      heading: 'Import Catalog',
      route: 'main.user.items.import.select_bu',
      key: 'import',
      params: {
        status: 'import',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function( event, data ) {
    if(!!data.name && data.name.indexOf('manageCatalog')> -1){
      $scope.searchPlaceholder = 'Search Catalogs';
      $scope.searchState = 'main.user.items.manageCatalog';
      $scope.parentState = 'main.user.items.manageCatalog';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageCatalogColumns;
    }
    else if(!!data.name && data.name.indexOf('catalogGroup') > -1){
      $scope.searchPlaceholder = 'Search Catalog Groups';
      $scope.searchState = 'main.user.items.catalogGroup';
      $scope.parentState = 'main.user.items.catalogGroup';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogGroupColumns;
    }
    else if(!!data.name && data.name.indexOf('catalogCode') > -1){
      $scope.searchPlaceholder = 'Search Catalog Code';
      $scope.searchState = 'main.user.items.catalogCode';
      $scope.parentState = 'main.user.items.catalogCode';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogCodeColumns;
    }
    else if(!!data.name && data.name.indexOf('catalogItems') > -1){
      $scope.searchPlaceholder = 'Search Catalog Items';
      $scope.searchState = 'main.user.items.catalogItems';
      $scope.parentState = 'main.user.items.catalogItems';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogItemColumns;
    }
  });

  function initialize(){
    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }

    if(!!$state.current.name && $state.current.name.indexOf('manageCatalog') > -1) {
      $scope.searchPlaceholder = 'Search Catalogs';
      $scope.searchState = 'main.user.items.manageCatalog';
      $scope.parentState = 'main.user.items.manageCatalog';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageCatalogColumns;
      $scope.activatedTab = $scope.tabData[0]
    }
    else if(!!$state.current.name && ($state.current.name.indexOf('catalogGroup') > -1)
        || ($state.current.name.indexOf('createCatalogGroup') > -1)
        || ($state.current.name.indexOf('editCatalogGroup') > -1)){
      $scope.searchPlaceholder = 'Search Catalog Groups';
      $scope.searchState = 'main.user.items.catalogGroup';
      $scope.parentState = 'main.user.items.catalogGroup';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogGroupColumns;
      $scope.activatedTab = $scope.tabData[1]
    }
    else if(!!$state.current.name && $state.current.name.indexOf('catalogCode') > -1){
      $scope.searchPlaceholder = 'Search Catalog Code';
      $scope.searchState = 'main.user.items.catalogCode';
      $scope.parentState = 'main.user.items.catalogCode';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogCodeColumns;
      $scope.activatedTab = $scope.tabData[1]
    }
    else if(!!$state.current.name && $state.current.name.indexOf('catalogItems') > -1) {
      $scope.searchPlaceholder = 'Search Catalog Items';
      $scope.searchState = 'main.user.items.catalogItems';
      $scope.parentState = 'main.user.items.catalogItems';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogItemColumns;
      $scope.activatedTab = $scope.tabData[1]
    }
    else if(!!$state.current.name && $state.current.name.indexOf('import') > -1) {
        $scope.searchPlaceholder = 'Search Catalogs';
        $scope.searchState = 'main.user.items.manageCatalog';
        $scope.parentState = 'main.user.items.manageCatalog';
        $scope.parentStateParams = $state.params;
        $scope.activatedTab = $scope.tabData[2]
    }
    else{
      $scope.searchPlaceholder = 'Search Catalogs';
      $scope.searchState = 'main.user.items.manageCatalog';
      $scope.parentState = 'main.user.items.manageCatalog';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageCatalogColumns;
      $scope.activatedTab = $scope.tabData[0];
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status
    }

    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }


  }

  var manageCatalogColumns = [
    {id: 'check box', title: '', unsortable: true, unsearchable: true, conditional: true, condition: ['pending']},
    {id: 'catalog_group.descr', label: 'Catalog Group'},
    {id: 'catalog_code.code', label: 'Catalog Code'},
    {id: 'catalog_code.descr', label: 'Catalog Description'},
    {id: 'supplier.descr', label: 'Supplier'},
    {
      id: 'file_header|itemcount',
      label: '# item',
      type: 'double',
      unsearchable: true
    },
    {
      id: 'import_effective_date',
      label: 'Effective At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Creator'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'import_status', label: 'Status', conditional: true, condition: 'all'}
  ];

  var catalogGroupColumns = [
    {id: 'code', label: 'Code'},
    {id: 'descr', label: 'Description'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  var catalogCodeColumns = [
    {id: 'code', label: 'Catalog Code'},
    {id: 'descr', label: 'Catalog Code Description'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  var catalogItemColumns = [
    {id: 'item_name', label: 'Item Name'},
    {id: 'description', label: 'Description'},
    {id: 'commodity_code', label: 'Commodity Code'},
    {id: 'commodity_descr', label: 'Commodity Description'},
    {id: 'supplier.descr', label: 'Supplier'},
    {id: 'supplier_part_no', label: 'Supplier Part Number'},
    {id: 'tax.rate', label: 'Tax Rate', unsortable: true},
    {id: 'payment_term.descr', label: 'Payment Term'},
    {id: 'currency.code', label: 'Currency'},
    {
      id: 'unit_price',
      label: 'Unit Price',
      type: 'double',
      unsearchable: true
    },
    {id: 'Uom.code', label: 'UOM'},
    {
      id: 'effective_at',
      label: 'Effective Date',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'expiry_at',
      label: 'Expiry Date',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true},
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Manage Catalog');

  $scope.$on('$stateChangeStart', function(event, toState, toParams){
    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }
  });
}
ItemsCtrl.$inject = ['$scope', '$state'];


angular.module('metabuyer')
  .controller('ItemsCtrl', ItemsCtrl);
