/**
 * @name dynamicHeight
 *
 * @requires $rootScope
 *
 * @description Directive to handling the height and detect the device height
 *
 * @author Deniel
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('dynamicHeight', function ($window) {
    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {

        var dynamicHeight = document.getElementById('dynamicHeightTable');

        function setHeight() {
          var windowsHeight = window.innerHeight;
          var headerHeight = 207;
          var additionalHeight = 50;
          var newHeight = windowsHeight - headerHeight - additionalHeight;

          angular.element(dynamicHeight).css('max-height', newHeight + 'px');
          angular.element(dynamicHeight).css('display', 'block');
        }

        angular.element($window).bind('resize', function() {
          setHeight();

          // to avoid error $digest already in progress
          _.defer(function() {
            $scope.$apply();
          });
        });

        setHeight();
      }
    }
  });
