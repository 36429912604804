'use strict';

function OrdersDetailCtrl(
  $scope, $q, globalFunc, $state, $location, $anchorScroll, order, pathConstants, Orders, profile, starringPO, $rootScope, toastr, purchaseRequisitionsServices, HighlightId, POSubscriptionCheck, getPOPDFHash, $filter,
  notificationSubscriber, POComments, UserPermissions, $timeout
) {

  $scope.po = order;
  $scope.service = POComments;
  $scope.po.notify = POSubscriptionCheck.subscribed;
  $scope.profile = profile;
  $scope.notificationToggle = notificationToggle;
  $scope.imageBasePath = pathConstants.apiUrls.image.pr;
  $scope.userBasePath = $rootScope.userImageBasePath;
  $scope.checkGRNAccess = checkGRNAccess;
  $scope.checkCancelButton = checkCancelButton;
  $scope.canUpdatePO = canUpdatePO;
  $scope.selectedAction = selectedAction;
  $scope.parseConditions = parseConditions;
  $scope.disableResendPO = disableResendPO;
  $scope.status_mapping =
    {
      'DELIVERY_DATE_UNCONFIRMED': 3,
      'DECLINED': 4,
      'AWAITING_DELIVERY': 5
    };


  function getStatusOptions() {
    $scope.poStatusOptions = [];
    switch ($scope.po.status) {
      case 'DECLINED': {
        $scope.poStatusOptions.push(
        {
          status: 3,
          descr: 'Supplier couldn\'t confirm delivery date'
        },
        {
          status: 5,
          descr: 'Supplier accepted PO'
        })
      }
      break;
      case 'SUBMITTED': {
        $scope.poStatusOptions.push(
        {
          status: 5,
          descr: 'Supplier accepted PO'
        },
        {
          status: 3,
          descr: 'Supplier couldn\'t confirm delivery date'
        },
        {
          status: 4,
          descr: 'Supplier declined PO'
        })
      }
      break;
      case 'DELIVERY_DATE_UNCONFIRMED': {
        $scope.poStatusOptions.push(
        {
          status: 5,
          descr: 'Supplier Accepted PO'
        },
        {
          status: 4,
          descr: 'Supplier Declined PO'
        })
      }
      break;
    }
  }

  $scope.printPO = function() {

    getPOPDFHash.get(
      {
        id: $scope.po._id,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function(){
        toastr.error('Failed to print PO');
      }
    );
  };

  $scope.backToListingPO = backToListingPO;
  $scope.starred = starred;
  $scope.scrollTo = scrollTo;
  $scope.scrollToClosedLineItemComment = scrollToClosedLineItemComment;
  $scope.loadContactPersons = loadContactPersons;

  // Check and assign the right status for details page
  $scope.accessStatus = $scope.po.status;
  if(angular.isUndefined($scope.accessStatus) || $scope.accessStatus === null) {
    $scope.accessStatus = $scope.previousState.params.status;
  } else if ($scope.previousState.params.status !== null) {
    if ($scope.previousState.params.status === 'STARRED')
      $scope.accessStatus = $scope.previousState.params.status;
  }

  function selectedAction(item) {

    //check if current status was set
    if(item.status === $scope.status_mapping[$scope.po.status]) {
      toastr.success('The status has been set');
      return;
    }
    /**
     * If statement only for decision 'Decline PO'
     */
    if(item.status === 4) {
      swal(
      {
        title: 'Reason for supplier declined PO?',
        text: 'Please state a reason for supplier declined PO',
        type: 'input',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function(inputValue) {
        if (!!inputValue) {
          var trimmedValue = inputValue.trim();
        }

        if (!inputValue || trimmedValue === '') {
          swal.showInputError('You need to state a reason for supplier decline PO!');
          return false;
        }

        Orders.setPOStatus(
          {
            id: order._id
          },
          {
            status: item.status,
            comment: inputValue
          },
          function(success) {
            toastr.success('Supplier declined PO');
            swal.close();
            //check if supplier declined the po
            if (item.status === $scope.status_mapping['DECLINED']) {
              HighlightId.setId($scope.po._id);
              $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('declined')});
              return;
            }
          },
          function (error) {
            toastr.error('Failed to submit status due to ' + error.data.message);
          });
      });
    }
    else {
      Orders.setPOStatus(
        {
          id: order._id
        },
        {
          status: item.status
        },
        function (success) {
          /**
           * status 5 for accepting PO
           * setting the status of the PO
           */
          if (item.status === $scope.status_mapping['AWAITING_DELIVERY']) {
            toastr.success('The supplier has accepted the PO');
            $scope.po.status = 'AWAITING_DELIVERY';
            $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('awaiting_delivery'), noTransition: true});
          }

          if (item.status === $scope.status_mapping['DELIVERY_DATE_UNCONFIRMED']) {
            toastr.success('The status has been set');
            $scope.po.status = 'DELIVERY_DATE_UNCONFIRMED';

            //update history
            $rootScope.$broadcast('refreshComments');
            $rootScope.$broadcast('loadSpecificTab', {tab: activateTab('submitted'), noTransition: true});
          }

          getStatusOptions();
          labelStatus();

        },
        function (error) {
          toastr.error('Failed to submit status due to ' + error.data.message);
        });
    }
  }

  function backToListingPO() {
      window.history.back();
      HighlightId.setId($scope.po._id);
  }

  function starred(po) {
    starringPO.update(
      {
        id: po._id,
        action: !!po.is_starred ? 'unstar' : 'star'
      }
    ).
      $promise.then(function (v) {
        //successful
        if (!!po.is_starred) {
          po.is_starred = false;
        }
        else {
          po.is_starred = true;
        }
      },
      function (error) {
        toastr.error(error.message);
      }
    );
  }

  function scrollTo(id) {
    var old_hash = $location.hash();
    $location.hash(id);
    $anchorScroll();
    $location.hash(old_hash);
  }

  function notificationToggle() {

    if(!$scope.po.notify) {
      notificationSubscriber.post({
          object_id: $scope.po._id, class: 'Metabuyer\\Models\\PO', method: 'subscribe'
        }
        , function (data) {
          $scope.po.notify = true;
          toastr.success('Turned on notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn on notifications');
        });
    }
    else{
      notificationSubscriber.delete({
          object_id: $scope.po._id, class: 'Metabuyer\\Models\\PO', method: 'unsubscribe'
        }
        , function (data) {
          $scope.po.notify = false;
          toastr.success('Turned off notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn off notifications');
        });
    }

  }


  function scrollToClosedLineItemComment(id){
    $rootScope.$broadcast('scrollToClosedLineItemComment', {id: id});
  }

  function loadContactPersons(company) {

    if(!company || !company._id){
      return;
    }
    var defer = $q.defer();

    purchaseRequisitionsServices.getContactPersonsList(
      {
        id: company._id
      }, function (resource) {
        var contactPersons = (typeof resource.content !== 'undefined' &&
                              typeof resource.content.data !== 'undefined') ? resource.content.data : [];
        $scope.options['contact_persons'] = contactPersons;
        defer.resolve(contactPersons);
      }, function (error) {
        defer.reject(error);
      }
    );

    $scope.datalists = defer.promise;
  }

  function checkGRNAccess() {
    return (UserPermissions.checkPermissionsAccess(profile, 'C', 'GRN') ||
      UserPermissions.checkPermissionsAccess(profile, 'U', 'GRN'));
  }

  function canUpdatePO () {
    return (UserPermissions.checkPermissionsAccess(profile, 'U', 'PO'));
  }

  function parseConditions(condition,poStatus) {
    var returnValue = '';
    switch(condition) {
      case 1:
        returnValue = (['DECLINED','SUBMITTED', 'AWAITING_CONFIRMATION','DELIVERY_DATE_UNCONFIRMED'].indexOf(poStatus));
        break;
      case 2:
        returnValue = (['PARTIALLY_DELIVERED', 'AWAITING_DELIVERY','IN_PORT','CLOSED'].indexOf(poStatus));
        break;
      case 3:
        returnValue = (['FULLY_DELIVERED', 'CLOSED','PARTIALLY_DELIVERED'].indexOf(poStatus));
        break;
      case 4:
        returnValue = (['SUBMITTED', 'AWAITING_CONFIRMATION','DELIVERY_DATE_UNCONFIRMED','AWAITING_DELIVERY'].indexOf(poStatus));
        break;
      case 5:
        returnValue = (['PARTIALLY_DELIVERED', 'AWAITING_DELIVERY','IN_PORT'].indexOf(poStatus));
        break;
      case 6:
        returnValue = (['SUBMITTED'].indexOf(poStatus));
        break;
      case 7:
        returnValue = (['DELIVERY_DATE_UNCONFIRMED'].indexOf(poStatus));
        break;
      case 8:
        returnValue = (['DECLINED'].indexOf(poStatus));
        break;
    }
    return returnValue > -1;
  }

  function labelStatus() {
    if(!!$scope.po.status) {
      switch($scope.po.status) {
        case 'CANCELLED':
          $scope.po.generalStatus ='Cancelled';
          $scope.po.specificStatus ='Cancelled';
          break;
        case 'ACCEPTED':
          $scope.po.generalStatus ='Accepted';
          $scope.po.specificStatus ='Accepted';
          break;
        case 'CONFIRMED':
          $scope.po.generalStatus ='Confirmed';
          $scope.po.specificStatus ='Confirmed';
          break;
        case 'CLOSED':
          $scope.po.generalStatus ='Closed';
          $scope.po.specificStatus ='Closed';
          break;
        case 'FULLY_DELIVERED':
          $scope.po.generalStatus ='Delivered';
          $scope.po.specificStatus ='Delivered';
          break;
        case 'DECLINED':
          $scope.po.generalStatus ='Declined';
          $scope.po.specificStatus ='Declined';
          break;
        case 'DELIVERY_DATE_UNCONFIRMED':
          $scope.po.generalStatus ='Submitted';
          $scope.po.specificStatus ='Delivery Date Unconfirmed';
          break;
        case 'SUBMITTED':
          $scope.po.generalStatus ='Submitted';
          $scope.po.specificStatus ='Submitted';
          break;
        case 'AWAITING_CONFIRMATION':
          $scope.po.generalStatus ='Submitted';
          $scope.po.specificStatus ='Awaiting Confirmation';
          break;
        case 'AWAITING_DELIVERY':
          $scope.po.generalStatus ='Awaiting Delivery';
          $scope.po.specificStatus ='Awaiting Delivery';
          break;
        case 'PARTIALLY_DELIVERED':
          $scope.po.generalStatus ='Awaiting Delivery';
          $scope.po.specificStatus ='Partially Delivered ( ' +
            $filter('formatDecimal')($scope.po.received_percentage*100, 2) + '% )';
          break;
        case 'IN_PORT':
          $scope.po.generalStatus ='Awaiting Delivery';
          $scope.po.specificStatus ='Awaiting Delivery';
          break;
      }
    }
  }

  function activateTab(tab){
    var status = tab;
    if(!status)
      status = _.cloneDeep($scope.po.status.toLowerCase());

    //amend the status name to match the keys
    if(status === 'awaiting_confirmation')
      status = 'submitted';
    else if (status === 'partially_delivered')
      status = 'awaiting_delivery';
    else if (status === 'delivered')
      status = 'closed';
    else if (status === 'fully_delivered')
      status = 'closed';
    else if (status === 'delivery_date_unconfirmed')
      status = 'submitted';

    tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if(!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);

    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  function disableResendPO(){
    var allowableStatus = [
      'SUBMITTED',
      'CONFIRMED',
      'AWAITING_CONFIRMATION',
      'DELIVERY_DATE_UNCONFIRMED',
      'AWAITING_DELIVERY',
      'PARTIALLY_DELIVERED',
      'IN_PORT',
      'FULLY_DELIVERED',
      'CLOSED'
    ];

    return allowableStatus.indexOf($scope.po.status) >= 0;
  }

  function initialize() {

    if($scope.po.status === 'AWAITING_CONFIRMATION')
      $state.params.status = 'SUBMITTED';
    else if ($scope.po.status === 'PARTIALLY_DELIVERED')
      $state.params.status = 'AWAITING_DELIVERY';
    else if ($scope.po.status === 'DELIVERED')
      $state.params.status = 'CLOSED';
    else if ($scope.po.status === 'FULLY_DELIVERED')
      $state.params.status = 'CLOSED';
    else if ($scope.po.status === 'DELIVERY_DATE_UNCONFIRMED')
      $state.params.status = 'SUBMITTED';

    var tabData = {
      tab: activateTab(),
      noTransition: true
    };

    //HACK: as the tabdata controller doesn't load in time and the the tabs will be deleted in the new design,
    //this is a work around to only load it after 300 milisecond
    // TODO: remove this with the new design, Ahmed Saleh (25/9/2017)
    $timeout(function() {
      $rootScope.$broadcast('loadSpecificTab', tabData);
    }, 300);

    $scope.options = {
      contact_persons: []
    };

    loadContactPersons($scope.po.billing.company);
    getStatusOptions();
    labelStatus();

    // Support old format of delivery address in PO
    if (!!$scope.po.delivery.address && !!$scope.po.delivery.address.details && !$scope.po.delivery.address.line1) {
      $scope.po.delivery.address.line1 = $scope.po.delivery.address.details.line1;
      $scope.po.delivery.address.line2 = $scope.po.delivery.address.details.line2;
      $scope.po.delivery.address.line3 = $scope.po.delivery.address.details.line3;
      $scope.po.delivery.address.postal_code = $scope.po.delivery.address.details.postal_code;
      $scope.po.delivery.address.city = $scope.po.delivery.address.details.city;
      $scope.po.delivery.address.state = $scope.po.delivery.address.details.state;
      $scope.po.delivery.address.country = $scope.po.delivery.address.details.country;
    }

    //clean up old notes structure
    if(!!$scope.po.notes && _.isArray($scope.po.notes)){
      $scope.po.notes = $scope.po.notes[0];
    }
  }

  /**
   * check the creator or requestor to cancel
   * @returns {boolean}
   */
  function checkCancelButton() {
    if (!$scope.po.creator) {
      return false;
    }

    if ($scope.po.creator._id !== $rootScope.currentUser._id) {
      if (!$scope.po.requestor || $scope.po.requestor._id !== $rootScope.currentUser._id) {
        return false;
      }
    }
    return true;
  }

  initialize();
}

OrdersDetailCtrl.$inject = ['$scope', '$q', 'globalFunc', '$state', '$location', '$anchorScroll',
  'order', 'pathConstants', 'Orders', 'profile', 'starringPO', '$rootScope',
  'toastr', 'purchaseRequisitionsServices', 'HighlightId', 'POSubscriptionCheck', 'getPOPDFHash',
  '$filter', 'notificationSubscriber', 'POComments', 'UserPermissions', '$timeout'];

angular.module('metabuyer')
  .controller('OrdersDetailCtrl', OrdersDetailCtrl);
