'use strict';
/**
 * @name reportsCtrl
 * @desc Controller for reports main Controller
 */
function reportsCtrl ($scope, $state) {

  $scope.$emit('pageTitleChanged', 'Reports');

  $scope.tabData = [
    {
      heading: 'Logs',
      route: 'main.user.reports.log',
      key: 'logs',
      params: {
        order_by: 'done_at',
        order: '0'
      }
    },
    {
      heading: 'Reports',
      route: 'main.user.reports.generate',
      key: 'reports'
    }
  ];
}
reportsCtrl.$inject = ['$scope', '$state'];

angular
  .module('metabuyer')
  .controller('reportsCtrl', reportsCtrl);
