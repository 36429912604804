'use strict';

angular
  .module('metabuyer')
  .controller('UserWaitingOnYouCtrl', UserWaitingOnYouCtrl);

/**
 * @name UserWaitingOnYouCtrl
 * @desc Controller for User Waiting on you
 */
function UserWaitingOnYouCtrl($scope, $state, waitingList, waitingOnUserList, $rootScope) {
  $scope.dataList = waitingList.data;
  $scope.meta = waitingList.meta;
  $scope.waitingService = waitingOnUserList;
  //TODO: 'nofifyWaitingOnFavico' is calling parent parent controller (user.controller) to use the function, have this centralized (Ahmed Saleh)
  $scope.nofifyWaitingOnFavico($rootScope.waitings);
  $rootScope.$broadcast('newWaitingList');
  $scope.redirectTo = function (item) {
    switch (item.context_type) {
      case 'Metabuyer\\PR\\PR':
      {
        $state.go('main.user.purchaseRequisitions.details', {
          id: item.context_id,
          status: 'pending',
          action: 'access_directly',
          from: "waiting-on-you",
          title: undefined
        });
        break;
      }
      case 'Metabuyer\\Models\\Supplier':
      {
        $state.go('main.user.suppliers.detail', {
          id: item.context_id,
          title: undefined
        });
        break;
      }
      case 'Metabuyer\\Models\\ImportItemSummary':
      {
        $state.go('main.user.items.detail', {
          id: item.context_id,
          //assuming waiting on will only results on a pending item import.
          //this is to be fixed once a panel is created to access the archived approved operations
          status: 'pending',
          title: undefined
        });
        break;
      }
      case 'Metabuyer\\Budget\\Models\\Budget': {
        $state.go('main.user.budget.details', {
          id: item.context_id
        });
        break;
      }
    }
  };

  function dataCleanUp() {
    _.forEach($scope.dataList, function (item) {
      if (item.context_type === 'Metabuyer\\Models\\Supplier') {
        item.displayCategory = 'Supplier';
      }
      if (item.context_type === 'Metabuyer\\Models\\ImportItemSummary') {
        item.displayCategory = 'Catalog Approval';
      }
      if (item.context_type === 'Metabuyer\\PR\\PR') {
        item.displayCategory = 'Purchase Requisition';
      }
      if (item.context_type === 'Metabuyer\\Budget\\Models\\Budget') {
        item.displayCategory = 'Budget';
      }
    });
  }

  dataCleanUp();

}
UserWaitingOnYouCtrl.$inject = ['$scope', '$state', 'waitingList', 'waitingOnUserList', '$rootScope'];
