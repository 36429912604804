'use strict';

function addContactCtrl($scope, $uibModalInstance, toastr, $q, Contacts, globalFunc) {

  //cancel action
  $scope.cancel = function(){
    $uibModalInstance.close();
  };

  function validateContact(){
    if(!$scope.add_data.first_name){
      return false;
    }

    if(!$scope.add_data.phone){
      return false;
    }

    if (!$scope.isValidPhoneNo) {
      return false;
    }

    // if (!$scope.isValidFaxNo) {
    //   return false;
    // }

    return true;
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  //proceed action
  $scope.addContact = function(){
    $scope.clicked_submit = true;
    if(!validateContact()){
      toastr.error('Please fill all required fields');
      return;
    }

    if(!!$scope.add_data.email && !globalFunc.emailFormatValidation($scope.add_data.email)) {
      toastr.error('Please enter a valid email address');
      return;
    }

    Contacts.addContact({}, $scope.add_data,
      function(success){
        toastr.success('Contact added');

        if(!!success._id){
          $scope.add_data._id = success._id;
        }

        $scope.addedContact({contact: $scope.add_data});

        $uibModalInstance.close();
      }, function(error){
        globalFunc.objectErrorMessage(error);
        $uibModalInstance.close();
      });
  };

  function initialize() {
    $scope.add_data = {};
    $scope.validateContact = validateContact;
    $scope.emailFormatValidation = globalFunc.emailFormatValidation;
    $scope.validateNumbers = validateNumbers;
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
  }

  initialize();
}

addContactCtrl.$inject = ['$scope', '$uibModalInstance', 'toastr', '$q', 'Contacts', 'globalFunc'];

angular.module('metabuyer')
  .controller('addContactCtrl', addContactCtrl);
