'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider, pathConstants) {

    $stateProvider
      .state('main.user.masterData', {
        url: '/master-data',
        controller: 'MasterDataCtrl',
        abstract: true,
        templateUrl: 'app/masterDataManagement/master-data.html'
      })
      .state('main.user.masterData.currency', {
        templateUrl: 'app/masterDataManagement/currency/manage/manage.html',
        url: '/currency?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageCurrencyCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkCurrenciesAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkCurrenciesAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkCurrenciesAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'currencies',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Currencies - Master Data';
          }
        }
      })
      .state('main.user.masterData.exchangeRate', {
        templateUrl: 'app/masterDataManagement/exchangeRate/manage/manage.html',
        url: '/exchangerate?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageExchangeRateCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkExchangeRateAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkExchangeRateAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkExchangeRateAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
                module: 'exchange-rate',
                'criteria[1][code]': $stateParams.query,
                'criteria[1][descr]': $stateParams.query,
                'criteria[1][$operator]': !!$stateParams.query ? 'or' : null,
                criteria_operator: !!$stateParams.query ? 'and' : null,
                offset: 20,
                'criteria[0][company_id]': '',
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currencies: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'currencies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Exchange Rates - Master Data';
          }
        }
      })
      .state('main.user.masterData.uom', {
        templateUrl: 'app/masterDataManagement/uom/manage/manage.html',
        url: '/uom?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageUOMCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
          if (!UserPermissions.checkUOMAccess(profile, 'C', $rootScope.changeWorkSpace) &&
            !UserPermissions.checkUOMAccess(profile, 'U', $rootScope.changeWorkSpace) &&
            !UserPermissions.checkUOMAccess(profile, 'D', $rootScope.changeWorkSpace))  {
            $state.go('notAuthorized');
          }
        },
          uomList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
                module: 'uom',
                'criteria[code]': $stateParams.query,
                'criteria[descr]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Units of Measurement - Master Data';
          }
        }
      })
      .state('main.user.masterData.paymentTerms', {
        templateUrl: 'app/masterDataManagement/paymentTerms/manage/manage.html',
        url: '/payment-terms?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'masterDataManagePaymentTermsCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPaymentTermsAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkPaymentTermsAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkPaymentTermsAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'payment-term',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Payment Terms - Master Data';
          }
        }
      })
      .state('main.user.masterData.paymentMethods', {
        templateUrl: 'app/masterDataManagement/paymentMethods/manage/manage.html',
        url: '/payment-methods?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'masterDataPaymentMethodsCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkPaymentMethodAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkPaymentMethodAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkPaymentMethodAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'payment-method',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Payment Methods - Master Data';
          }
        }
      })
      .state('main.user.masterData.commodityCode', {
        templateUrl: 'app/masterDataManagement/commodityCode/manage/manage.html',
        url: '/commodity-code?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}&{parentUniqueName:[^~,]}',
        controller: 'masterDataCommodityCodeCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkCommodityCodeAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkCommodityCodeAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkCommodityCodeAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            /**
             * pass parent_unique_name as All in order to list segments
             * Strict field is for determining if search criteria is strict searching, 1 for true 0 for false(default)
             * If parentUniqueName was passed into state parameters, list child instead with strict 1
             */
            var params = {
              module: 'commodity',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              'criteria[parent_unique_name]': !!$stateParams.query ? null : ($stateParams.parentUniqueName ? $stateParams.parentUniqueName : 'All'),
              criteria_operator: !!$stateParams.query ? 'or' : null,
              strict: $stateParams.parentUniqueName ? 1: 0,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if(!!resource.content) {
                  deferred.resolve(resource.content);
                }else{
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Commodity Codes - Master Data';
          }
        }
      })
      .state('main.user.masterData.taxes', {
        templateUrl: 'app/masterDataManagement/taxes/manage/manage.html',
        url: '/taxes?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageTaxesCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkTaxesManagementAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkTaxesManagementAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkTaxesManagementAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'tax',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Taxes - Master Data';
          }
        }
      })
      .state('main.user.masterData.expenseTypes', {
        templateUrl: 'app/masterDataManagement/expenseTypes/manage/manage.html',
        url: '/expense-types?{query:[^~,]}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'MasterDataManageExpenseTypesCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkExpenseTypeAccess(profile, 'C', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkExpenseTypeAccess(profile, 'U', $rootScope.changeWorkSpace) &&
              !UserPermissions.checkExpenseTypeAccess(profile, 'D', $rootScope.changeWorkSpace))  {
              $state.go('notAuthorized');
            }
          },
          dataList: function ($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();

            var params = {
              module: 'expense-type',
              'criteria[code]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              criteria_operator: !!$stateParams.query ? 'or' : null,
              offset: 20,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Expense Types - Master Data';
          }
        }
      })
  }]);
