'use strict';

/**
 * Controller for email items listing
 * @param $scope
 * @param dataList
 */

function emailLogsManageCtrl($scope, dataList) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.showColumn = showColumn;

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

}

emailLogsManageCtrl.$inject = ['$scope', 'dataList'];

angular.module('metabuyer')
  .controller('emailLogsManageCtrl', emailLogsManageCtrl);
