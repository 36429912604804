'use strict';
/**
 * @name UsersEditCtrl
 * @desc Controller for users edit
 */
function UsersEditCtrl($scope, $q, $resource, $state, globalFunc, toastr, editedUser, $uibModal,
                       companiesForAdminUse, singleUser, companyCostCenters, $rootScope) {

  var currentEmpNo = angular.copy($scope.user.employee_number);

  $scope.selectCostCenter = selectCostCenter;
  $scope.user = editedUser;
  $scope.user.employee_number = $scope.user.emp_no;
  $scope.user.cc_list = [];
  $scope.deselectedCC = {};
  $scope.selectedEmailFrequency = selectedEmailFrequency;
  $scope.duplicationCheck = duplicationCheck;
  $scope.saveDisabled = true;

  $scope.emailFrequency = [
    {descr: 'Immediate', value: true},
    {descr: 'End of Day', value: false}
  ];

  function selectedEmailFrequency(value){
    $scope.user.receive_email_immediate = value;
  }

  function deselectCompany (companyID){
    companyCostCenters.get(
      {
        id: companyID
      },
      function(resource){
        $scope.deselectedCC = resource.content.data;
        for(var k =0;k<$scope.user.cc_list.length;k++)
        {
          for(var l=0; l<$scope.deselectedCC.length;l++)
          {
            if($scope.user.cc_list[k] === $scope.deselectedCC[l].code)
            {
              $scope.user.cc_list.splice(k,1);
            }
          }
        }
      }
    );
  }

  getUserCCList();
  //to prepare the cc list
  function getUserCCList()
  {
    var temp = [];
    for(var i =0; i<$scope.user.costCenters.length; i++){
      temp.push($scope.user.costCenters[i].code);
    }
    $scope.user.cc_list = temp;
  }

//  function removeCCList()
//  {
//    var temp = [];
//    for(var i = 0; i < $scope.user.cc_list.length; i++){
////      if($scope.user.cc_list.code)
//      temp.splice($scope.user.costCenters[i].code);
//    }
//    $scope.user.cc_list = temp;
//  }

  $scope.options = {
    companies: companiesForAdminUse
  };

  $scope.submitted = false;

  $scope.message_error = {};

  $scope.selected_options = {
    companies: $scope.user.companies
  };

  $scope.companyEditEvent = {

    onItemDeselect: function(selected_company_item){
      deselectCompany(selected_company_item._id);
    }
  };
  //setting for company multi-select dropdown list
  $scope.settingCompany = {smartButtonMaxItems: 1,
    scrollable: true,
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    enableSearch: true,
    enableSortOnTop: true,
    closeOnSelect: true,
    smartButtonTextConverter: function(itemText, originalItem) { return "Select/deselect company"; }};

  $scope.defaultCompanyButtonText = {buttonDefaultText: "Select/deselect company"};

  //load option
  $scope.loadOptions = function (url, params, options, paramValues, scopeOptionName) {
    if ($scope.options[scopeOptionName].length > 0) {
      return $scope.options[scopeOptionName];
    }

    var deferred = $q.defer();
    $resource(
      url,
      params,
      options
    ).get(
      paramValues,
      function (resource) {
        $scope.options[scopeOptionName] = resource.content.data;
        deferred.resolve(resource.data);

      }
    );

    return deferred.promise;
  };

  $scope.loadOptionsSimple = function (url, scopeOptionName) {
    return $scope.loadOptions(url, {}, {}, {}, scopeOptionName);
  };

  function selectCostCenter(selected_company){
    $uibModal.open({
      templateUrl: "app/usersManagement/add/user-costcenter-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: userCostCenterCtrl,
      scope: $scope,
      resolve:{
        userCostCenters: function($q,companyCostCenters){
          var deferred = $q.defer();
          companyCostCenters.get(
            {
              id: selected_company._id
            },
            function (resource) {
              if (!!resource.content) {
                deferred.resolve(resource.content.data);
              }
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    })
  }

  function validateInputData() {
    if (!$scope.user.display_name || !$scope.user.employee_number ||
      $scope.empCheck === true || !getSelectedCCList() || $scope.saveDisabled
      || (!!getSelectedCCList() && getSelectedCCList().length === 0)) {
      return false;
    }
    return true;
  }

  $scope.cancelEditUser = function(){
    $state.go($scope.previousState.name, $scope.previousState.params);
  };

  //upload user detail
  $scope.saveUser = function(){
    $scope.submitted = true;

    if(!validateInputData()){
      toastr.error('Please fill all required fields');
      $scope.submitted = false;
      return;
    }
    var submit_data = {
      display_name: $scope.user.display_name,
      cc_list: getSelectedCCList(),
      receive_email_immediate: $scope.user.receive_email_immediate,
      emp_no: (currentEmpNo !== $scope.user.employee_number) ? $scope.user.employee_number : undefined,
      roles: getSelectedRolesList(),
      positions: getSelectedPositionsList(),
      superiors: getSuperiorFormattedInfo()
    };

    singleUser.put(
      {
        id: $scope.user._id
      },
      submit_data,
      function () {
        toastr.success('Changes has been saved');
        $state.go('main.user.users.detail', {id: $scope.user._id});
      },
      function (err) {
        globalFunc.objectErrorMessage(err);
      });
  };

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker) {
    if (currentEmpNo !== value) {
      globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
        switch (callback) {
          case 'empCheckTrue':
            $scope.empCheck = true;
            break;
          case 'empCheckFalse':
            $scope.empCheck = false;
            break;
        }
      });
    }else{
      $scope.empCheck = false;
    }
  }

  function getSelectedCCList(){
    var list = [];
    _.forEach($scope.user.companies, function(company){
      _.forEach(company.CCs.selected, function (costCenter) {
        list.push(costCenter.code);
      });
    });
    return list;
  }

  function getSelectedRolesList() {
    var list = [];
    _.forEach($scope.user.companies, function (company) {
      _.forEach(company.roles.selected, function (role) {
        list.push(role._id);
      });
    });
    return list;
  }

  function getSelectedPositionsList() {
    var list = [];
    _.forEach($scope.user.companies, function (company) {
      _.forEach(company.positions.selected, function (position) {
        list.push(position._id);
      });
    });
    return list;
  }

  function getSuperiorFormattedInfo() {
    var list = {};
    _.forEach($scope.user.companies, function(company) {
      if(!!company.superior.selected && !!company.superior.selected._id) {
        list[company.code] = company.superior.selected._id;
      }
      else {
        if(company.code !== 'system')
        list[company.code] = '';
      }
    });
    return list;
  }

  /**
   * Function to generate system object to display
   * @param userObject
   * Name of the object in user object
   * @param systemArray
   * Name of the array to be used
   */
  function generateSystemArray(userObject, systemArray){
    systemArray = [];
    if(!!$scope.user[userObject] && !!$scope.user[userObject].length){
      for(var i = 0; i < $scope.user[userObject].length ; i++){
        if($scope.user[userObject][i].company_code === null){
          systemArray.push($scope.user[userObject][i]);
        }
      }
      $scope.systemObject[userObject] = systemArray;
    }
  }

  function initialize(){
    $scope.systemObject = {};
    $scope.systemObject.descr = 'System';
    $scope.systemObject.code = 'system';
    $scope.systemObject._id = 'system';
    generateSystemArray('roles', 'systemRoles');
    generateSystemArray('positions', 'systemPositions');

    // Initializing the user companies if exists else create empty array for push
    $scope.user.companies = !!$scope.user.companies ? $scope.user.companies : [];
    $scope.user.companies.push($scope.systemObject);

    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    if(angular.isDefined($scope.user) && angular.isDefined($scope.user.receive_email_immediate)){
      $scope.user.email_notification_frequency = $scope.user.receive_email_immediate;
    }
  }

  initialize();
}

UsersEditCtrl.$inject = ['$scope', '$q', '$resource', '$state', 'globalFunc', 'toastr', 'editedUser',
                         '$uibModal', 'companiesForAdminUse', 'singleUser', 'companyCostCenters', '$rootScope'];

angular
  .module('metabuyer')
  .controller('UsersEditCtrl', UsersEditCtrl);


