'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user', {
        url: 'user',
        abstract: true,
        controller: 'userCtrl',
        template: '<ui-view></ui-view>',
        resolve: {
          profile: function ($q, userSettings, $state, $filter, $rootScope, updateProfile, $auth, $location, toastr, Rollbar) {
            var deferred = $q.defer();

            /**
             * check the authentication and store the url for redirection after a successful login
             * redirect the user to Login page
             */
            if (!$auth.isAuthenticated()) {
              $rootScope.redirectUrl = $location.url();
              $state.go('login');
            }

            /**
             * loading the user profile
             */
            updateProfile.get({},
              function (resource) {
              if (!!resource.content && !!resource.content.data) {
                /**
                 * store the user in the rootScope
                 * store the user in the Rollbar configuration
                 */
                $rootScope.currentUser = resource.content.data;

                  Rollbar.configure({
                    payload: {
                      person: {
                        id: $rootScope.currentUser._id,
                        username: $rootScope.currentUser.display_name,
                        email: $rootScope.currentUser.email
                      }
                    }
                  });

                  /**
                   * checking if the workspace has been selected and saved
                   */
                  if (!JSON.parse(window.localStorage.getItem('workSpace'))) {
                    if (!resource.content.data.default_company) {
                      $rootScope.companyWorkSpace = resource.content.data.companies[0];
                    }
                    else {
                      $rootScope.companyWorkSpace = resource.content.data.default_company;
                    }
                  }
                  else {
                    $rootScope.companyWorkSpace = JSON.parse(window.localStorage.getItem('workSpace'));
                  }

                  deferred.resolve(resource.content.data);
                } else {
                  Rollbar.error('an error occurred while logging in, user info: ' + $auth.getPayload());
                  toastr.info('an error occurred');
                  userSettings.loggedOut();
                  $state.go('login');
                }
              },
              function (error) {
                //failed to get profile information, console logging to get the data.
                console.log(data);
                console.log(status);
                console.log(headers);
                console.log(config);
              });

            return deferred.promise;
          }
        }
      })
    .state('main.user.mainMenu', {
        url: '',
        templateUrl: 'app/user/mainMenu/mainMenuTemplate.html',
        controller: 'mainMenuCtrl',
        abstract: true
    })
      .state('main.user.mainMenu.waiting-on-you', {
        url: '/waiting-on-you?{cursor:[^~,]}',
        template: '<div ng-include="waitingTemplate"></div>',
        controller: 'UserWaitingOnYouCtrl',
        resolve: {
          waitingList: function ($q, waitingOnUserList, $stateParams, $rootScope) {
            var deferred = $q.defer();
            waitingOnUserList.get(
              {
                cursor: $stateParams.cursor
              },
              function (resource) {
                if (!!resource.content) {
                  $rootScope.waitings = resource.content;
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User - waiting on you";
          },
          findTemplate: function ($rootScope) {
            $rootScope.waitingTemplate = $rootScope.isMobileMode ? 'app/user/waiting-on-you/waiting-on-you-mobile.html'
              : 'app/user/waiting-on-you/waiting-on-you.html';
          }
        }
      })
      .state('main.user.mainMenu.notifications', {
        url: '/notifications?{subTab:[a-zA-Z0-9]+}&{cursor:[^~,]}',
        templateUrl: 'app/user/notifications/notifications.html',
        controller: 'UserNotificationsCtrl',
        resolve: {
          notificationListing : function ($q, unreadNotificationList, notificationList, $stateParams) {
            if (!$stateParams.subTab) {
              $stateParams.subTab = 'unread';
            }
            var deferred = $q.defer();
            if($stateParams.subTab == 'all'){
              notificationList.get(
                {
                  cursor: $stateParams.cursor
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                  }
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else if($stateParams.subTab == 'unread'){
              unreadNotificationList.get(
                {
                  offset: 1000
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                  }
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }

            return deferred.promise;
          },
          $title: function($stateParams){
            return "User - notifications";
          }
        }
      })
      .state('main.user.mainMenu.userProfile', {
        url: '/profile',
        templateUrl: 'app/user/settings/userProfile/userProfileTemplate.html',
        controller: 'userProfileCtrl',
        resolve: {
          $title: function($stateParams){
            return "User - profile";
          }
        }
      })
  }]);
