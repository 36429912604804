'use strict';

function adminSettingsCtrl ($scope, settings, adminSettings, globalFunc, $state, toastr) {

  $scope.settings = {};
  $scope.submitSettings = submitSettings;
  $scope.cancelSubmit = cancelSubmit;
  $scope.resetSettings = resetSettings;

  $scope.expiryDates = [
    {value: 60, descr: '60 Days'},
    {value: 90, descr: '90 Days'},
    {value: 120, descr: '120 Days'},
    {value: 150, descr: '150 Days'}
  ];

  $scope.passwordLength = [
    {value: 6, descr: '6 Characters'},
    {value: 8, descr: '8 Characters'},
    {value: 10, descr: '10 Characters'}
  ];

  $scope.inactiveDuration = [
    {value: 60, descr: '60 Days'},
    {value: 90, descr: '90 Days'},
    {value: 120, descr: '120 Days'},
    {value: 150, descr: '150 Days'}
  ];

  $scope.passwordHistory = [
    {value: 2, descr: '2 passwords'},
    {value: 3, descr: '3 passwords'},
    {value: 4, descr: '4 passwords'},
    {value: 5, descr: '5 passwords'}
  ];

  $scope.loginAttempts = [
    {value: 2, descr: '2 retries'},
    {value: 3, descr: '3 retries'},
    {value: 4, descr: '4 retries'},
    {value: 5, descr: '5 retries'}
  ];

  $scope.idleTime = [
    {value: 15, descr:'15 minutes'},
    {value: 30, descr:'30 minutes'},
    {value: 60, descr:'60 minutes'},
    {value: 90, descr:'90 minutes'},
    {value: 120, descr:'120 minutes'}
  ];

  $scope.unlockAfterMinutes = [
    {value: 1, descr:'1 minute'},
    {value: 2, descr:'2 minute'},
    {value: 3, descr:'3 minute'},
    {value: 5, descr:'5 minute'},
    {value: 10, descr:'10 minute'}
  ];

  $scope.booleanArray = [
    {value: true, descr: ' True'},
    {value: false, descr: ' False'}
  ];

  function submitSettings() {
    var data = {
      config: {}
    };

    /**
     * Preparing the password object for payload
     */
    data.config.password = {};
    data.config.password.len = angular.copy($scope.settings.len.value);
    data.config.password.expiry = angular.copy($scope.settings.expiry.value);
    data.config.password.lock_after = angular.copy($scope.settings.lock_after.value);
    data.config.password.history = angular.copy($scope.settings.history.value);
    data.config.password.max_login_attempts = angular.copy($scope.settings.max_login_attempts.value);
    data.config.password.multicase = angular.copy($scope.settings.multicase.value);
    data.config.password.numbers = angular.copy($scope.settings.numbers.value);
    data.config.password.symbols = angular.copy($scope.settings.symbols.value);
    data.config.password.unlock_after_minutes = angular.copy($scope.settings.unlock_after_minutes.value);

    /**
     * Preparing the session timeout field and enable auditing
     */
    data.config.session_timeout = angular.copy($scope.settings.session_timeout.value);

    data.config.enable_auditing = angular.copy($scope.settings.enable_auditing.value);

    adminSettings.put(
      {}, data,
      function () {
        toastr.success('Successfully applied settings');
      },
      function () {
        toastr.error('Failed to apply settings');
      }
    );
  }

  function cancelSubmit() {
    $state.go('main.user.settings.mainPanel');
  }

  function resetSettings() {
    swal({
        title: 'Confirm reset settings?',
        text: 'The admin settings will be reverted to original values.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          var data = {
            config: {}
          };

          /**
           * Preparing the default values in password object for payload
           */
          data.config.password = {};
          data.config.password.len = 8;
          data.config.password.expiry = 90;
          data.config.password.lock_after = 90;
          data.config.password.history = 3;
          data.config.password.max_login_attempts = 5;
          data.config.password.multicase = true;
          data.config.password.numbers = true;
          data.config.password.symbols = false;
          data.config.password.unlock_after_minutes = 1;

          /**
           * Preparing the default session timeout and enable auditing
           */
          data.config.session_timeout = 60;

          data.config.enable_auditing = false;

          adminSettings.put(
            {}, data,
            function () {
              toastr.success('Successfully reverted settings to default values');
              cancelSubmit();
            },
            function () {
              toastr.error('Failed to revert settings to default values');
            }
          );
        }
      });
  }

  function initialize() {
    if (!!settings.data && !!settings.data.config) {
      $scope.settings = settings.data.config.password;
      $scope.settings.session_timeout = settings.data.config.session_timeout;
      $scope.settings.enable_auditing = settings.data.config.enable_auditing;
    }

    /**
     * Initializing the password settings
     */
    $scope.settings.expiry = globalFunc.findInArray(
      $scope.expiryDates, 'value', $scope.settings.expiry
    );
    $scope.settings.lock_after = globalFunc.findInArray(
      $scope.inactiveDuration, 'value', $scope.settings.lock_after
    );
    $scope.settings.history = globalFunc.findInArray(
      $scope.passwordHistory,'value', $scope.settings.history
    );
    $scope.settings.max_login_attempts = globalFunc.findInArray(
      $scope.loginAttempts,'value', $scope.settings.max_login_attempts
    );
    $scope.settings.len = globalFunc.findInArray(
      $scope.passwordLength,'value', $scope.settings.len
    );
    $scope.settings.multicase = globalFunc.findInArray(
      $scope.booleanArray, 'value', $scope.settings.multicase
    );
    $scope.settings.numbers = globalFunc.findInArray(
      $scope.booleanArray, 'value', $scope.settings.numbers
    );
    $scope.settings.symbols = globalFunc.findInArray(
      $scope.booleanArray, 'value', $scope.settings.symbols
    );
    $scope.settings.unlock_after_minutes = globalFunc.findInArray(
      $scope.unlockAfterMinutes, 'value', $scope.settings.unlock_after_minutes
    );

    /**
     * Initializing the session timeout and enable auditing settings
     */
    $scope.settings.session_timeout = globalFunc.findInArray(
      $scope.idleTime, 'value', $scope.settings.session_timeout
    );
    $scope.settings.enable_auditing = globalFunc.findInArray(
      $scope.booleanArray, 'value', $scope.settings.enable_auditing
    );
  }

  initialize();
}
adminSettingsCtrl.$inject = ['$scope', 'settings', 'adminSettings', 'globalFunc', '$state', 'toastr'];

angular
  .module('metabuyer')
  .controller('adminSettingsCtrl', adminSettingsCtrl);
