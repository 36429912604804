'use strict';
/**
 * @name addAttachmentCtrl
 *
 * @description
 * Controller for add attachment in attachmentCategory
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

function addAttachmentCtrl(
  $scope, attachmentCategory, globalFunc, $uibModalInstance, searchModule, module, editingData, $rootScope
) {

  $scope.attachment           = {};
  $scope.attachmentCategories = [];
  $scope.attachmentFields     = [];
  $scope.datepickerOpened     = {};
  $scope.editing              = !!editingData;
  $scope.dateOptions          = { formatYear: 'yy', startingDay: 1 };

  $scope.close                = close;
  $scope.onSelectCategory     = onSelectCategory;
  $scope.saveAttachment       = saveAttachment;
  $scope.openDatepicker       = openDatepicker;

  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  function close() {
    $uibModalInstance.close();
  }

  /**
   * Save the attachment
   */
  function saveAttachment() {
    if (!!$scope.editing)
      saveAttachmentDescription($scope.attachmentFields, $scope.attachment, 'update');
    else
      saveAttachmentDescription($scope.attachmentFields, $scope.attachment, 'add');
  }

  /**
   * save in local variable
   * @param attachmentFields
   * @param attachment
   * @param action
   */
  function saveAttachmentDescription(attachmentFields, attachment, action) {
    var attachmentDescription = {
      attachment_category: attachment.category,
      description_array: prepareAttachmentArray(attachmentFields)
    };
    if (action === 'add' || !!$scope.attachment.file) {
      attachmentDescription.attachment_detail = {
        title: attachment.file.name,
        creator: {
          display_name: $rootScope.currentUser.display_name
        },
        created_at: new Date(),
        file: attachment.file
      };
    } else {
      attachmentDescription.attachment_detail = $scope.attachmentDetail;
    }

    $uibModalInstance.close(attachmentDescription);
  }

  function prepareAttachmentArray(attachmentFields) {
    _.forEach(attachmentFields, function (field) {
      if (field.type.toLowerCase() === 'mongodate' && field.value instanceof Date && !isNaN(field.value.valueOf()))
        field.value = globalFunc.convertDateToTimestamp(field.value);
    });

    return attachmentFields;
  }

  /**
   * On selecting category
   * @param category
   */
  function onSelectCategory(category) {
    attachmentCategory.get({
      id: category._id
    }, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id)
        $scope.attachmentFields = resource.content.data.description_fields;
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * get attachment category
   */
  function getAttachmentCategory() {
    var params = {
      module: 'attachment-categories',
      'criteria[host_object_class]': 'Metabuyer\\Models\\' + module,
      order_by: 'name',
      offset: 0,
      order: 1
    };
    searchModule.get(
      params,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $scope.attachmentCategories = resource.content.data;

        isEditing();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function isEditing() {
    if (!!editingData) {
      $scope.attachment.category  = angular.copy(editingData.attachment_category);
      $scope.attachmentFields     = angular.copy(editingData.description_array);

      // Using angular copy causes “TypeError: Illegal invocation”
      $scope.attachmentDetail     =  _.cloneDeep(editingData.attachment_detail);

      $scope.attachment.status    = angular.copy(editingData.status);
    }
  }

  function initialize() {
    getAttachmentCategory();
  }

  initialize();
}
addAttachmentCtrl.$inject = [
  '$scope', 'attachmentCategory', 'globalFunc', '$uibModalInstance', 'searchModule', 'module', 'editingData', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('addAttachmentCtrl', addAttachmentCtrl);
