'use strict';

angular.module('metabuyer.services.catalogServices', ['ngResource'])
.factory('itemList', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.list,
        {
          user_id: '@user_id',
          code: '@code',
          cc: '@cc',
          supplier_id: '@supplier_id'
        });
    }])
  .factory('itemDetailsInformation', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.details,
        {
          user_id: '@user_id',
          item_id: '@item_id'
        });
    }])
  .factory('searching', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.searching,
        {
          user_id: '@user_id',
          supplier_id: '@supplier_id'
        });
    }])
  .factory('starringItem', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.starringItem,
        {
          user_id: '@user_id',
          item_id: '@item_id'
        }, {

          'update': { method: 'PUT' }

        });
    }])
  .factory('catalogShoppingSearch', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.catalog.search,
        {
          user_id: '@user_id',
          type: '@type'
        });
    }]);
