'use strict';
/**
 * @name UsersCtrl
 * @desc Controller for users
 */
function UsersCtrl($scope, $state, Users, profile, $rootScope, UserPermissions) {
  $scope.$emit('pageTitleChanged', 'Manage Users');


  $scope.tabData = [
    {
      heading: 'Manage Users',
      route: 'main.user.users.manage',
      key: 'manageUsers',
      menu: [
        {
          heading: 'All Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'all',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Active Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'active',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Pending Approval Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'pending',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Inactive Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'inactive',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Rejected Users',
          route: 'main.user.users.manage',
          key: 'manageUsers',
          params: {
            status: 'rejected',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        }
      ]
    },
    {
      heading: 'Add',
      route: 'main.user.users.add',
      key: 'addUser',
      menu: [
        {
          heading: 'Add a User',
          key: 'addUser',
          route: 'main.user.users.add'
        },
        {
          heading: 'Import Users',
          key: 'addUser',
          route: 'main.user.users.import'
        }
      ]
    },
    {
      heading: 'Escalation',
      route: 'main.user.users.escalation',
      key: 'escalation',
      cursor: null,
      filter: null,
      query: null,
      type: null
    },
    {
      heading: 'Delegation',
      route: 'main.user.users.delegation',
      key: 'delegation',
      cursor: null,
      filter: null,
      query: null,
      type: null
    }
  ];
  var onlineUsersTab = {
    heading: 'Online Users',
    route: 'main.user.users.onlineUsers',
    key: 'online'
  };
  if (!!UserPermissions.checkUserSessionAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkUserSessionAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkUserSessionAccess(profile, 'D', $rootScope.changeWorkSpace)) {
    $scope.tabData.push(onlineUsersTab);
  }

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */

  $scope.$on('$stateChangeStart', function( event, data, toParams ) {

    if (!!data.name && data.name.indexOf('escalation')> -1) {
      $scope.searchState = 'main.user.users.escalation';
      $scope.searchPlaceholder = 'Search users in escalation';
      $scope.parentState = 'main.user.users.escalation';
      $scope.parentStateParams = toParams;
      $scope.columns = escalationColumns;

    }
    else if (!!data.name && data.name.indexOf('delegation')> -1) {
      $scope.searchState = 'main.user.users.delegation';
      $scope.searchPlaceholder = 'Search delegations';
      $scope.parentState = 'main.user.users.delegation';
      $scope.parentStateParams = toParams;
      $scope.columns = delegationColumns;

    }
    else {

      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = toParams;
      $scope.columns = userColumns;
    }

    if(!!toParams.status){
      $scope.status = toParams.status;
      $scope.parentStateParams = toParams;
    }
  });

  var userColumns = [
    {id: 'display_name', label: 'Full Name'},
    {id: 'email', label: 'Email'},
    {id: 'emp_no', label: 'Employee Number'},
    {id: 'company', label: 'Company(s)', unsortable: true,  unsearchable: true},
    {id: 'costCenter', label: 'Cost Center(s)', unsortable: true,  unsearchable: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'role', label: 'Role', unsortable: true, unsearchable: true},
    {id: 'status', label: 'Status', conditional: true, condition: 'all'}
  ];

  var escalationColumns = [
    {id: 'user.display_name', label: 'Full Name'},
    {id: 'user.email', label: 'Email'},
    {id: 'company.descr', label: 'Company'},
    {id: 'superior.display_name', label: 'Superior Name'},
    {id: 'superior.email', label: 'Superior Email'}
    ];

  var delegationColumns = [
    {id: 'creator.display_name', label: 'Creator'},
    {id: 'user.display_name', label: 'Delegator'},
    {id: 'delegatee.display_name', label: 'Delegatee'},
    {id: 'company_code', label: 'Company Code'},
    {id: 'company.descr', label: 'Company Name'},
    {
      id: 'start_date',
      label: 'Start Date',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'end_date',
      label: 'End Date',
      type: 'date',
      unsearchable: true
    },
    {id: 'reason', label: 'Reason', unsortable: true,  unsearchable: true},
    {id: 'status', label: 'Status', unsearchable: true}
  ];

  function initialize(){

    if (!!$state.current.name && $state.current.name.indexOf('escalation')> -1) {
      $scope.searchState = 'main.user.users.escalation';
      $scope.searchPlaceholder = 'Search users in escalation';
      $scope.parentState = 'main.user.users.escalation';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
      $scope.columns = escalationColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('add')> -1) {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
      $scope.columns = userColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('delegation')> -1) {
      $scope.searchState = 'main.user.users.delegation';
      $scope.searchPlaceholder = 'Search delegations';
      $scope.parentState = 'main.user.users.delegation';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
      $scope.columns = delegationColumns;
    }
    else if (!!$state.current.name && $state.current.name.indexOf('onlineUsers')> -1) {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = onlineUsersTab;
      $scope.columns = userColumns;
    }
    else {
      $scope.searchState = 'main.user.users.manage';
      $scope.searchPlaceholder = 'Search users';
      $scope.parentState = 'main.user.users.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
      $scope.columns = userColumns;
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }
  }

  initialize();
}
UsersCtrl.$inject = ['$scope', '$state', 'Users', 'profile', '$rootScope', 'UserPermissions'];

angular
  .module('metabuyer')
  .controller('UsersCtrl', UsersCtrl);
