/**
 * @name userDelegation
 *
 * @requires $scope
 * @requires $state
 * @requires $stateParams
 * @requires pathConstants (Metabuyer service)
 * @requires toastr
 * @requires $http
 * @requires $rootScope
 * @requires delegatedToAUser (Metabuyer service)
 * @requires massDelegate (Metabuyer service)
 * @requires delegations (Metabuyer service)
 *
 * @description Directive for delegation settings under profile and user management
 * This directive is for any delegation logic involved
 *
 * Logic involved:
 * search users from the available users, and delegate the tasks to certain user for a period of time
 *
 * @function users
 * Function to get list of users by email or username for typeahead
 *
 * @function delegate
 * Function to Delegate to another user
 *
 * @function checkToSubmit
 * Function to check the submitted data
 *
 * @function removeDelegationFunction
 * Function to remove Delegation
 *
 * @listener:
 * $watcher for disabling the Delegation
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>, Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('userDelegation', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/userDelegation/userDelegationTemplate.html',
      scope: {
        source: '@',
        user: '=?'
      },
      controller: function ($scope, $state, $stateParams, pathConstants, toastr, $http, $rootScope, delegatedToAUser, delegations,
                            globalFunc, $uibModal, massRemoveDelegation) {

        /**
         * Function to generate delegatee object
         * @param data
         */
        function generateDelegateeObject(data) {
          if (!!data && !!data.length) {
            _.forEach($scope.user.companies, function (company) {
              var delegation = globalFunc.findInArray(data, 'company_code', company.code);
              if (!!delegation) {
                company.delegation_id = delegation._id;
                company.delegatee = delegation.delegatee;
                company.delegatedReason = delegation.reason;
                company.delegateStatus = delegation.status;
                company.delegateStartDate = delegation.start_date;
                company.delegateEndDate = delegation.end_date;
              }
            })
          }
        }

        /**
         * returns selected Companies as an array of company codes
         * @returns {Array}
         */
        function getSelectedCompanies() {
          var selectedCompanies = {};
          selectedCompanies.codes = [];
          selectedCompanies.companies = [];
          selectedCompanies.delegationIds = [];

          _.forEach($scope.user.companies, function (company) {
            if (!!company.selected) {
              selectedCompanies.codes.push(company.code);
              selectedCompanies.companies.push(company);

              if (!!company.delegation_id) {
                selectedCompanies.delegationIds.push(company.delegation_id);
              }
            }
          });

          return selectedCompanies;
        }

        /**
         * Function to select all companies
         * @param select
         */
        function selectAllCompanies(select) {
          _.forEach($scope.user.companies, function (company) {
            if(company.descr !== 'System') {
              // Set all selected to true or false
              company.selected = select;
            }
          });
        }

        /**
         * Function to open the delegation modal
         */
        function openDelegationModal() {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/userDelegation/userDelegationModal.html',
            backdrop: 'static',
            keyboard: false,
            scope: $scope,
            controller: userDelegationModalCtrl,
            resolve: {
              selectedCompanies: function () {
                return getSelectedCompanies().codes;
              }
            }
          });

          modalInstance.result.then(function (newValue) {
            if (!!newValue) {
              loadDelegations();
            }
          });
        }

        /**
         * Function to check selected company to add or remove
         * @param action
         * @returns {boolean}
         */
        function checkSelectedCompany(action) {
          var selectedCompanies = getSelectedCompanies();
          var countCompanyHasDelegation = selectedCompanies.delegationIds.length;
          selectedCompanies = selectedCompanies.companies.length;

          if (action === 'add') {
            return (countCompanyHasDelegation === 0 && !!selectedCompanies);
          } else if (action === 'remove') {
            return (!!selectedCompanies && selectedCompanies === countCompanyHasDelegation);
          }
        }

        /**
         * Function to mass remove delegation
         */
        function massRemove() {
          var delegationIds = getSelectedCompanies().delegationIds;

          if (!!delegationIds.length) {
            swal(
              {
                title: 'Confirm remove Delegations',
                text: 'Selected Delegations will be removed',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ed5565',
                confirmButtonText: 'Remove',
                closeOnConfirm: true
              },
              function (confirm) {
                if (!!confirm) {
                  massRemoveDelegation.delete({
                    'ids[]': delegationIds
                  }, function (resource) {
                    if (!!resource && !!resource.content) {
                      clearSelectedCompaniesData();
                      toastr.success(resource.content.message);
                    }
                  }, function (error) {
                    globalFunc.objectErrorMessage(error.data);
                  });
                }
              }
            );
          } else {
            toastr.error('There is no item selected')
          }
        }

        /**
         * Function to clear data for removed delegations
         */
        function clearSelectedCompaniesData() {
          _.forEach(getSelectedCompanies().codes, function (selectedCompanyCode) {
            _.forEach($scope.user.companies, function (company) {
              if (company.code === selectedCompanyCode) {
                company.selected = false;
                company.delegation_id = undefined;
                company.delegatee = undefined;
                company.delegatedReason = undefined;
                company.delegateStatus = undefined;
                company.delegateStartDate = undefined;
                company.delegateEndDate = undefined;
              }
            });
          });
        }

        /**
         * Function to load the delegations
         */
        function loadDelegations() {
          var companyCodes = [];
          _.forEach($scope.user.companies, function (company) {
            if (!!company && !!company.code) {
              companyCodes.push(company.code);
            }
          });

          if (companyCodes.length > 0) {
            delegatedToAUser.get(
              {
                userId: $scope.user._id,
                'company_codes[]': companyCodes
              },
              function (resource) {
                if (!!resource && !!resource.content && (resource.content.data instanceof Array)) {
                  generateDelegateeObject(resource.content.data);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
        }

        function initialize() {
          $scope.delegation = {};

          $scope.getSelectedCompanies = getSelectedCompanies;
          $scope.selectAllCompanies = selectAllCompanies;
          $scope.openDelegationModal = openDelegationModal;
          $scope.checkSelectedCompany = checkSelectedCompany;
          $scope.massRemove = massRemove;
          loadDelegations();
        }

        initialize();
      }
    }
  });
