'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.catalogView', {
        url: '/catalogView',
        controller: 'catalogViewCtrl',
        templateUrl: 'app/catalogView/catalogView.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.ImportItemDetail(profile, 'L')
              && !UserPermissions.ImportItemDetail(profile, 'O')) {
              $state.go('notAuthorized');
            }
            if (!UserPermissions.ImportItemSummary(profile, 'L')
              && !UserPermissions.ImportItemSummary(profile, 'O')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.catalogView.catalogItemList', {
        templateUrl: 'app/catalogView/manage/manage.html',
        url: '/manage?&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'viewCatalogItemsCtrl',
        resolve: {
          dataList: function ($q, globalFunc, $stateParams, searchModule) {

            var deferred = $q.defer();
            var params = {};
            if (!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.filter);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else if ($stateParams.type === 'double') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                temp = 'criteria[0][' + $stateParams.filter + ']';
                params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
              }

              params = {
                module: 'catalog-details',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
            } else {
              params = {
                module: 'catalog-details',
                offset: 20,
                'criteria[0][item_name]': $stateParams.query,
                'criteria[0][description]': $stateParams.query,
                'criteria[0][commodity_code]': $stateParams.query,
                'criteria[0][commodity_descr]': $stateParams.query,
                'criteria[0][supplier.descr]': $stateParams.query,
                'criteria[0][supplier_part_no]': $stateParams.query,
                'criteria[0][payment_term.descr]': $stateParams.query,
                'criteria[0][currency.code]': $stateParams.query,
                'criteria[0][Uom.code]': $stateParams.query,
                'criteria[0][creator.display_name]': $stateParams.query,
                'criteria[0][updater.display_name]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content);
                else
                  deferred.resolve({data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function() {
            return 'Catalog - Items - listing';
          }
        }
      })
  }]);
