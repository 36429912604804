'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.companiesMgmt', {
        url: '/companies',
        controller: 'CompaniesCtrl',
        templateUrl: 'app/companiesManagement/companiesManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;
            if (!!profile.roles && profile.roles.length > 0) {
              for (var index in profile.roles) {
                if ((profile.roles[index].name.toLowerCase() === 'admin' && profile.roles[index].company_code === null) ||
                profile.roles[index].name.toLowerCase() === 'tenant_super_admin') {
                  check = true;
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      //Start of company states
      .state('main.user.companiesMgmt.addCompany', {
        url: '/add-company',
        templateUrl: 'app/companiesManagement/companies/add/add.html',
        controller: 'addCompanyCtrl',
        resolve:{
          BU: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          industryList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'industries',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currenciesList: function ($q, Currencies) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('currencies'))) {
              Currencies.get(
                {},
                function (resource) {
                  window.localStorage.setItem("currencies", JSON.stringify(resource.content.data));
                  deferred.resolve(resource.content.data);
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else{
              var content = JSON.parse(window.localStorage.getItem('currencies'));
              if(!!content){
                deferred.resolve(content);
              }
              else{
                deferred.resolve([]);
              }

            }
            return deferred.promise;
          },
          expenseTypesList: function($q, metabuyerCache, expenseTypeUserList, globalFunc) {
            var deferred = $q.defer();
            if (!!metabuyerCache.get('expenseTypes')) {
              deferred.resolve(metabuyerCache.get('expenseTypes'));
            } else {
              expenseTypeUserList.get({},
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    metabuyerCache.put('expenseTypes', resource.content.data)
                  }
                }, function (error) {
                  globalFunc.objectErrorMessage(error);
                  deferred.resolve([]);
                }
              )
            }
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Add";
          }
        }
      })
      .state('main.user.companiesMgmt.manageCompanies', {
        url: '/manage?{industry_code:[a-zA-Z0-9]+}&{bu_code:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/manage/manage.html',
        controller: 'manageCompanyCtrl',
        resolve: {
          companies: function($q, $stateParams, searchModule) {
            var deferred = $q.defer();
            //For listing companies for a particular BU by id
            if (!!$stateParams.bu_code) {
              searchModule.get(
                {
                  module: 'companies',
                  'criteria[bu_code]': $stateParams.bu_code
                },
                function (resource) {
                  deferred.resolve(resource.content || {data: []});
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
              return deferred.promise;
            }
            //For listing companies for a particular industry by id
            else if (!!$stateParams.industry_code) {
              searchModule.get(
                {
                  module: 'companies',
                  'criteria[industry_code]': $stateParams.industry_code
                },
                function (resource) {
                  deferred.resolve(resource.content || {data: []});
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
              return deferred.promise;
            }
            //For listing companies
            else {
              searchModule.get(
                {
                  module: 'companies',
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                },
                function (resource) {
                  deferred.resolve(resource.content);
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
              return deferred.promise;
            }
          },
          $title: function () {
            return "Companies - listing";
          }
        }
      })
      .state('main.user.companiesMgmt.searchCompanies', {
        url: '/search?{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/manage/manage.html',
        controller: 'manageCompanyCtrl',
        resolve: {
          action: function(){
            return "search";
          },
          companies: function($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.query);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = 'criteria[0][' + $stateParams.filter + ']';
              params = {
                module: 'companies',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };

              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
              }
            }
            else{
              params = {
                module: 'companies',
                offset: 20,
                'criteria[0][code]': $stateParams.query,
                'criteria[0][descr]': $stateParams.query,
                'criteria[0][reg_no]': $stateParams.query,
                'criteria[0][currency_code]': $stateParams.query,
                criteria_operator: 'or',
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data){
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function () {
                deferred.resolve({data: []});
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - search";
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies', {
        url: '/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/details/details.html',
        controller: 'companyDetailCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams, toastr, companyGlobalFunc) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
                companyGlobalFunc.setCompanyData(resource.content.data);
              },
              function (error) {
                if(!!error.data&& !!error.data.content){
                  toastr.error(error.data.content.error);
                }
              }
            );
            return deferred.promise;
          },
          companies: function($q, searchModule, $stateParams, toastr) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                toastr.error('Error, Failed to get the companies');
              }
            );
            return deferred.promise;
          },
          currenciesList: function ($q, Currencies) {
            var deferred = $q.defer();
            if (!JSON.parse(window.localStorage.getItem('currencies'))) {
              Currencies.get(
                {},
                function (resource) {
                  window.localStorage.setItem("currencies", JSON.stringify(resource.content.data));
                  deferred.resolve(resource.content.data);
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else{
              var content = JSON.parse(window.localStorage.getItem('currencies'));
              if(!!content){
                deferred.resolve(content);
              }
              else{
                deferred.resolve([]);
              }

            }
            return deferred.promise;
          },
          BUs: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0,

                // Get only active BU = 1
                'criteria[is_active]': 1
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          industryList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'industries',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyCurrencies: function($q, companyCurrencies, $stateParams) {
            var deferred = $q.defer();
            companyCurrencies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          expenseTypesList: function($q, metabuyerCache, expenseTypeUserList, globalFunc) {
            var deferred = $q.defer();
            if (!!metabuyerCache.get('expenseTypes')) {
              deferred.resolve(metabuyerCache.get('expenseTypes'));
            } else {
              expenseTypeUserList.get({},
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    metabuyerCache.put('expenseTypes', resource.content.data)
                  }
                }, function (error) {
                  globalFunc.objectErrorMessage(error);
                  deferred.resolve([]);
                }
              )
            }
            return deferred.promise;
          },
          $title: function () {
            return "Companies - details";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesExchangeRate', {
        url: '/manage/{id:[a-zA-Z0-9]+}/exchange-rate?{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/exchangeRate/manage.html',
        controller: 'companyExchangeRateCtrl',
        resolve: {
          //TODO: have this endpoint child of the company details so that the loading is not done twice (Ahmed Saleh, 25/7/2016)
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyExchangeRate: function($q, companyDetails, searchModule,$stateParams, exportSearch){
            var deferred = $q.defer();

            var params = {
              module: 'exchange-rate',
              offset: 20,
              'criteria[company_id]': companyDetails.data._id,
              cursor: $stateParams.cursor,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currenciesList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'currencies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Exchange Rate";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesContent', {
        url: '/{id:[a-zA-Z0-9]+}/content',
        templateUrl: 'app/companiesManagement/companies/content/content.html',
        controller: 'companyContentCtrl',
        resolve: {
          $title: function () {
            return 'Companies - Content Management';
          }
        }
      })
      .state('main.user.companiesMgmt.companiesUsers', {
        url: '/manage/{id:[a-zA-Z0-9]+}/users?{code:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{query:[a-zA-Z0-9]+}&{filter:[^~,]}&{type:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/users/manage.html',
        controller: 'companyUsersCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyUsers: function($q, searchModule, $stateParams, globalFunc) {
            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.query);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = 'criteria[1][' + $stateParams.filter + ']';
              params = {
                module: 'users',
                'criteria[0][companies][]': $stateParams.code,
                'criteria_operator': 'and',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
              }
            }else{
              params = {
                module: 'users',
                'criteria[0][companies][]': $stateParams.code,
                'criteria[1][display_name]': $stateParams.query,
                'criteria[1][email]': $stateParams.query,
                'criteria[1][emp_no]': $stateParams.query,
                'criteria[1][$operator]': !!$stateParams.query ? 'or' : null,
                'criteria_operator': 'and',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }
            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                  deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Users";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesAccountCode', {
        url: '/manage/{id:[a-zA-Z0-9]+}/account-code?{query:[^~,]}+{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}&{code:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/accountCode/manage.html',
        controller: 'companyAccountCodeCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyAccountCodes: function($q, companyDetails, searchModule, $stateParams, exportSearch){
            var deferred = $q.defer();

            var params = {
              module: 'account-code',
              'criteria[name]': $stateParams.query,
              'criteria[descr]': $stateParams.query,
              'criteria[company_code]':  companyDetails.data.code,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - details";
          }
        }
      })
      //End of company states

      //Start of business unit states
      .state('main.user.companiesMgmt.addBU', {
        url: '/add-BU',
        templateUrl: 'app/companiesManagement/businessUnits/add/add.html',
        controller: 'addBUCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "Companies - Business Unit - Add";
          }
        }
      })
      .state('main.user.companiesMgmt.manageBusinessUnits', {
        url: '/businessUnits/manage?{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}&{code:[^~,]}&{query:[^~,]}&{filter:[^~,]}&{type:[^~,]}',
        templateUrl: 'app/companiesManagement/businessUnits/manage/manage.html',
        controller: 'manageBUCtrl',
        resolve: {
          BU: function($q, searchModule, $stateParams, globalFunc, exportSearch) {
            var deferred = $q.defer();
            var params = {};
            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {
                /**
                 * check for the status
                 */
                if ($stateParams.filter.toLowerCase() === 'status') {
                  var statusCode = globalFunc.getStatusCode($stateParams.query);
                  if (statusCode === null) {
                    statusCode = 1;
                  }
                }
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                params = {
                  module: 'business-units',
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
                if ($stateParams.type === 'date') {
                  temp = 'criteria[' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
                }
              } else {
                params = {
                  module: 'business-units',
                  'criteria[0][code]': $stateParams.query,
                  'criteria[0][descr]': $stateParams.query,
                  criteria_operator: !!$stateParams.query ? 'or' : null,
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            else{
              params = {
                module: 'business-units',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Business Unit - Listing";
          }
        }
      })
      .state('main.user.companiesMgmt.detailBusinessUnits', {
        url: '/manage/businessUnits/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/businessUnits/details/details.html',
        controller: 'BUDetailCtrl',
        resolve: {
          BUDetails: function($q, singleBU, $stateParams) {
            var deferred = $q.defer();
            singleBU.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Business Unit - Details";
          }
        }
      })
      //End of business unit states

      //Start of industries states
      .state('main.user.companiesMgmt.addIndustry', {
        url: '/add-industry',
        templateUrl: 'app/companiesManagement/industries/add/add.html',
        controller: 'addIndustryCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "Companies - Industry - Add";
          }
        }
      })
      .state('main.user.companiesMgmt.manageIndustries', {
        url: '/industries/manage?{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}&{query:[^~,]}&{filter:[^~,]}&{type:[^~,]}',
        templateUrl: 'app/companiesManagement/industries/manage/manage.html',
        controller: 'manageIndustryCtrl',
        resolve: {
          industryList: function($q, searchModule, $stateParams, globalFunc, exportSearch) {
            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.query) {
              if (!!$stateParams.filter) {
                /**
                 * check for the status
                 */
                if ($stateParams.filter.toLowerCase() === 'status') {
                  var statusCode = globalFunc.getStatusCode($stateParams.query);
                  if (statusCode === null) {
                    statusCode = 1;
                  }
                }
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                params = {
                  module: 'industries',
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
                if ($stateParams.type === 'date') {
                  temp = 'criteria[' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
                }
              } else {
                params = {
                  module: 'industries',
                  'criteria[code]': $stateParams.query,
                  'criteria[descr]': $stateParams.query,
                  criteria_operator: !!$stateParams.query ? 'or' : null,
                  offset: 20,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            else{
              params = {
                module: 'industries',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Industries - Listing";
          }
        }
      })
      .state('main.user.companiesMgmt.detailIndustries', {
        url: '/manage/industries/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/industries/details/details.html',
        controller: 'industryDetailCtrl',
        resolve: {
          industryDetails: function($q, singleIndustry, $stateParams) {
            var deferred = $q.defer();
            singleIndustry.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Industry - Details";
          }
        }
      })
      //end of industries states

      .state('main.user.companiesMgmt.costCenterDetail', {
        url: '/manage/{companyId:[a-zA-Z0-9]+}/manage/cost-center/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/costcenters/address/manage.html',
        controller: 'costCenterAddressesCtrl',
        resolve: {
          costCenterDetails: function($q, CostCenters, $stateParams) {
            var deferred = $q.defer();
            CostCenters.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyCostCentersList: function($q, companyCostCenters, $stateParams) {
            var deferred = $q.defer();
            companyCostCenters.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          addresses: function($q, singleAddress, companyDetails) {
            var deferred = $q.defer();
            if (!!companyDetails && !!companyDetails.data && !!companyDetails.data.related_addresses_ids && !!companyDetails.data.related_addresses_ids.length) {
              var promises = [];
              angular.forEach(companyDetails.data.related_addresses_ids, function (id) {
                var promise = singleAddress.get({
                    id: id
                  }
                );
                promises.push(promise.$promise);
              });
              var cleanedAddress = [];
              $q.all(promises).then(function (resource) {
                angular.forEach(resource, function (address) {
                  if (!!address && !!address.content && !!address.content.data) {
                    cleanedAddress.push(address.content.data);
                  }
                });
              }).catch().finally(function () {
                deferred.resolve(cleanedAddress);
              });
            } else {
              deferred.resolve([]);
            }
            return deferred.promise;

          },
          $title: function ($stateParams) {
            return "Companies - Cost Center - Addresses - List";
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies.addCostCenter', {
        url: '/cost-center',
        templateUrl: 'app/companiesManagement/costcenters/add/add.html',
        params: {costCenterId: null},
        controller: 'addCostCenterCtrl',
        resolve: {
          companyUsers: function ($q, purchaseRequisitionsServices, globalFunc, $stateParams) {
            var deferred = $q.defer();

            purchaseRequisitionsServices.getContactPersonsList(
              {
                id: $stateParams.id
              }, function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              }, function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          costCenterData: function () {
            return [];
          },
          costCenterUsers: function () {
            return [];
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies.updateCostCenter', {
        url: '/cost-center/{costCenterId:[a-zA-Z0-9]+}?',
        templateUrl: 'app/companiesManagement/costcenters/add/add.html',
        controller: 'addCostCenterCtrl',
        resolve: {
          companyUsers: function ($q, companyActiveUsers, globalFunc, $stateParams) {
            var deferred = $q.defer();

            companyActiveUsers.get(
              {
                id: $stateParams.id
              }, function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              }, function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          costCenterData: function ($q, CostCenters, $stateParams) {
            var deferred = $q.defer();

            CostCenters.get(
              {
                id: $stateParams.costCenterId
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          costCenterUsers: function ($q, purchaseRequisitionsServices, globalFunc, $stateParams) {
            var deferred = $q.defer();

            purchaseRequisitionsServices.getAvailableRequestorsList(
              {
                id: $stateParams.costCenterId
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          }
        }
      });
  }]);
