'use strict';

angular.module('metabuyer.services.adminSettings', ['ngResource'])
  .factory( 'adminSettings', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.security.password,
      {

      },
      {
        'get'   : {method: 'GET'},
        'put'   : {method: 'PUT'},
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('tenantLogo', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.tenant.image,
      {

      },
      {
        'get'   : {method: 'GET'}
      }
    );
  })
  .factory('singleTenantById', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.tenant.singleTenantById,
      {
        id: '@id'
      },
      {
        'get'   : {method: 'GET'},
        'put'   : {method: 'PUT'}
      }
    );
  })
  .factory('uploadTenantLogo', function ($resource, pathConstants) {
    return{
      uploadImagePath: function () {
        return pathConstants.apiUrls.tenant.image;
      }
    }
  })
  .factory( 'tenantSettings', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.security.tenant,
      {

      },
      {
        'get'   : {method: 'GET'},
        'put'   : {method: 'PUT'},
        'delete': {method: 'DELETE'}
      }
    );
  });

