'use strict';

/**
 *
 * @name catalogPanelCtrl
 *
 * @description Controller for Catalog Panel, it takes care of loading the items, assigning the tabs
 * as well as requesting the detailed information about an item
 *
 *
 * @requires $scope
 * @requires $rootScope
 * @requires catalogShoppingSearch
 * @requires $http
 * @requires pathConstants
 * @requires globalFunc
 * @requires $filter
 * @requires purchaseRequisitionsServices
 * @requires registerViewedItem
 * @requires $uibModal
 * @requires prFunction
 * @requires starringItem
 *
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

function catalogHeaderCtrl(
  $scope, $rootScope,catalogShoppingSearch, $http, pathConstants, globalFunc, $filter, purchaseRequisitionsServices,
  registerViewedItem, $uibModal, prFunction, starringItem, itemsCategories, metabuyerCache, paymentTerms, $anchorScroll
) {


 function showItemDetails(item) {

   $uibModal.open({
     templateUrl: 'components/purchaseRequisitionComponents/purchaseRequisitionShoppingMode/detailsTemplate.html',
     controller: 'CatalogItemDetailCtrl',
     backdrop: 'static',
     keyboard: false,
     windowClass: 'item-details-modal',
     resolve: {
       item: function () {
         return item;
       },
       itemDetails: function () {
         return item.details;
       },
       addItemToPRFunction: function () {
         return addItemToPRResource;
       },
       paymentTerms: function () {
         return paymentTerms.data;
       }
     }
   });

   /**
    * add to the list of recently viewed items
    */
   registerViewedItem.put({
     user_id: $rootScope.currentUser._id,
     item_id: item.item_id
   });
 }


  /**
   * function to prepare the breadCrumb on case of category selection
   * @param code
   * @param previousBreadCrumb
   * @returns {{}}
   */
  function prepareBreadCrumb(code, previousBreadCrumb) {


    var breadCrumb = {};
    var familyCode = '';

    var segmentCode = code.substring(0, 2).concat('000000');
    var segment = globalFunc.findInArray($scope.categories, 'code', segmentCode);

    // type Segment
    if (code.substring(2) === '000000') {
      breadCrumb.current = segment;
      breadCrumb.childern = segment.families;
    }
    // type Family
    else if (code.substring(4) === '0000') {
      breadCrumb.parent = segment;
      breadCrumb.current = globalFunc.findInArray(segment.families, 'code', code);
      breadCrumb.childern = breadCrumb.current.classes;
    }
    // type Class
    else if (code.substring(6) === '00') {
      familyCode = code.substring(0, 4).concat('0000');
      breadCrumb.grandParent = segment;
      breadCrumb.parent = globalFunc.findInArray(segment.families, 'code', familyCode);
      breadCrumb.current = globalFunc.findInArray(globalFunc.findInArray(segment.families, 'code', familyCode).classes, 'code', code);
      breadCrumb.childern = globalFunc.findInArray(globalFunc.findInArray(segment.families, 'code', familyCode).classes, 'code', code).commodities;

    }
    // type Commodity
    else
      breadCrumb = previousBreadCrumb;

    return breadCrumb;
  }


  /**
   * function that posts the Item to add to the PR
   * @param itemDetailsInfo
   * @returns {*|{method, params, url}}
   */
  function addItemToPRResource(itemDetailsInfo) {
    prFunction.setLoading('lineItemTable', true);
    prFunction.setLoading('addingItem', true);
    prFunction.setLoading('updatePRField', true);
    return purchaseRequisitionsServices.setItem({
     id: $scope.PR._id,
     action: 'add'
    }, {
      'item_details_id': itemDetailsInfo.detail_id
    });
  }

  /**
   * function to call the resource and update the html when adding items to the PR
   * @param item
   */
  function addItemToPR(item){
    var pr = prFunction.getPRData();
    var validateLumpSum = prFunction.validateLumpSum(item, -1);

    if (!checkPaymentTerm(item)) {
      swal({
        title: 'Invalid Payment Term',
        text: 'The payment term for this item is inactive. Please check.',
        type: 'info',
        showCancelButton: true,
        showConfirmButton: false
      });
    }
    else {
      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: 'Confirm add item?',
          text: validateLumpSum.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prFunction.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                sendingAddNewItemRequest(item);
            })
          }
        });
      }
      else
        sendingAddNewItemRequest(item);
    }
  }

  /**
   * validate new item
   * if the pr got lump sum
   * @param item
   */
  function sendingAddNewItemRequest(item) {
    addItemToPRResource(item).$promise.then(function(resource){
      if (!!resource && !!resource.content && !!resource.content.data) {
        $rootScope.$broadcast('itemAdded');
        prFunction.setPRData(resource.content.data);
        refreshAddCartButton();
      }

      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('addingItem', false);
    },function (error){
      globalFunc.objectErrorMessage(error);
      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('addingItem', false);
    });
  }

  /**
   * the type ahead for the supplier search
   * @param val
   * @returns {*|webdriver.promise}
   */
  function supplierListing(val) {
    if(!!val && val.length > 2) {
      $scope.loading = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][descr]': val,
        'criteria[0][archive_of]': '',
        'criteria[0][draft_status]': 3,
        'criteria[0][approval_status]': 1,
        'criteria[0][status]': 1,
        criteria_operator: 'and',
        offset: 10
      };

      additionalParams['criteria[1][bu_status|' + $scope.PR.company.bu_code + '|status]'] = 1;
      additionalParams['criteria[1][is_global_supplier]'] = 'true';
      additionalParams['criteria[1][$operator]'] = 'or';

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        $scope.loading = false;
        return response.data.content.data.map(function (item) {
          $scope.loading = false;
          return item;
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }


  /**
   * view or hide the Category list
   */
  function setCategoriesView() {
    if (!metabuyerCache.get('categories')) {
      itemsCategories.get(
        {
          code: 'all',
          groupIds: [prFunction.getCatalogGroup()]
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            metabuyerCache.put('categories', resource.content.data);
            $scope.categories = resource.content.data;
            divideCategories();
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      $scope.categories = metabuyerCache.get('categories');
      divideCategories();
    }
  }

  /**
   * validate and set which display for the catalog is active
   * @param code
   * @param value
   */
  function setCatalogDisplay (value, code){
    $scope.supplierSearchDisplay = (code === 'supplier');
    $scope.catalogDisplayHandler = value;
  }

  /**
   * selected a filter and update the filter message
   * @param filter
   */
  function selectFilter(filter) {
    $scope.selectedFilter = filter;
    $scope.selectedFilter.text = 'Search ' + filter.label + ' catalog';
  }


  /**
   * prepare the payload to be searched
   * @param query
   */
  function searchCatalog(query) {
    $scope.searchActivated = true;
    $scope.selectedTab = 'search_results';
    $scope.paramsSource.code = $scope.selectedFilter.code;
    $scope.paramsSource.query = query;
    $scope.paramsSource.mb_supplier_code = null;

    if ($scope.selectedFilter.type === 'root')
      $scope.paramsSource.type = 'search';

    if ($scope.selectedFilter.type === 'tab') {
      $scope.paramsSource.type = $scope.selectedFilter.code;
      loadItems($scope.paramsSource.type, false, query, $scope.selectedFilter.code);
    } else if ($scope.selectedFilter.type === 'supplier') {
      $scope.paramsSource.code = null;
      $scope.paramsSource.mb_supplier_code = $scope.selectedFilter.code;
      loadItems('search', $scope.selectedFilter.code, query, false);
    }
    else
      loadItems('search', false, query, false, $scope.selectedFilter.code);
  }


  /**
   * the main function for loading items
   * @param type
   * @param supplierCode
   * @param query
   * @param tab
   * @param code
   */
  function loadItems(type, supplierCode, query, tab, code) {
    $scope.catalogList = [];
    $scope.meta = {};
    $scope.catalogDisplayHandler = 'showMessage';
    $scope.message = 'Loading items';
    catalogShoppingSearch.get(
      {
        user_id: $rootScope.currentUser._id,
        type: type,
        mb_supplier_code: !!supplierCode ? supplierCode : null,
        query: !!query ? query : null,
        group: !!tab ? tab : null,
        code: !!code ? code : null,
        groupIds: [prFunction.getCatalogGroup()]
      },
      function (resource) {
        $scope.showLoadingMessage = false;

        if (!!resource.content && !!resource.content.data) {
          $scope.catalogDisplayHandler = 'showItems';
          $scope.meta = resource.content.meta;
          $scope.catalogList = resource.content.data;
        } else {
          //TODO: handle the error
        }

        if (!$scope.catalogList || $scope.catalogList.length < 1) {
          $scope.catalogDisplayHandler = 'showMessage';
          $scope.message = 'There are no Items under the selected category at the moment!';
        }
      },
      function (error) {
        $scope.catalogDisplayHandler = '';
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * updated the drop down for the list to be searched
   * @param code
   * @param label
   * @param type
   */
  function refreshSearchFilters(code, label, type, makeAllAsDefault) {
    $scope.searchFilter = [];
    /**
     * add the All option and setting it for the search filter
     */
    $scope.searchFilter.push(
      {
        label: 'All',
        code: '00000000',
        type: 'root'
      }
    );
    selectFilter($scope.searchFilter[0]);

    if (!!label && !!code) {
      $scope.searchFilter.push({
        label: label,
        code: code,
        type: type
      });

    if(!makeAllAsDefault)
      selectFilter($scope.searchFilter[1]);
    }
  }


  /**
   * this function is the main to be called as it calls each other individually to perform different tasks
   * @param code
   * @param label
   * @param doNotLoadItems
   * @param type
   * @param makeAllAsDefault
   */
  function browseCatalog(code, label, doNotLoadItems, type, makeAllAsDefault) {
    $scope.search.title = '';
    $scope.paramsSource.query = '';

    setCatalogDisplay('showItems', type);

    refreshSearchFilters(code, label, type, makeAllAsDefault);

    if (!doNotLoadItems) {
      if (type === 'supplier') {
        $scope.paramsSource.type = 'search';
        $scope.paramsSource.mb_supplier_code = code;
        $scope.paramsSource.code = null;
        loadItems('search', code);
      } else {
        $scope.paramsSource.type = code;
        $scope.paramsSource.mb_supplier_code = null;
        loadItems(code);
      }
    }

    if (type === 'category')
      $scope.currentCategory = prepareBreadCrumb(code, $scope.currentCategory);

    $scope.searchActivated = false;
  }

  /**
   * get loading value from prFunction service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prFunction.getLoading(loadingObject);
  }

  /**
   * check if the item has been added to the PR previously
   * @param id
   * @returns {boolean}
   */
  function checkItemInCart(id) {
    return prFunction.getPRItemsIDs().indexOf(id) !== -1;
  }

  /**
   * watcher to apply the selection for the item, and updates in case of a pagination occurred
   */
  $scope.$watch('catalogList', function(){
    refreshAddCartButton();
  });

  function refreshAddCartButton() {
    if (!!$scope.catalogList) {
      _.forEach($scope.catalogList, function (product) {
        if (product.default)
          product.selected = checkItemInCart(product.default.detail_id);
      })
    }
  }

  function itemStarring (product, productId) {
    starringItem.update(
      {
        user_id: $rootScope.currentUser._id,
        item_id: productId
      },
      function (resource) {
        if (product.starred)
          product.starred = false;
        else {
          product.starred = true;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }

  /**
   * function for clearing the search
   * it clears the text as well as requests for items from all suppliers
   */
  function clearSupplierSearch(){
    $scope.searchedSupplier = {
      text: ''
    };
    browseCatalog('', '', false, 'supplier');
  }

  /**
   * divide the Categories into Segments and Families for display
   */
  function divideCategories() {
    if ($scope.categories) {
      $scope.segments = [];
      _.forEach($scope.categories, function (category) {
        var temp = {
          'code': category.code,
          'title': category.title,
          'families': category.families
        };
        $scope.segments.push(temp);
      });
      setCatalogDisplay('showCategories');
    }
  }

  function checkPaymentTerm(item) {
    var isPaymentTermValid = false;
    if (!!item.payment_term.code) {
      if (globalFunc.findInArray($scope.validPaymentTerm, 'code',
          item.payment_term.code)) {
        isPaymentTermValid = true;
      }
    }
    return isPaymentTermValid;
  }

  function initialize() {
    $scope.validPaymentTerm = paymentTerms.data;
    // start the catalog browsing with the Starred
    $scope.selectedTab = 'starred';
    browseCatalog('starred', 'Starred Items', false, 'tab', true);

    // set addItem loading status as false
    prFunction.setLoading('addingItem', false);
  }

  /**
   *  Receive broadcast for updated embedded pagination data to update on controller side
   */
  $scope.$on('updateEmbeddedPagination', function(events, content){
    if(!!content && !!content.meta)
    $scope.meta = content.meta;

    $anchorScroll();
  });

  $scope.selectFilter               = selectFilter;
  $scope.browseCatalog              = browseCatalog;
  $scope.setCategoriesView          = setCategoriesView;
  $scope.supplierListing            = supplierListing;
  $scope.addItemToPR                = addItemToPR;
  $scope.showItemDetails            = showItemDetails;
  $scope.searchCatalog              = searchCatalog;
  $scope.checkLoading               = checkLoading;
  $scope.itemStarring               = itemStarring;
  $scope.clearSupplierSearch        = clearSupplierSearch;
  $scope.checkPaymentTerm           = checkPaymentTerm;

  $scope.searchFilter = [];
  $scope.selectedFilter = {};
  $scope.catalogDisplayHandler = '';
  $scope.segments = [];
  $scope.showMessage = false;
  $scope.itemsService = catalogShoppingSearch;
  $scope.paramsSource = {
    user_id: $rootScope.currentUser._id,
    pr_cc: $scope.PR.cost_center.code,
    type: 'starred',
    offset: 20,
    groupIds: [prFunction.getCatalogGroup()]
  };
  $scope.supplierSearchDisplay = false;
  $scope.loading = false;
  $scope.searchActivated = false;
  $scope.searchedSupplier = {
    text: ''
  };
  $scope.search = {
    title: ''
  };


  /**
   * bind the PR to the Parent Object (main PR)
   */
  $scope.PR = prFunction.getPRData();
  $scope.categories = [];

  initialize();

}

catalogHeaderCtrl.$inject = [
  '$scope', '$rootScope', 'catalogShoppingSearch', '$http', 'pathConstants', 'globalFunc', '$filter',
  'purchaseRequisitionsServices', 'registerViewedItem', '$uibModal', 'prFunction', 'starringItem', 'itemsCategories',
  'metabuyerCache', 'paymentTerms', '$anchorScroll'
];

angular
  .module('metabuyer')
  .controller('catalogHeaderCtrl', catalogHeaderCtrl);
