'use strict';

function tenantSettingsCtrl($scope, tenant, globalFunc, $state, toastr, tenantSettings, $auth,
                            tenantLogo, uploadTenantLogo, pathConstants, singleTenantById,
                            $filter, pdfPreview, $rootScope) {

  $scope.tenantSetting = {};
  $scope.submitSettings = submitSettings;
  $scope.saveTenantInfo = saveTenantInfo;
  $scope.cancelSubmit = cancelSubmit;
  $scope.isUploadImageSuccess = isUploadImageSuccess;

  $scope.forexUpdateFreq = [
    {value: 1, descr: 'Daily'},
    {value: 7, descr: 'Weekly'},
    {value: 30, descr: 'Monthly'}
  ];

  // config flowjs
  $scope.flowConfig = new Flow({
    target: uploadTenantLogo.uploadImagePath,
    singleFile: true,
    testChunks: false,
    chunkSize: 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    },
    query: {
      tenant_name: 'Sunway Group'
    }
  });

  function isUploadImageSuccess(file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  }

  function submitSettings() {
    var data = {};
    data.config = {};

    data.config.forex_update_freq = angular.copy($scope.tenantSetting.forex.value);
    tenantSettings.put(
      {}, data,
      function () {
        saveTenantInfo(false);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function saveTenantInfo(printFlag) {
    var submitData = $scope.tenantData;
    submitData.terms_and_conditions = $scope.tenantTermsAndConditions;
    singleTenantById.put(
      {
        id: $scope.tenantData._id
      },
      submitData,
      function () {
        toastr.success('Settings successfully applied.');
        if (!!printFlag) {
          previewTenantPDF();
        }
        else {
          $state.go('main.user.settings.mainPanel');
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function previewTenantPDF() {
    pdfPreview.post(
      {
        id: $scope.tenantData._id
      },
      {
        terms_and_conditions_level: 'tenant'
      },
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function cancelSubmit() {
    $state.go('main.user.settings.mainPanel');
  }

  function initialize() {
    if (!!tenant && !!tenant.data && !!tenant.data.config) {
      var settings = tenant.data.config,
        forexSetting = settings.forex_update_freq;

      $scope.tenantSetting.forex = globalFunc.findInArray($scope.forexUpdateFreq, 'value', forexSetting);
    }

    $scope.imageBasePath = pathConstants.apiUrls.image.user;

    singleTenantById.get(
      {
        id: $rootScope.currentUser.tenant_id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.tenantData = resource.content.data;
          if (!!resource.content.data.terms_and_conditions)
          $scope.tenantTermsAndConditions = resource.content.data.terms_and_conditions;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );

    tenantLogo.get(
      {
        tenant_name: 'Sunway Group'
      },
      function (resource) {
        $scope.tenantImgUrl = resource.url;
      }
    )
  }

  initialize();
}

tenantSettingsCtrl.$inject = ['$scope', 'tenant', 'globalFunc', '$state', 'toastr', 'tenantSettings', '$auth',
  'tenantLogo', 'uploadTenantLogo', 'pathConstants', 'singleTenantById', '$filter', 'pdfPreview', '$rootScope'];

angular
  .module('metabuyer')
  .controller('tenantSettingsCtrl', tenantSettingsCtrl);
