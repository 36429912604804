'use strict';
/**
 * @name adminUnlockUserCtrl
 * @desc Controller for Admin to Unlock a locked User
 */
function adminUnlockUserCtrl($scope, adminUnlockUser, toastr, $state, $uibModalInstance) {

  $scope.closeModal = closeModal;
  $scope.unlockUser = unlockUser;
  $scope.confirmUnlockUser = confirmUnlockUser;
  $scope.error = false;
  $scope.unlockedLockedUser = false;
  $scope.wrongFormatError = false;


  function confirmUnlockUser() {
    //clearing all the errors
    $scope.wrongFormatError = false;
    $scope.error = false;
    //checks if they pass all the validation
    if (checkPasswordFormat()) {
    }
    else {
      //message about the required password format
      $scope.wrongFormatError = true;
      $scope.error = true;
    }
    if (!$scope.error) {
      adminUnlockUser.put({
          id: $scope.user._id
        }, {
          password: $scope.password_new
        },
        function (success) {
          $uibModalInstance.close();
          toastr.success("User successfully unlocked");
          $state.go($state.current, {}, {reload: true});
        },
        function (error) {
          if (!!error) {
            toastr.error("There was an error in unlocking this user.");
          }
        });
    }
    else {

    }

  }

  function checkPasswordFormat() {
    var letter = /[a-z]/;
    var capitalLetter = /[A-Z]/;
    var number = /[0-9]/;
    return (letter.test($scope.password_new) && number.test($scope.password_new) && $scope.password_new.length > 7 && capitalLetter.test($scope.password_new));
  }

  function unlockUser() {
    $scope.unlockedLockedUser = true;
  }

  function closeModal() {
    $uibModalInstance.close();
  }
}

adminUnlockUserCtrl.$inject = ['$scope', 'adminUnlockUser', 'toastr', '$state', '$uibModalInstance'];

angular
  .module('metabuyer')
  .controller('adminUnlockUserCtrl', adminUnlockUserCtrl);
