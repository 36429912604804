'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.reports', {
        url: '/reports',
        controller: 'reportsCtrl',
        templateUrl: 'app/reports/reports.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state) {
            if (!UserPermissions.checkReportLogAccess(profile, 'C') ||
              !UserPermissions.checkReportLogAccess(profile, 'L') ||
              !UserPermissions.checkReportLogAccess(profile, 'O') ||
              !UserPermissions.checkReportLogAccess(profile, 'U') ||
              !UserPermissions.checkReportLogAccess(profile, 'D')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.reports.log', {
        templateUrl: 'app/reports/logs/logs.html',
        url: '/logs?{cursor:[^~,]}&{order_by:[^~,]}&{order:[^~,]}',
        controller: 'reportsLogCtrl',
        resolve: {
          logList: function ($q, searchModule, $stateParams, profile) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'reports',
                'criteria[requestor_user._id]': profile._id,
                cursor: $stateParams.cursor,
                offset: 20,
                order_by: !!$stateParams.order_by ? $stateParams.order_by : 'done_at',
                order: !!$stateParams.order ? $stateParams.order : 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Reports - Logs';
          }
        }
      })
      .state('main.user.reports.generate', {
        templateUrl: 'app/reports/generate/generate.html',
        url: '/generate',
        controller: 'reportsGenerationCtrl',
        resolve: {
          $title: function(){
            return 'Reports - Generate';
          },
          businessList: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0,

                // Get only active BU = 1
                'criteria[is_active]': 1
              },
              function (resource) {
                deferred.resolve(resource.content.data);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          }
        }
      })
  }]);

