'use strict';


/**
 * //TODO: document
 * @param $scope
 * @param $uibModal
 * @param $element
 * @param searchModule
 * @param metabuyerCache
 * @param $q
 */
function nonCatalogItemDirectiveCtrl($scope, $uibModal, $element, searchModule, metabuyerCache, $q, Currencies) {

  function panel() {
    $uibModal.open({
      templateUrl: 'components/purchaseRequisitionComponents/nonCatalog/nonCatalogItemTemplate.html',
      controller: catalogNonCatalogItemCtrl,
      backdrop :'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        paymentTermsList: function () {
          return loadData({
            module: 'payment-term',
            'criteria[is_active]': 1,
            offset: 0
          }, 'payment-term');
        },
        taxesList: function () {
          return loadData({
            module: 'tax',
            offset: 0
          }, 'taxes');
        },
        item: function() {
          return $scope.item || {};
        },
        currencies: function ($q, Currencies) {
          var deferred = $q.defer();
          Currencies.get(function (resource) {
              deferred.resolve(resource.content);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });
  }

  $element.bind('click', function () {
    panel();
  });


  function loadData(params, cacheKey) {

    var deferred = $q.defer();

    if (!!metabuyerCache.get(cacheKey)) {
      deferred.resolve(metabuyerCache.get(cacheKey));
    } else {
      searchModule.get(
        params
        ,
        function (resource) {
          if (!!resource.content) {
            metabuyerCache.put(cacheKey, resource.content.data);
            deferred.resolve(metabuyerCache.get(cacheKey));
          } else {
            //TODO: handle the error
            deferred.resolve([]);
          }

        },
        function (error) {
          deferred.resolve([]);
          //TODO: handle the error
        });
    }
    return deferred.promise;
  }


}

nonCatalogItemDirectiveCtrl.$inject = ['$scope', '$uibModal', '$element', 'searchModule', 'metabuyerCache', '$q', 'Currencies'];

angular
  .module('metabuyer')
  .directive('nonCatalogItem',function() {
    return {
      restrict: 'A',
      scope: {
        item: '=?'
      },
      controller: 'nonCatalogItemDirectiveCtrl'
      }
  })
  .controller('nonCatalogItemDirectiveCtrl', nonCatalogItemDirectiveCtrl);
