'use strict';

angular
  .module('metabuyer')
  .directive('poItemlistPanel', ['$document', '$window', '$location', 'ExchangeRate', 'toastr',
    function ($document, $window, $location, ExchangeRate, toastr) {
      return {
        restrict: 'E',
        templateUrl: 'components/poItemList/poItemlistTemplate.html',
        controller: function ($scope, $element) {
          //<editor-fold desc="Total">

          /* Calculations:
           Amount = Quantity * Unit price
           Discount = an array of (a number or (Amount * Discount (%)))
           Tax = (Amount - Discount) * Tax (%)
           Total Amount = Amount - Discount + Tax
           */

          /* Notes:
           total_ (totalAmount) totalAmount field in the UI
           sum_ (sumDiscount) sum of objects in arrays
           grand_ (grandAmount, grandDiscount, grandTotalAmount, grandTax) fields last line in itemList
           */

          var calcTotal = function() {
            $scope.grandAmount = 0;
            $scope.grandDiscount = 0;
            $scope.grandTax = 0;
            $scope.newTotalAmount = 0;
            $scope.grandTotalAmount = 0;
            $scope.totalLumpSum = 0;
            $scope.lumpsumTax = 0;

            var tempLumpSumTax;

            if ($scope.po.items != null) {
              for (var i = 0; i < $scope.po.items.length; i++) {
                var currentIndexPO = $scope.po.items[i];

                if (_.isEmpty(currentIndexPO.backup_discount)) {
                  currentIndexPO.backup_discount = [];
                }

                //<editor-fold desc="Calculate item's amount, discount, tax & totalAmount">
                // Calculate item's amount
                currentIndexPO.amount = currentIndexPO.unit_price * currentIndexPO.quantity;

                // Calculate item's discount array
                if (!!currentIndexPO.discount && Number(currentIndexPO.discount !== 0) &&
                      currentIndexPO.discount !== null) {
                  currentIndexPO.sumDiscount = 0;
                  calculateItemDiscount(currentIndexPO);

                  $scope.grandDiscount += (currentIndexPO.sumDiscount  || 0);
                }
                else{
                  currentIndexPO.sumDiscount = 0;
                }

                // Calculate item's tax
                if (!!currentIndexPO.taxes && !!currentIndexPO.taxes[0]) {
                  currentIndexPO.taxes[0].calculated = (currentIndexPO.amount - (currentIndexPO.sumDiscount || 0))
                    * parseFloat(currentIndexPO.taxes[0].rate) / 100;
                }

                // Calculate item's totalAmount
                currentIndexPO.totalAmount = currentIndexPO.amount - (currentIndexPO.sumDiscount || 0);

                // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                $scope.grandAmount += currentIndexPO.amount;
                //$scope.grandDiscount is calculated when loop currentIndexPO.discount array
                $scope.grandTax += ((!!currentIndexPO.taxes && currentIndexPO.taxes[0]) ? currentIndexPO.taxes[0].calculated || 0 : 0);
                $scope.newTotalAmount += (currentIndexPO.amount - currentIndexPO.sumDiscount);
                $scope.grandTotalAmount += (currentIndexPO.amount - (currentIndexPO.sumDiscount || 0) +
                  ((!!currentIndexPO.taxes && currentIndexPO.taxes[0]) ? currentIndexPO.taxes[0].calculated || 0 : 0));
                //</editor-fold>

                //<editor-fold desc="Calculate item's freights amount, discount, tax and totalAmount">
                if (!!currentIndexPO.freight) {
                  _.forEach(currentIndexPO.freight, function (freightItem) {

                    var newFreightAmount;
                    if(!!freightItem.is_percentage){
                      newFreightAmount = Number((freightItem.amount*currentIndexPO.amount)/100);
                    }
                    else{
                      newFreightAmount = Number(freightItem.amount);
                    }

                    // calculate freight displayAmount
                    freightItem.displayAmount = freightItem.amount;
                    if (!!freightItem.is_percentage) {
                      freightItem.displayAmount = freightItem.amount + "%";
                    }

                    // Calculate freightItem's discount
                    if (!_.isEmpty(freightItem.discount)) {
                      if(!!currentIndexPO.exchangeRate){
                        freightItem.discount.calculated = Number(freightItem.discount.amount)*currentIndexPO.exchangeRate;
                        if (!!freightItem.discount.is_percentage) {
                          freightItem.discount.calculated = Number(newFreightAmount) *
                            (freightItem.discount.amount / 100);
                          freightItem.discount.displayAmount = freightItem.discount.amount + "%";
                          var newFreightDiscount = freightItem.discount.calculated;
                        }
                        else{
                          freightItem.discount.calculated = freightItem.discount.amount*currentIndexPO.exchangeRate;
                          newFreightDiscount = freightItem.discount.calculated;
                        }
                      }
                      else{
                        if (!!freightItem.discount.is_percentage) {
                          freightItem.discount.calculated = Number(newFreightAmount) *
                            (freightItem.discount.amount / 100);
                          freightItem.discount.displayAmount = freightItem.discount.amount + "%";
                          newFreightDiscount = freightItem.discount.calculated;
                        }
                        else{
                          freightItem.discount.calculated = freightItem.discount.amount*currentIndexPO.exchangeRate;
                          newFreightDiscount = freightItem.discount.amount;
                        }
                      }
                    }
                    else{
                      newFreightDiscount = 0;
                    }

                    // Calculate freightItem's tax
                    if (!_.isEmpty(freightItem.tax)) {
                      freightItem.tax.calculated = Number((Number(newFreightAmount) - (
                        freightItem.discount ? freightItem.discount.calculated || 0 : 0
                        )) * (parseFloat(freightItem.tax.rate) || 0) / 100);
                    }

                    /**
                     * Freight's total amount is calculated here
                     */
                    freightItem.totalAmount = Number(newFreightAmount) - Number(newFreightDiscount);

                    if(angular.isDefined(freightItem.tax)) {
                      var newFreightTax = Number(freightItem.tax.calculated);
                      if (!!currentIndexPO.exchangeRate) {
                        newFreightTax = Number(newFreightTax * Number(currentIndexPO.exchangeRate));
                      }
                    }
                    else{
                      newFreightTax = 0;
                    }

                    // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                    $scope.grandAmount += Number(newFreightAmount);
                    $scope.grandDiscount += Number(newFreightDiscount);
                    $scope.grandTax += Number(newFreightTax);
                    // Delete totalAmount + tax
                    // (freightItem.tax ? freightItem.tax.calculated || 0 : 0)
                    $scope.grandTotalAmount += Number(newFreightAmount - newFreightDiscount);
                    $scope.newTotalAmount += freightItem.totalAmount;
                  });
                }

                if (!!currentIndexPO.transportation) {
                  _.forEach(currentIndexPO.transportation, function (transportationItem) {

                    var newTransportationAmount;
                    if(!!transportationItem.is_percentage){
                      newTransportationAmount = Number((transportationItem.amount*currentIndexPO.amount)/100);
                    }
                    else{
                      newTransportationAmount = Number(transportationItem.amount);
                    }

                    transportationItem.displayAmount = transportationItem.amount;
                    if (!!transportationItem.is_percentage) {
                      transportationItem.displayAmount = transportationItem.amount + "%";
                    }

                    // Calculate transportationItem's discount
                    if (!_.isEmpty(transportationItem.discount)) {
                      var newTransportationDiscount;
                      if(!!currentIndexPO.exchangeRate){
                        transportationItem.discount.calculated = Number(transportationItem.discount.amount)*currentIndexPO.exchangeRate;
                        if (!!transportationItem.discount.is_percentage) {
                          transportationItem.discount.calculated = Number(newTransportationAmount) *
                            (transportationItem.discount.amount / 100);
                          transportationItem.discount.displayAmount = transportationItem.discount.amount + "%";
                          newTransportationDiscount = transportationItem.discount.calculated;
                        }
                        else{
                          transportationItem.discount.calculated = transportationItem.discount.amount*currentIndexPO.exchangeRate;
                          newTransportationDiscount = transportationItem.discount.calculated;
                        }
                      }
                      else{
                        if (!!transportationItem.discount.is_percentage) {
                          transportationItem.discount.calculated = Number(newTransportationAmount) *
                            (transportationItem.discount.amount / 100);
                          transportationItem.discount.displayAmount = transportationItem.discount.amount + "%";
                          newTransportationDiscount = transportationItem.discount.calculated;
                        }
                        else{
                          transportationItem.discount.calculated = transportationItem.discount.amount*currentIndexPO.exchangeRate;
                          newTransportationDiscount = transportationItem.discount.amount;
                        }
                      }
                    }
                    else{
                      newTransportationDiscount = 0;
                    }

                    // Calculate transportationItem's tax
                    if (!_.isEmpty(transportationItem.tax)) {
                      transportationItem.tax.calculated = Number((Number(newTransportationAmount) - (
                        transportationItem.discount ? transportationItem.discount.calculated || 0 : 0
                        )) * (parseFloat(transportationItem.tax.rate) || 0) / 100);
                    }

                    /**
                     * Transportation's total amount is calculated here
                     */
                    transportationItem.totalAmount = Number(newTransportationAmount) - Number(newTransportationDiscount);

                    if(angular.isDefined(transportationItem.tax)) {
                      var newTransportationTax = Number(transportationItem.tax.calculated);
                      if (!!currentIndexPO.exchangeRate) {
                        newTransportationTax = Number(newTransportationTax * Number(currentIndexPO.exchangeRate));
                      }
                    }
                    else{
                      newTransportationTax = 0;
                    }

                    // Calculate grandAmount, grandDiscount, grandTax & grandTotalAmount
                    $scope.grandAmount += Number(newTransportationAmount);
                    $scope.grandDiscount += Number(newTransportationDiscount);
                    $scope.grandTax += Number(newTransportationTax);
                    // Delete totalAmount + tax
                    // (freightItem.tax ? freightItem.tax.calculated || 0 : 0)
                    $scope.grandTotalAmount += Number(newTransportationAmount- newTransportationDiscount);
                    $scope.newTotalAmount += transportationItem.totalAmount;
                  });
                }
                //</editor-fold>
              }

              // calculate the lump sum discount After the grand total
              if(!!$scope.po.lump_sum_discount && !!$scope.po.lump_sum_discount.amount) {
                if ($scope.po.lump_sum_discount.is_percentage) {
                  $scope.totalLumpSum = (Number($scope.po.lump_sum_discount.amount)/100 * $scope.grandAmount);
                } else {
                  $scope.totalLumpSum = $scope.po.lump_sum_discount.amount;
                }

                $scope.grandTotalAmount = $scope.grandTotalAmount - $scope.totalLumpSum;

                // calculate tax in lump sum
                if(!!$scope.po.lump_sum_discount.tax){
                  tempLumpSumTax = (Number($scope.po.lump_sum_discount.tax.rate)/100 * $scope.totalLumpSum);
                  $scope.lumpsumTax = tempLumpSumTax;
                  $scope.grandTotalAmount = $scope.grandTotalAmount - tempLumpSumTax;
                }
              }
            }
          };

          var backupDiscountEntry;
          var calculateItemDiscount = function(item){
            if (!!item.discount) {
              for (var i = 0; i < item.discount.length; i++) {
                var itemDiscount = item.discount[i];
                if (!item.backup_discount[i]) {
                  item.backup_discount[i] = _.cloneDeep(item.discount[i]);
                }

                var entryIsPercentage;

                // first load, set itemDiscount.entry
                if (!itemDiscount.amount) {
                  itemDiscount.amount = 0;
                  itemDiscount.is_percentage = false;
                }

                if (!itemDiscount.entry) {
                  itemDiscount.entry = itemDiscount.amount + (!!itemDiscount.is_percentage ? '%' : '');
                  backupDiscountEntry = itemDiscount.entry;
                } else if (itemDiscount.entry == '') {
                  itemDiscount.amount = 0;
                  itemDiscount.is_percentage = false;
                } else {
                  if (itemDiscount.entry != '') {
                    entryIsPercentage = itemDiscount.entry.indexOf('%') > -1;
                    itemDiscount.is_percentage = entryIsPercentage;
                  }

                  // calculate itemDiscount.amount
                  var tempAmount;
                  if (entryIsPercentage) {
                    tempAmount = itemDiscount.entry.substring(0, itemDiscount.entry.length - 1);
                    itemDiscount.amount = _.isNaN(parseFloat(tempAmount)) ? 0 : tempAmount;
                  } else {
                    tempAmount = parseFloat(itemDiscount.entry);
                    itemDiscount.amount = _.isNaN(tempAmount)? 0 : tempAmount;
                  }
                }

                if (!!itemDiscount.is_percentage) {
                  item.sumDiscount += item.amount * itemDiscount.amount / 100;
                } else {
                  item.sumDiscount += itemDiscount.amount;
                }
              }
            }
          };
          //</editor-fold>

          //<editor-fold desc="Run on init">
          calcTotal();

          $scope.$watch('po.billing.currency', function(newCurrency) {
            if (!!newCurrency && !!newCurrency.code) {
              $scope.newCurrencyCode = newCurrency.code;
              if (!_.isEmpty($scope.po.items) && newCurrency.code !== $scope.po.items[0].currency.code) {
                ExchangeRate.get({
                  id: $scope.po.billing.company._id,
                  source_id: $scope.po.items[0].currency._id,
                  target_id: newCurrency._id
                }, function(resource) {
                  // TODO: check !resource && !!resource.content && !!resource.content.data! && !!resource.content.data.status
                  if (!!resource && !!resource.content){
                    if(resource.content.data !== false){
                      $scope.exchangeRate = parseFloat(resource.content.data);

                      _.forEach($scope.po.items, function(item) {
                        if (!item.original_unit_price) {
                          item.original_unit_price = item.unit_price;
                        }
                        item.unit_price = item.original_unit_price;
                      });
                    } else {
                      toastr.error(resource.content.message);
                      _.forEach($scope.po.items, function(item) {
                        if (!item.original_unit_price) {
                          item.original_unit_price = item.unit_price;
                        } else {
                          item.unit_price = item.original_unit_price;
                        }
                      });
                    }

                  }
                }, function(error) {
                  console.log(error);
                });
              } else {
                _.forEach($scope.po.items, function(item) {

                  if (!item.original_unit_price) {
                    item.original_unit_price = item.unit_price;
                  } else {
                    item.unit_price = item.original_unit_price;
                  }
                });
              }
            }
          });

          $scope.$watch('po.items', function (newValue, oldValue) {
            calcTotal();

            if (!!newValue && !!oldValue) {
              if (newValue.length > oldValue.length) {
                _.forEach($scope.po.items, function(item) {
                  if (!item.original_unit_price) {
                    item.original_unit_price = item.unit_price;
                  }
                  if (!!$scope.exchangeRate) {
                    item.unit_price = item.original_unit_price;
                  }
                })
              }
            }
          }, true);
          //</editor-fold>
        }
      };
    }
  ]);
