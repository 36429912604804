'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.items', {
        url: '/items',
        controller: 'ItemsCtrl',
        templateUrl: 'app/items/items.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.ImportItemDetail(profile, 'O')
              && !UserPermissions.ImportItemDetail(profile, 'U') && !UserPermissions.ImportItemDetail(profile, 'D')) {
              $state.go('notAuthorized');
            }
            if (!UserPermissions.ImportItemSummary(profile, 'O')
              && !UserPermissions.ImportItemSummary(profile, 'U') && !UserPermissions.ImportItemSummary(profile, 'D')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.items.manageCatalog', {
        templateUrl: 'app/items/manage/manage.html',
        url: '/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'ItemsManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if($stateParams.query){
              return 'search';
            }
            else{
              return 'show';
            }
          },
          items: function ($q, $state, $stateParams, searchModule, globalFunc, exportSearch) {

            var deferred = $q.defer();
            var status = globalFunc.availableStatuses('catalogs', $stateParams.status);
            var params = {};

            /**
             * First determine if search or listing
             */
            if(!!$stateParams.query){
              /**
               * If searching , check if searching with filter
               */
            if (!!$stateParams.filter) {
              params = {
                module: 'catalogs',
                criteria_operator: !!$stateParams.status ? 'and' : null,
                'criteria[1][import_status]': $stateParams.status !== 'all' ? status : null,
                offset: 20,
                cursor: $stateParams.cursor
              };

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
              }
            }
            /**
             * If searching without filter
             */
            else {
              params = {
                module: 'catalogs',
                offset: 20,
                'criteria[0][catalog_group.descr]': $stateParams.query,
                'criteria[0][catalog_code.code]': $stateParams.query,
                'criteria[0][catalog_code.descr]': $stateParams.query,
                'criteria[0][supplier.descr]': $stateParams.query,
                'criteria[0][creator.display_name]': $stateParams.query,
                'criteria[1][import_status]': $stateParams.status !== 'all' ? status : null,
                'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
                criteria_operator: !!$stateParams.query ? 'and' : null,
                cursor: $stateParams.cursor
              };
            }
          }
            /**
             * For listing
             */
            else{
              params = {
                module: 'catalogs',
                'criteria[import_status]': $stateParams.status ? status : null,
                offset: 20,
                cursor: $stateParams.cursor
              };
            }

            params.order_by = !!$stateParams.order_by ? $stateParams.order_by : 'catalog_code.updated_at';
            params.order =  !!$stateParams.order ? $stateParams.order : 0;

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data){
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },

          $title: function($stateParams){
            return "Catalog - listing" + (!!$stateParams.status? " - " + $stateParams.status : "");
          }
        }
      })
      .state('main.user.items.catalogGroup', {
        templateUrl: 'app/items/catalog-group/manage/manage.html',
        url: '/catalog-group/manage?&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'manageCatalogGroupCtrl',
        resolve: {
          dataList: function ($q, globalFunc, $stateParams, searchModule) {

            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.filter);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                temp = 'criteria[0][' + $stateParams.filter + ']';
                params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
              }

              params = {
                module: 'catalog-groups',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
            }
            else {
              params = {
                module: 'catalog-groups',
                offset: 20,
                'criteria[0][code]': $stateParams.query,
                'criteria[0][descr]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            searchModule.get(
              params,
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content);
                else
                  deferred.resolve({data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function(){
            return "Catalog - Group - listing";
          }
        }
      })
      .state('main.user.items.createCatalogGroup', {
        templateUrl: 'app/items/catalog-group/add/addCatalogGroup.html',
        url: '/catalog-group/create',
        controller: 'createCatalogGroupCtrl',
        resolve: {
          $title: function(){
            return "Create - Catalog Group";
          }
        }
      })
      .state('main.user.items.editCatalogGroup', {
        templateUrl: 'app/items/catalog-group/edit/editCatalogGroup.html',
        url: '/catalog-group/edit/{id:[a-zA-Z0-9]+}',
        controller: 'editCatalogGroupCtrl',
        resolve: {
          catalogGroupDetails: function($q, $stateParams, companyGroupsList){
            var deferred = $q.defer();
            companyGroupsList.get(
              {
                groupId: $stateParams.id
              },
              function(resource){
                deferred.resolve(resource.content || {data: []});
              },
              function(){
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          companies: function($q, $stateParams, searchModule){
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function(resource){
                deferred.resolve(resource.content || {data: []});
              },
              function(){
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Edit - Catalog Group";
          }
        }
      })
      .state('main.user.items.catalogCode', {
        templateUrl: 'app/items/catalog-code/manage/ManageCatalogCode.html',
        url: '/catalog-code/manage?&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'ManageCatalogCodeCtrl',
        resolve: {
          dataList: function ($q, globalFunc, $stateParams, searchModule) {

            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.filter);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                temp = 'criteria[0][' + $stateParams.filter + ']';
                params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
              }

              params = {
                module: 'catalog-code',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
            }
            else {
              params = {
                module: 'catalog-code',
                offset: 20,
                'criteria[0][code]': $stateParams.query,
                'criteria[0][descr]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }


            searchModule.get(
              params,
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content);
                else
                  deferred.resolve({data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function(){
            return "Catalog - Code - listing";
          }
        }
      })
      .state('main.user.items.catalogItems', {
        templateUrl: 'app/items/catalog-items/manage/manage.html',
        url: '/catalog-items/manage?&{query:[a-zA-Z0-9]+}&{cursor:[^~,]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'manageCatalogItemsCtrl',
        resolve: {
          dataList: function ($q, globalFunc, $stateParams, searchModule, exportSearch) {

            var deferred = $q.defer();
            var params = {};
            if (!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.filter);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = '';
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else if ($stateParams.type === 'double') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                temp = 'criteria[' + $stateParams.filter + ']';
                params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
              }

              params = {
                module: 'catalog-details',
                offset: 20,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              params[temp] = (!!statusCode || statusCode === 0) ? statusCode : $stateParams.query;
            } else {
              params = {
                module: 'catalog-details',
                offset: 20,
                'criteria[0][item_name]': $stateParams.query,
                'criteria[0][description]': $stateParams.query,
                'criteria[0][commodity_code]': $stateParams.query,
                'criteria[0][commodity_descr]': $stateParams.query,
                'criteria[0][supplier.descr]': $stateParams.query,
                'criteria[0][supplier_part_no]': $stateParams.query,
                'criteria[0][payment_term.descr]': $stateParams.query,
                'criteria[0][currency.code]': $stateParams.query,
                'criteria[0][Uom.code]': $stateParams.query,
                'criteria[0][creator.display_name]': $stateParams.query,
                'criteria[0][updater.display_name]': $stateParams.query,
                criteria_operator: !!$stateParams.query ? 'or' : null,
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content);
                else
                  deferred.resolve({data: []});
              },
              function () {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function(){
            return "Catalog - Items - listing";
          }
        }
      })
      .state('main.user.items.import', {
        templateUrl: 'app/items/import/itemsImportTemplate.html',
        abstract: true,
        controller: 'importItemsCtrl',
        resolve: {
          BUs: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'catalog-groups',
                offset: 0
              },
              function (resource) {
                if(!!resource.content){
                  deferred.resolve(resource.content);
                }else{
                  deferred.resolve([]);
                }
              },
              function () {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Catalog - Import";
          }
        }
      })
      .state('main.user.items.import.select_bu', {
        templateUrl: 'app/items/import/selectBUTemplate.html',
        url: '/importItems/select_bu'
      })
      .state('main.user.items.import.select_supplier', {
        templateUrl: 'app/items/import/selectSupplierTemplate.html',
        url: '/importItems/select_supplier'
      })
      .state('main.user.items.import.select_file', {
        templateUrl: 'app/items/import/selectFile.html',
        url: '/importItems/select_file?&{importID:[a-zA-Z0-9]+}&{catalogGroupID:[a-zA-Z0-9]+}&' +
             '{supplierID:[a-zA-Z0-9]+}&{catalogCodeID:[a-zA-Z0-9]+}'
      })
      .state('main.user.items.import.confirmation', {
        templateUrl: 'app/items/import/confirmation.html',
        url: '/importItems/confirmation'
      })
      .state('main.user.items.detail', {
        url: '/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/items/details/details.html',
        controller: 'itemDetailCtrl',
        resolve: {
          managedItem: function ($q, $stateParams, manageItemDetail, authenticated, toastr, $rootScope, $state) {
            var deferred = $q.defer();
            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }
            manageItemDetail.get({id: $stateParams.id},
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function () {
                toastr.error('Catalog not found');

                if(!!$rootScope.previousState && !!$rootScope.previousState.name){
                  $state.go($rootScope.previousState.name, $rootScope.previousState.params);
                }
              }
            );
            return deferred.promise;
          },
          $title: function($stateParams){
            return "Catalog - detail" + (!!$stateParams.id? " - " + $stateParams.id : "");
          }
        }
      })
  }]);
