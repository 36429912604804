'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.settings', {
        url: '/system-settings',
        controller: 'settingsCtrl',
        templateUrl: 'app/settings/settingsTemplate.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;
            if (!!profile.roles && profile.roles.length > 0) {
              for (var index in profile.roles) {
                if ((profile.roles[index].name.toLowerCase() === 'admin' && profile.roles[index].company_code === null) ||
                  profile.roles[index].name.toLowerCase() === 'tenant_super_admin') {
                  check = true;
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.settings.mainPanel', {
        url: '/main',
        controller: 'mainPanelSettingsCtrl',
        templateUrl: 'app/settings/mainPanel/mainPanelSettingsTemplate.html',
        resolve:{
          $title: function(){
            return "Settings";
          }
        }
      })
      .state('main.user.settings.adminSettings',{
        url: '/admin-settings',
        controller: 'adminSettingsCtrl',
        templateUrl: 'app/settings/adminSettings/adminSettings.html',
        resolve:{
          settings: function($q, adminSettings) {
            var deferred = $q.defer();
            adminSettings.get(
              {},
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          }
        }
      })
      .state('main.user.settings.PRPOSettings', {
        url: '/PR-PO-Settings',
        controller: 'PRPOSettingsCtrl',
        abstract: true,
        templateUrl: 'app/settings/PR-POSettings/PRPOSettingsTemplate.html'
      })
      .state('main.user.settings.tenant',{
        url: '/tenant',
        controller: 'tenantSettingsCtrl',
        templateUrl: 'app/settings/tenantSettings/tenantSettings.html',
        resolve:{
          tenant: function($q, tenantSettings) {
            var deferred = $q.defer();
            tenantSettings.get(
              {},
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Tenant Settings";
          }
        }
      })
      .state('main.user.settings.PRPOSettings.prefix', {
        url: '/Prefix',
        controller: 'PRPOPrefixCtrl',
        templateUrl: 'app/settings/PR-POSettings/prefix/PRPOPrefixTemplate.html',
        resolve:{
          companies: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order: 1,
                order_by: 'descr'
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Company Prefix";
          }
        }
      })
  }]);
