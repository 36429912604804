'use strict';
/**
 * @name UsersImportUserCtrl
 * @desc Controller for users import user
 */
function UsersImportUserCtrl($scope, Users) {

  $scope.params = {
    class: 'Metabuyer\\Models\\Users',
    'additional_params[]': ''
  };

}

UsersImportUserCtrl.$inject = ['$scope', 'Users'];

angular.module('metabuyer')
  .controller('UsersImportUserCtrl', UsersImportUserCtrl);
