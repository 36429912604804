'use strict';
function commodityDetailsCtrl($scope, toastr, commodityDetails, segmentList, commodityList, singleCommodity,
                              availability, $uibModalInstance, globalFunc) {
  $scope.commodityDetails = commodityDetails.data;
  $scope.submitted = false;
  $scope.editClicked = false;

  $scope.masterDataSegments = segmentList.data;
  $scope.masterDataFamilies = [];
  $scope.masterDataClasses = [];
  $scope.masterDataCommodities = [];

  $scope.commodityDetails.segment = $scope.commodityDetails.code.substring(0,2);
  $scope.commodityDetails.family = $scope.commodityDetails.code.substring(2,4);
  $scope.commodityDetails.class = $scope.commodityDetails.code.substring(4,6);
  $scope.commodityDetails.commodity = $scope.commodityDetails.code.substring(6,8);

  $scope.originalCode = $scope.commodityDetails.commodity;
  $scope.fullCode = $scope.commodityDetails.code;
  $scope.firstSixCode = $scope.commodityDetails.code.substring(0,6);

  // Statuses for dropdown in object
  $scope.commodityStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];

  $scope.commodityPosting = [
    {value:1, text: 'POSTING'},
    {value:0, text: 'NON POSTING'}
  ];

  $scope.commodityTypes = [
    {value: 'OI' , descr: 'Other Items'},
    {value: 'IT' , descr: 'Information Technology'},
    {value: 'CP' , descr: 'Car Purchase'},
    {value: 'ME' , descr: 'M&E (for SunCity PI only)'}
  ];

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.commodityDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\Commodity',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.cancel                     = cancel;
  $scope.saveCommodity              = saveCommodity;
  $scope.editMode                   = editMode;
  $scope.toggleTab                  = toggleTab;
  $scope.selectedMasterDataSegments = selectedMasterDataSegments;
  $scope.selectedMasterDataFamilies = selectedMasterDataFamilies;
  $scope.selectedMasterDataClasses  = selectedMasterDataClasses;
  $scope.duplicationCheck           = duplicationCheck;

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    $scope.tab = tab;
  }

  function editMode(){
    $scope.editClicked = true;
  }

  function selectedMasterDataSegments(item, callback) {
    $scope.masterDataSegments.selected = item;
    $scope.masterDataFamilies.selected = undefined;
    $scope.masterDataClasses.selected = undefined;
    commodityList.get(
        {
          code : item.unique_name
        },
        function(resource){
          $scope.masterDataFamilies = resource.content.data;
          $scope.segmentCode = item.unique_name;
          if(typeof callback === 'function'){
            callback($scope.masterDataFamilies);
          }
        },
        function(error){
          if (error.status === 404) {
            toastr.error('Could not load families for this segment');
          }
        }
      );
  }

  function selectedMasterDataFamilies(item, callback) {
    $scope.masterDataFamilies.selected = item;
    $scope.masterDataClasses.selected = undefined;
    commodityList.get(
      {
        code : item.unique_name
      },
      function(resource){
        $scope.masterDataClasses = resource.content.data;
        $scope.familyCode = item.unique_name.substring(2);
        if(typeof callback === 'function'){
          callback($scope.masterDataClasses);
        }
      },
      function(error){
        if (error.status === 404) {
          toastr.error('Could not load classes for this family');
        }
      }
    );
  }

  function selectedMasterDataClasses(item) {
    $scope.masterDataClasses.selected = item;
    $scope.commodityDetails.commodity = undefined;
    commodityList.get(
      {
        code : item.unique_name
      },
      function(resource){
        $scope.masterDataCommodities = resource.content.data;
        $scope.classCode = item.unique_name.substring(4);
        $scope.firstSixCode = item.unique_name;
      },
      function(error){
        if (error.status === 404) {
          toastr.error('Could not load commodity for this class');
        }
      }
    );
  }

  function validate(commodity){
    if(!commodity){
      return false;
    }
    if (!(commodity.code)){
      return false;
    }
    if (!(commodity.descr)) {
      return false;
    }
    if (!(commodity.commodityStatus)) {
      return false;
    }
    if (!(commodity.select_commodity_type)) {
      return false;
    }
    if (!(commodity.commodity) || commodity.commodity.length != 2) {
      return false;
    }
    if (!(commodity.posting)) {
      return false;
    }
    return true;
  }

  function saveCommodity(){
    $scope.submitted = true;
    if(!!$scope.codeCheck){
      toastr.error('Commodity Code ' + $scope.commodityDetails.commodity + ' is already in use');
    }
    else if(validate($scope.commodityDetails)) {
      var postingValue = 0;
      if($scope.commodityDetails.posting.text === 'POSTING'){
        postingValue = 1;
      }
      if($scope.commodityDetails.posting.text === 'NON POSTING'){
        postingValue = 0;
      }

      // set status in integer to pass it as parameter
      var tempStatus;
      switch($scope.commodityDetails.commodityStatus.isActive.toLowerCase()){
        case 'active':
          tempStatus = 1;
          break;
        case 'deactivated':
          tempStatus = 0;
          break;
        case 'disabled':
          tempStatus = 3;
          break;
      }

      $scope.fullCode = $scope.firstSixCode + $scope.commodityDetails.commodity;
      $scope.commodityDetails.segment = $scope.fullCode.substring(0,2);
      $scope.commodityDetails.family = $scope.fullCode.substring(2,4);
      $scope.commodityDetails.class = $scope.fullCode.substring(4,6);
      $scope.commodityCode = $scope.fullCode.substring(6);

      var submitData = {
        code: $scope.fullCode,
        descr: $scope.commodityDetails.descr,
        is_active: tempStatus,
        domain: $scope.commodityDetails.domain,
        type: $scope.commodityDetails.select_commodity_type.value,
        segment: $scope.commodityDetails.segment,
        family: $scope.commodityDetails.family,
        class: $scope.commodityDetails.class,
        commodity: $scope.commodityCode,
        unique_name: $scope.fullCode,
        parent_unique_name: $scope.firstSixCode,
        posting: postingValue
      };
      singleCommodity.put(
        {
          id: $scope.commodityDetails._id
        },
        submitData,
        function (resource) {
          toastr.success('Commodity has been updated');
          $uibModalInstance.close(resource.content.data);
        },
        function () {
          toastr.error('Failed to update commodity');
          return false;
        }
      );
    }
    else{
      toastr.error('Please fill in required fields');
      return false;
    }
  }

  function initialize(){
    if(!!$scope.commodityDetails.is_active) {
      $scope.commodityDetails.commodityStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.commodityDetails.is_active)};
    }

    $scope.commodityDetails.status = {status: $scope.commodityDetails.status};

    if(!_.isUndefined($scope.commodityDetails) && !_.isUndefined($scope.commodityDetails.type)){
      switch($scope.commodityDetails.type){
        case 'Other Items':
          $scope.commodityDetails.select_commodity_type = $scope.commodityTypes[0];
          break;
        case 'Information Technology':
          $scope.commodityDetails.select_commodity_type = $scope.commodityTypes[1];
          break;
        case 'Car Purchase':
          $scope.commodityDetails.select_commodity_type = $scope.commodityTypes[2];
          break;
        case 'M&E (for SunCity PI only)':
          $scope.commodityDetails.select_commodity_type = $scope.commodityTypes[3];
          break;
      }
    }

    if(!_.isUndefined($scope.commodityDetails.code)) {
      var tempSegment = $scope.commodityDetails.code.substring(0, 2);
      var tempFamily = $scope.commodityDetails.code.substring(2, 4);
      var tempClass = $scope.commodityDetails.code.substring(4,6);
      var tempCommodity = $scope.commodityDetails.code.substring(6,8);

      var commoditySegment = tempSegment + '000000';
      var commodityFamily = tempSegment + tempFamily + '0000';
      var commodityClass = tempSegment + tempFamily + tempClass + '00';


      for (var a = 0; a < $scope.masterDataSegments.length; a++) {
        if ($scope.masterDataSegments[a].code === commoditySegment) {
          $scope.masterDataSegments.selected = $scope.masterDataSegments[a];
        }
      }
      selectedMasterDataSegments($scope.masterDataSegments.selected, function(callback){
        $scope.masterDataFamilies = callback;
        for(var b = 0; b < callback.length ; b++){
          if(callback[b].code === commodityFamily){
            $scope.masterDataFamilies.selected = callback[b];
            selectedMasterDataFamilies($scope.masterDataFamilies.selected, function(callback){
              $scope.masterDataClasses = callback;
              for(var c = 0; c < callback.length ; c++){
                if(callback[c].code === commodityClass){
                  $scope.masterDataClasses.selected = callback[c];
                }
              }
            });
          }
        }
      });

      $scope.commodityDetails.commodity = tempCommodity;
    }

    if($scope.commodityDetails.posting === 1){
      $scope.posting = 'POSTING';
    }
    else{
      $scope.posting = 'NON POSTING';
    }
    $scope.commodityDetails.posting = {text: $scope.posting};
  }

  initialize();

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker)
  {
    if(!!value) {
      availability.get(
        {
          model: model,
          field: field,
          value: $scope.firstSixCode + value
        },
        function (resource) {
          if(resource) {
            if(checker === 'code'){
              $scope.codeCheck = false;
            }
          }
        },
        function (error) {
          if(error) {
            if(checker === 'code' && ($scope.commodityDetails.commodity != $scope.originalCode)){
              $scope.codeCheck = true;
            }
            else {
              $scope.codeCheck = false;
            }
          }
        }
      );
    }
  }
}

commodityDetailsCtrl.$inject = [
  '$scope', 'toastr', 'commodityDetails', 'segmentList', 'commodityList', 'singleCommodity',
  'availability', '$uibModalInstance', 'globalFunc'
];

angular
  .module('metabuyer')
  .controller('commodityDetailsCtrl', commodityDetailsCtrl);
