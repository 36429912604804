/**
 * @name actionModal
 *
 * @description
 * Directive that loads the Action modal for Approval and handles the success, error, and redirection
 *
 *
 * @requires $uibModal
 *
 * @authors Ahmed Saleh (a.s.alsalali@gmail.com)
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('actionModal', function($uibModal, preventDoubleClickingService) {
    return {
      restrict: 'A',
      scope: {
        title: '@',
        actionFunction: '&',
        nextActionId: '@?',
        actionCondition: '&?',
        module: '@',
        successHandler: '&?',
        errorHandler: '&?',
        modalBodyUrl: '@?',
        commentBlock: '@?',
        commentPlaceHolder: '@?',
        extraParams: '=?',
        nextActionAmount : '=?',
        allActionsCompleted: '=?',
        sendEmail: '=?',
        commentKey: '@?',
        bodyMessage: '@?',
        enableAttachment: '=?'
      },
      link: function ($scope, element, attrs) {

        var panel = function () {

          $uibModal.open({
            templateUrl: 'components/actionModal/actionModalTemplate.html',
            scope: $scope,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'action-modal',
            controller: 'actionModalCtrl',
            resolve: {
              successHandler: function () {
                return !!attrs.successHandler ? $scope.successHandler : undefined;
              },
              errorHandler: function () {
                return !!attrs.errorHandler ? $scope.errorHandler : undefined;
              }
            }
          });
        };

        element.bind('click', function () {
          if (preventDoubleClickingService.checkClick())
            panel();
        });
      }
    }
  });
