/**
 * @name CatalogItemDetailCtrl
 *
 * @description
 *
 * Controller for showing an item Details and listing the available variations of the item
 * Also, enables the editing in case of a Non Catalog item
 *
 * @requires $scope
 * @requires $rootScope
 * @requires item (resolved from caller controller, represents the main item)
 * @requires itemDetails (resolved from caller controller, represents the list of details an item has)
 * @requires pr (resolved from caller controller, represents the PR)
 * @requires $uibModalInstance
 * @requires paymentTermsList
 * @require prFunction (Metabuyer service)
 *
 *
 * @function detailAddToPR
 * adds the item to the PR, once successful its updates the PR
 *
 * @function close
 * closes the modal
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

function CatalogItemDetailCtrl (
  $scope, item, itemDetails,$uibModalInstance, addItemToPRFunction, prFunction, globalFunc, starringItem, $rootScope,
  paymentTerms
) {

  $scope.mainItem = item;
  $scope.itemDetails = itemDetails;
  $scope.itemCommodity = !!itemDetails[0] ? itemDetails[0].commodity : null;

  $scope.checkPaymentTerm = checkPaymentTerm;
  $scope.itemStarring = itemStarring;
  $scope.close = close;
  $scope.addItemToPR = addItemToPR;
  $scope.checkLoading = checkLoading;

  function addItemToPR(item){
    var pr = prFunction.getPRData();
    var validateLumpSum = prFunction.validateLumpSum(item, -1);

    if (!checkPaymentTerm(item)) {
      swal({
        title: 'Invalid Payment Term',
        text: 'The payment term for this item is inactive. Please check.',
        type: 'info',
        showCancelButton: true,
        showConfirmButton: false
      });
    }
    else {
      if (!!pr.lump_sum_discount && !validateLumpSum.status) {
        swal({
          title: 'Confirm add item?',
          text: validateLumpSum.message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Confirm',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (isConfirm) {
            // remove lump sum
            prFunction.removeLumpSum(pr._id, function (resource) {
              if (!!resource)
                sendingAddNewItemRequest(item);
            })
          }
        });
      }
      else
        sendingAddNewItemRequest(item);
    }
  }

  function sendingAddNewItemRequest(item) {
    addItemToPRFunction(item).$promise.then(function(resource){
      if (!!resource && !!resource.content && !!resource.content.data)
        prFunction.setPRData(resource.content.data);

      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('addingItem', false);
    },function (e){
      globalFunc.objectErrorMessage(e);
      prFunction.setLoading('lineItemTable', false);
      prFunction.setLoading('addingItem', false);
    });
  }

  function itemStarring (product, productId) {
    starringItem.update(
      {
        user_id: $rootScope.currentUser._id,
        item_id: productId
      },
      function (resource) {
        if (product.starred)
          product.starred = false;
        else {
          product.starred = true;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }

  /**
   * get loading value from prFunction service
   * @param loadingObject
   * @returns {*|{}}
   */
  function checkLoading(loadingObject) {
    return prFunction.getLoading(loadingObject);
  }


  function close () {
    $uibModalInstance.close();
  }

  function checkPaymentTerm(item) {
    var isPaymentTermValid = false;
    if (!!item.payment_term.code) {
      if (globalFunc.findInArray($scope.validPaymentTerm, 'code',
          item.payment_term.code)) {
        isPaymentTermValid = true;
      }
    }
    return isPaymentTermValid;
  }

  function initialize(){
    $scope.validPaymentTerm = paymentTerms;
    $scope.lastCommodity = $scope.itemCommodity;
  }

  initialize();
}

CatalogItemDetailCtrl.$injecct = [
  '$scope', 'item', 'itemDetails','$uibModalInstance', 'addItemToPRFunction', 'prFunction', 'globalFunc', 'starringItem',
  '$rootScope', 'paymentTerms'
];

angular
  .module('metabuyer')
  .controller('CatalogItemDetailCtrl',CatalogItemDetailCtrl);
