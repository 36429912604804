'use strict';
/**
 * @name mobileSearchBar Directive
 * @desc Directive for search bar in mobile version
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright Sunway Metacloud &copy; 2017
 */
angular
  .module('metabuyer')
  .directive('mobileSearchBar',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/mobileSearchBar/mobileSearchBar-template.html',
      controller: function ($scope, $state) {
        $scope.search = search;

        function search(query) {
          var param = {};
          param.query = query;
          if (!!$state.current.name && $state.current.name.indexOf('purchaseRequisitions') > -1) {
            $state.go('main.user.purchaseRequisitions.manage', param);
          }
          else if ((!!$state.current.name && $state.current.name.indexOf('orders') > -1)) {
            $state.go('main.user.orders.manage', param);
          }
        }
      }
    }
  });
