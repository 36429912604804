'use strict';

angular.module('metabuyer')
  .factory('Account', function($http, $auth, pathConstants) {
    return {
      getProfile: function() {
        return $http.get(pathConstants.apiUrls.profile);
      },
      updateProfile: function(profileData) {
        return $http.put(pathConstants.apiUrls.profile, profileData);
      },
      uploadImagePath: function (userID) {
        var path = pathConstants.apiUrls.users.single + "/image";
        return path.replace(":id", userID);
      }
    };
  })
  //TODO: rename this to be single profile factory (Ahmed Saleh)
  .factory('updateProfile', function($resource, $filter, pathConstants) {
    return $resource(
        pathConstants.apiUrls.profile,
        {
          id: '@id'
        },
        {
          'get': {method: 'GET'},
          'put': {method: 'PUT'}
        }
    )
  });
