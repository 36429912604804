'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.security', {
        url: '/security',
        controller: 'securityCtrl',
        abstract: true,
        templateUrl: 'app/security/security.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;
            if (!!profile.roles && profile.roles.length > 0) {
              for (var index in profile.roles) {
                if ((profile.roles[index].name.toLowerCase() === 'admin' && profile.roles[index].company_code === null) ||
                  profile.roles[index].name.toLowerCase() === 'tenant_super_admin'){
                  check = true;
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          },
          classesList: function(permissionsParameters, $q){
            var deferred = $q.defer();
            permissionsParameters.get(
              {},
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          classes: function(classesList, permissionsParameters, globalFunc, $q) {
            var deferred = $q.defer();
            var array = [];
            var promises = [];
            var companyConditions =  globalFunc.securityConditions();

            /**
             * Get Conditions List of the Class.
             *
             * @param permClass
             * @returns {Array}
             */
            function getConditionsList(permClass){
              var availableConditions = [],
                checkCondition = globalFunc.findInArray(companyConditions, 'class', permClass);

              var temp = {
                name: 'Self',
                value: '(@__OWN__)'
              };

              availableConditions.push(temp);

              if(!!checkCondition && !!checkCondition.value) {
                availableConditions.push({
                  name: 'Company',
                  value: checkCondition.value
                });

                if (permClass.toLowerCase() === 'po') {
                  availableConditions.push({
                    name: 'Cost Center',
                    value: '(@__COST_CENTER__)'
                  });
                }

                /**
                 * adding Not Allowed in case of 2 or more options
                 */
                if(availableConditions.length > 1){
                  temp = {
                    name: 'not allowed',
                    value: 'null'
                  };
                  availableConditions.push(temp);
                }
              }


              return availableConditions;
            }

            /**
             * To get permissions list for all classes
             * and define it in new object of array
             */
            _.forEach(classesList.data, function (permClass) {

              var promise = permissionsParameters.get(
                {
                  class: Object.keys(permClass)
                });
              promises.push(promise.$promise);
            });


            $q.all(promises).then(function (resource) {
              angular.forEach(resource, function (singleClass) {
                if(!!singleClass.content && !!singleClass.content.data) {
                  // Var for specific order of the subjectAction
                  var specificOrderArray = ['Create', 'Read', 'Update', 'Delete', 'Approve'];
                  // Sorts the return data from the promise based on the specific order
                  var data = singleClass.content.data.sort(function(first, second) {
                    return specificOrderArray.indexOf(first.subjectAction) - specificOrderArray.indexOf(second.subjectAction);
                  });
                  array.push({
                    className: globalFunc.translateSecurityClasses(data[0].subjectClass.split('\\').pop()),
                    val: data,
                    dropdown: getConditionsList(data[0].subjectClass.split('\\').pop())
                  });
                }
              });
            }).catch().finally(function () {
              deferred.resolve(array);
            });

            return deferred.promise;
          },
          companies: function(searchModule, $q) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          permissions: function(permissionsList, $q){
            var deferred = $q.defer();
            permissionsList.get(
              {
                offset: 10000
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          }
        }
      })
      .state('main.user.security.rolesListing', {
        templateUrl: 'app/security/rolesListing/rolesListing.html',
        url: '/rolesListing/{code:[a-zA-Z0-9]+}?{order:[a-zA-Z0-9]+}&{order_by:[a-zA-Z0-9]+}',
        controller: 'rolesListingCtrl',
        resolve: {
          dataList: function($q, $stateParams, searchModule){
            var deferred = $q.defer();
            /**
             * Loading company roles
             */
            searchModule.get({
                module: 'role',
                'criteria[0][company_code][1]': '',
                'criteria[0][company_code][2]': $stateParams.code,
                'criteria[0][$operator]': 'or',
                'criteria[1][type]': 1,
                offset: 0,
                order: $stateParams.order,
                order_by: $stateParams.order_by
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function(error){
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Security - Roles Listing";
          }
        }
      })
      .state('main.user.security.manage', {
        templateUrl: 'app/security/manage/manageTemplate.html',
        url: '/manage',
        resolve: {
          $title: function(){
            return "Security - Manage";
          }
        }
      })
      .state('main.user.security.add', {
        templateUrl: 'app/security/add/add-roleTemplate.html',
        url: '/add/{code:[a-zA-Z0-9]+}',
        controller: 'addRolesCtrl',
        resolve: {
          $title: function(){
            return "Security - Add Role";
          }
        }
      })
      .state('main.user.security.edit', {
        templateUrl: 'app/security/manage/manageTemplate.html',
        url:'/edit?{code:[a-zA-Z0-9]+}/{id:[a-zA-Z0-9]+}',
        controller: 'securityManageCtrl',
        resolve: {
          managedRole: function(rolesList, $q, $stateParams) {
            var deferred = $q.defer();
            rolesList.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          permissionsList: function(permissions){
            return permissions
          },
          classes: function(classesList, permissionsParameters, globalFunc, $q) {
            var deferred = $q.defer();
            var array = [];
            var promises = [];
            var companyConditions =  globalFunc.securityConditions();

            /**
             * Get Conditions List of the Class.
             *
             * @param permClass
             * @returns {Array}
             */
            function getConditionsList(permClass){
              var availableConditions = [],
                checkCondition = globalFunc.findInArray(companyConditions, 'class', permClass);

              var temp = {
                name: 'Self',
                value: '(@__OWN__)'
              };

              availableConditions.push(temp);

              if(!!checkCondition && !!checkCondition.value) {
                availableConditions.push({
                    name: 'Company',
                    value: checkCondition.value
                });

                if (permClass.toLowerCase() === 'po') {
                  availableConditions.push({
                    name: 'Cost Center',
                    value: '(@__COST_CENTER__)'
                  });
                }

                /**
                 * adding Not Allowed in case of 2 or more options
                 */
                if(availableConditions.length > 1){
                  temp = {
                    name: 'not allowed',
                    value: 'null'
                  };
                  availableConditions.push(temp);
                }
              }
              return availableConditions;
            }

            /**
             * To get permissions list for all classes
             * and define it in new object of array
             */
            _.forEach(classesList.data, function (permClass) {
              var promise = permissionsParameters.get({
                class: Object.keys(permClass)
              });
              promises.push(promise.$promise);
            });

            $q.all(promises).then(function (resource) {
              angular.forEach(resource, function (singleClass) {
                if(!!singleClass.content && !!singleClass.content.data) {
                  var data = singleClass.content.data;
                  array.push({
                    className: globalFunc.translateSecurityClasses(data[0].subjectClass.split('\\').pop()),
                    val: data,
                    dropdown: getConditionsList(data[0].subjectClass.split('\\').pop())
                  });
                }
              });
            }).catch().finally(function () {
              deferred.resolve(array);
            });

            return deferred.promise;
          },
          $title: function(){
            return "Security - Edit Role";
          }
        }
      })
      .state('main.user.security.view', {
        templateUrl: 'app/security/edit/permissionsViewTemplate.html',
        url:'/view/{id:[a-zA-Z0-9]+}',
        resolve: {
          $title: function(){
            return "Security - Role";
          }
        }
      })
  }]);

