'use strict';

/**
 * @name supplierTagsByCompanyDetailCtrl
 *
 * @description Controller for supplier tags grouped by selected company
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>
 * @copyright 2018 Metacloud Sdn. Bhd.
 */
function supplierTagsByCompanyDetailCtrl($scope, suppliersTaggedToCompany, $rootScope, companyName, $stateParams,
                                         supplierGlobalFunc, detailState, $state, canEditSupplierTag, HighlightId) {

  $scope.showColumn = showColumn;
  $scope.back = back;
  $scope.getStatusName = getStatusName;
  $scope.goToSupplierTagging = goToSupplierTagging;
  $scope.isCurrentUserSupplierManager = isCurrentUserSupplierManager;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function back() {
    if (!!$stateParams.companyId) {
      HighlightId.setId($stateParams.companyId);
    }

    window.history.back();
  }

  /**
   * returns the status name
   * @param supplierStatus
   * @param approvalStatus
   * @param draftingStatus
   * @returns {*}
   */
  function getStatusName(supplierStatus, approvalStatus, draftingStatus) {
    var status = supplierGlobalFunc.getSupplierStatus(supplierStatus, approvalStatus, draftingStatus, 'val');
    if (!!status)
      return status;

    return 'N/A';
  }

  function goToSupplierTagging() {
    $state.go(detailState,
      {
        companyCode: $scope.companyCode
      });
  }

  /**
   * Check if current user has system role: Supplier Manager
   *
   * @returns {boolean}
   */
  function isCurrentUserSupplierManager() {
    return (supplierGlobalFunc.checkIfUserHasSupplierManagerRole($rootScope.currentUser));
  }

  function initialize() {
    $scope.editClicked = false;
    $scope.canEditSupplierTag = canEditSupplierTag;
    $scope.companyName = companyName;
    $scope.companyCode = $stateParams.companyCode;
    $scope.suppliersTaggedToCompany = suppliersTaggedToCompany.data;
    $scope.meta = suppliersTaggedToCompany.meta;
    $scope.supplierTagService = suppliersTaggedToCompany;
    $scope.storageKey = 'suppliers-tagged-to-company-selected-columns';
    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

supplierTagsByCompanyDetailCtrl.$inject = [
  '$scope', 'suppliersTaggedToCompany', '$rootScope', 'companyName', '$stateParams', 'supplierGlobalFunc',
  'detailState', '$state', 'canEditSupplierTag', 'HighlightId'
];

angular.module('metabuyer')
  .controller('supplierTagsByCompanyDetailCtrl', supplierTagsByCompanyDetailCtrl);
