'use strict';

angular.module('metabuyer.services.orders', ['ngResource'])
  .factory('Orders', function ($resource, pathConstants) {
    var Orders = $resource(
      pathConstants.apiUrls.po.single,
      {
        id: '@id',
        status: '@status'
      },
      {
        'search': {
          method: 'GET',
          url: pathConstants.apiUrls.po.search
        },

        'resendPO': {
          method: 'PUT',
          params:{
            id: "@id"
          },
          url: pathConstants.apiUrls.po.resend
        },
        'setPOStatus': {
          method: 'PUT',
          params:{
            id: "@id"
          },
          url: pathConstants.apiUrls.po.single
        },
        'closeLineItem': {
          method: 'PUT',
          params:{
            po_id: "@po_id",
            item_id: "@item_id"
          },
          url: pathConstants.apiUrls.po.closeLineItem
        }
      }
    );
    return Orders;
  })
  .factory('generatePO',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.po.single,
      {
        id: '@id'
      },
      {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('getPOPDFHash',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.po.hash,
      {
        id: '@id'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('listPOs',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.po.list,
      {
      },
      {
        'get': { method: 'GET' }
      }
    );
  });
