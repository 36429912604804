'use strict';

function grnCtrl($scope,  $uibModalInstance, grn, po, getGRNPDFHash, $filter, toastr, pathConstants, $rootScope) {

  $scope.grn = grn;
  $scope.po = po;

  $scope.cancel = cancel;
  $scope.printGRN = printGRN;

  function cancel(){
    $uibModalInstance.close();
  }

  function printGRN() {

    var tabWindow = window.open('', '_blank');

    getGRNPDFHash.get(
      {
        id: $scope.grn._id,
        timezone: $rootScope.currentUser.config.TZ
      }
    ).$promise.then(
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.po.pdfHash, {
          pdf_hash: resource.url
        });

        setTimeout(function (){
            tabWindow.location.href = newUrl;
          },
          1001
        );
      },
      function(){
        toastr.error('Failed to print GRN');
      }
    );
  }
}

grnCtrl.$inject = ['$scope', '$uibModalInstance', 'grn', 'po', 'getGRNPDFHash', '$filter', 'toastr', 'pathConstants', '$rootScope'];

angular.module('metabuyer')
  .controller('grnCtrl', grnCtrl);
