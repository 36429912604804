'use strict';

function purchaseRequisitionsActionCtrl(
  $scope, pathConstants, purchaseRequisitionsServices, $state, HighlightId, prFunction, $rootScope, globalFunc, toastr,
  $stateParams, getPRPDFHash, $filter, UserPermissions, generatePO, notificationSubscriber
){

  $scope.localApprovalData          = {};
  $scope.currentUser                = $rootScope.currentUser || { _id: null };
  $scope.nextPR                     = { context_id: 'loading' };
  $scope.userBasePath               = $rootScope.userImageBasePath;
  $scope.waitingOnUser              = [];
  $scope.templateUrlPath            = 'components/purchaseRequisitionComponents/actions/purchaseRequisitionActions.html';
  $scope.module                     = 'PR';

  if ($rootScope.isMobileMode) {
    $scope.templateUrlPath = 'components/purchaseRequisitionComponents/actions/purchaseRequisitionActions-mobile.html';
    if ($scope.actionType === 'button')
      $scope.templateUrlPath = 'components/purchaseRequisitionComponents/actions/purchaseRequisitionActions-mobile-button.html';
  }

  $scope.deletePR                   = deletePR;
  $scope.generatePurchaseOrder      = generatePurchaseOrder;
  $scope.approvalAction             = approvalAction;
  $scope.submitPurchaseRequisition  = submitPurchaseRequisition;
  $scope.transitionToListingPending = transitionToListingPending;
  $scope.approvalFlowData           = approvalFlowData;
  $scope.checkWaitingOn             = checkWaitingOn;
  $scope.isPRCreator                = isPRCreator;
  $scope.canSubmit                  = canSubmit;
  $scope.canUnHold                  = canUnHold;
  $scope.canApprove                 = canApprove;
  $scope.canDelete                  = canDelete;
  $scope.actionCondition            = actionCondition;
  $scope.refreshPRDetail            = refreshPRDetail;
  $scope.prAction                   = prAction;
  $scope.printPRViaPDF              = printPRViaPDF;
  $scope.notificationToggle         = notificationToggle;
  $scope.duplicatePR                = duplicatePR;
  $scope.moveToDraftPR              = moveToDraftPR;
  $scope.allowEditingPR             = allowEditingPR;
  $scope.setEditableMode            = setEditableMode;
  $scope.canHold                    = canHold;
  $scope.canReject                  = canReject;
  $scope.canWithdraw                = canWithdraw;
  $scope.canGeneratePO              = canGeneratePO;
  $scope.submitEditableDraft        = submitEditableDraft;
  $scope.requestEditableDraft       = requestEditableDraft;
  $scope.cancelEditingPR            = cancelEditingPR;
  $scope.allowedToRevertApproval    = allowedToRevertApproval;
  $scope.revertApproval             = revertApproval;
  $scope.revertApprovalErrorHandler = revertApprovalErrorHandler;
  $scope.canUpdateCollaborators     = canUpdateCollaborators;

  /**
   * Check if the current PR has valid status to update the collaborator
   *
   * @param string currentPrStatus String of PR status
   * @returns {boolean}
   */
  function canUpdateCollaborators(currentPrStatus) {
    var validPRStatus = [
      'draft',
      'pending',
      'on_hold',
      'withdrawn',
      'rejected',
      'approved'
    ];

    return !_.isEmpty(_.intersection(validPRStatus, [currentPrStatus]));
  }

  function allowedToRevertApproval () {
    return $scope.PR.status.toLowerCase() === 'pending' && globalFunc.findInArray($scope.currentUser.roles, 'name', 'TENANT_SUPER_ADMIN');
  }

  function revertApproval(extraPayload) {
    return purchaseRequisitionsServices.resetApproval(
      {
        approval_id: $scope.PR.approval_id
      },
      extraPayload
    );
  }

  function revertApprovalErrorHandler(message){
    globalFunc.objectErrorMessage(message);
  }

  function approvalFlowData() {
    $scope.localApprovalData = prFunction.getApprovalFlow();
    if (!!$scope.localApprovalData && !!$scope.localApprovalData.waiting_on && !!$scope.localApprovalData.waiting_on.length)
      globalFunc.getWaitingOnUser($scope.localApprovalData, $scope.waitingOnUser);
    return $scope.localApprovalData;
  }

  function loadNextData() {
    if ($scope.PR.status === 'pending' || $scope.PR.status.toLowerCase() === 'on_hold') {
      /**
       * add the 2 extra params for Hold and Reject PRs
       * @type {any}
       */
      $scope.onHoldPRExtraParams = prepareOnHoldExtraParams();
      $scope.rejectPRExtraParams = {};
      $scope.revertExtraParams = {};
      globalFunc.nextAvailableAction('Metabuyer\\PR\\PR', $scope.PR._id).then(function (data) {
        if (!!data && !!data.waitingOnObject && !!data.waitingOnObject.context_id) {
          $scope.nextPR = data.waitingOnObject;
          $scope.totalPRs = data.count;
        }
        else
          $scope.nextPR = {};
      });
    }
  }

  /**
   * Refresh PR detail page
   */
  function refreshPRDetail(){
    $state.transitionTo($state.current, $stateParams, {
      reload: true,
      inherit: false,
      notify: true
    });
  }

  function notificationToggle() {

    if(!$scope.PR.notify) {
      notificationSubscriber.post({
          object_id: $scope.PR._id, class: 'Metabuyer\\PR\\PR', method: 'subscribe'
        }
        , function (data) {
          $scope.PR.notify = true;
          toastr.success('Turned on notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn on notifications');
        });
    }
    else{
      notificationSubscriber.delete({
          object_id: $scope.PR._id, class: 'Metabuyer\\PR\\PR', method: 'unsubscribe'
        }
        , function (data) {
          $scope.PR.notify = false;
          toastr.success('Turned off notifications successfully');
        }
        , function (error) {
          toastr.error('Failed to turn off notifications');
        });
    }

  }

  function duplicatePR() {
    purchaseRequisitionsServices.duplicate({
      id: $scope.PR._id
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        $state.go('main.user.purchaseRequisitions.details', { id: resource.content.data._id });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  function moveToDraftPR(){
    purchaseRequisitionsServices.moveToDraft(
      {
        id: $scope.PR._id
      }, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          $state.reload();
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      })
  }

  /**
   * Preparing on hold extra params
   * @returns {{user_ids: Array}}
   */
  function prepareOnHoldExtraParams() {
    var userIDArray = [];
    userIDArray.push($scope.PR.creator._id);
    if ($scope.PR.requestor._id !== $scope.PR.creator._id)
      userIDArray.push($scope.PR.requestor._id);

    return {user_ids: userIDArray};
  }

  function isPRCreator() {
    return ($scope.currentUser._id === $scope.PR.creator._id);
  }

  /**
   * check the statuses of the items in which the PR is still approvable
   * a list of string for all the statuses that can be approved
   * @returns {boolean}
   */
  function checkItemsApprovability() {
    var items = prFunction.getPRData().pr_items;
    var approvalableStatuses = ['0', '11', '12', '13', '14', '18', '19'];
    if (!items || items.length < 1) {
      return false;
    }
    for (var i in items) {
      if (_.isUndefined(items[i].item_validity_status))
        return false;

      if (approvalableStatuses.indexOf(items[i].item_validity_status.toString()) === -1)
        return false;
    }
    return true;
  }

  /**
   * Function to check PR data for changes and approvability
   * @returns {boolean}
   */
  function checkPRDataApprovability(){
    var pr = prFunction.getPRData();
    if(!!pr.pr_related_data_changes)
      return false;

    return true;
  }

  function canGeneratePO() {
    return (
      ($scope.PR.status.toLowerCase() === 'approved') &&
      (!$scope.PR.pos || (!!$scope.PR.pos && !$scope.PR.pos.length)) &&
      (
        isPRCreator() ||
        $scope.PR.requestor._id === $scope.currentUser._id ||
        (globalFunc.findInArray($scope.currentUser.roles, 'name', 'TENANT_SUPER_ADMIN') ||
        globalFunc.findInArray($scope.currentUser.roles, 'name', 'ADMIN'))
      )
    );
  }

  function canApprove() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $rootScope.currentUser._id);
    var PRApprovable  = checkItemsApprovability();
    var PRDataChanged = checkPRDataApprovability();

    return (checkPRStatus && !!isApprover && PRDataChanged && PRApprovable && checkUserAccessToCompany());
  }

  function canUnHold() {
    var approverAbleToHoldPR = true;
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'on_hold');
    var isApprover = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);
    var selectedCompany = globalFunc.findInArray($scope.currentUser.companies, '_id', $scope.PR.company._id);

    if (!!selectedCompany && !!selectedCompany.config && !!selectedCompany.config.allowedToHoldPR) {
      approverAbleToHoldPR = selectedCompany.config.allowedToHoldPR;
    }

    return (checkPRStatus && ((approverAbleToHoldPR && !!isApprover && checkUserAccessToCompany()) ||
    !!globalFunc.findInArray($scope.currentUser.roles, 'name', 'TENANT_SUPER_ADMIN')));
  }

  function canHold() {
    var approverAbleToHoldPR = true;
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);
    var selectedCompany = globalFunc.findInArray($scope.currentUser.companies, '_id', $scope.PR.company._id);
    if (!!selectedCompany && !!selectedCompany.config && !!selectedCompany.config.allowedToHoldPR) {
      approverAbleToHoldPR = selectedCompany.config.allowedToHoldPR;
    }

    return (checkPRStatus && ((approverAbleToHoldPR && !!isApprover && checkUserAccessToCompany()) ||
    !!globalFunc.findInArray($scope.currentUser.roles, 'name', 'TENANT_SUPER_ADMIN')));
  }

  function canReject() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    var isApprover    = globalFunc.findInArray($scope.localApprovalData.waiting_on, '_id', $scope.currentUser._id);

    return (checkPRStatus && !!isApprover && checkUserAccessToCompany());
  }

  function canWithdraw() {
    var checkPRStatus = ($scope.PR.status.toLowerCase() === 'pending');
    return (checkPRStatus && ($scope.PR.creator._id === $scope.currentUser._id || (!!$scope.PR.requestor && ($scope.PR.requestor._id === $scope.currentUser._id))));
  }

  function canSubmit() {
    var pr = getUpdatedPRData();
    return (isPRCreator() && (!!pr.pr_items && !!pr.pr_items.length) && pr.status.toLowerCase() === 'draft');
  }

  /**
   * check permission and pr status to delete
   * @returns {*|boolean}
   */
  function canDelete() {
    var pr = getUpdatedPRData();
    return (isPRCreator() && pr.status.toLowerCase() === 'draft');
  }

  function getUpdatedPRData() {
    return prFunction.getPRData();
  }

  function checkWaitingOn() {
    return true;
  }

  function printPRViaPDF() {

    /**
     * Validate the pr items before proceeding
     */
    if (!$scope.PR.pr_items || $scope.PR.pr_items.length < 1) {
      toastr.error('Print PR failed because the PR has no items.');
    }
    else {

      /**
       * Pass current logged in user's timezone
       */
      getPRPDFHash.get(
        {
          id: $scope.PR._id,
          timezone: $scope.currentUser.config.TZ
        }, function (resource) {
          var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
            pdf_hash: resource.url
          });

          setTimeout(function () {
            window.open(newUrl, '_blank')
          }, 1001);
        }, function () {
          toastr.error('Failed to print PR');
        }
      );
    }
  }

  /**
   * Function for deleting PR
   */
  function deletePR(){
    swal(
      {
        title: 'Confirm delete PR',
        text: 'This PR will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Delete',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          if (!!$scope.PR) {
            purchaseRequisitionsServices.deleteSinglePR({
              id: $scope.PR._id
            }, function () {
              toastr.success('PR deleted');
              $state.go('main.user.purchaseRequisitions.manage', {status: 'draft', tab: 'all'});
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            })
          }
        }
      }
    );
  }

  /**
   * Function for generating PO manually in cases where PO failed to generate
   * @param PRId
   */
  function generatePurchaseOrder(PRId){
    generatePO.post({
      id: PRId
    }, function (resource) {
      if (!!resource && !!resource.content && !!resource.content.message) {
        toastr.success(resource.content.message);
        $state.reload();
      }
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * allow the editing button to appear
   * @returns {*}
   */
  function allowEditingPR() {
    if (prFunction.getPRData().status === 'pending')
      return prFunction.checkCurrentApproverIsPA(!!prFunction.getPRData() ? prFunction.getPRData().waiting_on : null, !!$rootScope.currentUser ? $rootScope.currentUser._id : null,
        !!$rootScope.currentUser ? $rootScope.currentUser.roles : null);
    else
      return false
  }

  function setEditableMode(mode){
    prFunction.setEditableState(mode);
  }

  function prAction(title, action) {
    swal(
      {
        title: title,
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (is_confirm) {
        if (is_confirm) {
          if (action === 1)
            approvalAction('approve', 'You have approved this purchase requisition');
          if (action === 3)
            approvalAction('release', 'You have removed the hold from this purchase requisition', {});
        }
      }
    );
  }

  /**
   * function returns the approval resource so it can be used on other module
   *
   * @param status
   * @param text
   * @param extraPayload in some cases the action needs a comment or such extra payload passed to the backend
   * @returns {*|{method, params, url}}
   */
  function approvalAction(status, text, extraPayload) {

    return purchaseRequisitionsServices.approvalAction(
      {
        approval_id: $scope.PR.approval_id,
        status: status
      },
      extraPayload,
      function () {
        if (!!text)
          toastr.success(text);
        else
          toastr.success('You have submitted this purchase requisition.');
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * the condition to pass to the directive to check if the action is ready to fire
   * @returns {boolean}
   */
  function actionCondition(){
    // check if PA and missing Tax
    // if (checkPurchaseAgentRole() && checkIfMissingTax()) {
    //   toastr.error('Item(s) are missing a Tax. Please complete them before approving');
    //   return false;
    // }
    return true;
  }

  /**
   * Submit PR
   * @param ignoreOverBudget (0-do not ignore over budget; 1-do ignore over budget)
   */
  function submitPurchaseRequisition(ignoreOverBudget) {
    submitValidation();

    return purchaseRequisitionsServices.submitPR({
      id: $scope.PR._id,
      data: {
        ignore_over_budget: ignoreOverBudget
      }
    }, function () {
      transitionToListingPending();
    }, function (error) {
      var message = (!!error.data && !!error.data.content && !!error.data.content.message) ?
        error.data.content.message : null;
      switch (message) {
        // case where over budget detected, but is allowed
        case 'Overbudget is allowed.':
          userToConfirmOverbudget();
          break;
        // case where over budget detected, but is not allowed further action
        case 'Overbudget is not allowed.':
          alertUserOnOverBudgetNotAllowed();
          break;
      }
    });
  }

  /**
   * pop up to ask user if the over budget pr submit is confirmed
   */
  function userToConfirmOverbudget() {
    swal(
      {
        title: 'Confirm submission?',
        text: 'This requisition exceeds the available budget amount, confirm the submission?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          submitPurchaseRequisition(true);
        }
      }
    );
  }

  /**
   * pop to notify the user over-budget is not allowed
   */
  function alertUserOnOverBudgetNotAllowed() {
    swal(
      {
        title: 'Available budget exceeded.',
        text: 'This requisition exceeds the available budget amount.',
        type: 'info',
        confirmButtonColor: '#D0D0D0',
        confirmButtonText: 'Close',
        closeOnConfirm: true
      }
    );
  }

  function submitValidation() {
    var pr = getUpdatedPRData();
    var errors = [];
    if (!!pr) {
      // validate expense type
      if (!pr.expense_type || (!!pr.expense_type && !pr.expense_type.code)) {
        errors.push('expense_type');
        toastr.error('Expense type is required.');
      }

      if (!pr.contact_person) {
        errors.push('contact_person');
        toastr.error('Contact person is required.');
      }

      if (_.isEmpty(pr.budget))
        errors.push('budget');

      if (_.isEmpty(pr.title)) {
        errors.push('title');
        toastr.error('PR Title is required.');
      }
    }

    prFunction.setErrorsOnSubmit(errors);
    return !!(errors.length);
  }

  function transitionToListingPending(){
    $state.go('main.user.purchaseRequisitions.manage', {status: 'pending', tab: 'all'});
    HighlightId.setId($scope.PR._id);
  }

  function requestEditableDraft(){
    purchaseRequisitionsServices.requestEditableCopy({
      id: prFunction.getPRData()._id
    },{}, function (resource) {
      if(!!resource.content && !!resource.content.data) {
        prFunction.setPRData(resource.content.data);
        setEditableMode('editable');
        // Refresh attachment stuff
        $scope.$emit('loadAtt');
        $rootScope.$broadcast('thisBetterWork');

      }
      else
        globalFunc.objectErrorMessage('Something went wrong')
    }, function (e) {
      globalFunc.objectErrorMessage(e);
    });
  }

  function submitEditableDraft(){
    swal(
      {
        title: 'Confirm submit edited PR?',
        text: 'The approval flow will be restarted',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      },
      function (isConfirm) {
        if (isConfirm) {
          purchaseRequisitionsServices.submitEditableCopy({
            id: prFunction.getPRData()._id
          }, {}, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prFunction.setPRData(resource.content.data);
              setEditableMode('notEditable');
              $state.reload();
            }
            else
              globalFunc.objectErrorMessage('Something went wrong')
          }, function (e) {
            globalFunc.objectErrorMessage(e);
          });
        }
      }
    );
  }

  function cancelEditingPR() {
    purchaseRequisitionsServices.cancelEditableCopy({
      id: prFunction.getPRData()._id
    }, {}, function (resource) {
      if(!!resource.content && !!resource.content.data) {
        prFunction.setPRData(resource.content.data);
        setEditableMode('notEditable');
        $state.reload();
      }
      else
        globalFunc.objectErrorMessage('Something went wrong')
    }, function (e) {
      globalFunc.objectErrorMessage(e);
    });
  }

  /**
   * Check user access to the company
   * @returns {boolean}
   */
  function checkUserAccessToCompany() {
    return !!globalFunc.findInArray(
      $scope.currentUser.companies,
      'code',
      prFunction.getPRData().company.code
    );
  }

  function initialize() {
    loadNextData();
  }

  initialize();

  $scope.$watch('editingState', function (newValue) {
    // Editing mode on the actions only need to change the title
    // and show hide the button
    $scope.editingState = newValue;
  });
}

purchaseRequisitionsActionCtrl.$inject = [
  '$scope', 'pathConstants', 'purchaseRequisitionsServices', '$state', 'HighlightId', 'prFunction', '$rootScope',
  'globalFunc', 'toastr', '$stateParams', 'getPRPDFHash', '$filter', 'UserPermissions', 'generatePO', 'notificationSubscriber'
];

angular
  .module('metabuyer')
  .directive('purchaseRequisitionActions',function() {
    return {
      restrict: 'E',
      scope: {
        editingState: '=',
        PR: '=pr',
        actionType: '@'
      },
      template: '<div ng-include="templateUrlPath"></div>',
      controller: 'purchaseRequisitionsActionCtrl'
    }
  })
  .controller('purchaseRequisitionsActionCtrl', purchaseRequisitionsActionCtrl);
