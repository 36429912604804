'use strict';
/**
 * @name approvalFlowManagementController
 * @desc Controller for Approval Flow Management for listing All the available tabs and functions of the Approval Flow
 */

function approvalFlowManagementController (
  $scope, approvalTemplateListing, approvalTemplates, globalFunc, approvalTemplateSingle, toastr, $state, searchModule,
  $uibModal, companies, companyExpenseTypes, HighlightId
) {

  $scope.importParams = {
    class: 'Metabuyer\\ApprovalFlow\\Templates\\Models\\ApprovalTemplate',
    'additional_params[]': ''
  };

  $scope.columns = [
    { id: 'number', label: '#', unsortable: true, unsearchable: true, unSelectable: true },
    { id: 'type', label: 'Type' },
    { id: 'code', label: 'Code' },
    { id: 'title', label: 'Title' },
    { id: 'company', label: 'Company' , unsortable: true, unsearchable: true},
    { id: 'cost_center', label: 'Cost Center' , unsortable: true, unsearchable: true},
    { id: 'expense_type', label: 'Expense Type' , unsortable: true, unsearchable: true},
    { id: 'effective_at', label: 'Effective At', unsortable: true, unsearchable: true},
    { id: 'created_at', label: 'Created At' },
    { id: 'updated_at', label: 'Updated At' },
    { id: 'created_by', label: 'Created By', unsortable: true, unsearchable: true},
    { id: 'updated_by', label: 'Updated By', unsortable: true, unsearchable: true},
    { id: 'status', label: 'Status' },
    // { id: 'remove', label: 'Remove', unsortable: true, unsearchable: true, unSelectable: true }
  ];

  $scope.meta = [];
  $scope.page = 0;
  $scope.limit = 10;
  $scope.currentStatus = {
    col: null,
    order: null
  };
  $scope.timezoneDiff = '';
  $scope.colSorting = null;
  $scope.colOrder = null;
  $scope.storageKey = 'approval-templates-manage-selected-columns';
  $scope.companies = [];
  $scope.uiCompanySelect = {};
  $scope.uiCompanySelect.list = {};
  $scope.uiCostCenterSelect = {};
  $scope.uiCostCenterSelect.list = {};
  $scope.uiExpenseSelect = {};
  $scope.uiExpenseSelect.list = {};
  $scope.companyListing = companies.data;
  $scope.expenseTypesListing = null;
  $scope.checkTemplateType = checkTemplateType;

  $scope.templateTypes = [
    {
      code: 'PR',
      descr: 'PR'
    },
    {
      code: 'Supplier',
      descr: 'Supplier'
    },
    {
      code: 'Catalog',
      descr: 'Catalog'
    },
    {
      code: 'Budget',
      descr: 'Budget'
    }
  ];
  $scope.uiType = {};
  $scope.uiType.list = [];
  $scope.uiCode = null;
  $scope.uiTitle = null;
  $scope.uiExpense = null;
  $scope.statusListing = [
    {
      code: '0',
      descr: 'Active'
    },
    {
      code: '1',
      descr: 'Inactive'
    }
  ];
  $scope.status = {};
  $scope.status.list = [];

  $scope.visibleColumn = visibleColumn;
  $scope.search = search;
  $scope.removeTemplate = removeTemplate;
  $scope.exportTemplate = exportTemplate;
  $scope.search = search;
  $scope.resetSelection = resetSelection;
  $scope.processCostCenterList = processCostCenterList;

  function checkTemplateType() {
    if ($scope.uiType.list.code !== 'PR') {
      $scope.uiCompanySelect.list = null;
      $scope.uiCostCenterSelect.list = null;
      $scope.uiExpenseSelect.list = null;
    }
  }

  function visibleColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function prepareMeta() {
    $scope.processedData = [];
    $scope.processedData = $scope.dataList;
    $scope.meta.currentPageNumber = $scope.dataList.meta.current;
    $scope.meta.totalPageNumber = $scope.dataList.meta.pages;
    $scope.meta.totalRec = $scope.dataList.meta.count;
    $scope.meta.startRecNo = $scope.limit * $scope.page + 1;
    $scope.meta.endRecNo = $scope.meta.totalRec - ((1 + $scope.meta.currentPageNumber) * $scope.limit) >= 0 ?
      (1 + $scope.meta.currentPageNumber) * $scope.limit :
      (($scope.meta.currentPageNumber) * $scope.limit) + $scope.meta.totalRec % $scope.limit;
    $scope.dataList = $scope.processedData;
  }

  function getStatus(status) {
    var statusString = '';
    switch (status) {
      case 0:
        statusString = 'Active';
        break;
      case 1:
        statusString = 'Inactive';
        break;
    }
    return statusString;
  }

  function search(page, sortingCol, sortingOrder) {
    $scope.page = page;
    $scope.colSorting = sortingCol;
    $scope.colOrder = sortingOrder;
    approvalTemplates.get(
      {
        'sort[0][key]': sortingCol,
        'sort[0][direction]': sortingOrder,
        'sort[1][key]': 'status',
        'sort[1][direction]': 'ASC',
        'sort[2][key]': 'updated_at',
        'sort[2][direction]': 'DESC',
        'filter_groups[0][filters][0][key]': 'module',
        'filter_groups[0][filters][0][value]': !!$scope.uiType.list.code ? $scope.uiType.list.code : '',
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[1][filters][0][key]': 'code',
        'filter_groups[1][filters][0][value]': !!$scope.uiCode ? $scope.uiCode : '',
        'filter_groups[1][filters][0][operator]': 'ct',
        'filter_groups[2][filters][0][key]': 'title',
        'filter_groups[2][filters][0][value]': !!$scope.uiTitle ? $scope.uiTitle : '',
        'filter_groups[2][filters][0][operator]': 'ct',
        'filter_groups[3][filters][0][key]': 'status',
        'filter_groups[3][filters][0][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
        'filter_groups[3][filters][0][operator]': 'ct',
        'filter_groups[4][filters][0][key]': 'rule',
        'filter_groups[4][filters][0][value]': [
          !!$scope.uiCompanySelect.list && !!$scope.uiCompanySelect.list.code ? 'company:' + $scope.uiCompanySelect.list.code : '',
          !!$scope.uiCostCenterSelect.list && !!$scope.uiCostCenterSelect.list.code ? 'cost_center:' + $scope.uiCostCenterSelect.list.code : '',
          !!$scope.uiExpenseSelect.list && !!$scope.uiExpenseSelect.list.code ? 'expense_type:' + $scope.uiExpenseSelect.list.code : ''
        ].join(),
        'filter_groups[4][filters][0][operator]': 'ct',
        limit: $scope.limit,
        page: page
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.dataList = resource.content.data;
          $scope.dataList.meta = resource.content.meta.cursor;
          prepareMeta();
          prepareDataList();
        }
      },
      function (resource) {
        if (
          !!resource.data &&
          !!resource.data.content &&
          !!resource.data.content.message &&
          !!resource.data.content.message.errors
        ) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        }
        else {
          toastr.error('Failed to search');
        }
      }
    )
  }

  function prepareDataList() {
    _.forEach($scope.dataList, function(approval) {
      approval.status = getStatus(approval.status);
      approval.created_at = globalFunc.convertToLocalDate(approval.created_at);
      approval.updated_at = globalFunc.convertToLocalDate(approval.updated_at);
      approval.effective_date = globalFunc.convertToLocalDate(approval.effective_date);
    });
  }

  function removeTemplate(approval) {
    swal(
      {
        title: 'Warning!',
        text: 'Are you sure you want to delete \'' + approval.title + '\' ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          approvalTemplateSingle.delete({
            id: approval.id
          }, function (resource) {
            // search($scope.meta['currentPageNumber']);
            toastr.success('Approval template has been successfully deleted.');
            $state.reload();
          }, function (error) {
            if (
              !!resource.data &&
              !!resource.data.content &&
              !!resource.data.content.message &&
              !!resource.data.content.message.errors
            ) {
              toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
            } else {
              toastr.error('Failed to delete selected approval template.');
            }
          });
        }
      }
    );
  }

  function exportTemplate() {
    $uibModal.open({
      templateUrl: 'app/approvalFlow/manage/export.html',
      controller: 'exportTemplateController',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        companies: function() {
          return $scope.companies;
        }
      }
    });
  }

  function getCompanies() {
    $scope.disableExportButton = true;
    searchModule.get(
      {
        module: 'companies',
        offset: 0,
        order_by: 'descr',
        order: 1
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.companies = resource.content.data;
          $scope.disableExportButton = false;
        }
      }
    );
  }

  function resetSelection() {
    $scope.uiType.list = [];
    $scope.uiCode = null;
    $scope.uiTitle = null;
    $scope.uiCompanySelect.list = null;
    $scope.uiCostCenterSelect.list = null;
    $scope.uiExpenseSelect.list = null;
    $scope.status.list = [];
  }

  function processCostCenterList() {
    $scope.uiCostCenterSelect.list = {};
    $scope.uiExpenseSelect.list = [];

    //stop when no company is selected
    if (!$scope.uiCompanySelect.list.code) {
      $scope.costCenterListing = [];
      return;
    }

    loadExpenseTypes();

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'and',
      order_by: 'descr',
      order: 1
    };

    if ($scope.uiCompanySelect.list.code !== 'SYSTEM')
      params['criteria[company_code]'] = $scope.uiCompanySelect.list.code;

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterListing = resource.content.data;
          $scope.costCenterListing.unshift({'code':'all', 'descr':'All'});
        }
      }
    )
  }

  function loadExpenseTypes() {
    companyExpenseTypes.get(
      {
        id: $scope.uiCompanySelect.list._id
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypesListing = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function initialize() {
    $scope.timezoneDiff = globalFunc.getTimezoneDiff();
    $scope.dataList = approvalTemplateListing.data;
    $scope.dataList.meta = approvalTemplateListing.meta.cursor;
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
    prepareMeta();
    prepareDataList();
    getCompanies();
  }

  initialize();
}

approvalFlowManagementController.$inject = [
  '$scope', 'approvalTemplateListing', 'approvalTemplates', 'globalFunc', 'approvalTemplateSingle', 'toastr', '$state',
  'searchModule', '$uibModal', 'companies', 'companyExpenseTypes', 'HighlightId'
];

angular
  .module('metabuyer')
  .controller('approvalFlowManagementController', approvalFlowManagementController);
