'use strict';
/**
 * @name companiesDetailCtrl
 * @desc Controller for Companies detail
 */
function companyDetailCtrl(
  $scope, $state, companyDetails, Companies, $uibModal, toastr, currenciesList, BUs, industryList, metabuyerCache,
  singleAddress, $filter, pathConstants, companiesDetailImage, $auth, globalFunc, companyCurrencies, companies,
  $rootScope, companyGlobalFunc, expenseTypesList, exportSearch, companyCostCentersAddress, companyBudgetSetting,
  HighlightId) {

  var backupCorrespondingAddress = {
    line1: companyDetails.data.corresponding_address.line1,
    line2: companyDetails.data.corresponding_address.line2,
    line3: companyDetails.data.corresponding_address.line3,
    postal_code: companyDetails.data.corresponding_address.postal_code,
    city: companyDetails.data.corresponding_address.city,
    state: companyDetails.data.corresponding_address.state,
    country: companyDetails.data.corresponding_address.country,
    phone: companyDetails.data.corresponding_address.phone
  };

  $scope.companyDetails = companyDetails.data;
  $scope.tabActive = "companies";
  $scope.parentCompany = [];
  $scope.BUs = BUs.data;
  $scope.industries = industryList.data;
  $scope.currencies = currenciesList;
  $scope.expenseTypes = expenseTypesList;
  $scope.companyCurrencies = companyCurrencies.data;
  $scope.companyDetails.corresponding_address_id = "";
  $scope.preferredCurrencyList = [];
  $scope.selectedCompanyCurrencies = [];
  $scope.selectedPreferredCurrency = {};
  $scope.importLink = pathConstants.apiUrls.costCenters.import;
  $scope.countries = [];
  $scope.MYStates = [];
  $scope.extraSettingCurrencies = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select currency'
  };
  $scope.extraSettingExpenseTypes = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    showCheckAll: true,
    showUncheckAll: true,
    enableSearch: true,
    buttonDefaultText: 'Select Expense type'
  };
  $scope.extraSettingPreferredCurrencies = {
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    scrollable: true,
    scrollableHeight: '200px',
    enableSearch: true,
    selectionLimit: 1
  };
  $scope.POInitialStatuses = companyGlobalFunc.getPOInitialStatuses();
  $scope.budgetModuleToggle = null;
  $scope.budgetExceedToggle = null;
  $scope.budgetNotificationToggle = null;
  $scope.budgetThreshold = {
    normal: {
      low: null
    },
    range: {}
  };

  var formatToPercentage = function (value) {
    return value + '%';
  };

  $scope.budgetThreshold.normal.options = {
    floor: 0,
    ceil: 100,
    translate: formatToPercentage,
    showSelectionBar: true,
    getSelectionBarColor: function (value) {
      if (value <= 30)
        return '#2AE02A';
      if (value <= 60)
        return 'yellow';
      if (value <= 80)
        return 'orange';
      return 'red';
    }
  };

  $scope.budgetThreshold.range.options = {
    floor: 0,
    ceil: 100,
    translate: formatToPercentage
  };

  // config flowjs
  $scope.flowConfig = new Flow({
    target: companiesDetailImage.uploadImagePath($scope.companyDetails._id),
    singleFile: true,
    testChunks: false,
    chunkSize: 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    }
  });

  /**
   * provide the preferred currency data
   * from selectedCurrencies
   */
  $scope.selectCurrencies = {
    onItemSelect: function (selectedCurrencies) {
      var selectedCurrency = globalFunc.findInArray($scope.currencies, '_id', selectedCurrencies._id);
      $scope.companyCurrencies = globalFunc.removeValueFromArray($scope.companyCurrencies, '_id', selectedCurrencies._id);
      $scope.companyCurrencies.push(selectedCurrency);
      $scope.preferredCurrencyList = $scope.companyCurrencies;
    },
    onItemDeselect: function (deselectedCurrencies) {
      $scope.preferredCurrencyList = globalFunc.removeValueFromArray(
        $scope.preferredCurrencyList, '_id', deselectedCurrencies._id
      );

      if (!!$scope.selectedPreferredCurrency && !!$scope.selectedPreferredCurrency._id) {
        if ($scope.selectedPreferredCurrency._id === deselectedCurrencies._id) {
          $scope.selectedPreferredCurrency = {};
        }
      }
    },
    onDeselectAll: function () {
      $scope.preferredCurrencyList = [];
      $scope.selectedPreferredCurrency = {};
    }
  };

  //$scope.selectPreferredCurrencies = {
  //  onItemSelect: function(selectedCurrencies){
  //    var selectedCurrency = globalFunc.findInArray($scope.currencies, 'code', selectedCurrencies.id);
  //    $scope.selectedPreferredCurrency = selectedCurrency;
  //  }
  //};

  $scope.setActiveTab = setActiveTab;
  $scope.back = back;
  $scope.onSaveCompanyDetails = onSaveCompanyDetails;
  $scope.saveCorrespondingAddressChanges = saveCorrespondingAddressChanges;
  $scope.addCostCenterModal = addCostCenterModal;
  $scope.costCenterDetails = costCenterDetails;
  $scope.viewCurrencies = viewCurrencies;
  $scope.loadParentCompany = loadParentCompany;
  $scope.loadBUs = loadBUs;
  $scope.loadIndustry = loadIndustry;
  $scope.loadApproverOptions = loadApproverOptions;
  $scope.saveBu = saveBu;
  $scope.isUploadImageSuccess = isUploadImageSuccess;
  $scope.importCostCentersModal = importCostCentersModal;
  $scope.onSelectedState = onSelectedState;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.getPrintLogoSetting = getPrintLogoSetting;
  $scope.duplicationCheck = duplicationCheck;
  $scope.budgetSettingChanges = budgetSettingChanges;
  $scope.budgetSettingToggle = budgetSettingToggle;
  $scope.saveBudgetSetting = saveBudgetSetting;
  $scope.exportCostCenterAddresses = exportCostCenterAddresses;
  $scope.isCostCenterMode = isCostCenterMode;

  /**
   * Generate CSV for address of all cost centers
   */
  function exportCostCenterAddresses() {
    companyCostCentersAddress.get({
        companyId: $scope.companyDetails._id
      },
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
            id: resource.content.data
          });
          window.open(newUrl, '_self');
        } else {
          globalFunc.objectErrorMessage('An error occurred.');
        }
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )

  }

  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id) {
      HighlightId.setId($scope.companyDetails._id);
    }
    window.history.back();
  }

  // Set Manage tab active
  function setActiveTab() {
    $scope.tabData.active = _.find($scope.tabData, function (tab) {
      if (!!tab.menu && tab.menu.length) {
        return _.find(tab.menu, function (item) {
          return item.params.action === "companies";
        });
      }
      else {
        return tab.params.action === "companies";
      }
    });

    if (!!$scope.tabData) {
      $scope.tabData.active.active = true;
    }
  }

  function onSelectedCountry(self,country){
    $scope.companyDetails.corresponding_address.state = undefined;
    if(!!self.$data) {
      $scope.companyDetails.corresponding_address.country = self.$data;
      if (self.$data === "Malaysia") {
        $scope.selectedMalaysia = true;
      }
      else {
        $scope.selectedMalaysia = false;
      }
    }
  }

  function onSelectedState(self,state){
    if(!!state) {
      $scope.companyDetails.corresponding_address.state = self.$data;
    }
  }

  function saveCorrespondingAddressChanges() {
    if(validate()) {
      var updateData = {
        line1: $scope.companyDetails.corresponding_address.line1,
        line2: $scope.companyDetails.corresponding_address.line2,
        line3: $scope.companyDetails.corresponding_address.line3,
        postal_code: $scope.companyDetails.corresponding_address.postal_code,
        city: $scope.companyDetails.corresponding_address.city,
        state: $scope.companyDetails.corresponding_address.state,
        country: $scope.companyDetails.corresponding_address.country,
        phone: $scope.companyDetails.phone
      };

      if (!_.isEqual(updateData, backupCorrespondingAddress)) {
        singleAddress.put(
          {
            id: $scope.companyDetails.corresponding_address._id
          },
          updateData,
          function () {
            onSaveCompanyDetails($scope.companyDetails);
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
          }
        )
      } else {
        onSaveCompanyDetails($scope.companyDetails);
      }
    }
  }

  function prepareCompanyChangedData(companyData) {
    /**
     * check if the GST was update and if its available
     */
    var companyDataChanges = {};
    var companyCurrency = [];
    var preferredCurrency = '';
    if (!!$scope.companyCurrencies && !!$scope.selectedPreferredCurrency) {
      companyCurrency = reformatCurrenciesSelected();
      preferredCurrency = $scope.selectedPreferredCurrency.code;
    }
    if ($scope.companyDetails.gst_code !== $scope.tempGST) {
      companyDataChanges.gst_code = $scope.companyDetails.gst_code;
    }
    companyDataChanges.descr = companyData.descr;
    companyDataChanges.print_logo = companyData.print_logo.value;
    companyDataChanges.parent_company_id = (!!companyData.parent_company && !!companyData.parent_company.length > 0) ? companyData.parent_company[0]._id : null;
    companyDataChanges.currencies = companyCurrency;
    companyDataChanges.currency_code = preferredCurrency;
    companyDataChanges.fax = companyData.fax;
    companyDataChanges.email = companyData.email;
    companyDataChanges.phone = companyData.phone;
    companyDataChanges.corresponding_address_id = $scope.companyDetails.corresponding_address._id;
    companyDataChanges.PO_initial_status = companyData.PO_initial_status.value;
    companyDataChanges.expense_types = globalFunc.getArrayOfKeyValues(companyData.expense_types, '_id');
    companyDataChanges.allowedToHoldPR = $scope.companyDetails.config.allowedToHoldPR;
    companyDataChanges.billingCompanies = $scope.companyDetails.billingCompanies;
    companyDataChanges.defaultBillingCompany = $scope.companyDetails.defaultBillingCompany;
    companyDataChanges.budget_setting = $scope.companyDetails.budget_setting;

    return companyDataChanges;
  }

  function onSaveCompanyDetails(companyData) {
    var companyDataChanges = prepareCompanyChangedData(companyData);
    Companies.put(
      {
        id: companyData._id
      }, companyDataChanges,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.message) {
          toastr.success(resource.content.message);
          //todo: update addresses correctly without using state reload
          $state.reload();
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $state.reload();
      }
    );
  }

  /**
   * Navigate to add cost center page
   */
  function addCostCenterModal() {
    $state.go('main.user.companiesMgmt.detailCompanies.addCostCenter');
  }

  /**
   * To check if currently is in cost center mode
   */
  function isCostCenterMode() {
    return $state.includes('main.user.companiesMgmt.detailCompanies.addCostCenter') ||
      $state.includes('main.user.companiesMgmt.detailCompanies.updateCostCenter');
  }

  function costCenterDetails(costCenterId) {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/companiesManagement/costcenters/details/details.html',
      controller: 'costCenterDetailCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        costCenterDetails: function ($q, CostCenters) {
          var deferred = $q.defer();
          CostCenters.get(
            {
              id: costCenterId
            },
            function (resource) {
              deferred.resolve(resource.content.data || []);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        companyDetails: function(){
          return $scope.companyDetails;
        }
      }
    });
    modalInstance.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.companyDetails.costCenters, function (dataList) {
          if (dataList._id === newValue._id) {
            dataList.descr = newValue.descr;
            dataList.corresponding_address_id = newValue.delivery_address_id;
          }
        });
      }
    }, function () {
    });
  }

  function viewCurrencies(companyCurrencies, company){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/details/viewCurrencies-modalTemplate.html',
      controller: 'viewCurrenciesCtrl',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        viewCurrencies: function (){
          return companyCurrencies;
        },
        company: function (){
          return company.descr;
        }
      }
    });
  }

  function saveBu(dataChanges) {
    var newData = prepareCompanyChangedData(dataChanges);
    newData.bu_code = dataChanges.business_unit.code;
    newData.industry_code = dataChanges.industry.code;

    Companies.put(
      {
        id: dataChanges._id
      }, newData,
      function (resource) {
        toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
          ? resource.content.message : 'Successfully updated' );
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  /**
   * To send request to backend and save budget setting
   */
  function saveBudgetSetting(dataChanges) {
    var newData = prepareCompanyChangedData(dataChanges);
    newData.budget_setting = {
      'enable': $scope.budgetModuleToggle,
      'allow_pr_overbudget': $scope.budgetExceedToggle,
      'threshold_notification': {
        'enable': $scope.budgetNotificationToggle,
        'threshold': $scope.budgetThreshold.normal.low
      }
    };

    Companies.put(
      {
        id: dataChanges._id
      }, newData,
      function (resource) {
        toastr.success((!!resource.content && !!resource.content.message && _.isString(resource.content.message))
          ? resource.content.message : 'Successfully updated');
        $scope.companyDetails.budget_setting.enable = $scope.budgetModuleToggle;
        $scope.companyDetails.budget_setting.allow_pr_overbudget = $scope.budgetExceedToggle;
        $scope.companyDetails.budget_setting.threshold_notification.enable = $scope.budgetNotificationToggle;
        $scope.companyDetails.budget_setting.threshold_notification.threshold = $scope.budgetThreshold.normal.low;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function getPrintLogoSetting(printLogoValue){
     switch(printLogoValue){
       case 0:
         return 'No logo';
         break;
       case 1:
         return 'Tenant Logo';
         break;
       case 2:
         return 'Company Logo';
         break;
     }
  }

  function loadParentCompany() {
    if (!!$scope.companyDetails.parent_company) {
      return $scope.parentCompany = companies.data;
    }
    else {
      return '-';
    }
  }

  function loadBUs() {
    var returnText = "Not set";

    if (!!$scope.companyDetails.business_unit && !!$scope.companyDetails.business_unit.code) {
      var selected = globalFunc.findInArray(BUs.data, 'code', $scope.companyDetails.business_unit.code);

      if ($scope.companyDetails.business_unit.code && !!selected)
        returnText = selected.code + " (" + selected.descr + ")";
    }
    return returnText;
  }

  function loadIndustry() {
    var returnText = "Not set";
    if (!!$scope.companyDetails.industry && !!$scope.companyDetails.industry.code) {
      var selected = globalFunc.findInArray(industryList.data, 'code', $scope.companyDetails.industry.code);

      if ($scope.companyDetails.industry.code && !!selected)
        returnText = selected.code + " (" + selected.descr + ")";
    }
    return returnText;
  }

  function loadApproverOptions() {
    var returnText = 'No';
    if(!!$scope.companyDetails.config.allowedToHoldPR) {
      returnText = 'Yes';
    }
    return returnText;
  }




  function isUploadImageSuccess(file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  }

  function initialize() {
    budgetSettingToggle('reset');
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.printLogoOptions = [
      {
        descr: 'No Logo',
        value: 0
      },
      {
        descr: 'Tenant Logo',
        value: 1
      },
      {
        descr: 'Company Logo',
        value: 2
      }
    ];

    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();

    $scope.companyDetails.currency_code = globalFunc.findInArray($scope.companyCurrencies, 'code', $scope.companyDetails.currency_code);

    // storing the initial GST to use it for duplication check later on.
    $scope.tempGST = null;
    if(!!$scope.companyDetails.gst_code){
      $scope.tempGST = $scope.companyDetails.gst_code;
    }

    $scope.selectedMalaysia = ($scope.companyDetails.corresponding_address && $scope.companyDetails.corresponding_address.country === 'Malaysia');

    $scope.companyDetails.PO_initial_status = globalFunc.findInArray(
      $scope.POInitialStatuses, 'value', $scope.companyDetails.PO_initial_status
    );

    $scope.imageBasePath = pathConstants.apiUrls.image.user;
    setActiveTab();
//    companyCurrenciesData();
    preferredCurrencyInitValue();
    loadApproverOptions();

    //set params to send company id for export
    var params = {
      module: 'cost-centers',
      offset: 0,
      'criteria[company_code]': $scope.companyDetails.code
    };
    exportSearch.setParams(params);
  }

  /**
   * initial value for preferred currency
   */
  function preferredCurrencyInitValue(){
    $scope.preferredCurrencyList = $scope.companyCurrencies;
    $scope.selectedPreferredCurrency = globalFunc.findInArray(
      $scope.preferredCurrencyList, 'code', $scope.companyDetails.currency_code.code
    );
  }

  /**
   * Reformat Currencies Selected
   * reformat after the ng-dropdown-multiselect
   * to follow the endpoint format
   */
  function reformatCurrenciesSelected(){
    var companyCurrency = [];
    _.forEach($scope.companyCurrencies, function(currency){
      companyCurrency.push(currency.code);
    });

    return companyCurrency;
  }

  /**
   * To import Cost Centers by uploading csv file
   */
  function importCostCentersModal() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return "Cost Centers";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'rocCheckTrue':
          $scope.rocCheck = true;
          break;
        case 'gstCheckTrue':
          $scope.gstCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
        case 'rocCheckFalse':
          $scope.rocCheck = false;
          break;
        case 'gstCheckFalse':
          $scope.gstCheck = false;
          break;
      }
    });
  }

  /**
   * To check if budget setting is changed by the user
   */
  function budgetSettingChanges() {
    return ($scope.budgetModuleToggle !== $scope.companyDetails.budget_setting.enable) ||
    ($scope.budgetExceedToggle !== $scope.companyDetails.budget_setting.allow_pr_overbudget) ||
    ($scope.budgetNotificationToggle !== $scope.companyDetails.budget_setting.threshold_notification.enable) ||
    ($scope.budgetThreshold.normal.low !== ($scope.companyDetails.budget_setting.threshold_notification.threshold || 0)) ?
      true : false;
  }

  /**
   * Events to trigger when budget setting update by user
   * module : if budget module is set to off, sub setting will be set to null
   * notification : if budget notification is turn off, threshold will be set to null
   * reset : reset the initial setting of the budget setting
   */
  function budgetSettingToggle(type) {
    if ($scope.budgetModuleToggle === false && type === 'module') {
      $scope.budgetExceedToggle = false;
      $scope.budgetNotificationToggle = false;
      $scope.budgetThreshold.normal.low = null;
    }
    if (type === 'notification') {
      if ($scope.budgetNotificationToggle === false) {
        $scope.budgetThreshold.normal.low = null;
      } else {
        $scope.budgetThreshold.normal.low =
          (!!$scope.companyDetails.budget_setting &&
          !!$scope.companyDetails.budget_setting.threshold_notification &&
          !!$scope.companyDetails.budget_setting.threshold_notification.threshold) ?
            !!$scope.companyDetails.budget_setting.threshold_notification.threshold : 80; // initialize value to 80
      }
    }
    if (type === 'reset') {
      $scope.budgetModuleToggle = $scope.companyDetails.budget_setting.enable;
      $scope.budgetExceedToggle = $scope.companyDetails.budget_setting.allow_pr_overbudget;
      $scope.budgetNotificationToggle = $scope.companyDetails.budget_setting.threshold_notification.enable;
      $scope.budgetThreshold.normal.low = $scope.companyDetails.budget_setting.threshold_notification.threshold;
    }
  }

  /**
   * validate the entries of the company
   * @returns {boolean}
   */
  function validate() {
    /**
     * validate the gst code
     */
    if ($scope.gstCheck === true) {
      $scope.companyDetails.gst_code = $scope.tempGST;
      toastr.error('The GST number is registered to another company');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address){
      toastr.error('The Address is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.line1){
      toastr.error('The Address First line field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.postal_code){
      toastr.error('The Address Postal code field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.city){
      toastr.error('The Address City field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.state){
      toastr.error('The Address State field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.country){
      toastr.error('The Address Country field is required');
      return false;
    }
    if(!$scope.companyDetails.phone){
      toastr.error('The phone field is required');
      return false;
    }
    if(!$scope.companyDetails.corresponding_address.state){
      toastr.error('The Address State field is required');
      return false;
    }
    if (!$scope.companyCurrencies ||
      (!!$scope.companyCurrencies && !$scope.companyCurrencies.length)
    ){
      toastr.error('The Company Currency is required');
      return false;
    }
    if (!$scope.selectedPreferredCurrency ||
      (!!$scope.selectedPreferredCurrency && !$scope.selectedPreferredCurrency.code)
    ){
      toastr.error('The Preferred Currency is required');
      return false;
    }
    if(!$scope.companyDetails.expense_types || !$scope.companyDetails.expense_types.length){
      toastr.error('The expense type is required');
      return false;
    }
    return true;
  }

  initialize();

}
companyDetailCtrl.$inject = [
  '$scope', '$state', 'companyDetails', 'Companies', '$uibModal', 'toastr', 'currenciesList', 'BUs', 'industryList',
  'metabuyerCache', 'singleAddress', '$filter', 'pathConstants', 'companiesDetailImage', '$auth', 'globalFunc',
  'companyCurrencies', 'companies', '$rootScope', 'companyGlobalFunc', 'expenseTypesList', 'exportSearch', 'companyCostCentersAddress',
  'companyBudgetSetting', 'HighlightId'
];

angular
  .module('metabuyer')
  .controller('companyDetailCtrl', companyDetailCtrl);
