'use strict';

angular.module('metabuyer').directive('poAttachmentsBlock', function () {
  return {
    restrict: 'E',
    templateUrl: 'components/poAttachment/poattachmentTemplate.html',
    scope: {
      disableAttaching : '=?'
    },
    controller: function (
      $scope, Attachments, AttachmentSingle, $uibModal, $stateParams, $auth, toastr, pathConstants, $filter, $http
    ) {

      $scope.showDropZone = false;

      Attachments.getListPOAttachment(
        {
          po_id: $stateParams.id
        },
        function (resource) {
          if(!!resource && !!resource.content && !!resource.content.data){
            if(_.isArray(resource.content.data)){
              $scope.files = resource.content.data.reverse();
            }
          }

          if($scope.files.length == 0 && !$scope.disableAttaching){
            $scope.showDropZone = true;
          }
        });

      $scope.download = function (file_id) {

        AttachmentSingle.getSinglePOAttachment({
          po_id: $stateParams.id,
          attachment_id: file_id
        }).$promise.then(
          function (resource) {
            var newUrl = $filter("format")(pathConstants.apiUrls.po.downloadAttachment, {
              hash: resource.url
            });

            // Use HTTP to allow and use the application/json
            // and download with the newUrl
            $http({
              url: newUrl
            }).then(function (response) {
              window.open(newUrl, "_self");
            }, function (response) {
            });
          }, function (error) {
            toastr.error("Failed to download attachment.");
          });
      };

      $scope.dropzoneConfig = {
        options: {
          // passed into the Dropzone constructor
          //maxFilesize in MB
          maxFilesize: 10,
          url: $filter('format')(pathConstants.apiUrls.po.listAttachment, {po_id: $stateParams.id}) + "?descr=ABC",
          headers: {
            'Authorization': 'Bearer ' + $auth.getToken()
          },
          previewTemplate: '<div class="dz-preview dz-file-preview">' +
          '<div class="dz-details">' +
          '<div class="dz-filename"><span data-dz-name></span></div>' +
          '<div class="dz-size" data-dz-size></div>' +
          '<img data-dz-thumbnail />' +
          '</div>' +
          '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
          '<div style="z-index: 999; position: absolute; width: 30px; height: 30px; font-size: 25px; text-align: center; ' +
          'right: -10px; top: -10px;"><a data-dz-remove type="button" class="btn-xs btn-white pull-right"><i class="fa fa-trash-o"></i></a>' +
          '</div>' +
          '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
          '</div>',
          acceptedFiles: '.xls, .doc, .ppt, .txt, .pdf, .jpg, .png, .gif, .bmp, .xlsx, .docx, .pptx, .jpeg'
        },

        eventHandlers: {

          processing: function(file) {
            this.options.url = $filter('format')(pathConstants.apiUrls.po.listAttachment, {po_id: $stateParams.id}) + "?descr=Add title";
          },

          sending: function (file, formData, xhr) {

          },
          error: function (file, error) {
            toastr.error(error);
          },
          success: function (file, response) {
            var _this = this;
            setTimeout(function () {
              _this.removeFile(file);
            }, 1000);

            toastr.success("Upload attachment successfully.");

            if(!!response && !!response.content && !!response.content.data){
              $scope.files.splice(0, 0, response.content.data);
            }
          }
        }
      };

      $scope.update = function (file) {

        if(file.edit_file_name == null || file.edit_file_name == undefined){
          file.edit_file_name = file.descr;
          return;
        }

        if(file.edit_file_name == file.descr){
          return;
        }

        AttachmentSingle.updateSinglePOAttachment(
          {
            po_id: $stateParams.id,
            attachment_id: file._id
          },
          {
            descr: file.edit_file_name
          }).$promise.then(
          function () {
            file.descr = file.edit_file_name;
          }, function (error) {
            toastr.error("Failed to update attachment.");
            file.edit_file_name = file.descr;
          });
      };
      $scope.delete = function (file) {
        var file_index = _.findIndex($scope.files, function(file_item){
          return file._id == file_item._id;
        });

        $scope.files.splice(file_index, 1);

        AttachmentSingle.deleteSinglePOAttachment(
          {
            po_id: $stateParams.id,
            attachment_id: file._id
          }).$promise.then(
          function() {
            if($scope.files.length == 0){
              $scope.showDropZone = true;
            }
          }, function (error) {
            toastr.error("Failed to delete attachment.");
            $scope.files.splice(file_index, 0, file);
          });
      };
    }
  };
});
