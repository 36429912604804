/**
 * @name usersDelegationCtrl
 *
 * @description
 * List all the delegations and redirected to user details page
 * when clicked on the list
 *
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';

function usersDelegationCtrl($scope, delegationList, delegations, HighlightId) {
  $scope.showColumn = showColumn;
  $scope.getDelegationStatus = getDelegationStatus;

  $scope.storageKey = 'delegation-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getDelegationStatus(status) {
    switch(status) {
      case 0:
        return 'Planned';
      case 1:
        return 'Active';
      case 2:
        return 'Cancelled';
      case 3:
        return 'Finished';
    }
  }

  function initialize(){
    $scope.dataList = delegationList.data;
    $scope.meta = delegationList.meta;
    $scope.delegationsService = delegations;
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();

}

usersDelegationCtrl.$inject = ['$scope', 'delegationList', 'delegations', 'HighlightId'];

angular
  .module('metabuyer')
  .controller('usersDelegationCtrl', usersDelegationCtrl);
