'use strict';

angular.module('metabuyer.services.users', ['ngResource'])
  .factory('Users', function($resource, $filter, pathConstants){
    //TODO: refactor and simplify this part as well as update the function that uses this resource (Ahmed Saleh)
    var Users = $resource(
      pathConstants.apiUrls.users.list,
      {
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'}
      }
    );
    return Users;
  })
  .factory('searchUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.search,
      {
        query: '@text'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('singleUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'},
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('passwordUpdate',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.updatingPassword,
      {
      },
      {
        'put': { method: 'PUT' }
      }
    );
  })
  .factory('forgottenPassword',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.forgottenPassword,
      {
      },
      {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('activeUsers', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.users.activeUsers,
      {

      },
      {
        'get': {method: 'GET'},
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('adminUnlockUser', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.adminUnlockUser,
      {
        id: '@id'
      },
      {
        'put': { method: 'PUT' }
      }
    );
  })
  .factory('userImage', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.image,
      {
        id: '@id'
      },
      {
        'get': { method: 'get' }
      }
    );
  })
  .factory('uploadImage', function($resource, $filter, pathConstants) {
    return{
      uploadImagePath: function (userID) {
        var path = pathConstants.apiUrls.users.image;
        return path.replace(":id", userID);
      }
    }
  })
  .factory('exportUserAssignments', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.exportAssignments,
      {},
      {
        'get': { method: 'get' }
      }
    );
  })
  .factory('userBudget', function ($resource, $filter, pathConstants) {
    var budgetUsers = $resource(
      pathConstants.apiUrls.users.budgets,
      {},
      {
        'get': {method: 'GET'}
      }
    );
    return budgetUsers;
  })
  .factory('userPendingApproval', function ($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.pendingApproval,
      {
        userId: '@userId',
        companyCode: '@companyCode'
      },
      {
        'get': {method: 'GET'}
      }
    );
  });
