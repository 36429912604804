'use strict';

function supplierTagsBySupplierCtrl($scope, suppliersList, $stateParams, $state, detailState, HighlightId)
{

  $scope.showColumn = showColumn;
  $scope.supplierTagDetails = supplierTagDetails;
  $scope.getStatusName = getStatusName;

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function supplierTagDetails (supplier) {
    $state.go(detailState,
      {
        supplierCode: supplier.mb_supplier_code,
        supplierName: supplier.descr
      });
  }

  function getStatusName(status){
    switch (status) {
      case 1:
        return 'Active';
        break;
      case 2:
        return 'Black Listed';
        break;
      case 6:
        return 'Inactive';
        break;
      default:
        return 'N/A';
    }
  }

  function initialize () {
    $scope.dataList = suppliersList.data;
    $scope.storageKey = 'supplier-tags-by-supplier-selected-columns';
    $scope.meta = suppliersList.meta;
    $scope.suppliersService = suppliersList;
    $scope.status = $stateParams.status;
    $scope.highlightId = HighlightId.getId();
    if (!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();
}

supplierTagsBySupplierCtrl.$inject = [
  '$scope', 'suppliersList', '$stateParams', '$state', 'detailState', 'HighlightId'
];

angular.module('metabuyer')
  .controller('supplierTagsBySupplierCtrl', supplierTagsBySupplierCtrl);
