'use strict';
/**
 * @name CompaniesCtrl
 * @desc Controller for companies
 */
function CompaniesCtrl($scope, $state, globalFunc) {
  $scope.$emit('pageTitleChanged', 'Manage Companies');
  var tempState = _.cloneDeep($state.params);


  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function (event, data, toParams) {
    if (!!data.name && data.name.indexOf('BusinessUnits') > -1) {
      $scope.columns = BUColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.searchPlaceHolder = 'Search Business Units';
      $scope.parentState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Industries') > -1) {
      $scope.columns = industryColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageIndustries';
      $scope.searchPlaceHolder = 'Search Industries';
      $scope.parentState = 'main.user.companiesMgmt.manageIndustries';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Users') > -1) {
      $scope.columns = companyUsersColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesUsers';
      $scope.searchPlaceHolder = 'Search Company Users';
      $scope.parentState = 'main.user.companiesMgmt.companiesUsers';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Companies') > -1) {
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.companiesMgmt.searchCompanies';
      $scope.searchPlaceHolder = 'Search Companies';
      $scope.parentState = 'main.user.companiesMgmt.manageCompanies';
      $scope.parentStateParams = $state.params;
    }
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }
  });


  $scope.tabData = [
    {
      heading: 'Companies',
      route: 'main.user.companiesMgmt.manageCompanies',
      key: 'companies',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'companies',
        action: 'companies',
        cursor: null
      })
    },
    {
      heading: 'Business Units',
      route: 'main.user.companiesMgmt.manageBusinessUnits',
      key: 'businessunit',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'businessunit',
        action: 'businessunit',
        cursor: null
      })
    },
    {
      heading: 'Industries',
      route: 'main.user.companiesMgmt.manageIndustries',
      key: 'industries',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'industries',
        action: 'industries',
        cursor: null
      })
    },
    {
      heading: 'Add',
      key: 'add',
      menu: [
        {
          heading: 'Add Company',
          route: 'main.user.companiesMgmt.addCompany',
          key: 'add'
        },
        {
          heading: 'Add Business Unit',
          route: 'main.user.companiesMgmt.addBU',
          key: 'add'
        },
        {
          heading: 'Add Industries',
          route: 'main.user.companiesMgmt.addIndustry',
          key: 'add'
        }
      ]
    }
  ];

  function initialize() {

    if (!!$state.current.name && $state.current.name.indexOf('BusinessUnits') > -1) {
      $scope.columns = BUColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.searchPlaceHolder = 'Search Business Units';
      $scope.parentState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    } else if (!!$state.current.name && $state.current.name.indexOf('Industries') > -1) {
      $scope.columns = industryColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageIndustries';
      $scope.searchPlaceHolder = 'Search Industries';
      $scope.parentState = 'main.user.companiesMgmt.manageIndustries';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('companiesUsers') > -1) {
      $scope.columns = companyUsersColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesUsers';
      $scope.searchPlaceHolder = 'Search Company Users';
      $scope.parentState = 'main.user.companiesMgmt.companiesUsers';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('add') > -1) {
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.companiesMgmt.searchCompanies';
      $scope.searchPlaceHolder = 'Search Companies';
      $scope.parentState = 'main.user.companiesMgmt.manageCompanies';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('Companies') > -1){
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.companiesMgmt.searchCompanies';
      $scope.searchPlaceHolder = 'Search Companies';
      $scope.parentState = 'main.user.companiesMgmt.manageCompanies';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }

    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }
    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

  }

  var companyColumns = [
    {id: 'descr', label: 'Company'},
    {id: 'code', label: 'Code'},
    {id: 'reg_no', label: 'Registration Code'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'business_unit.descr', label: 'Business Unit'},
    {id: 'industry.descr', label: 'Industry'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  var BUColumns = [
    {id: 'descr', label: 'Business Unit'},
    {id: 'code', label: 'Code'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'is_active',
      label: 'Status',
      unsearchable: true
    }
  ];

  var industryColumns = [
    {id: 'descr', label: 'Industry'},
    {id: 'code', label: 'Industry Code'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  var companyUsersColumns = [
    {id: 'display_name', label: 'Full Name'},
    {id: 'email', label: 'Email'},
    {id: 'emp_no', label: 'Employee Number'},
    {id: 'role', label: 'Role(s)', unsortable: true},
    {id: 'manager', label: 'Superior', unsortable: true},
    {id: 'position', label: 'Position', unsortable: true},
    {id: 'cc_list', label: 'Cost Center(s)', unsortable: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'status', label: 'Status'}
  ];


  initialize();

}
CompaniesCtrl.$inject = ['$scope', '$state', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('CompaniesCtrl', CompaniesCtrl);
