'use strict';
/**
 * @name approvalFlowCtrl
 * @desc Controller for Approval Flow Management Parent Controller
 */
function mainPanelSettingsCtrl ($scope) {
  $scope.settings = [
    {
      title: 'Company Settings',
      key: 'main.user.settings.PRPOSettings.prefix'
    },
    {
      title: 'Admin Settings',
      key: 'main.user.settings.adminSettings'
    },
    {
      title: 'Tenant Settings',
      key: 'main.user.settings.tenant'
    }
  ];

  //TODO: have this cleaned up with better engineering solution (Ahmed Saleh)
}
mainPanelSettingsCtrl.$inject = ['$scope'];

angular
  .module('metabuyer')
  .controller('mainPanelSettingsCtrl', mainPanelSettingsCtrl);
