'use strict';

angular
  .module('metabuyer')
  .directive('treeSelection', function($timeout, $rootScope) {
    return {
      restrict: 'EA',
      scope: {
        model: '=ngModel',
        data: '=data',
        title: '=title',
        selectable: '=selectable',
        level: '=level',
        action: '=action',
        closeTreeSearch: '=closeTreeSearch'
      },
      replace: true,
      templateUrl: 'components/tree-selection/tree-selection.template.html',
      link: function($scope, $element, $attrs) {
        $scope.commodityCodeList = [];
        $scope.ccSelected = {};
        $scope.openingChild = false;
        $scope.openingParent = false;
        $scope.currentParrent = {};

        $scope.selectCC = selectCC;
        $scope.checkLevel = checkLevel;
        $scope.openChildCC = openChildCC;
        $scope.openParent = openParent;
        $scope.randomDate = Date.now();

        /**
         * Open and get child
         * @param cc
         */
        function openChildCC(cc){
          $scope.openingChild = true;
          $scope.openingParent = false;
          $scope.currentParrent = angular.copy(cc);
          $scope.action(cc.unique_name, cc.index);
        }

        function openParent(cc){
          $scope.openingChild = false;
          $scope.openingParent = true;
          $scope.action(cc.parent_unique_name, cc.index);
        }

        //TODO: have a deselect OR reset for the Radio button is the selection could be removed from a parent scope, that if the Radio is still the choice (Ahmed Saleh, 28/4/2016)
        /**
         * To change and send CC data to the parent
         * @param selectedCC
         */
        function selectCC(selectedCC){
          if(!!selectedCC){
            $scope.model = selectedCC;
            $scope.title = selectedCC.code + ' ' + selectedCC.descr;
            $scope.closeTreeSearch= true;
          }
        }

        function checkLevel(ccLevel){
          if($scope.level.indexOf(ccLevel) > -1) return true;
          return false;
        }

        $scope.$watch('data', function(newData){
          $scope.commodityCodeList = newData;
        });
      }
    }
  });
