'use strict';

function suppliersCtrl($scope, UserPermissions, profile, $state) {
  $scope.tabData = [
    {
      heading: 'Manage Suppliers',
      route: 'main.user.suppliers.manage',
      key: 'suppliers',
      id: 'manageSuppliersTab',
      menu: [
        {
          heading: 'All',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'allSuppliersTab',
          params: {
            status: 'all',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Draft',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'draftSuppliersTab',
          params: {
            status: 'draft',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Active',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'activeSuppliersTab',
          params: {
            status: 'active',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Pending Approval',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'pendingSuppliersTab',
          params: {
            status: 'pending',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Blacklisted',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'blacklistedSuppliersTab',
          params: {
            status: 'black_listed',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Rejected',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'rejectedSuppliersTab',
          params: {
            status: 'rejected',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Withdrawn',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'withdrawnSuppliersTab',
          params: {
            status: 'withdrawn',
            cursor: null,
            order_by: 'descr',
            order: 1,
            query: null,
            filter: null
          }
        },
        {
          heading: 'Inactive',
          route: 'main.user.suppliers.manage',
          key: 'suppliers',
          id: 'inactiveSuppliersTab',
          params: {
            status: 'inactive',
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        }
      ]
    },
    {
      heading: 'Suppliers Group',
      route:  'main.user.suppliers.supplierGroups',
      key: 'supplierGroups',
      id: 'supplierGroupsTab',
      menu: [
        {
          heading: 'Global Suppliers',
          route:  'main.user.suppliers.supplierGroupDetail',
          key: 'supplierGroups',
          id: 'globalSuppliersTab',
          params:{
            bu_code: 'Global',
            status: 'all',
            order_by: 'descr',
            order: 1,
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Supplier Business Units',
          route:  'main.user.suppliers.supplierGroups',
          key: 'supplierGroups',
          id: 'supplierBusinessUnitsTab',
          params:{
            order_by: 'code',
            order: 1,
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Companies Tagged to Supplier',
          route: 'main.user.suppliers.tagsBySupplier',
          key: 'supplierGroups',
          params: {
            cursor: null,
            order_by: 'descr',
            order: 1,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Suppliers Tagged to Company',
          route: 'main.user.suppliers.tagsByCompany',
          key: 'supplierGroups',
          params: {
            order_by: 'descr',
            order: 1,
            cursor: null,
            filter: null,
            query: null
          }
        }
      ]
    }
  ];

  /**
   * Tab for creating suppliers, check user permissions
   */
  if (!!UserPermissions.checkSupplierAccess(profile, 'C')) {
    var addSupplierTab = {
      heading: 'Add Suppliers',
      route: 'main.user.suppliers.newSupplier',
      key: 'createSupplier',
      id: 'addSupplierTab',
      menu: [
        {
          heading: 'Create Single Supplier',
          route:  'main.user.suppliers.newSupplier',
          key: 'createSupplier',
          id: 'createSupplierTab',
          params:{
            status: 'addNew',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        },
        {
          heading: 'Upload Suppliers',
          route:  'main.user.suppliers.import',
          key: 'createSupplier',
          id: 'importSupplierTab',
          params:{
            status: 'import',
            cursor: null,
            filter: null,
            query: null,
            type: null
          }
        }
      ]
    };
    $scope.tabData.push(addSupplierTab);
  }

  /**
   * Tab for supplier tagging, checks the permissions of the user
   */
  if (!!UserPermissions.checkPermissionsAccess(profile, 'C', 'SupplierTag') &&
    !!UserPermissions.checkPermissionsAccess(profile, 'U', 'SupplierTag')) {
    var supplierTagTab = {
      heading: 'Supplier Tagging',
      route: 'main.user.suppliers.supplierTagging',
      key: 'supplierTagging',
      id: 'supplierTaggingTab',
      params: {
        status: 'tag',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    };
    $scope.tabData.push(supplierTagTab);
  }

  function initialize(){

    if(!!$state.current.name && $state.current.name.indexOf('supplierGroups') > -1){
      $scope.columns = supplierGroupColumns;
      $scope.searchPlaceholder = 'Search Supplier Business Units';
      $scope.searchState = 'main.user.suppliers.supplierGroups';
      $scope.parentState = 'main.user.suppliers.supplierGroups';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('supplierGroupDetail') > -1){
      $scope.columns = supplierByGroupColumns;
      $scope.searchPlaceholder = 'Search Suppliers in Business Unit';
      $scope.searchState = 'main.user.suppliers.supplierGroupDetail';
      $scope.parentState = 'main.user.suppliers.supplierGroupDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('tagsBySupplier')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.tagsBySupplier';
      $scope.parentState = 'main.user.suppliers.tagsBySupplier';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagsBySupplierDetail')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies Tagged to Supplier';
      $scope.searchState = 'main.user.suppliers.supplierTagsBySupplierDetail';
      $scope.parentState = 'main.user.suppliers.supplierTagsBySupplierDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('tagsByCompany')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies';
      $scope.searchState = 'main.user.suppliers.tagsByCompany';
      $scope.parentState = 'main.user.suppliers.tagsByCompany';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagsByCompanyDetail')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers Tagged to Company';
      $scope.searchState = 'main.user.suppliers.supplierTagsByCompanyDetail';
      $scope.parentState = 'main.user.suppliers.supplierTagsByCompanyDetail';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('supplierTagging') > -1){
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.manage';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('import') > -1){
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.manage';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('newSupplier') > -1){
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.manage';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else{
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.manage';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }

    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status
    }
    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }
  }

  var supplierColumns = [
    {id: 'check box', title: '', unsortable: true, unsearchable: true, conditional: true, condition: ['pending'], unSelectable: true},
    {id: 'descr', label: 'Supplier Name'},
    {id: 'mb_supplier_code', label: 'Supplier Code'},
    {id: 'reg_no', label: 'ROC/ROB/NRIC'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'email', label: 'Email'},
    {id: 'contact_person.first_name', label:'Contact person\'s name'},
    {id: 'contact_person.email', label: 'Contact person\'s email'},
    {id: 'contact_person.phone', label: 'Contact person\'s phone'},
    {id: 'primary_business_nature', label: 'Primary Business Nature'},
    {id: 'primary_commodity.descr', label: 'Primary Commodity'},
    {id: 'secondary_commodity.descr', label: 'Secondary Commodity'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'blacklisted_by', label: 'Blacklisted By', conditional: true, condition: 'black_listed'},
    {id: 'blacklisted_reason', label: 'Blacklisting Reason', conditional: true, condition: 'black_listed'},
    {id: 'submittedFrom', label: 'Submitted From', conditional: true, condition: 'black_listed'},
    {id: 'status', label: 'Status', conditional: true, condition: 'all'}
  ];

  var supplierByGroupColumns = [
    {id: 'descr', label: 'Supplier Name'},
    {id: 'mb_supplier_code', label: 'Supplier Code'},
    {id: 'reg_no', label: 'ROC/ROB/NRIC'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'email', label: 'Email'},
    {id: 'contact_person.first_name', label:'Contact person\'s name'},
    {id: 'contact_person.email', label: 'Contact person\'s email'},
    {id: 'contact_person.phone', label: 'Contact person\'s phone'},
    {id: 'primary_business_nature', label: 'Primary Business Nature'},
    {id: 'primary_commodity.descr', label: 'Primary Commodity'},
    {id: 'secondary_commodity.descr', label: 'Secondary Commodity'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'bu_status', label: 'Status', unsearchable: true, unsortable: true,
      conditional: true, condition: 'all'}
  ];

  var supplierGroupColumns = [
    {id: 'code', label: 'Code'},
    {id: 'descr', label: 'Description'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'}
  ];

  var supplierTagsBySupplierColumns = [
    {id: 'descr', label: 'Supplier Name'},
    {id: 'mb_supplier_code', label: 'Supplier Code'},
    {id: 'reg_no', label: 'ROC/ROB/NRIC'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'email', label: 'Email'},
    {id: 'contact_person.first_name', label:'Contact person\'s name'},
    {id: 'contact_person.email', label: 'Contact person\'s email'},
    {id: 'contact_person.phone', label: 'Contact person\'s phone'},
    {id: 'primary_business_nature', label: 'Primary Business Nature'},
    {id: 'primary_commodity.descr', label: 'Primary Commodity'},
    {id: 'secondary_commodity.descr', label: 'Secondary Commodity'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'status', label: 'Supplier Status'}
  ];

  var supplierTagsByCompanyColumns = [
    {id: 'descr', label: 'Company Name'},
    {id: 'code', label: 'Company Code'},
    {id: 'reg_no', label: 'Registration Code'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'business_unit.descr', label: 'Business Unit'},
    {id: 'industry.descr', label: 'Industry'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function( event, data, toParams ) {
    if(!!data.name && data.name.indexOf('supplierGroups')> -1){
      $scope.columns = supplierGroupColumns;
      $scope.searchPlaceholder = 'Search Supplier Business Units';
      $scope.searchState = 'main.user.suppliers.supplierGroups';
      $scope.parentState = 'main.user.suppliers.supplierGroups';
      $scope.parentStateParams = $state.params;
    }
    else if(!!data.name && data.name.indexOf('supplierGroupDetail')> -1){
      $scope.columns = supplierByGroupColumns;
      $scope.searchPlaceholder = 'Search Suppliers in Business Units';
      $scope.searchState = 'main.user.suppliers.supplierGroupDetail';
      $scope.parentState = 'main.user.suppliers.supplierGroupDetail';
      $scope.parentStateParams = $state.params;
    }
    else if(!!data.name && data.name.indexOf('tagsBySupplier')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.tagsBySupplier';
      $scope.parentState = 'main.user.suppliers.tagsBySupplier';
      $scope.parentStateParams = $state.params;
    }
    else if(!!data.name && data.name.indexOf('supplierTagsBySupplierDetail')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies Tagged to Supplier';
      $scope.searchState = 'main.user.suppliers.supplierTagsBySupplierDetail';
      $scope.parentState = 'main.user.suppliers.supplierTagsBySupplierDetail';
      $scope.parentStateParams = $state.params;
    }
    else if(!!data.name && data.name.indexOf('tagsByCompany')> -1){
      $scope.columns = supplierTagsByCompanyColumns;
      $scope.searchPlaceholder = 'Search Companies';
      $scope.searchState = 'main.user.suppliers.tagsByCompany';
      $scope.parentState = 'main.user.suppliers.tagsByCompany';
      $scope.parentStateParams = $state.params;
    }
    else if(!!data.name && data.name.indexOf('supplierTagsByCompanyDetail')> -1){
      $scope.columns = supplierTagsBySupplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers Tagged to Company';
      $scope.searchState = 'main.user.suppliers.supplierTagsByCompanyDetail';
      $scope.parentState = 'main.user.suppliers.supplierTagsByCompanyDetail';
      $scope.parentStateParams = $state.params;
    }
    else{
      $scope.columns = supplierColumns;
      $scope.searchPlaceholder = 'Search Suppliers';
      $scope.searchState = 'main.user.suppliers.manage';
      $scope.parentState = 'main.user.suppliers.manage';
      $scope.parentStateParams = $state.params;
    }
    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }
  });

  initialize();

  $scope.$emit('pageTitleChanged', 'Manage Suppliers');
  //TODO: have this cleaned up with better engineering solution (Ahmed Saleh)
  $scope.$emit('actionsChanged', []);

}
suppliersCtrl.$inject = ['$scope', 'UserPermissions', 'profile', '$state'];

angular.module('metabuyer')
  .controller('suppliersCtrl', suppliersCtrl);

