'use strict';

/**
 * @name attachmentsBlock Directive
 *
 * @desc Directive for showing and adding attachments
 *
 * @requires $scope
 * @requires $stateParams
 * @requires $auth
 * @requires toastr
 * @requires pathConstants
 * @requires $filter
 * @requires $http
 * @requires globalFunc
 * @requires $rootScope
 *
 * @isolatedScope disableEdit
 * Flag for disable edit attachment
 * @bindingType Two way binding
 *
 * @isolatedScope isFirstCreate
 * Flag for if PR is first create
 * @bindingType Two way binding
 *
 * @isolatedScope profile
 * Profile for current user
 * @bindingType Two way binding
 *
 * @isolatedScope singleAttachmentService
 * Service for single attachment
 * @bindingType Two way binding
 *
 * @isolatedScope attachmentParam
 * Parameters to be passed into attachment endpoint
 * @bindingType Two way binding
 *
 * @isolatedScope attachmentUrl
 * URL for attachment endpoint
 * @bindingType Two way binding
 *
 * @isolatedScope attachmentModel
 * String for the attachment model to determine storage path back end
 * @bindingType Attribute binding
 *
 * @isolatedScope hideIsVisibleToSupplier
 * Flag to hide is visible to supplier
 * @bindingType Two way binding
 *
 * @isolatedScope files
 * Array of files to be displayed in attachments
 * @bindingType Two way binding
 */
angular.module('metabuyer').directive('attachmentsBlock', function ($rootScope) {
  return {
    restrict: 'E',
    scope:{
      disableEdit: '=?',
      isFirstCreate: '=?',
      profile: '=?',
      singleAttachmentService: '=',
      attachmentParam: '=',
      attachmentUrl: '=',
      attachmentModel: '@',
      hideIsVisibleToSupplier: '=?',
      files: '='
    },
    templateUrl: function () {
      return $rootScope.isMobileMode ? 'components/attachment/attachmentTemplate-mobile.html' :
        'components/attachment/attachmentTemplate.html';
    },
    controller: function (
      $scope, $stateParams, $auth, toastr, pathConstants, $filter, $http, globalFunc, $rootScope
    ) {

      $scope.showDropZone = true;
      $scope.file_id = '';

      $scope.download = download;
      $scope.update = update;
      $scope.delete = deleteFile;

      _.forEach($scope.files, function (file) {
        file.edit_description = file.description;
      });

      var attachmentParameters = $scope.attachmentParam;

      /**
       * Function to download specific file
       * @param file_id
       */
      function download(file_id) {
        attachmentParameters['attachmentId'] = file_id;
        $scope.singleAttachmentService.get(
          attachmentParameters
        ).$promise.then(
          function (resource) {
            if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.temporary_url) {
              var newUrl = $filter('format')(pathConstants.apiUrls.attachments.downloadAttachment, {
                hash: resource.content.data.temporary_url
              });
            }
            // Use HTTP to allow and use the application/json
            // and download with the newUrl
            $http({
              url: newUrl
            }).then(function () {
              window.open(newUrl, '_self');
            }, function (response) {
            });
          }, function () {
            toastr.error('Failed to download attachment');
          }
        );
      }

      $scope.dropzoneConfig = {
        options: {
          // passed into the Dropzone constructor
          //maxFilesize in MB
          maxFilesize: 30,
          url: $scope.attachmentUrl,
          init: function() {
            /**
             * Append attachment model name to the form data
             */
            this.on('sending', function(file, xhr, formData){
              formData.append('model', $scope.attachmentModel);
            });
          },
          headers: {
            'Authorization': 'Bearer ' + $auth.getToken()
            //'mb_request_id': Date.now()
          },
          previewTemplate: '<div class="dz-preview dz-file-preview">' +
          '<div class="dz-details">' +
          '<div class="dz-filename"><span data-dz-name></span></div>' +
          '<div class="dz-size" data-dz-size></div>' +
          '<img data-dz-thumbnail />' +
          '</div>' +
          '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
          '<div style="z-index: 999; position: absolute; width: 30px; height: 30px; font-size: 25px; text-align: center; right: -10px; top: -10px;">' +
          '<a data-dz-remove type="button" class="btn-xs btn-white pull-right"><i class="fa fa-trash-o"></i></a>' +
          '</div>' +
          '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
          '</div>',
          acceptedFiles: '.xls, .doc, .ppt, .txt, .pdf, .jpg, .png, .gif, .bmp, .xlsx, .docx, .pptx, .jpeg'
        },
        eventHandlers: {
          sending: function (file, formData, xhr) {

          },
          error: function (file, error) {
            globalFunc.objectErrorMessage(error);
            this.removeFile(file);
          },
          success: function (file, resource) {
            $scope.$emit('changedField');
            $rootScope.$broadcast('refreshComments');

            var _this = this;
            setTimeout(function () {
              _this.removeFile(file);
            }, 1000);
            toastr.success('Attachment successfully uploaded');

            if (!!resource && !!resource.content && !!resource.content.data) {
              $scope.files.splice(0, 0, resource.content.data);
            }
            if (!!$scope.files.length) {
              $scope.showDropZone = false;
            }
          }
        }
      };

      /**
       * Function to update specific attachment
       * @param file
       * @param edit_is_visible_to_supplier
       */
      function update(file, edit_is_visible_to_supplier) {
        if (!edit_is_visible_to_supplier)
          edit_is_visible_to_supplier = false;

        attachmentParameters['attachmentId'] = file._id;

        $scope.singleAttachmentService.put(
          attachmentParameters,
          {
            description: file.description,
            is_visible_to_supplier: edit_is_visible_to_supplier
          }).$promise.then(
          function () {
            toastr.success('Attachment successfully updated');
          }, function () {
            file.edit_description = file.description;
            toastr.error('Failed to update attachment');
          }
        );
      }

      /**
       * Function to delete specific attachment
       * @param file
       */
      function deleteFile(file) {
        var file_index = _.findIndex($scope.files, function (file_item) {
          return file._id === file_item._id;
        });

        $scope.files.splice(file_index, 1);
        attachmentParameters['attachmentId'] = file._id;

        $scope.singleAttachmentService.delete(
          attachmentParameters
          ).$promise.then(
          function (resource) {
            toastr.success(resource.content.message);
            $rootScope.$broadcast('refreshComments');
            if (!$scope.files.length) {
              $scope.showDropZone = true;
            }
          }, function () {
            toastr.error('Failed to delete attachment');
            $scope.files.splice(file_index, 0, file);
          });
      }

    }
  };
});
