/**
 * @name shoppingCart
 *
 * @requires $scope
 * @requires $state
 * @requires $stateParams
 *
 * @description Directive for shopping cart in PR detail
 * This directive is for displaying the html of shopping cart and the logic involved with shopping cart
 *
 * Logic involved:
 * Adding item to cart
 * Removing item from cart
 * Updating the quantity of items in the cart
 *
 * @isolatedScope prItems
 * Isolated scope for parent scope's array of items, pr.items
 * @bindingType Two way binding
 *
 * @isolatedScope prStatus
 * Isolated scope for parent scope's status, pr.status
 * @bindingType Two way binding
 *
 *
 * @isolatedScope grandTotalAmount
 * Isolated scope for parent scope's grand total, grandTotalAmount
 * @bindingType Two way binding
 *
 *
 * @isolatedScope openLineItemDetails
 * Isolated scope for parent scope's function to view line item details, openLineItemDetails(item)
 * @bindingType Expression binding
 *
 * @function isShoppingMode
 * Function to check if PR is in shopping mode state, if yes will display different shopping cart html
 *
 * @function backToDetailPr
 * Function to return to PR details from shopping mode on shopping cart
 *
 * @author Justin Cheong Tian Yee<justin.cty90@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular
  .module('metabuyer')
  .directive('shoppingCart',function() {
    return{
      restrict: 'EA',
      templateUrl: 'components/shoppingCart/shoppingCartTemplate.html',
      scope: {
        prBilling: '=',
        grandTotalAmount: '=',
        openLineItemDetails: '&',
        prCurrency: '=',
        editingState: '='
      },
      controller: function(
        $scope, $state, $stateParams, toastr, prFunction, pathConstants, purchaseRequisitionsServices, globalFunc,
        $timeout
      ){

        $scope.imageBasePath      = pathConstants.apiUrls.image.pr;

        $scope.submitAmount       = submitAmount;
        $scope.isShoppingMode     = isShoppingMode;
        $scope.backToDetailPR     = backToDetailPR;
        $scope.getPRItems         = getPRItems;
        $scope.getPRStatus        = getPRStatus;
        $scope.openCart           = openCart;
        $scope.PRData             = PRData;
        $scope.viewCart           = viewCart;
        $scope.calculateCartTotal = calculateCartTotal;

        $scope.updateQuantityShoppingCart = updateQuantityShoppingCart;
        $scope.removeItemShoppingCart = removeItemShoppingCart;
        $scope.openLineItemDetailsShoppingCart = openLineItemDetailsShoppingCart;

        function isShoppingMode() {
          return $state.includes('main.user.purchaseRequisitions.details.catalog');
        }


        /**
         * force the form to Blur to bypass the $event.stopPropagation()
         * validate the entry with UOM, and reset
         * @param field
           * @param originalAmount
           * @param uom
           */
        function submitAmount(field, originalAmount, uom) {
          if (validateEntry(field.$data, uom)) {
            field.$form.$submit();
          } else {
            field.$data = originalAmount;
            field.$form.$cancel();
          }
        }

        /**
         * validate the entry with the UOM for the Fraction
         * @param amount
         * @param uom
         * @returns {boolean}
           */
        function validateEntry(amount, uom) {
          if (!amount) {
            toastr.error('Please insert a valid number');
            return false;
          }
          if ((angular.isDefined(uom) && !!uom) && (uom.is_fraction === 0 || !uom.is_fraction)) {
            if (amount % 1 !== 0) {
              toastr.error('This Unit of Measurement does not accept Fractions');
              return false;
            }
          }
          return true;
        }

        function backToDetailPR() {
          if (!!PRData().original_pr) {
            window.history.back();
          } else {
            $state.go('main.user.purchaseRequisitions.details', {id: PRData()._id, PR: {data: PRData()}});
          }
        }

        /**
         * @param item object
         * @param qty
         */
        function updateQuantityShoppingCart(item, qty){
          purchaseRequisitionsServices.setItem({
            id: item._id,
            action: 'qty'
          }, {
            Qty: qty
          }, function(resource) {
            if (!!resource && !!resource.content && !!resource.content.data)
              prFunction.setPRData(resource.content.data);

          }, function(error) {
            globalFunc.objectErrorMessage(error);
            //TODO: handle the error similar to itemList
          });
        }

        /**
         * @param item
         */
        function removeItemShoppingCart(item) {
          prFunction.removeItem(item._id).$promise.then(function (resource) {
            if (!!resource && !!resource.content && !!resource.content.data)
              prFunction.setPRData(resource.content.data);
          }, function (error) {
            globalFunc.objectErrorMessage(error);
            //TODO: handle the error similar to itemList
          });
        }
        /**
         * @param item object
         */
        function openLineItemDetailsShoppingCart(item){
          $scope.openLineItemDetails()(item);
        }

        function getPRItems(){
          return prFunction.getPRData().pr_items;
        }

        function getPRStatus() {
          return prFunction.getPRData().status;
        }

        function PRData() {
          return prFunction.getPRData();
        }


        /**
         * function to ensure the popup cart stays active
         */
        function openCart() {
          $('.dropdown-menu').click(function (event) {
            event.stopPropagation();
          });
        }

        /**
         * opening the Cart from the 'view cart message'
         */
        function viewCart(){
          $scope.itemAdded = false;
          $('#shoppingCartDirective').click(function (event) {
            event.stopPropagation();
            $('#shoppingCartPanel').addClass('show');
          });

        }

        /**
         * remove the pop up once the mouse is out
         */
        $scope.removeClass = function(){
          $('#shoppingCartPanel').removeClass('show');
        };

        /**
         * listener to activate the popup once an item is added
         */
        $scope.$on('itemAdded', function(){
          $scope.itemAdded = true;
          $scope.timer = $timeout(function(){
            $scope.itemAdded = false;
          }, 3000);
        });

        /**
         * calculate the Cart total without adding Taxes or Lump sum
         * @returns {number}
         */
        function calculateCartTotal(){
          var total = 0;
          _.forEach(getPRItems(), function (item) {
            total += item.amount_pr_currency;
          });
          return total;
        }
      }
    }
});
