'use strict';

angular.module('metabuyer')
  .controller('ResendPOCtrl', function ($scope, $rootScope, $uibModalInstance, Orders, toastr) {

    //cancel
    $scope.cancelResendPO = cancelResendPO;
    $scope.resend = resend;

    function cancelResendPO(){
      $uibModalInstance.close();
    }

    //send action
    function resend(poid){

      $uibModalInstance.close();

      Orders.resendPO({id: poid}, function(){
        toastr.success('Resent to supplier');

        //emit refresh history
        $rootScope.$broadcast('refreshComments');

      }, function(){
        toastr.error('Failed to resent to supplier');
      });
    }
  });

