'use strict';

function suppliersListDetailsCtrl(
  $scope, $q, $rootScope, toastr, supplier, currencies, Approvals, profile, globalFunc, listChild, commodities,
  supplierGlobalFunc, $state, HighlightId
) {
  $scope.supplier = supplier;
  $scope.supplier.status = supplierGlobalFunc.getSupplierStatus(
      supplier.status, supplier.approval_status, supplier.draft_status, 'descr'
  );
  $scope.approvals = [];
  $scope.approvalFlowData = {};
  $scope.waiting_on_user = [];
  $scope.checkWaitingOn = checkWaitingOn;
  $scope.back = back;
  $scope.loadCommodityList = loadCommodityList;
  $scope.selectedBumiputra = selectedBumiputra;
  $scope.selectPO = selectPO;
  $scope.address = {};
  $scope.loadApprovalFlow = loadApprovalFlow;
  $scope.currencies = currencies;
  $scope.primarySegments = commodities.data;
  $scope.secondarySegments = commodities.data;
  $scope.secondarySegments.unshift({_id: null, descr: 'N/A', unique_name: null});
  $scope.selectedContactPersons = [];
  $scope.user = profile;
  $scope.editClicked = false;
  $scope.originalROC = $scope.supplier.reg_no;
  $scope.originalGSTCode = $scope.supplier.gst_code;

  $scope.bumiputraStatus = [
    {descr: 'Yes', value: 1},
    {descr: 'No', value: 0}
  ];


  //TODO: move this data to the Global function (Ahmed Saleh, 26/3/2016)
  // The standard list for Business Nature and Business Entity
  $scope.businessNatures = [
    {descr: 'Unspecified Business Nature', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Agent', shortDescr: 'AGENT', value: 1},
    {descr: 'Banking & Financial Services', shortDescr: 'BANKING',  value: 2},
    {descr: 'Consultancy, Advisory & Other Professional Bodies', shortDescr: 'CONSULTANCY',  value: 3},
    {descr: 'Contractor', shortDescr: 'CONTRACTOR',  value: 4},
    {descr: 'Dealer', shortDescr: 'DEALER',  value: 5},
    {descr: 'Distribute', shortDescr: 'DISTRIBUTOR',  value: 6},
    {descr: 'Manufacturer', shortDescr: 'MANUFACTURER',  value: 7},
    {descr: 'Marketing', shortDescr: 'MARKETING',  value: 8},
    {descr: 'Retailer', shortDescr: 'RETAILER',  value: 9},
    {descr: 'Service Provider', shortDescr: 'SERVICE_PROVIDER',  value: 10},
    {descr: 'Sub-Contractor', shortDescr: 'SUB_CONTRACTOR',  value: 11},
    {descr: 'Trading', shortDescr: 'TRADING',  value: 12},
    {descr: 'Other', shortDescr: 'OTHERS',  value: 13}
  ];
  $scope.businessEntities = [
    {descr: 'Unspecified Business Entity', shortDescr: 'UNSPECIFIED', value: 0},
    {descr: 'Berhad (Public Limited)', shortDescr: 'BERHAD',  value: 1},
    {descr: 'Sendirian Berhad (Private Limited)', shortDescr: 'SENDIRIAN_BERHAD',  value: 2},
    {descr: 'Sole Proprietor & Enterprise', shortDescr: 'SOLE', value: 3},
    {descr: 'Partnership', shortDescr: 'PARTNERSHIP', value: 4}
  ];

  $scope.poReceive = [
    {descr: 'System', value: 0},
    {descr: 'System and Email', value: 1},
    {descr: 'System, Email and Fax', value: 2},
    {descr: 'System, Email, Fax and Post', value: 3}
  ];

  $scope.selection = {
    poMethod: {}
  };

  $scope.freightTerms = [];

  // Add requestor
  $scope.requestor = {
    "_id": $scope.supplier.created_by._id,
    "display_name": $scope.supplier.created_by.display_name,
    "img_url":""
  };

  $scope.methodSelected = false;
  $scope.faxChecker = false;
  $scope.emailChecker = false;

  function selectPO(poMethod){
    $scope.methodSelected = true;
    if(poMethod === 2 || poMethod === 3){
      $scope.faxChecker = true;
    }
    else{
      $scope.faxChecker = false;
    }
    if(poMethod === 1 || poMethod === 2 || poMethod === 3){
      $scope.emailChecker = true;
    }
    else{
      $scope.emailChecker = false;
    }
  }

  //check user waiting on
  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function (approver) {
      return (approver._id == profile._id);
    });

    if (find_user != undefined) {
      return true;
    }
    return false;
  }

  // Get the waiting on user
  function getWaitingOnUser(approval_flow_data) {
    $scope.waiting_on_user.splice(0, $scope.waiting_on_user.length);
    for (var i = 0; i < approval_flow_data.waiting_on.length; i++) {
      for (var z = 0; z < approval_flow_data.flow.length; z++) {
        for (var k = 0; k < approval_flow_data.flow[z].roles.length; k++) {
          for (var j = 0; j < approval_flow_data.flow[z].roles[k].users.length; j++) {
            if (approval_flow_data.waiting_on[i]._id === approval_flow_data.flow[z].roles[k].users[j]._id) {
              $scope.waiting_on_user.push(approval_flow_data.flow[z].roles[k].users[j]);
            }
          }
        }
      }
    }
    // });
  }

  function back() {
    if(!!$scope.supplier && !!$scope.supplier._id){
      HighlightId.setId($scope.supplier._id);
    }

    window.history.back();
  }

  /**
   * Function for loading the commodity listing based on parameter item for code
   */
  function loadCommodityList(item, callback){
    listChild.get(
      {
        code : item
      },
      function(resource){
        $scope.families = resource.content.data;
        if(typeof callback === 'function'){
          callback($scope.families);
        }
      },
      function(error){
        if (error.status === 404) {
          toastr.error("Could not load families for this segment");
        }
      }
    );
  }

  /**
   * Function for setting bumiputra status
   */
  function selectedBumiputra(value){
    $scope.supplier.bumiputra_status = value;
  }


  /**
   * function for loading the approval
   * @returns {*}
   */
  function loadApprovalFlow() {

    var deferred = $q.defer();

    if (!$scope.supplier || !$scope.supplier.approval_id) {
      $scope.approvals = [];
      deferred.resolve([]);
      return deferred.promise;
    }

    Approvals.get(
      {
        id: $scope.supplier.approval_id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.flow) {
          $scope.approvals = resource.content.data.flow;
          deferred.resolve(resource.content.data.flow);
          if ($scope.supplier.status.toLowerCase() === 'pending') {
            $scope.approvalFlowData = resource.content.data;
            getWaitingOnUser(resource.content.data);
          }
        } else {
          $scope.approvals = [];
          deferred.resolve([]);
        }
      },
      function () {
        $scope.approvals = [];
        deferred.resolve([]);
      });

    return deferred.promise;
  }

  function getPaidUpCategory(){
    $scope.paidUpCategoryDescr = supplierGlobalFunc.getPaidUpCategory($scope.supplier.paid_up_value);
  }

  function initialize(){
    $state.params.status = !!$scope.supplier.status ? $scope.supplier.status.toLowerCase() : 'all';

    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    getPaidUpCategory();

    //active tab
    $rootScope.$broadcast('setSuppliersListTab', $scope.supplier.status.toLowerCase());
    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();

    /**
     * To initialize primary business nature by looping and comparing short descr with returned data
     */
    if(angular.isDefined($scope.supplier.primary_business_nature)){
      for(var i = 0; i < $scope.businessNatures.length ; i++){
        if($scope.businessNatures[i].shortDescr === $scope.supplier.primary_business_nature){
          $scope.supplier.selectedPrimaryBusinessNature = $scope.businessNatures[i];
        }
      }
    }
    else{
      $scope.supplier.selectedPrimaryBusinessNature = $scope.businessNatures[0];
    }

    /**
     * To initialize secondary business nature by looping and comparing short descr with returned data
     */
    if(angular.isDefined($scope.supplier.secondary_business_nature)){
      for(var j = 0; j < $scope.businessNatures.length ; j++){
        if($scope.businessNatures[j].shortDescr === $scope.supplier.secondary_business_nature){
          $scope.supplier.selectedSecondaryBusinessNature = $scope.businessNatures[j];
        }
      }
    }
    else{
      $scope.supplier.selectedSecondaryBusinessNature = $scope.businessNatures[0];
    }

    /**
     * To initialize business entities by looping and comparing short descr with returned data
     */
    if(angular.isDefined($scope.supplier.business_entity)){
      for(var k = 0; k < $scope.businessEntities.length ; k++){
        if($scope.businessEntities[k].value === parseInt($scope.supplier.business_entity)){
          $scope.supplier.selectedBusinessEntity= $scope.businessEntities[k];
        }
      }
    }else{
      $scope.supplier.selectedBusinessEntity = $scope.businessEntities[0];
    }

    $scope.selectedContactPersons = null;
    if(!!$scope.supplier && !!$scope.supplier.additional_contacts &&
       !!$scope.supplier.additional_contacts.data){
      $scope.selectedContactPersons = _.cloneDeep($scope.supplier.additional_contacts.data);
      $scope.selectedContactPersons.unshift($scope.supplier.contact_person);
    }

    $scope.supplier.selectedCurrency = null;
    if(!!$scope.supplier && !!$scope.supplier.currency_code ){
      var temp = _.find($scope.currencies, function(item){
        return $scope.supplier.currency_code == item.code;
      });

      if(!!temp){
        $scope.supplier.selectedCurrency = temp;
      }
    }

    $scope.address = {};
    if(!!$scope.supplier && !!$scope.supplier.corresponding_address &&
       !_.isArray($scope.supplier.corresponding_address)){
      $scope.address = _.cloneDeep($scope.supplier.corresponding_address);
    }

    /**
     * To initialize country by looping and comparing descr with returned data
     */
    if(angular.isDefined($scope.address.country)){
        if(!!globalFunc.findInArray($scope.countries,'descr',$scope.address.country)
            &&globalFunc.findInArray($scope.countries,'descr',$scope.address.country).descr ===
          $scope.address.country){
          if($scope.address.country === "Malaysia")
          {
            $scope.selectedMalaysia = true;
          }
          else {
            $scope.selectedMalaysia = false;
          }
        }
    }

    if ($scope.supplier.status.toLowerCase() === 'pending') {
      $scope.loadApprovalFlow();
    }

    if(angular.isDefined($scope.supplier) && angular.isDefined($scope.supplier.bumi_status)){
      $scope.supplier.bumiputra_status = $scope.supplier.bumi_status;
    }

    if(angular.isDefined($scope.supplier) && angular.isDefined($scope.supplier.receiving_mode)){
      switch($scope.supplier.receiving_mode){
        case 'SYSTEM':
          $scope.selection.poMethod = 0;
          break;
        case 'EMAIL':
          $scope.selection.poMethod = 1;
          break;
        case 'FAX':
          $scope.selection.poMethod = 2;
          break;
        case 'POST':
          $scope.selection.poMethod = 3;
          break;
      }
    }

    $scope.supplier.comments = globalFunc.translateSupplierHistory($scope.supplier.comments);
  }

  initialize();


}

suppliersListDetailsCtrl.$inject = [
  '$scope', '$q', '$rootScope', 'toastr','supplier', 'currencies', 'Approvals' , 'profile', 'globalFunc','listChild',
  'commodities', 'supplierGlobalFunc', '$state', 'HighlightId'
];

angular.module('metabuyer')
  .controller('suppliersListDetailsCtrl', suppliersListDetailsCtrl);
