'use strict';

function compareSupplierVersionCtrl(
  $scope, $uibModalInstance, $uibModal, versions, selectedVersion, globalFunc, currentStatus, versionType
) {
  $scope.closeComparisonModal = closeComparisonModal;
  $scope.compareArrayValue = compareArrayValue;
  $scope.compareAttachmentDescriptionVersion = compareAttachmentDescriptionVersion;

  $scope.currentStatus = currentStatus;
  $scope.versionType = versionType;

  function closeComparisonModal() {
    // Remove row highlighting
    clearHasUpdateFlag();

    $uibModalInstance.close();
    $uibModal.open({
      templateUrl: 'app/suppliersManagement/versions/supplier-versions.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        supplierVersions: function() {
          return versions;
        },
        supplierStatus: function() {
          return $scope.currentStatus;
        },
        versionType: function () {
          return versionType;
        }
      },
      controller: 'supplierVersionsController'
    });
  }

  /**
   * compare Arrays value
   * @param array1
   * @param array2
   * @param fieldToCompare
   * @returns {boolean}
   */
  function compareArrayValue(array1, array2, fieldToCompare) {
    var arrIteration;

    if (!array1 || !array2)
      return false;

    if(array1.length !== array2.length)
      return false;

    for (arrIteration in array1) {
      if (array1.hasOwnProperty(arrIteration)) {
        var checkValInArray2 = globalFunc.findInArray(array2, fieldToCompare, array1[arrIteration][fieldToCompare]);
        if (!checkValInArray2)
          return false;
      }
    }

    return true;
  }

  /**
   * comparing attachment description version
   * @param prevAttachment
   * @param newAttachment
   * @return  {boolean}
   */
  function compareAttachmentDescriptionVersion(prevAttachment, newAttachment) {
    var comparison = true;
    if (!prevAttachment || !newAttachment)
      comparison = false;

    if(prevAttachment.length !== newAttachment.length)
      comparison = false;

    _.forEach(prevAttachment, function (prev) {
      // get the same data from newAttachment
      if (!!prev._id) {
        var attachmentDesc = globalFunc.findInArray(newAttachment, '_id', prev._id);
        if (!!attachmentDesc && !!attachmentDesc.attachment_category && !!attachmentDesc.attachment_detail &&
            !!attachmentDesc.description_array && !!prev.attachment_detail && prev.attachment_category &&
            !!attachmentDesc.description_array && !!prev.description_array) {
          // compare the attachment category
          if (attachmentDesc.attachment_category._id !== prev.attachment_category._id) {
            comparison = false;
            return false;
          }

          if (attachmentDesc.attachment_detail._id !== prev.attachment_detail._id) {
            comparison = false;
            return false;
          }

          if (prev.description_array.length !== attachmentDesc.description_array.length) {
            comparison = false;
            return false;
          }

          _.forEach(prev.description_array, function (field) {
            if (!!field._id) {
              var attachmentDescField = globalFunc.findInArray(attachmentDesc.description_array, '_id', field._id);
              if (!!attachmentDescField) {
                if (field.value !== attachmentDescField.value) {
                  comparison = false;
                  return false;
                }
              } else {
                comparison = false;
                return false;
              }
            }
          });
        } else {
          comparison = false;
        }
      }
    });
    return comparison;
  }

  function checkBranchDifferences(array1, array2) {
    // Remove row highlighting
    clearHasUpdateFlag();

    var hasDifferences = !_.isEqual(array1,array2);
    if (!!hasDifferences) {
      _.forEach($scope.supplierVersions[0].branches, function(branch, index){
        if (!_.isEqual(branch,$scope.supplierVersions[1].branches[index])) {
          branch.hasUpdate = true;
        }
      });

      _.forEach($scope.supplierVersions[1].branches, function(branch, index){
        if (!_.isEqual(branch,$scope.supplierVersions[0].branches[index])) {
          branch.hasUpdate = true;
        }
      });

    }
    return hasDifferences;
  }

  /**
   * Set hasUpdate attribute for branch to false to clear the flag
   *
   * @returns void
   */
  function clearHasUpdateFlag() {
    _.forEach($scope.supplierVersions[0].branches, function(branch){
      branch.hasUpdate = false;
    });

    _.forEach($scope.supplierVersions[1].branches, function(branch){
      branch.hasUpdate = false;
    });
  }

  function initialize() {
    $scope.supplierVersions = [];
    _.forEach(versions, function (version) {
      if (selectedVersion.indexOf(version._id) > -1)
        $scope.supplierVersions.push(version);
    });
    $scope.branchChanges = checkBranchDifferences($scope.supplierVersions[0].branches,$scope.supplierVersions[1].branches);
  }

  initialize();
}

compareSupplierVersionCtrl.$inject = [
  '$scope', '$uibModalInstance', '$uibModal', 'versions', 'selectedVersion', 'globalFunc', 'currentStatus',
  'versionType'
];

angular.module('metabuyer')
  .controller('compareSupplierVersionCtrl', compareSupplierVersionCtrl);
