/**
 * @name addressBook
 *
 * @requires $uibModal
 *
 * @description This directive is for the address book popup to select an address
 *
 * @isolatedScope addressBook
 * Isolated scope for parent scope's address object
 * @bindingType One way binding
 *
 * @isolatedScope addressList
 * Isolated scope for parent scope's list of address
 * @bindingType Two way binding
 *
 * @isolatedScope onSelectedAddressBook
 * Isolated scope for parent scope's function on select address
 * @bindingType Expression binding
 *
 * @isolatedScope accessFrom
 * Isolated scope for determining directive is accessed from which feature
 * @bindingType One way binding
 *
 * @isolatedScope eventHandler
 * Isolated scope for determining when to call the directive
 * @bindingType Two way binding
 *
 *
 * @authors Justin Cheong Tian Yee
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';
angular
  .module('metabuyer')
  .directive('addressBook',function($uibModal, toastr, Orders) {
    return{
      restrict: 'A',
      scope: {
        addressBook: '@?',
        addressList: '=',
        onSelectedAddressBook: '&',
        accessFrom: '@',
        eventHandler: '=',
        lumpSum: '=?',
        removeLumpSum: '&?',
        prDeliveryAddress: '=?',
        selectedItemsIndexes: '=?',
        multiDeletionFunction: '&?'
      },
      link: function(scope, element, attrs) {
        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/addressBook/addressBookTemplate.html',
            controller: "AddressBookCtrl",
            backdrop: 'static',
            keyboard: false,
            scope: scope,
            size: 'lg'
          });
        };

        //loading address book
        scope.showAddressBook = function () {
          panel();
        };

        element.bind('click' , function($event){
          if(scope.eventHandler){
            scope.showAddressBook();
          }
        });

      }
    };
  });
