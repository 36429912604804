'use strict';
/**
 * @name UsersAddUserCtrl
 * @desc Controller for users details
 */
function UsersAddUserCtrl($scope, $q, $resource, $state, Users, toastr, companiesForAdminUse,
                          $uibModal, globalFunc, companyCostCenters, $rootScope) {

  $scope.selectCostCenter = selectCostCenter;
  $scope.duplicationCheck = duplicationCheck;
  $scope.generatePassword = generatePassword;
  $scope.selectedEmailFrequency = selectedEmailFrequency;
  $scope.randomPassword = false;
  $scope.submitted = false;

  $scope.addUser = addUser;

    $scope.options = {
    companies: companiesForAdminUse,
    user_group: [],
    languages: [],
    timezones: [],
    countries: []
  };

  $scope.emailFrequency = [
    {descr: 'Immediate', value: true},
    {descr: 'End of Day', value: false}
  ];

  function selectedEmailFrequency(value){
    $scope.user.receive_email_immediate = value;
  }

  $scope.submitted = false;

  $scope.user = {};
  $scope.user.companies = [];
  //TODO: remove redundant variables (Ahmed Saleh, 10/07/2017)
  $scope.user.cc_list = [];
  $scope.user.roles = [];
  $scope.user.roles_id = [];
  $scope.deselectedCC = {};

  function deselectCompany (companyID){
    companyCostCenters.get(
      {
        id: companyID
      },
      function(resource){
        $scope.deselectedCC = resource.content.data;
        for(var k =0;k<$scope.user.cc_list.length;k++)
        {
          for(var l=0; l<$scope.deselectedCC.length;l++)
          {
            if($scope.user.cc_list[k] === $scope.deselectedCC[l].code)
            {
              $scope.user.cc_list.splice(k,1);
            }
          }
        }
      }
    );
  }
  $scope.message_error = {};

  $scope.selected_options = {
    companies:[],
    user_group: [],
    languages: [],
    timezones: [],
    countries: []
  };

  //setting for company multi-select dropdown list
  $scope.settingCompany = {smartButtonMaxItems: 1,
    scrollable: true,
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    enableSearch: true,
    enableSortOnTop: true,
    closeOnSelect: true,
    smartButtonTextConverter: function(itemText, originalItem) { return "Add a company"; }};

  $scope.defaultCompanyButtonText = {buttonDefaultText: "Add a company"};

  $scope.companyItemEvent = {
    onItemSelect: function(selected_company_item){
      $scope.user.companies.push(selected_company_item);
      var current_company = _.find($scope.selected_options.companies, function(company){
        return company._id == selected_company_item._id;
      });
    },

    onItemDeselect: function(selected_company_item){
      deselectCompany(selected_company_item._id);
    }
  };

  //load option
  $scope.loadOptions = function (url, params, options, paramValues, scopeOptionName) {
    if ($scope.options[scopeOptionName].length > 0) {
      return $scope.options[scopeOptionName];
    }

    var deferred = $q.defer();
    $resource(
      url,
      params,
      options
    ).get(
      paramValues,
      function (resource) {
        $scope.options[scopeOptionName] = resource.content.data;
        deferred.resolve(resource.data);

      }
    );

    return deferred.promise;
  };

  $scope.loadOptionsSimple = function (url, scopeOptionName) {
    return $scope.loadOptions(url, {}, {}, {}, scopeOptionName);
  };

  //load user group
  $scope.loadUserGroup = function () {
    //return $scope.loadOptionsSimple(pathConstants.apiUrls.companies.list, 'user_group');

    $scope.options['user_group'] = [{name: "Users", id:1}, {name:"Admin", id:2}, {name: "Procurement Agents", id: 3}];
    $scope.selected_options['user_group'] = [$scope.options.user_group[0]];
  };

  //load countries
  $scope.loadCountries = function () {
    //return $scope.loadOptionsSimple(pathConstants.apiUrls.companies.list, 'countries');

    $scope.options['countries'] = globalFunc.countryCode();

//    $scope.selected_options['country'] = {selected: $scope.options.countries[0]};
  };


  function selectCostCenter(selected_company){
    $uibModal.open({
      templateUrl: "app/usersManagement/add/user-costcenter-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: userCostCenterCtrl,
      scope: $scope,
      resolve:{
        userCostCenters: function($q,companyCostCenters){
          var deferred = $q.defer();
          companyCostCenters.get(
            {
              id: selected_company._id
            },
            function (resource) {
              if (!!resource.content) {
                deferred.resolve(resource.content.data);
              }
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
  })
  }

  $scope.validateInputData = function(){

    //validate password
    if (!$scope.user.password || !$scope.user.password.length)
      return false;
    if (!!$scope.user.password && $scope.user.password.length < 8) {
      $scope.message_error.password_length = true;
      return false;
    }
    if (!$scope.user.name || !$scope.user.email || !$scope.user.employee_number)
      return false;
    if (!$scope.selected_options.country || (!!$scope.selected_options.country && !$scope.selected_options.country.selected))
      return false;

    return true;
  };

  $scope.cancelAddUser = function(){
    $state.go('main.user.users.manage', {status:'active'});
  };

  //upload user detail
  function addUser (){
    $scope.submitted = true;

    if(!$scope.validateInputData()){
      toastr.error('Please fill all required fields');
      $scope.submitted = false;
      return;
    }

    var submit_data = {
      display_name: $scope.user.name,
      email: $scope.user.email,
      send_email: $scope.user.send_email,
      password: $scope.user.password,
      //TODO: remove safely (Ahmed Saleh, 10/07/2017)
      img_url: 'http://lorempixel.com/128/128/people/?16748',
      cc_list: getSelectedCCList(),
      receive_email_immediate: $scope.user.receive_email_immediate,
      emp_no: $scope.user.employee_number,
      config: {
        lang: 'EN-US',
        TZ: 'Asia/Kuala_Lumpur',
        country: $scope.selected_options.country.selected.descr,
        mobile: $scope.user.mobile
      },
      roles: getSelectedRolesList(),
      superiors: getSuperiorFormattedInfo()
    };

    $scope.user.cc_list = getSelectedCCList();
    $scope.user.roles = getSelectedRolesList();

    Users.post(
      {},
    submit_data,
      function () {
        toastr.success('User has been added successfully');
        $state.go('main.user.users.manage', {status: 'Active'});
      },
      function () {
        //TODO: the error from the backend is not clean, fix from backend then update the error to use globalFunc (Ahmed Saleh, 3/2/2017)
        $scope.submitted = false;
        if($scope.user.cc_list.length === 0){
          toastr.error('Selected companies does not have cost centers!');
        }
        else if($scope.user.roles.length === 0){
          toastr.error('There are no roles assigned to user!');
        }
        else{
          toastr.error('User failed to be added');
        }
      });
  }

  function generatePassword(){
    $scope.user.password = globalFunc.generateRandomPassword();
    $scope.randomPassword = true;
    $scope.user.send_email = true;
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'emailCheckTrue':
          $scope.emailCheck = true;
          break;
        case 'empCheckTrue':
          $scope.empCheck = true;
          break;
        case 'emailCheckFalse':
          $scope.emailCheck = false;
          break;
        case 'empCheckFalse':
          $scope.empCheck = false;
          break;
      }
    });
  }

  function getSelectedCCList(){
    var list = [];
    _.forEach($scope.user.companies, function(company){
      _.forEach(company.CCs.selected, function (costCenter) {
          list.push(costCenter.code);
      });
    });
    return list;
  }

  function getSelectedRolesList() {
    var list = [];
    _.forEach($scope.user.companies, function (company) {
      _.forEach(company.roles.selected, function (role) {
        list.push(role._id);
      });
    });
    return list;
  }

  function getSuperiorFormattedInfo() {
    var list = {};
    _.forEach($scope.user.companies, function(company) {
      if(!!company.superior.selected && !!company.superior.selected._id) {
        list[company.code] = company.superior.selected._id;
      }
    });
    return list;
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

UsersAddUserCtrl.$inject = ['$scope', '$q', '$resource', '$state', 'Users', 'toastr', 'companiesForAdminUse',
                            '$uibModal', 'globalFunc', 'companyCostCenters', '$rootScope'];

angular
  .module('metabuyer')
  .controller('UsersAddUserCtrl', UsersAddUserCtrl);


