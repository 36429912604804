'use strict';

/**
 * @name budget assignments Directive
 * @desc Directive for assignments in budget
 *
 * @isolatedScope budgetData
 * Budget data
 *
 * @isolatedScope budgetAssignmentDetailsError
 * Flag to indicate assignment details contains any error upon submit
 *
 * @isolatedScope budgetItemDetailsError
 * Flag to indicate item details contains any error upon submit
 *
 * @isolatedScope editingMode
 * Whether the budget is in editing mode
 *
 * @isolatedScope costCenterList
 * List of cost centers to be selected
 *
 * @isolatedScope expenseTypeList
 * List of expense type to be selected
 *
 * @isolatedScope apportionList
 * List of apportion to be selected
 *
 * @author H.W. Liew <liewhanwahwork@gmail.com>
 * @copyright 2017 Metacloud Sdn. Bhd.
 */
angular
  .module('metabuyer')
  .directive('budgetAssignments', function () {
    return {
      restrict: 'E',
      template: '<div ng-include="templateUrl"></div>',
      scope: {
        budgetData: '=',
        budgetAssignmentDetailsError: '=',
        budgetItemDetailsError: '=',
        editingMode: '=',
        costCenterList: '=',
        expenseTypeList: '=',
        apportionList: '='
      },
      controller: function ($scope, $rootScope, budgetFunctions) {
        $scope.templateUrl = $scope.editingMode === 'editable' ?
          'components/budget/assignments/budgetAssignments.editableTemplate.html' :
          'components/budget/assignments/budgetAssignments.nonEditableTemplate.html';
        $scope.startDateOptions = [];
        $scope.endDateOptions = [];
        $scope.allAssignmentsExpand = false;

        $scope.addBudgetAssignments = addBudgetAssignments;
        $scope.deleteBudgetAssignments = deleteBudgetAssignments;
        $scope.openAssignmentDatepicker = openAssignmentDatepicker;
        $scope.checkApportionAllocation = checkApportionAllocation;
        $scope.isAssignmentAmountGreaterThanMaster = isAssignmentAmountGreaterThanMaster;
        $scope.showAssignmentItems = function(assignment) {
          assignment.toggle = !assignment.toggle;
        };
        $scope.canDelete = canDelete;
        $scope.hasParentBudget = hasParentBudget;
        $scope.toggleAssignmentDisplay = toggleAssignmentDisplay;
        $scope.showItemListing = showItemListing;

        /**
         * Check if budget is a revision
         */
        function hasParentBudget() {
          return !!$scope.budgetData.parent_id;
        }

        /**
         * Add budget Assignment
         */
        function addBudgetAssignments() {
          $scope.budgetData.assignments.push(
            {
              cost_center: [],
              expense_type: !!$scope.budgetData.expense_type ? $scope.budgetData.expense_type : null,
              apportion_type: $scope.budgetData.apportion_type,
              item_breakdowns: [],
              pr_dashboard_display: $scope.budgetData.pr_dashboard_display,
              start_date: !!$scope.budgetData.start_date ? $scope.budgetData.start_date : null,
              end_date: !!$scope.budgetData.end_date ? $scope.budgetData.end_date : null,
              justification: !!$scope.budgetData.justification ? $scope.budgetData.justification : ''
            }
          );

          // Removing the parent level apportion data
          $scope.budgetData = budgetFunctions.removeParentApportionData($scope.budgetData);
        }

        /**
         * Delete Budget Assignment
         *
         * @param {number} index
         */
        function deleteBudgetAssignments(index) {
          $scope.budgetData.assignments.splice(index, 1);
        }

        /**
         * Datepicker for assignment dates selection
         *
         * @param {Object} $event
         * @param {string} which
         * @param {number} index
         */
        function openAssignmentDatepicker($event, which, index) {
          if (!!$scope.budgetData.assignments[index].end_date) {
            $scope.startDateOptions[index] = {
              formatYear: 'yy',
              startingDay: 1
            };
            $scope.startDateOptions[index].maxDate = $scope.budgetData.assignments[index].end_date;
          }

          if (!!$scope.budgetData.assignments[index].start_date) {
            $scope.endDateOptions[index] = {
              formatYear: 'yy',
              startingDay: 1
            };
            $scope.endDateOptions[index].minDate = $scope.budgetData.assignments[index].start_date;
          }

          if ($scope.budgetData.assignments[index].startDatepickerOpened ||
            $scope.budgetData.assignments[index].endDatepickerOpened) {
            $event.preventDefault();
            $event.stopPropagation();
          } else {
            if (which === 'startDateAssignment') {
              $scope.budgetData.assignments[index].startDatepickerOpened = true;
            } else {
              $scope.budgetData.assignments[index].endDatepickerOpened = true;
            }
          }
        }

        /**
         * To check if apportion allocation values will change due to field changes
         *
         * @param {*}      oldValue   old value of the field
         * @param {*}      newValue   new value of the field
         * @param {string} fieldModel Name of the model to be modified
         * @param {number} index
         */
        function checkApportionAllocation(oldValue, newValue, fieldModel, index) {
          budgetFunctions.checkApportionAllocation(
            oldValue,
            newValue,
            $scope.budgetData.assignments[index].apportion_allocation).then(function (resource) {
            $scope.budgetData.assignments[index].apportionRelatedValues = resource;
            $scope.budgetData.assignments[index][fieldModel] = resource;
            $scope.originalAssignmentData[index][fieldModel] = resource;
          });
        }

        /**
         * To check if assignment total amounts greater than master amount
         */
        function isAssignmentAmountGreaterThanMaster() {
          var totalAssignmentAmount = 0;
          _.forEach($scope.budgetData.assignments, function (assignment) {
            if (!!assignment.amount) {
              var assignmentActualAmount = budgetFunctions.subtract(assignment.amount, assignment.block_amount);
              totalAssignmentAmount = budgetFunctions.add(assignmentActualAmount, totalAssignmentAmount);
            }
          });

          var budgetActualAmount = budgetFunctions.subtract($scope.budgetData.amount, $scope.budgetData.block_amount);
          if (budgetActualAmount < totalAssignmentAmount) {
            return true;
          }

          return false;
        }

        /**
         * To check if assignment amount is used before, then can't be removed
         */
        function canDelete (assignment) {
          if ((!!assignment.committed_amount || !!assignment.used_amount) &&
            (assignment.committed_amount > 0 || !!assignment.used_amount > 0)) {
            return false;
          }

          return true;
        }

        /**
         * Toggle between off and on for assignments expansion
         */
        function toggleAssignmentDisplay() {
          $scope.allAssignmentsExpand = !$scope.allAssignmentsExpand;
          $scope.budgetData.assignments = $scope.budgetData.assignments.map(function(assignment) {
            assignment.isOpen = $scope.allAssignmentsExpand;
            return assignment;
          });
        }

        /**
         * Indicator to show item listing
         */
        function showItemListing() {
          // don't show if budget is revised budget or status is in pending/on-hold/draft/revision
          return !$scope.budgetData.parent_id &&
            ['pending', 'on_hold', 'draft', 'revision'].indexOf($scope.budgetData.status) === -1;
        }

        function initialize() {
          $scope.originalAssignmentData = {};
          $scope.budgetData.assignments = $scope.budgetData.assignments.map(function(assignment) {
            assignment.isOpen = false;
            assignment.toggle = false;
            return assignment;
          });
          _.forEach($scope.budgetData.assignments, function(assignment, key){
            $scope.originalAssignmentData[key] = _.cloneDeep(assignment);
          });
        }

        initialize();
      }
    };
  });
