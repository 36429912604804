'use strict';

/**
 * Controller for viewing supplier groups
 * @param $scope
 * @param globalFunc
 * @param suppliersByGroup
 * @param suppliersList
 * @param $stateParams
 * @param $rootScope
 * @param HighlightId
 */

function supplierListGroupDetailCtrl(
  $scope, globalFunc, suppliersByGroup, suppliersList, $stateParams, $rootScope, HighlightId
) {

  function back(){
    window.history.back();
  }

  function getStatusName(bu_status, status){
    if($scope.supplierBUCode.toLowerCase() === 'global'){
      if (status === 1) {
        return 'Active'
      }
      if (status === 2) {
        return 'Black Listed'
      }
      if (status === 6) {
        return 'Inactive'
      }
    }
    else{
      var buStatusAsArray = globalFunc.reformatFromObjectToArray(bu_status);
      var supplierStatus;
      _.forEach(buStatusAsArray, function(bu){
        if(bu.key === $scope.supplierBUCode){
          supplierStatus = bu.value.status;
        }
      });

      if (supplierStatus === 1) {
        return 'Active'
      }
      if (supplierStatus === 2) {
        return 'Black Listed'
      }
      if (supplierStatus === 6) {
        return 'Inactive'
      }
    }
  }

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    /**
     * Switch case to display readable status
     */
    switch($stateParams.status){
      case 'all':
        $scope.supplierBUStatus = 'all';
        break;
      case 'active':
        $scope.supplierBUStatus = 'active';
        break;
      case 'black_listed':
        $scope.supplierBUStatus = 'black listed';
        break;
      case 'inactive':
        $scope.supplierBUStatus = 'inactive';
        break;
    }

    $scope.supplierBUCode = $stateParams.bu_code;
    $scope.supplierBUDescr = $stateParams.bu_descr;
    $scope.title = $stateParams.title;
    $scope.dataList = suppliersByGroup.data;
    $scope.meta = suppliersByGroup.meta;
    $scope.listingSupplierService = suppliersList;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;
    $scope.getStatusName = getStatusName;
    $scope.showColumn = showColumn;
    $scope.back = back;

    $scope.storageKey = 'suppliers-listing-by-bu-selected-columns';
    $scope.highlightId = HighlightId.getId();
    if(!!$scope.highlightId) {
      HighlightId.setId(null);
    }
  }

  initialize();
}

supplierListGroupDetailCtrl.$inject = [
  '$scope', 'globalFunc', 'suppliersByGroup', 'suppliersList', '$stateParams', '$rootScope', 'HighlightId'
];

angular.module('metabuyer')
  .controller('supplierListGroupDetailCtrl', supplierListGroupDetailCtrl);
