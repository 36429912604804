'use strict';
/**
 * @name manageCompanyCtrl
 * @desc Controller for manage companies
 */
function manageCompanyCtrl($scope, $stateParams, companies, $location, pathConstants, $uibModal, HighlightId) {
  //TODO: safely remove the 3 useless variables (Ahmed Saleh, 25/7/2016)
  $scope.status = $stateParams.status;
  $scope.paramId = $stateParams.bu_id ? $stateParams.bu_id : $stateParams.industry_id;

  $scope.gotoCompaniesList = gotoCompaniesList;
  $scope.back = back;
  $scope.importCompanies = importCompanies;
  $scope.importAddresses = importAddresses;
  $scope.showColumn = showColumn;
  $scope.companies = companies.data;
  $scope.meta = companies.meta;

  $scope.companyImportLink = pathConstants.apiUrls.companies.import;
  $scope.addressImportLink = pathConstants.apiUrls.addresses.import;
  $scope.highlightId = HighlightId.getId();
  if (!!$scope.highlightId) {
    HighlightId.setId(null);
  }

  function back(){
    window.history.back();
  }

  function gotoCompaniesList() {
    $location.url($location.path());
  }

  function importCompanies() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.companyImportLink;
        },
        title: function () {
          return "Companies";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }


  function importAddresses(){
    $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.addressImportLink;
        },
        title: function () {
          return "Addresses";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  $scope.storageKey = 'company-management-listing-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }
}

manageCompanyCtrl.$inject = [
  '$scope','$stateParams', 'companies', '$location', 'pathConstants', '$uibModal', 'HighlightId'
];
manageCompanyCtrl.PazeSize = 50;

angular.module('metabuyer')
  .controller('manageCompanyCtrl', manageCompanyCtrl);
