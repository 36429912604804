/**
 * @name mainMenuCtrl
 *
 * @requires $scope
 * @requires $rootScope
 * @requires waitingOnUserList (Metabuyer service)
 * @requires unreadNotificationList (Metabuyer service)
 *
 *
 * @description
 *
 * The Main Menu Controller handles the the three main components (Waiting on you, Notifications and Profile)
 *
 * The notifications and waiting on you counts and data are stored in the $rootScope
 * as this data is used as part of the main information
 * and is shown in different panels
 *
 * @listener notificationsHaveBeenRead
 * is used to to update the counter in the Tab of the main menu navigation bar
 *
 * @listener newNotifications
 * is used to update the count of the notifications once new notifications have been received
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */
'use strict';

function mainMenuCtrl($scope, $rootScope, unreadNotificationList, $state) {

  var cNotifications = '';

  var cWaitings = '';
  $scope.tabData = [
    {
      heading: 'Waiting on you ' + cWaitings,
      route: 'main.user.mainMenu.waiting-on-you',
      key: 'waiting'
    },
    {
      heading: 'Notifications ' + cNotifications,
      route: 'main.user.mainMenu.notifications',
      key: 'notification'
    },
    {
      heading: 'Settings',
      route: 'main.user.mainMenu.userProfile',
      key: 'settings'
    }
  ];

  $scope.$emit('pageTitleChanged', $rootScope.currentUser.display_name );

  /**
   * removed from the Resolve to make the list updated dynamically
   */
  unreadNotificationList.get(
    {
      offset: 1000
    },
    function (resource) {
      if (!!resource.content) {
        $rootScope.notifications = resource.content;
        $rootScope.$broadcast('newNotifications');
        initialize();
      }
    },
    function (error) {
      if (error.status === 404) {
      }
    }
  );

  function initialize() {
    if(!!$rootScope.notifications && !!$rootScope.notifications.meta && !!$rootScope.notifications.meta.cursor) {
      cNotifications = $rootScope.notifications.meta.cursor.count ?
      '(' + $rootScope.notifications.meta.cursor.count + ')' : '';
    }

    if(!!$rootScope.waitings && !!$rootScope.waitings.meta && !!$rootScope.waitings.meta.cursor) {
      cWaitings = $rootScope.waitings.meta.cursor.count ?
      '(' + $rootScope.waitings.meta.cursor.count + ')' : '';
    }

    $scope.tabData[1].heading = 'Notifications ' + cNotifications;
    $scope.tabData[0].heading = 'Waiting on you ' + cWaitings;

    if(!!$state.current.name) {
      if (!!$state.current.name && $state.current.name.indexOf('userProfile') > -1) {

        if(!!$scope.$parent)
          $scope.$parent.activatedTab = $scope.tabData[2];
        else
          $scope.activatedTab = $scope.tabData[2];

      } else if (!!$state.current.name && $state.current.name.indexOf('notifications') > -1) {

        if(!!$scope.$parent)
          $scope.$parent.activatedTab = $scope.tabData[1];
        else
          $scope.activatedTab = $scope.tabData[1];

      } else {

        if(!!$scope.$parent)
          $scope.$parent.activatedTab = $scope.tabData[0];
        else
          $scope.activatedTab = $scope.tabData[0];

      }
    }

  }



  //Listeners for notifications listing
  $scope.$on('notificationsHaveBeenRead', function (event, count) {
    if (count > 0) {
      $scope.tabData[1].heading = 'Notifications' + ' (' + count + ')';
    } else {
      $scope.tabData[1].heading = 'Notifications';
    }
    if(!!$rootScope.notifications && !!$rootScope.notifications.meta && !!$rootScope.notifications.meta.cursor){
      if(count > 0) {
        $rootScope.notifications.meta.cursor.count = count;
      }else{
        $rootScope.notifications.meta.cursor.count= null;
      }
    }
  });
  $scope.$on('newNotifications', function () {
    if (!!$rootScope.notifications && !!$rootScope.notifications.meta && !!$rootScope.notifications.meta.cursor) {
      var cNotifications = $rootScope.notifications.meta.cursor.count ?
        '(' + $rootScope.notifications.meta.cursor.count + ')' : '';
    }
    $scope.tabData[1].heading = 'Notifications ' + cNotifications;
  });

  //Listener for waiting on you listing
  $scope.$on('newWaitingList', function () {
    if (!!$rootScope.waitings && !!$rootScope.waitings.meta && !!$rootScope.waitings.meta.cursor) {
      var cWaitings = $rootScope.waitings.meta.cursor.count ?
        '(' + $rootScope.waitings.meta.cursor.count + ')' : '';
    }
    $scope.tabData[0].heading = 'Waiting on you ' + cWaitings;
  });

  initialize();

}
mainMenuCtrl.$inject = ['$scope', '$rootScope', 'unreadNotificationList', '$state'];
angular
  .module('metabuyer')
  .controller('mainMenuCtrl', mainMenuCtrl);
