'use strict';

angular.module('metabuyer.services.approval', ['ngResource'])
  .factory('Approvals', function($resource, pathConstants){
    return $resource(
      pathConstants.apiUrls.approvals.single,
      {
        id: '@id'
      }
    );
  })
  .factory('adhocApprover', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.addApprover,
      {
        id: '@id'
      },
      {
        'post': { method: 'POST' }
      }
    );
  })
  .factory('removeAdhocApprover', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.removeAdhoc,
      {
        id: '@id',
        step: '@step',
        positionId: '@positionId'
      },
      {
        'delete': { method: 'DELETE' }
      }
    );
  })
  .factory('approvalPreview', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.draftPreview,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('approvalPreviewByData', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.dataPreview,
      {},
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('multiApprovalActions', function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.approvals.multiApprovalActions,
      {
        action: '@action'
      },
      {
        'post': { method: 'POST' }
      }
    );
  });
