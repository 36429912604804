'use strict';
angular
  .module('metabuyer')
  .directive('receiveGoods',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/receiveGoods/receiveGoodsTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ReceiveGoodsCtrl',
            scope: scope,
            windowClass: 'receive-goods-modal'
          });

        };
        //loading the panel
        scope.showReceiveGoods = function () {
          panel();
        };
      }
    };
  });
