'use strict';

angular.module('metabuyer').constant('pathConstants', (function() {
  var ip = 'BACKEND_IP';

  /**
   * Rollbar variables
   * possible environment value: production, staging, stg, development, local, sit
   * DEVELOPERS MUST USE local on their local machine.
   * Optional: for developers you can change the value of 'verbose' to true to check the Rollbar errors in the console log
   * the Initial values are for development
   */
  var token = 'ROLLBAR_TOKEN';
  var captureUncaught = true;
  var environment = 'local';
  var buildNumber = 'BUILD_NUMBER';
  var verbose = false;

  return {
    environmentUrl: ip,
    RollbarConfiguration:{
      token: token,
      captureUncaught: captureUncaught,
      environment: environment,
      verbose: verbose,
      buildNumber: buildNumber
    },
    baseUrl: '/',
    apiUrls: {
      login: ip + '/api/users/login',
      profile: ip + '/api/users/me',
      po: {
        list: ip + '/api/po',
        single: ip + '/api/po/:id',
        delete: ip + '/api/po/deleted/:id',
        status: ip + '/api/po/:id/:status',
        search: ip + '/api/po/search?example[creator]=:title',
        resend: ip + '/api/po/:id/resend',
        comment: ip + '/api/po/:id/comment/:comment_id',
        listAttachment: ip + '/api/po/:po_id/attach',
        singleAttachment: ip + '/api/po/:po_id/attach/:attachment_id',
        downloadAttachment: ip + '/api:hash',
        hash: ip + '/api/po/:id/pdf',
        pdfHash: ip + '/api:pdf_hash',
        receive: ip + '/api/po/:po_id/receive',
        reverse: ip + '/api/po/:po_id/reverse',
        closeLineItem: ip + '/api/po/:po_id/items/:item_id',
        grn: ip + '/api/grn/:id',
        getGRNPDFHash: ip + '/api/grn/:id/pdf'
      },
      users: {
        list: ip + '/api/users',
        search: ip + '/api/users/search?:text&response_form=users',
        single: ip + '/api/users/:id',
        status: ip + '/api/users/:id/:status',
        listPOStar: ip + '/api/users/:id/stared_pos',
        suppliersSearch: ip + '/api/suppliers/search',
        updatingPassword: ip + '/api/users/pass_reset',
        adminUnlockUser: ip + '/api/users/unlock/:id',
        activeUsers: ip + '/api/users/sessions/:session_id',
        image: ip + '/api/users/:id/image',
        notifications: ip + '/api/users/notifications/:id',
        unreadNotifications: ip + '/api/users/notifications/unread',
        readNotifications: ip + '/api/users/notifications/read',
        countUnreadNotifications: ip + '/api/users/notifications/unread/count',
        forgottenPassword: ip + '/api/users/forgot_password',
        importManager: ip + '/api/users/import_manager',
        importAssignments: ip + '/api/users/import_user_roles',
        exportAssignments: ip + '/api/users/export-user-assignments',
        budgets: ip + '/api/users/budgets',
        pendingApproval: ip + '/api/users/:userId/pending-approval/:companyCode'
      },
      companies: {
        list: ip + '/api/companies',
        single: ip + '/api/companies/:id',
        costCenters: ip + '/api/companies/:id/costcenters',
        accountCode: ip + '/api/companies/:id/account_code',
        currencies: ip + '/api/companies/:id/currencies',
        users: ip + '/api/companies/:id/users',
        activeUsers: ip + '/api/companies/:id/active-users',
        usersInCompanies: ip + '/api/companies/users',
        image: ip + '/api/companies/:id/image',
        search: ip + '/api/companies/search?query=:query',
        referenceCounter: ip + '/api/companies/:companyId/reference_counter/:referenceId/:action',
        referenceCounterSearch: ip + '/api/companies/:companyId/reference_counter/search',
        import: ip + '/api/companies/import',
        expenseTypes: ip + '/api/companies/:id/expense_types',
        costCentersAddress: ip + '/api/companies/:companyId/export-cost-centers'
      },
      costCenters: {
        new: ip + '/api/costcenters/',
        list: ip + '/api/costcenters',
        single: ip + '/api/costcenters/:id',
        import: ip + '/api/costcenters/import'
      },
      expenseTypes: {
        masterList: ip + '/api/expense_types/:id',
        list: ip + '/api/expense_types/list/all',
        search: ip + '/api/expense_types/search/?query=:query',
        import: ip + '/api/expense_types/import'
      },
      currencies: {
        list: ip + '/api/currencies/list/all',
        all: ip + '/api/currencies',
        masterList: ip + '/api/currencies/:id',
        search: ip + '/api/currencies/search/?query=:query',
        import: ip + '/api/currencies/import'
      },
      usersBehalfOf: {
        list: ip + '/api/costcenters/:id/users'
      },
      contactPersons: {
        list: ip + '/api/companies/:id/users',
        contacts: ip + '/api/contact',
        single: ip + '/api/contact/:id'
      },
      companyAddress: {
        single: ip + '/api/address/:company_id'
      },
      comments: {
        listPRNewComment: ip + '/api/prsNew/:referenceId/comment',
        listPOComment: ip + '/api/po/:referenceId/comment',
        listSupplierTagHistory: ip + '/api/supplierTag/:companyCode/comment',
        savePOComment: ip + '/api/po/:referenceId/comment/:comment_id',
        savePRNewComment: ip + '/api/prsNew/:referenceId/comment/:commentId',
        reference: ip + '/api/comments-history/:referenceId',
        listBudgetComment: ip + '/api/budgets/:referenceId/comments',
        saveBudgetComment: ip + '/api/budgets/:referenceId/comments/:commentId',
        single: ip + '/api/comments-history/:commentId'
      },
      attachments: {
        listPRNewAttachment: ip + '/api/prsNew/:pr_id/attachment',
        singlePRNewAttachment: ip + '/api/prsNew/:pr_id/attachment/:attachmentId',
        downloadAttachment: ip + '/api/attachment/download/:hash',
        downloadErrorLog: ip + '/api/attachment/download/log/:hash',
        category: ip + '/api/attachment-category/:id',
        description: ip + '/api/attachment-description/:id/host_class/:host_class/object_id/:object_id',
        field: ip + '/api/attachment-cat/:id',
        file: ip + '/api/attachment',
        detail: ip + '/api/attachment/:id',
        download: ip + '/api/attachment/download/:hash',
        reference: ip + '/api/attachments/:referenceId',
        single: ip + '/api/attachment/:attachmentId'
      },
      starring: {
        listPO: ip + '/api/po/:id/:action'
      },
      catalog: {
        list: ip + '/api/users/:user_id/items/:code',
        search: ip + '/api/users/:user_id/items/:type',
        details: ip + '/api/users/:user_id/items/:item_id/details',
        searching: ip + '/api/users/:user_id/items/search/',
        starringItem: ip + '/api/users/:user_id/items/:item_id/starred'
      },
      items: {
        list: ip + '/api/items/:status',
        delete: ip + '/api/items/:id',
        detail: ip + '/api/items/:id/details',
        import: ip + '/api/items/import',
        changeStatus: ip + '/api/items/:status/:id',
        imagesImport: ip + '/api/items/images/',
        nonCatalog: ip + '/api/users/:id/item',
        manageNonCatalog: ip + '/api/users/:user_id/item/:item_id/:action?itemName=:itemName&pr_cc=:pr_cc',
        itemDetailStarring: ip + '/api/users/:user_id/items/:item_id/:group',
        catalogVersion: ip + '/api/items/:summary_id/history',
        download: ip + '/api/items/:summary_id/attachment/:attachment_id',
        singleItemDetail: ip + '/api/items/:status/:summary_id/details/:import_item_id',
        checkImport: ip + '/api/items/import/validate/:supplier_id/:BU/:catalog_code_id',
        imagesUpload: ip + '/api/users/:user_id/item/:item_id/details/:detail_id/image',
        historyDetails: ip + '/api/items/:summary_id/details/',
        itemDetailsListing: ip + '/api/items/:summary_id/details/:status',
        singleItemDetails: ip + '/api/items/:summary_id/details/:import_item_id/info',
        listCatalogCode: ip + '/api/items/catalog_code/:supplier_id/:BU',
        registerViewedItem: ip + '/api/users/:user_id/viewed-item/:item_id'
      },
      itemsCategories: {
        list: ip + '/api/commodity/list/:code'
      },
      notification: {
        count: ip + '/api/notifications/count',
        subscribe: ip + '/api/notifications/:method',
        subscriptionCheck: ip + '/api/notifications/is_subscribed'
      },
      waitingOnUser: {
        list: ip + '/api/waiting'
      },
      tax: {
        list: ip + '/api/tax/list/all',
        masterList: ip + '/api/tax/:id',
        search: ip + '/api/tax/search/?query=:query',
        import: ip + '/api/tax/import'
      },
      uom: {
        list: ip + '/api/uoms/list/all?query=:query',
        single: ip + '/api/uoms/:id',
        search: ip + '/api/uoms/search/',
        import: ip + '/api/uoms/import'
      },
      suppliers: {
        list: ip + '/api/suppliers',
        listActive: ip + '/api/activeSuppliers',
        draft: ip + '/api/suppliers/draft/:id/:action',
        archives: ip + '/api/suppliers/archives/:id',
        single: ip + '/api/suppliers/:id',
        import: ip + '/api/suppliers/import',
        new: ip + '/api/suppliers',
        tagViaCompany: ip + '/api/supplier_tag/companyCode',
        tagBySupplierCode: ip + '/api/supplier_tag/:supplierCode/suppliercode',
        tagByCompanyCode: ip + '/api/supplier_tag/:companyCode/companycode',
        search: ip + '/api/suppliers/:status',
        status: ip + '/api/suppliers/:id/status/:status',
        singleAttachment: ip + '/api/suppliers/:supplier_id/attach/:attachment_id',
        downloadAttachment: ip + '/api/:hash',
        blackList: ip + '/api/suppliers/:supplier_id/status/2?comment=:comment',
        unBlackList: ip + '/api/suppliers/:supplier_id/status/0?comment=:comment',
        validateBranchDeletion: ip + '/api/suppliers/draft/:id/validate_branch_deletion',
        branches: ip + '/api/suppliers/:id/branches'
      },
      paymentMethods: {
        list: ip + '/api/payment_method/list/all',
        masterList: ip + '/api/payment_method/',
        single: ip + '/api/payment_method/:id',
        search: ip + '/api/payment_method/search/?query=:query',
        import: ip + '/api/payment_method/import'
      },
      BusinessUnits: {
        list: ip + '/api/business_units/',
        single: ip + '/api/business_units/:id',
        import: ip + '/api/business_units/import'
      },
      industry: {
        list: ip + '/api/industries',
        new: ip + '/api/industry',
        single: ip + '/api/industry/:id',
        import: ip + '/api/industry/import'
      },
      paymentTerms: {
        list: ip + '/api/payment_term/list/all',
        masterList: ip + '/api/payment_term/',
        single: ip + '/api/payment_term/:id',
        search: ip + '/api/payment_term/search/?query=:query',
        import: ip + '/api/payment_term/import'
      },
      commodity: {
        list: ip + '/api/commodity?code=:code',
        listChild: ip + '/api/commodity/list/child?code=:code',
        masterList: ip + '/api/commodity',
        single: ip + '/api/commodity/:id',
        search: ip + '/api/commodity/search/?query=:query',
        searchAllLevel: ip + '/api/commodity/list/search?keywords=:keywords',
        import: ip + '/api/commodity/import'
      },
      exchangeRate: {
        single: ip + '/api/companies/:id/exchange_rate/:source_id/:target_id',
        masterList: ip + '/api/companies/:id/exchange_rate/',
        search: ip + '/api/companies/:id/exchange_rate/search/?query=:query',
        rate: ip + '/api/companies/:id/exchange_rate/:rate_id',
        import: ip + '/api/companies/:id/exchange_rate/import'
      },
      globalExchangeRate: {
        import: ip + '/api/companies/global/exchange_rate/import'
      },
      addresses: {
        list: ip + '/api/address',
        single: ip + '/api/address/:id',
        import: ip + '/api/address/import'
      },
      approvals: {
        single: ip + '/api/approval/:id',
        draftPreview: ip + '/api/approval-preview',
        dataPreview: ip + '/api/approval-data-preview',
        status: ip + '/api/approval/:approval_id/action/:status',
        addApprover: ip + '/api/approval/:id/ad-hoc-approver',
        removeAdhoc: ip + '/api/approval/:id/step/:step/position/:positionId',
        multiApprovalActions: ip + '/api/approval/mass-action/:action'
      },
      available: {
        single: ip + '/api/available/:model/:field/:value'
      },
      auditList: {
        get: ip + '/api/audits'
      },
      accountCode: {
        list: ip + '/api/companies/:id/account_code/list/child?code=:code',
        prList: ip + '/api/account_code?company_code=:company_code&cost_center_id=:cost_center_id&parent_code=:parent_code',
        single: ip + '/api/companies/:id/account_code/:accountCode_id',
        search: ip + '/api/companies/:id/account_code/search/?query=:query',
        add: ip + '/api/companies/:id/account_code/',
        import: ip + '/api/companies/:id/account_code/import/'
      },
      image: {
        pr: ip + '/api/items/images/',
        user: ip + '/api'
      },
      permissions: {
        userList: ip + '/api/users/:id/permissions',
        list: ip + '/api/permissions/',
        parameters: ip + '/api/permissions/class/:class'
      },
      roles: {
        userList: ip + '/api/users/:id/roles',
        list: ip + '/api/roles/:id',
        position: ip + '/api/users/:id/:positionId',
        managePermissions: ip + '/api/roles/:id/permissions/:pid',
        listPermissions: ip + '/api/roles/:id/permissions',
        users: ip + '/api/roles/:id/users',
        importUserRoles: ip + '/api/roles/import'
      },
      escalation: {
        add: ip + '/api/users/:id/manager/:mid',
        delete: ip + '/api/users/:id/manager/'
      },
      reports: {
        generate: ip + '/api/reports/:id',
        download: ip + '/api/reports/download/:id',
        checkStatus: ip + '/api/reports/:id/check'
      },
      security: {
        password: ip + '/api/sys_conf/mb_security',
        tenant: ip + '/api/sys_conf/tenant'
      },
      delegation: {
        delegateToAUser: ip + '/api/users/:userId/delegate/:delegateeId',
        massDelegate: ip + '/api/users/:userId/mass-delegate',
        delegatedToAUser: ip + '/api/users/:userId/delegatees',
        delegatedFromAUser: ip + '/api/users/:userId/delegators',
        list: ip + '/api/delegations/:delegationId'
      },
      export: {
        generate: ip + '/api/exports',
        download: ip + '/api/exports/download/:export_hash',
        checkStatus: ip + '/api/exports/:id/check'
      },
      genericSearch: {
        searchBasePath: ip + '/api/search',
        search: ip + '/api/search/:module'
      },
      tenant: {
        image: ip + '/api/tenants/image',
        singleTenantById: ip + '/api/tenants/:id'
      },
      purchaseRequisitions: {
        getSinglePR: ip + '/api/prsNew/:id',
        searchPRs: ip + '/api/prsNew/search',
        createPR: ip + '/api/prsNew/',
        updatePRCompanyInfo: ip + '/api/prsNew/:id/company',
        checkPRCompanyInfo: ip + '/api/prsNew/:id/company-check',
        getAvailableDeliveryAddresses: ip + '/api/companies/:id/delivery-addresses',
        setPRTitle: ip + '/api/prsNew/:id/title',
        setRemarks: ip + '/api/prsNew/:id/remarks',
        setPRContactPerson: ip + '/api/prsNew/:id/contact-person',
        pdfHash: ip + '/api/prsNew/:id/pdf',
        getPRContactPersons: ip + '/api/companies/:id/users',
        getAvailableRequestorsList: ip + '/api/costcenters/:id/users',
        setExpenseType: ip + '/api/prsNew/:id/expense-type',
        setPaymentTerm: ip + '/api/prsNew/:id/payment-term',
        setRequestedBy: ip + '/api/prsNew/:id/requested-by',
        setNeededByDate: ip + '/api/prsNew/:id/needed-by-date',
        lumpSum: ip + '/api/prsNew/:id/lump-sum-discount',
        submitPR: ip + '/api/prsNew/:id/submit',
        withdrawPR: ip + '/api/prsNew/:id/withdraw',
        collaborators: ip + '/api/prsNew/:id/collaborators',
        item: ip + '/api/pr-items/:id/:action',
        starring: ip + '/api/prsNew/:id/star/:action',
        supplierNotes: ip + '/api/prsNew/:id/supplier_note',
        duplicate: ip + '/api/prsNew/:id/duplicate',
        moveToDraft: ip + '/api/prsNew/:id/to_draft',
        nonCatalogImage: ip + '/api/pr-items/:id/noncatalog-details',
        updateNonCatalogImage: ip + '/api/pr-items/:id/non-catalog-image',
        requestEditableCopy: ip + '/api/prsNew/:id/requestEditableCopy',
        submitEditedCopy: ip + '/api/prsNew/:id/submitEditedCopy',
        cancelEditedCopy: ip + '/api/prsNew/:id/cancelEditedCopy',
        resetApproval: ip + '/api/approval/:approval_id/reset-step'
      },
      imports: {
        now: ip + '/api/imports/now/'
      },
      catalogGroups: {
        list: ip + '/api/catalog_group/:groupId',
        assignment: ip + '/api/catalog_group/:groupId/company/:companyId',
        import: ip + '/api/catalog_group/import'
      },
      catalogCode: {
        single: ip + '/api/catalog_code/:id/:action',
        list: ip + '/api/catalog_code/list'
      },
      approvalEngine: {
        position: {
          get: ip + '/api/positions',
          single: ip + '/api/positions/:id',
          export: ip + '/api/positions/export'
        },
        positionAssign: {
          get: ip + '/api/position-assignments',
          single: ip + '/api/position-assignments/:id',
          export: ip + '/api/position-assignments/export'
        },
        approvalTemplate: {
          get: ip + '/api/approval-templates/',
          detail: ip + '/api/approval-templates/:id',
          info: ip + '/api/approval-templates-info',
          export: ip + '/api/approval-templates/export'
        },
        approvalTemplateHistory: {
          get: ip + '/api/approval-history',
          single: ip + '/api/approval-history/:id',
          all: ip + '/api/approval-history-template'
        }
      },
      budget: {
        list: ip + '/api/budgets',
        single: ip + '/api/budgets/:id/:action',
        apportionAllocation: ip + '/api/budget-apportion-allocations'
      },
      pdfPreview: {
        preview: ip + '/api/preview-pdf/:id'
      },
      globalPDFHashDownload: ip + '/api:pdf_hash',
      devInfo: ip + '/api/dev-info'
    }
  }
})());
