'use strict';
/**
 * @name industryDetailCtrl
 * @desc Controller for industry detail
 */
function industryDetailCtrl($scope, $state, toastr, industryDetails, singleIndustry, globalFunc, $rootScope) {

  $scope.industryDetails = industryDetails.data;
  $scope.editIndustry = false;
  $scope.updateChanges = updateChanges;
  $scope.revertUpdate = revertUpdate;
  $scope.cancelUpdate = cancelUpdate;
  $scope.back = back;
  $scope.editableIndustry = editableIndustry;
  $scope.deleteIndustry = deleteIndustry;
  $scope.updateIndustry = updateIndustry;
  $scope.duplicationCheck = duplicationCheck;
  $scope.newIndustryDetails = {};

  function back() {
    window.history.back();
  }

  function editableIndustry() {
    angular.copy($scope.industryDetails, $scope.newIndustryDetails);
    $scope.editIndustry = true;
  }


  $scope.validateInputData = function(){

    if(!$scope.industryDetails.descr || !$scope.industryDetails.descr.length) {
      return false;
    }

    if(!$scope.industryDetails.code || !$scope.industryDetails.code.length) {
      return false;
    }

    return true;
  };

  function updateChanges(){

    $scope.submitted = true;
    if($scope.validateInputData() == false){
      toastr.error("Please fill the required fields before proceeding");
      return;
    }
    updateIndustry();
  }

  function revertUpdate() {
    $state.go('main.user.companiesMgmt.manageIndustries');
  }

  function cancelUpdate() {
    $scope.editIndustry = false;
  }
  function getUpdateData(){
    var update_data = {
      _id: $scope.industryDetails._id
    };

    if(!!$scope.newIndustryDetails.descr){
      update_data.descr = $scope.newIndustryDetails.descr;
    }
    if(!!$scope.newIndustryDetails.code && $scope.newIndustryDetails.code.trim() !== '' &&
      $scope.newIndustryDetails.code !== $scope.industryDetails.code){
      update_data.code = $scope.newIndustryDetails.code;
    }

    return update_data;
  }

  function updateIndustry() {

    singleIndustry.put(
      {
        id: $scope.industryDetails._id
      },
      getUpdateData() ,
      function(){
        toastr.success('The Industry was updated successfully');
        $state.go("main.user.companiesMgmt.manageIndustries");
      },
      function(){
        toastr.error('Failed to update the Industry');
        revertUpdate();
      }
    );
  }

  function deleteIndustry() {
    swal(
      {
        title: "Confirm delete Industry?",
        text: "This Industry will be deleted by you",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1ab394",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function(isConfirm) {
        if(isConfirm){
          singleIndustry.delete(
            {
              id: $scope.industryDetails._id
            },
            function(){
              toastr.success('The Industry was deleted successfully');
              $state.go("main.user.companiesMgmt.manageIndustries");
            },
            function(){
              toastr.error('Failed to delete the Industry');
            }
          );
        }
      }
    );
  }


  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[2],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

industryDetailCtrl.$inject = [
  '$scope', '$state', 'toastr', 'industryDetails', 'singleIndustry', 'globalFunc', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('industryDetailCtrl', industryDetailCtrl);
