'use strict';

function UserPermissions ($rootScope, globalFunc) {

  function validatePermission(profile, permission) {

    if(!!profile && !!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN'))
      return true;

    if (!profile) {
      return false;
    }

    if (!profile.view_perms) {
      return false;
    }

    if (profile.view_perms.length < 1) {
      return false;
    }

    if (!_.isString(permission)) {
      return false;
    }


    return true;
  }

  // get list of companies by permission, domain and profile
  function getCompaniesByPermission(profile, domain, permission) {
    return !!profile.view_perms[domain] && !!profile.view_perms[domain][permission] ?
      profile.view_perms[domain][permission] : [];
  }

  /**
   * types of permissions:
   *
   * C: Create
   * L: List
   * O: Open
   * U: Update
   * D: Delete
   *
   * @param profile
   * @param domain
   * @param permission
   * @param companyCode
   * @returns {boolean}
     */
  //TODO: document and rewrite this section to be clear (Ahmed Saleh 11/2/2016)
  function hasPermission(profile, domain, permission, companyCode) {

    if (!!globalFunc.findInArray(profile.roles, 'name', 'TENANT_SUPER_ADMIN'))
      return true;

    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    if(!!companyCode) {
      if (!!profile.view_perms[domain]) {
        if (permission in profile.view_perms[domain]) {
          if (!!profile.view_perms[domain][permission]) {
            if (!!profile.view_perms[domain][permission][0] && findInCompanyCodes(profile.view_perms[domain][permission], companyCode)) {
              return true;
            }
          }
        }
      }
    }
    else if(!!profile.view_perms[domain]) {
      if (permission in profile.view_perms[domain]) {
        if (!!profile.view_perms[domain][permission]) {
          return true;
        }
      }
    }

    return false;
  }

  function findInCompanyCodes(profileCodes, companyCode){
    if(!!profileCodes && !!companyCode) {
      for (var i in profileCodes) {
        if (!!profileCodes[i] && (profileCodes[i].toLowerCase() === companyCode.toLowerCase())) {
            return true;
        }
      }
    }
    return false;
  }

  //check permission on approval
  function checkApprovalTemplateAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ApprovalTemplate', permission)
  }

  //check permission on attachment
  function checkAttachmentAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Attachment', permission)
  }

  //check permission on company
  function checkCompaniesManagementAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Company', permission)
  }

  //check permission on costcenter
  function checkCostCentersManagementAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'CostCenter', permission)
  }

  //check permission on currency
  function checkCurrenciesAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Currencies', permission)
  }

  //check permission on ExchangeRate
  function checkExchangeRateAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ExchangeRate', permission)
  }

  //check permission on ExpenseType
  function checkExpenseTypeAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ExpenseTypes', permission)
  }

  //check permission on History
  function checkHistoryAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'History', permission)
  }

  //check permission on item
  function checkItemAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Item', permission)
  }

  //check permission on ItemDetail
  function checkItemDetailAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ItemDetail', permission)
  }

  //check permission on item import
  function ImportItemDetail(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ImportItemDetail', permission)
  }

  //check permission on Item import summary
  function ImportItemSummary(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ImportItemSummary', permission)
  }


  //check permission on PO
  function checkPOAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'PO', permission)
  }

  //check permission on PR
  function checkPRAccess(profile, permission, companyCode) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }
    if(permission.toLowerCase() !== 'c' && permission.toLowerCase() !== 'a'){
      companyCode = null;
    }
    return userPermission.hasPermission(profile, 'PR', permission, companyCode)
  }

  //check permission on Permission
  function checkPermissionAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Permission', permission)
  }

  //check permission on Role
  function checkRoleAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Role', permission)
  }

  //check permission on Supplier
  function checkSupplierAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Supplier', permission)
  }

  //check permission on Tax
  function checkTaxesManagementAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Tax', permission)
  }

  //check permission on Tenant
  function checkTenantAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Tenant', permission)
  }

  //check permission on UNSPSC
  function checkUNSPSCAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'UNSPSC', permission)
  }

  //check permission on UOM
  function checkUOMAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'UOM', permission)
  }

  //check permission on Users
  function checkUsersManagementAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Users', permission)
  }

  //check permission on Commodity Codes
  function checkCommodityCodeAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'Commodity', permission)
  }

  //check permission on Payment Terms
  function checkPaymentTermsAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'PaymentTerm', permission)
  }

  //check permission on Payment Methods
  function checkPaymentMethodsAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'PaymentMethod', permission)
  }

  //check permission on Report Logs
  function checkReportLogAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ReportLog', permission)
  }

  //check permission on Report Logs
  function checkSupplierTagAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'SupplierTag', permission)
  }

  //TODO: get the exact permission for exports log rather than use ReportLog's permissions (Justin 4/5/2016)
  //check permission on Exports Log
  function checkExportLogAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'ReportLog', permission)
  }

  //check permission on user sessions
  function checkUserSessionAccess(profile, permission) {
    if (userPermission.validatePermission(profile, permission) === false) {
      return false;
    }

    return userPermission.hasPermission(profile, 'UserSession', permission)
  }

  // Function to check permissions by module
  // todo: remove all the functions above and use this instead
  function checkPermissionsAccess(profile, permission, module) {
    if (userPermission.validatePermission(profile, permission) === false)
      return false;

    return userPermission.hasPermission(profile, module, permission);
  }

  // get list of companies by permission and profile on budget
  function getBudgetCompanies(profile, permission) {

    var companiesWithPermission = userPermission.getCompaniesByPermission(profile, 'Budget', permission);
    var budgetEnabledCompanies = [];

    _.forEach(profile.companies, function(company) {
      if (!!company.budget_setting.enable && companiesWithPermission.includes(company.code)) {
        budgetEnabledCompanies.push(company);
      }
    });

    return budgetEnabledCompanies;
  }

  var userPermission = {};
  userPermission.validatePermission = validatePermission;
  userPermission.getCompaniesByPermission = getCompaniesByPermission;
  userPermission.hasPermission = hasPermission;
  userPermission.checkApprovalTemplateAccess = checkApprovalTemplateAccess;
  userPermission.checkAttachmentAccess = checkAttachmentAccess;
  userPermission.checkCompaniesManagementAccess = checkCompaniesManagementAccess;
  userPermission.checkCostCentersManagementAccess = checkCostCentersManagementAccess;
  userPermission.checkCurrenciesAccess = checkCurrenciesAccess;
  userPermission.checkExchangeRateAccess = checkExchangeRateAccess;
  userPermission.checkExpenseTypeAccess = checkExpenseTypeAccess;
  userPermission.checkHistoryAccess = checkHistoryAccess;
  userPermission.checkItemAccess = checkItemAccess;
  userPermission.checkItemDetailAccess = checkItemDetailAccess;
  userPermission.ImportItemDetail = ImportItemDetail;
  userPermission.ImportItemSummary = ImportItemSummary;
  userPermission.checkPOAccess = checkPOAccess;
  userPermission.checkPRAccess = checkPRAccess;
  userPermission.checkPermissionAccess = checkPermissionAccess;
  userPermission.checkRolesAccess = checkRoleAccess;
  userPermission.checkSupplierAccess = checkSupplierAccess;
  userPermission.checkTaxesManagementAccess = checkTaxesManagementAccess;
  userPermission.checkTenantAccess = checkTenantAccess;
  userPermission.checkUNSPSCAccess = checkUNSPSCAccess;
  userPermission.checkUOMAccess = checkUOMAccess;
  userPermission.checkUsersManagementAccess = checkUsersManagementAccess;
  userPermission.checkPaymentTermsAccess = checkPaymentTermsAccess;
  userPermission.checkPaymentMethodAccess = checkPaymentMethodsAccess;
  userPermission.checkCommodityCodeAccess = checkCommodityCodeAccess;
  userPermission.checkUserSessionAccess = checkUserSessionAccess;
  userPermission.checkReportLogAccess = checkReportLogAccess;
  userPermission.checkExportLogAccess = checkExportLogAccess;
  userPermission.checkPermissionsAccess = checkPermissionsAccess;
  userPermission.checkSupplierTagAccess = checkSupplierTagAccess;
  userPermission.getBudgetCompanies = getBudgetCompanies;

  return userPermission;
}
UserPermissions.$inject = ['$rootScope', 'globalFunc'];
angular
  .module('metabuyer')
  .factory('UserPermissions',UserPermissions);


