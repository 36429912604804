'use strict';

/**
 * @name history directive
 *
 * @requires globalFunc
 *
 * @description
 * Directive to display the history of the module
 *
 * @copyright MetaCloud Sdn. Bhd. &copy; 2016
 */

angular
  .module('metabuyer')
  .directive('history', function (globalFunc, $filter, auditList) {
    return {
      restrict: 'E',
      scope: {
        dependencies: '='
      },
      replace: true,
      templateUrl: 'components/history/history.html',
      controller: function ($scope) {
        $scope.getActionBy = getActionBy;
        $scope.getAction = getAction;
        $scope.prepareHistoryData = prepareHistoryData;

        $scope.embeddedParams = $scope.dependencies.embeddedParams;
        $scope.service = auditList;
        $scope.historyData = [
          {data: [{loading: true}]}
        ];

        /**
         * Gets the value of 'action_by'.
         *
         * @param {Object} history Single history
         * @returns {*}
         */
        function getActionBy(history) {
          if (!!history && !!history.action_by && history.action_by !== 'system') {
            return history.action_by.display_name;
          } else {
            return history.action_by
          }
        }

        /**
         * Get the value for 'action'.
         *
         * @param {Object} history Single history
         * @returns {string}
         */
        function getAction(history) {
          return (!!history && !!history.action) ? history.action : '';
        }

        /**
         * Filter the history data.
         *
         * @param {Object} historyData Multiple histories
         * @returns {Array}
         */
        function prepareHistoryData(historyData) {
          var preparedData = [];

          _.forEach(historyData, function (history) {
            history.created_at = transformCreatedAt(history.created_at);
            history.changes = recursiveTransformDates(history.changes);
            history.changeArray = transformChanges(history.changes);
            preparedData.push(history);
          });

          return preparedData;
        }

        /**
         * Transform the createdAt timestamp to an object of date and time
         *
         * @param {string} createdAt Created at timestamp string
         * @returns {*}
         */
        function transformCreatedAt(createdAt) {
          if (typeof createdAt !== 'object' && !!createdAt) {
            createdAt = createdAt.replace('__timestamp_', '');
            createdAt = {
              'date': $filter('date')(createdAt, 'dd MMM yyyy'),
              'time': $filter('date')(createdAt, 'h:mm a')
            };
          }

          return createdAt;
        }

        /**
         * Recursively iterate through the history data and converts timestamps to date string.
         *
         * @param {Object} history Single history
         * @returns {*}
         */
        function recursiveTransformDates(history) {
          for (var fieldName in history) {
            if (typeof history[fieldName] === 'object') {
              history[fieldName] = recursiveTransformDates(history[fieldName]);
            } else if (typeof history[fieldName] === 'string' && history[fieldName].indexOf('__timestamp_') > -1) {
              history[fieldName] = history[fieldName].replace('__timestamp_', '');
              history[fieldName] = $filter('date')(history[fieldName], 'dd MMM yyyy');
            }
          }

          return history;
        }

        /**
         * Transform the changes subarray to an array of strings.
         *
         * @param {Array} historyChanges Single history's 'changes' array
         * @returns {Array}
         */
        function transformChanges(historyChanges) {
          var transformedChanges = [];
          if(_.isEmpty(historyChanges)) {
            transformedChanges.push('<i>Internal changes</i>');
          } else {
            _.forEach(historyChanges, function (change) {
              var str = change.field + ': ';
              if (!!change.added) {
                str += '<b>Added:</b> ' + change.added.join(', ') + '. ';
              }
              if (!!change.removed) {
                str += '<b>Removed:</b> ' + change.removed.join(', ') + '. ';
              }
              if (!!change.changed) {
                str += buildChangedString(change.changed);
              }
              transformedChanges.push(str);
            });
          }

          return transformedChanges;
        }

        /**
         * Build the log string if the type is "changed".
         *
         * @param {Array} changeArray Changed subarray
         * @returns {string}
         */
        function buildChangedString(changeArray) {
          var str = '<b>Changed</b>';

          if (!_.isEmpty(changeArray)) {
            str += ': ';
            str += changeArray[0] === '' || _.isNull(changeArray[0]) ? '<i>empty</i>' : changeArray[0];
            str += ' => ';
            str += changeArray[1] === '' || _.isNull(changeArray[1]) ? '<i>empty</i>' : changeArray[1];
          } else {
            str += '.';
          }

          return str;
        }

        /**
         * Get the history data.
         */
        function getHistoryData() {
          auditList.get(
            $scope.dependencies.embeddedParams,
            {}, // payload
            function (resource) {
              if (!!resource.content) {
                $scope.historyData = resource.content.data;
                $scope.meta = resource.content.meta;
              }
            },
            function () {
              $scope.historyData = [];
            }
          );
        }

        function initialize() {
          getHistoryData();
          $scope.$broadcast('pagination', {
            data: $scope.historyData,
            meta: $scope.meta
          });
        }

        initialize();
      }
    }
  });
