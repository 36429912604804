'use strict';

/**
 * @name UserNotificationsCtrl
 * @desc Controller for User Notifications with unread and all
 * @subTabs The two subTabs check for unread or all, when passed will filter based on the parameter
 */
function UserNotificationsCtrl($scope, $rootScope, notificationList, notificationListing, $stateParams, toastr, readNotificationList, globalFunc){

  $scope.notifications = notificationListing.data;
  $scope.service = notificationList;
  $scope.meta = notificationListing.meta;

  $scope.getClassName = getClassName;

  $scope.initialize = initialize;
  var unreadNotification;

  if(!!$rootScope.notifications && !!$rootScope.notifications.data){
    unreadNotification = $rootScope.notifications.data;
  }

  if($stateParams.subTab === 'unread' && $scope.notifications.length > 0) {
    var count = $scope.notifications.length;
    if(!!notificationListing.meta && !!notificationListing.meta.cursor && !!notificationListing.meta.cursor.count){
      count = Number(notificationListing.meta.cursor.count) - count;
      var ids = [];
      _.forEach(unreadNotification, function (notif) {
        if (!!notif._id) {
          ids.push(notif._id);
        }
      });
    }
    if (ids.length > 0) {
      readNotificationList.post({
            notification_ids: ids
          }, function () {
            $rootScope.$broadcast('notificationsHaveBeenRead', count);
          }, function () {
            toastr.error("Can't make notification as read");
          });
    }
  }

  /**
   * Function to get the class name at the end of metabuyer\models\
   * @param string
   * @returns {*}
   */
  function getClassName(string){
    var className = string.substring(string.indexOf('Models') + 7);
    // add space to the front of each capital letter
    return className.replace(/([A-Z]+)/g, ' $1').trim();
  }

  /**
   * function to reformat the PR and Suppliers as the object is not consistent
   * The Escalation Part is calculated in the HTML part
   */
  function reformatNotifications() {
    var additionalInfo;
    for (var i in $scope.notifications) {

      additionalInfo = $scope.notifications[i].additional_info;

      if (!!$scope.notifications[i].changeType && !!$scope.notifications[i].class &&
        ($scope.notifications[i].class.toLowerCase() === 'metabuyer\\pr\\pr' ||
        $scope.notifications[i].class.toLowerCase() === 'metabuyer\\models\\pr')) {

        //getting the Reference Number from the PR object
        var prObject = globalFunc.findObjectInArrayWithKeyValue(additionalInfo, 'pr');
        if (!!prObject)
          $scope.notifications[i].prNumber = prObject.reference;
      } else if (!!$scope.notifications[i].changeType && !!$scope.notifications[i].class &&
        $scope.notifications[i].class.toLowerCase() === 'metabuyer\\models\\supplier') {

        var supplier = globalFunc.findObjectInArrayWithKeyValue(additionalInfo, 'supplier');
        if (!!supplier) {
          $scope.notifications[i].supplierDescr = supplier.descr;
          if (!!supplier.parent_id)
            $scope.notifications[i].object_id = supplier.parent_id;
        }
      } else if (!!$scope.notifications[i].changeType && !!$scope.notifications[i].class &&
        $scope.notifications[i].class.toLowerCase() === 'metabuyer\\budget\\models\\budget') {
        var budget = globalFunc.findObjectInArrayWithKeyValue(additionalInfo, 'budget');
        if (!!budget) {
          $scope.notifications[i].budgetNumber = budget.code;
        }
      }
    }
  }

  function initialize(){
    $scope.selectedListTab = 'unread';

    if($stateParams.subTab === 'all'){
      $scope.selectedListTab = 'all';
    } else if($stateParams.subTab === 'unread'){
      $scope.selectedListTab = 'unread';
    }
    reformatNotifications();
  }
  $scope.initialize();
}

UserNotificationsCtrl.$inject = [
  '$scope', '$rootScope', 'notificationList', 'notificationListing', '$stateParams', 'toastr', 'readNotificationList', 'globalFunc'
];

angular
  .module('metabuyer')
  .controller('UserNotificationsCtrl', UserNotificationsCtrl);
