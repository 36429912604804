'use strict';

angular.module('metabuyer.services.approvalEngine.approvalTemplates', ['ngResource'])
  .factory('approvalTemplates', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.get,
        {
          limit: '@limit',
          page: '@page',
          sort: '@sort',
          includes: '@includes',
          filter_groups: '@filter_groups',
          position: '@position'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }])
  .factory('approvalTemplateSingle', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.detail,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' },
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    }])
  .factory('approvalTemplateExport', ['$resource', 'pathConstants',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.approvalEngine.approvalTemplate.export,
        {
          id: '@id'
        },
        {
          'post': { method: 'POST' }
        }
      );
    }])