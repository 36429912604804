'use strict';

angular.module('metabuyer')
  .controller('ReceiveGoodsCtrl', function (
    $scope, $uibModalInstance, Orders, toastr, HighlightId, pathConstants, $http, $filter,$timeout,$state,globalFunc
  ) {
    $scope.next_receive_goods = true;
    $scope.back_receive_goods = false;
    $scope.check_for_all = false;
    $scope.enable_upload_do = true;
    $scope.submit_data = {};
    $scope.do_uploader = {};
    $scope.received_date = null;
    $scope.receive_goods_data = [];
    $scope.reopenStatus = false;
    $scope.copyCommentData = $scope.commentData;
    $scope.do_dates = { do_date: {}};
    $scope.doDatepickerOpened = false;
    $scope.submitted = false;

    $scope.uploadPO = uploadPO;
    $scope.cancel = cancel;
    $scope.backOnReceiveGoods = backOnReceiveGoods;
    $scope.checkAllItemReceived = checkAllItemReceived;
    $scope.doneReceiveGoods = doneReceiveGoods;
    $scope.reopenItem = reopenItem;
    $scope.getClosedDetail = getClosedDetail;
    $scope.openDatePicker = openDatePicker;
    $scope.parseToInt = parseToInt;
    $scope.doCheck = false;
    $scope.dateCheck = false;

    //Variable to capture current date
    var currentDate = new Date();
    $scope.tempcurrentDate = currentDate;
    //Formatting the current date to MMM dd, yyyy
    $scope.formattedCurrentDate = currentDate;

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 1,
      maxDate: new Date
    };

    $scope.datePicker = {
      openDatepicker: function($event,index) {
        if($scope.receive_goods_data[index].datepickerOpened) {
          $event.preventDefault();
          $event.stopPropagation();
        }
        else{
          $scope.receive_goods_data[index].datepickerOpened = true;
        }
      }
    };

    function openDatePicker($event) {
      if($scope.doDatepickerOpened){
        $event.preventDefault();
        $event.stopPropagation();
      }
      else{
        $timeout(function(){
          $scope.doDatepickerOpened = true;
        })
      }
    }

    //next action
    function uploadPO(){
      $scope.next_receive_goods = false;
      $scope.back_receive_goods = true;
    }

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //back action
    function backOnReceiveGoods(){
      $scope.next_receive_goods = true;
      $scope.back_receive_goods = false;
    }

    function parseToInt(number) {
      return Number(number);
    }

    function checkAllItemReceived(){
      if($scope.check_for_all){
        _.forEach($scope.receive_goods_data, function(item, key){
          item.received_amount = Number(item.po_item.remaining_quantity);
          //ensure the item closed flag is removed
          reopenItem(key);
        });
      } else {
        _.forEach($scope.receive_goods_data, function(item){
          item.received_amount = 0;
        });
      }
    }

    $scope.$watch('receive_goods_data', function(value){
      var fill_up_wrong = _.find($scope.receive_goods_data, function(item){
        return item.received_amount != 0 &&
          (item.received_amount > item.po_item.remaining_quantity);
      });

      if(fill_up_wrong != undefined){
        $scope.enable_upload_do = false;
      }
      else{
        var fill_up_right = _.find($scope.receive_goods_data, function(item){
          return (angular.isDefined(item.received_amount && item.received_amount != 0));
        });

        if(fill_up_right != undefined){
          $scope.enable_upload_do = true;
        } else {
          $scope.enable_upload_do = false;
        }
      }

      //enable check for all
      var validateCheckAll = _.find($scope.receive_goods_data, function(item){
        return Number(item.received_amount) + Number(item.po_item.order.delivered) != item.po_item.quantity;
      });

      $scope.check_for_all = (validateCheckAll === undefined);

    }, true);


    function validateFileType(){
      if(angular.isDefined($scope.submit_data.do_file)){
        var extensions = ['application/pdf','image/png','application/msword','image/jpg',
                          'image/jpeg','text/plain'];
        var fileType = $scope.submit_data.do_file.type;
        for(var i = 0; i < extensions.length; i++){
          if(fileType === extensions[i]){
            return true;
          }
        }
        return false;
      }else
      {
        /**
         * no file is uploaded, its not Mandatory so no need to verify
         */
        return true;
      }
    }

    //done action
    function doneReceiveGoods(){
      $scope.submitted = true;

      if(validateFileType() === false){
        toastr.error('Invalid file format! Allowed extensions are: .pdf,.png,.doc,.jpg,.jpeg,.txt');
        $scope.submit_data.do_file = {};
        return;
      }

      if(validateEntries() === false){
        return;
      }


        var fd = new FormData();

        if (angular.isDefined($scope.do_dates.do_date) && angular.isDefined($scope.submit_data.do_number)) {
          var doDate = new Date($scope.do_dates.do_date);
          fd.append('do_date', doDate.getTime());
        }

        if (angular.isDefined($scope.submit_data.do_file)) {
          fd.append('do_file', $scope.submit_data.do_file);
        }

        if (angular.isDefined($scope.submit_data.do_number)) {
          fd.append('do_number', $scope.submit_data.do_number);
        }

        if (!$scope.check_for_all) {
          _.forEach($scope.receive_goods_data, function (item) {

            if (item.received_amount && (item.received_amount <= item.po_item.remaining_quantity)) {
              fd.append('items[' + item.POIndex + ']', item.received_amount);
            }

          });
        } else {
          fd.append('items', 'all');
        }

        _.forEach($scope.receive_goods_data, function (item) {
          if (item.received_amount && (item.received_amount <= item.po_item.remaining_quantity)) {
            var receivedDate = new Date(item.received_date);
            fd.append('items_dates[' + item.POIndex + ']', receivedDate.getTime());
          }
        });

      if (angular.isDefined($scope.submit_data.comment)) {
          fd.append('comment', $scope.submit_data.comment);
        }



        $http.post($filter('format')(pathConstants.apiUrls.po.receive, {po_id: $scope.po._id}), fd, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        })
          .then(function onSuccess () {
            toastr.success('DO has been submitted');
            /**
             * Loops for each receive goods item
             */
            for(var i in $scope.receive_goods_data) {
              /**
               * Check if the PO is still partially delivered, break out of loop if true
               */
              var totalAmountReceived = math.subtract(math.bignumber($scope.receive_goods_data[i].received_amount),
                math.bignumber($scope.receive_goods_data[i].po_item.order.delivered));
              totalAmountReceived = math.format(totalAmountReceived, {precision: 64});
              if (totalAmountReceived < Number($scope.receive_goods_data[i].po_item.quantity)) {
                /**
                 * If PO is still partially delivered, state transition to awaiting delivery
                 */
                HighlightId.setId($scope.po._id);
                $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
                $uibModalInstance.close();
                return false;
              }
            }
            $uibModalInstance.close();
            HighlightId.setId($scope.po._id);
            $state.go('main.user.orders.manage', {status: 'CLOSED'});
          })
          .catch(function onError (error) {
            if(!!error.data.content && angular.isDefined(error.data.content.error) && !!error.data.content.error) {
              if(typeof error.data.content.error === 'string') {
                var errorstr = error.data.content.error.toLowerCase();
                if (errorstr.indexOf('do number') > -1 && errorstr.indexOf('used') > -1) {
                  $scope.doCheck = true;
                  $scope.backupDONumber = angular.copy($scope.submit_data.do_number);
                }

                if (errorstr.indexOf('date') > -1)
                  $scope.dateCheck = true;

                toastr.error(error.data.content.error);

              }
              else
                globalFunc.objectErrorMessage(error);
            }
            else{
              toastr.error('Failed to submit DO');
            }
          });
    }

    function reopenItem(index) {
      $scope.receive_goods_data[index].po_item.closed = false;
    }

    function getClosedDetail(id) {
      if(typeof id !== undefined || typeof id !== null){
        var closedItemComment = _.find($scope.copyCommentData, function(comment){
          return comment._id == id;
        });

        if(typeof closedItemComment !== undefined || typeof closedItemComment !== null) {
          if(_.has(closedItemComment, 'updated_at')){
            var _date = $filter('date')(new Date(closedItemComment.updated_at), 'dd MMM yyyy');
            return _date + ', ' + 'by: ' + closedItemComment.commentator.data.display_name;
          }
        } else
          return '';
      }
      return '';
    }

    function initialize(){
      _.forEach($scope.po.items, function(item, i){
        item.remaining_quantity = math.subtract(math.bignumber(item.quantity), math.bignumber(item.order.delivered));
        item.remaining_quantity = math.format(item.remaining_quantity, {precision: 64});

        if(item.remaining_quantity <= 0)
          return;

        $scope.received_date = $scope.formattedCurrentDate;
        $scope.do_dates.do_date = $scope.formattedCurrentDate;

        $scope.receive_goods_data.push(
          {
            po_item: item,
            received_amount: 0,
            received_date: $scope.received_date,
            POIndex: i
          }
        );
      });
    }

    /**
     * function to validation the Amount existed as well as the if the fraction is valid for a specific UOM
     * @param amount
     * @param uom
     * @returns {boolean}
       */
    function validateAmountAndFraction(amount, uom) {
      if(!amount){
        toastr.error('please add amount of good received');
        return false;
      }
      if (!uom.is_fraction || uom.is_fraction === 0) {
        if (amount % 1 !== 0) {
          toastr.error('Unit of Measurement does not allow amount in fraction');
          return false;
        }
      }
      return true;
    }

    /**
     * Loop on all GRN and validate each GRN entry
     * @returns {boolean}
     */
    function validateEntries() {

      if (!$scope.submit_data.do_number || !$scope.submit_data.do_number.length) {
        return false;
      }

      if (!$scope.do_dates.do_date) {
        return false;
      }

      if ($scope.receive_goods_data.length === 1 && Number($scope.receive_goods_data[0].received_amount) === 0)
        return false;

      for (var i in $scope.receive_goods_data) {
        if (Number($scope.receive_goods_data[i].received_amount) !== 0)
          return validateAmountAndFraction(
            $scope.receive_goods_data[i].received_amount,
            $scope.receive_goods_data[i].po_item.uom
          );
      }
    }

    initialize();

  });

