'use strict';
/**
 * @name requisitionsDetailsCtrl
 *
 * @description
 *
 * Controller for the requisitions details state
 *
 *
 * @authors Justin <justin.cty90@gmail.com> Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */

function requisitionsDetailsCtrl(
  $scope, purchaseRequisition, metabuyerCache, $rootScope, $state, companyCurrencies, PRNewComments, prFunction,
  approvalPreview, globalFunc, profile, Approvals, searchModule, singleCommentsHistory, NewPRFirstLoad, PRNewAttachments,
  $filter, pathConstants
) {

  $scope.PR                           = purchaseRequisition.data;
  $scope.editingPRState               = 'editable';
  $scope.commentService               = PRNewComments;
  $scope.deleteService                = singleCommentsHistory;
  $scope.singleAttachmentService      = PRNewAttachments;
  $scope.attachmentParam              = {
    pr_id: prFunction.getPRData()._id,
    attachmentId: null
  };
  $scope.attachmentUrl                = $filter('format')(pathConstants.apiUrls.attachments.listPRNewAttachment,
                                        {pr_id: prFunction.getPRData()._id});
  $scope.waiting_on_user              = [];
  $scope.approvals                    = {};
  $scope.currentUser                  = $rootScope.currentUser || {_id:null};
  $scope.prAttachments                = [];
  $scope.isFirstCreate                = NewPRFirstLoad.isFirstLoad;
  $scope.profile                      = profile;

  $scope.getUpdatePRField             = getUpdatePRField;
  $scope.isShoppingMode               = isShoppingMode;
  $scope.back                         = back;
  $scope.getContactPerson             = getContactPerson;
  $scope.approvalsPreview             = approvalsPreview;
  $scope.checkWaitingOn               = checkWaitingOn;
  $scope.getEditingPRState            = getEditingPRState;
  $scope.isCurrentUserIsPAInApproval  = isCurrentUserIsPAInApproval;

  /**
   * this function will check the state of the PR
   * as well as the Type of the logged in user
   */
  function checkIfPRIsEditable(){
    prFunction.setEditableState(($scope.PR.status === 'draft') ? 'editable' : 'notEditable');
    if ($scope.currentUser._id !== $scope.PR.creator._id)
      prFunction.setEditableState('notEditable');
  }

  /**
   * function returns checker for the states to hide or show the PR details
   * @returns {*|boolean}
   */
  function isShoppingMode() {
    $scope.PR = prFunction.getPRData();
    return $state.includes('main.user.purchaseRequisitions.details.catalog');
  }

  function back () {

    var prStatus = $scope.PR.status;
    if($scope.PR.status === 'on_hold'){
      prStatus = 'pending';
    }

    $state.go('main.user.purchaseRequisitions.manage', {
      status: prStatus,
      tab: 'waiting',
      extraFilterKey: prStatus === 'pending' ? 'criteria[waiting_on][]' : null,
      extraFilterValue: prStatus === 'pending' ? $rootScope.currentUser._id : null,
      cursor: null
    });
  }

  function getUpdatePRField() {
    return prFunction.getLoading('updatePRField');
  }

  /**
   * Load All available costCenters fro the user profile and cache them
   * @returns {Array}
   */
  function loadCostCenters() {
    var allCostCenters = [];
    if (metabuyerCache.get('userCostCenters')) {
      allCostCenters = metabuyerCache.get('userCostCenters');
    } else {
      allCostCenters = $rootScope.currentUser.costCenters;
      metabuyerCache.put('userCostCenters', allCostCenters);
    }
    return allCostCenters;
  }

  /**
   * refine the list of Cost Centers to the ones available within the Company of the PR
   * the cost centers object in the User only holds the company Code, not ID, so the matching is using the unique code of the Company
   * @param allCostCenters
   * @returns {Array}
   */
  function loadSelectableCostCenters(allCostCenters) {
    var costCenters = [];

    angular.forEach(allCostCenters, function (CC) {
      if (!!$scope.PR && !!$scope.PR.company &&
        (CC.company_code === $scope.PR.company.code)) {
        costCenters.push(CC);
      }
    });

    return costCenters;
  }

  function loadApprovalFlow() {
    if ($scope.PR.status !== 'draft') {
      Approvals.get({
        id: $scope.PR.approval_id
      }, function(resource){
        if(!!resource && !!resource.content && !!resource.content.data){
          $scope.approvals = resource.content.data;
          $scope.approvalFlowData = resource.content.data;
          globalFunc.getWaitingOnUser(resource.content.data, $scope.waiting_on_user);
          prFunction.setApprovalFlow(resource.content.data);
        }
        else
          $scope.approvals = [];
      }, function (){
        $scope.approvals = [];
      });
    }
  }

  /**
   * Loading the Currencies options for the Company of the PR to select from
   * @param company
   */
  function loadCurrencies(company) {

    if (!!company._id) {
      companyCurrencies.get({
          id: company._id
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            prFunction.setCurrenciesData(resource.content.data);
          }
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
    }
  }

  /**
   * get the approval flow to display it in draft
   */
  function approvalsPreview(){
    approvalPreview.get({
      company_code: $scope.PR.company.code,
      context_type: 'Metabuyer\\PR\\PR',
      context_id: $scope.PR._id
    }, function(resource){
      // send to action directive
      $scope.approvalFlowData = resource.content.data;
      $scope.approvals = resource.content.data;
      prFunction.setLoading('updatePRField', false);
    }, function(error){
      globalFunc.objectErrorMessage('Failed to preview the approval flow');
      globalFunc.objectErrorMessage(error);
      prFunction.setLoading('updatePRField', true);
    });
  }

  function loadTax() {
    var localStorageTaxes = JSON.parse(window.localStorage.getItem('taxes'));
    if (!localStorageTaxes) {
      searchModule.get(
        {
          module: 'tax',
          'criteria[is_active]': 1,
          offset: 0
        },
        function (resource) {
          if (!!resource.content && !!resource.content.data) {
            window.localStorage.setItem('taxes', JSON.stringify(resource.content.data));
            prFunction.setTaxData(resource.content.data);
          }
        }
      );
    }
    else
      prFunction.setTaxData(localStorageTaxes);
  }

  /**
   * check the waiting on value
   * @returns {boolean}
   */
  function checkWaitingOn() {
    var find_user = _.find($scope.waiting_on_user, function (approver) {
      return (approver._id === profile._id);
    });

    return (find_user !== undefined);
  }

  /**
   * get the contact persons from service
   * the contactPerson setter is in the header directive
   * @returns {*}
   */
  function getContactPerson() {
    return prFunction.getContactPerson();
  }

  function getPRAttachment() {
    PRNewAttachments.getListPRAttachment({
      pr_id: prFunction.getPRData()._id
    }, function(resource) {
      if (!!resource && !!resource.content)
        $scope.prAttachments = resource.content.data;
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * get the editable state for
   * @returns {*}
   */
  function getEditingPRState(){
    return prFunction.getEditingState();
  }

  function activateTab(){
    var status = prFunction.getPRData().status.toLowerCase();

    if (status === 'on_hold')
      status = 'pending';

    var tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if(!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);
    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  function isCurrentUserIsPAInApproval() {
    return (globalFunc.checkIfUserHasPARole($rootScope.currentUser));
  }

  function initialize(){

    $state.params.status = $scope.PR.status.toLowerCase();
    if ($scope.PR.status.toLowerCase() === 'on_hold')
      $state.params.status = 'pending';

    $scope.prBudgetId = (!!$scope.PR.budget && !!$scope.PR.budget._id) ? $scope.PR.budget._id : null;

    var tabData = {
      tab: activateTab(),
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
    checkIfPRIsEditable();
    loadApprovalFlow();
    getPRAttachment();

    loadTax();

    var allCostCenters =  loadCostCenters();

    $scope.costCenters = loadSelectableCostCenters(allCostCenters);
    loadCurrencies($scope.PR.company);
    // prepare the list of items in the main PR object to the children objects
    if (!$scope.PR.pr_items)
      $scope.PR.pr_items = [];

    // clean up the error messages
    prFunction.setErrorsOnSubmit([]);
    NewPRFirstLoad.unset();
  }

  initialize();

}
requisitionsDetailsCtrl.$inject = [
  '$scope', 'purchaseRequisition', 'metabuyerCache', '$rootScope', '$state', 'companyCurrencies', 'PRNewComments',
  'prFunction', 'approvalPreview', 'globalFunc', 'profile', 'Approvals', 'searchModule', 'singleCommentsHistory',
  'NewPRFirstLoad', 'PRNewAttachments', '$filter', 'pathConstants'
];

angular
  .module('metabuyer')
  .controller('requisitionsDetailsCtrl', requisitionsDetailsCtrl);
