/**
 * @name embeddedPaginationService
 *
 * @requires $scope
 * @requires $rootScope
 *
 * @description
 *
 * Directive for embedded pagination used without supporting URL
 * This pagination is different from the Pagination service as it depends on loading the data within a different module
 *
 * @listener embeddedPaginationUpdate
 * reinitialize the directive with the new set of data
 *
 * @listener checkPages
 * used to check the state of the page if its a first page or not, in order to reset the pages to first page or not
 * this listener broadcast a message back to the directive using it
 *
 * @Listener addToCountEnd
 *  updates the page end count to provide a single page addition to data without reloading the data
 *
 * $watcher
 * to check if new Data has been sent into the directive in order to refresh the count, as well as initializes it
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */

'use strict';
angular
  .module('metabuyer')
  .directive('embeddedPaginationService',function() {
    return {
      restrict: 'E',
      templateUrl: 'components/pagination/paginationTemplate.html',
      scope: {
        list: '=',
        service: '=',
        type: '=',
        paramsSource: '=?',
        offset: '=?offset',
        meta: '='
      },
      controller: function ($scope, $rootScope) {
        $scope.disablePaginationButtons = false;
        $scope.pageCount = 0;
        $scope.goNextPage = goNextPage;
        $scope.goPreviousPage = goPreviousPage;
        if(!!$scope.meta && !!$scope.meta.cursor) {
          $scope.cursor = $scope.meta.cursor;
        }
        function initialize(){
          //TODO: prepare the data accordingly in case of a support of pages (at this moment only Elements) (Ahmed Saleh)
          if(!!$scope.cursor) {
            $scope.currentPageCode = $scope.cursor.current;
            $scope.nextPageCode = $scope.cursor.next;
            $scope.previousPageCode = $scope.cursor.prev;

            if (!!$scope.cursor.current) {
              $scope.currentCountStart = atob($scope.cursor.current);
            } else {
              $scope.currentCountStart = 1;
            }

            if ($scope.offset === undefined) {
              $scope.offset = $scope.list.length;
            }
            if (!$scope.previousPageCode) {
              $scope.currentCountStart = 1;
            } else {
            }

            if ($scope.cursor.count !== $scope.list.length) {
              $scope.currentCountEnd = $scope.list.length +
                (Number($scope.currentCountStart) !== 1 ? Number($scope.currentCountStart) : 0);
            } else {
              $scope.currentCountEnd = $scope.list.length;
            }
            $scope.totalCount = $scope.cursor.count;

          } else {
            $scope.currentPageCode = null;
            $scope.nextPageCode = null;
            $scope.previousPageCode = null;

            $scope.currentCountStart = 0;
            $scope.currentCountStart = 0;

          }
        }

        function goNextPage(resetCursor) {
          if(!resetCursor) {
            $scope.paramsSource['cursor'] = $scope.nextPageCode;
          }
          else{
            $scope.paramsSource['cursor'] = null;
          }
          $scope.disablePaginationButtons = true;

          $scope.service.get( $scope.paramsSource ,
            function (resource) {
              if (!!resource.content) {
                $scope.list = resource.content.data;
                $scope.disablePaginationButtons = false;
                if (!!resource.content.meta) {
                  if (!!resource.content.meta.cursor) {
                    $scope.meta.cursor = null;
                    $scope.meta.cursor = resource.content.meta.cursor;
                    $scope.pageCount++;
                    $scope.currentCountStart = Number(atob(resource.content.meta.cursor.current)) + 1;
                    $scope.currentCountEnd = $scope.currentCountStart + $scope.list.length - 1;
                    if ($scope.currentCountEnd > $scope.totalCount) {
                      $scope.currentCountEnd = $scope.totalCount;
                    }
                    $scope.currentPageCode = resource.content.meta.cursor.current;
                    $scope.nextPageCode = resource.content.meta.cursor.next;
                    $scope.previousPageCode = resource.content.meta.cursor.prev;
                  }
                }
                $rootScope.$broadcast('updateEmbeddedPagination', resource.content);
              }
            },
            function () {
              $scope.disablePaginationButtons = false;
            }
          );
        }

        function goPreviousPage() {
          $scope.paramsSource['cursor'] = $scope.previousPageCode;
          $scope.disablePaginationButtons = true;
          $scope.service.get( $scope.paramsSource ,
            function (resource) {
              if (!!resource.content) {
                $scope.list = resource.content.data;
                $scope.disablePaginationButtons = false;
                if (!!resource.content.meta) {
                  if (!!resource.content.meta.cursor) {
                    $scope.pageCount--;
                    $scope.currentCountStart = Number(atob(resource.content.meta.cursor.current)) + 1;
                    $scope.currentCountEnd = $scope.currentCountStart + $scope.list.length - 1;
                    if ($scope.currentCountEnd > $scope.totalCount) {
                      $scope.currentCountEnd = $scope.totalCount;
                    }
                    $scope.currentPageCode = resource.content.meta.cursor.current;
                    $scope.nextPageCode = resource.content.meta.cursor.next;
                    $scope.previousPageCode = resource.content.meta.cursor.prev;
                  }
                }
              }
              $rootScope.$broadcast('updateEmbeddedPagination', resource.content);
            },
            function () {
              $scope.disablePaginationButtons = false;
            }
          );
        }

        /**
         *  Receive the broadcast for pagination
         *  used for promised data
         *  in case of a second page, supporting only meta update
         */
        /**
         * TODO: this is being called by multiple directives concurrently, needs to investigate and come up with a better solution (Ahmed Saleh, 31/10/2016)
         */
        $scope.$on('embeddedPaginationUpdate', function (event, args) {
          if(!!args.data) {
            $scope.list = args.data;
          }
          if(!!args.meta) {
            $scope.cursor = args.meta.cursor;
          }
          $scope.offset = args.offset;
          initialize();
        });
        $scope.$on('checkPages', function () {
          if(!!$scope.meta && !!$scope.meta.cursor && $scope.meta.cursor.prev !== null) {
             goNextPage(true);
          }
          else{
            $rootScope.$broadcast('firstPageIsTrue');
          }
        });

        /**
         *  Receive broadcast for initializing the pagination
         */
        $scope.$on('initializePagination', function(){
          initialize()
        });
        /**
         *  Receive broadcast for updating the end count of a page
         */
        $scope.$on('addToCountEnd', function(){
          $scope.currentCountEnd ++;
        });

        /**
         *  Receive broadcast for get the new list and reset the counts
         */
        $scope.$on('resetPagination', function(event, args){
          if(!!args.data) {
            $scope.list = args.data;
          }
          if(!!args.meta){
            $scope.cursor = args.meta.cursor;
          }
          $scope.offset = args.offset;
          $scope.pageCount = 0;
          initialize();
        });

        $scope.$watch('meta', function(meta){
          if(!!meta) {
            $scope.cursor = meta.cursor;
            /**
             * resetting all the data as its a new set of pagination
             * @type {null}
               */
            $scope.currentCountEnd = null;
            $scope.pageCount = 0;
            $scope.totalCount = null;
            $scope.currentPageCode = null;
            $scope.nextPageCode = null;
            $scope.previousPageCode = null;
          }
          initialize();
        });
      }
    };
  });
