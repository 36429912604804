'use strict';
/**
 * @name companyUsers
 * @desc Controller for users list in companies
 */

function companyUsersCtrl($scope, companyDetails, companyUsers, $uibModal, Users, $state, $rootScope) {
  $scope.companyUsers = companyUsers.data;
  $scope.meta = companyUsers.meta;
  $scope.companyDetails = companyDetails.data;
  $scope.userManageService = Users;
  $scope.viewRoles = viewRoles;
  $scope.viewManagers = viewManagers;
  $scope.viewPositions = viewPositions;
  $scope.viewCostCenter = viewCostCenter;
  $scope.getStatusName = getStatusName;

  $scope.showColumn = showColumn;

  $scope.back = back;

  $scope.storageKey = 'companies-users-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }


  function getStatusName(number){
    /**
     * Pending = 0
     *
     * Active = 1
     *
     * Inactive = 2
     *
     * Rejected = 3
     */
    if(number === 0){
      return 'Pending';
    }
    if(number === 1){
      return 'Active';
    }
    if(number === 2){
      return 'Inactive';
    }
    if(number === 3){
      return 'Rejected';
    }
    return 'N/A';
  }

  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    else
      window.history.back();
  }

  function viewCostCenter(listCostCenters, name) {
    /**
     * formatting the cost centers as the return from the backend is not consistent
     * @type {Array}
     */
    var formattedCCs = [];
    var temp;
    for(var i in listCostCenters) {
      temp = {
        code: listCostCenters[i].code,
        descr: listCostCenters[i].descr
      };
      formattedCCs.push(temp);
    }
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showCostCenters-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showCostCenterCtrl,
      resolve: {
        listCostCenters: function () {
          return formattedCCs;
        },
        company: function () {
          return name;
        }}
    })
  }

  function viewRoles(listRoles, name) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showRoles-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showRolesCtrl,
      resolve: {
        listRoles: function () {
          return listRoles;
        },
        company: function () {
          return name;
        }}
    })
  }

  function viewManagers(listManagers, name) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showManagers-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showManagersCtrl,
      resolve: {
        listManagers: function () {
          return listManagers;
        },
        company: function () {
          return name;
        }}
    })
  }

  function viewPositions(listPositions, name) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showPositions-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showPositionsCtrl,
      resolve: {
        listPositions: function () {
          return listPositions;
        },
        company: function () {
          return name;
        }}
    })
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();

}

companyUsersCtrl.$inject = [
  '$scope', 'companyDetails', 'companyUsers', '$uibModal', 'Users', '$state', '$rootScope'
];

angular.module('metabuyer')
  .controller('companyUsersCtrl', companyUsersCtrl);
