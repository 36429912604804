'use strict';

/**
 * @name editPaymentTermCtrl
 * @desc Controller for master data payment terms details controller
 */
function editPaymentTermCtrl($scope, toastr, paymentTermDetails, singlePaymentTerm,
                             globalFunc, $uibModalInstance) {
  $scope.paymentTermDetails = paymentTermDetails.data;

  $scope.editClicked = false;
  $scope.submitted = false;
  $scope.originalCode = $scope.paymentTermDetails.code;

  $scope.editMode         = editMode;
  $scope.savePaymentTerm  = savePaymentTerm;
  $scope.duplicationCheck = duplicationCheck;
  $scope.cancel           = cancel;
  $scope.toggleTab        = toggleTab;

  $scope.paymentTermStatuses = [
    {isActive: 'Active'},
    {isActive: 'Deactivated'}
  ];

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.paymentTermDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\PaymentTerm',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    $scope.tab = tab;
  }

  function editMode() {
    $scope.editClicked = true;
  }

  function validate(paymentTerm) {
    if (!paymentTerm) {
      return false;
    }
    if (!paymentTerm.code) {
      return false;
    }
    if (!paymentTerm.descr) {
      return false;
    }
    if (!paymentTerm.paymentTermStatus) {
      return false;
    }
    return true;
  }

  function savePaymentTerm() {
    $scope.submitted = true;
    if (!!$scope.codeCheck) {
      toastr.error($scope.paymentTermDetails.code + ' is already in use');
    }
    else if (validate($scope.paymentTermDetails)) {
      swal({
          title: 'Confirm Updating the Payment Term',
          text: 'This will update the details for this Payment Term',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {

            // set status in integer to pass it as parameter
            var tempStatus;
            switch ($scope.paymentTermDetails.paymentTermStatus.isActive.toLowerCase()) {
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            $scope.paymentTermDetails.is_active = $scope.paymentTermDetails.paymentTermStatus.isActive.toUpperCase();
            $scope.paymentTermDetails.updated_at = new Date();

            singlePaymentTerm.put(
              {
                id: $scope.paymentTermDetails._id
              },
              {
                code: $scope.paymentTermDetails.code,
                descr: $scope.paymentTermDetails.descr,
                is_active: tempStatus
              },
              function (resource) {
                toastr.success('Payment term has been updated');
                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model, field, value, checker) {
    globalFunc.duplicationCheck(model, field, value, checker, function (callback) {
      switch (callback) {
        case 'codeCheckTrue':
          if ($scope.paymentTermDetails.code != $scope.originalCode) {
            $scope.codeCheck = true;
          }
          else {
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }



  function initialize() {
    if (!!$scope.paymentTermDetails.is_active) {
      $scope.paymentTermDetails.paymentTermStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.paymentTermDetails.is_active)};
    }
  }

  initialize();
}

editPaymentTermCtrl.$inject = [
  '$scope', 'toastr', 'modalPaymentTerm', 'singlePaymentTerm',
  'globalFunc', '$uibModalInstance'
];

angular
  .module('metabuyer')
  .controller('editPaymentTermCtrl', editPaymentTermCtrl);
