'use strict';

/**
 * @name userProfileCtrl
 * @desc Controller for user profile
 */
function userProfileCtrl($scope, $state, globalFunc, $auth, profile, Account, toastr, pathConstants,
                         $rootScope, ManageLocalStorage, updateProfile) {
  $scope.loading = false;
  $scope.removeCachedData = removeCachedData;
  $scope.selectedEmailFrequency = selectedEmailFrequency;
  $scope.isUploadImageError = isUploadImageError;
  $scope.delegatee = {};

  $scope.emailFrequency = [
    {descr: 'Immediate', value: true, id: 1 },
    {descr: 'End of Day', value: false, id: 2}
  ];

  function selectedEmailFrequency(value){

    swal({
      title: 'Set Email Notification Frequency',
      text: 'Confirm update your email notification frequency?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        updateProfile.put(
          {
            id: $scope.profile._id
          },
          {
            receive_email_immediate: value
          },
          function(){
            toastr.success('Email notification frequency updated');
            profile.receive_email_immediate = value;
          }
        );
      } else {
        if(!!profile.receive_email_immediate)
          $scope.activeRadio = 1;
        else
          $scope.activeRadio = 2;
      }
    });
  }

  function removeCachedData() {
    swal({
      title: 'Clearing Cached Data',
      text: 'Metabuyer makes itself snappy by storing non-sensitive data like Commodity Codes and Preferences in Local Storage of your browser. Confirming this clear the cached data and force Metabuyer to renew it for you.',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        ManageLocalStorage.clearLocalStorage('All');
        toastr.success('Cached data removed');
      }
    });
  }

  function isUploadImageError(file, message, flow){
    var error;
    try{
      error = JSON.parse(message);
    }catch (e){
      error = 'Error occurred while uploading'
    }
    globalFunc.objectErrorMessage(error);
  }

  $scope.isUploadImageSuccess = function (file, message, flow) {
    if (!!message) {
      toastr.success('Image updated successfully');
      loadNewProfileData();
    }
  };

  function loadNewProfileData(){
    Account.getProfile()
      .then(function (data) {
        if ((data.status === 404)) {
          $auth.logout();
          $state.go('login');
        }
        if (!!data && !!data.content && !!data.content.data) {
          $scope.user = data.content.data;
          $rootScope.currentUser = $scope.user;
        }
      })
      .catch(function (error) {
        if (error) {
          console.error(error);
          $auth.logout();
          $state.go('login');
        }
      });
  }

  // handle company & cost center
  $scope.companies = [];
  $scope.handleGroup = function(objects){
    var groups = [];

    for(var index in objects){
      var obj = objects[index];
      var group = undefined;

      for(var i in groups){
        if(obj.company_code === groups[i].code){
          group = groups[i];
          break;
        }
      }

      if(group){
        group.costCenters.push(obj);
      } else {
        groups.push({
          code: obj.company_code,
          name: obj.company_name,
          costCenters: [obj]
        })
      }
    }

    return groups;
  };
  $scope.companies = $scope.handleGroup(profile.costCenters);

  function initialize() {
    $scope.imageBasePath = pathConstants.apiUrls.image.user + '/';
    $scope.profile = _.cloneDeep(profile);

    if (!!($scope.profile) && !_.isUndefined($scope.profile.receive_email_immediate)) {
        if (!!profile.receive_email_immediate){
          $scope.activeRadio = 1;
        }else{
          $scope.activeRadio = 2;
        }
    }

    // config flowjs
    $scope.flowConfig = new Flow({
      target: Account.uploadImagePath($scope.profile._id),
      singleFile: true,
      testChunks:false,
      chunkSize : 10000000,
      headers: {
        'Authorization': 'Bearer ' + $auth.getToken()
      }
    });
  }
  initialize();
}


userProfileCtrl.$inject = [
  '$scope', '$state', 'globalFunc', '$auth', 'profile', 'Account', 'toastr', 'pathConstants',
  '$rootScope', 'ManageLocalStorage', 'updateProfile'
];
angular
  .module('metabuyer')
  .controller('userProfileCtrl', userProfileCtrl);
