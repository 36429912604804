'use strict';

angular.module('metabuyer.services.commentsServices', ['ngResource'])
  .factory('PRNewComments',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.listPRNewComment,
        {
          referenceId: '@referenceId'
        },
        {
          'get': {method: 'GET'},
          post: {
            method: 'POST',
            params: {
              referenceId: '@referenceId'
            },
            url: pathConstants.apiUrls.comments.savePRNewComment
          },
          delete: {
            method: 'DELETE',
            params: {
              referenceId: '@referenceId',
              commentId: '@commentId'
            },
            url: pathConstants.apiUrls.comments.savePRNewComment
          }
        }
      )
    })
  .factory('POComments',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.listPOComment,
        {
          referenceId: '@referenceId'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'},
          delete: {
            method: 'DELETE',
            params: {
              referenceId: '@referenceId',
              comment_id: '@comment_id'
            },
            url: pathConstants.apiUrls.comments.savePOComment
          }
        }
      );
    })
  .factory('supplierTagHistory',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.listSupplierTagHistory,
        {
          companyCode: '@companyCode'
        },
        {
          'get': {method: 'GET'}
        }
      );
    })
  .factory('commentsHistory',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.reference,
        {
          referenceId: '@referenceId'
        },
        {
          'get': {method: 'GET'},
          'post': {method: 'POST'}
        }
      );
    })
  .factory('singleCommentsHistory',
    function ($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.comments.single,
        {
          commentId: '@commentId'
        },
        {
          'put': {method: 'PUT'},
          'delete': {method: 'DELETE'}
        }
      );
    });

