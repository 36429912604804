'use strict';

/**
 * Controller for catalog groups listing
 * @param $scope
 * @param dataList
 * @param $state
 * @param $uibModal
 * @param pathConstants
 */

function manageCatalogGroupCtrl($scope, dataList, $state, $uibModal, pathConstants) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.catalogItemsManageService = dataList;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.importCatalogGroups = importCatalogGroups;
  $scope.newCatalogGroup = newCatalogGroup;
  $scope.viewCatalogGroupDetails = viewCatalogGroupDetails;

  $scope.showColumn = showColumn;

  $scope.storageKey = 'catalog-group-management-selected-columns';

  $scope.importLink = pathConstants.apiUrls.imports.now;

  function showColumn(id){
    var i;
    var columns = $scope.columns;
    for (i in columns) {
      if(columns.hasOwnProperty(i)) {
        if (id === columns[i].id) {
          if(!!columns[i].conditional){
            if($scope.status === columns[i].condition)
              return columns[i].selected;
            if(columns[i].condition instanceof Array){
              var j;
              var columnCondition = $scope.columns[i].condition;
              for(j in columnCondition){
                if(columnCondition.hasOwnProperty(j)) {
                  if($scope.status === columnCondition[j])
                    return columns[i].selected;
                }
              }
            }
          }
          else
            return columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }

  function importCatalogGroups(){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Catalog Group Assignment';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = {
          class: 'Metabuyer\\Models\\CatalogGroup',
          'additional_params[]': ''
        };
      }
    });
  }

  function newCatalogGroup(){
    $state.go('main.user.items.createCatalogGroup');
  }

  function viewCatalogGroupDetails(data){
    $state.go('main.user.items.editCatalogGroup', {id: data._id});
  }

}

manageCatalogGroupCtrl.$inject = ['$scope', 'dataList', '$state', '$uibModal', 'pathConstants'];

angular.module('metabuyer')
  .controller('manageCatalogGroupCtrl', manageCatalogGroupCtrl);
