/**
 * @name userAssignment
 *
 * @description
 * Directive to assign user to company, cost center, roles, position, and superior
 *
 * @param setting
 * Setting is for multiselect setting.
 * Using multiselect inside a custom directive needs to define the setting in controller level
 *
 * @author Deniel Ariesta
 * @copyright Sunway Metacloud &copy; 2016
 */

'use strict';

angular.module('metabuyer')
  .directive('userAssignment', function (
    companyCostCenters, globalFunc, $q, rolesList, searchModule, userPendingApproval, $uibModal
  ) {
    return {
      restrict: 'E',
      templateUrl: 'components/userAssignment/userAssignmentTemplate.html',
      scope: {
        userId: '=?',
        selectedCompanies: '=',
        selectedCostCenters: '=?',
        companies: '=',
        selectedRoles: '=?',
        selectedSuperiors: '=?',
        selectedPositions: '=?',
        saveDisabled: '='
      },
      controller: function ($scope) {

        $scope.query                        = '';
        $scope.companies                    = $scope.companies || [];
        $scope.selectedCompanies            = $scope.selectedCompanies || [];
        $scope.selectedCostCenters          = $scope.selectedCostCenters || [];
        $scope.selectedCompaniesForListing  = [];
        $scope.usersSuperior                = [];
        $scope.loading                      = false;
        $scope.promisesCount                = 0;
        $scope.multiselectTranslation       = {
          selectAll       : 'Select all',
          selectNone      : 'Select none',
          reset           : 'Reset',
          search          : 'Type here to search...',
          nothingSelected : 'Select'
        };

        $scope.updateQuery              = updateQuery;
        $scope.removeCompany            = removeCompany;
        $scope.onSelectCompany          = onSelectCompany;
        $scope.userId                   = $scope.userId || null;
        $scope.checkPendingApproval     = checkPendingApproval;

        /**
         * run when selecting company
         * @param company
         * @param autoFill for initializing the data
         */
        function onSelectCompany(company, autoFill) {
          if (!!company.ticked) {
            $scope.loading = true;
            ++$scope.promisesCount;
            var tempCompany = angular.copy(company);
            var promises = {
              costCenters: getCompanyCostCenters(tempCompany._id),
              roles: getCompanyRoles(tempCompany.code),
              superior: getCompanyUsers(tempCompany.code)
            };

            // Load promises data
            $q.all(promises).then(
              function (resource) {
                tempCompany.CCs.list = resource.costCenters;
                tempCompany.roles.list = filterArray(resource.roles, 'type', 'unequal', 'ORG_ROLE');
                tempCompany.positions.list = filterArray(resource.roles, 'type', 'equal', 'ORG_ROLE');
                tempCompany.superior.list = resource.superior;

              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            ).catch().finally(function () {

              if(autoFill){
                assignSelectedInfo(tempCompany);
              }

              $scope.selectedCompanies.push(tempCompany);
              // wait for promises to finish load then only insert loaded data
              --$scope.promisesCount;
              if ($scope.promisesCount === 0) {
                $scope.loading = false;
                $scope.saveDisabled = false;
              }

            });
          } else if (!company.ticked) {
            removeCompany(company._id);
          }
        }

        /**
         * Filter Roles based on the type
         * @param array
         * @param key
         * @param operation
         * @param roleType
         */
        function filterArray(array, key, operation, roleType) {
          var tempArr = [];
          _.forEach(array, function (arr) {
            if (operation === 'equal') {
              if (arr[key] === roleType)
                tempArr.push(arr);
            } else {
              if (arr[key] !== roleType)
                tempArr.push(arr);
            }

          });
          return tempArr;
        }

        /**
         * added system to the companies list
         */
        function addSystemToCompanies() {
          var system = {
            _id: 'system',
            code: 'system',
            descr: 'System'
          };
          $scope.companies.unshift(system);
        }

        function addCompaniesInitVal(companies) {
          _.forEach(companies, function (company) {
            // Cost Center init value
            company.CCs = {
              list: [],
              selected: []
            };

            // Roles init value
            company.roles = {
              list: [],
              selected: []
            };

            // Position init value
            company.positions = {
              list: [],
              selected: []
            };

            // Superior init value
            company.superior = {
              list: []
            };
          })
        }

        /**
         * Check all pending approval flow
         * @param {string} companyId
         * @param {string} companyCode
         */
        function checkPendingApproval(companyId, companyCode) {
          userPendingApproval.get(
            {
              userId: $scope.userId,
              companyCode: companyCode
            }, function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data &&
                resource.content.data.length > 0) {
                $uibModal.open({
                  templateUrl: 'components/userAssignment/pendingApprovalList.html',
                  backdrop: 'static',
                  keyboard: false,
                  resolve: {
                    approvalFlows: function () {
                      return resource.content.data;
                    }
                  },
                  controller: function ($scope, approvalFlows, $uibModalInstance) {
                    $scope.cancel = cancel;
                    $scope.approvalFlows = approvalFlows;

                    function cancel() {
                      $uibModalInstance.close();
                    }
                  }
                });
              } else {
                removeCompany(companyId, companyCode);
              }
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        /**
         * remove company manually from the list
         * @param companyId
         * @param {string} companyCode Company code
         */
        function removeCompany(companyId, companyCode) {
          _.forEach($scope.companies, function (company) {
            if (company._id === companyId) {
              company.ticked = false;

              // remove from selected companies in listing
              $scope.selectedCompaniesForListing = globalFunc.removeValueFromArray(
                $scope.selectedCompaniesForListing, '_id', companyId
              );

              // remove from selected companies in selected
              $scope.selectedCompanies = globalFunc.removeValueFromArray(
                $scope.selectedCompanies, '_id', companyId
              );
            }
          });
        }

        /**
         * To update the query value manually
         * @param query
         */
        function updateQuery(query) {
          $scope.query = query;
        }

        /**
         * Retrieve company's cost centers
         * @param companyId
         * @returns {Array}
         */
        function getCompanyCostCenters(companyId) {
          if (!!companyId && companyId !== 'system') {
            var deferred = $q.defer();
            companyCostCenters.get({
              id: companyId
            }, function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data) {
                deferred.resolve(resource.content.data);
              }
            }, function () {
              deferred.resolve([]);
            });
            return deferred.promise;
          }
        }

        /**
         * get company's Roles and Position
         * @param companyCode
         * @returns {Array}
         */
        function getCompanyRoles(companyCode) {
          var deferred = $q.defer();
          var roles = [];
          if (!!companyCode) {
            rolesList.get(
              {
                company_code: companyCode,
                offset: 0
              },
              function (companyRoles) {
                if (!!companyRoles && !!companyRoles.content && !!companyRoles.content.data) {
                  roles = companyRoles.content.data;
                  deferred.resolve(roles);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
          return deferred.promise;
        }

        /**
         * get company's users
         * @param companyCode
         * @returns {Array}
         */
        function getCompanyUsers(companyCode) {
          if (!!companyCode && companyCode !== 'system') {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'users',
                'criteria[companies][]': companyCode,
                'criteria[status][]': 1, // Get only user with active status
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          }
        }

        function assignSelectedInfo(company) {

            if(!!$scope.selectedCostCenters) {
              _.forEach($scope.selectedCostCenters, function (CC) {
                var temp = globalFunc.findInArray(company.CCs.list, '_id', CC._id);
                if (!!temp) {
                  company.CCs.selected.push(temp);
                }
              });
            }

            if(!!$scope.selectedRoles) {
              _.forEach($scope.selectedRoles, function (role) {
                var temp = globalFunc.findInArray(company.roles.list, '_id', role._id);
                if (!!temp) {
                  company.roles.selected.push(temp);
                }
              });
            }

            if(!!$scope.selectedSuperiors) {
              _.forEach($scope.selectedSuperiors, function (superior) {
                if(superior.company_code === company.code) {
                  var temp = globalFunc.findInArray(company.superior.list, '_id', superior._id);
                  if (!!temp) {
                    company.superior.selected = temp;
                  }
                }
              });
            }


            if(!!$scope.selectedPositions) {
              _.forEach($scope.selectedPositions, function (position) {
                var temp = globalFunc.findInArray(company.positions.list, '_id', position._id);
                if (!!temp) {
                  company.positions.selected.push(temp);
                }
              });
            }
        }

        function initialize() {
          addSystemToCompanies();
          addCompaniesInitVal($scope.companies);

          //prepare the list and assigning the data
          if(!!$scope.selectedCompanies){
            var temp = _.cloneDeep($scope.selectedCompanies);
            $scope.selectedCompanies = [];
            _.forEach(temp, function(tempCompany){
              var company = globalFunc.findInArray($scope.companies, '_id', tempCompany._id);
              company.ticked = true;
              tempCompany.ticked = true;
              addCompaniesInitVal([tempCompany]);
              onSelectCompany(tempCompany, true);
            });

          }
        }

        initialize();
      }
    }
  });
