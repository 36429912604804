'use strict';

/**
 * @name exportTemplateController
 * @desc Controller for approval flow export
 */
function exportTemplateController (
  $scope, companies, $uibModalInstance, toastr, approvalTemplateExport, globalFunc, pathConstants, $http, $filter
) {

  $scope.companies = companies;
  $scope.template = {};
  $scope.templateTypes = [
    {
      code: 'PR',
      descr: 'PR'
    },
    {
      code: 'Supplier',
      descr: 'Supplier'
    },
    {
      code: 'Catalog',
      descr: 'Catalog'
    },
    {
      code: 'Budget',
      descr: 'Budget'
    }
  ];
  $scope.modulesWithCompany = ['PR', 'Budget'];

  $scope.close = close;
  $scope.exportTemplate = exportTemplate;

  function close() {
    $uibModalInstance.close();
  }

  function exportTemplate() {
    if (exportValidation()) {
      var params = {
        module: $scope.template.type.code,
        only_active: (!!$scope.template.onlyActive) ? 1 : 0
      };

      if ($scope.modulesWithCompany.indexOf($scope.template.type.code) > -1) {
        params.company_code = $scope.template.company.code;
      }

      $http({
        method: 'POST',
        url: pathConstants.apiUrls.approvalEngine.approvalTemplate.export,
        params: params
      }).then(function (response) {
        var blob = new Blob([response.data]);
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Template-' +
          $scope.template.type.code +
          (!!$scope.template.company && !!$scope.template.company.code ? '-' + $scope.template.company.code + '-' : '') +
          $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
        link.click();
        toastr.success('Template file has been successfully generated.');
      }, function () {
        toastr.error('Failed to generate template file.');
      });
    }
  }

  function exportValidation() {
    if (!!$scope.template) {
      if (!$scope.template.type || !$scope.template.type.code) {
        toastr.error('The type field is required');
        return false;
      }

      if (($scope.modulesWithCompany.indexOf($scope.template.type.code) > -1) &&
        (!$scope.template.company || !$scope.template.company.code)) {
        toastr.error('The company field is required');
        return false;
      }
    }

    return true;
  }
}

exportTemplateController.$inject = [
  '$scope', 'companies', '$uibModalInstance', 'toastr', 'approvalTemplateExport', 'globalFunc', 'pathConstants',
  '$http', '$filter'
];

angular
  .module('metabuyer')
  .controller('exportTemplateController', exportTemplateController);
