'use strict';

angular.module('metabuyer.services.commodity', ['ngResource'])
/**
 * Factory for commodity listing
 * This endpoint returns list with offset
 */
  .factory('commodityList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.commodity.list,
      {
        code: '@code'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })

/**
 * Factory for listing child, can list segments by passing code = 'All'
 * This endpoint returns list for nonCatalog and supplier's primary commodity
 */
  .factory('listChild',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.commodity.listChild,
      {
        code: '@code'
      },
      {
        'get': { method: 'GET' }
      }
    );
  })
  .factory('commodityMasterDataList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.commodity.masterList,
      {

      },
      {
        'get': { method: 'GET' },
        'post': {method: 'POST'}
      }
    );
  })
  .factory('searchCommodity',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.commodity.search,
      {
        query: '@query'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('singleCommodity',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.commodity.single,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'put': {method: 'PUT'},
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('searchAllCommodity',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.commodity.searchAllLevel,
        {
          keywords: '@keywords'
        },
        {
          'get': {method: 'GET'}
        }
      );
    }
  );
