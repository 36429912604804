'use strict';

angular.module('metabuyer.services.roles', ['ngResource'])
  .factory('usersRoles',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.roles.userList,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('rolesList',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.roles.list,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' },
          'post': { method: 'POST' },
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('userPosition',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.roles.position,
        {
          id: '@id',
          positionId: '@positionId'
        },
        {
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('getPermissionsListForRoles',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.roles.listPermissions,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  )
  .factory('manageSinglePermissionForRoles',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.roles.managePermissions,
        {
          id: '@id',
          pid: '@pid'
        },
        {
          'post': { method: 'POST' },
          'put': { method: 'PUT' },
          'delete': { method: 'DELETE' }
        }
      );
    }
  )
  .factory('roleUsers',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.roles.users,
        {
          id: '@id'
        },
        {
          'get': { method: 'GET' }
        }
      );
    }
  );
