'use strict';

/**
 * @name prFunction
 *
 * @requires $state
 *
 * @description Service for PR functions
 * This service is for functions that are re-used in PR modules, to minimize redundancy
 *
 * @function validateLumpSum
 * This function will return a message in case there is an error in the lump sum,
 * then the controllers will remove that from their respectively
 *
 * @authors Ahmed Saleh
 * @copyright Sunway Metacloud &copy; 2016
 */

function prFunction(purchaseRequisitionsServices, $q, prStarring, lang, globalFunc, $rootScope) {

  var globalPRData = {};
  var loading = {};
  var contactPerson = [];
  var mentionUsers = [];
  var approvalFlow = {};
  var taxes = [];
  var submitErrors = [];
  var costCenterDeliveryAddresses = [];
  var accountCodes = [];
  var currencyList = [];
  var PREditableState = 'editable';
  var isShoppingMode = false;
  var itemErrorsFound = false;

  return {
    initializePRData: function (pr) {
      this.clearPRData();
      this.setPRData(pr);
    },
    clearPRData: function() {
      globalPRData = {};
      loading = {};
      contactPerson = [];
      mentionUsers = [];
      approvalFlow = {};
      taxes = [];
      submitErrors = [];
      costCenterDeliveryAddresses = [];
      accountCodes = [];
      currencyList = [];
      PREditableState = 'editable';
      isShoppingMode = false;
      itemErrorsFound = false;
    },
    /**
     * @function validateLumpSum
     * this function will return a message in case there is an error in the lump sum,
     * then the controllers will remove that from their respectively
     *
     * @param newItem
     * new Item that is tested before adding
     * @param index
     * item index
     *
     * @returns {{}}
     */
    validateLumpSum: function (newItem, index) {
      var items = angular.copy(globalPRData.pr_items) || [];
      var prevSupplier = '';
      var prevPaymentTerm = '';
      var prevTax = '';
      var prevDeliveryAddress = '';
      var prevSupplierBranch = '';
      var errorMessage = {
        status: true,
        message: ''
      };

      // adding item temporally to the list to evaluate it
      if (!!newItem && index === -1) {
        if (!newItem.tax && !!newItem.tax_id)
          newItem.tax = {_id: newItem.tax_id};
        items.push(newItem);
      }
      else if (!!newItem && index > -1)
        items[index] = newItem;

      if (!items || (!!items && !items.length))
        return errorMessage;

      if (!!items && !!items.length) {
        _.forEach(items, function (item) {
          // validate supplier
          // check if the supplier exist (non catalog)
          if (!item.supplier) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.notHaving + 'supplier';
            return true;
          }
          else if (!!item && !!item.supplier && !prevSupplier)
            prevSupplier = item.supplier._id;

          // check if the items having the same supplier
          if (item.supplier._id !== prevSupplier) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'supplier';
            return true;
          }

          // validate payment term
          // check if the payment term exist (non catalog)
          if (!item.payment_term) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.notHaving + 'payment term';
            return true;
          }
          else if (!!item && !!item.payment_term && !prevPaymentTerm)
            prevPaymentTerm = item.payment_term._id;

          // check if the items having the same payment term
          if (item.payment_term._id !== prevPaymentTerm) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'payment term';
            return true;
          }

          // validate tax
          // check if the tax exist (non catalog)
          if (!item.payment_term) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.notHaving + 'tax';
            return true;
          }
          else if (!!item && !!item.tax && !prevTax)
            prevTax = item.tax._id;

          // check if the items having the same payment term
          if (!!item.tax && item.tax._id !== prevTax) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'tax';
            return true;
          }

          // set initial delivery address
          if (!!item.delivery_address && !!item.delivery_address._id && !prevDeliveryAddress)
            prevDeliveryAddress = item.delivery_address;

          // check if the items having the same delivery address
          if ((!!item.delivery_address && item.delivery_address._id !== prevDeliveryAddress) ||
            (!item.delivery_address && !!prevDeliveryAddress)) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'delivery address';
            return true;
          }

          // set initial supplier branch
          if (!!item.branch && !!item.branch._id && !prevSupplierBranch) {
            prevSupplierBranch = item.branch._id;
          }

          // check if the items have the same branch
          if ((!!item.branch && item.branch._id !== prevSupplierBranch) ||
            (!item.branch && !!prevSupplierBranch)) {
            errorMessage.status = false;
            errorMessage.message = lang.validation.lumpSum.different + 'supplier branch';
            return true;
          }
        });

        return errorMessage;
      }
    },
    removeLumpSum: function (prId, callback) {
      var self = this;
      self.setLoading('lineItemTable', true);
      self.setLoading('updatePRField', true);
      purchaseRequisitionsServices.setLumpSum({
        id: prId
      }, {}, function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data)
          self.setPRData(resource.content.data);

        self.setLoading('lineItemTable', false);
        callback(true);
      }, function (error) {
        self.setLoading('lineItemTable', false);
        globalFunc.objectErrorMessage(error);
        callback(false);
      });
    },
    /**
     * To set PR data and update to local variable
     * @param pr
     */
    setPRData: function (pr) {
      globalPRData = this.updatePRData(pr);
      $rootScope.$broadcast('PRDataUpdated');
    },

    /**
     * to return the globalPRData value
     * use this service to share data between directives in PR
     * @returns {{}}
     */
    getPRData: function () {
      return globalPRData;
    },

    /**
     * set loading object in PR
     * @param loadingObject
     * @param loadingValue
     */
    setLoading: function (loadingObject, loadingValue) {
      loading[loadingObject] = loadingValue;
    },

    /**
     * get loading data
     * @param loadingObject
     * @returns {{}}
     */
    getLoading: function (loadingObject) {
      return loading[loadingObject];
    },
    setContactPerson: function (contactPersons) {
      contactPerson = contactPersons;
    },
    getContactPerson: function () {
      return contactPerson;
    },
    setApprovalFlow: function (approvalFlowData) {
      approvalFlow = approvalFlowData;
    },
    getApprovalFlow: function () {
      return approvalFlow;
    },
    setTaxData: function (taxData) {
      taxes = taxData;
    },
    getTaxData: function () {
      return taxes;
    },
    setCurrenciesData: function (currencyData) {
      currencyList = currencyData;
    },
    getCurrenciesData: function () {
      return currencyList;
    },
    setErrorsOnSubmit: function (errors) {
      submitErrors = errors;
    },
    getErrorsOnSubmit: function () {
      return submitErrors;
    },
    setCostCenterDeliveryAddresses: function (addresses) {
      costCenterDeliveryAddresses = addresses;
    },
    getCostCenterDeliveryAddresses: function () {
      return costCenterDeliveryAddresses;
    },
    setAccountCodes: function (accountCodesData) {
      accountCodes = accountCodesData;
    },
    getAccountCodes: function () {
      return accountCodes;
    },
    setEditableState: function (state) {
      PREditableState = state;
    },
    getEditingState: function () {
      return PREditableState;
    },
    setShoppingModeStatus: function (mode) {
      isShoppingMode = mode;
    },
    getShoppingModeStatus: function () {
      return isShoppingMode;
    },
    removeItem: function (itemId) {
      return purchaseRequisitionsServices.deleteItem({
        id: itemId
      });
    },
    setItem: function (item, field, param) {
      return purchaseRequisitionsServices.setItem({
        id: item._id,
        action: field
      }, param);
    },
    refreshPRData: function () {
      var deferred = $q.defer();
      purchaseRequisitionsServices.getSinglePR(
        {
          id: this.getPRData()._id
        }, function (resource) {
          deferred.resolve(resource);
        }, function (error) {
          deferred.resolve([]);
        });
      return deferred.promise;
    },
    /**
     * lis the the IDs into an array so that its easy to handle later on
     * @returns {Array}
     */
    getPRItemsIDs: function () {
      var tempArray = [];
      _.forEach(this.getPRData().pr_items, function (item) {
        if (!!item.item_details) {
          tempArray.push(item.item_details._id);
        }
      });
      return tempArray;
    },
    getPRSuppliers: function () {
      var items = angular.copy(globalPRData.pr_items) || [];
      var suppliers = [];
      var newFormat = {};
      _.forEach(items, function (item) {
        if (!!item.supplier) {
          newFormat = {
            id: item.supplier._id,
            label: item.supplier.descr,
            code: item.supplier.code
          };

          if (!globalFunc.findInArray(suppliers, 'id', newFormat.id)) {
            suppliers.push(newFormat);
          }
        }
      });
      return suppliers;
    },
    /**
     * Starring PRs service
     * @param req
     * @param addStar
     * @returns {*|{method, params, url}|{method}}
     */
    requisitionStarring: function (req, addStar) {
      if (addStar) {
        return prStarring.post(
          {
            id: req._id
          }
        );
      } else {
        return prStarring.delete(
          {
            id: req._id
          }
        );
      }
    },
    /**
     * checked for the conditions:
     * 1- Waiting on user
     * 2- is PA
     * 3- is PA System Level
     * @param waitings
     * @param userId
     * @param roles
     * @returns {boolean}
     */
    checkCurrentApproverIsPA: function (waitings, userId, roles) {
      if (!waitings || !_.isArray(waitings) || !userId || !roles || !_.isArray(roles))
        return false;

      if (waitings.indexOf(userId) === -1)
        return false;

      var validRole = false;

      _.forEach(roles, function (role) {
        if (role.name === 'PA' && role.company_code === null) {
          validRole = true;
        }
      });

      return validRole;
    },
    /**
     * To modify pr data for backup and input entry
     * @param pr
     */
    updatePRData: function (pr) {
      _.forEach(pr.pr_items, function (item) {
        // Set discount_entry
        item.discount_entry = item.discount;
        if (!!item.discount_percentage)
          item.discount_entry = item.discount_percentage + '%';

        // set freight entry
        item.freights.entry = item.freights.amount;
        if (!!item.freights.amount_percentage)
          item.freights.entry = item.freights.amount_percentage + '%';

        // set freight discount
        item.freights.discount_entry = item.freights.discount_amount;
        if (!!item.freights.discount_percentage)
          item.freights.discount_entry = item.freights.discount_percentage + '%';

        // set transportation entry
        item.transportation.entry = item.transportation.amount;
        if (!!item.transportation.amount_percentage)
          item.transportation.entry = item.transportation.amount_percentage + '%';

        // set transportation discount
        item.transportation.discount_entry = item.transportation.discount_amount;
        if (!!item.transportation.discount_percentage)
          item.transportation.discount_entry = item.transportation.discount_percentage + '%';

        // set backup value
        item.backup_discount = angular.copy(item.discount_entry);
        item.backup_qty = angular.copy(Number(item.qty));
        item.backup_unit_price = angular.copy(Number(item.unit_price));
        item.backup_needed_by_date = angular.copy(item.needed_by_date);
        item.backup_note = angular.copy(item.note);
        item.backup_tax = angular.copy(item.tax);
        item.backup_freights = angular.copy(item.freights);
        item.backup_transportation = angular.copy(item.transportation);
      });

      // Update Lumpsum discount_entry
      pr.lump_sum_discount_entry = (pr.lump_sum_discount > -1 ? pr.lump_sum_discount : null);
      if (!!pr.lump_sum_discount_percentage)
        pr.lump_sum_discount_entry = pr.lump_sum_discount_percentage + '%';

      pr.backup_lump_sum_discount = angular.copy(pr.lump_sum_discount);

      return pr;
    },
    setDisplayWarningBlock: function (errors) {
      itemErrorsFound = errors;
    },
    getDisplayWarningBlock: function () {
      return itemErrorsFound;
    },
    /**
     * return an array of the catalog group ids within the PR company, by reading the User's companies
     * @returns {Array}
     */
    getCatalogGroup: function () {
      var company = globalFunc.findInArray($rootScope.currentUser.companies, '_id', this.getPRData().company._id);
      var ids = [];
      _.forEach(company.catalog_groups, function(group){
        ids.push(group._id);
      });
      return ids;
    }
  }
}

prFunction.$inject = ['purchaseRequisitionsServices', '$q', 'prStarring', 'lang', 'globalFunc', '$rootScope'];


angular
  .module('metabuyer')
  .factory('prFunction', prFunction);
