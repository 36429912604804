'use strict';

function OrdersManageCtrl($scope, $state, $stateParams, $filter, Orders, orders, $window, HighlightId,
                          pathConstants, $resource, $rootScope) {

  var checkedListItem = [];
  $scope.check = check;
  $scope.checkAll = checkAll;
  $scope.formatPercentage = formatPercentage;
  $scope.changeStringCase = changeStringCase;
  $scope.checkStar = checkStar;

  $scope.showColumn = showColumn;

  $scope.storageKey = 'orders-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * Function to return whether po is starred by current user or not
   * @param order
   * @returns {boolean}
   */
  function checkStar(order) {
    if (order.stared_by !== null) {
      for (var i in order.stared_by) {
        if ($rootScope.currentUser._id === order.stared_by[i]) {
          order.isStarred = true;
        }
      }
    }
  }

  // Trigger if any item is checked. Display action button.
  function check(requisition) {
    checkedListItem.splice(0, checkedListItem.length);
    $scope.showAction = false;
    for (var index = 0; index < $scope.orders.length; ++index) {
      var item = $scope.orders[index];
      if (!item.selected) {
        continue;
      }
      $scope.showAction = true;
      checkedListItem.push(item);
    }
  }
  // Check all item.
  function checkAll() {
    if ($scope.selectedAll) {
      $scope.showAction = $scope.selectedAll = true;
    } else {
      $scope.showAction = $scope.selectedAll = false;
    }
    checkedListItem.splice(0, checkedListItem.length);
    angular.forEach($scope.orders, function (item) {
      item.selected = $scope.selectedAll;
      if (item.selected)
        checkedListItem.push(item);
    });
  }

  function formatPercentage(value){
    return value * 100;
  }

  function changeStringCase(string){
    var newString = string.replace('_', ' ');
    newString = $filter('titleCase')(newString);
    return newString;
  }

  function initialize(){

    $stateParams.status = !!$stateParams.status ? $stateParams.status.toUpperCase() : '';
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;
    $scope.orders = orders.data;
    $scope.meta = orders.meta;
    $scope.mangeOrderService = Orders;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    //TODO: have an extra filter for Closed  and Delivered for now they are both Closed (Ahmed Saleh, 9/3/2016)
    if(!!$scope.status && $scope.status.toLowerCase() === 'fully_delivered'){
      $scope.status = 'CLOSED';
    }
    //highlight row
    if(!!HighlightId.getId() && HighlightId.getId() != undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    angular.forEach($scope.orders, function(order) {
      checkStar(order);
    });

    //progress piety chart
    $scope.peiChart = {
      options:{
        fill: ['#1ab394', "#eeeeee"]
      }
    };
  }

  initialize();
}

OrdersManageCtrl.$inject = ['$scope', '$state', '$stateParams', '$filter', 'Orders', 'orders', '$window',
                            'HighlightId', 'pathConstants', '$resource', '$rootScope'];

angular.module('metabuyer')
  .controller('OrdersManageCtrl', OrdersManageCtrl);
