'use strict';
/**
 * @name MasterDataManageExpenseTypesCtrl
 * @desc Controller for master data expense types controller
 */
function MasterDataManageExpenseTypesCtrl($scope, dataList, expenseTypeMasterDataList,
                                          toastr, $uibModal, $location,pathConstants, globalFunc, $stateParams) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.expenseTypeMasterDataList = expenseTypeMasterDataList;
  $scope.addNewExpenseType = addNewExpenseType;
  $scope.expenseTypesDetails = expenseTypesDetails;
  $scope.search = search;
  $scope.showColumn = showColumn;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.importExpenseType = importExpenseType;
  $scope.importLink = pathConstants.apiUrls.expenseTypes.import;
  $scope.params = {};

  $scope.columns = [
    {id: 'code', label: 'Code'},
    {id: 'descr', label: 'Description'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator.display_name', label: 'Created By'},
    {id: 'is_active', label: 'Status'}
  ];

  $scope.storageKey = 'master-data-expense-types-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }

  function importExpenseType() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Expense Type';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function addNewExpenseType() {
    if(validate($scope.expenseType)) {
      expenseTypeMasterDataList.post(
        {
          code: $scope.expenseType.code,
          descr: $scope.expenseType.descr,
          is_active: 1
        },
        function (resource) {
          toastr.success('A new Expense Type has been added successfully');
          var addedExpenseType = resource.content.data;
          $scope.dataList.unshift({
            _id: addedExpenseType._id,
            code: addedExpenseType.code,
            descr: addedExpenseType.descr,
            created_at: addedExpenseType.created_at,
            updated_at: addedExpenseType.updated_at,
            creator: [$scope.user],
            updater: [$scope.user],
            is_active: 1
          });
          $scope.expenseType.code='';
          $scope.expenseType.descr='';
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function search(query) {
    $location.search({query: query});
  }

  function validate(expenseType){
    if (!expenseType){
      return false;
    }
    if (!expenseType.code){
      return false;
    }
    if (!expenseType.descr){
      return false;
    }

    return true;
  }

  function expenseTypesDetails(index) {
    var expenseTypeId = $scope.dataList[index]._id;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/expenseTypes/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'expenseTypeDetailsCtrl',
      size: 'lg',
      resolve: {
        expenseTypeDetails: function($q, expenseTypeMasterDataList) {
          var deferred = $q.defer();
          expenseTypeMasterDataList.get(
            {
              id: expenseTypeId
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updated_by;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });
  }

  function initialize(){
    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }
  }

  initialize();
}

MasterDataManageExpenseTypesCtrl.$inject = [
  '$scope', 'dataList', 'expenseTypeMasterDataList', 'toastr', '$uibModal',
  '$location', 'pathConstants', 'globalFunc', '$stateParams'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageExpenseTypesCtrl', MasterDataManageExpenseTypesCtrl);
