'use strict';

function catalogViewCtrl($scope, $state) {

  $scope.tabData = [
    {
      heading: 'Catalog Items',
      route: 'main.user.catalogView.catalogItemList',
      key: 'catalog',
      params: {
        status: '',
        cursor: null,
        filter: null,
        query: null,
        type: null
      }
    }
  ];

  function initialize() {
    if(!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }

    if(!!$state.current.name && $state.current.name.indexOf('catalogItemList') > -1) {
      $scope.searchPlaceholder = 'Search Catalog Items';
      $scope.searchState = 'main.user.catalogView.catalogItemList';
      $scope.parentState = 'main.user.catalogView.catalogItemList';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogViewItemColumns;
    }

    if(!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status
    }

    if(!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

  }

  var catalogViewItemColumns = [
    {id: 'item_name', label: 'Item Name'},
    {id: 'description', label: 'Description'},
    {id: 'commodity_code', label: 'Commodity Code'},
    {id: 'commodity_descr', label: 'Commodity Description'},
    {id: 'supplier.descr', label: 'Supplier'},
    {id: 'supplier_part_no', label: 'Supplier Part Number'},
    {id: 'tax.rate', label: 'Tax Rate', unsortable: true},
    {id: 'payment_term.descr', label: 'Payment Term'},
    {id: 'currency.code', label: 'Currency'},
    {
      id: 'unit_price',
      label: 'Unit Price',
      type: 'double',
      unsearchable: true
    },
    {id: 'Uom.code', label: 'UOM'},
    {
      id: 'effective_at',
      label: 'Effective Date',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'expiry_at',
      label: 'Expiry Date',
      type: 'date',
      unsearchable: true
    }
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Catalog List');
}
catalogViewCtrl.$inject = ['$scope', '$state'];

angular.module('metabuyer')
  .controller('catalogViewCtrl', catalogViewCtrl);
