'use strict';
/**
 * @name companyAccountCodeCtrl
 * @desc Controller for account code list in companies
 */
function companyAccountCodeCtrl($scope, companyDetails, companyAccountCodes, $state, $rootScope) {
  $scope.accountCodes = companyAccountCodes;
  $scope.companyDetails = companyDetails.data;

  $scope.back = back;

  function back(){
    if (!!$scope.companyDetails && !!$scope.companyDetails._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    else
      window.history.back();
  }

  function initialize(){
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);
  }

  initialize();
}

companyAccountCodeCtrl.$inject = [
  '$scope', 'companyDetails', 'companyAccountCodes', '$state', '$rootScope'
];

angular.module('metabuyer')
  .controller('companyAccountCodeCtrl', companyAccountCodeCtrl);
