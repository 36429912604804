'use strict';

/**
 * @name attachmentCategory
 *
 * @requires $uibModal
 *
 * @description
 * Attachment directive with category and fields based on the category
 *
 * @copyright Sunway Metacloud &copy; 2016
 */

function attachmentCategory($uibModal, globalFunc, attachmentDetail, pathConstants, $http, $filter, $timeout,
  attachmentCategory, attachmentDescription
) {
  return{
    restrict: 'E',
    scope: {
      module: '@',
      data: '=',
      editable: '=',
      comparison: '=',
      objectId: '=?',
      hostClass: '@'
    },
    templateUrl: 'components/attachmentCategory/attachmentCategory.html',
    controller: function($scope) {

      $scope.attachments        = $scope.data || [];

      $scope.addAttachment      = addAttachment;
      $scope.downloadAttachment = downloadAttachment;
      $scope.editAttachment     = editAttachment;
      $scope.deleteAttachment   = deleteAttachment;

      /**
       * Load modal to add attachment
       */
      function addAttachment() {
        var modalInstance = $uibModal.open({
          templateUrl: 'components/attachmentCategory/addAttachment/addAttachment.html',
          backdrop: 'static',
          keyboard: false,
          controller: 'addAttachmentCtrl',
          resolve: {
            module: function () {
              return $scope.module;
            },
            editingData: function () {
              return false;
            }
          }
        });
        modalInstance.result.then(function (newValue) {
          if (!!newValue) {
            $scope.attachments.push(newValue);
            saveAttachmentData();
          }
        });
      }

      /**
       * check attachment_detail
       * if temporary_url is exist and not null
       * then directly download
       * @param attachment
       */
      function downloadAttachment(attachment) {
        if (!!attachment && !!attachment.attachment_detail && !!attachment.attachment_detail.temporary_url) {
          getAttachmentFile(attachment.attachment_detail.temporary_url);
        } else {
          attachmentDetail.get({
            id: attachment.attachment_detail._id
          }).$promise.then(
            function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data)
                getAttachmentFile(resource.content.data.temporary_url);
            }, function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }
      }

      /**
       * get the attachment file
       */
      function getAttachmentFile(url) {
        var newUrl = $filter('format')(pathConstants.apiUrls.attachments.download, {
          hash: url
        });
        $http({
          url: newUrl
        }).then(function () {
          window.open(newUrl, '_self');
        }, function (error) {
          globalFunc.objectErrorMessage(error);
        });
      }

      function editAttachment(attachment, index) {
        var modalInstance = $uibModal.open({
          templateUrl: 'components/attachmentCategory/addAttachment/addAttachment.html',
          backdrop: 'static',
          keyboard: false,
          controller: 'addAttachmentCtrl',
          resolve: {
            module: function () {
              return $scope.module;
            },
            editingData: function () {
              return attachment;
            }
          }
        });
        modalInstance.result.then(function (newValue) {
          if (!!newValue) {
            $scope.attachments[index] = newValue;
            saveAttachmentData();
          }
        });
      }

      function deleteAttachment (attachment, index) {
        swal({
          title: 'Are you sure?',
          text: 'Your attachment will not be recoverable',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: 'Yes, delete it!',
          closeOnConfirm: true
        }, function (isConfirm) {
          if (!!isConfirm) {
              if (!!attachment._id && !!$scope.objectId && !!$scope.hostClass) {
                attachmentDescription.delete(
                  {
                    id: attachment._id,
                    host_class: $scope.hostClass,
                    object_id: $scope.objectId
                  }, function (resource) {
                    $scope.attachments = globalFunc.removeValueFromArray($scope.attachments, '_id', attachment._id);
                    saveAttachmentData();
                  }, function (error){
                    globalFunc.objectErrorMessage(error);
                  });
              } else {
                $scope.attachments.splice(index, 1);
                saveAttachmentData();
              }
          }
        });
      }

      /**
       * save the attachments data and pass to the directive's attribute
       */
      function saveAttachmentData() {
        $scope.data = $scope.attachments;
      }

      /**
       * combine description array
       * @param descriptionArray
       * @param attachment
       * @returns {*}
       */
      function combineDescriptionArray(descriptionArray, attachment) {
        _.forEach(descriptionArray, function (description) {
          description.value = attachment[description.name];
        });
        return descriptionArray;
      }


      /**
       * Load attachments details
       * and prepare the data for display
       */
      function loadAttachments() {
        if (!!$scope.attachments && !!$scope.attachments.length) {
          _.forEach($scope.attachments, function (attachmentDescription) {
            // convert description_array to array
            attachmentCategory.get({
              id: attachmentDescription.attachment_category_id
            }, function(resource) {
              if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data._id)
                attachmentDescription.description_array = combineDescriptionArray(
                  resource.content.data.description_fields, attachmentDescription
                );
            }, function(error) {
              globalFunc.objectErrorMessage(error);
            });

            // get the attachment details
            attachmentDetail.get({
              id: attachmentDescription.attachment_id
            }, function (resource) {
              if (!!resource && !!resource.content && !!resource.content.data)
                attachmentDescription.attachment_detail = resource.content.data;
            });
          });
        }
      }

      function initialize() {
        loadAttachments();
      }

      initialize();

    }
  };
}

attachmentCategory.$inject = [
  '$uibModal', 'globalFunc', 'attachmentDetail', 'pathConstants', '$http', '$filter', '$timeout', 'attachmentCategory', 'attachmentDescription'
];

angular
  .module('metabuyer')
  .directive('attachmentCategory', attachmentCategory);
