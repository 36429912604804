'use strict';

function editContactCtrl($scope, $uibModalInstance, toastr, Contacts, globalFunc) {

  //cancel action
  $scope.cancel = function () {
    $uibModalInstance.close();
  };

  function validateContact() {
    if (!$scope.edit_data.first_name) {
      return false;
    }

    if (!$scope.edit_data.phone) {
      return false;
    }

    if (!$scope.isValidPhoneNo) {
     return false;
    }

    // temporary fix
    // if (!$scope.isValidFaxNo) {
    //   return false;
    // }

    return true;
  }

  /**
   * Validate number format with given element id and returns boolean in field name
   *
   * @param {string} elementId
   * @param {string} fieldName
   */
  function validateNumbers(elementId, fieldName) {
    $scope[fieldName] = globalFunc.numberFormatValidation(elementId);
  }

  //proceed action
  function updateContactInfo() {
    $scope.clicked_submit = true;
    if (!validateContact()) {
      toastr.error('Please fill all required fields');
      return;
    }

    if (!!$scope.edit_data.email && !globalFunc.emailFormatValidation($scope.edit_data.email)) {
      toastr.error('Please enter a valid email address');
      return;
    }
    Contacts.updateContact({id: $scope.editContact._id}, $scope.edit_data,
      function (success) {
        toastr.success('Contact updated');
        $scope.edit_data._id = $scope.editContact._id;

        $scope.updatedContact({contact: $scope.edit_data});

        $uibModalInstance.close();
      }, function (error) {
        globalFunc.objectErrorMessage(error);
        $uibModalInstance.close();
      });

  }

  function initialize() {
    $scope.edit_data = _.cloneDeep($scope.editContact);
    $scope.validateContact = validateContact;
    $scope.validateNumbers = validateNumbers;
    $scope.updateContactInfo = updateContactInfo;
    $scope.emailFormatValidation = globalFunc.emailFormatValidation;
    $scope.isValidPhoneNo = true;
    $scope.isValidFaxNo = true;
  }

  initialize();
}

editContactCtrl.$inject = ['$scope', '$uibModalInstance', 'toastr', 'Contacts', 'globalFunc'];

angular.module('metabuyer')
  .controller('editContactCtrl', editContactCtrl);
