'use strict';
/**
 * @name MasterDataCtrl
 * @desc Controller for master data controller The tabs will be pushed according to the access rights of the user's profile
 */
function MasterDataCtrl($scope, profile, UserPermissions, $rootScope, $stateParams, $state) {

  $scope.$emit('pageTitleChanged', 'Master Data');
  $scope.status = true;
  $scope.$broadcast('setStatus', $stateParams.status);


  $scope.tabData = [];
  var currencyTab = {
    heading: 'Currencies',
    route: 'main.user.masterData.currency',
    key: 'currency',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkCurrenciesAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkCurrenciesAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkCurrenciesAccess(profile, 'D', $rootScope.changeWorkSpace)) {
    $scope.tabData.push(currencyTab);
  }
  var exchangeRateTab = {
    heading: 'Exchange Rates',
    route: 'main.user.masterData.exchangeRate',
    key: 'exchangeRate',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkExchangeRateAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkExchangeRateAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkExchangeRateAccess(profile, 'D', $rootScope.changeWorkSpace)) {

    $scope.tabData.push(exchangeRateTab);
  }

  var uomTab = {
    heading: 'UOM',
    route: 'main.user.masterData.uom',
    key: 'uom',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkUOMAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkUOMAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkUOMAccess(profile, 'D', $rootScope.changeWorkSpace)) {

    $scope.tabData.push(uomTab);
  }

  var paymentTermsTab = {
    heading: 'Payment Terms',
    route: 'main.user.masterData.paymentTerms',
    key: 'paymentTerms',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkPaymentTermsAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkPaymentTermsAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkPaymentTermsAccess(profile, 'D', $rootScope.changeWorkSpace)) {

    $scope.tabData.push(paymentTermsTab);
  }

  var paymentMethodsTab = {
    heading: 'Payment Methods',
    route: 'main.user.masterData.paymentMethods',
    key: 'paymentMethods',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkPaymentMethodAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkPaymentMethodAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkPaymentMethodAccess(profile, 'D', $rootScope.changeWorkSpace)) {

    $scope.tabData.push(paymentMethodsTab);
  }

  var commodityCodesTab = {
    heading: 'Commodity Codes',
    route: 'main.user.masterData.commodityCode',
    key: 'commodityCodes',
    params: {
      cursor: null,
      parentUniqueName: 'All'
    }
  };
  if (!!UserPermissions.checkCommodityCodeAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkCommodityCodeAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkCommodityCodeAccess(profile, 'D', $rootScope.changeWorkSpace)) {
    $scope.tabData.push(commodityCodesTab);
  }

  var taxesTab = {
    heading: 'Taxes',
    route: 'main.user.masterData.taxes',
    key: 'taxes',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkTaxesManagementAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkTaxesManagementAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkTaxesManagementAccess(profile, 'D', $rootScope.changeWorkSpace)) {

    $scope.tabData.push(taxesTab);
  }

  var expenseTypesTab = {
    heading: 'Expense Types',
    route: 'main.user.masterData.expenseTypes',
    key: 'expenseTypes',
    params: {
      cursor: null
    }
  };
  if (!!UserPermissions.checkExpenseTypeAccess(profile, 'C', $rootScope.changeWorkSpace) || !!UserPermissions.checkExpenseTypeAccess(profile, 'U', $rootScope.changeWorkSpace)
    || !!UserPermissions.checkExpenseTypeAccess(profile, 'D', $rootScope.changeWorkSpace)) {

    $scope.tabData.push(expenseTypesTab);
  }

  function initialize() {
    if (!!$state.current.name) {
      if ($state.current.name.indexOf('currency') > -1) {
        $scope.activatedTab = currencyTab;
      } else if ($state.current.name.indexOf('exchangeRate') > -1) {
        $scope.activatedTab = exchangeRateTab;
      } else if ($state.current.name.indexOf('uom') > -1) {
        $scope.activatedTab = uomTab;
      } else if ($state.current.name.indexOf('paymentTerms') > -1) {
        $scope.activatedTab = paymentTermsTab;
      } else if ($state.current.name.indexOf('paymentMethods') > -1) {
        $scope.activatedTab = paymentMethodsTab;
      } else if ($state.current.name.indexOf('commodityCode') > -1) {
        $scope.activatedTab = commodityCodesTab;
      } else if ($state.current.name.indexOf('taxes') > -1) {
        $scope.activatedTab = taxesTab;
      } else if ($state.current.name.indexOf('expenseTypes') > -1) {
        $scope.activatedTab = expenseTypesTab;
      }
    }
  }

  initialize();

}
MasterDataCtrl.$inject = ['$scope', 'profile', 'UserPermissions', '$rootScope', '$stateParams', '$state'];

angular
  .module('metabuyer')
  .controller('MasterDataCtrl', MasterDataCtrl);
