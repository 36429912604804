'use strict';


/**
 * //TODO: document
 * @param $scope
 * @param taxesList
 * @param paymentTermsList
 * @param pathConstants
 * @param $http
 * @param globalFunc
 * @param $filter
 * @param supplierGlobalFunc
 * @param searchModule
 * @param purchaseRequisitionsServices
 * @param $rootScope
 * @param toastr
 * @param prFunction
 * @param $uibModalInstance
 * @param listChild
 * @param lang
 * @param item
 * @param currencies
 */
function catalogNonCatalogItemCtrl(
  $scope, taxesList, paymentTermsList, pathConstants, $http, globalFunc, $filter, supplierGlobalFunc, searchModule,
  purchaseRequisitionsServices, $rootScope, toastr, prFunction, $uibModalInstance, listChild, lang, item, currencies
) {

  $scope.paymentTermsList             = paymentTermsList;
  $scope.taxesList                    = taxesList;
  $scope.currenciesList               = currencies.data;
  $scope.PR                           = prFunction.getPRData();

  $scope.uomSearching                 = false;
  $scope.supplierSearching            = false;
  $scope.commoditySearching           = false;
  $scope.editingState                 = false;
  $scope.formSubmitted                = false;
  $scope.commodityLevel               = [4];
  $scope.nonCatalog                   = {};
  $scope.invalidSupplierSelected      = false;

  $scope.formatModel                  = formatModel;
  $scope.searchUOM                    = searchUOM;
  $scope.searchSuppliers              = searchSuppliers;
  $scope.searchCommodityCode          = searchCommodityCode;
  $scope.submitNonCatalogItem         = submitNonCatalogItem;
  $scope.openChildCommodityCode       = openChildCommodityCode;
  $scope.onUOMSelect                  = onUOMSelect;
  $scope.validationUOMSelection       = validationUOMSelection;
  $scope.onSupplierSelect             = onSupplierSelect;
  $scope.validationSupplierSelection  = validationSupplierSelection;
  $scope.getCurrencyList              = getCurrencyList;
  $scope.close                        = close;

  function createNonCatalogItem() {
    $scope.submitted = true;
    var params = prepareNonCatalogData();
    purchaseRequisitionsServices.createNonCatalogItem(
      {
        id: $rootScope.currentUser._id
      },
      params
    ).$promise
      .then(function (success) {
        if (!!success.content && !!success.content.data && !!success.content.data.default
          && !!success.content.data.default._id) {
          if ($scope.nonCatalog.image) {
            uploadNonCatalogImage(success.content.data).then(
              function () {
                addNonCatalogToPR(success.content.data.default._id, $scope.nonCatalog.qty);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                $scope.submitted = false;
              }
            );
          }
          else
            addNonCatalogToPR(success.content.data.default._id, $scope.nonCatalog.qty);
        } else {
          globalFunc.objectErrorMessage('Failed to create a non catalog item');
        }
      },
      function (err) {
        $scope.submitted = false;
        globalFunc.objectErrorMessage(err);
      }
    );
  }

  function addNonCatalogToPR(itemId, qty) {
    purchaseRequisitionsServices.setItem({
      id: $scope.PR._id,
      action: 'add'
    }, {
      'item_details_id': itemId,
      'qty': qty
    }, function (resource) {
      if (!!resource.content && !!resource.content.data) {
        prFunction.setPRData(resource.content.data);
        $scope.submitted = false;
        toastr.success($scope.nonCatalog.item_details.item_name + ' has been added.');
        close();
      }
    }, function (error) {
      $scope.submitted = false;
      globalFunc.objectErrorMessage(error);
    });
  }

  function updateNonCatalogItem(nonCatalogItem) {
    var params = prepareUpdateNonCatalogData();
    $scope.submitted = true;
    purchaseRequisitionsServices.setItem({
        id: nonCatalogItem._id,
        action: 'item-details'
      },
      {
        item_data: params
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          prFunction.setPRData(resource.content.data);
          $rootScope.$broadcast('updateDetailsData', globalFunc.findInArray(
            resource.content.data.pr_items, '_id', nonCatalogItem._id)
          );

          //update the image in case the user added an image
          var item =  globalFunc.findInArray(resource.content.data.pr_items, '_id', nonCatalogItem._id);
          if(!!$scope.nonCatalog.image && item && item._id){
            updateNonCatalogImage(item).then(function(resource){

              if (!!resource.data && !!resource.data.content && !!resource.data.content.data) {
                prFunction.setPRData(resource.data.content.data);
                $rootScope.$broadcast('updateDetailsData', globalFunc.findInArray(
                  resource.data.content.data.pr_items, '_id', nonCatalogItem._id)
                );
              }
            }, function(error){
              globalFunc.objectErrorMessage(error);
            });
          }
        }
        else
          globalFunc.objectErrorMessage('An error occurred');

        $scope.submitted = false;
        close();
      },
      function (error) {
        $scope.submitted = false;
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function prepareNonCatalogItem(item) {

    //find Index
    var index = null;
    var prItems = prFunction.getPRData().pr_items;
    for (var i in prItems) {
      if (prItems[i]._id === item._id) {
        index = i;
      }
    }

    if (index === null) {
      globalFunc.objectErrorMessage('An error occurred');
      return;
    }

    var validateLumpSum = prFunction.validateLumpSum(item, index);
    if (!!prFunction.getPRData().lump_sum_discount && !validateLumpSum.status) {
      swal({
        title: 'Are you sure you want to update the item?',
        text: validateLumpSum.message,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes, update it!',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          // remove lump sum
          prFunction.removeLumpSum(prFunction.getPRData()._id, function (resource) {
            if (!!resource.content && !!resource.content.data) {
              prFunction.setPRData(resource.content.data);
              updateNonCatalogItem(item);
            } else {
              globalFunc.objectErrorMessage('An error occurred');

            }
          })
        }
      });
    }
    else
      updateNonCatalogItem(item);
  }

  function prepareNonCatalogData() {
    return {
      'itemName'        : $scope.nonCatalog.item_details.item_name,
      'internalPartNo'  : '',
      'description'     : $scope.nonCatalog.item_details.description,
      'supplierId'      : !!$scope.nonCatalog.supplier ? $scope.nonCatalog.supplier._id : '',
      'supplier_name'   : !!$scope.nonCatalog.supplier ? $scope.nonCatalog.supplier.descr : '',
      'supplierPartNo'  : $scope.nonCatalog.item_details.supplier_part_no,
      'quantity'        : !!$scope.nonCatalog.qty ? $scope.nonCatalog.qty : 1,
      'unitPrice'       : ($scope.nonCatalog.unit_price > -1) ? $scope.nonCatalog.unit_price : 0,
      'currencyId'      : (!!$scope.nonCatalog.currency && !!$scope.nonCatalog.currency._id) ?
        $scope.nonCatalog.currency._id : '',
      'uomId'           : (!!$scope.nonCatalog.UOM && !!$scope.nonCatalog.UOM._id) ? $scope.nonCatalog.UOM._id : '',
      'commodity_title' : (!!$scope.nonCatalog.commodity_code && !!$scope.nonCatalog.commodity_code.descr) ?
        $scope.nonCatalog.commodity_code.descr : '',
      'commodityCode'   : (!!$scope.nonCatalog.commodity_code && !!$scope.nonCatalog.commodity_code.code) ?
        $scope.nonCatalog.commodity_code.code : '',
      'paymentTermId'   : (!!$scope.nonCatalog.payment_term && !!$scope.nonCatalog.payment_term._id) ?
        $scope.nonCatalog.payment_term._id : '',
      'taxId'           : (!!$scope.nonCatalog.tax && !!$scope.nonCatalog.tax._id) ? $scope.nonCatalog.tax._id : '',
      'pr_cc'           : (!!$scope.PR && !!$scope.PR.cost_center) ? $scope.PR.cost_center._id : '',
      'uomCode'         : (!!$scope.nonCatalog.UOM && !!$scope.nonCatalog.UOM.code) ? $scope.nonCatalog.UOM.code : '',
      'catalog_group_ids': prFunction.getCatalogGroup()
    };
  }

  /**
   * this function prepare the data differently from the Items endpoint, this will be the standard
   * @returns
   * {{item_name: *, description, supplier_id: string, currency_id: string, uom_id: string,
   * commodity_id: string, string, payment_term_id: string}}
   */
  function prepareUpdateNonCatalogData() {
    return  {
      'item_name': $scope.nonCatalog.item_details.item_name,
      'description': $scope.nonCatalog.item_details.description,
      'supplier_id': !!$scope.nonCatalog.supplier ? $scope.nonCatalog.supplier._id : '',
      'currency_id': (!!$scope.nonCatalog.currency && !!$scope.nonCatalog.currency._id) ?
        $scope.nonCatalog.currency._id : '',
      'uom_id': (!!$scope.nonCatalog.UOM && !!$scope.nonCatalog.UOM._id) ? $scope.nonCatalog.UOM._id : '',
      'commodity_id': (!!$scope.nonCatalog.commodity_code && !!$scope.nonCatalog.commodity_code._id) ?
        $scope.nonCatalog.commodity_code._id : '',
      'payment_term_id': (!!$scope.nonCatalog.payment_term && !!$scope.nonCatalog.payment_term._id) ?
        $scope.nonCatalog.payment_term._id : '',
      'supplier_part_no': $scope.nonCatalog.item_details.supplier_part_no
    };
  }

  function nonCatalogValidation() {

    // validate image type
    if (!!$scope.nonCatalog.image) {
      if (/^image\/*/.test($scope.nonCatalog.image.type) === false) {
        toastr.error('Item image' + lang.validation.upload.onlyImage);
        return false;
      }
    }

    if (!$scope.nonCatalog.item_details.item_name) {
      toastr.error(lang.validation.required.input + 'item Name');
      return false;
    }

    if (!$scope.nonCatalog.item_details.description) {
      toastr.error(lang.validation.required.input + 'item Description');
      return false;
    }

    if (!!$scope.nonCatalog.qty && isNaN($scope.nonCatalog.qty) === true) {
      toastr.error(lang.validation.number.isNumber + ' for quantity');
      return false;
    }
    if (Number($scope.nonCatalog.qty) === 0) {
      toastr.error(lang.validation.required.input + 'more than 0');
      return false;
    } else if (!$scope.nonCatalog.qty) {
      toastr.error(lang.validation.required.input + 'a Quantity');
      return false;
    }

    if (!$scope.nonCatalog.UOM) {
      toastr.error(lang.validation.required.input + 'a Unit of Measurement');
      return false;
    }

    if(!!$scope.nonCatalog.UOM && !$scope.nonCatalog.UOM.is_fraction && $scope.nonCatalog.qty % 1 !== 0) {
      toastr.error('Unit of Measurement ' + lang.validation.number.noFraction);
      $scope.decimalError = true;
      return false;
    }

    if (!!$scope.nonCatalog.supplier &&
      (!$scope.nonCatalog.supplierStatus || $scope.nonCatalog.supplierStatus.toLowerCase() !== 'active')) {
      toastr.error('The selected Supplier is not Valid, please select other supplier');
      return false;
    } else if (!$scope.nonCatalog.supplier) {
      toastr.error(lang.validation.required.selection + 'a Supplier');
      return false;
    }

    if (!$scope.nonCatalog.commodity_code) {
      toastr.error(lang.validation.required.selection + 'a Commodity');
      return false;
    }

    if (!$scope.nonCatalog.currency) {
      toastr.error(lang.validation.required.selection + 'a Currency');
      return false;
    }

    if ($scope.nonCatalog.unit_price < 0) {
      toastr.error(lang.validation.required.input + 'a Unit Price');
      return false;
    }

    if (!$scope.nonCatalog.payment_term) {
      toastr.error(lang.validation.required.selection + 'a Payment Term');
      return false;
    }

    return true;
  }

  /**
   * upload non catalog image
   * the image is uploaded after the item has been created
   * use the item id and item_details id
   *
   * @param itemData
   */
  function uploadNonCatalogImage(itemData) {

    var fd = new FormData();
    fd.append('itemImage', $scope.nonCatalog.image);
    return $http.post($filter('format')(pathConstants.apiUrls.items.imagesUpload, {
      user_id: $rootScope.currentUser._id,
      item_id: itemData.item_id,
      detail_id: itemData.detail_id
    }), fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    });
  }

  /**
   * update the nonCatalog image
   * @param itemData
   */
  function updateNonCatalogImage(itemData) {
    var fd = new FormData();
    fd.append('item_image', $scope.nonCatalog.image);
    return $http.post($filter('format')(pathConstants.apiUrls.purchaseRequisitions.updateNonCatalogImage, {
      id: itemData._id
    }), fd, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined}
    });
  }


  function submitNonCatalogItem(action, item) {
    $scope.formSubmitted = true;
    if (nonCatalogValidation()) {
      if (action === 'create')
        createNonCatalogItem();
      else
        prepareNonCatalogItem(item);
    }
  }

  function searchCommodityCode(keyword) {
    if (!!keyword && keyword.length > 1) {
      $scope.commoditySearching = true;
      $scope.commodityCodes = [];
      searchModule.get(
        {
          module: 'commodity',
          'criteria[0][code]': keyword,
          'criteria[0][descr]': keyword,
          'criteria[0][$operator]': 'or',
          'criteria[1][is_active]': 1,
          order: 1,
          order_by: 'code',
          offset: 0
        }, function (resource) {
          $scope.commoditySearching = false;
          if (!!resource && !!resource.content && !!resource.content.data)
            $scope.commodityCodes = resource.content.data;
        }, function () {
          $scope.commoditySearching = false;
          $scope.commodityCodes = [];
        });
    }
  }

  /**
   * Get children data for commodity Code
   * @param cc
   */
  function openChildCommodityCode(cc){
    listChild.get({
      code: cc
    }, function(resource){
      if(!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0)
        $scope.commodityCodes = resource.content.data;
      else
        toastr.error('There are no child on this commodity code');
    }, function(){
      toastr.error('Failed to get Commodity Codes data');
    })
  }

  function onUOMSelect(UOM) {
    $scope.validUOMSelected = true;
    $scope.nonCatalog.UOM = UOM;
    $scope.nonCatalog.UOMText = UOM.code + ' < ' + UOM.descr + ' >';
  }

  /**
   * Validate the Selection for the Type-a-head
   * @param text
   */
  function validationUOMSelection(text) {
    if (!$scope.validUOMIsSelected || (!!text && text.length < 2)) {
      $scope.nonCatalog.UOM = null;
      $scope.nonCatalog.UOMText = '';
    }
  }

  /**
   * 1- validate the status of the supplier
   * 2- auto select default Tax if not selected
   * 3- auto select default Payment Terms if not selected
   * @param supplier
   */
  function onSupplierSelect(supplier) {
    var text;
    $scope.invalidSupplierSelected = false;
    if (!supplier || (!!supplier.status && supplier.status.toLowerCase() !== 'active')) {
      if (supplier.status.toLowerCase() === 'pending')
        text = 'The selected Supplier is pending approval, please select another supplier.';
      else if (supplier.status.toLowerCase() === 'black_listed')
        text = 'The selected Supplier is blacklisted, please select another supplier.';
      else
        text = 'The selected Supplier is not valid, please select another supplier.';

      globalFunc.objectErrorMessage(text);
      $scope.nonCatalog.supplier_display_name = '';
      $scope.nonCatalog.supplier = null;
      $scope.invalidSupplierSelected = true;
      return false;
    }

    $scope.nonCatalog.supplier        = supplier;
    $scope.nonCatalog.supplierStatus  = supplier.status;

    // auto assign the default Tax if its not already selected
    if (!$scope.nonCatalog.tax && !!supplier.tax_id)
      $scope.nonCatalog.tax = globalFunc.findInArray($scope.taxesList, '_id', supplier.tax_id);

    // select the Payment Term of the supplier as the supplier selection returns code only
    if (!!supplier.pay_terms_code && !!$scope.paymentTermsList)
      $scope.nonCatalog.payment_term = globalFunc.findInArray($scope.paymentTermsList, 'code', supplier.pay_terms_code);
  }

  /**
   * Validate the Selection for the Type-a-head
   * @param text
   */
  function validationSupplierSelection(text) {
    if (!$scope.validSupplierIsSelected || (text.length < 3)) {
      $scope.nonCatalog.supplier = null;
      $scope.nonCatalog.supplierName = '';
    }
  }

  /**
   * Function to format the ng-model after typeahead selected
   */
  function formatModel(model) {
    var indexOfHtml = model.indexOf('<');
    $scope.nonCatalog.supplierName = model.substring(0, indexOfHtml);
  }

  /**
   * Function to modify supplier object and pass label class as html
   */
  function modifySupplierObject(suppliers) {
    suppliers.status = supplierGlobalFunc.getSupplierStatus(
      suppliers.status, suppliers.approval_status, suppliers.draft_status, 'val'
    );
    switch (suppliers.status.toLowerCase()) {
      case 'black_listed':
        suppliers.labelStatus = 'black';
        break;
      case 'inactive':
        suppliers.labelStatus = 'warning';
        break;
    }

    return suppliers;
  }


  function searchSuppliers(val) {
    if (!!val && val.length > 2) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][descr]': val,
        'criteria[0][archive_of]': '',
        'criteria[0][draft_status]': 3,
        'criteria[0][approval_status]': 1,
        criteria_operator: 'and',
        offset: 10
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        if (response.data.content.data.length === 0) {
          $scope.supplierSearching = false;
          return [];
        } else {
          return response.data.content.data.map(function (item) {
            $scope.supplierSearching = false;
            return modifySupplierObject(item);
          });
        }
      });
    }
  }

  function getCurrencyList() {
    return $scope.currenciesList;
  }


  function searchUOM(val) {

    if (!!val && val.length > 1) {
      $scope.uomSearching = true;

      return $http.get(pathConstants.apiUrls.uom.list, {
        params: {
          query: val
        },
        ignoreLoadingBar: true
      }).then(function (response) {
        $scope.uomSearching = false;
        return response.data.content.data.map(function (item) {
          return item;
        });
      }).catch(function(error){
        $scope.uomSearching = false;
        globalFunc.objectErrorMessage(error);
      })
    }
  }


  function close(){
    $uibModalInstance.close();
  }

  function initialize() {
    // init value for non catalog
    // the variable name is following the pr_items naming
    // to make it easier on editing
    $scope.nonCatalog.unit_price        = 0;
    $scope.nonCatalog.qty               = 1;
    $scope.nonCatalog.item_details      = {};

    // if item is exist (editing mode)
    // modify some of the value to fit to the nonCatalog controller and template
    if (!!item && !!item._id) {
      if (!item.commodity && !!item.item && !!item.item.commodity)
        item.commodity = item.item.commodity;

      $scope.editingState               = true;
      $scope.nonCatalog                 = _.cloneDeep(item);
      // display supplier name on the supplier search box
      $scope.nonCatalog.supplierName    = item.supplier.descr;
      // to display the selected commodity in the commodity search box
      $scope.commodityKeyword           = item.commodity.code + ' ' + item.commodity.descr;
      // // the commodity code data to send to endpoint
      $scope.nonCatalog.commodity_code  = $scope.nonCatalog.commodity;
      //correction for the incorrect set Ids
      if(!$scope.nonCatalog.commodity_code._id){
        $scope.nonCatalog.commodity_code._id = $scope.nonCatalog.commodity_code.id;
      }

      // if the supplier was selected the Supplier will always initially be active as the PR handles the data
      if(!!$scope.nonCatalog.supplier)
        $scope.nonCatalog.supplierStatus = 'active';

      // if the UOM is set it will be active as the PR handles the errors
      if(!!$scope.nonCatalog.UOM)
      {
        $scope.nonCatalog.UOMText = $scope.nonCatalog.UOM.code + ' < ' + $scope.nonCatalog.UOM.descr + ' >';
        $scope.validUOMIsSelected = true;
      }
    }
  }

  initialize();

}

catalogNonCatalogItemCtrl.$inject =  [
  '$scope', 'taxesList', 'paymentTermsList', 'pathConstants', '$http', 'globalFunc', '$filter', 'supplierGlobalFunc',
  'searchModule', 'purchaseRequisitionsServices', '$rootScope', 'toastr', 'prFunction', '$uibModalInstance', 'listChild',
  'lang', 'item', 'currencies'
];

angular
  .module('metabuyer')
  .controller('catalogNonCatalogItemCtrl', catalogNonCatalogItemCtrl);
