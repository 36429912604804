'use strict';
/**
 * @name costCenterAddressesCtrl
 * @desc Controller for addresses list controller
 */
function costCenterAddressesCtrl(
  $scope, $state, $uibModal, toastr, costCenterDetails, companyDetails, companyCostCentersList, addresses, CostCenters,
  pathConstants
) {

  $scope.addressList = {};
  $scope.costCenterDetails = costCenterDetails.data;
  $scope.company = companyDetails.data;
  $scope.companyCostCentersList = companyCostCentersList.data;
  $scope.allAddresses = addresses;
  $scope.deliveryAddressActive = false;
  $scope.billingAddressActive = true;
  $scope.importLink = pathConstants.apiUrls.addresses.import;

  $scope.back = back;
  $scope.newAddress = newAddress;
  $scope.addressActiveTab = addressActiveTab;
  $scope.detailAddress = detailAddress;
  $scope.beforeSave = beforeSave;
  $scope.saveSelection = saveSelection;
  $scope.updateAddressModel = updateAddressModel;

  function back() {
    window.history.back();
  }

  function addressActiveTab(type){
    if(type === 'billing'){
      $scope.deliveryAddressActive = false;
      $scope.billingAddressActive = true;
    } else {
      $scope.deliveryAddressActive = true;
      $scope.billingAddressActive = false;
    }
  }

  function backToListingCompanies() {
    $state.go("main.user.companiesMgmt.manageCompanies");
  }

  function setActiveTab() {
    $scope.tabData.active = _.find($scope.tabData, function (tab) {
      if (!!tab.menu && tab.menu.length){
        return _.find(tab.menu, function (item) {
          return item.params.action === "companies";
        });
      }
      else{
        return tab.params.action === "companies";
      }
    });

    if (!!$scope.tabData){
      $scope.tabData.active.active = true;
    }
  }

  function newAddress(type){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/companiesManagement/costcenters/address/addAddress.html',
      controller: 'newAddressCtrl',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        addressType: function() {
          return type;
        },
        costCenterDetails: function() {
          return $scope.costCenterDetails;
        },
        company: function() {
          return $scope.company;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        if(newValue.type === 'billing'){
          $scope.addressList.billing_addresses.push(newValue);
        } else if(newValue.type === 'delivery') {
          $scope.addressList.delivery_addresses.push(newValue);
        }
      }
    }, function () {
      // do nothing for now, the best that this function can do
    });
  }

  function detailAddress(address, type){
    var modalInstance = $uibModal.open({
      templateUrl: 'app/companiesManagement/costcenters/address/editAddress.html',
      controller: 'editAddressCostCenterCtrl',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        addressType: function() {
          return type;
        },
        addressDetail: function() {
          return angular.copy(address);
        },
        costCenterDetails: function() {
          return $scope.costCenterDetails;
        },
        updateFunction: function(){
          return $scope.updateAddressModel;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      var deletedIndex = null;
      var index = 0;

      if(!!newValue){
        if(newValue.type === 'billing'){

          deletedIndex = null;
          index = 0;
          _.forEach($scope.addressList.billing_addresses, function(billing){
            if(billing._id === newValue.id){
              deletedIndex = index;
            }
            index++;
          });

          if (index > -1) {
            $scope.addressList.billing_addresses.splice(deletedIndex, 1);
          }

        } else if(newValue.type === 'delivery') {
          deletedIndex = null;
          index = 0;
          _.forEach($scope.addressList.delivery_addresses, function(delivery){
            if(delivery._id === newValue.id){
              deletedIndex = index;
            }
            index++;
          });

          if (index > -1) {
            $scope.addressList.delivery_addresses.splice(deletedIndex, 1);
          }
        }
      }
    }, function () {
      // do nothing for now, the best that this function can do
    });
  }

  function getIndexFromObject(objects, value){
    var index = 0;
    _.forEach(objects, function(object){
      if(object._id === value){
        return index;
      }
      index++;
    });
  }

  function initialize() {
    setActiveTab();
    /**
     * loading all available addresses in the list
     * @type {Array}
     */
    $scope.addressList.billing_addresses = angular.copy($scope.allAddresses);
    $scope.addressList.delivery_addresses = angular.copy($scope.allAddresses);

    /**
     * checking for the selected Addresses
     */
    checkSelectedAddresses($scope.costCenterDetails);
  }


  function checkSelectedAddresses(costCenters) {

    if (!!costCenters.billing_addresses) {
      for (var i in $scope.addressList.billing_addresses) {
        for (var k in costCenters.billing_addresses) {
          if ($scope.addressList.billing_addresses[i]._id === costCenters.billing_addresses[k]._id) {
            $scope.addressList.billing_addresses[i].selected = true;
          }
        }
      }
    }

    if (!!costCenters.delivery_addresses) {
      for (var i in $scope.addressList.delivery_addresses) {
        for (var k in costCenters.delivery_addresses) {
          if ($scope.addressList.delivery_addresses[i]._id === costCenters.delivery_addresses[k]._id) {
            $scope.addressList.delivery_addresses[i].selected = true;
          }
        }
      }
    }
  }


  /**
   * function to update the Address after being edited in the edit modal
   * @param address
   * @param type
     */
  function updateAddressModel(address, type){
    if(type === 'delivery') {
      for (var i in $scope.addressList.delivery_addresses) {
        if ($scope.addressList.delivery_addresses[i]._id === address._id) {
          $scope.addressList.delivery_addresses[i] = address;
        }
      }
    }
    else {
      for (var i in $scope.addressList.billing_addresses) {
        if ($scope.addressList.billing_addresses[i]._id === address._id) {
          $scope.addressList.billing_addresses[i] = address;
        }
      }
    }
  }

  function beforeSave(ckSelected) {
    if (!ckSelected) {
      swal({
        title: 'Unassign Address',
        text: 'Are you sure you want to unassign this address from the cost center?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes',
        closeOnConfirm: true
      }, function(isConfirm) {
        if(isConfirm) {
          saveSelection();
        } else {
          $state.reload();
        }
      });
    } else {
      saveSelection();
    }
  }


  /**
   * saving the Addresses
   */
  function saveSelection() {

    var update_data = {};
    update_data['delivery_addresses_ids'] = [];
    update_data['billing_addresses_ids'] = [];

    if (!!$scope.addressList.delivery_addresses) {
      _.forEach($scope.addressList.delivery_addresses, function (deliveryAddress) {
        if (!!deliveryAddress.selected) {
          update_data['delivery_addresses_ids'].push(deliveryAddress._id);
        }
      });
    }

    if (!!$scope.addressList.billing_addresses) {
      _.forEach($scope.addressList.billing_addresses, function (billingAddress) {
        if (billingAddress.selected) {
          update_data['billing_addresses_ids'].push(billingAddress._id);
        }
      });
    }
    CostCenters.put(
      {
        id: $scope.costCenterDetails._id,
        descr: $scope.costCenterDetails.descr
      }, update_data,
      function () {
        toastr.success('The addresses were updated successfully');
      },
      function () {
        toastr.error('Failed to update addresses');
        $state.reload();
      }
    );
  }
  initialize();
}

costCenterAddressesCtrl.$inject = [
  '$scope', '$state', '$uibModal', 'toastr', 'costCenterDetails', 'companyDetails', 'companyCostCentersList', 'addresses',
  'CostCenters', 'pathConstants'
];

angular
  .module('metabuyer')
  .controller('costCenterAddressesCtrl', costCenterAddressesCtrl);
