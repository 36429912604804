'use strict';
/**
 * @name addSupplierBranches
 * @desc Controller to add branch to supplier
 */
function addSupplierBranches($scope, toastr, branches, globalFunc, $uibModalInstance, $timeout) {
  $scope.closeModal = closeModal;
  $scope.newBranch = newBranch;
  $scope.validateEmail = validateEmail;
  $scope.onUpdatedCountry = onUpdatedCountry;
  $scope.onUpdatedState = onUpdatedState;
  $scope.addContact = addContact;
  $scope.removeContact = removeContact;
  $scope.onUpdatedContact = onUpdatedContact;
  $scope.branches = {};
  $scope.branches.contact=[];

  function closeModal() {
    $uibModalInstance.close();
  }
  $scope.branchStatus = [{
    descr: 'Active',
    value: 1
  },{
    descr: 'Inactive',
    value: 0
  } ];

  function newBranch() {
    if (validate().decision) {
      $uibModalInstance.close($scope.branches);
    } else {
      toastr.error('Please ' + validate().reason + ' to create a new branch');
    }
  }

  function onUpdatedCountry(country) {
    $scope.branches.address.state = '';
    if (!!country.descr) {
      $scope.branches.address.country = country.descr;
      $scope.selectedMalaysia = (country.descr === 'Malaysia');
    }
  }

  function onUpdatedState(state) {
    if (!!state) {
      $scope.branches.address.state = state.descr;
    }
  }

  function addContact(contact) {
    var spliceIndex = $scope.branches.contact[0];
    if (!!spliceIndex) {
      $scope.branches.contact.push(contact);
    }
    /**
     * If the first index is null, undefined or empty
     */
    else {
      $scope.branches.contact.splice(spliceIndex, 1, contact);
    }
  }

  function removeContact(contact) {
    var index = _.findIndex($scope.branches.contact, function (item) {
      return item._id == contact._id;
    });
    $scope.branches.contact.splice(index, 1);
  }

  function onUpdatedContact(contact) {
    var index = _.findIndex($scope.branches.contact, function (item) {
      return item._id == contact._id;
    });

    $scope.branches.contact.splice(index, 1, contact);
  }

  function validateEmail(email) {
    var regex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  function validatePostalCode(postalCode) {
    var regex = /^[a-z0-9]+$/i;
    return regex.test(postalCode);
  }

  function validateBranchUnique(name) {
    var sameBranchName = globalFunc.findInArray(branches,'descr', name);
    return (!sameBranchName);
  }

  function validate() {

    var result = [];
    result.decision = false;
    if(!$scope.branches.descr) {
      result.reason = 'fill in Supplier Branch Name';
      return result;
    }

    if (!validateBranchUnique($scope.branches.descr)) {
      result.reason = 'use unique Supplier Branch Name';
      return result;
    }

    if((!$scope.branches.receive_via_fax && !$scope.branches.receive_via_email
      && !$scope.branches.receive_via_post)) {
      result.reason = 'select one method of receiving PO';
      return result;
    }

    if(!!$scope.branches.receive_via_email) {
      if(!$scope.branches.email) {
        result.reason = 'fill in Branch Email Address';
        return result;
      }
    }

    if (!!$scope.branches.email && !validateEmail($scope.branches.email)) {
      result.reason = 'enter Branch Email Address with the correct format';
      return result;
    }

    if(!!$scope.branches.receive_via_fax) {
      if(!$scope.branches.fax) {

        result.reason = 'fill in Branch Fax Number';
        return result;
      }
    }

    if(!$scope.branches.phone) {
      result.reason = 'fill in Branch Phone Number';
      return result;
    }

    if(!$scope.branches.address || !$scope.branches.address.line1 || !$scope.branches.address.city ||
      !$scope.branches.address.state || !$scope.branches.address.country ||
      !$scope.branches.address.postal_code) {
      result.reason = 'fill in Branch Corresponding Address';
      return result;
    }

    if(!validatePostalCode($scope.branches.address.postal_code)) {
      result.reason = 'enter a correct postal code (alphanumeric only)';
      return result;
    }

    if($scope.branches.contact.length == 0) {
      result.reason = 'create a Branch contact person';
      return result;
    }

    if($scope.branches.isActive !== 1 && $scope.branches.isActive !== 0) {
      result.reason = 'select Branch Status';
      return result;
    }

    result.decision = true;
    return result;
  }

  function initialize() {

    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();

    /**
     * To initialize country by looping and comparing descr with returned data
     */
    if (!!$scope.branches.country) {
      if (!!globalFunc.findInArray($scope.countries, 'descr', $scope.address.branches.country)
        && globalFunc.findInArray($scope.countries, 'descr', $scope.address.branches.country).descr ===
        $scope.address.branches.country) {
        $scope.selectedMalaysia = ($scope.address.branches.country === 'Malaysia');
      }
    }
  }

  initialize();

}

addSupplierBranches.$inject = [
  '$scope', 'toastr', 'branches', 'globalFunc', '$uibModalInstance', '$timeout'
];

angular
  .module('metabuyer')
  .controller('addSupplierBranches', addSupplierBranches);
