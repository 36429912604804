'use strict';

angular.module('metabuyer')
  .controller('ClosePOCtrl', function ($scope, $uibModalInstance, Orders, toastr, HighlightId, $interval, $state) {
    $scope.next_close = true;
    $scope.back_close = false;
    $scope.commentText = "";
    $scope.enable_next = false;
    $scope.check_for_all = false;
    $scope.close_po_data = [];

    $scope.next = next;
    $scope.cancel = cancel;
    $scope.backOnClosePO = backOnClosePO;
    $scope.checkedLineItem = checkedLineItem;
    $scope.checkedAllLineItems = checkedAllLineItems;
    $scope.proceedClosePO = proceedClosePO;
    $scope.initialize = initialize;

    //next action
    function next(){
      $scope.next_close = false;
      $scope.back_close = true;
    }

    //cancel action
    function cancel(){
      $uibModalInstance.close();
    }

    //back action
    function backOnClosePO(){
      $scope.next_close = true;
      $scope.back_close = false;
    }

    function checkedLineItem(item){
      //check all selected line items
      var unselected_item = _.find($scope.close_po_data, function(item){
        return !item.selected;
      });

      if(unselected_item != undefined){
        $scope.check_for_all = false;
      }

      //check enable next
      var selected_item = _.find($scope.close_po_data, function(item){
        return item.selected;
      });

      if(selected_item != undefined){
        $scope.enable_next = true;
      } else {
        $scope.enable_next = false;
      }

    }

    //check all line items
    function checkedAllLineItems(){
      if($scope.check_for_all){
        $scope.enable_next = true;

      } else {
        $scope.enable_next = false;
      }

      _.forEach($scope.close_po_data, function(item){
        if(!!item.po_item) {
          if(!!item.po_item.order && !!item.po_item.order.delivered) {
            if (!item.po_item.closed && (item.po_item.order.delivered !== item.po_item.quantity)) {
              item.selected = $scope.enable_next;
            }
          }
          else {
            /**
             * no delivery occurred
             * @type {boolean}
             */
            item.selected = $scope.enable_next;
          }
        }
      });
    }

    //proceed action
    function proceedClosePO(){

      var indexes = [];
      _.forEach($scope.close_po_data, function(item){
        if(!item.selected){
          return;
        }
        indexes.push(item.itemIndex);
      });
      var i = 0;
      var flagErrorOccurred = false;
      /**
       * extra check to prevent the closing in case of data not being updated
       */
      if(indexes.length<1) {
        toastr.error('something went wrong, you can\'t close the PO at the moment');
        return;
      }
      /**
       * set the interval for closing multiple items
       */
      var closing = $interval(function () {
        Orders.closeLineItem({po_id: $scope.po._id, item_id: indexes[i], comment: $scope.commentText},
          function (success) {
          },
          function (error) {
            flagErrorOccurred = true;
            $interval.cancel(closing);
          });
        i++;
      }, 300, indexes.length).then(function(){

      if(flagErrorOccurred === false){
        toastr.success('The PO / line items is closed');
        HighlightId.setId($scope.po._id);
        if(indexes.length === $scope.close_po_data.length){
          $state.go('main.user.orders.manage', {status: 'CLOSED'});
        } else {
          $state.go('main.user.orders.manage', {status: 'AWAITING_DELIVERY'});
        }
      }else{
        toastr.error('Failed to close the PO / line items');
      }
        $uibModalInstance.close();
      });
    }

    function initialize() {
      _.forEach($scope.po.items, function(item, i){
        item.remaining_quantity = math.subtract(math.bignumber(item.quantity), math.bignumber(item.order.delivered));
        item.remaining_quantity = math.format(item.remaining_quantity, {precision: 64});
        $scope.close_po_data.push(
          {
            po_item: item,
            itemIndex: i
          }
        );
      });
    }

    initialize();
  });
