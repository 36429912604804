'use strict';

angular.module('metabuyer.services.reports', ['ngResource'])
  .factory('reports', function($resource, $filter, pathConstants) {
    return $resource(
      pathConstants.apiUrls.reports.generate,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('downloadReport', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.reports.download,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'},
        'download': {
          method: "GET",
          params: {
            id: '@id'
          },
          url: pathConstants.apiUrls.reports.download
        }
      }
    );
  })
  .factory('checkReportStatus', function($resource, $filter, pathConstants){
    return $resource(
      pathConstants.apiUrls.reports.checkStatus,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('reportTypes', function(){
    var reportTypes = [
      {
        code: 'SPEND',
        descr: 'Spend'
      },
      {
        code: 'RECEIVING',
        descr: 'Receiving Details'
      },
      {
        code: 'REQUISITION_COUNTER',
        descr: 'PR / PO Count Per Status Per Company'
      },
      {
        code: 'REQUISITION',
        descr: 'PR Report'
      },
      {
        code: 'BUDGET',
        descr: 'Budget'
      },
      {
        code: 'SUPPLIER_TAGGING',
        descr: 'Supplier Tags'
      }
    ];

    return {
      getReportTypes: function() {
        return reportTypes;
      }
    }
  });
