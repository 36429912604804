'use strict';

angular.module('metabuyer.services.notification', ['ngResource'])
  .factory('notificationCount',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.notification.count,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('notificationSubscriber',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.notification.subscribe,
      {
        method: '@method'
      },
      {
        'post': {method: 'POST'},
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('subscriptionCheck',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.notification.subscriptionCheck,
      {
        object_id: '@object_id',
        class: '@class'
      },
      {
        'get': {method: 'get'}
      }
    );
  })
  .factory('notificationList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.notifications,
      {
        id: '@id'
      },
      {
        'get': {method: 'GET',
          ignoreLoadingBar: true
        },
        'delete': {method: 'DELETE',
          ignoreLoadingBar: true}
      }
    );
  })
  .factory('unreadNotificationCount',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.countUnreadNotifications,
      {},
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('unreadNotificationList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.unreadNotifications,
      {},
      {
        'get': {method: 'GET',
          ignoreLoadingBar: true}
        ,
        'delete': {method: 'DELETE'}
      }
    );
  }).factory('readNotificationList',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.users.readNotifications,
      {},
      {
        'post': {method: 'POST',
          ignoreLoadingBar: true
        }
      }
    );
  });
