/**
 * @name escalationModificationPanel
 *
 * @description
 *
 * Controller for the Modal window to List the searched results
 *
 * @requires $scope
 * @requires user (resolved user from the previous state)
 * @requires company (resolved user from the previous state)
 * @requires searchUser (Metabuyer service)
 * @requires toastr
 * @requires $uibModalInstance
 * @requires addEscalation (Metabuyer service)
 * @requires removeEscalation (Metabuyer service)
 *
 *
 * @function selectingUser
 * Uses 'swal' to show a warning message, after the confirmation of Adding the user it confirmation
 * will have the Modal to dismiss as well the selected User will be added to the user's company
 * immediately to show in the HTML
 *
 * @function removeUser
 * Uses 'swal' to show a warning message, after the confirmation of Removing the user it will have the
 * Modal to dismiss as well the selected User will be removed from the user's company immediately
 * to remove it in the HTML
 *
 * @function validate
 * returns true or false, after validating, the User, Company and Selected User
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Verve Technologies Sdn. Bhd. &copy; 2015
 */


'use strict';

function escalationModificationPanel(
  $scope, user, company, superior, userInfo, searchUser, toastr, $uibModalInstance, addEscalation, removeEscalation,
  globalFunc
) {

  $scope.user = user;
  $scope.company = company;
  $scope.superior = superior;
  $scope.selectingUser = selectingUser;
  $scope.removeUser = removeUser;
  $scope.close = close;
  $scope.usersSearched = [];

  $scope.search = function (text) {
    if(text.length > 2){
      searchUser.get(
        {
          query: text
        },
        function (resource) {
          $scope.searchResults = 0;
          if(!!resource.content && !!resource.content.data){
            $scope.usersSearched = resource.content.data;
          }
        },
        function (error) {
          if(error.status === 404)
          {
            $scope.searchResult = 0;
            $scope.suppliersResults = [];
          }
          else {
            toastr.error("Failed to load a list of superiors");
          }
        }
      );
    }
  };

  function validate(userSelected){
    if(!$scope.user || !$scope.user._id){
      toastr.error('Failed to access the main user');
      return false;
    }
    if(!$scope.company){
      toastr.error('Failed to load the desired company');
      return false;
    }
    if(!userSelected || !userSelected._id || userSelected._id === $scope.user._id){
      toastr.error('Failed to access the escalated to user');
      return false;
    }
    return true;
  }

  function selectingUser(userSelected){
    if(validate(userSelected)) {
      swal({
          title: 'Confirm Selecting an Escalation user in ' + $scope.company.descr,
          text: userSelected.display_name + ' will be selected !',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (isConfirm) {
            addEscalation.post(
              {
                id: $scope.user._id,
                mid: userSelected._id
              },
              {
                company_code: $scope.company.code,
                class: userInfo.class
              },
              function (resource) {
                toastr.success('User was selected successfully');
                // Return selected user
                $uibModalInstance.close(userSelected);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
      });
    }
  }

  function close(){
    $uibModalInstance.close();
  }

  function removeUser(userSelected) {
    if(validate(userSelected)) {
      swal({
          title: 'Confirm Removing an Escalation user in ' + $scope.company.descr,
          text: userSelected.display_name + ' will be removed !',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#1ab394",
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if(isConfirm) {
            removeEscalation.delete(
              {
                id: $scope.user._id,
                company_code: $scope.company.code || null,
                class: userInfo.class
              },
              function (success) {
                toastr.success('Escalation option has been removed successfully');
                $scope.superior = null;
                userInfo.superior[0] = null;
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
          }
          $uibModalInstance.close();
        });
    }
  }

  function initialize() {
    // adding the selected manager if existed
    if (!!$scope.superior) {
      $scope.usersSearched.push($scope.superior);
    }

    if (!$scope.company) {
      var className = userInfo.class.replace('Metabuyer\\Models\\', '');

      if (className === 'ImportItemSummary') {
        className = 'Catalog';
      }
      $scope.company = { code: '', descr: className };
    }
  }

  initialize();
}

escalationModificationPanel.$inject = [
  '$scope', 'user', 'company', 'superior', 'userInfo', 'searchUser', 'toastr', '$uibModalInstance', 'addEscalation',
  'removeEscalation', 'globalFunc'
];

angular
  .module('metabuyer')
  .controller('escalationModificationPanel', escalationModificationPanel);
