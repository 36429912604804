/**
 * Controller for the user delegation modal
 * @param $scope
 * @param $uibModalInstance
 * @param $rootScope
 * @param massDelegate
 * @param toastr
 * @param globalFunc
 * @param selectedCompanies
 * @param getUsersInCompanies
 */

function userDelegationModalCtrl($scope, $uibModalInstance, $rootScope, massDelegate, toastr, globalFunc,
                                 selectedCompanies, getUsersInCompanies) {

  /**
   * Function to get the users array for ui-select based on company(s)
   */
  function getUsersArray() {
    var params = {};
    _.forEach(selectedCompanies, function (companyCode, index) {
      var temp = 'companyCodes[' + index + ']';
      params[temp] = companyCode;
    });

    getUsersInCompanies.get(
      params,
      function (resource) {
        $scope.users = (!!resource && !!resource.content && !!resource.content.data) ?
          resource.content.data : [];

        removeLoggedUserAndEditUser();
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.users = [];
      }
    );
  }

  /**
   * Function to remove current logged in user and current edit user from user array
   */
  function removeLoggedUserAndEditUser() {
    var currentLoggedInUser = _.findIndex($scope.users, function (user) {
      return user._id === $rootScope.currentUser._id;
    });

    if (currentLoggedInUser !== -1)
      $scope.users.splice(currentLoggedInUser, 1);

    // If the current user not editing himself i.e. admin editing a user
    if ($scope.user._id !== $rootScope.currentUser._id) {
      var currentEditUser = _.findIndex($scope.users, function (user) {
        return user._id === $scope.user._id;
      });
      $scope.users.splice(currentEditUser, 1);
    }
  }

  function openDatePicker($event, which) {

    /**
     * Setting minimum selection for start date to be today
     */
    $scope.startDateOptions.minDate = new Date();

    /**
     * Setting the minimum selection for end date to be one day ahead of selected start date
     */
    var startDateClone = _.cloneDeep($scope.delegation.startDate);
    var startDate = new Date(startDateClone);
    startDate.setDate(startDate.getDate() + 1);
    $scope.endDateOptions.minDate = startDate;

    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  function closeModal() {
    $uibModalInstance.close();
  }

  function onUserSelect(userObject) {
    $scope.selectedUser = userObject;
  }

  /**
   * function to check the submitted data
   */
  function checkToSubmit() {

    /**
     * Assign selected start date
     */
    var start_time = globalFunc.convertDateToTimestamp($scope.delegation.startDate);

    /**
     * Assign selected end date
     */
    var end_time = globalFunc.convertDateToTimestamp($scope.delegation.endDate);

    delegate(start_time, end_time, $scope.selectedUser._id);

  }

  /**
   * function to Delegate to another user
   * @param start_time
   * @param end_time
   * @param delegateeID
   * @returns {*}
   */
  function delegate(start_time, end_time, delegateeID) {

    return massDelegate.post(
      {
        userId: (!!$scope.user && !!$scope.user._id) ? $scope.user._id : $rootScope.currentUser._id
      },
      {
        delegatee_id: delegateeID,
        reason: $scope.delegation.reason,
        company_codes: selectedCompanies,
        start_date: start_time,
        end_date: end_time
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $uibModalInstance.close('success');
        }
        toastr.success(resource.content.message);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function initialize() {

    $scope.datePickerTimezone = $rootScope.datePickerTimezone;
    $scope.delegation = {};

    $scope.onUserSelect = onUserSelect;
    $scope.openDatePicker = openDatePicker;
    $scope.startDateOptions = {
      formatYear: 'yy',
      minDate: new Date(),
      startingDay: 1
    };
    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };
    $scope.datepickerOpened = {
      start_date: false,
      end_date: false
    };

    $scope.closeModal = closeModal;

    $scope.checkToSubmit = checkToSubmit;

    getUsersArray();
  }

  initialize();
}

userDelegationModalCtrl.$inject = [
  '$scope', '$uibModalInstance', '$rootScope', 'massDelegate', 'toastr', 'globalFunc',
  'selectedCompanies', 'getUsersInCompanies'
];

angular
  .module('metabuyer')
  .controller('userDelegationModalCtrl', userDelegationModalCtrl);
