'use strict';

angular.module('metabuyer.services.itemsServices', ['ngResource'])
    .factory('items',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.items.list,
        {
          status:'@status'
        },
        {
          'update': { method: 'PUT' },
          'get': { method: 'GET'},
          'post': {method: 'POST'},
          'approve': {
            method: 'POST',
            params: {
              approval_id: '@approval_id',
              status: '@status'
            },
            url: pathConstants.apiUrls.approvals.status
          }
        }
      );
    })
  .factory('createNonCatalogItem',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.nonCatalog,
      {
        id:'@user_id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('updateNonCatalogItem',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.manageNonCatalog,
      {
        user_id:'@user_id',
        item_id: '@item_id',
        action: '@action',
        pr_cc: '@pr_cc'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('deleteNonCatalogItem',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.manageNonCatalog,
      {
        user_id:'@user_id',
        item_id: '@item_id',
        action: '@action',
        itemName: '@itemName',
        pr_cc: '@pr_cc'
      },
      {
        'delete': {method: 'DELETE'}
      }
    );
  })
  .factory('manageItemDetail',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.detail,
      {
        id: '@id'
      },
      {
        'update': { method: 'PUT' },
        'get': { method: 'GET'},
        'post': {method: 'POST'}
      }
    );
  })
  .factory('deleteItems',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.delete,
      {
        id: '@id'
      },
      {
        'delete': { method: 'DELETE' }
      }
    );
  })
  .factory('importItems', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.import,
      {
        attachmentFile: '@attachmentFile',
        img_url: '@img_url'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('changeStatusCatalog', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.changeStatus,
      {
        status: '@status',
        id: '@id'
      },
      {
        'put': {method: 'PUT'}
      }
    );
  })
  .factory('catalogVersion', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.catalogVersion,
      {
        supplier_id: '@supplier_id',
        BU: '@BU'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('downloadFile', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.download,
      {
        supplier_id: '@supplierId',
        company_id: '@companyId'
      },
      {
        'get': {
          method: 'GET'
        },
        'download': {
          method: "GET",
          params: {
            summary_id: "@summary_id",
            attachment_id: "@attachment_id"
          },
          url: pathConstants.apiUrls.items.download
        }
      }
    );
  })
  .factory('checkImport', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.checkImport,
      {
        supplier_id: '@supplier_id',
        BU: '@BU'
      },
      {
        'get': {method: 'GET'}
      }
    );
  })
  .factory('itemDetailStarring',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.items.itemDetailStarring,
        {
          user_id: '@user_id',
          item_id: '@item_id',
          group: '@group'
        }, {

          'update': { method: 'PUT' }

        });
    })
  .factory('uploadImage', function ($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.imagesUpload,
      {
        user_id: '@user_id',
        item_id: '@item_id',
        detail_id: '@detail_id'
      },
      {
        'post': {method: 'POST'}
      }
    );
  })
  .factory('viewHistoryDetails',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.historyDetails,
      {
        summary_id: '@summary_id'
      },
      {
        'get': { method: 'GET' }
      });
  })
  .factory('itemDetailsListing',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.itemDetailsListing,
      {
        summary_id: '@summary_id',
        status: '@status'
      },
      {
        'get': { method: 'GET' }
      });
  })
  .factory('singleItemDetails',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.singleItemDetails,
      {
        summary_id: '@summary_id',
        import_item_id: '@import_item_id'
      },
      {
        'get': { method: 'GET' }
      });
  })
  .factory('getCatalogCodeListing',
  function($resource, pathConstants) {
    return $resource(
      pathConstants.apiUrls.items.listCatalogCode,
      {
        supplier_id: '@supplier_id',
        BU: '@BU'
      },
      {
        'get': { method: 'GET' }
      });
  })
  .factory('registerViewedItem', ['$resource', 'pathConstants',
    function($resource, pathConstants) {
      return $resource(
        pathConstants.apiUrls.items.registerViewedItem,
        {
          user_id: '@user_id',
          item_id: '@item_id'
        }, {
          put: { method: 'PUT', ignoreLoadingBar: true }
        });
    }]);


