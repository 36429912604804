'use strict';
/**
 * @name UsersDetailCtrl
 * @desc Controller for user detail
 */
function UsersDetailCtrl(
  $scope, $uibModal, $state, profile, toastr, singleUser, pathConstants, uploadImage, $auth, $rootScope,
  positionAssignment, HighlightId
) {

  $scope.user = profile;
  $scope.viewCostCenter = viewCostCenter;
  $scope.viewRoles = viewRoles;
  $scope.user.CClist = [];
  $scope.user.roleList = [];
  $scope.back = back;
  $scope.checkLockedUser = checkLockedUser;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.user._id,
      'object[0][object_type]': 'Metabuyer\\Models\\Users',
      offset: 10,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.isEditMode = false;
  $scope.listCostCenters = [];
  $scope.companyToAdd = {};
  $scope.positionAssign = null;

  // setting for cost center multi-select dropdown list
  $scope.settingCostCenter = {
    dynamicTitle: false,
    smartButtonMaxItems: 1,
    scrollable: true,
    idProp: "_id",
    externalIdProp: "",
    displayProp: "descr",
    enableSearch: true,
    enableSortOnTop: true,
    buttonClasses: "btn btn-white"
  };

  if(!$scope.user.status){
    $scope.user.status = '';
  }

  if(!$scope.user.role){
    $scope.user.role = '';
  }

  /**
   * Function to generate arrays for an object in each company
   * @param companyObject
   * Name of the object in companies array
   * @param userObject
   * Name of the object in user object
   */
  function generateArrayPerCompany(companyObject, userObject){
    for (var i = 0; i < $scope.user.companies.length; i++) {
      if(!$scope.user.companies[i][companyObject]){
        $scope.user.companies[i][companyObject] = [];
      }
      for (var j = 0; j < $scope.user[userObject].length; j++) {
        if ($scope.user[userObject][j].company_code === $scope.user.companies[i].code) {
          $scope.user.companies[i][companyObject].push($scope.user[userObject][j]);
        }
      }
    }
  }

  /**
   * Function to generate system object to display
   * @param userObject
   * Name of the object in user object
   * @param systemArray
   * Name of the array to be used
   */
  function generateSystemArray(userObject, systemArray){
    systemArray = [];
    if($scope.user[userObject] && $scope.user[userObject].length){
      for(var i = 0; i < $scope.user[userObject].length ; i++){
        if($scope.user[userObject][i].company_code === null){
          systemArray.push($scope.user[userObject][i]);
        }
      }
      $scope.systemObject[userObject] = systemArray;
    }
  }

  function viewCostCenter(listCostCenters) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showCostCenters-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showCostCenterCtrl,
      resolve: {
        listCostCenters: function () {
          return listCostCenters;
        },
        company: function () {
          return listCostCenters[0].company_name;
        }}
    })
  }

  function viewRoles(listRoles, company) {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/showRoles-modalTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: showRolesCtrl,
      resolve: {
        listRoles: function () {
          return listRoles;
        },
        company: function () {
          return company.descr;
        }}
    })
  }

  function back() {
    if (!!$scope.user && !!$scope.user._id) {
      HighlightId.setId($scope.user._id);
    }
    window.history.back();
  }

  function checkLockedUser() {
    $uibModal.open({
      templateUrl: "app/usersManagement/detail/admin-unlocking-user-panelTemplate.html",
      backdrop: 'static',
      keyboard: false,
      controller: adminUnlockUserCtrl,
      scope: $scope
    })
  }


  /*
   * The user is submitted and pending approval.

   PENDING = 0;

   /**
   * The user is active (Approved)

   ACTIVE = 1;

   /**
   * The user is deactivated

   INACTIVE = 2;

   /**
   * The user was not approved

   REJECTED = 3;
   */
  $scope.changeUserStatus = function (newStatus) {
    if (newStatus.toUpperCase() === 'INACTIVE') {
        swal({
            title: "User Deactivation",
            text: "Are you sure to DEACTIVATE this user?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, Deactivate It!",
            closeOnConfirm: true
        }, function(isConfirm){
            if (isConfirm) {
                singleUser.put(
                  {
                      id: $scope.user._id,
                      status: 2
                  },
                  function (resource) {
                      $scope.user.status = 'INACTIVE';
                  },
                  function (error) {
                      toastr.error('Failed to deactivate the user');
                  });
            }
        });
    }
    else if (newStatus.toUpperCase() === 'ACTIVE') {
        swal({
            title: "User Activation",
            text: "Are you sure to ACTIVATE this user?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1AB394",
            confirmButtonText: "Yes, Activate It!",
            closeOnConfirm: true
        }, function(isConfirm){
            if (isConfirm) {
                singleUser.put(
                    {
                        id: $scope.user._id,
                        status: 1
                    },
                    function (resource) {
                        $scope.user.status = 'ACTIVE';
                    },
                    function (error) {
                        toastr.error('Failed to activate the user');
                    });
            }
        });
    }

  };

  $scope.reject = function () {

  };

  // config flowjs
  $scope.flowConfig = new Flow({
    target: uploadImage.uploadImagePath($scope.user._id),
    singleFile: true,
    testChunks: false,
    chunkSize : 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    }
  });


  $scope.isUploadImageSuccess = function (file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  };

  function getPosition() {
    positionAssignment.get(
      {
        'sort[0][key]': 'updated_at',
        'sort[0][direction]': 'DESC',
        'filter_groups[1][filters][0][key]': 'assignee_id',
        'filter_groups[1][filters][0][value]': $scope.user._id,
        'filter_groups[1][filters][0][operator]': 'ct',
        'filter_groups[2][filters][0][key]': 'status',
        'filter_groups[2][filters][0][value]': 0,
        'filter_groups[2][filters][0][operator]': 'ct',
        limit: 0,
        page: 0
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.positionAssign = resource.content.data;
        }
      },
      function () {
        toastr.error('Failed to retrieve position information.');
      }
    )
  }

  function initialize() {

    $state.params.status = !!$scope.user.status ? $scope.user.status.toLowerCase() : 'all';

    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    /**
     * Check if user.companies is defined
     */
    if(!$scope.user.companies || angular.isUndefined($scope.user.companies)){
      $scope.user.companies = [];
    }
    $scope.systemObject = {};
    $scope.systemObject.descr = 'System';
    $scope.systemObject.code = 'system';
    $scope.systemObject._id = 'system';
    generateSystemArray('roles', 'systemRoles');
    generateSystemArray('positions', 'systemPositions');

    $scope.user.companies.push($scope.systemObject);

    generateArrayPerCompany('cost_centers', 'costCenters');
    generateArrayPerCompany('roles', 'roles');
    generateArrayPerCompany('positions', 'positions');
    generateArrayPerCompany('managers', 'managers');

    $scope.imageBasePath = pathConstants.apiUrls.image.user;

    getPosition();
  }

  initialize();

}

UsersDetailCtrl.$inject = [
  '$scope', '$uibModal', '$state', 'profile', 'toastr', 'singleUser', 'pathConstants', 'uploadImage', '$auth',
  '$rootScope', 'positionAssignment', 'HighlightId'
];

angular
  .module('metabuyer')
  .controller('UsersDetailCtrl', UsersDetailCtrl);
