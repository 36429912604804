'use strict';

/**
 * @name companyBillingOptions directive
 *
 * @description
 * The directive is to set the additional billing options for purchase requisitions that belong to the company
 *
 * @author Ahmed Saleh (a.saleh@metabuyer.com)
 * @copyright Sunway Metacloud &copy; 2016
 */

angular
  .module('metabuyer')
  .directive('companyBillingOptions', function() {
    return {
      restrict: 'E',
      scope: {
        company: '='
      },
      templateUrl: 'components/companyBillingOptions/companyBillingOptionsTemplate.html',
      controller: function ($scope, searchModule, globalFunc, Companies, toastr, $state) {

        $scope.availableCompanies = [];
        $scope.defaultBilledCompany = {
          selected: null
        };
        $scope.selectedBilledToCompanies = [];
        $scope.addToBillingCompanies = addToBillingCompanies;
        $scope.selectDefaultBillingCompany = selectDefaultBillingCompany;
        $scope.removeFromBillingCompanies = removeFromBillingCompanies;
        $scope.enableEditMode = enableEditMode;
        $scope.submit = submit;
        $scope.cancel = cancel;

        function setBillingInfo() {
          $scope.selectedBillingCompanies = [];
          $scope.defaultBilledCompany.selected = null;
          if (!!$scope.company.billingCompanies) {
            _.forEach($scope.company.billingCompanies, function (companyCode) {
              var temp = globalFunc.findInArray($scope.availableCompanies,
                'code', companyCode);
              if (!!temp) {
                $scope.selectedBillingCompanies.push(temp);
              }
            });

          }

          if (!!$scope.company.defaultBillingCompany) {
            $scope.defaultBilledCompany.selected =
              globalFunc.findInArray($scope.availableCompanies,
                'code', $scope.company.defaultBillingCompany);
          }
        }

        function initialize() {
          $scope.editMode = false;

          /**
           * load all companies
           */
          searchModule.get(
            {
              module: 'companies',
              offset: 0,
              order: 1,
              order_by: 'descr'
            },
            function (resource) {
              if (!!resource.content) {
                $scope.availableCompanies = resource.content.data;
                setBillingInfo();
              } else {
                globalFunc.objectErrorMessage('An error occurred');
              }
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        function addToBillingCompanies(com) {
          if (!globalFunc.findInArray($scope.selectedBillingCompanies, '_id', com._id))
            $scope.selectedBillingCompanies.push(com);

          if ($scope.selectedBillingCompanies.length === 1 && $scope.defaultBilledCompany.selected == null) {
            $scope.defaultBilledCompany.selected = com;
          }
        }

        function removeFromBillingCompanies(com) {
          $scope.selectedBillingCompanies =
            globalFunc.removeValueFromArray($scope.selectedBillingCompanies, '_id', com._id);
          if (!!$scope.defaultBilledCompany.selected && $scope.defaultBilledCompany.selected._id === com._id) {
            $scope.defaultBilledCompany.selected = null;
          }

          if ($scope.selectedBillingCompanies.length === 1 && $scope.defaultBilledCompany.selected == null) {
            $scope.defaultBilledCompany.selected = $scope.selectedBillingCompanies[0];
          }
        }

        function selectDefaultBillingCompany(com) {
          $scope.defaultBilledCompany = com;
        }

        function enableEditMode() {
          $scope.editMode = true;
        }

        function prepareCompanyChangedData() {
          var companyCodes = [];

          _.forEach($scope.selectedBillingCompanies, function (company) {
            companyCodes.push(company.code);
          });

          /**
           * re-fill up the required data to avoid errors
           */
          var companyDataChanges = {};
          companyDataChanges.fax = $scope.company.fax;
          companyDataChanges.email = $scope.company.email;
          companyDataChanges.phone = $scope.company.phone;
          companyDataChanges.corresponding_address_id = $scope.company.corresponding_address._id;
          companyDataChanges.PO_initial_status = $scope.company.PO_initial_status.value;
          companyDataChanges.expense_types = globalFunc.getArrayOfKeyValues($scope.company.expense_types, '_id');
          companyDataChanges.allowedToHoldPR = $scope.company.config.allowedToHoldPR;
          companyDataChanges.billingCompanies = companyCodes;
          companyDataChanges.defaultBillingCompany = !!$scope.defaultBilledCompany.selected ?
            $scope.defaultBilledCompany.selected.code : null;
          companyDataChanges.budget_setting = $scope.company.budget_setting;

          return companyDataChanges;
        }

        function submit() {
          var newData = prepareCompanyChangedData();
          Companies.put(
            {
              id: $scope.company._id
            }, newData,
            function () {
              toastr.success('Successfully updated');
              //todo: update company billing to correctly without using state reload
              $state.reload();
            },
            function (error) {
              globalFunc.objectErrorMessage(error);
            }
          );
        }

        function cancel() {
          setBillingInfo();
          //todo: update company billing to correctly without using state reload
          $state.reload();
        }

        initialize();
      }
    }
  });
