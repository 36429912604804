'use strict';

/**
 * Language file
 * first step of i18n
 */

angular.module('metabuyer').constant('lang', (function() {
  return {
    validation: {
      number: {
        isNumber: 'Please enter only numbers',
        positive: 'Value must be positive',
        noFraction: 'Value can not be fraction',
        notValidFraction: 'Fraction value is not valid'
      },
      percentage: {
        invalid: 'Invalid discount value'
      },
      lumpSum: {
        notHaving: 'The lump sum discount will be removed due to not having a ',
        different: 'The lump sum discount will be removed, due to having different '
      },
      required: {
        input: 'Please enter ',
        selection: 'Please select '
      },
      upload: {
        onlyImage: ' only accepts image file'
      }
    }
  }
})());
