'use strict';
/**
 * @name manageIndustryCtrl
 * @desc Controller for managing Industry
 */
function manageIndustryCtrl($scope, $stateParams, industryList, listingIndustries, pathConstants, $uibModal) {
  $scope.status = $stateParams.status;
  $scope.title = $stateParams.title;
  $scope.dataList = industryList.data;
  $scope.meta = industryList.meta;
  $scope.listingIndustryService = listingIndustries;
  $scope.importIndustry = importIndustry;
  $scope.importLink = pathConstants.apiUrls.industry.import;
  $scope.showColumn = showColumn;

    function importIndustry() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return "Industry";
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };
        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  $scope.storageKey = 'industries-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

}
manageIndustryCtrl.$inject = ['$scope','$stateParams', 'industryList', 'listingIndustries', 'pathConstants','$uibModal'];

angular.module('metabuyer')
  .controller('manageIndustryCtrl', manageIndustryCtrl);
