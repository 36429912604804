'use strict';

function suppliersListManageCtrl(
  $scope, suppliers, $rootScope, action, suppliersList, $stateParams, HighlightId, $window, supplierGlobalFunc,
  $filter)
{

  $scope.showColumn = showColumn;
  $scope.getStatusName = getStatusName;

  $scope.storageKey = 'suppliers-list-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the status name
   * @param supplierStatus
   * @param approvalStatus
   * @param draftingStatus
   * @returns {*}
   */
  function getStatusName(supplierStatus, approvalStatus, draftingStatus){
    var status = supplierGlobalFunc.getSupplierStatus(supplierStatus, approvalStatus, draftingStatus, 'val');
    if (!!status)
      return status;

    return 'N/A';
  }


  function initialize(){
    $scope.suppliers = suppliers.data;
    $scope.actionType = action;
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    $scope.meta = suppliers.meta;
    $scope.supplierManageService = suppliersList;
    $scope.status = $stateParams.status;

    if(!!$stateParams.status) {
      $rootScope.$broadcast('setSuppliersListTab', $stateParams.status.toLowerCase());
    }
    //set status for parent
    $scope.highlightId = HighlightId.getId();
    if(!!$scope.highlightId) {
      //scroll the window to top
      $window.scroll(0, 0);
      //clear current id
      HighlightId.setId(null);
    }
  }

  initialize();
}

suppliersListManageCtrl.$inject = [
  '$scope','suppliers', '$rootScope', 'action', 'suppliersList', '$stateParams', 'HighlightId', '$window',
  'supplierGlobalFunc', '$filter'
];

angular.module('metabuyer')
  .controller('suppliersListManageCtrl', suppliersListManageCtrl);
