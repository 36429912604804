'use strict';
/**
 *
 * @description
 *
 * State for the purchase requisitions module
 *
 *
 * @author Ahmed Saleh <a.saleh@vventures.asia>
 * @copyright Sunway Metacloud &copy; 2016
 */

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
    /**
     * @description
     *
     * Main state for the purchase requisitions,
     * Abstract and only handles the access to the Module
     *
     */
      .state('main.user.purchaseRequisitions', {
        url: '/requisitions',
        controller: 'purchaseRequisitionsMainCtrl',
        template: '<div ng-include="purchaseRequisitionsTemplate"></div>',
        abstract: true,
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            if (!UserPermissions.checkPRAccess(profile, 'O') || !UserPermissions.checkPRAccess(profile, 'L')) {
              $state.go('notAuthorized');
            }
          },
          findTemplate: function ($rootScope) {
            $rootScope.purchaseRequisitionsTemplate = $rootScope.isMobileMode ?
              'app/purchaseRequisitions/purchaseRequisitions-mobile.html' :
              'app/purchaseRequisitions/purchaseRequisitions.html';
          }
        }
      })
      .state('main.user.purchaseRequisitions.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}&{cursor:[^~,]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&' +
        '{query:[a-zA-Z0-9]+}&{order:[0-9]+}&{order_by:[a-zA-Z0-9]+}',
        controller: 'requisitionsManageCtrl',
        template: '<div ng-include="PRManageTemplate"></div>',
        resolve: {
          dataList: function ($q, searchModule, $stateParams, profile, exportSearch) {
            var deferred = $q.defer();
            var params;
            /**
             * searching
             */
            if(!!$stateParams.query) {

              /**
               * searching with a specific filter
               */
              if (!!$stateParams.filter) {
                params = {
                  module: 'PR',
                  offset: 20,
                  'criteria[2][creator|_id][]': ($stateParams.extraFilterKey === 'criteria[creator|_id][][]') ?
                    $stateParams.extraFilterValue : null,
                  'criteria[2][collaborators_ids][]': ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]') ?
                    $stateParams.extraFilterValue : null,
                  'criteria[2][waiting_on][]': ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                    $stateParams.extraFilterValue : null,
                  criteria_operator: 'and',
                  'criteria[0][status][2]' : $stateParams.status === 'pending' ? 'on_hold' : null,
                  'criteria[0][$operator]' : 'or',
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };

                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                //TODO: To have backend support searching double or change it (Justin)
                else if ($stateParams.type === 'double') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * searching all filters
               */
              else {
                params = {
                  module: 'PR',
                  offset: 20,
                  'criteria[2][creator|_id][]': ($stateParams.extraFilterKey === 'criteria[creator|_id][][]') ?
                    $stateParams.extraFilterValue : null,
                  'criteria[2][collaborators_ids][]': ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]') ?
                    $stateParams.extraFilterValue : null,
                  'criteria[2][waiting_on][]': ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                    $stateParams.extraFilterValue : null,
                  'criteria[1][reference]': $stateParams.query,
                  'criteria[1][title]': $stateParams.query,
                  'criteria[1][creator|display_name]': $stateParams.query,
                  'criteria[1][requestor|display_name]': $stateParams.query,
                  'criteria[1][company|descr]': $stateParams.query,
                  'criteria[1][cost_center|descr]': $stateParams.query,
                  'criteria[1][withdrawer|display_name]': $stateParams.status === 'withdrawn' ? $stateParams.query : null,
                  'criteria[1][$operator]': !!$stateParams.query ? 'or' : null,
                  'criteria[0][status][2]' : $stateParams.status === 'pending' ? 'on_hold' : null,
                  'criteria[0][$operator]' : 'or',
                  criteria_operator: !!$stateParams.query ? 'and' : null,
                  cursor: $stateParams.cursor,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
              }
            }
            /**
             * listing
             */
            else {
              params = {
                module: 'PR',
                offset: 20,
                'criteria[1][creator|_id][]': ($stateParams.extraFilterKey === 'criteria[creator|_id][][]') ?
                                            $stateParams.extraFilterValue : null,
                'criteria[1][collaborators_ids][]': ($stateParams.extraFilterKey === 'criteria[collaborators_ids][]') ?
                                                  $stateParams.extraFilterValue : null,
                'criteria[1][waiting_on][]': ($stateParams.extraFilterKey === 'criteria[waiting_on][]') ?
                                           $stateParams.extraFilterValue : null,
                'criteria[0][status][2]' : $stateParams.status === 'pending' ? 'on_hold' : null,
                'criteria[0][$operator]' : 'or',
                criteria_operator: 'and',
                cursor: $stateParams.cursor,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
            }

            if ($stateParams.status === 'starred')
              params['criteria[0][starred_users][]'] = profile._id;
            else if($stateParams.status === 'pending')
              params['criteria[0][status][1]'] = $stateParams.status;
            else
              params['criteria[0][status]'] = $stateParams.status;

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                //TODO: handle the error
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          status: function ($stateParams) {
            return !!$stateParams.status ? $stateParams.status.toLowerCase() : '';
          },
          $title: function () {
            return 'Purchase Requisitions'
          },
          findTemplate: function ($rootScope) {
            $rootScope.PRManageTemplate = $rootScope.isMobileMode ?
              'app/purchaseRequisitions/manage/manage-mobile.html' :
              'app/purchaseRequisitions/manage/manage.html';
          }
        }
      })
      /**
       * @description
       *
       * details state for the purchase requisitions,
       * Resolves the main PR in which passed to the rest of the components
       *
       */
      .state('main.user.purchaseRequisitions.details', {
        url: '/{id:[a-zA-Z0-9]+}',
        controller: 'requisitionsDetailsCtrl',
        template: '<div ng-include="PRDetailsTemplate"></div>',
        params: {PR: null},
        resolve: {
          purchaseRequisition: function($q, purchaseRequisitionsServices, $stateParams, prFunction, globalFunc) {

            var deferred = $q.defer();
            /**
             * the PR data is available, no need for a call to the backend to get the PR info
             */
            if (!!$stateParams.PR && !!$stateParams.PR.data && $stateParams.PR.data._id === $stateParams.id) {
              if (!prFunction.getPRData() || !prFunction.getPRData()._id || prFunction.getPRData()._id !== $stateParams.id) {
                //clean set of the PR object
                prFunction.initializePRData($stateParams.PR.data);
              }
              $stateParams.PR.data = prFunction.getPRData();
              deferred.resolve($stateParams.PR);
            }
            /**
             * the PR data is missing, transition is from a list or a Url
             */
            else {
              purchaseRequisitionsServices.getSinglePR(
                {
                  id: $stateParams.id
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content);
                    prFunction.initializePRData(resource.content.data);
                  } else {
                    //TODO: it was successful but something is a miss, Have the best way to redirect in a globalFunc all resolved errors
                    deferred.resolve([]);
                  }
                },
                function (error) {
                  globalFunc.objectErrorMessage(error);
                }
              );
            }
            return deferred.promise;
          },
          $title: function(){
            return 'PR - Details';
          },
          findTemplate: function ($rootScope) {
            $rootScope.PRDetailsTemplate = $rootScope.isMobileMode ?
              'app/purchaseRequisitions/details/details-mobile.html' :
              'app/purchaseRequisitions/details/details.html';
          }
        }
      })
      .state('main.user.purchaseRequisitions.details.catalog', {
        url: '/catalog',
        abstract: true,
        templateUrl: 'components/purchaseRequisitionComponents/purchaseRequisitionShoppingMode/catalogPanelTemplate.html',
        controller: 'catalogHeaderCtrl',
        resolve: {
          purchaseRequisition: function (purchaseRequisition) {
            return purchaseRequisition;
          },
          paymentTerms: function ($q, searchModule) {
            var deferred = $q.defer();
            //load the paymentTerms
            searchModule.get(
              {
                module: 'payment-term',
                'criteria[is_active]': 1,
                offset: 0
              },
              function (resource) {
                if (!!resource && !!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              });
            return deferred.promise;
          }
        }
      })
      .state('main.user.purchaseRequisitions.details.catalog.tab', {
        url: '/',
        templateUrl: 'components/purchaseRequisitionComponents/purchaseRequisitionShoppingMode/catalog.html',
        scope: false,
        resolve: {
          $title: function(){
            return "PR - detail - catalog";
          }
        }
      });
  }]);
