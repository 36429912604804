'use strict';
angular
  .module('metabuyer')
  .directive('reverseGoods',function($uibModal) {
    return{
      restrict: 'A',
      scope: true,
      link: function(scope) {

        var panel = function () {
          var modalInstance = $uibModal.open({
            templateUrl: 'components/reverseGoods/reverseGoodsTemplate.html',
            backdrop: 'static',
            keyboard: false,
            controller: 'ReverseGoodsCtrl',
            scope: scope,
            windowClass: 'reverse-goods-modal'
          });
        };

        //loading the panel
        scope.showReverseGoods = function () {
          panel();
        };
      }
    };
  });
